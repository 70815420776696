<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <h1 class="title">Catálogo de medicamentos oncológicos</h1>
            </div>
            <div class="level-right">
                <a
                    @click="addMedicine"
                    class="button"
                    append
                >
                    <span class="icon">
                        <font-awesome-icon icon="plus" />
                    </span>
                    <span> Agregar medicamento</span>
                </a>
            </div>
        </nav>
        <hr>
        <serbitec-oncologic-medicines-table
            :medicines="medicines"
            :medicines-count="medicinesCount"
            tableType="oncologic"

            :page.sync="page"
            :pageSize="pageSize"
            :sortField.sync="sortField"
            :sortOrder.sync="sortOrder"

            @page-change="onPageChange"
            @sort="onSort"
            @medicine-edited="medicineAddedOrEdited"
            @reload-medicines="$apollo.queries.medicines.refresh()"
        />
    </div>
</template>

<script>
import serbitecOncologicMedicinesTable from '../../../components/dashboard/medicines-catalog/medicines-table.vue';
import serbitecAddOncologicMedicineModal from '../../../components/dashboard/medicines-catalog/oncologic/add-medicine-modal.vue';

import gql from 'graphql-tag';
const query = gql` query ( $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String )
{
    medicines( is_oncologic: true, with_component_type_ids: "1, 5, 13", page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction ) {
        id
        commercial_name
        comments
        component {
            id
            name
            component_type {
                id
            }
        }
        manufacturer {
            id
            name
        }
        component_unit {
            id
            symbol
        }
        liofilized_oncologic_fields {
            id
            reconstitution_thinner {
                id
                name
            }
            reconstitution_stability_ambient_in_hours
            reconstitution_stability_cooled_in_hours
            reconstitution_final_volume_factor
        }
    }
    medicinesCount: table_counts( count_type: "medicines", is_oncologic: true, with_component_type_ids: "1, 5, 13" ) {
        medicines
    }
}`;

export default {
    name:'index',
    components: {
        serbitecOncologicMedicinesTable
    },
    data () {
        return {
            page: 0,
            pageSize: 10,
            sortField: 'id',
            sortOrder:'asc',
            medicinesCount: 0
        };
    },
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
        addMedicine () {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecAddOncologicMedicineModal,
                hasModalCard: true,
                trapFocus: true,
                fullScreen: true,
                events: {
                    'success': this.medicineAddedOrEdited
                }
            });
        },
        medicineAddedOrEdited () {
            this.$apollo.queries.medicines.refresh();
        }
    },
    apollo: {
        medicines: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            update ( data ) {
                this.medicinesCount = data.medicinesCount.medicines;
                return data.medicines;
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            }
        },
    }
};
</script>