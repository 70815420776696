export default function selectFunction () {
    console.error('You must select the function to be used in the import section in utils library');
}

export function setRandomId() {
    return Math.random().toString(36).substr(2, 8);
}

export function getVolumeInMlFromName (name) {
    var re = /(\d+(.(\d+)*)) *ml$/;
    var results = re.exec(name);

    if ( ! results ) {
        return 0;
    }

    if (results.length < 2) {
        return 0;
    }

    return parseInt(results[1]);
}

export function getmEqPerMlFromName (name) {
    var re = /(\d+(.(\d+)*)) *mEq\/ml/;
    var results = re.exec(name);

    if ( ! results ) {
        return 0;
    }

    if (results.length < 2) {
        return 0;
    }

    return parseFloat(results[1]);
}

export function getAditiveMlFactorFromName (name) {

    var response = {
        "factor": 1,
        "units": ["ml"]
    };

    var re = /(\d+(\.\d+)?)\s*UI\/ml/;
    var results = re.exec(name);
    if ( results ) {
        if (results.length >= 2) {
            response.factor = parseFloat(results[1]);
            response.units = ["UI", "ml"];
            return response;
        }
    }

    var re = /(\d+(\.\d+)?)\s*mcg\/ml/;
    var results = re.exec(name);
    if ( results ) {
        if (results.length >= 2) {
            response.factor = parseFloat(results[1]);
            response.units = ["mcg", "ml"];
            return response;
        }
    }

    var re = /(\d+(\.\d+)?)\s*mg\/ml/;
    var results = re.exec(name);
    if ( results ) {
        if (results.length >= 2) {
            response.factor = parseFloat(results[1]);
            response.units = ["mg", "ml"];
            return response;
        }
    }

    var re = /(\d+(\.\d+)?)\s*mg\/(\d+(\.\d+)?)\s*ml/;
    var results = re.exec(name);
    if ( results ) {
        if (results.length >= 2) {
            response.factor = parseFloat(results[1]/results[3]);
            response.units = ["mg", "ml"];
            console.log(response.factor);
            console.log(results);
            return response;
        }
    }

    var re = /(\d+(\.\d+)?)\s*mg\/(\d+(\.\d+)?)\s*ml/;
    var results = re.exec(name);
    if ( results ) {
        if (results.length >= 2) {
            response.factor = parseFloat(results[1]);
            response.units = ["mg", "ml"];
            console.log(response.factor);
            return response;
        }
    }

    var re = /(\d+(\.\d+)?)\s*g\/ml/;
    var results = re.exec(name);
    if ( results ) {
        if (results.length >= 2) {
            response.factor = parseFloat(results[1]);
            response.units = ["g", "ml"];
            return response;
        }
    }

    var re = /(\d+(\.\d+)?)\s*ml/;
    var results = re.exec(name);
    if ( results ) {
        if (results.length >= 2) {
            response.factor = parseFloat(results[1]);
            return response;
        }
    }
    
    return response;
}

export function totalGramsForMacrocomponent ( component, patientWeightInKg ) {
    if ( component.quantity && component.componentTypeId == '7' )
    {
        return null;
    }
    if ( component.quantity ) {
        if (component.unitSymbol == 'ml') {
            return (component.quantity * component.selectedComponent.nutritional_field.ml_to_g_factor).toFixed(2);
        }
        if (component.unitSymbol == 'g') {
            return parseFloat(component.quantity).toFixed(2);
        }
        if (component.unitSymbol == 'g/Kg') {
            return (component.quantity * patientWeightInKg).toFixed(2);
        }
    }
    return 0;
}

export function totalMlForMacrocomponent ( component, patientWeightInKg ) {
    if ( component.quantity && component.componentTypeId == '7' )
    {
        var mlPerBag = getVolumeInMlFromName( component.selectedComponent.name );
        return component.quantity * mlPerBag;
    }
    if ( component.quantity ) {
        if (component.unitSymbol == 'ml') {
            return parseFloat(component.quantity).toFixed(2);
        }
        if (component.unitSymbol == 'g') {
            return ( component.quantity / component.selectedComponent.nutritional_field.ml_to_g_factor ).toFixed(2);
        }
        if (component.unitSymbol == 'g/Kg') {
            var quantityInGrams = component.quantity * patientWeightInKg;
            return ( quantityInGrams / component.selectedComponent.nutritional_field.ml_to_g_factor ).toFixed(2);
        }
    }
    return 0;
}

export function kcalForMacrocomponent ( component, patientWeightInKg ) {
    if ( component.quantity && component.componentTypeId == '7' )
    {
        return ( component.quantity * component.selectedComponent.nutritional_field.energy_input_in_kcal).toFixed(2);
    }
    if ( component.quantity ) {
        return ( component.selectedComponent.nutritional_field.energy_input_in_kcal * totalMlForMacrocomponent( component, patientWeightInKg ) ).toFixed(2);
    }
    return 0;
}

export function electrolyteInMEq ( electrolyte, patientWeightInKg ) {
    if ( electrolyte.quantity ) {
        if (electrolyte.unitSymbol == 'mEq') {
            return parseFloat( electrolyte.quantity ).toFixed(2);
        }
        if (electrolyte.unitSymbol == 'mEq/Kg') {
            return ( electrolyte.quantity * patientWeightInKg ).toFixed(2);
        }
        if (electrolyte.unitSymbol == 'ml') {
            var mEqPerMl = getmEqPerMlFromName(electrolyte.name);
            return ( electrolyte.quantity * mEqPerMl ).toFixed(2);
        }
    }
    return 0;
}

export function electrolyteInMEqPerKg ( electrolyte, patientWeightInKg ) {
    if ( electrolyte.quantity ) {
        if (electrolyte.unitSymbol == 'mEq') {
            return ( electrolyte.quantity / patientWeightInKg ).toFixed(2);
        }
        if (electrolyte.unitSymbol == 'mEq/Kg') {
            return parseFloat( electrolyte.quantity).toFixed(2);
        }
        if (electrolyte.unitSymbol == 'ml') {
            const mEqPerMl = getmEqPerMlFromName( electrolyte.name );
            const quantityInMEq = ( electrolyte.quantity * mEqPerMl );
            return ( quantityInMEq / patientWeightInKg ).toFixed(2);
        }
    }
    return 0;
}

export function electrolyteInMl ( electrolyte, patientWeightInKg ) {
    const mEq_per_ml = getmEqPerMlFromName(electrolyte.name);

    if ( electrolyte.quantity ) {
        if ( electrolyte.unitSymbol == 'mEq' ) {
            return ( electrolyte.quantity / mEq_per_ml ).toFixed(2);
        }
        if ( electrolyte.unitSymbol == 'mEq/Kg' ) {
            var mEqPerKgToMEq = electrolyte.quantity * patientWeightInKg;
            return ( mEqPerKgToMEq / mEq_per_ml ).toFixed(2);
        }
        if (electrolyte.unitSymbol == 'ml') {
            return parseFloat( electrolyte.quantity ).toFixed(2);
        }
    }
    return 0;
}

export function aditiveInMl ( aditive ) {
    if ( aditive.unitSymbol == 'ml' ) {
        return aditive.quantity;
    } else {
        return ( aditive.quantity / aditive.factor);
    }
}