<template>
    <div class="box">
        <b-table
            :data="payers"
            :loading="loading"

            paginated
            backend-pagination
            :total="payersCount"
            :per-page="pageSize"
            @page-change="changePage"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="sort">

            <template slot-scope="props">
                <b-table-column label="Cliente">
                    {{ props.row.invoicing_data.business_name }}
                    <b-tag v-if="props.row.invoicing_data.price_in_cents_per_nutritional_mixture > 0">Precio por mezcla NPT</b-tag>
                    <b-tag v-if="props.row.invoicing_data.price_in_cents_per_oncologic_mixture > 0">Precio por mezcla Oncológicos</b-tag>
                </b-table-column>

                <b-table-column label="Configuración" centered>
                    <router-link :to="{ path: 'configuration', query: { payer_id: props.row.id } }" class="button is-light is-small" append>
                        <span class="icon is-medium">
                            <font-awesome-icon icon="cog" />
                        </span>
                    </router-link>
                </b-table-column>
                <b-table-column label="Medicamentos" centered>
                    <router-link :to="{ path: 'medicines', query: { payer_id: props.row.id } }" class="button is-light is-small" append>
                        <span class="icon is-medium">
                            <font-awesome-icon icon="tablets" />
                        </span>
                    </router-link>
                </b-table-column>
                <b-table-column label="Usuarios" centered>
                    <router-link :to="{ path: 'users', query: { payer_id: props.row.id } }" class="button is-light is-small" append>
                        <span class="icon is-medium">
                            <font-awesome-icon icon="users" />
                        </span>
                    </router-link>
                </b-table-column>
                <b-table-column label="Editar" centered>
                    <router-link :to="{ path: 'edit', query: { payer_id: props.row.id } }" class="button is-light is-small" append>
                        <span class="icon is-medium">
                            <font-awesome-icon icon="edit" />
                        </span>
                    </router-link>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay clientes registrados.</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    props: [
        'payers',
        'loading',
        'payersCount',
        'page',
        'pageSize',
        'sortField',
        'sortOrder'
    ],
    methods: {
        changePage ( page ) {
            this.$emit('page-change', page);
        },
        sort ( field, order ) {
            this.$emit('sort', field, order);
        }
    }
};
</script>