var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("b-loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function($event) {
            _vm.loading = $event
          }
        }
      }),
      _vm._v(" "),
      _vm.hasCloseButton
        ? _c("nav", { staticClass: "level" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "level-right" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close-form")
                    }
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "icon" },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "times-circle" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v(" Cerrar forma")])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "true" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitPayerMedicineForm($event)
            }
          }
        },
        [
          !_vm.medicine
            ? _c("serbitec-medicine-autocomplete", {
                on: { "medicine-selected": _vm.medicineSelected }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.medicine
            ? _c(
                "b-field",
                { attrs: { grouped: "", "group-multiline": "" } },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "Medicamento" } },
                    [
                      _c("b-field", [
                        _c("p", { staticClass: "control" }, [
                          _c(
                            "span",
                            {
                              staticClass: "button",
                              on: { click: _vm.unselectMedicine }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-small" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "times-circle" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "control" }, [
                          _c("span", { staticClass: "button is-static" }, [
                            _vm._v(
                              "\n                            Nombre comercial: " +
                                _vm._s(_vm.medicine.commercial_name) +
                                " | \n                            Genérico: " +
                                _vm._s(_vm.medicine.component.name) +
                                " |\n                            Fabricante: " +
                                _vm._s(_vm.medicine.manufacturer.name) +
                                "\n                        "
                            )
                          ])
                        ])
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.showPricePerPresentation
                    ? _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Precio del medicamento",
                            type: { "is-danger": _vm.$v.pricePerMl.$error },
                            message: {
                              "Se requiere introducir el precio por ml del medicamento":
                                !_vm.$v.pricePerMl.required &&
                                _vm.$v.pricePerMl.$error
                            },
                            expanded: ""
                          }
                        },
                        [
                          _c(
                            "b-field",
                            {
                              attrs: {
                                type: { "is-danger": _vm.$v.pricePerMl.$error }
                              }
                            },
                            [
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "button",
                                  { staticClass: "button is-static" },
                                  [_vm._v("$")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("b-input", {
                                directives: [
                                  {
                                    name: "cleave",
                                    rawName: "v-cleave",
                                    value: _vm.masks.numeral,
                                    expression: "masks.numeral"
                                  }
                                ],
                                attrs: {
                                  value: _vm.$v.pricePerMl.$model,
                                  type: "text",
                                  expanded: ""
                                },
                                nativeOn: {
                                  input: function($event) {
                                    return _vm.onInputPricePerMl($event)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _vm.medicine.component_unit.symbol == "pz"
                                  ? _c(
                                      "button",
                                      { staticClass: "button is-static" },
                                      [_vm._v("MXN por pieza")]
                                    )
                                  : _c(
                                      "button",
                                      { staticClass: "button is-static" },
                                      [_vm._v("MXN por ml")]
                                    )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          !_vm.showPricePerPresentation
            ? _c(
                "b-field",
                { attrs: { grouped: "", position: "is-centered" } },
                [
                  _c("p", { staticClass: "control" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-primary",
                        attrs: { type: "submit" }
                      },
                      [
                        _c("span", [
                          _vm._v(
                            "\n                        Agregar medicamento\n                    "
                          )
                        ])
                      ]
                    )
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("br")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("h1", { staticClass: "subtitle" }, [_vm._v("Agregar medicamento")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }