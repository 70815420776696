var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.elements, function(element, index) {
        return _c(
          "div",
          { key: index, staticClass: "box" },
          [
            _c("nav", { staticClass: "level serbitec-small-bottom" }, [
              _c("div", { staticClass: "level-left" }, [
                _c("p", { staticClass: "has-text-weight-bold is-size-5" }, [
                  _vm._v(_vm._s(element.componentName))
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "level-right" }, [
                _c("p", { staticClass: "is-size-4" }, [
                  _vm._v(_vm._s(element.quantityInMedicineUnit) + " ml")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("b-table", {
              attrs: { data: element.warehouseItems },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c("b-table-column", { attrs: { label: "Lote" } }, [
                          _c("p", [
                            props.row.prefix
                              ? _c("strong", [
                                  _vm._v(_vm._s(props.row.prefix.prefix) + "-")
                                ])
                              : _vm._e(),
                            _vm._v(" " + _vm._s(props.row.batch))
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-table-column",
                          { attrs: { label: "Medicamento" } },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(props.row.medicineName) +
                                "\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-table-column",
                          { attrs: { label: "Presentación", centered: "" } },
                          [
                            element.isPiece
                              ? _c("b-tag", [
                                  _vm._v(
                                    _vm._s(
                                      props.row.medicinePresentationQuantity
                                    ) +
                                      " " +
                                      _vm._s(
                                        props.row
                                          .medicinePresentationQuantityUnit
                                      )
                                  )
                                ])
                              : _c("b-tag", [
                                  _vm._v(
                                    _vm._s(
                                      props.row.medicinePresentationVolumeInMl
                                    ) + " ml"
                                  )
                                ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-table-column",
                          { attrs: { label: "Cantidad", centered: "" } },
                          [
                            element.isPiece
                              ? _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(props.row.medicineQuantityInMl) +
                                        " " +
                                        _vm._s(
                                          props.row
                                            .medicinePresentationQuantityUnit
                                        )
                                    )
                                  ])
                                ])
                              : _c("p", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(props.row.medicineQuantityInMl) +
                                        " ml"
                                    )
                                  ])
                                ])
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            })
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.hasInfusionSet
        ? _c("b-message", { staticClass: "is-success" }, [
            _c("nav", { staticClass: "level" }, [
              _c("div", { staticClass: "level-left" }, [
                _c("h1", [_vm._v("Set de infusión")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "level-right" }, [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "check-circle" } })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v(" Solicitado")])
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-field",
        {
          staticStyle: { "margin-top": "25px" },
          attrs: { position: "is-centered", grouped: "" }
        },
        [
          _c("p", { staticClass: "control has-text-centered" }, [
            _c(
              "button",
              {
                staticClass: "button is-primary",
                on: {
                  "~click": function($event) {
                    return _vm.$emit("mixture-prepared")
                  }
                }
              },
              [_vm._v("\n                Mezcla preparada\n            ")]
            )
          ])
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }