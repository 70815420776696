<template>
    <div class="box">
        <b-loading :active.sync="loading"></b-loading>
        <b-table
        :data="isEmpty ? [] : orderList"
        :hoverable="true"
        :loading="loadingTable"
        paginated
        per-page="10"
        detailed
        detail-key="id"
        :mobile-cards="true">

            <template slot-scope="props">
                <b-table-column field="name" label="Medicamento">
                    <span v-if="props.row.thinner_id">
                        <span v-for="medicine in props.row.medicines" :key="medicine.id">
                            {{ medicine.name }} + {{ props.row.thinner_name }}
                        </span>
                    </span>
                    <span v-if="props.row.medicine_presentation_id">
                        {{ props.row.medicine_name }}
                    </span>
                </b-table-column>

                <b-table-column label="Tipo">
                    <b-tag v-if="props.row.thinner_id">Bolo</b-tag>
                    <b-tag v-if="props.row.medicine_presentation_id">Frasco</b-tag>
                </b-table-column>

                <b-table-column label="Eliminar" centered>
                    <a v-if="props.row.thinner_id" class="button is-small is-danger" v-on:click="deleteMixture(props.row.id)"><font-awesome-icon icon="trash-alt" /></a>
                    <a v-if="props.row.medicine_presentation_id" class="button is-small is-danger" v-on:click="deleteFlask(props.row.id)"><font-awesome-icon icon="trash-alt" /></a>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay bolos ni frascos en la orden, para agregar de click en el botón de agregar bolo o agregar frasco.</p>
                    </div>
                </section>
            </template>

            <template slot="detail" slot-scope="props">
                <div class="content">
                    <p v-if="props.row.thinner_id">
                        <strong>Diluyente: </strong> {{ props.row.thinner_quantity }} ml de {{ props.row.thinner_name }}
                        <span v-for="medicine in props.row.medicines" :key="medicine.id">
                            <br>
                            <strong>Activo: </strong> {{ medicine.medicine_quantity }} {{ medicine.medicine_unit_symbol }} de {{ medicine.name }}
                        </span>
                        <span v-if="props.row.comments">
                            <br>
                            <strong>Comentarios: </strong>{{ props.row.comments }}
                        </span>
                        <br>
                        <strong>Set de infusión: </strong>
                        <font-awesome-icon v-if="props.row.infusion_set" icon="check-circle" />
                        <font-awesome-icon v-else icon="times-circle" />
                    </p>
                    <p v-if="props.row.medicine_presentation_id">
                        <strong>Activo: </strong> {{ props.row.medicine_name }} {{ props.row.medicine_component_name }}
                        <br>
                        <strong>Presentación: </strong> {{ props.row.presentation_quantity }} {{ props.row.medicine_presentation_unit_symbol }}
                        <br>
                        <strong>Cantidad: </strong> {{ props.row.number_of_flasks }} frasco(s)
                        <span v-if="props.row.comments">
                            <br>
                            <strong>Comentarios: </strong>{{ props.row.comments }}
                        </span>
                    </p>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState({
            mixtures: state => state.oncologicOrder.mixtures,
            flasks: state => state.oncologicOrder.flasks
        }),
        orderList () {
            return this.mixtures.concat(this.flasks);
        },
    },
    data: function () {
        return {
            isEmpty: false,
            loadingTable: false,
            loading: false,
        };
    },
    methods: {
        deleteMixture ( mixtureID ) {
            this.loading = true;
            this.$store.commit('oncologicOrder/REMOVE_ONCOLOGIC_MIXTURE_FROM_ORDER', mixtureID);
            this.loading = false;
        },
        deleteFlask ( flaskID ) {
            this.loading = true;
            this.$store.commit('oncologicOrder/REMOVE_ONCOLOGIC_FLASK_FROM_ORDER', flaskID);
            this.loading = false;
        },
    }
};
</script>