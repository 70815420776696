<template>
    <div class="box">
        <div class="column is-flex is-horizontal-center">
            <figure class="image is-256x256">
                <img src="images/SERBITEC_login.png">
            </figure>
        </div>
        <small>Para cambiar la contraseña introduzca la contraseña anterior, la nueva contraseña y su confirmación</small>
        <form
            class="serbitec-small-top-margin"
            @submit.prevent="submitForm"
            novalidate="true"
        >
            <b-field
                label="Correo electrónico"
                :type="{'is-danger' : $v.email.$error }"
                :message="{
                    'Se requiere introducir el correo electrónico' : !$v.email.required && $v.email.$error,
                    'Se requiere introducir un correo electrónico válido' : !$v.email.email && $v.email.$error
                }"
                label-position="on-border"
                expanded
            >
                <b-input
                    v-model="$v.email.$model"
                    type="text">
                </b-input>
            </b-field>
            <b-field
                label="Contraseña nueva"
                :type="{'is-danger' : $v.newPassword.$error }"
                :message="{
                    'Se requiere introducir la contraseña' : !$v.newPassword.required && $v.newPassword.$error
                }"
                label-position="on-border"
                expanded
            >
                <b-input
                    v-model="$v.newPassword.$model"
                    type="password"
                    password-reveal>
                </b-input>
            </b-field>
            <b-field
                label="Repita la nueva contraseña"
                :type="{'is-danger' : $v.newPasswordConfirmation.$error }"
                :message="{
                    'Se requiere introducir la contraseña' : !$v.newPasswordConfirmation.required && $v.newPasswordConfirmation.$error,
                    'La contraseña repetida debe der igual' : !$v.newPasswordConfirmation.sameAsNewPassword && $v.newPasswordConfirmation.$error
                }"
                label-position="on-border"
                expanded
            >
                <b-input
                    v-model="$v.newPasswordConfirmation.$model"
                    type="password"
                    password-reveal>
                </b-input>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                grouped position="is-centered"
            >
                <p class="control">
                    <button type="submit" class="button is-primary">
                        <span>
                            Reestablecer contraseña
                        </span>
                    </button>
                </p>
            </b-field>
        </form>
    </div>
</template>

<script>
import { required, sameAs, email } from 'vuelidate/lib/validators';
import axios from 'axios';

export default {
    props: {
        resetToken: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            email: '',
            newPassword: '',
            newPasswordConfirmation: ''
        };
    },
    methods: {
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.login();
            }
        },
        login() {
            const loader = this.$buefy.loading.open();
            const params = {
                email: this.email,
                password: this.newPassword,
                token: this.resetToken
            };
            axios.post('/api/password/reset', params).then(() => {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha modificado tu correo, introduce tus datos para ingresar al sistema'
                });
                this.$router.push({ path: '/' });
                loader.close();
            }).catch((error) => {
                console.error(error);
                this.$notify({
                    title: '¡Algo ha sucedido!',
                    type: 'error',
                    text: error.response.data.errors ? error.response.data.errors.email[0] : 'Algo ha sucedido, contacte al administrador'
                });
                loader.close();
            });
        }
    },
    validations: {
        email: {
            required,
            email
        },
        newPassword: {
            required
        },
        newPasswordConfirmation: {
            required,
            sameAsNewPassword: sameAs('newPassword')
        }
    }
};
</script>