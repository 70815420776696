var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card", staticStyle: { width: "auto" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: "Diluyente",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.selectedThinner.$error },
                message: {
                  "Se requiere introducir y seleccionar el diluyente para reconstitución":
                    !_vm.$v.selectedThinner.required &&
                    _vm.$v.selectedThinner.$error
                },
                expanded: ""
              }
            },
            [
              _c("serbitec-component-select", {
                attrs: {
                  "selected-component": _vm.selectedThinner,
                  "with-component-type-ids": "6,5,13"
                },
                on: {
                  "component-selected": function(thinner) {
                    return (_vm.selectedThinner = thinner)
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                label: "Estabilidad a temperatura ambiente",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.ambientStability.$error },
                message: {
                  "Se requiere introducir la estabilidad a temperatura ambiente":
                    !_vm.$v.ambientStability.required &&
                    _vm.$v.ambientStability.$error,
                  "Se requiere introducir un número decimal":
                    !_vm.$v.ambientStability.decimal &&
                    _vm.$v.ambientStability.$error
                },
                expanded: ""
              }
            },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    type: { "is-danger": _vm.$v.ambientStability.$error }
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text", expanded: "" },
                    model: {
                      value: _vm.$v.ambientStability.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.ambientStability,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.ambientStability.$model"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "control" }, [
                    _c("span", { staticClass: "button is-static" }, [
                      _vm._v("h")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                label: "Estabilidad en refrigeración",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.cooledStability.$error },
                message: {
                  "Se requiere introducir la estabilidad en refrigeración":
                    !_vm.$v.cooledStability.required &&
                    _vm.$v.cooledStability.$error,
                  "Se requiere introducir un número decimal":
                    !_vm.$v.cooledStability.decimal &&
                    _vm.$v.cooledStability.$error
                },
                expanded: ""
              }
            },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    type: { "is-danger": _vm.$v.cooledStability.$error }
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text", expanded: "" },
                    model: {
                      value: _vm.$v.cooledStability.$model,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.$v.cooledStability,
                          "$model",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "$v.cooledStability.$model"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "control" }, [
                    _c("span", { staticClass: "button is-static" }, [
                      _vm._v("h")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("h1", { staticClass: "subtitle is-6" }, [
            _vm._v("Información adicional")
          ]),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                label: "Concentración mínima recomendada",
                "label-position": "on-border",
                expanded: ""
              }
            },
            [
              _c(
                "b-field",
                [
                  _c("b-input", {
                    attrs: { type: "text", expanded: "" },
                    model: {
                      value: _vm.minimumConcentrationRecommended,
                      callback: function($$v) {
                        _vm.minimumConcentrationRecommended = $$v
                      },
                      expression: "minimumConcentrationRecommended"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                label: "Concentración máxima recomendada",
                "label-position": "on-border",
                expanded: ""
              }
            },
            [
              _c(
                "b-field",
                [
                  _c("b-input", {
                    attrs: { type: "text", expanded: "" },
                    model: {
                      value: _vm.maximumConcentrationRecommended,
                      callback: function($$v) {
                        _vm.maximumConcentrationRecommended = $$v
                      },
                      expression: "maximumConcentrationRecommended"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: { grouped: "", "group-multiline": "" }
            },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Velocidad de infusión mínima",
                    "label-position": "on-border",
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: {
                      placeholder: "Mínima",
                      type: "text",
                      expanded: ""
                    },
                    model: {
                      value: _vm.minInfusionSpeed,
                      callback: function($$v) {
                        _vm.minInfusionSpeed = $$v
                      },
                      expression: "minInfusionSpeed"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Máxima",
                    "label-position": "on-border",
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: {
                      name: "max_infusion_speed",
                      placeholder: "Máxima",
                      type: "text",
                      expanded: ""
                    },
                    model: {
                      value: _vm.maxInfusionSpeed,
                      callback: function($$v) {
                        _vm.maxInfusionSpeed = $$v
                      },
                      expression: "maxInfusionSpeed"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.minInfusionSpeed || _vm.maxInfusionSpeed
                ? _c(
                    "b-field",
                    {
                      attrs: {
                        label: "Unidad",
                        "label-position": "on-border",
                        type: { "is-danger": _vm.$v.selectedUnit.$error },
                        message: {
                          "Se requiere la unidad":
                            !_vm.$v.selectedUnit.required &&
                            _vm.$v.selectedUnit.$error
                        },
                        expanded: ""
                      }
                    },
                    [
                      _c("serbitec-unit-select", {
                        attrs: { selectedUnit: _vm.selectedUnit },
                        on: {
                          "unit-selected": function(unit) {
                            return (_vm.selectedUnit = unit)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                label: "Referencia",
                "label-position": "on-border",
                message:
                  "Introduzca la referencia de la cual se obtuvo la información adicional",
                expanded: ""
              }
            },
            [
              _c(
                "b-field",
                [
                  _c("b-input", {
                    attrs: { type: "text", expanded: "" },
                    model: {
                      value: _vm.reference,
                      callback: function($$v) {
                        _vm.reference = $$v
                      },
                      expression: "reference"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$parent.close()
              }
            }
          },
          [_vm._v("\n            Cancelar\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button is-primary", on: { click: _vm.submitForm } },
          [_vm._v("\n            Agregar\n        ")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v("\n            Agregar diluyente\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }