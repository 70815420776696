<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Editar comentarios
            </p>
        </header>
        <section class="modal-card-body">
            <p>
                1. Para editar los comentarios cambie o introduzca los comentarios en el siguiente campo y de click en editar.
            </p>
            <br>
            <b-field label="Comentarios" label-position="on-border">
                <b-input
                    v-model="mixture.comments"
                    maxlength="200"
                    type="textarea"
                />
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                Cancelar
            </button>
            <button class="button is-primary" @click="editComments">
                Editar
            </button>
        </footer>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        mixture: {
            type: Object,
            default: () => {}
        },
        type: {
            type: String,
            default: ''
        },
        itemType: {
            type: String,
            default: ''
        }
    },
    methods: {
        editComments () {
            var url = '';
            var params = {};
            if (this.type === 'nutritional') {
                url = '/api/nutritional/mixtures/' + this.mixture.id + '/comments';
                params = { mixture_comments: this.mixture.comments };
            }
            if (this.type === 'oncologic' && this.itemType === 'mixture') {
                url = '/api/oncologic/mixtures/' + this.mixture.id + '/comments';
                params = { mixture_comments: this.mixture.comments };
            }
            axios.put(url, params)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se han modificado los comentarios'
                });
                this.$emit('close');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
        }
    }
};
</script>