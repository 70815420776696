var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("b-loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function($event) {
            _vm.loading = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "true" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitPayerForm($event)
            }
          }
        },
        [
          _c("p", [
            _vm._v("1. Ingrese los datos de la persona de contacto del cliente")
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { grouped: "", "group-multiline": "" } },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Nombre",
                    type: { "is-danger": _vm.$v.name.$error },
                    message: {
                      "Se requiere introducir el nombre completo de la persona de contacto":
                        !_vm.$v.name.required && _vm.$v.name.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.name, "$model", $$v)
                      },
                      expression: "$v.name.$model"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Apellido paterno",
                    type: { "is-danger": _vm.$v.last_name_1.$error },
                    message: {
                      "Se requiere introducir el apellido paterno de la persona de contacto":
                        !_vm.$v.last_name_1.required &&
                        _vm.$v.last_name_1.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.last_name_1.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.last_name_1, "$model", $$v)
                      },
                      expression: "$v.last_name_1.$model"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Apellido materno",
                    type: { "is-danger": _vm.$v.last_name_2.$error },
                    message: {
                      "Se requiere introducir el apellido materno de la persona de contacto":
                        !_vm.$v.last_name_2.required &&
                        _vm.$v.last_name_2.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.last_name_2.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.last_name_2, "$model", $$v)
                      },
                      expression: "$v.last_name_2.$model"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { grouped: "", "group-multiline": "" } },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Correo electrónico",
                    type: { "is-danger": _vm.$v.contact_email.$error },
                    message: {
                      "Se requiere introducir el correo electrónico de la persona de contacto":
                        !_vm.$v.contact_email.required &&
                        _vm.$v.contact_email.$error,
                      "Se requiere introducir un correo electrónico válido":
                        !_vm.$v.contact_email.email &&
                        _vm.$v.contact_email.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.contact_email.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.contact_email, "$model", $$v)
                      },
                      expression: "$v.contact_email.$model"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Tipo de cliente",
                    type: { "is-danger": _vm.$v.payerTypeId.$error },
                    message: {
                      "Se requiere introducir el tipo de cliente":
                        !_vm.$v.payerTypeId.required &&
                        _vm.$v.payerTypeId.$error
                    }
                  }
                },
                [
                  _c(
                    "b-select",
                    {
                      attrs: { placeholder: "Seleccione un tipo" },
                      model: {
                        value: _vm.$v.payerTypeId.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.payerTypeId, "$model", $$v)
                        },
                        expression: "$v.payerTypeId.$model"
                      }
                    },
                    _vm._l(_vm.payerTypes, function(payerType) {
                      return _c(
                        "option",
                        {
                          key: payerType.id,
                          domProps: { value: payerType.id }
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(payerType.name) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("p", [_vm._v("2. Ingrese los datos fiscales del cliente")]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { grouped: "", "group-multiline": "" } },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Razón social",
                    type: { "is-danger": _vm.$v.business_name.$error },
                    message: {
                      "Se requiere introducir la razón social del cliente":
                        !_vm.$v.business_name.required &&
                        _vm.$v.business_name.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.business_name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.business_name, "$model", $$v)
                      },
                      expression: "$v.business_name.$model"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "RFC",
                    type: { "is-danger": _vm.$v.rfc.$error },
                    message: {
                      "Se requiere introducir el RFC del cliente":
                        !_vm.$v.rfc.required && _vm.$v.rfc.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.rfc.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.rfc, "$model", $$v)
                      },
                      expression: "$v.rfc.$model"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              attrs: {
                label: "Dirección fiscal",
                type: { "is-danger": _vm.$v.address.$error },
                message: {
                  "Se requiere introducir la dirección fiscal del cliente":
                    !_vm.$v.address.required && _vm.$v.address.$error
                },
                expanded: ""
              }
            },
            [
              _c("b-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.$v.address.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.address, "$model", $$v)
                  },
                  expression: "$v.address.$model"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { grouped: "", "group-multiline": "" } },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Teléfono",
                    type: { "is-danger": _vm.$v.phone_number.$error },
                    message: {
                      "Se requiere introducir el teléfono del cliente":
                        !_vm.$v.phone_number.required &&
                        _vm.$v.phone_number.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    directives: [
                      {
                        name: "cleave",
                        rawName: "v-cleave",
                        value: _vm.masks.phone,
                        expression: "masks.phone"
                      }
                    ],
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.phone_number.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.phone_number, "$model", $$v)
                      },
                      expression: "$v.phone_number.$model"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Correo electrónico (facturación)",
                    type: { "is-danger": _vm.$v.business_email.$error },
                    message: {
                      "Se requiere introducir el correo electrónico de facturación del cliente":
                        !_vm.$v.business_email.required &&
                        _vm.$v.business_email.$error,
                      "Se requiere introducir un correo electrónico válido":
                        !_vm.$v.business_email.email &&
                        _vm.$v.business_email.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.business_email.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.business_email, "$model", $$v)
                      },
                      expression: "$v.business_email.$model"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("b-message", [
            _vm._v(
              "\n            El precio de la mezcla se puede modificar posteriormente desde la pantalla de edición de cliente.\n        "
            )
          ]),
          _vm._v(" "),
          _c("b-field", { attrs: { grouped: "", "group-multiline": "" } }, [
            _c(
              "div",
              { staticClass: "field" },
              [
                _c(
                  "b-checkbox",
                  {
                    model: {
                      value: _vm.pricePerOncologicMixture,
                      callback: function($$v) {
                        _vm.pricePerOncologicMixture = $$v
                      },
                      expression: "pricePerOncologicMixture"
                    }
                  },
                  [
                    _vm._v(
                      "\n                    Oncológicos\n                "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "field" },
              [
                _c(
                  "b-checkbox",
                  {
                    model: {
                      value: _vm.pricePerNutritionalMixture,
                      callback: function($$v) {
                        _vm.pricePerNutritionalMixture = $$v
                      },
                      expression: "pricePerNutritionalMixture"
                    }
                  },
                  [
                    _vm._v(
                      "\n                    Nutricionales\n                "
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.pricePerOncologicMixture || _vm.pricePerNutritionalMixture
            ? _c(
                "b-field",
                { attrs: { grouped: "", "group-multiline": "" } },
                [
                  _vm.pricePerOncologicMixture
                    ? _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Precio por mezcla oncológica",
                            type: {
                              "is-danger": _vm.$v.oncologicMixturePrice.$error
                            },
                            message: {
                              "Se requiere introducir el precio por mezcla oncológica":
                                !_vm.$v.oncologicMixturePrice.required &&
                                _vm.$v.oncologicMixturePrice.$error
                            },
                            expanded: ""
                          }
                        },
                        [
                          _c(
                            "b-field",
                            {
                              attrs: {
                                type: {
                                  "is-danger":
                                    _vm.$v.oncologicMixturePrice.$error
                                }
                              }
                            },
                            [
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "button",
                                  { staticClass: "button is-static" },
                                  [_vm._v("$")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("b-input", {
                                directives: [
                                  {
                                    name: "cleave",
                                    rawName: "v-cleave",
                                    value: _vm.masks.numeral,
                                    expression: "masks.numeral"
                                  }
                                ],
                                attrs: {
                                  value: _vm.$v.oncologicMixturePrice.$model,
                                  type: "text"
                                },
                                nativeOn: {
                                  input: function($event) {
                                    return _vm.onInputOncologicMixturePrice(
                                      $event
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "button",
                                  { staticClass: "button is-static" },
                                  [_vm._v("MXN")]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pricePerNutritionalMixture
                    ? _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Precio por mezcla nutricional",
                            type: {
                              "is-danger": _vm.$v.nutritionalMixturePrice.$error
                            },
                            message: {
                              "Se requiere introducir el precio por mezcla nutricional":
                                !_vm.$v.nutritionalMixturePrice.required &&
                                _vm.$v.nutritionalMixturePrice.$error
                            },
                            expanded: ""
                          }
                        },
                        [
                          _c(
                            "b-field",
                            {
                              attrs: {
                                type: {
                                  "is-danger":
                                    _vm.$v.nutritionalMixturePrice.$error
                                }
                              }
                            },
                            [
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "button",
                                  { staticClass: "button is-static" },
                                  [_vm._v("$")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("b-input", {
                                directives: [
                                  {
                                    name: "cleave",
                                    rawName: "v-cleave",
                                    value: _vm.masks.numeral,
                                    expression: "masks.numeral"
                                  }
                                ],
                                attrs: {
                                  value: _vm.$v.nutritionalMixturePrice.$model,
                                  type: "text"
                                },
                                nativeOn: {
                                  input: function($event) {
                                    return _vm.onInputNutritionalMixturePrice(
                                      $event
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "button",
                                  { staticClass: "button is-static" },
                                  [_vm._v("MXN")]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.payer
            ? _c(
                "div",
                [
                  _c("hr"),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("b-message", { staticClass: "is-warning" }, [
                    _c("p", [
                      _vm._v(
                        "Los medicamentos se darán de alta desactivados y con un precio de 0.0, favor de hacer los ajustes previo a dar de alta usuarios ya que de lo contrario no podrán seleccionar los componentes que no estén activados."
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    { attrs: { grouped: "", "group-multiline": "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c(
                            "b-checkbox",
                            {
                              model: {
                                value: _vm.enablePayerOncologicMedicines,
                                callback: function($$v) {
                                  _vm.enablePayerOncologicMedicines = $$v
                                },
                                expression: "enablePayerOncologicMedicines"
                              }
                            },
                            [
                              _vm._v(
                                "\n                        Oncológicos\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c(
                            "b-checkbox",
                            {
                              model: {
                                value: _vm.enablePayerNutritionalMedicines,
                                callback: function($$v) {
                                  _vm.enablePayerNutritionalMedicines = $$v
                                },
                                expression: "enablePayerNutritionalMedicines"
                              }
                            },
                            [
                              _vm._v(
                                "\n                        Nutricionales\n                    "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("b-field", { attrs: { grouped: "", position: "is-centered" } }, [
            _c("p", { staticClass: "control" }, [
              _c(
                "button",
                { staticClass: "button is-primary", attrs: { type: "submit" } },
                [
                  _vm.payer
                    ? _c("span", [
                        _vm._v(
                          "\n                        Editar cliente\n                    "
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          "\n                        Agregar cliente\n                    "
                        )
                      ])
                ]
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n            3. "),
      _c("strong", [_vm._v("OPCIONAL.")]),
      _vm._v(
        " Seleccione en su caso si el cobró al cliente se le realizará por mezcla\n            "
      ),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "Se puede seleccionar cobro por mezcla nutricional, oncológica o ambas, así como asignar el precio de manera individual."
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n                4. Seleccione los tipos de medicamento para dar de alta al cliente\n                "
      ),
      _c("br"),
      _vm._v(" "),
      _c("small", [
        _vm._v(
          "Se darán de alta todos los medicamentos en existencia en el sistema del tipo seleccionado."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }