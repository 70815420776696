const getDefaultState = () => {
    return {
        shopOrders: []
    };
};

const state = getDefaultState();

const mutations = {
    SET_SHOP_ORDERS (state, data) {
        state.shopOrders = data;
    },
};

const actions = {
    
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
  