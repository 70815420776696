<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <h1 class="title">Actualización de intentos de impresión</h1>
            </div>
            <div class="level-right" />
        </nav>
        <div class="row">
            <div class="col-md-6">
                <form @submit.prevent="submitUpdateNut">
                    <p>Ingrese el número de lote Nutricional</p>
                    <b-field
                    label="Número de Lote"
                    expanded>
                        <b-input
                            v-model="loteNut"
                            type="text">
                        </b-input>
                    </b-field>
                    <button type="submit" class="button is-primary">
                        Enviar
                    </button>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <form @submit.prevent="submitUpdateOnc">
                    <p>Ingrese el número de lote Oncologico</p>
                    <b-field
                    label="Número de Lote"
                    expanded>
                        <b-input
                            v-model="loteOnc"
                            type="text">
                        </b-input>
                    </b-field>
                    <button type="submit" class="button is-primary">
                        Enviar
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>
 
<script>
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';

    export default {
        props: [
        'id',
        'printed_opportunities_used',
        'mixture_state_id'
        ],
        data() {
            return {
                loteNut: '',
                loteOnc: '',
                printedOpportunitiesUsed: '0',
                mixtureStateId: '6',
            }
        },
        methods: {
            submitUpdateNut () {
            // this.$v.$touch();
                if (this.loteNut!= '') 
                {
                    this.updateMixtureNut();
                }

            },
            submitUpdateOnc () {
            // this.$v.$touch();
                if (this.loteOnc!= '') 
                {
                    this.updateMixtureOnc();
                }

            },
            getRequestParamsNut () {
                console.log(this.loteNut);
                const params = {
                    id: this.loteNut,
                    printed_opportunities_used: this.printedOpportunitiesUsed,
                    mixture_state_id: this.mixtureStateId,
            };
            return params;
            },
            getRequestParamsOnc () {
                console.log(this.loteOnc);
                const params = {
                    id: this.loteOnc,
                    printed_opportunities_used: this.printedOpportunitiesUsed,
                    mixture_state_id: this.mixtureStateId,
            };
            return params;
            },
            updateMixtureNut() {
                var url = '/api/mixtures/nutritional/' + this.loteNut;
                this.makeRequest( url,  this.$axios.put, this.getRequestParamsNut() );
            },
            updateMixtureOnc() {
                var url = '/api/mixtures/oncologic/' + this.loteOnc;
                this.makeRequest( url,  this.$axios.put, this.getRequestParamsOnc() );
            },
            makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method(url,params)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha editado el lote'
                });
                this.$serbitec.finishLoading();
                this.$emit('success');
                this.$emit('close');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
            }
        },
        validations () {
        const validations = {
            lote: {
                required
            }
        };
        }
    }
    
</script>