<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right"/>
        </nav>
        <hr>
        <serbitec-deleted-users-table
        :payerUsers="internalUsers"
        :payer-users-count="internalUsersCount"

        :loading.sync="loading"
        :page.sync="page"
        :pageSize="pageSize"
        :sortField.sync="sortField"
        :sortOrder.sync="sortOrder"

        @page-change="onPageChange"
        @sort="onSort"
        @restore-user="restoreUser"></serbitec-deleted-users-table>
    </div>
</template>

<script>
import serbitecDeletedUsersTable from '../../../../components/dashboard/payers/deleted-users-table.vue';
import serbitecBreadcumbs from '../../../../components/dashboard/utils/breadcumbs.vue';

import axios from 'axios';
import gql from 'graphql-tag';
const query = gql` query ( $page: Int, $limit: Int, $order_by: String, $order_direction: String )
{
    users: paginated_users( is_internal:true, page: $page, limit: $limit, order_by: $order_by, order_direction: $order_direction, trashed: true ) {
        total
        data {
            id
            first_name
            last_name_1
            last_name_2
            types {
                id
                name
            }
        }
    }
}`;

export default {
    components: {
        serbitecDeletedUsersTable,
        serbitecBreadcumbs
    },
    data (){
        return {
            internalUsersCount: null,
            loading: false,
            page: 1,
            pageSize: 10,
            sortField: 'first_name',
            sortOrder:'asc',
            addUserFormIsActive: false,
            route: [
                {
                    label: 'Usuarios internos',
                    to: '/internal_users',
                    active: false
                },
                {
                    label: 'Eliminados',
                    to: '/internal_users/deleted',
                    active: true
                }
            ]
        };
    },
    methods: {
        onPageChange( page ) {
            this.page = page;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
        restoreUser( userId ){
            this.makeRequest('/api/users/restore/' + userId, axios.post, {}, 'restoreUser');
        },
        async makeRequest( url, method, params, comesFrom ){
            this.loading = true;
            try {
                await method(url,params);
                if ( comesFrom == 'restoreUser' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha reestablecido el usuario correctamente'
                    });
                }
                this.$apollo.queries.internalUsers.refresh();
                this.loading = false;
            } catch (error) {
                console.error(error);
                this.$notify({
                    title: '¡Algo ha sucedido!',
                    type: 'error',
                    text: 'Contacte el administrador'
                });
                this.loading = false;
            }
        }
    },
    apollo: {
        internalUsers: {
            query: query,
            variables () {
                return {
                    page: this.page,
                    limit: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder,
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                this.payerUsersCount = data.users.total;
                return data.users.data;
            }
        },
    }
};
</script>