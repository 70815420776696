<template>
    <div class="columns is-centered">
        <div
            class="column"
            :class="{
                'is-three-fifths': $mq === 'desktop',
                'is-half': $mq === 'touch'
            }"
        >
            <p class="control">
                <router-link
                    :to="{ path:'/login'}"
                    type="submit"
                    class="button"
                >
                    <span class="icon">
                        <font-awesome-icon icon="caret-left" />
                    </span>
                    <span>
                        Regresar
                    </span>
                </router-link>
            </p>
            <serbitec-forgot-password-form
                class="serbitec-small-top-margin"
            />
        </div>
    </div>
</template>

<script>
import serbitecForgotPasswordForm from '../../components/landing/forgot-password-form.vue';

export default {
    components: {
        serbitecForgotPasswordForm
    }
};
</script>