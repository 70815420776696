<template>
    <div class="box">
        <b-table
            :data="flasks"
            :loading="loading"

            paginated
            backend-pagination
            :total="flasksCount"
            :per-page="pageSize"
            @page-change="changePage"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="sort">

            <template slot-scope="props">
                <b-table-column field="id" label="Lote" numeric sortable>
                    <p>LO-{{ props.row.id }}</p>
                </b-table-column>

                <b-table-column label="Orden" numeric>
                    <p>OO-{{ props.row.order.id }}</p>
                </b-table-column>

                <b-table-column label="Paciente">
                    {{ props.row.order.patient.name }}
                </b-table-column>

                <b-table-column label="Fecha de entrega">
                    <p>{{ formatDate( props.row.order.delivery_date ) }}</p>
                </b-table-column>

                <b-table-column label="Inspección calidad" centered>
                    <router-link
                    v-if="props.row.state.id == 5"
                    class="button is-light is-small"
                    :to="{ path: 'flask_inspection', query: { flaskId: props.row.id } }"
                    append
                >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="clipboard-check" />
                        </span>
                    </router-link>
                    <a
                    v-else
                    class="button is-light is-small"
                    disabled>
                        <span class="icon is-medium">
                            <font-awesome-icon icon="clipboard-check" />
                        </span>
                    </a>
                </b-table-column>

                <b-table-column label="Frasco acondicionado" centered>
                    <a
                    v-if="props.row.state.id == 6"
                    class="button is-light is-small"
                    @click="$emit( 'flask-aconditioned', props.row.id )">
                        <span class="icon is-medium">
                            <font-awesome-icon icon="check-circle" />
                        </span>
                    </a>
                    <a
                    v-else
                    class="button is-light is-small"
                    disabled>
                        <span class="icon is-medium">
                            <font-awesome-icon icon="check-circle" />
                        </span>
                    </a>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay frascos pendientes.</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { es } from 'date-fns/locale';

export default {
    props: [
        'qualityType',
        'flasks',
        'loading',
        'flasksCount',
        'page',
        'pageSize',
        'sortField',
        'sortOrder'
    ],
    methods: {
        formatDate( date ) {
            return format( parseISO(date), "d 'de' MMMM yyyy '|' HH:mm", { locale: es } );
        },
        changePage ( page ) {
            this.$emit('page-change', page);
        },
        sort ( field, order ) {
            this.$emit('sort', field, order);
        }
    }
};
</script>