var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.isEmpty ? [] : _vm.internalWarehouseItems,
            loading: _vm.loading,
            paginated: "",
            "backend-pagination": "",
            total: _vm.internalWarehouseItemsCount,
            "per-page": _vm.pageSize,
            "aria-next-label": "Siguiente página",
            "aria-previous-label": "Página anterior",
            "aria-page-label": "Página",
            "aria-current-label": "Página actual",
            "backend-sorting": ""
          },
          on: { "page-change": _vm.onPageChange, sort: _vm.onSort },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "batch",
                        label: "Lote",
                        numeric: "",
                        sortable: ""
                      }
                    },
                    [
                      _c("p", [
                        props.row.prefix
                          ? _c("strong", [
                              _vm._v(_vm._s(props.row.prefix.prefix) + "-")
                            ])
                          : _vm._e(),
                        _vm._v(" " + _vm._s(props.row.batch))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Nombre" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          props.row.medicine_presentation.medicine
                            .commercial_name
                        ) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Fabricante" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          props.row.medicine_presentation.medicine.manufacturer
                            .name
                        ) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Presentación", centered: "" } },
                    [
                      props.row.medicine_presentation.medicine.component
                        .component_type.id == 8
                        ? _c("b-tag", [_vm._v("pieza")])
                        : props.row.medicine_presentation.medicine.component
                            .component_type.id == 1
                        ? _c("b-tag", [
                            _vm._v(
                              _vm._s(
                                props.row.medicine_presentation
                                  .component_quantity
                              ) +
                                " " +
                                _vm._s(
                                  props.row.medicine_presentation.medicine
                                    .component_unit.symbol
                                ) +
                                " en " +
                                _vm._s(
                                  props.row.medicine_presentation.volume_in_ml
                                ) +
                                " ml"
                            )
                          ])
                        : _c("b-tag", [
                            _vm._v(
                              _vm._s(
                                props.row.medicine_presentation.volume_in_ml
                              ) + " ml"
                            )
                          ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.enableWeeklyCycleInput
                    ? _c(
                        "b-table-column",
                        {
                          attrs: { label: "Fecha de caducidad", centered: "" }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(props.row.expiry_date) +
                              "\n            "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Existencias", centered: "" } },
                    [_c("p", [_vm._v(_vm._s(_vm.setItemQuantity(props.row)))])]
                  ),
                  _vm._v(" "),
                  _vm.enableWeeklyCycleInput
                    ? _c(
                        "b-table-column",
                        { attrs: { label: "Ajuste", centered: "" } },
                        [
                          _c(
                            "b-field",
                            { attrs: { position: "is-centered" } },
                            [
                              _vm.isPieceItem(props.row)
                                ? _c("b-input", {
                                    directives: [
                                      {
                                        name: "cleave",
                                        rawName: "v-cleave",
                                        value: _vm.masks.pieces,
                                        expression: "masks.pieces"
                                      }
                                    ],
                                    model: {
                                      value: props.row.adjustedPieceQuantity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          props.row,
                                          "adjustedPieceQuantity",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "props.row.adjustedPieceQuantity"
                                    }
                                  })
                                : _vm.isOncologic
                                ? _c("b-input", {
                                    directives: [
                                      {
                                        name: "cleave",
                                        rawName: "v-cleave",
                                        value: _vm.masks.custom,
                                        expression: "masks.custom"
                                      }
                                    ],
                                    model: {
                                      value:
                                        props.row.adjustedOncologicQuantityInMl,
                                      callback: function($$v) {
                                        _vm.$set(
                                          props.row,
                                          "adjustedOncologicQuantityInMl",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "props.row.adjustedOncologicQuantityInMl"
                                    }
                                  })
                                : _vm.isNutritional
                                ? _c("b-input", {
                                    directives: [
                                      {
                                        name: "cleave",
                                        rawName: "v-cleave",
                                        value: _vm.masks.custom,
                                        expression: "masks.custom"
                                      }
                                    ],
                                    model: {
                                      value:
                                        props.row
                                          .adjustedNutritionalQuantityInMl,
                                      callback: function($$v) {
                                        _vm.$set(
                                          props.row,
                                          "adjustedNutritionalQuantityInMl",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "props.row.adjustedNutritionalQuantityInMl"
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _vm.isPieceItem(props.row)
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "button is-static",
                                        attrs: { outlined: "" }
                                      },
                                      [_vm._v("pz")]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "button is-static",
                                        attrs: { outlined: "" }
                                      },
                                      [_vm._v("ml")]
                                    )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.quantityMismatch(props.row)
                            ? _c("b-tag", { staticClass: "is-danger" }, [
                                _vm._v("Modificado")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("No hay elementos existentes en almacén"),
                    _c("br"),
                    _c("small", [
                      _vm._v(
                        "Para agregar realice una solicitud al almacén externo"
                      )
                    ])
                  ])
                ]
              )
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _vm.comesFromCycle && _vm.internalWarehouseItems
        ? _c("b-field", { attrs: { grouped: "", position: "is-centered" } }, [
            _c("p", { staticClass: "control" }, [
              _vm.hasAdjustments
                ? _c(
                    "button",
                    {
                      staticClass: "button is-primary",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "submit" },
                      on: { click: _vm.submitWarehouseCycle }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "icon" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "check-circle" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v("Hacer corte de almacén")])
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "button is-primary",
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "submit", disabled: "" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "icon" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "check-circle" }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", [_vm._v("Hacer corte de almacén")])
                    ]
                  )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }