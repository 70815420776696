<template>
    <div v-if="payer">
        <b-loading :active.sync="loading"></b-loading>
        <b-message>
            <h1 class="subtitle">
                <strong>Cliente: </strong>{{ payer.invoicing_data.business_name }}
                <br>
                <small><strong>Dirección: </strong>{{ payer.invoicing_data.address }}</small>
            </h1>
        </b-message>
    </div>
</template>

<script>
import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    payer( id: $id ) {
        id
        invoicing_data {
            id
            business_name
            address
        }
    }
}`;

export default {
    props: ['payerId'],
    data (){
        return {
            loading: false,
        };
    },
    apollo: {
        payer: {
            fetchPolicy: 'network-only',
            query: query,
            variables () {
                return {
                    id: this.payerId
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                return data.payer;
            }
        },
    }
};
</script>