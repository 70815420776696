var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("serbitec-mixtures-table", {
        attrs: {
          "preparer-type": "oncologic",
          "mixtures-count": _vm.mixturesCount,
          page: _vm.page,
          "page-size": _vm.pageSize,
          "sort-field": _vm.sortField,
          "sort-order": _vm.sortOrder,
          mixtures: _vm.mixtures
        },
        on: {
          "update:mixturesCount": function($event) {
            _vm.mixturesCount = $event
          },
          "update:mixtures-count": function($event) {
            _vm.mixturesCount = $event
          },
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:pageSize": function($event) {
            _vm.pageSize = $event
          },
          "update:page-size": function($event) {
            _vm.pageSize = $event
          },
          "update:sortField": function($event) {
            _vm.sortField = $event
          },
          "update:sort-field": function($event) {
            _vm.sortField = $event
          },
          "update:sortOrder": function($event) {
            _vm.sortOrder = $event
          },
          "update:sort-order": function($event) {
            _vm.sortOrder = $event
          },
          "update:mixtures": function($event) {
            _vm.mixtures = $event
          },
          "page-change": _vm.onPageChange,
          sort: _vm.onSort
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { staticClass: "level is-mobile" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("div", { staticClass: "level-item" }, [
          _c("h1", { staticClass: "title" }, [
            _vm._v("Área de preparación de mezclas oncológicas")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }