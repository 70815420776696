var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-field",
    { staticClass: "serbitec-small-left-margin" },
    [
      _c(
        "b-checkbox-button",
        {
          attrs: { "native-value": _vm.oncologicType },
          on: { input: _vm.updateComponentTypes },
          model: {
            value: _vm.componentTypes,
            callback: function($$v) {
              _vm.componentTypes = $$v
            },
            expression: "componentTypes"
          }
        },
        [_c("span", [_vm._v("Oncológico")])]
      ),
      _vm._v(" "),
      _c(
        "b-checkbox-button",
        {
          attrs: { "native-value": _vm.nutritionalType },
          on: { input: _vm.updateComponentTypes },
          model: {
            value: _vm.componentTypes,
            callback: function($$v) {
              _vm.componentTypes = $$v
            },
            expression: "componentTypes"
          }
        },
        [_c("span", [_vm._v("Nutricional")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }