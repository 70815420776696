<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right"></div>
        </nav>
        <hr>
        <div v-if="elements">
            <div class="box">
                <h1 class="title has-text-centered">Orden: ON-{{ orderId }} | Lote: LN-{{ mixtureId }}</h1>
            </div>
            <serbitec-horizontal-order-data
                :medic="medic"
                :patient="patient"
            />
            <div class="columns">
                <div class="column is-half is-size-4">
                    Volumen total: <strong>{{ totalVolumeInMl }} ml</strong>
                </div>
                <div class="column is-half is-size-4">
                    Sobrellenado: <strong>{{ overfillInMl }} ml</strong>
                </div>
            </div>
            <serbitec-nutritional-preparer-order
                :elements="sortedElements()"
                :has-infusion-set="hasInfusionSet"
                @mixture-prepared="submitMixturePrepared"
            />
        </div>
    </div>
</template>

<script>
import serbitecHorizontalOrderData from '../../../components/dashboard/utils/horizontal-order-data.vue';
import serbitecNutritionalPreparerOrder from '../../../components/dashboard/preparation-area/nutritional-preparer-order.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';
import axios from 'axios';
import gql from 'graphql-tag';

const query = gql` query ( $id: Int! )
{
    nutritional_mixture( id: $id ) {
        id
        total_volume_in_ml
        overfill_in_ml
        elements {
            id
            medicine_quantity
            component {
                id
                name
                component_type {
                    id
                }
            }
            warehouse_items {
                id
                prefix {
                    id
                    prefix
                }
                batch
                pivot {
                    id
                    medicine_warehouse_item_quantity
                }
                medicine_presentation {
                    id
                    volume_in_ml
                    component_quantity
                    medicine {
                        id
                        component {
                            id
                            name
                        }
                        component_unit {
                            id
                            symbol
                        }
                        commercial_name
                    }
                }
            }
        }
        order {
            id
            medic {
                id
                first_name
                last_name_1
                last_name_2
                email
                medical_card
                payer {
                    id
                    invoicing_data {
                        id
                        business_name
                    }
                }
            }
            patient {
                id
                name
                diagnosis
                height_in_meters
                weight_in_kilograms
                birth_date
                file_id
                location
                bed
            }
        }
    }
}`;

export default {
    props: {
        mixtureId: {
            type: [Number, String],
            default: 0 | '0'
        }
    },
    components: {
       serbitecHorizontalOrderData ,
       serbitecNutritionalPreparerOrder,
       serbitecBreadcumbs
    },
    data () {
        return {
            medic: null,
            patient: null,
            totalVolumeInMl: null,
            overfillInMl: '0',
            orderId: null,
            hasInfusionSet: false,
            route: [
                {
                    label: 'Área de preparación de mezclas nutricionales',
                    to: '/nutritional_preparation_area',
                    active: false
                },
                {
                    label: 'Preparar mezcla',
                    to: '/nutritional_preparation_area/prepare',
                    active: true
                }
            ]
        };
    },
    methods: {
        sortedElements () {
            var componentNames = [
                { componentName: 'aminoacidos'},
                { componentName:'glutamina'},
                { componentName: 'dextrosa'},
                { componentName: 'agua'},
                { componentName: 'cloruro de sodio 0.9%'},
                { componentName: 'fosfato de potasio'},
                { componentName: 'acetato de sodio'},
                { componentName: 'acetato de potasio'},
                { componentName: 'cloruro de sodio 3'},
                { componentName: 'cloruro de potasio'},
                { componentName: 'sulfato de magnesio'},
                { componentName: 'gluconato de calcio'},
                { componentName: 'oligoelementos'},
                { componentName: 'zinc'},
                { componentName: 'selenio'},
                { componentName: 'manganeso'},
                { componentName: 'cromo'},
                { componentName: 'vitamina k'},
                { componentName: 'multivitaminico'},
                { componentName: 'carnitina'},
                { componentName: 'cisteina'},
                { componentName: 'vitamina c'},
                { componentName: 'acido folinico'},
                { componentName: 'insulina'},
                { componentName: 'heparina'},
                { componentName: 'albumina'},
                { componentName: 'grasos omega'},
                { componentName: 'lipidos'}
            ];
            var sortedComponents = [];
            componentNames.map( name => {
                this.elements.map( element => {
                    if ( element.componentName.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().includes( name.componentName ) ) {
                        Object.assign( name, element );
                        sortedComponents.push(name);
                    }
                });

            } );
            return sortedComponents;
        },
        getRequestParams () {
            const requestParams = {
                new_state_id: 5
            };
            return requestParams;
        },
        submitMixturePrepared () {
            var url = '/api/nutritional/mixtures/' + this.mixtureId + '/state';
            this.makeRequest( url, axios.post, this.getRequestParams() );
        },
        makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method( url, params )
            .then(function () {
                this.$serbitec.finishLoading();
                this.$router.push({ path: '/nutritional_preparation_area', query: { success: true } });
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$serbitec.finishLoading();
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
        }
    },
    apollo: {
        elements: {
            query: query,
            fetchPolicy: 'no-cache',
            variables () {
                return {
                    id: this.mixtureId
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update ( data ) {
                this.medic = data.nutritional_mixture.order.medic;
                this.patient = data.nutritional_mixture.order.patient;
                this.totalVolumeInMl = data.nutritional_mixture.total_volume_in_ml;
                this.overfillInMl = data.nutritional_mixture.overfill_in_ml;
                this.orderId = data.nutritional_mixture.order.id;
                var elementWithMedicine = [];
                data.nutritional_mixture.elements.map( element => {
                    const overfillFactor = ( data.nutritional_mixture.total_volume_in_ml + data.nutritional_mixture.overfill_in_ml ) / data.nutritional_mixture.total_volume_in_ml;
                    var obj = {};
                    obj.componentName = element.component.name;
                    obj.quantityInMedicineUnit = ( element.medicine_quantity * overfillFactor ).toFixed(3);

                    if ( element.component.component_type.id == 8 ) {
                        this.hasInfusionSet = true;
                    }

                    obj.isPiece = false;
                    if ( element.component.component_type.id == 8 || element.component.component_type.id == 12 ) {
                        obj.isPiece = true;
                    }

                    const warehouseItems = [];
                    element.warehouse_items.map( warehouseItem => {
                        const item = {};
                        item.prefix = warehouseItem.prefix;
                        item.batch = warehouseItem.batch;
                        item.medicineName = warehouseItem.medicine_presentation.medicine.commercial_name;
                        item.medicinePresentationQuantity = warehouseItem.medicine_presentation.component_quantity;
                        item.medicinePresentationQuantityUnit = warehouseItem.medicine_presentation.medicine.component_unit.symbol;
                        item.medicinePresentationVolumeInMl = warehouseItem.medicine_presentation.volume_in_ml;
                        item.medicineQuantityInMl = warehouseItem.pivot.medicine_warehouse_item_quantity;

                        warehouseItems.push(item);
                    });
                    obj.warehouseItems = warehouseItems;
                    elementWithMedicine.push( obj );
                });
                return elementWithMedicine;
            },
        }
    }
};
</script>