import serbitecLogin from './pages/landing/login.vue';
import sertibecDashboardHome from './pages/dashboard/index.vue';
import serbitecPayers from './pages/dashboard/payers/index.vue';
import serbitecAddPayer from './pages/dashboard/payers/add.vue';
import serbitecPayerConfiguration from './pages/dashboard/payers/configuration.vue';
import serbitecPayerMedicines from './pages/dashboard/payers/medicines.vue';
import serbitecPayerMedicinePresentations from './pages/dashboard/payers/medicine-presentations.vue';
import serbitecPayerUsers from './pages/dashboard/payers/users.vue';
import serbitecEditPayerUser from './pages/dashboard/payers/users-edit.vue';
import serbitecDeletedPayerUsers from './pages/dashboard/payers/users-deleted.vue';
import serbitecEditPayer from './pages/dashboard/payers/edit.vue';
import serbitecInternalUsers from './pages/dashboard/internal-users/index.vue';
import serbitecDeletedInternalUsers from './pages/dashboard/internal-users/deleted/index.vue';
import serbitecMedicineManufacturers from './pages/dashboard/medicine-manufacturers/index.vue';
import serbitecComponents from './pages/dashboard/components/index.vue';
import serbitecOncologicMedicineCatalog from './pages/dashboard/oncologic-medicine-catalog/index.vue';
import serbitecOncologicMedicineThinners from './pages/dashboard/oncologic-medicine-catalog/thinners.vue';
import serbitecOncologicMedicinePresentations from './pages/dashboard/oncologic-medicine-catalog/presentations.vue';
import serbitecNutritionalMedicineCatalog from './pages/dashboard/nutritional-medicine-catalog/index.vue';
import serbitecNutritionalMedicinePresentations from './pages/dashboard/nutritional-medicine-catalog/presentations.vue';
import serbitecWarehouse from './pages/dashboard/warehouse/index.vue';
import serbitecWarehousePurchaseOrders from './pages/dashboard/warehouse/purchase-orders.vue';
import serbitecWarehouseRequests from './pages/dashboard/warehouse-requests/index.vue';
import serbitecNutritionalMixturesRecords from './pages/dashboard/nutritional-mixtures-records/index.vue';
import serbitecNutritionalMixtureDetails from './pages/dashboard/nutritional-mixtures-records/mixture-details.vue';
import serbitecOncologicOrdersRecords from './pages/dashboard/oncologic-orders-records/index.vue';
import serbitecOncologicOrderDetails from './pages/dashboard/oncologic-orders-records/order-details.vue';
import serbitecOncologicOrdersAuthorizer from './pages/dashboard/oncologic-orders-authorizer/index.vue';
import serbitecAuthorizeOncologicOrder from './pages/dashboard/oncologic-orders-authorizer/authorize.vue';
import serbitecEditOncologicOrder from './pages/dashboard/oncologic-orders-authorizer/edit.vue';
import serbitecOncologicPreparationAreaSupplies from './pages/dashboard/oncologic-preparation-area-supplies/index.vue';
import serbitecOncologicPreparationAreaRequestMedicines from './pages/dashboard/oncologic-preparation-area-supplies/request-medicines.vue';
import serbitecOncologicPreparationAreaReceiveMedicines from './pages/dashboard/oncologic-preparation-area-supplies/receive-medicines.vue';
import serbitecOncologicPreparationAreaUpdateSupplies from './pages/dashboard/oncologic-preparation-area-supplies/update.vue';
import serbitecOncologicPreparationArea from './pages/dashboard/oncologic-preparation-area/index.vue';
import serbitecOncologicPreparationAreaSelectSupplies from './pages/dashboard/oncologic-preparation-area/select-supplies.vue';
import serbitecOncologicPreparationAreaPrepare from './pages/dashboard/oncologic-preparation-area/prepare.vue';
import serbitecOncologicOrdersQualityControl from './pages/dashboard/oncologic-orders-quality-control/index.vue';
import serbitecOncologicOrderMixtureInspection from './pages/dashboard/oncologic-orders-quality-control/mixture-inspection.vue';
import serbitecOncologicFlaskInspection from './pages/dashboard/oncologic-orders-quality-control/flask-inspection.vue';
import serbitecOncologicOrdersDistribution from './pages/dashboard/oncologic-orders-distribution/index.vue';
import serbitecNutritionalMixturesAuthorizer from './pages/dashboard/nutritional-mixtures-authorizer/index.vue';
import serbitecAuthorizeNutritionalMixtures from './pages/dashboard/nutritional-mixtures-authorizer/authorize.vue';
import serbitecEditNutritionalMixtures from './pages/dashboard/nutritional-mixtures-authorizer/edit.vue';
import serbitecNutritionalPreparationAreaSupplies from './pages/dashboard/nutritional-preparation-area-supplies/index.vue';
import serbitecNutritionalPreparationAreaRequestMedicines from './pages/dashboard/nutritional-preparation-area-supplies/request-medicines.vue';
import serbitecNutritionalPreparationAreaReceiveMedicines from './pages/dashboard/nutritional-preparation-area-supplies/receive-medicines.vue';
import serbitecNutritionalPreparationAreaUpdateSupplies from './pages/dashboard/nutritional-preparation-area-supplies/update.vue';
import serbitecNutritionalPreparationArea from './pages/dashboard/nutritional-preparation-area/index.vue';
import serbitecNutritionalPreparationAreaSelectSupplies from './pages/dashboard/nutritional-preparation-area/select-supplies.vue';
import serbitecNutritionalPreparationAreaPrepare from './pages/dashboard/nutritional-preparation-area/prepare.vue';
import serbitecNutritionalMixturesQualityControl from './pages/dashboard/nutritional-mixtures-quality-control/index.vue';
import serbitecNutritionalMixtureInspection from './pages/dashboard/nutritional-mixtures-quality-control/mixture-inspection.vue';
import serbitecNutritionalMixturesDistribution from './pages/dashboard/nutritional-mixtures-distribution/index.vue';
import serbitecNutritionalMixturesRequest from './pages/dashboard/nutritional-mixtures-request/index.vue';
import serbitecOncologicOrdersRequest from './pages/dashboard/oncologic-orders-request/index.vue';
import serbitecExternalUserAccount from './pages/dashboard/external-user/account/index.vue';
import serbitecExternalUserPatients from './pages/dashboard/external-user/patients/index.vue';
import serbitecExternalUserNutritionalMixturesRecords from './pages/dashboard/external-user/nutritional-mixtures-records/index.vue';
import serbitecExternalUserNutritionalMixtureDetails from './pages/dashboard/external-user/nutritional-mixtures-records/mixture-details.vue';
import serbitecExternalUserOncologicOrdersRecords from './pages/dashboard/external-user/oncologic-orders-records/index.vue';
import serbitecExternalUserOncologicOrderDetails from './pages/dashboard/external-user/oncologic-orders-records/order-details.vue';
import serbitecReports from './pages/dashboard/reports/index.vue';
import serbitecUpdates from './pages/dashboard/updates/index.vue';
import pageNotFound from './pages/error/page-not-found.vue';
import notAuthorized from './pages/error/not-authorized.vue';
import forgotPassword from './pages/landing/forgot-password.vue';
import resetPassword from './pages/landing/reset-password.vue';
// Middlewares
import auth from './middleware/auth.js';
import checkPermission from './middleware/check-permission.js';

export const routes = [
    // Error pages
    {   path: "*",
        component: pageNotFound
    },
    {   path: "/not-authorized",
        component: notAuthorized
    },
    // Dashboard
    {
        path: '/',
        component: sertibecDashboardHome,
        meta: { 
            middleware: [ auth ] 
        }
    },
    // Login
    {
        path: '/login',
        component: serbitecLogin
    },
    {
        path: '/forgot_password',
        component: forgotPassword
    },
    {
        path: '/reset_password',
        component: resetPassword
    },
    // Users administration
    // ** Payer administration
    {
        path: '/payers',
        component: serbitecPayers,
        meta: { 
            middleware: [ auth, checkPermission ] 
        }
    },
    {
        path: '/internal_users',
        component: serbitecInternalUsers,
        meta: { 
            middleware: [ auth, checkPermission ] 
        }
    },
    {
        path: '/internal_users/deleted',
        component: serbitecDeletedInternalUsers,
        meta: { 
            middleware: [ auth, checkPermission ] 
        }
    },
    {
        path: '/payers/add',
        component: serbitecAddPayer,
        meta: { 
            middleware: [ auth, checkPermission ] 
        }
    },
    {
        path: '/payers/configuration',
        component: serbitecPayerConfiguration,
        meta: { 
            middleware: [ auth, checkPermission ] 
        }
    },
    {
        path: '/payers/medicines',
        component: serbitecPayerMedicines,
        meta: { 
            middleware: [ auth, checkPermission ] 
        }
    },
    {
        path: '/payers/medicines/presentations',
        component: serbitecPayerMedicinePresentations,
        meta: { 
            middleware: [ auth, checkPermission ] 
        }
    },
    {
        path: '/payers/users',
        component: serbitecPayerUsers,
        meta: { 
            middleware: [ auth, checkPermission ] 
        }
    },
    {
        path: '/payers/users/edit',
        component: serbitecEditPayerUser,
        meta: { 
            middleware: [ auth, checkPermission ] 
        }
    },
    {
        path: '/payers/users/deleted',
        component: serbitecDeletedPayerUsers,
        meta: { 
            middleware: [ auth, checkPermission ] 
        }
    },
    {
        path: '/payers/edit',
        component: serbitecEditPayer,
        meta: { 
            middleware: [ auth, checkPermission ] 
        }
    },
    // Medicine catalog
    {
        path: '/medicine_manufacturers',
        component: serbitecMedicineManufacturers,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/components',
        component: serbitecComponents,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_catalog',
        component: serbitecOncologicMedicineCatalog,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_catalog/thinners',
        component: serbitecOncologicMedicineThinners,
        props: (route) => ({ medicineId: route.query.medicineId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_catalog/presentations',
        component: serbitecOncologicMedicinePresentations,
        props: (route) => ({ medicineId: route.query.medicineId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_catalog',
        component: serbitecNutritionalMedicineCatalog,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_catalog/presentations',
        component: serbitecNutritionalMedicinePresentations,
        props: (route) => ({ medicineId: route.query.medicineId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    // Warehouse
    {
        path: '/warehouse',
        component: serbitecWarehouse,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/warehouse/purchase_orders',
        component: serbitecWarehousePurchaseOrders,
        props: (route) => ({ batchId: route.query.batchId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/warehouse_requests',
        component: serbitecWarehouseRequests,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    // New request
    {
        path: '/nutritional_mixtures_request',
        component: serbitecNutritionalMixturesRequest,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_orders_request',
        component: serbitecOncologicOrdersRequest,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    // Nutritional 
    {
        path: '/nutritional_mixtures_records' ,
        component: serbitecNutritionalMixturesRecords,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_mixtures_records/mixture_details' ,
        component: serbitecNutritionalMixtureDetails,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_mixtures_authorizer' ,
        component: serbitecNutritionalMixturesAuthorizer,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_mixtures_authorizer/authorize',
        component: serbitecAuthorizeNutritionalMixtures,
        props: (route) => ({ mixtureId: route.query.mixtureId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_mixtures_authorizer/edit',
        component: serbitecEditNutritionalMixtures,
        props: (route) => ({ mixtureId: route.query.mixtureId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_preparation_area_supplies' ,
        component: serbitecNutritionalPreparationAreaSupplies,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_preparation_area_supplies/request_medicines' ,
        component: serbitecNutritionalPreparationAreaRequestMedicines,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_preparation_area_supplies/receive_medicines' ,
        component: serbitecNutritionalPreparationAreaReceiveMedicines,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_preparation_area_supplies/update' ,
        component: serbitecNutritionalPreparationAreaUpdateSupplies,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_preparation_area' ,
        component: serbitecNutritionalPreparationArea,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_preparation_area/select_supplies' ,
        component: serbitecNutritionalPreparationAreaSelectSupplies,
        props: (route) => ({ mixtureId: route.query.mixtureId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_preparation_area/prepare' ,
        component: serbitecNutritionalPreparationAreaPrepare,
        props: (route) => ({ mixtureId: route.query.mixtureId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_mixtures_quality_control' ,
        component: serbitecNutritionalMixturesQualityControl,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_mixtures_quality_control/mixture_inspection' ,
        component: serbitecNutritionalMixtureInspection,
        props: (route) => ({ mixtureId: route.query.mixtureId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/nutritional_mixtures_distribution' ,
        component: serbitecNutritionalMixturesDistribution,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    // Oncologic
    {
        path: '/oncologic_orders_records' ,
        component: serbitecOncologicOrdersRecords,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_orders_records/order_details' ,
        component: serbitecOncologicOrderDetails,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_orders_authorizer' ,
        component: serbitecOncologicOrdersAuthorizer,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_orders_authorizer/authorize',
        component: serbitecAuthorizeOncologicOrder,
        props: (route) => ({ orderId: route.query.orderId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_orders_authorizer/edit',
        component: serbitecEditOncologicOrder,
        props: (route) => ({ orderId: route.query.orderId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_preparation_area_supplies' ,
        component: serbitecOncologicPreparationAreaSupplies,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_preparation_area_supplies/request_medicines' ,
        component: serbitecOncologicPreparationAreaRequestMedicines,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_preparation_area_supplies/receive_medicines' ,
        component: serbitecOncologicPreparationAreaReceiveMedicines,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_preparation_area_supplies/update' ,
        component: serbitecOncologicPreparationAreaUpdateSupplies,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_preparation_area' ,
        component: serbitecOncologicPreparationArea,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_preparation_area/select_supplies' ,
        component: serbitecOncologicPreparationAreaSelectSupplies,
        props: (route) => ({ mixtureId: route.query.mixtureId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_preparation_area/prepare' ,
        component: serbitecOncologicPreparationAreaPrepare,
        props: (route) => ({ mixtureId: route.query.mixtureId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_orders_quality_control' ,
        component: serbitecOncologicOrdersQualityControl,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_orders_quality_control/mixture_inspection' ,
        component: serbitecOncologicOrderMixtureInspection,
        props: (route) => ({ mixtureId: route.query.mixtureId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_orders_quality_control/flask_inspection' ,
        component: serbitecOncologicFlaskInspection,
        props: (route) => ({ flaskId: route.query.flaskId }),
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/oncologic_orders_distribution' ,
        component: serbitecOncologicOrdersDistribution,
        meta: { 
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/reports',
        component: serbitecReports,
        meta: {
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/updates',
        component: serbitecUpdates,
        meta: {
            middleware: [ auth, checkPermission ]
        }
    },
    // External user
    {
        path: '/external_user/account',
        component: serbitecExternalUserAccount,
        meta: {
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/external_user/patients',
        component: serbitecExternalUserPatients,
        meta: {
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/external_user/nutritional_mixtures_records',
        component: serbitecExternalUserNutritionalMixturesRecords,
        meta: {
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/external_user/nutritional_mixtures_records/mixture_details',
        component: serbitecExternalUserNutritionalMixtureDetails,
        props: (route) => ({ mixtureId: route.query.mixtureId }),
        meta: {
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/external_user/oncologic_orders_records',
        component: serbitecExternalUserOncologicOrdersRecords,
        meta: {
            middleware: [ auth, checkPermission ]
        }
    },
    {
        path: '/external_user/oncologic_orders_records/order_details',
        component: serbitecExternalUserOncologicOrderDetails,
        props: (route) => ({ orderId: route.query.orderId }),
        meta: {
            middleware: [ auth, checkPermission ]
        }
    }
];
