var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submitUpdateNut($event)
              }
            }
          },
          [
            _c("p", [_vm._v("Ingrese el número de lote Nutricional")]),
            _vm._v(" "),
            _c(
              "b-field",
              { attrs: { label: "Número de Lote", expanded: "" } },
              [
                _c("b-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.loteNut,
                    callback: function($$v) {
                      _vm.loteNut = $$v
                    },
                    expression: "loteNut"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "button is-primary", attrs: { type: "submit" } },
              [_vm._v("\n                    Enviar\n                ")]
            )
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.submitUpdateOnc($event)
              }
            }
          },
          [
            _c("p", [_vm._v("Ingrese el número de lote Oncologico")]),
            _vm._v(" "),
            _c(
              "b-field",
              { attrs: { label: "Número de Lote", expanded: "" } },
              [
                _c("b-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.loteOnc,
                    callback: function($$v) {
                      _vm.loteOnc = $$v
                    },
                    expression: "loteOnc"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "button is-primary", attrs: { type: "submit" } },
              [_vm._v("\n                    Enviar\n                ")]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { staticClass: "level" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("Actualización de intentos de impresión")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }