var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.macronutrients
    ? _c(
        "div",
        [
          _c("b-loading", {
            attrs: { active: _vm.loading },
            on: {
              "update:active": function($event) {
                _vm.loading = $event
              }
            }
          }),
          _vm._v(" "),
          _c("h1", { staticClass: "subtitle" }, [_vm._v("Macronutrientes")]),
          _vm._v(" "),
          _c("serbitec-macronutrients-table", {
            attrs: { tableData: _vm.macronutrients }
          }),
          _vm._v(" "),
          _c(
            "form",
            {
              attrs: { novalidate: "true" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submitForm($event)
                }
              }
            },
            _vm._l(_vm.macronutrients, function(component, index) {
              return _c(
                "div",
                { key: index, staticStyle: { "margin-bottom": "15px" } },
                [
                  component.componentsAvailable.length > 0
                    ? _c(
                        "b-field",
                        {
                          attrs: {
                            message: [
                              component.componentTypeId != 7
                                ? "Seleccione el componente e introduzca la cantidad del componente en ml, g o g/Kg"
                                : "Seleccione el componente e introduzca la cantidad de bolsas deseada"
                            ],
                            label: component.name,
                            expanded: ""
                          }
                        },
                        [
                          _c(
                            "b-field",
                            [
                              component.selectedComponent
                                ? _c("p", { staticClass: "control" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "button",
                                        on: {
                                          click: function($event) {
                                            return _vm.unselectComponent(
                                              component.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "icon is-small" },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: "times-circle" }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-select",
                                {
                                  attrs: {
                                    value: component.selectedComponent,
                                    placeholder: "Introduzca el componente",
                                    expanded: ""
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.updateSelectedComponent(
                                        $event,
                                        component.id
                                      )
                                    }
                                  }
                                },
                                _vm._l(component.componentsAvailable, function(
                                  option
                                ) {
                                  return _c(
                                    "option",
                                    {
                                      key: option.id,
                                      domProps: { value: option }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(option.name) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                }),
                                0
                              ),
                              _vm._v(" "),
                              component.selectedComponent &&
                              component.componentTypeId != 7
                                ? _c("b-input", {
                                    directives: [
                                      {
                                        name: "cleave",
                                        rawName: "v-cleave",
                                        value: _vm.masks.custom,
                                        expression: "masks.custom"
                                      }
                                    ],
                                    attrs: {
                                      name: "component.quantity",
                                      value: component.quantity,
                                      type: "text"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.updateComponentQuantity(
                                          $event,
                                          component.id
                                        )
                                      }
                                    }
                                  })
                                : component.selectedComponent
                                ? _c("b-input", {
                                    directives: [
                                      {
                                        name: "cleave",
                                        rawName: "v-cleave",
                                        value: _vm.masks.pieces,
                                        expression: "masks.pieces"
                                      }
                                    ],
                                    attrs: {
                                      name: "component.quantity",
                                      value: component.quantity,
                                      type: "text"
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.updateComponentQuantity(
                                          $event,
                                          component.id
                                        )
                                      }
                                    }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              component.selectedComponent &&
                              component.componentTypeId != 7
                                ? _c(
                                    "b-select",
                                    {
                                      attrs: {
                                        value: component.unitSymbol,
                                        name: "unitSymbol"
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.updateSelectedComponentUnit(
                                            $event,
                                            component.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "option",
                                        { domProps: { value: "ml" } },
                                        [_vm._v("ml")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { domProps: { value: "g" } },
                                        [_vm._v("g")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "option",
                                        { domProps: { value: "g/Kg" } },
                                        [_vm._v("g/Kg")]
                                      )
                                    ]
                                  )
                                : component.selectedComponent
                                ? _c("p", { staticClass: "control" }, [
                                    _c(
                                      "span",
                                      { staticClass: "button is-static" },
                                      [_vm._v("bolsa")]
                                    )
                                  ])
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            }),
            0
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }