var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("div", { staticClass: "columns" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "column is-half" }, [
        _c("h1", { staticClass: "subtitle" }, [
          _vm._v(
            _vm._s(_vm.user.first_name) +
              " " +
              _vm._s(_vm.user.last_name_1) +
              " " +
              _vm._s(_vm.user.last_name_2)
          )
        ]),
        _vm._v(" "),
        _vm.user.medical_card != null
          ? _c("p", [
              _c("strong", [_vm._v("Cédula profesional: ")]),
              _vm._v(_vm._s(_vm.user.medical_card))
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", [
          _c("strong", [_vm._v("Correo electrónico: ")]),
          _vm._v(_vm._s(_vm.user.email))
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c(
          "a",
          { staticClass: "button is-small", on: { click: _vm.editPassword } },
          [
            _c(
              "span",
              { staticClass: "icon is-medium" },
              [_c("font-awesome-icon", { attrs: { icon: "key" } })],
              1
            ),
            _vm._v(" "),
            _c("span", [_vm._v("Cambiar contraseña")])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column is-one-fifth" }, [
      _c(
        "figure",
        { staticClass: "image is-128x128 serbitec-has-image-centered" },
        [
          _c("img", {
            staticClass: "is-rounded",
            attrs: { src: "https://picsum.photos/320/320?grayscale" }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }