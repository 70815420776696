<template>
    <b-select
        :value="selectedComponent"
        @input="option => $emit('component-selected', option)"
        expanded
    >
        <option
            v-for="option in components"
            :key="option.id"
            :value="option"
        >
            {{ option.name }}
        </option>
    </b-select>
</template>

<script>
import gql from 'graphql-tag';
const query = gql` query ( $with_component_type_ids: String )
{
    components ( with_component_type_ids: $with_component_type_ids ) {
        id
        name
    }
}`;

export default {
    props: {
        withComponentTypeIds: {
            type: String,
            default: ''
        },
        selectedComponent: {
            type: Object,
            default: () => {}
        }
    },
    apollo: {
        components: {
            query: query,
            variables () {
                return {
                    with_component_type_ids: this.withComponentTypeIds
                };
            },
            watchLoading (isLoading) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            }
        }
    }
};
</script>