var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card", staticStyle: { width: "auto" } },
    [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.comesFromEdit ? "Editar componente" : "Agregar componente"
              ) +
              "\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: "Nombre del componente",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.componentName.$error },
                message: {
                  "Se requiere introducir el nombre del componente":
                    !_vm.$v.componentName.required &&
                    _vm.$v.componentName.$error
                },
                expanded: ""
              }
            },
            [
              _c("b-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.$v.componentName.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$v.componentName,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.componentName.$model"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              attrs: {
                label: "Tipo de mezcla en la que se usará el componente",
                type: { "is-danger": _vm.$v.componentType.$error },
                message: {
                  "Se requiere introducir el tipo de mezcla":
                    !_vm.$v.componentType.required &&
                    _vm.$v.componentType.$error
                },
                expanded: ""
              }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "b-checkbox",
                    {
                      attrs: { disabled: _vm.comesFromEdit },
                      model: {
                        value: _vm.componentType.isNutritional,
                        callback: function($$v) {
                          _vm.$set(_vm.componentType, "isNutritional", $$v)
                        },
                        expression: "componentType.isNutritional"
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Nutricional\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-checkbox",
                    {
                      attrs: { disabled: _vm.comesFromEdit },
                      model: {
                        value: _vm.componentType.isOncologic,
                        callback: function($$v) {
                          _vm.$set(_vm.componentType, "isOncologic", $$v)
                        },
                        expression: "componentType.isOncologic"
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Oncológico\n                "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _vm.componentType.isOncologic && !_vm.componentType.isNutritional
            ? _c(
                "b-field",
                { attrs: { label: "Tipo de componente", expanded: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "block" },
                    [
                      _c(
                        "b-radio",
                        {
                          attrs: {
                            name: "componentTypeId",
                            "native-value": 1,
                            disabled: _vm.comesFromEdit
                          },
                          model: {
                            value: _vm.oncologicComponent.componentTypeId,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.oncologicComponent,
                                "componentTypeId",
                                $$v
                              )
                            },
                            expression: "oncologicComponent.componentTypeId"
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Medicamento oncológico\n                "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-radio",
                        {
                          attrs: {
                            name: "componentTypeId",
                            "native-value": 13,
                            disabled: _vm.comesFromEdit
                          },
                          model: {
                            value: _vm.oncologicComponent.componentTypeId,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.oncologicComponent,
                                "componentTypeId",
                                $$v
                              )
                            },
                            expression: "oncologicComponent.componentTypeId"
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Diluyente\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.componentType.isNutritional
            ? _c(
                "div",
                [
                  _c("hr"),
                  _vm._v(" "),
                  _c("h2", { staticClass: "subtitle is-6" }, [
                    _vm._v("Información del aporte nutricional del componente")
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      attrs: {
                        label: "Tipo de componente nutricional",
                        "label-position": "on-border",
                        type: {
                          "is-danger":
                            _vm.$v.nutritionalComponent.componentType.$error
                        },
                        message: {
                          "Se requiere introducir el tipo de componente nutricional":
                            !_vm.$v.nutritionalComponent.componentType
                              .required &&
                            _vm.$v.nutritionalComponent.componentType.$error
                        },
                        expanded: ""
                      }
                    },
                    [
                      _c("serbitec-component-type-select", {
                        attrs: {
                          "component-type-category-id": 2,
                          "selected-component":
                            _vm.$v.nutritionalComponent.$model.componentType,
                          disabled: _vm.comesFromEdit
                        },
                        on: {
                          "update:component-type-selected": function(option) {
                            return (_vm.$v.nutritionalComponent.$model.componentType = option)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      staticClass: "serbitec-small-top-margin",
                      attrs: { grouped: "", "group-multiline": "" }
                    },
                    [
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Aporte calórico",
                            "label-position": "on-border",
                            type: {
                              "is-danger":
                                _vm.$v.nutritionalComponent.energyInputInKcal
                                  .$error
                            },
                            message: {
                              "Se requiere introducir el aporte calórico en Kcal":
                                !_vm.$v.nutritionalComponent.energyInputInKcal
                                  .required &&
                                _vm.$v.nutritionalComponent.energyInputInKcal
                                  .$error
                            },
                            expanded: ""
                          }
                        },
                        [
                          _c(
                            "b-field",
                            {
                              attrs: {
                                type: {
                                  "is-danger":
                                    _vm.$v.nutritionalComponent
                                      .energyInputInKcal.$error
                                }
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: { type: "text", expanded: "" },
                                model: {
                                  value:
                                    _vm.$v.nutritionalComponent
                                      .energyInputInKcal.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.nutritionalComponent
                                        .energyInputInKcal,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.nutritionalComponent.energyInputInKcal.$model"
                                }
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "span",
                                  { staticClass: "button is-static" },
                                  [_vm._v("Kcal")]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Aporte calórico no protéico",
                            "label-position": "on-border",
                            type: {
                              "is-danger":
                                _vm.$v.nutritionalComponent
                                  .nonProteicEnergyInputInKcal.$error
                            },
                            message: {
                              "Se requiere introducir el aporte calórico no protéico en Kcal":
                                !_vm.$v.nutritionalComponent
                                  .nonProteicEnergyInputInKcal.required &&
                                _vm.$v.nutritionalComponent
                                  .nonProteicEnergyInputInKcal.$error
                            },
                            expanded: ""
                          }
                        },
                        [
                          _c(
                            "b-field",
                            {
                              attrs: {
                                type: {
                                  "is-danger":
                                    _vm.$v.nutritionalComponent
                                      .nonProteicEnergyInputInKcal.$error
                                }
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: { type: "text", expanded: "" },
                                model: {
                                  value:
                                    _vm.$v.nutritionalComponent
                                      .nonProteicEnergyInputInKcal.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.nutritionalComponent
                                        .nonProteicEnergyInputInKcal,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.nutritionalComponent.nonProteicEnergyInputInKcal.$model"
                                }
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "span",
                                  { staticClass: "button is-static" },
                                  [_vm._v("Kcal")]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      staticClass: "serbitec-small-top-margin",
                      attrs: { grouped: "", "group-multiline": "" }
                    },
                    [
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Gramos de aminoácidos",
                            "label-position": "on-border",
                            type: {
                              "is-danger":
                                _vm.$v.nutritionalComponent.gramsOfAminoacid
                                  .$error
                            },
                            message: {
                              "Se requiere introducir los gramos de aminoácidos":
                                !_vm.$v.nutritionalComponent.gramsOfAminoacid
                                  .required &&
                                _vm.$v.nutritionalComponent.gramsOfAminoacid
                                  .$error,
                              "Se requiere introducir un número decimal":
                                !_vm.$v.nutritionalComponent.gramsOfAminoacid
                                  .decimal &&
                                _vm.$v.nutritionalComponent.gramsOfAminoacid
                                  .$error
                            },
                            expanded: ""
                          }
                        },
                        [
                          _c(
                            "b-field",
                            {
                              attrs: {
                                type: {
                                  "is-danger":
                                    _vm.$v.nutritionalComponent.gramsOfAminoacid
                                      .$error
                                }
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: { type: "text", expanded: "" },
                                model: {
                                  value:
                                    _vm.$v.nutritionalComponent.gramsOfAminoacid
                                      .$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.nutritionalComponent
                                        .gramsOfAminoacid,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.nutritionalComponent.gramsOfAminoacid.$model"
                                }
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "span",
                                  { staticClass: "button is-static" },
                                  [_vm._v("g")]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Gramos de nitrógeno",
                            "label-position": "on-border",
                            type: {
                              "is-danger":
                                _vm.$v.nutritionalComponent.nitrogenGrams.$error
                            },
                            message: {
                              "Se requiere introducir los gramos de nitrógeno":
                                !_vm.$v.nutritionalComponent.nitrogenGrams
                                  .required &&
                                _vm.$v.nutritionalComponent.nitrogenGrams
                                  .$error,
                              "Los gramos de nitrógeno deben ser un número decimal":
                                !_vm.$v.nutritionalComponent.nitrogenGrams
                                  .decimal &&
                                _vm.$v.nutritionalComponent.nitrogenGrams.$error
                            },
                            expanded: ""
                          }
                        },
                        [
                          _c(
                            "b-field",
                            {
                              attrs: {
                                type: {
                                  "is-danger":
                                    _vm.$v.nutritionalComponent.nitrogenGrams
                                      .$error
                                }
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: { type: "text", expanded: "" },
                                model: {
                                  value:
                                    _vm.$v.nutritionalComponent.nitrogenGrams
                                      .$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.nutritionalComponent.nitrogenGrams,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.nutritionalComponent.nitrogenGrams.$model"
                                }
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "span",
                                  { staticClass: "button is-static" },
                                  [_vm._v("g")]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      staticClass: "serbitec-small-top-margin",
                      attrs: { grouped: "", "group-multiline": "" }
                    },
                    [
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Concentración osmótica",
                            "label-position": "on-border",
                            type: {
                              "is-danger":
                                _vm.$v.nutritionalComponent.osmoticConcentration
                                  .$error
                            },
                            message: {
                              "Se requiere introducir la concentración osmótica":
                                !_vm.$v.nutritionalComponent
                                  .osmoticConcentration.required &&
                                _vm.$v.nutritionalComponent.osmoticConcentration
                                  .$error,
                              "La concentración osmótica debe ser un número":
                                !_vm.$v.nutritionalComponent
                                  .osmoticConcentration.decimal &&
                                _vm.$v.nutritionalComponent.osmoticConcentration
                                  .$error
                            },
                            expanded: ""
                          }
                        },
                        [
                          _c(
                            "b-field",
                            {
                              attrs: {
                                type: {
                                  "is-danger":
                                    _vm.$v.nutritionalComponent
                                      .osmoticConcentration.$error
                                }
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: { type: "text", expanded: "" },
                                model: {
                                  value:
                                    _vm.$v.nutritionalComponent
                                      .osmoticConcentration.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.nutritionalComponent
                                        .osmoticConcentration,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.nutritionalComponent.osmoticConcentration.$model"
                                }
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "span",
                                  { staticClass: "button is-static" },
                                  [_vm._v("mOsmol/L")]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-field",
                        {
                          attrs: {
                            label: "Densidad",
                            "label-position": "on-border",
                            type: {
                              "is-danger":
                                _vm.$v.nutritionalComponent.densityQuantity
                                  .$error ||
                                _vm.$v.nutritionalComponent.densityUnit.$error
                            },
                            message: {
                              "Se requiere introducir la densidad":
                                !_vm.$v.nutritionalComponent.densityQuantity
                                  .required &&
                                _vm.$v.nutritionalComponent.densityQuantity
                                  .$error,
                              "La densidad tiene que ser un número":
                                !_vm.$v.nutritionalComponent.densityQuantity
                                  .decimal &&
                                _vm.$v.nutritionalComponent.densityQuantity
                                  .$error,
                              "Se requiere introducir la unidad de la densidad":
                                !_vm.$v.nutritionalComponent.densityUnit
                                  .required &&
                                _vm.$v.nutritionalComponent.densityUnit.$error
                            },
                            expanded: ""
                          }
                        },
                        [
                          _c(
                            "b-field",
                            {
                              attrs: {
                                type: {
                                  "is-danger":
                                    _vm.$v.nutritionalComponent.densityQuantity
                                      .$error ||
                                    _vm.$v.nutritionalComponent.densityUnit
                                      .$error
                                }
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: { type: "text", expanded: "" },
                                model: {
                                  value:
                                    _vm.$v.nutritionalComponent.densityQuantity
                                      .$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.nutritionalComponent
                                        .densityQuantity,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "$v.nutritionalComponent.densityQuantity.$model"
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "b-select",
                                {
                                  model: {
                                    value: _vm.nutritionalComponent.densityUnit,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.nutritionalComponent,
                                        "densityUnit",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "nutritionalComponent.densityUnit"
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    { domProps: { value: { symbol: "g/ml" } } },
                                    [_vm._v("g/ml")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { domProps: { value: { symbol: "g/l" } } },
                                    [_vm._v("g/l")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._l(_vm.nutritionalComponent.electrolytes, function(
                    electrolyte
                  ) {
                    return _c(
                      "b-field",
                      {
                        key: electrolyte.id,
                        staticStyle: { "margin-bottom": "25px" },
                        attrs: { grouped: "", "group-multiline": "" }
                      },
                      [
                        _c(
                          "b-checkbox",
                          {
                            staticStyle: { "margin-right": "25px" },
                            nativeOn: {
                              change: function($event) {
                                electrolyte.quantityInMeq = null
                              }
                            },
                            model: {
                              value: electrolyte.showInput,
                              callback: function($$v) {
                                _vm.$set(electrolyte, "showInput", $$v)
                              },
                              expression: "electrolyte.showInput"
                            }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(electrolyte.name) +
                                "\n            "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        electrolyte.showInput
                          ? _c(
                              "b-field",
                              [
                                _c("b-input", {
                                  attrs: { type: "text", expanded: "" },
                                  model: {
                                    value: electrolyte.quantityInMeq,
                                    callback: function($$v) {
                                      _vm.$set(
                                        electrolyte,
                                        "quantityInMeq",
                                        $$v
                                      )
                                    },
                                    expression: "electrolyte.quantityInMeq"
                                  }
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "control" }, [
                                  _c(
                                    "span",
                                    { staticClass: "button is-static" },
                                    [_vm._v("mEq")]
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$parent.close()
              }
            }
          },
          [_vm._v("\n            Cancelar\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button is-primary", on: { click: _vm.submitForm } },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.comesFromEdit ? "Editar" : "Agregar") +
                "\n        "
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "subtitle is-6" }, [
      _c("strong", [_vm._v("Aporte de electrolitos")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }