var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-field",
    {
      attrs: {
        label: "Fecha de entrega",
        "label-position": "on-border",
        type: { "is-danger": _vm.selectedDatesRange.$error },
        message: {
          "Se requiere introducir el rango de fechas":
            !_vm.selectedDatesRange.required && _vm.selectedDatesRange.$error
        },
        expanded: ""
      }
    },
    [
      _c("b-datepicker", {
        attrs: {
          placeholder: "Seleccione las fechas",
          "date-formatter": function(dates) {
            return _vm.formatDateRange(dates)
          },
          range: "",
          expanded: ""
        },
        on: {
          input: function(datesRange) {
            return _vm.$emit("dates-range-selected", datesRange)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }