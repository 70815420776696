<template>
    <div class="box">
        <b-loading :active.sync="loading"></b-loading>
        <nav v-if="hasCloseButton" class="level">
            <div class="level-left">
                <h1 class="subtitle">Agregar usuario</h1>
            </div>
            <div class="level-right">
                <a
                @click="$emit('close-form')"
                class="button">
                    <span class="icon">
                        <font-awesome-icon icon="times-circle" />
                    </span>
                    <span> Cerrar forma</span>
                </a>
            </div>
        </nav>
        <form
        @submit.prevent="submitUserForm"
        novalidate="true">
            <b-field grouped group-multiline>
                <b-field
                label="Nombre"
                :type="{'is-danger' : $v.name.$error }"
                :message="{
                    'Se requiere introducir el nombre completo de la persona de contacto' : !$v.name.required && $v.name.$error
                }"
                expanded>
                    <b-input
                        v-model="$v.name.$model"
                        type="text">
                    </b-input>
                </b-field>
                <b-field
                label="Apellido paterno"
                :type="{'is-danger' : $v.last_name_1.$error }"
                :message="{
                    'Se requiere introducir el apellido paterno de la persona de contacto' : !$v.last_name_1.required && $v.last_name_1.$error
                }"
                expanded>
                    <b-input
                        v-model="$v.last_name_1.$model"
                        type="text">
                    </b-input>
                </b-field>
                <b-field
                    label="Apellido materno"
                    :type="{'is-danger' : $v.last_name_2.$error }"
                    :message="{
                        'Se requiere introducir el apellido materno de la persona de contacto' : !$v.last_name_2.required && $v.last_name_2.$error
                    }"
                    expanded>
                    <b-input
                        v-model="$v.last_name_2.$model"
                        type="text">
                    </b-input>
                </b-field>
            </b-field>
            <b-field grouped group-multiline>
                <b-field
                    label="Correo electrónico"
                    :type="{'is-danger' : $v.email.$error }"
                    :message="{
                        'Se requiere introducir el correo electrónico de la persona de contacto' : !$v.email.required && $v.email.$error,
                        'Se requiere introducir un correo electrónico válido' : !$v.email.email && $v.email.$error
                    }"
                    expanded>
                    <b-input
                        v-model="$v.email.$model"
                        type="text">
                    </b-input>
                </b-field>
                <b-field
                label="Tipo de usuario"
                :type="{'is-danger' : $v.userTypeId.$error }"
                :message="{
                    'Se requiere introducir el tipo de cliente' : !$v.userTypeId.required && $v.userTypeId.$error
                }">
                    <b-select
                    v-model="$v.userTypeId.$model"
                    placeholder="Seleccione un tipo"
                    :disabled="user">
                        <option
                            v-for="userType in userTypes"
                            :value="userType.id"
                            :key="userType.id">
                                {{ userType.name }}
                        </option>
                    </b-select>
                </b-field>
                <b-field
                    v-if="userTypeId && userTypeId == 3"
                    label="Cédula médica"
                    :type="{'is-danger' : $v.medicalCard.$error }"
                    :message="{
                        'Se requiere introducir la cédula profesional del médico' : !$v.medicalCard.required && $v.medicalCard.$error,
                    }"
                    expanded>
                    <b-input
                        v-model="$v.medicalCard.$model"
                        type="text">
                    </b-input>
                </b-field>
            </b-field>
            <br>
            <b-field grouped position="is-centered">
                <p class="control">
                    <button type="submit" class="button is-primary">
                        <span v-if="user">
                            Editar usuario
                        </span>
                        <span v-else>
                            Agregar usuario
                        </span>
                    </button>
                </p>
            </b-field>
        </form> 
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import axios from 'axios';

import gql from 'graphql-tag';
const query = gql` query
{
    user_types( is_internal:false ) {
        id
        name
    }
}`;

function initialDataState() {
    return {
        name: null,
        last_name_1: null,
        last_name_2: null,
        email: null,
        userTypeId: null,
        medicalCard: null,

        loading: false,
    };
}

export default {
    props: ['user', 'hasCloseButton', 'payerId'],
    beforeMount() {
        if ( this.user ){
            this.name = this.user.first_name;
            this.last_name_1 = this.user.last_name_1;
            this.last_name_2 = this.user.last_name_2;
            this.email = this.user.email;
            this.userTypeId = this.user.types[0].id;
            this.medicalCard = this.user.medical_card;
        }
    },
    data: initialDataState,
    methods: {
        submitUserForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.addUser();
            }
        },
        getUser () {
            const user = {
                first_name: this.name,
                last_name_1: this.last_name_1,
                last_name_2: this.last_name_2,
                email: this.email,
                payer_id: this.payerId,
                user_type_id: this.userTypeId
            };
            if ( this.userTypeId && this.userTypeId == 3 ){
                user.medical_card = this.medicalCard;
            }
            
            return user;
        },
        addUser () {
            if ( this.user ) {
                this.makeRequest('/api/external_users/' + this.user.id, axios.put, this.getUser());
            } else {
                this.makeRequest('/api/external_users', axios.post, this.getUser());
            }
        },
        async makeRequest( url, method, params ){
            this.loading = true;
            try {
                await method(url,params);
                this.$emit('success');
                this.loading = false;
            } catch (error) {
                console.error(error);
                this.$emit('error');
                this.loading = false;
            }
        }
    },
    validations () {
        const validations = {
            name: {
                required
            },
            last_name_1: {
                required
            },
            last_name_2: {
                required
            },
            email: {
                required,
                email
            },
            userTypeId: {
                required
            }
        };
        if ( this.userTypeId && this.userTypeId == 3 ){
            validations.medicalCard = {
                required
            };
        }
        return validations;
    },
    apollo: {
        userTypes: {
            query: query,
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {

                return data.user_types.filter( type => type.id !== 2 );
            }
        },
    }
};
</script>