<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <h1 class="title">Clientes</h1>
            </div>
            <div class="level-right">
                <router-link
                    :to="{ path: 'add' }"
                    class="button"
                    append
                >
                    <span class="icon">
                        <font-awesome-icon icon="plus" />
                    </span>
                    <span> Agregar cliente</span>
                </router-link>
            </div>
        </nav>
        <hr>
        <serbitec-payers-table
            :payers="payers"
            :payers-count="payersCount"

            :loading.sync="loading"
            :page.sync="page"
            :pageSize="pageSize"
            :sortField.sync="sortField"
            :sortOrder.sync="sortOrder"

            @page-change="onPageChange"
            @sort="onSort"
        />
    </div>
</template>

<script>
import serbitecPayersTable from '../../../components/dashboard/payers/payers-table.vue';

import gql from 'graphql-tag';
const query = gql` query ( $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String )
{
    payers( page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction ) {
        id
        invoicing_data {
            id
            business_name
            price_in_cents_per_nutritional_mixture
            price_in_cents_per_oncologic_mixture
        }
    }
    payersCount: table_counts( count_type: "payers" ) {
        payers
    }
}`;

export default {
    name:'index',
    components: {
        serbitecPayersTable
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if ( from.path == '/add' && to.query.success ) {
                vm.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha dado de alta el cliente'
                });
            }
            if ( from.path == '/edit' && to.query.success ) {
                vm.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha editado el cliente'
                });
            }
        });
    },
    data () {
        return {
            loading: false,
            page: 0,
            pageSize: 10,
            sortField: 'id',
            sortOrder:'asc',
            payersCount: 0,
        };
    },
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
    },
    apollo: {
        payers: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                this.payersCount = data.payersCount.payers;
                return data.payers;
            }
        },
    }
};
</script>