var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.selectedThinner
    ? _c("b-message", { class: { "is-warning": _vm.isWarning } }, [
        _vm._v("\n    " + _vm._s(this.mixtureAlert()) + "\n")
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }