var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { on: { submit: _vm.submitGenerateQuote } }, [
    _c("div", { staticClass: "modal-card", staticStyle: { width: "auto" } }, [
      _vm._m(0),
      _vm._v(" "),
      _c("section", { staticClass: "modal-card-body" }, [
        _c("p", [
          _vm._v(
            "\n                Para generar la trazabilidad seleccione los procesos a incluir y de click en el botón Generar\n            "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "columns serbitec-small-top-margin" }, [
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              { staticClass: "field" },
              [
                _c(
                  "b-checkbox",
                  {
                    attrs: { "native-value": "2", disabled: "" },
                    model: {
                      value: _vm.toMixtureStatesIds,
                      callback: function($$v) {
                        _vm.toMixtureStatesIds = $$v
                      },
                      expression: "toMixtureStatesIds"
                    }
                  },
                  [
                    _vm._v(
                      "\n                            Autorizada\n                        "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "field" },
              [
                _c(
                  "b-checkbox",
                  {
                    attrs: { "native-value": "4" },
                    model: {
                      value: _vm.toMixtureStatesIds,
                      callback: function($$v) {
                        _vm.toMixtureStatesIds = $$v
                      },
                      expression: "toMixtureStatesIds"
                    }
                  },
                  [
                    _vm._v(
                      "\n                            En preparación\n                        "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "field" },
              [
                _c(
                  "b-checkbox",
                  {
                    attrs: { "native-value": "5", disabled: "" },
                    model: {
                      value: _vm.toMixtureStatesIds,
                      callback: function($$v) {
                        _vm.toMixtureStatesIds = $$v
                      },
                      expression: "toMixtureStatesIds"
                    }
                  },
                  [
                    _vm._v(
                      "\n                            Preparada\n                        "
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              { staticClass: "field" },
              [
                _c(
                  "b-checkbox",
                  {
                    attrs: { "native-value": "6", disabled: "" },
                    model: {
                      value: _vm.toMixtureStatesIds,
                      callback: function($$v) {
                        _vm.toMixtureStatesIds = $$v
                      },
                      expression: "toMixtureStatesIds"
                    }
                  },
                  [
                    _vm._v(
                      "\n                            Liberada por calidad\n                        "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "field" },
              [
                _c(
                  "b-checkbox",
                  {
                    attrs: { "native-value": "7" },
                    model: {
                      value: _vm.toMixtureStatesIds,
                      callback: function($$v) {
                        _vm.toMixtureStatesIds = $$v
                      },
                      expression: "toMixtureStatesIds"
                    }
                  },
                  [
                    _vm._v(
                      "\n                            Rechazada por calidad\n                        "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "field" },
              [
                _c(
                  "b-checkbox",
                  {
                    attrs: { "native-value": "8" },
                    model: {
                      value: _vm.toMixtureStatesIds,
                      callback: function($$v) {
                        _vm.toMixtureStatesIds = $$v
                      },
                      expression: "toMixtureStatesIds"
                    }
                  },
                  [
                    _vm._v(
                      "\n                            Enviada\n                        "
                    )
                  ]
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "column" }, [
            _c(
              "div",
              { staticClass: "field" },
              [
                _c(
                  "b-checkbox",
                  {
                    attrs: { "native-value": "9" },
                    model: {
                      value: _vm.toMixtureStatesIds,
                      callback: function($$v) {
                        _vm.toMixtureStatesIds = $$v
                      },
                      expression: "toMixtureStatesIds"
                    }
                  },
                  [
                    _vm._v(
                      "\n                            Acondicionada\n                        "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "field" },
              [
                _c(
                  "b-checkbox",
                  {
                    attrs: { "native-value": "10" },
                    model: {
                      value: _vm.toMixtureStatesIds,
                      callback: function($$v) {
                        _vm.toMixtureStatesIds = $$v
                      },
                      expression: "toMixtureStatesIds"
                    }
                  },
                  [
                    _vm._v(
                      "\n                            Entregada\n                        "
                    )
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "field" },
              [
                _c(
                  "b-checkbox",
                  {
                    attrs: { "native-value": "3" },
                    model: {
                      value: _vm.toMixtureStatesIds,
                      callback: function($$v) {
                        _vm.toMixtureStatesIds = $$v
                      },
                      expression: "toMixtureStatesIds"
                    }
                  },
                  [
                    _vm._v(
                      "\n                            Cancelada\n                        "
                    )
                  ]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$parent.close()
              }
            }
          },
          [_vm._v("Cancelar")]
        ),
        _vm._v(" "),
        _c("button", { staticClass: "button is-primary" }, [_vm._v("Generar")])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v("\n                Generar trazabilidad\n            ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }