var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-table", {
    attrs: { data: _vm.flasks },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(props) {
          return [
            _c("b-table-column", { attrs: { label: "Nombre" } }, [
              _c("p", [
                _vm._v(
                  _vm._s(
                    props.row.medicine_presentation.medicine.commercial_name
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _c("b-table-column", { attrs: { label: "Componente" } }, [
              _c("p", [
                _vm._v(
                  _vm._s(
                    props.row.medicine_presentation.medicine.component.name
                  )
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "b-table-column",
              { attrs: { label: "Presentación" } },
              [
                _c("b-tag", [
                  _vm._v(
                    _vm._s(props.row.medicine_presentation.component_quantity) +
                      " " +
                      _vm._s(
                        props.row.medicine_presentation.medicine.component_unit
                          .symbol
                      ) +
                      " en " +
                      _vm._s(props.row.medicine_presentation.volume_in_ml) +
                      " ml"
                  )
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-table-column",
              { attrs: { label: "Cantidad", centered: "" } },
              [_c("p", [_vm._v(_vm._s(props.row.number_of_flasks))])]
            ),
            _vm._v(" "),
            _vm.comesFromRecords
              ? _c(
                  "b-table-column",
                  { attrs: { label: "Estado", centered: "" } },
                  [_c("b-tag", [_vm._v(_vm._s(props.row.state.name))])],
                  1
                )
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }