<template>
    <div>
        <div v-for="( element, index ) in elements" :key="index" class="box">
            <nav class="level serbitec-small-bottom">
                <div class="level-left">
                    <p class="has-text-weight-bold is-size-5">{{ element.componentName }}</p>
                </div>
                <div class="level-right">
                    <p v-if="element.isMedicine" class="is-size-4">{{ element.quantityInMedicineUnit }} {{ element.medicineUnit }} | {{ medicineQuantityInMl( element.warehouseItems ) }} ml</p>
                    <p v-else class="is-size-4">{{ setItemQuantity( element.quantityInMedicineUnit, computedMedicineQuantityInMl , element.isPiece ) }}</p>
                </div>
            </nav>
            <b-table
            :data="element.warehouseItems">
                <template slot-scope="props">
                    <b-table-column label="Lote">
                        <p><strong v-if="props.row.prefix">{{props.row.prefix.prefix}}-</strong> {{ props.row.batch }}</p>
                    </b-table-column>

                    <b-table-column label="Medicamento">
                        {{ props.row.medicineName }}
                    </b-table-column>

                    <b-table-column label="Presentación" centered>
                        <b-tag v-if="element.isMedicine">{{ props.row.medicinePresentationQuantity }} {{ props.row.medicinePresentationQuantityUnit }} en {{ props.row.medicinePresentationVolumeInMl }} ml</b-tag>
                        <b-tag v-else-if="element.isPiece">{{ props.row.medicinePresentationQuantity }} {{ props.row.medicinePresentationQuantityUnit }}</b-tag>
                        <b-tag v-else>{{ props.row.medicinePresentationVolumeInMl }} ml</b-tag>
                    </b-table-column>

                    <b-table-column v-if="element.isMedicine" :label="'Cantidad en ' + props.row.medicinePresentationQuantityUnit" centered>
                        <p><small>{{ quantityInMedicineUnit ( props.row.medicineQuantityInMl, props.row.medicinePresentationQuantity, props.row.medicinePresentationVolumeInMl ) }} {{ props.row.medicinePresentationQuantityUnit }}</small></p>
                    </b-table-column>

                    <b-table-column label="Cantidad" centered>
                        <p v-if="element.isPiece"><strong>{{ props.row.medicineQuantityInMl }} {{ props.row.medicinePresentationQuantityUnit }}</strong></p>
                        <p v-else><strong>{{ props.row.medicineQuantityInMl }} ml</strong></p>
                    </b-table-column>
                </template>
            </b-table>
        </div>
        <b-field style="margin-top:25px" position="is-centered" grouped>
            <p class="control has-text-centered">
                <button @click.once="submitMixturePrepared" class="button is-primary">
                    Mezcla preparada
                </button>
            </p>
        </b-field>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props:['elements', 'mixtureId', 'returnUrl'],
    computed: {
        computedMedicineQuantityInMl () {
            var medicineQuantity = 0;
            this.elements.map( element => {
                if ( element.isMedicine ) {
                    element.warehouseItems.map ( warehouseItem => {
                        medicineQuantity += warehouseItem.medicineQuantityInMl;
                    } );
                }
            } );
            return medicineQuantity;
        }
    },
    methods: {
        setItemQuantity( itemQuantity, medicineQuantityInMl, isPieceItem ) {
            if ( isPieceItem ) {
                return itemQuantity + ' pz';
            } else {
                return ( itemQuantity - medicineQuantityInMl ) + ' ml';
            }
        },
        quantityInMedicineUnit ( quantityInMl , medicinePresentationQuantity, medicinePresentationVolumeInMl ) {
            return ( ( quantityInMl * medicinePresentationQuantity ) / medicinePresentationVolumeInMl );
        },
        medicineQuantityInMl ( warehouseItems ) {
            var medicineQuantity = 0;
            warehouseItems.map ( warehouseItem => {
                medicineQuantity += warehouseItem.medicineQuantityInMl;
            } );
            return medicineQuantity;
        },
        getRequestParams () {
            const requestParams = {
                new_state_id: 5
            };
            return requestParams;
        },
        submitMixturePrepared () {
            var url = '/api/oncologic/mixtures/' + this.mixtureId + '/state';
            this.makeRequest( url, axios.post, this.getRequestParams() );
        },
        makeRequest ( url, method, params ) {
            this.loading = true;
            method( url, params )
            .then(function () {
                this.$router.push({ path: this.returnUrl, query: { success: true } });
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
            this.loading = false;
        }
    }
};
</script>