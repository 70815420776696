var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "level" }, [
      _c(
        "div",
        { staticClass: "level-left" },
        [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "level-right" },
        [
          _c(
            "router-link",
            {
              staticClass: "button",
              attrs: {
                to: {
                  path: "/nutritional_mixtures_authorizer/authorize",
                  query: { mixtureId: _vm.mixtureId }
                }
              }
            },
            [
              _c(
                "span",
                { staticClass: "icon" },
                [_c("font-awesome-icon", { attrs: { icon: "times-circle" } })],
                1
              ),
              _vm._v(" "),
              _c("span", [_vm._v(" Cancelar edición")])
            ]
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _vm.medic.payer
      ? _c(
          "div",
          [
            _c("div", { staticClass: "box" }, [
              _c("h1", { staticClass: "title has-text-centered" }, [
                _vm._v("Lote LN-" + _vm._s(_vm.mixtureId))
              ])
            ]),
            _vm._v(" "),
            _c("serbitec-horizontal-order-data", {
              attrs: { medic: _vm.medic, patient: _vm.patient }
            }),
            _vm._v(" "),
            _c("serbitec-water-form", {
              attrs: { "computed-total-volume": _vm.computedTotalVolume }
            }),
            _vm._v(" "),
            _c("serbitec-water-alerts", {
              attrs: { "computed-total-volume": _vm.computedTotalVolume }
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("serbitec-macronutrients-form"),
            _vm._v(" "),
            _c("serbitec-electrolytes-form"),
            _vm._v(" "),
            _c("serbitec-electrolytes-alerts", {
              attrs: {
                electrolytes: _vm.electrolytes,
                "computed-total-volume": _vm.computedTotalVolume
              }
            }),
            _vm._v(" "),
            _c("serbitec-aditives-form"),
            _vm._v(" "),
            _c("serbitec-additional-information", {
              attrs: { "computed-total-volume": _vm.computedTotalVolume }
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("serbitec-delivery-dates-form", {
              attrs: {
                "comes-from-edit": true,
                "delivery-dates": _vm.deliveryDates
              },
              on: {
                "update:deliveryDates": function($event) {
                  _vm.deliveryDates = $event
                },
                "update:delivery-dates": function($event) {
                  _vm.deliveryDates = $event
                }
              }
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "box" },
              [
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: "Comentarios (opcional)",
                      message: "Introduzca comentarios referentes a la mezcla"
                    }
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        type: "textarea",
                        maxlength: "500",
                        placeholder: "Introduzca sus comentarios..."
                      },
                      model: {
                        value: _vm.comments,
                        callback: function($$v) {
                          _vm.comments = $$v
                        },
                        expression: "comments"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticStyle: { "margin-top": "25px" },
                attrs: { position: "is-centered", grouped: "" }
              },
              [
                _c("p", { staticClass: "control has-text-centered" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button is-primary",
                      attrs: { disabled: _vm.dangerAlert },
                      on: { click: _vm.editAndAuthorizeMixture }
                    },
                    [
                      _vm._v(
                        "\n                    Editar y autorizar orden\n                "
                      )
                    ]
                  )
                ])
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }