<template>
    <div>
        <b-field grouped group-multiline>
        <b-field label="Volumen total" :message="'Introduzca el volumen total en ml'" expanded>
            <b-field>
            <b-input v-model.trim="totalVolume" type="text"></b-input>
            <p class="control">
                <span class="button is-static">ml</span>
            </p>
            <p class="control">
                <span class="button" @click="totalVolume = computedTotalVolume">
                <span class="icon is-small">
                    <font-awesome-icon icon="calculator" />
                </span>
                <span>Calcular</span>
                </span>
            </p>
            </b-field>
        </b-field>
        <b-field label="Sobrellenado" :message="'Introduzca una cantidad o deje en 0'" expanded>
            <b-field>
            <b-select v-model="overfilling" expanded>
                <option value="0">0</option>
                <option value="20">20</option>
                <option value="25">25</option>
                <option value="30">30</option>
                <option value="35">35</option>
                <option value="50">50</option>
                <option value="100">100</option>
            </b-select>
            <p class="control">
                <span class="button is-static">ml</span>
            </p>
            </b-field>
        </b-field>
        </b-field>
        <b-field
        v-if="computeWater"
        label="Agua inyectable"
        :message="'La cantidad de agua inyectable se calcula en función al volumen total introducido y el volumen calculado'"
        expanded
        >
        <b-field>
            <b-input type="text" v-model="computeWater" expanded disabled></b-input>
            <p class="control" disabled>
            <span class="button is-static">ml</span>
            </p>
        </b-field>
        </b-field>
        <br />
    </div>
</template>

<script>
export default {
    props:[ 'computedTotalVolume' ],
    computed: {
        totalVolume: {
            get() {
                return this.$store.state.nutritionalOrder.totalVolume;
            },
            set(value) {
                this.$store.commit(
                "nutritionalOrder/UPDATE_TOTAL_VOLUME",
                value
                );
            }
        },
        overfilling: {
            get() {
                return this.$store.state.nutritionalOrder.overfilling;
            },
            set(value) {
                this.$store.commit(
                "nutritionalOrder/UPDATE_OVERFILLING",
                value
                );
            }
        },
        computeWater() {
            if ( this.totalVolume && parseFloat( this.totalVolume ) > parseFloat( this.computedTotalVolume ) ) {
                let injectableWater = ( this.totalVolume - this.computedTotalVolume ).toFixed(2);
                this.$store.commit( 'nutritionalOrder/SET_INJECTABLE_WATER', injectableWater );
                return injectableWater;
            } else {
                this.$store.commit( 'nutritionalOrder/RESET_INJECTABLE_WATER' );
                return null;
            }
        },
    }
};
</script>