var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function($event) {
            _vm.loading = $event
          }
        }
      }),
      _vm._v(" "),
      _c("nav", { staticClass: "level" }, [
        _c(
          "div",
          { staticClass: "level-left" },
          [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "a",
            {
              staticClass: "button",
              attrs: {
                href:
                  "/pdf/nutritional/mixtures/" +
                  this.mixtureId +
                  "/client_report",
                target: "_blank"
              }
            },
            [
              _c(
                "span",
                { staticClass: "icon" },
                [_c("font-awesome-icon", { attrs: { icon: "print" } })],
                1
              ),
              _vm._v(" "),
              _c("span", [_vm._v(" Imprimir")])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.mixture
        ? _c(
            "div",
            [
              _c("div", { staticClass: "box" }, [
                _c("h1", { staticClass: "title has-text-centered" }, [
                  _vm._v(
                    "Orden: ON-" +
                      _vm._s(_vm.mixture.order.id) +
                      " | Lote: LN-" +
                      _vm._s(_vm.mixture.id)
                  )
                ])
              ]),
              _vm._v(" "),
              _c("serbitec-horizontal-order-data", {
                attrs: {
                  medic: _vm.mixture.order.medic,
                  patient: _vm.mixture.order.patient
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "box" }, [
                _c("div", { staticClass: "columns" }, [
                  _c("div", { staticClass: "column is-one-third" }, [
                    _vm._v("\n                    Volumen total: "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.mixture.total_volume_in_ml) + " ml")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "column is-one-third" }, [
                    _vm._v("\n                    Sobrellenado: "),
                    _c("strong", [
                      _vm._v(_vm._s(_vm.mixture.overfill_in_ml) + " ml")
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.water
                    ? _c("div", { staticClass: "column is-one-third" }, [
                        _vm._v("\n                    Agua inyectable: "),
                        _c("strong", [_vm._v(_vm._s(_vm.water) + " ml")])
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _vm.mixture.comments
                ? _c("b-message", [
                    _c("h1", [_c("strong", [_vm._v("Comentarios: ")])]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.mixture.comments))])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("serbitec-nutritional-elements-table", {
                attrs: {
                  elements: _vm.mixture.elements,
                  "total-volume": _vm.mixture.total_volume_in_ml
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }