var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function($event) {
            _vm.loading = $event
          }
        }
      }),
      _vm._v(" "),
      _c("nav", { staticClass: "level" }, [
        _c(
          "div",
          { staticClass: "level-left" },
          [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" })
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.order
        ? _c(
            "div",
            [
              _c("div", { staticClass: "box" }, [
                _c("h1", { staticClass: "title has-text-centered" }, [
                  _vm._v("Orden OO-" + _vm._s(_vm.orderId))
                ])
              ]),
              _vm._v(" "),
              _c("serbitec-horizontal-order-data", {
                attrs: { medic: _vm.order.medic, patient: _vm.order.patient }
              }),
              _vm._v(" "),
              _vm._l(_vm.order.mixtures, function(mixture, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    {
                      staticClass: "box",
                      staticStyle: { "margin-bottom": "15px" }
                    },
                    [
                      _c("nav", { staticClass: "level" }, [
                        _c("div", { staticClass: "level-left" }, [
                          _c("h1", { staticClass: "subtitle" }, [
                            _vm._v("Mezcla con lote: LO-" + _vm._s(mixture.id))
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "level-right" },
                          [
                            _c("b-tag", [
                              _vm._v("Estado: " + _vm._s(mixture.state.name))
                            ])
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("serbitec-oncologic-elements-table", {
                        attrs: { elements: mixture.elements }
                      }),
                      _vm._v(" "),
                      _c(
                        "b-message",
                        { staticClass: "serbitec-small-top-margin" },
                        [
                          _c("nav", { staticClass: "level" }, [
                            _c("div", { staticClass: "level-left" }, [
                              _c("h1", { staticClass: "subtitle" }, [
                                _vm._v("Comentarios")
                              ])
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "level-right" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "button is-small",
                                  on: {
                                    click: function($event) {
                                      return _vm.editComments(
                                        mixture,
                                        "mixture"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon is-medium" },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "edit" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ])
                          ]),
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                mixture.comments
                                  ? mixture.comments
                                  : "Sin comentarios"
                              ) +
                              "\n                "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ])
              }),
              _vm._v(" "),
              _vm.order.requested_flasks.length
                ? _c(
                    "div",
                    {
                      staticClass: "box",
                      staticStyle: { "margin-bottom": "15px" }
                    },
                    [
                      _c("h1", { staticClass: "subtitle" }, [
                        _vm._v("Frascos")
                      ]),
                      _vm._v(" "),
                      _c("serbitec-oncologic-flasks-table", {
                        attrs: {
                          "comes-from-records": true,
                          flasks: _vm.order.requested_flasks
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }