<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <h1 class="title">Registro de mezclas nutricionales</h1>
            </div>
            <div class="level-right" />
        </nav>
        <hr>
        <serbitec-nutritional-records-table
            :table-type="'nutritional'"
            :mixtures="mixtures"
            :mixtures-count="mixturesCount"

            :loading.sync="loading"
            :page.sync="page"
            :pageSize="pageSize"
            :sortField.sync="sortField"
            :sortOrder.sync="sortOrder"

            @page-change="onPageChange"
            @sort="onSort"
        />
    </div>
</template>

<script>
import serbitecNutritionalRecordsTable from '../../../../components/dashboard/external-user/records-table.vue';
import { mapState } from 'vuex';

import gql from 'graphql-tag';
const query = gql` query ( $medic_id: Int, $payer_id: Int, $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String )
{
    mixtures: nutritional_mixtures( medic_id: $medic_id, payer_id: $payer_id, page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction ) {
        id
        delivery_date
        state {
            id
            name
        }
        order {
            id
            patient {
                id
                name
            }
            medic {
                id
                payer {
                    id
                    invoicing_data {
                        id
                        business_name
                    }
                }
            }
        }
    }
    mixturesCount: table_counts( count_type: "nutritional_mixtures", medic_id: $medic_id, payer_id: $payer_id ) {
        nutritional_mixtures
    }
}`;

function initialDataState () {
    return {
        loading: false,
        mixturesCount: null,
        page: 0,
        pageSize: 10,
        sortField: 'id',
        sortOrder:'desc'
    };
}

export default {
    name:'index',
    components: {
        serbitecNutritionalRecordsTable
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.$apollo.queries.mixtures.refetch();
        });
    },
    data: initialDataState,
    computed: {
        ...mapState({
            user: state => state.user
        }),
    },
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        }
    },
    apollo: {
        mixtures: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {

                return {
                    payer_id: this.user.payer.id,
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                this.mixturesCount = data.mixturesCount.nutritional_mixtures;
                return data.mixtures;
            }
        },
    }
};
</script>
