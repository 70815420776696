var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        attrs: {
          data: _vm.externalWarehouseItems,
          loading: _vm.loading,
          paginated: "",
          "backend-pagination": "",
          total: _vm.externalWarehouseItemsCount,
          "per-page": _vm.pageSize,
          "aria-next-label": "Siguiente página",
          "aria-previous-label": "Página anterior",
          "aria-page-label": "Página",
          "aria-current-label": "Página actual",
          "backend-sorting": "",
          "default-sort": [_vm.sortField, _vm.sortOrder]
        },
        on: { "page-change": _vm.onPageChange, sort: _vm.onSort },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "batch",
                      label: "Lote",
                      numeric: "",
                      sortable: ""
                    }
                  },
                  [
                    _c("p", [
                      props.row.prefix
                        ? _c("strong", [
                            _vm._v(_vm._s(props.row.prefix.prefix) + "-")
                          ])
                        : _vm._e(),
                      _vm._v(" " + _vm._s(props.row.batch))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Fabricante", sortable: "" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          props.row.medicine_presentation.medicine.manufacturer
                            .name
                        ) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Presentación", centered: "" } },
                  [
                    _vm.isPieceItem(props.row)
                      ? _c("b-tag", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                props.row.medicine_presentation
                                  .component_quantity
                              ) +
                              " " +
                              _vm._s(
                                props.row.medicine_presentation.medicine
                                  .component_unit.symbol
                              ) +
                              "\n                "
                          )
                        ])
                      : _vm.isOncologicItem(props.row)
                      ? _c("b-tag", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                props.row.medicine_presentation
                                  .component_quantity
                              ) +
                              " " +
                              _vm._s(
                                props.row.medicine_presentation.medicine
                                  .component_unit.symbol
                              ) +
                              " en " +
                              _vm._s(
                                props.row.medicine_presentation.volume_in_ml
                              ) +
                              " ml\n                "
                          )
                        ])
                      : _c("b-tag", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                props.row.medicine_presentation.volume_in_ml
                              ) +
                              " ml\n                "
                          )
                        ]),
                    _vm._v(" "),
                    _vm.isEvaBag(props.row)
                      ? _c("b-tag", [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                props.row.medicine_presentation.volume_in_ml
                              ) +
                              " ml\n                "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "expiry_date",
                      label: "Fecha de caducidad",
                      centered: "",
                      sortable: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.expiry_date) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Existencias [frascos]", centered: "" } },
                  [
                    _c(
                      "p",
                      {
                        class: {
                          "has-text-danger":
                            _vm.computeRemainingFlasks(props.row) == 0
                        }
                      },
                      [_vm._v(_vm._s(_vm.computeRemainingFlasks(props.row)))]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Frascos a solicitar", centered: "" } },
                  [
                    _c("b-numberinput", {
                      attrs: {
                        min: "1",
                        max: _vm.computeRemainingFlasks(props.row),
                        editable: false,
                        size: "is-small",
                        type: "is-light",
                        "controls-position": "compact",
                        disabled: _vm.computeRemainingFlasks(props.row) == 0
                      },
                      model: {
                        value: props.row.requestedFlasks,
                        callback: function($$v) {
                          _vm.$set(props.row, "requestedFlasks", $$v)
                        },
                        expression: "props.row.requestedFlasks"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Agregar al listado", centered: "" } },
                  [
                    _vm.computeRemainingFlasks(props.row) > 0
                      ? _c(
                          "a",
                          {
                            staticClass: "button is-small",
                            on: {
                              click: function($event) {
                                return _vm.addRequestedMedicine(props.row)
                              }
                            }
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "check" }
                            })
                          ],
                          1
                        )
                      : _c(
                          "a",
                          {
                            staticClass: "button is-small",
                            attrs: { disabled: "" }
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "check" }
                            })
                          ],
                          1
                        )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }