const getDefaultState = () => {
    return {
        oncologicOrdersRecords: {
            payers: [],
            fromDate: null,
            untilDate: null
        },
        nutritionalMixturesRecords: {
            payers: [],
            fromDate: null,
            untilDate: null
        },
        payerMedicines: {
            componentTypes: [],
            enabledPayerMedicines: null
        }
    };
};

const state = getDefaultState();

const mutations = {
    SET_PAYER_FILTER( state, data ){
        let duplicate = state[data.comesFromPage].payers.find( payer => payer.id == data.id );
        if( duplicate !== undefined ){
            return 0;
        }
        let obj = {};
        obj.id = data.id;
        obj.label = data.invoicing_data.business_name;
        state[data.comesFromPage].payers.push(obj);
    },
    REMOVE_PAYER_FILTER( state, data ){
        let index;
        state[data.comesFromPage].payers.map ( payer => {
            if ( payer.id == data.payerId ) {
                index = state[data.comesFromPage].payers.indexOf( payer );
            }
        });
        state[data.comesFromPage].payers.splice(index,1);
    },
    SET_DATE_RANGE( state, data ){
        state[data.comesFromPage].fromDate = data.dates[0];
        state[data.comesFromPage].untilDate = data.dates[1];
    },
    REMOVE_DATE_RANGE( state, comesFromPage ){
        state[comesFromPage].fromDate = null;
        state[comesFromPage].untilDate = null;
    },
    UPDATE_COMPONENT_TYPES( state, data ){
        state[data.comesFromPage].componentTypes = data.componentTypes;
    },
    REMOVE_COMPONENT_TYPE( state, data ){
        let index;
        state[data.comesFromPage].componentTypes.map ( componentType => {
            if ( componentType.id == data.componentTypeId ) {
                index = state[data.comesFromPage].componentTypes.indexOf( componentType );
            }
        });
        state[data.comesFromPage].componentTypes.splice(index,1); 
    },
    UPDATE_ENABLED_PAYER_MEDICINES( state, data ){
        state[data.comesFromPage].enabledPayerMedicines = data.value;
    },
    REMOVE_ENABLED_PAYER_MEDICINES( state, comesFromPage ){
        state[comesFromPage].enabledPayerMedicines = null;
    }
};

export default {
    namespaced: true,
    state,
    mutations
};
  