var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav", { staticClass: "level" }, [
        _c(
          "div",
          { staticClass: "level-left" },
          [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "level-right" },
          [
            _c(
              "router-link",
              {
                staticClass: "button serbitec-small-right-margin",
                attrs: {
                  to: { path: "deleted", query: { payer_id: _vm.payerId } },
                  append: ""
                }
              },
              [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [_c("font-awesome-icon", { attrs: { icon: "user-times" } })],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v(" Usuarios eliminados")])
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "button serbitec-small-right-margin",
                on: { click: _vm.addPayerUser }
              },
              [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [_c("font-awesome-icon", { attrs: { icon: "plus" } })],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v(" Agregar usuario")])
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("serbitec-payer-info", { attrs: { "payer-id": _vm.payerId } }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.addUserFormIsActive
        ? _c(
            "div",
            [
              _c("serbitec-user-form", {
                attrs: { "payer-id": _vm.payerId, "has-close-button": true },
                on: {
                  "close-form": function($event) {
                    _vm.addUserFormIsActive = false
                  },
                  success: _vm.userAdded,
                  error: _vm.userAddedError
                }
              }),
              _vm._v(" "),
              _c("br")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("serbitec-payer-users-table", {
        attrs: {
          "payer-id": _vm.payerId,
          payerUsers: _vm.users,
          "payer-users-count": _vm.payerUsersCount,
          loading: _vm.loading,
          page: _vm.page,
          pageSize: _vm.pageSize,
          sortField: _vm.sortField,
          sortOrder: _vm.sortOrder
        },
        on: {
          "update:loading": function($event) {
            _vm.loading = $event
          },
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:sortField": function($event) {
            _vm.sortField = $event
          },
          "update:sort-field": function($event) {
            _vm.sortField = $event
          },
          "update:sortOrder": function($event) {
            _vm.sortOrder = $event
          },
          "update:sort-order": function($event) {
            _vm.sortOrder = $event
          },
          "page-change": _vm.onPageChange,
          sort: _vm.onSort,
          "reset-password": _vm.resetPassword,
          "delete-user": _vm.deleteUser
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }