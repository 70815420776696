var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "b-tabs",
          { attrs: { type: "is-toggle", expanded: "" } },
          [
            _c(
              "b-tab-item",
              [
                _c("template", { slot: "header" }, [
                  _c(
                    "span",
                    [
                      _vm._v(" Mezclas "),
                      _c("b-tag", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(" " + _vm._s(_vm.mixturesCount) + " ")
                      ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("serbitec-quality-mixtures-table", {
                  attrs: {
                    "quality-type": "oncologic",
                    mixtures: _vm.mixtures,
                    "mixtures-count": _vm.mixturesCount,
                    loading: _vm.loading,
                    page: _vm.page,
                    pageSize: _vm.pageSize,
                    sortField: _vm.sortField,
                    sortOrder: _vm.sortOrder
                  },
                  on: {
                    "update:loading": function($event) {
                      _vm.loading = $event
                    },
                    "update:page": function($event) {
                      _vm.page = $event
                    },
                    "update:sortField": function($event) {
                      _vm.sortField = $event
                    },
                    "update:sort-field": function($event) {
                      _vm.sortField = $event
                    },
                    "update:sortOrder": function($event) {
                      _vm.sortOrder = $event
                    },
                    "update:sort-order": function($event) {
                      _vm.sortOrder = $event
                    },
                    "page-change": _vm.onPageChange,
                    sort: _vm.onSort,
                    "print-attempt": _vm.updatePrintAttempts,
                    "mixture-aconditioned": _vm.mixtureAconditioned
                  }
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "b-tab-item",
              [
                _c("template", { slot: "header" }, [
                  _c(
                    "span",
                    [
                      _vm._v(" Frascos "),
                      _c("b-tag", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v(" " + _vm._s(_vm.flasksCount) + " ")
                      ])
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("serbitec-quality-flasks-table", {
                  attrs: {
                    "quality-type": "oncologic",
                    flasks: _vm.flasks,
                    "flasks-count": _vm.flasksCount,
                    loading: _vm.loading,
                    page: _vm.flasksPage,
                    pageSize: _vm.flasksPageSize,
                    sortField: _vm.flasksSortField,
                    sortOrder: _vm.flasksSortOrder
                  },
                  on: {
                    "update:loading": function($event) {
                      _vm.loading = $event
                    },
                    "update:page": function($event) {
                      _vm.flasksPage = $event
                    },
                    "update:sortField": function($event) {
                      _vm.flasksSortField = $event
                    },
                    "update:sort-field": function($event) {
                      _vm.flasksSortField = $event
                    },
                    "update:sortOrder": function($event) {
                      _vm.flasksSortOrder = $event
                    },
                    "update:sort-order": function($event) {
                      _vm.flasksSortOrder = $event
                    },
                    "page-change": _vm.onFlasksPageChange,
                    sort: _vm.onFlasksSort,
                    "flask-aconditioned": _vm.flaskAconditioned
                  }
                })
              ],
              2
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { staticClass: "level is-mobile" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("div", { staticClass: "level-item" }, [
          _c("h1", { staticClass: "title" }, [
            _vm._v("Control de calidad de órdenes oncológicas")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }