<template>
    <div>
        <nav class="level is-mobile">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title">Revisión de órdenes oncológicas</h1>
                </div>
            </div>

            <div class="level-right" />
        </nav>
        <hr>
        <serbitec-mixtures-table
            :preparer-type="'oncologic'"
            :mixtures="orders"
            :mixtures-count="mixturesCount"

            :page.sync="page"
            :page-size.sync="pageSize"
            :sort-field.sync="sortField"
            :sort-order.sync="sortOrder"
            
            @page-change="onPageChange"
            @sort="onSort"
        />
    </div>
</template>

<script>
import serbitecMixturesTable from '../../../components/dashboard/mixtures-authorizer/mixtures-table.vue';
import gql from 'graphql-tag';

const query = gql` query ( $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String )
{
    oncologic_orders( flask_or_mixture_state_id: 1, page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction ) {
        id
        delivery_date
        patient{
            id
            name
        }
        medic {
            id
            payer {
                id
                invoicing_data {
                    id
                    business_name
                }
            }
        }
    }
    table_counts(count_type: "oncologic_orders", flask_or_mixture_state_id: 1) {
        oncologic_orders
    }
}`;

export default {
    components: {
        serbitecMixturesTable
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.$apollo.queries.orders.refetch();
            if ( from.path == '/oncologic_orders_authorizer/authorize' && to.query.success ) {
                vm.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha autorizado la orden oncológica'
                });
            }
            if ( from.path == '/oncologic_orders_authorizer/edit' && to.query.success ) {
                vm.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha editado y autorizado la orden oncológica'
                });
            }
        });
    },
    data () {
        return {
            page: 0,
            pageSize: 10,
            sortField: 'id',
            sortOrder: 'desc',
            mixturesCount: null,
        };
    },
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
    },
    apollo: {
        orders: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update ( data ) {
                this.mixturesCount = data.table_counts.oncologic_orders;
                return data.oncologic_orders;
            }
        },
    }
};
</script>
