<template>
<div>
    <div class="box">        
        <b-table
            :data="purchaseOrders"
        >
            <template slot-scope="props">
                <b-table-column
                    v-if="!isExternalWarehouse"
                    field="purchase_order_id"
                    label="Orden de compra"
                    width="250"
                    numeric
                    sortable
                    centered
                >
                    {{ props.row.purchase_order_id }}
                </b-table-column>

                <b-table-column label="Número de frascos o piezas" centered>
                    {{ props.row.flasks_in_order }}
                </b-table-column>

                <b-table-column
                    v-if="!isExternalWarehouse"
                    label="Costo por frasco o pieza"
                    centered
                >
                    $ {{ props.row.cost_in_cents_per_item / 100 }} MXN
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay órdenes de compra registradas en este lote, para dar de alta de click en el botón "Agregar orden de compra"</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</div>
</template>

<script>
export default {
    props: {
        purchaseOrders: {
            type: Array,
            default: () => []
        },
        batchId: {
            type: [Number, String],
            default: 0 | '0'
        },
        showForm: {
            type: Boolean,
            default: false
        },
        isExternalWarehouse: {
            type: Boolean,
            default: false
        }
    }
};
</script>