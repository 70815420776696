<template>
    <b-navbar
        :mobile-burger="login ? false : true"
        type="is-light"
    >
        <template slot="brand">
            <b-navbar-item v-if="!login && $mq !== 'desktop'" @click="$emit('open-sidebar')">
                    <font-awesome-icon icon="angle-double-right" />
                </b-navbar-item>
            <b-navbar-item :to="{ path: '/' }" tag="router-link">
                <img src="/images/SERBITEC_logo2.png">
            </b-navbar-item>
        </template>

        <template v-if="!login" slot="end">
            <b-navbar-item v-if="!user.isExternalUser" href="https://mezclas-dev-docs.vitenskap.tech" target="_blank">
                <font-awesome-icon class="serbitec-extra-small-right-margin" icon="question-circle" />
                Ayuda
            </b-navbar-item>
            <b-navbar-dropdown :label="user ? user.first_name : ''" hoverable right>
                <b-navbar-item @click="logout()" tag="a">
                    <font-awesome-icon class="serbitec-extra-small-right-margin" icon="sign-out-alt" />
                    Cerrar sesión
                </b-navbar-item>
            </b-navbar-dropdown>
        </template>
    </b-navbar>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        login: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.user,
        })
    },
    methods: {
        async logout() {
            this.$serbitec.startLoading();
            try {
                await this.$store.commit('user/RESET_USER');
                await this.$axios.post('/logout');
                location.reload();
            } catch (error) {
                this.$serbitec.finishLoading();
                this.$notify({
                    title: '¡Algo ha ido mal!',
                    type: 'error',
                    text: 'Intenta nuevamente o contacta al administrador'
                });
                console.error(error);
            }
        }
    }
};
</script>