<template>
    <div v-if="computeCalciumAndPhosphate">
        <b-message v-if="computeCalciumAndPhosphate.currentCalciumAndPhosphateConcentration >= 30.0" class="is-warning">
            La concentración actual de calcio y fosfato es: {{ computeCalciumAndPhosphate.currentCalciumAndPhosphateConcentration }} mEq por cada 1000 ml ({{ computeCalciumAndPhosphate.calciumAndPhosphateInMeq }} mEq en {{ computeCalciumAndPhosphate.volume }} ml). La mezcla podría no ser procesada ya que es mayor a 30 mEq por cada 1000 ml.
        </b-message>
        <b-message v-else-if="computeCalciumAndPhosphate.currentCalciumAndPhosphateConcentration != null">
            La concentración actual de calcio y fosfato es: {{ computeCalciumAndPhosphate.currentCalciumAndPhosphateConcentration }} mEq por cada 1000 ml ({{ computeCalciumAndPhosphate.calciumAndPhosphateInMeq }} mEq en {{ computeCalciumAndPhosphate.volume }} ml).
        </b-message>
    </div>
</template>

<script>
import { electrolyteInMl } from '../../../../utils';
import { mapState } from 'vuex';

export default {
    props: ['computedTotalVolume', 'electrolytes'],
    computed: {
        ...mapState({
            patient: state => state.nutritionalOrder.patient,
            totalVolume: state => state.nutritionalOrder.totalVolume,
        }),
        computeCalciumAndPhosphate () {
            var calciumInElectrolytes = 0.0;
            var phosphateInElectrolytes = 0.0;
            var calciumAndPhosphate = {};

            this.electrolytes.map ( electrolyte => {
                if ( electrolyte.quantity_in_ml ) {
                    calciumInElectrolytes += ( parseFloat(electrolyte.nutritional_field.ca_in_meq) * electrolyte.quantity_in_ml );
                    phosphateInElectrolytes += ( parseFloat(electrolyte.nutritional_field.po4_in_meq) * electrolyte.quantity_in_ml );
                }
                if ( electrolyte.quantity != 0 ) {
                    var quantityInMl = parseFloat( electrolyteInMl( electrolyte, this.patient.weight_in_kilograms ) );
                    calciumInElectrolytes += ( parseFloat(electrolyte.nutritional_field.ca_in_meq) * quantityInMl );
                    phosphateInElectrolytes += ( parseFloat(electrolyte.nutritional_field.po4_in_meq) * quantityInMl );
                }
            });

            if ( calciumInElectrolytes == 0.0 || phosphateInElectrolytes == 0.0 ) {
                return null;
            } else {
                calciumAndPhosphate.calciumAndPhosphateInMeq = ( calciumInElectrolytes + phosphateInElectrolytes ).toFixed(2);
                if ( this.totalVolume ) {
                    calciumAndPhosphate.volume = this.totalVolume;
                    // calciumAndPhosphate.calciumAndPhospateConcentrationRatio = ( calciumAndPhosphate.calciumAndPhosphateInMeq / this.totalVolume).toFixed(2);
                    calciumAndPhosphate.currentCalciumAndPhosphateConcentration = ( ( calciumAndPhosphate.calciumAndPhosphateInMeq / this.totalVolume ) * 1000 ).toFixed(2);
                } else {
                    calciumAndPhosphate.volume = this.computedTotalVolume;
                    // calciumAndPhosphate.calciumAndPhospateConcentrationRatio = ( calciumAndPhosphate.calciumAndPhosphateInMeq / this.computedTotalVolume ).toFixed(2);
                    calciumAndPhosphate.currentCalciumAndPhosphateConcentration = ( ( calciumAndPhosphate.calciumAndPhosphateInMeq / this.computedTotalVolume ) * 1000 ).toFixed(2);
                }
                return calciumAndPhosphate;
            }
        }
    }
};
</script>