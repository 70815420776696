var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "b-sidebar",
        {
          attrs: {
            type: _vm.type,
            fullheight: true,
            "can-cancel": _vm.canCancel,
            overlay: _vm.overlay,
            open: _vm.open
          },
          on: {
            close: function($event) {
              return _vm.$emit("close-sidebar")
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "p-1" },
            [
              _c(
                "b-menu",
                [
                  _c(
                    "b-menu-list",
                    { attrs: { label: "Menu" } },
                    _vm._l(_vm.menu, function(menuSection) {
                      return _c(
                        "div",
                        { key: menuSection.id },
                        [
                          menuSection.numberOfItemsAuthorized > 0
                            ? _c(
                                "b-menu-item",
                                {
                                  attrs: { disabled: "", expanded: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function(props) {
                                          return [
                                            _c(
                                              "p",
                                              { staticClass: "is-size-7" },
                                              [
                                                _vm._v(
                                                  "\n                                " +
                                                    _vm._s(menuSection.label) +
                                                    "\n                                "
                                                ),
                                                _c("b-icon", {
                                                  staticClass:
                                                    "is-pulled-right",
                                                  attrs: {
                                                    size: "is-small",
                                                    icon: props.expanded
                                                      ? "chevron-down"
                                                      : "chevron-up"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(" "),
                                  _vm._l(menuSection.items, function(item) {
                                    return _c(
                                      "div",
                                      { key: item.id },
                                      [
                                        item.isAuth
                                          ? _c(
                                              "b-menu-item",
                                              {
                                                attrs: {
                                                  tag: "router-link",
                                                  to: item.route
                                                },
                                                nativeOn: {
                                                  click: function($event) {
                                                    return _vm.$emit(
                                                      "close-sidebar"
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "template",
                                                  { slot: "label" },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass: "is-size-7"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        " +
                                                            _vm._s(item.label) +
                                                            "\n                                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              2
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }