var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.mixtures,
            loading: _vm.loading,
            paginated: "",
            "backend-pagination": "",
            total: _vm.mixturesCount,
            "per-page": _vm.pageSize,
            "aria-next-label": "Siguiente página",
            "aria-previous-label": "Página anterior",
            "aria-page-label": "Página",
            "aria-current-label": "Página actual",
            "backend-sorting": "",
            "default-sort": [_vm.sortField, _vm.sortOrder],
            detailed: "",
            "detail-key": "id",
            "show-detail-icon": _vm.hasPermissionToSeeDetails
          },
          on: { "page-change": _vm.changePage, sort: _vm.sort },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "id",
                        label: "Orden",
                        numeric: "",
                        sortable: ""
                      }
                    },
                    [
                      _vm.isNutritional
                        ? _c("p", [_vm._v("ON-" + _vm._s(props.row.order.id))])
                        : _vm.isOncologic
                        ? _c("p", [_vm._v("OO-" + _vm._s(props.row.id))])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isNutritional
                    ? _c(
                        "b-table-column",
                        { attrs: { label: "Lote", numeric: "" } },
                        [_c("p", [_vm._v("LN-" + _vm._s(props.row.id))])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Cliente" } }, [
                    _vm.isNutritional
                      ? _c("p", [
                          _vm._v(
                            _vm._s(
                              props.row.order.medic.payer.invoicing_data
                                .business_name
                            )
                          )
                        ])
                      : _vm.isOncologic
                      ? _c("p", [
                          _vm._v(
                            _vm._s(
                              props.row.medic.payer.invoicing_data.business_name
                            )
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Paciente" } }, [
                    _vm.isNutritional
                      ? _c("p", [_vm._v(_vm._s(props.row.order.patient.name))])
                      : _vm.isOncologic
                      ? _c("p", [_vm._v(_vm._s(props.row.patient.name))])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "delivery_date",
                        label: "Fecha de entrega",
                        sortable: "",
                        centered: ""
                      }
                    },
                    [
                      _vm.isNutritional
                        ? _c("p", [
                            _vm._v(
                              _vm._s(_vm.formatDate(props.row.delivery_date))
                            )
                          ])
                        : _vm.isOncologic
                        ? _c("p", [
                            _vm._v(
                              _vm._s(_vm.formatDate(props.row.delivery_date))
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Hora de entrega", centered: "" } },
                    [
                      _vm.isNutritional
                        ? _c("p", [
                            _vm._v(
                              _vm._s(_vm.formatTime(props.row.delivery_date))
                            )
                          ])
                        : _vm.isOncologic
                        ? _c("p", [
                            _vm._v(
                              _vm._s(_vm.formatTime(props.row.delivery_date))
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Estado" } },
                    [
                      _vm.isNutritional
                        ? _c(
                            "b-tag",
                            {
                              class: {
                                "is-success": props.row.state.id >= 8,
                                "is-danger": props.row.state.id == 3
                              }
                            },
                            [_vm._v(_vm._s(props.row.state.name))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isOncologic
                        ? _c(
                            "b-tag",
                            {
                              class: {
                                "is-success": _vm.orderIsReadyToBeSent(
                                  props.row
                                ),
                                "is-danger": _vm.orderIsCanceled(props.row)
                              }
                            },
                            [_vm._v(_vm._s(_vm.setOrderState(props.row)))]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Detalles", centered: "" } },
                    [
                      _vm.isNutritional
                        ? _c(
                            "router-link",
                            {
                              staticClass: "button is-light is-small",
                              attrs: {
                                to: {
                                  path: "mixture_details",
                                  query: { mixture_id: props.row.id }
                                },
                                append: ""
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "info-circle" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm.isOncologic
                        ? _c(
                            "router-link",
                            {
                              staticClass: "button is-light is-small",
                              attrs: {
                                to: {
                                  path: "order_details",
                                  query: { order_id: props.row.id }
                                },
                                append: ""
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "info-circle" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            },
            {
              key: "detail",
              fn: function(props) {
                return [
                  (_vm.isNutritional && props.row.state.id == 3) ||
                  (_vm.isOncologic && _vm.orderIsCanceled(props.row))
                    ? _c("b-message", { staticClass: "is-danger" }, [
                        _c("small", [_vm._v("Esta orden ha sido cancelada")])
                      ])
                    : _vm.isOncologic && _vm.orderIsReadyToBeSent(props.row)
                    ? _c(
                        "article",
                        { staticClass: "media" },
                        [
                          _c(
                            "b-field",
                            { attrs: { grouped: "", "group-multiline": "" } },
                            [
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-small",
                                    on: {
                                      click: function($event) {
                                        return _vm.confirmTraceabilityWithOptions(
                                          props.row.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "icon is-medium" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "map-marked-alt" }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Trazabilidad")])
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-small",
                                    attrs: {
                                      href:
                                        "/pdf/oncologic/orders/" +
                                        props.row.id +
                                        "/shipping_record",
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "icon is-medium" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "truck" }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Registro de envío")])
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-small",
                                    attrs: {
                                      href:
                                        "/pdf/oncologic/orders/" +
                                        props.row.id +
                                        "/receipt",
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "icon is-medium" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "receipt" }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Recibo")])
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-small",
                                    on: {
                                      click: function($event) {
                                        return _vm.confirmQuoteWithOptions(
                                          props.row.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "icon is-medium" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "file-invoice-dollar" }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Cotización")])
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _vm.setOrderState(props.row) != "Entregada"
                                ? _c("p", { staticClass: "control" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "button is-small is-danger",
                                        on: {
                                          click: function($event) {
                                            return _vm.confirmCancelMixture(
                                              props.row.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "icon is-medium" },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: "times-circle" }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Cancelar")])
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    : _vm.isOncologic
                    ? _c(
                        "b-message",
                        [
                          _c("small", [
                            _vm._v(
                              "Algún elemento de la orden no se encuentra en estado acondicinado, por lo tanto no se puede gerenar la papelería."
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-field",
                            {
                              staticClass: "serbitec-small-top-margin",
                              attrs: { grouped: "", "group-multiline": "" }
                            },
                            [
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-small",
                                    on: {
                                      click: function($event) {
                                        return _vm.confirmQuoteWithOptions(
                                          props.row.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "icon is-medium" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "file-invoice-dollar" }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Cotización")])
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _vm.setOrderState(props.row) != "Entregada"
                                ? _c("p", { staticClass: "control" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "button is-small is-danger",
                                        on: {
                                          click: function($event) {
                                            return _vm.confirmCancelMixture(
                                              props.row.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "icon is-medium" },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: "times-circle" }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Cancelar")])
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    : _vm.isNutritional && props.row.state.id >= 8
                    ? _c(
                        "article",
                        { staticClass: "media" },
                        [
                          _c(
                            "b-field",
                            { attrs: { grouped: "", "group-multiline": "" } },
                            [
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-small",
                                    on: {
                                      click: function($event) {
                                        return _vm.confirmTraceabilityWithOptions(
                                          props.row.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "icon is-medium" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "map-marked-alt" }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Trazabilidad")])
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-small",
                                    attrs: {
                                      href:
                                        "/pdf/nutritional/mixtures/" +
                                        props.row.id +
                                        "/shipping_record",
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "icon is-medium" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "truck" }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Registro de envío")])
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-small",
                                    attrs: {
                                      href:
                                        "/pdf/nutritional/mixtures/" +
                                        props.row.id +
                                        "/receipt",
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "icon is-medium" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "receipt" }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Recibo")])
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-small",
                                    on: {
                                      click: function($event) {
                                        return _vm.confirmQuoteWithOptions(
                                          props.row.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "icon is-medium" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "file-invoice-dollar" }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Cotización")])
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              props.row.id >= 4
                                ? _c("p", { staticClass: "control" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "button is-small",
                                        attrs: {
                                          href:
                                            "/pdf/nutritional/mixtures/" +
                                            props.row.id +
                                            "/preparer_order",
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "icon is-medium" },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: "mortar-pestle" }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("Orden de preparación")
                                        ])
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-small",
                                    on: {
                                      click: function($event) {
                                        return _vm.editComments(props.row)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "icon is-medium" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "comment-alt" }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Comentarios")])
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              props.row.state.id != 10
                                ? _c("p", { staticClass: "control" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "button is-small is-danger",
                                        on: {
                                          click: function($event) {
                                            return _vm.confirmCancelMixture(
                                              props.row.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "icon is-medium" },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: "times-circle" }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Cancelar")])
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    : _vm.isNutritional
                    ? _c(
                        "b-message",
                        [
                          _c("small", [
                            _vm._v(
                              "La mezcla nutricional no se encuentra en estado acondicinado, por lo tanto no se puede gerenar toda la papelería."
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "b-field",
                            {
                              staticClass: "serbitec-small-top-margin",
                              attrs: { grouped: "", "group-multiline": "" }
                            },
                            [
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "button is-small",
                                    on: {
                                      click: function($event) {
                                        return _vm.confirmQuoteWithOptions(
                                          props.row.id
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "icon is-medium" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "file-invoice-dollar" }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("Cotización")])
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              props.row.state.id >= 4
                                ? _c("p", { staticClass: "control" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "button is-small",
                                        attrs: {
                                          href:
                                            "/pdf/nutritional/mixtures/" +
                                            props.row.id +
                                            "/preparer_order",
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "icon is-medium" },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: "mortar-pestle" }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v("Orden de preparación")
                                        ])
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              props.row.state.id != 10
                                ? _c("p", { staticClass: "control" }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "button is-small is-danger",
                                        on: {
                                          click: function($event) {
                                            return _vm.confirmCancelMixture(
                                              props.row.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "icon is-medium" },
                                          [
                                            _c("font-awesome-icon", {
                                              attrs: { icon: "times-circle" }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Cancelar")])
                                      ]
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("No hay registros realizados.")])
                ]
              )
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            active: _vm.isQuoteModalActive,
            "has-modal-card": "",
            "trap-focus": "",
            "aria-role": "dialog",
            "aria-modal": ""
          },
          on: {
            "update:active": function($event) {
              _vm.isQuoteModalActive = $event
            }
          }
        },
        [
          _c("serbitec-quote-with-options-modal", {
            attrs: { "quote-id": _vm.quoteId, type: _vm.tableType },
            on: {
              close: function($event) {
                _vm.isQuoteModalActive = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }