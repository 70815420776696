<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                {{ comesFromEdit ? 'Editar medicamento' : 'Agregar medicamento' }}
            </p>
        </header>
        <section class="modal-card-body">
            <b-field
                v-if="!showComponentData"
                label="Componente"
                label-position="on-border"
                :type="{'is-danger' : $v.selectedComponent.$error }"
                :message="{
                    'Se requiere introducir el componente' : !$v.selectedComponent.required && $v.selectedComponent.$error
                }"
                expanded
            >
                <serbitec-components-autocomplete
                    with-component-type-ids="1,3,5,13,15"
                    @component-added="updateComponent"
                />
            </b-field>
            <b-field
                v-if="showComponentData"
                label="Componente"
                label-position="on-border"
            >
                <b-field>
                    <p class="control">
                        <span class="button" @click="unselectComponent">
                            <span class="icon is-small">
                                <font-awesome-icon icon="times-circle" />
                            </span>
                        </span>
                    </p>
                    <p class="control">
                        <span class="button is-static">
                            Genérico: {{ selectedComponent.name }}
                        </span>
                    </p>
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                label="Nombre comercial del medicamento"
                label-position="on-border"
                :type="{'is-danger' : $v.medicineCommercialName.$error }"
                :message="{
                    'Se requiere introducir el nombre comercial del medicamento' : !$v.medicineCommercialName.required && $v.medicineCommercialName.$error
                }"
                expanded>
                <b-input
                    v-model.trim="$v.medicineCommercialName.$model"
                    type="text">
                </b-input>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                label="Fabricante"
                label-position="on-border"
                :type="{'is-danger' : $v.selectedManufacturer.$error }"
                :message="{
                    'Se requiere introducir y seleccionar el fabricante' : !$v.selectedManufacturer.required && $v.selectedManufacturer.$error
                }"
                expanded
            >
                <serbitec-manufacturer-select
                    :selectedManufacturer="selectedManufacturer"
                    @manufacturer-selected="updateSelectedManufacturer"
                />
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                label="Unidad del componente activo"
                label-position="on-border"
                :type="{'is-danger' : $v.selectedPresentationUnit.$error }"
                :message="{
                    'Se requiere seleccionar la unidad del componente activo' : !$v.selectedPresentationUnit.required && $v.selectedPresentationUnit.$error
                }"
                expanded
            >
                <serbitec-unit-select
                    :selectedUnit="selectedPresentationUnit"
                    @unit-selected="updateSelectedUnit"
                />
            </b-field> 
            <b-field
                class="serbitec-small-top-margin"
                label="Comentarios"
                label-position="on-border"
            >
                <b-input
                v-model="medicineComments"
                maxlength="200"
                type="textarea">
                </b-input>
            </b-field>
            <b-field
                label="¿El medicamento es liofilizado?"
                expanded
            >
                    <div>
                        <b-checkbox
                            v-model="medicineIsLiofilized"
                        >
                            Es liofilizado
                        </b-checkbox>
                    </div>
            </b-field>

            <div v-if="medicineIsLiofilized">
                <hr>
                <b-field
                    class="serbitec-small-top-margin"
                    label="Diluyente para reconstitución"
                    :type="{'is-danger' : $v.selectedThinner.$error }"
                    :message="{
                        'Se requiere introducir y seleccionar el diluyente para reconstitución' : !$v.selectedThinner.required && $v.selectedThinner.$error
                    }"
                    expanded
                >
                    <serbitec-component-select
                        :selected-component="selectedThinner"
                        with-component-type-ids="6,5,13"
                        @component-selected="updateThinner"
                    />
                </b-field>
                <b-field
                    class="serbitec-small-top-margin"
                    label="Estabilidad de reconstitución a temperatura ambiente"
                    label-position="on-border"
                    :type="{'is-danger' : $v.reconstitutionStabilityAmbient.$error }"
                    :message="{
                        'Se requiere introducir la estabilidad de reconstitución a temperatura ambiente' : !$v.reconstitutionStabilityAmbient.required && $v.reconstitutionStabilityAmbient.$error,
                        'Se requiere introducir un número decimal' : !$v.reconstitutionStabilityAmbient.decimal && $v.reconstitutionStabilityAmbient.$error
                    }"
                    expanded
                >
                    <b-field
                    :type="{'is-danger' : $v.reconstitutionStabilityAmbient.$error }">
                        <b-input
                        v-model.trim="$v.reconstitutionStabilityAmbient.$model"
                        type="text"
                        expanded>
                        </b-input>
                        <p class="control">
                            <span class="button is-static">h</span>
                        </p>
                    </b-field>
                </b-field>
                <b-field
                    class="serbitec-small-top-margin"
                    label="Estabilidad de reconstitución en refrigeración"
                    label-position="on-border"
                    :type="{'is-danger' : $v.reconstitutionStabilityCooled.$error }"
                    :message="{
                        'Se requiere introducir la estabilidad de reconstitución en refrigeración' : !$v.reconstitutionStabilityCooled.required && $v.reconstitutionStabilityCooled.$error,
                        'Se requiere introducir un número decimal' : !$v.reconstitutionStabilityCooled.decimal && $v.reconstitutionStabilityCooled.$error
                    }"
                    expanded
                >
                    <b-field
                    :type="{'is-danger' : $v.reconstitutionStabilityCooled.$error }">
                        <b-input
                        v-model.trim="$v.reconstitutionStabilityCooled.$model"
                        type="text"
                        expanded>
                        </b-input>
                        <p class="control">
                            <span class="button is-static">h</span>
                        </p>
                    </b-field>
                </b-field>
                <b-field
                    class="serbitec-small-top-margin serbitec-medium-bottom-margin"
                    label="Factor para calcular el volumen final"
                    label-position="on-border"
                    :type="{'is-danger' : $v.finalVolumeFactor.$error }"
                    :message="[
                        !$v.finalVolumeFactor.required && $v.finalVolumeFactor.$error ? 'Se requiere introducir el factor para calcular el volumen final. El factor para calcular el volumen final se obtiene dividiento el volumen final entre el volumen de la presentación' : 'El factor para calcular el volumen final se obtiene dividiento el volumen final entre el volumen de la presentación',
                        { 'Se requiere introducir un número decimal' : !$v.finalVolumeFactor.decimal && $v.finalVolumeFactor.$error }
                    ]"
                    expanded
                >
                    <b-field
                    :type="{'is-danger' : $v.finalVolumeFactor.$error }">
                        <b-input
                        v-model.trim="$v.finalVolumeFactor.$model"
                        type="text"
                        expanded>
                        </b-input>
                    </b-field>
                </b-field>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                Cancelar
            </button>
            <button class="button is-primary" @click="submitForm">
                {{ comesFromEdit ? 'Editar' : 'Agregar' }}
            </button>
        </footer>
    </div>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators';
import axios from 'axios';
import serbitecComponentsAutocomplete from '../../utils/autocompletes/components.vue';
import serbitecManufacturerSelect from '../../utils/select/manufacturers.vue';
import serbitecUnitSelect from '../../utils/select/unit.vue';
import serbitecComponentSelect from '../../utils/select/components.vue';

export default {
    props: {
        medicine: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        serbitecComponentsAutocomplete,
        serbitecManufacturerSelect,
        serbitecUnitSelect,
        serbitecComponentSelect
    },
    computed: {
        comesFromEdit: function () {
            if ( this.medicine ) {
                return true;
            } else {
                return false;
            }
        }
    },
    beforeMount () {
        if ( this.medicine ) {
            this.setData();
        }
    },
    data () {
        return {
            showComponentData: false,
            selectedComponent: {},
            medicineCommercialName: '',
            selectedManufacturer: {},
            selectedPresentationUnit: {},
            medicineComments: '',
            medicineIsLiofilized: false,
            selectedThinner: {},
            reconstitutionStabilityAmbient: '',
            reconstitutionStabilityCooled: '',
            finalVolumeFactor: ''
        };
    },
    methods: {
        updateComponent (component) {
            this.selectedComponent = component;
            this.showComponentData = true;
        },
        unselectComponent () {
            this.selectedComponent = {};
            this.showComponentData = false;
        },
        updateSelectedManufacturer (manufacturer) {
            this.selectedManufacturer = manufacturer;
        },
        updateSelectedUnit (unit) {
            this.selectedPresentationUnit = unit;
        },
        updateThinner (thinner) {
            this.selectedThinner = thinner;
        },
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                if ( this.comesFromEdit ) {
                    this.editMedicine();
                } else {
                    this.addMedicine();
                }
            }
        },
        setData () {
            this.updateComponent (this.medicine.component);
            this.medicineCommercialName = this.medicine.commercial_name;
            this.selectedManufacturer = this.medicine.manufacturer;
            this.selectedPresentationUnit = this.medicine.component_unit;
            this.medicineComments = this.medicine.comments;
            if ( this.medicine.liofilized_oncologic_fields.length ) {
                this.medicineIsLiofilized = true;
                this.selectedThinner = this.medicine.liofilized_oncologic_fields[0].reconstitution_thinner;
                this.reconstitutionStabilityAmbient = this.medicine.liofilized_oncologic_fields[0].reconstitution_stability_ambient_in_hours;
                this.reconstitutionStabilityCooled = this.medicine.liofilized_oncologic_fields[0].reconstitution_stability_cooled_in_hours;
                this.finalVolumeFactor = this.medicine.liofilized_oncologic_fields[0].reconstitution_final_volume_factor;
            }
        },
        getRequestParams () {
            var requesParams = {};

            if ( this.medicineIsLiofilized ) {
                requesParams = {
                    component_id: this.selectedComponent.id,
                    commercial_name: this.medicineCommercialName,
                    manufacturer_id: this.selectedManufacturer.id,
                    component_unit_id: this.selectedPresentationUnit.id,
                    comments: this.medicineComments,
                    is_liofilized: this.medicineIsLiofilized,
                    reconstitution_thinner_id: this.selectedThinner.id,
                    reconstitution_stability_ambient_in_hours: this.reconstitutionStabilityAmbient,
                    reconstitution_stability_cooled_in_hours: this.reconstitutionStabilityCooled,
                    reconstitution_final_volume_factor: this.finalVolumeFactor
                };
            } else {
               requesParams = {
                    component_id: this.selectedComponent.id,
                    commercial_name: this.medicineCommercialName,
                    manufacturer_id: this.selectedManufacturer.id,
                    component_unit_id: this.selectedPresentationUnit.id,
                    comments: this.medicineComments,
                    is_liofilized: this.medicineIsLiofilized,
                }; 
            }

            return requesParams;
        },
        addMedicine () {
            var postParams = this.getRequestParams();
            this.makeRequest('/api/medicines/oncologic', axios.post, postParams);
        },
        editMedicine () {
            var putParams = this.getRequestParams();
            var url = '/api/medicines/oncologic/' + this.medicine.id;
            this.makeRequest(url, axios.put, putParams);
        },
        makeRequest ( url, method, params ) {
            const loader = this.$buefy.loading.open();
            method(url,params)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha añadido o editado el medicamento'
                });
                loader.close();
                this.$emit('success');
                this.$emit('close');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                loader.close();
            }.bind(this));
        }
    },
    validations() {
        if ( this.medicineIsLiofilized ) {
            return {
                medicineCommercialName: {
                    required
                },
                selectedComponent: {
                    required
                },
                selectedManufacturer: {
                    required
                },
                selectedPresentationUnit: {
                    required
                },
                selectedThinner: {
                    required
                },
                reconstitutionStabilityAmbient: {
                    required,
                    decimal
                },
                reconstitutionStabilityCooled: {
                    required,
                    decimal
                },
                finalVolumeFactor: {
                    required,
                    decimal
                }
            };
        } else {
            return {
                medicineCommercialName: {
                    required
                },
                selectedComponent: {
                    required
                },
                selectedManufacturer: {
                    required
                },
                selectedPresentationUnit: {
                    required
                }
            };
        }
    }
};
</script>