var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.mixtures,
            loading: _vm.loading,
            paginated: "",
            "backend-pagination": "",
            total: _vm.mixturesCount,
            "per-page": _vm.pageSize,
            "aria-next-label": "Siguiente página",
            "aria-previous-label": "Página anterior",
            "aria-page-label": "Página",
            "aria-current-label": "Página actual",
            "backend-sorting": "",
            "default-sort": [_vm.sortField, _vm.sortOrder],
            detailed: _vm.isOncologic,
            "detail-key": "id"
          },
          on: { "page-change": _vm.changePage, sort: _vm.sort },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "id",
                        label: "Orden",
                        numeric: "",
                        sortable: ""
                      }
                    },
                    [
                      _vm.isNutritional
                        ? _c("p", [_vm._v("ON-" + _vm._s(props.row.order.id))])
                        : _vm.isOncologic
                        ? _c("p", [_vm._v("OO-" + _vm._s(props.row.id))])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isNutritional
                    ? _c(
                        "b-table-column",
                        { attrs: { label: "Lote", numeric: "" } },
                        [_c("p", [_vm._v("LN-" + _vm._s(props.row.id))])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Cliente" } }, [
                    _vm.isNutritional
                      ? _c("p", [
                          _vm._v(
                            _vm._s(
                              props.row.order.medic.payer.invoicing_data
                                .business_name
                            )
                          )
                        ])
                      : _vm.isOncologic
                      ? _c("p", [
                          _vm._v(
                            _vm._s(
                              props.row.medic.payer.invoicing_data.business_name
                            )
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Paciente" } }, [
                    _vm.isNutritional
                      ? _c("p", [_vm._v(_vm._s(props.row.order.patient.name))])
                      : _vm.isOncologic
                      ? _c("p", [_vm._v(_vm._s(props.row.patient.name))])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "delivery_date",
                        label: "Fecha de entrega",
                        sortable: ""
                      }
                    },
                    [
                      _vm.isNutritional
                        ? _c("p", [
                            _vm._v(
                              _vm._s(_vm.formatDate(props.row.delivery_date))
                            )
                          ])
                        : _vm.isOncologic
                        ? _c("p", [
                            _vm._v(
                              _vm._s(_vm.formatDate(props.row.delivery_date))
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Enviada", centered: "" } },
                    [
                      _vm.hasNotBeenAconditioned(props.row)
                        ? _c(
                            "a",
                            {
                              staticClass: "button is-light is-small",
                              on: {
                                click: function($event) {
                                  return _vm.$emit("order-sent", props.row.id)
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "motorcycle" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "button is-light is-small",
                              attrs: { disabled: "" }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "motorcycle" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Entregada", centered: "" } },
                    [
                      _vm.hasBeenSent(props.row)
                        ? _c(
                            "a",
                            {
                              staticClass: "button is-light is-small",
                              on: {
                                click: function($event) {
                                  return _vm.$emit(
                                    "order-delivered",
                                    props.row.id
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "clipboard-check" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "button is-light is-small",
                              attrs: { disabled: "" }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "clipboard-check" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                    ]
                  )
                ]
              }
            },
            {
              key: "detail",
              fn: function(props) {
                return [
                  _vm._l(props.row.mixtures, function(mixture) {
                    return _c(
                      "div",
                      { key: mixture.id },
                      [
                        _c("small", [
                          _vm._v("Mezcla con lote: "),
                          _c("strong", [_vm._v("LO-" + _vm._s(mixture.id))])
                        ]),
                        _vm._v(" "),
                        _c("b-tag", [
                          _vm._v("Estado: " + _vm._s(mixture.state.name))
                        ])
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm._l(props.row.requested_flasks, function(flask) {
                    return _c(
                      "div",
                      { key: flask.id },
                      [
                        _c("small", [
                          _vm._v("Frasco con lote: "),
                          _c("strong", [_vm._v("FO-" + _vm._s(flask.id))])
                        ]),
                        _vm._v(" "),
                        _c("b-tag", [
                          _vm._v("Estado: " + _vm._s(flask.state.name))
                        ])
                      ],
                      1
                    )
                  })
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("No hay registros realizados.")])
                ]
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }