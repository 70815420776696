var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "level" }, [
      _c(
        "div",
        { staticClass: "level-left" },
        [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" })
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _vm.elements
      ? _c(
          "div",
          [
            _c("div", { staticClass: "box" }, [
              _c("h1", { staticClass: "title has-text-centered" }, [
                _vm._v(
                  "Orden: OO-" +
                    _vm._s(_vm.orderId) +
                    " | Lote LO-" +
                    _vm._s(_vm.mixtureId)
                )
              ])
            ]),
            _vm._v(" "),
            _c("serbitec-horizontal-order-data", {
              attrs: { medic: _vm.medic, patient: _vm.patient }
            }),
            _vm._v(" "),
            _vm.isTransfer
              ? _c("b-message", [
                  _vm._v("\n            Esta mezcla es un "),
                  _c("strong", [_vm._v("Trasvase")])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("serbitec-prepare-order", {
              attrs: {
                "return-url": "/oncologic_preparation_area",
                "mixture-id": _vm.mixtureId,
                elements: _vm.elements
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }