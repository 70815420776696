<template>
    <div>
        <notifications
        animation-type="velocity"
        :animation="this.$animation"
        position="top right"
        classes="serbitec-light"
        :speed="500" :max="5"/>
        <b-loading :active.sync="loading"></b-loading>
        <div v-for="request in externalWarehouseRequests" :key="request.id" class="box">
            <nav class="level">
                <div class="level-left">
                    <h1>Número de pedido: <strong>{{ request.id }}</strong></h1>
                </div>

                <div class="level-right">
                    <button v-if="comesFromExternalWarehouse" class="button" @click="cancelRequest( request.id )">
                        <span class="icon">
                            <font-awesome-icon icon="times-circle" />
                        </span>
                        <span>Cancelar solicitud</span>
                    </button>
                </div>
            </nav>
            <b-table
                :data="request.items"
                :checked-rows.sync="request.checkedRows"
                checkable>
                    <template slot-scope="props">
                        <b-table-column label="Lote" numeric>
                            <p><strong v-if="props.row.warehouse_item.prefix">{{props.row.warehouse_item.prefix.prefix}}-</strong> {{ props.row.warehouse_item.batch }}</p>
                        </b-table-column>

                        <b-table-column label="Nombre">
                            {{ props.row.warehouse_item.medicine_presentation.medicine.commercial_name }}
                        </b-table-column>

                        <b-table-column label="Presentación" centered>
                            <b-tag
                            v-if="isPieceItem( props.row )">
                                {{ props.row.warehouse_item.medicine_presentation.component_quantity }} {{ props.row.warehouse_item.medicine_presentation.medicine.component_unit.symbol }}
                            </b-tag>
                            <b-tag
                            v-else-if="isOncologicItem( props.row )">
                                {{ props.row.warehouse_item.medicine_presentation.component_quantity }} {{ props.row.warehouse_item.medicine_presentation.medicine.component_unit.symbol }} en {{ props.row.warehouse_item.medicine_presentation.volume_in_ml }} ml
                            </b-tag>
                            <b-tag v-else>
                                {{ props.row.warehouse_item.medicine_presentation.volume_in_ml }} ml
                            </b-tag>
                        </b-table-column>

                        <b-table-column label="Fecha de caducidad" centered>
                            {{ props.row.warehouse_item.expiry_date }}
                        </b-table-column>

                        <b-table-column label="Frascos solicitados" centered>
                            {{ props.row.number_of_flasks }}
                        </b-table-column>
                    </template>
            </b-table>
            <b-field style="margin-top:15px">
                <p class="control has-text-right">
                    <button 
                    class="button is-primary"
                    @click="checkRequest( request.id )"
                    :disabled="request.checkedRows.length != request.items.length">
                        <span class="icon">
                            <font-awesome-icon icon="check-circle" />
                        </span>
                        <span v-if="comesFromExternalWarehouse">Solicitud entregada</span>
                        <span v-else>Solicitud recibida</span>
                    </button>
                </p>
            </b-field>
        </div>
        <b-message v-if="externalWarehouseRequestIsEmpty">
            No hay solicitudes pendientes en el almacén
            <br>
            <small
            v-if="!comesFromExternalWarehouse">
            Para realizar una solicitud vaya a la pantalla de solicitar medicamento o de click en el siguiente <router-link to="/oncologic_preparation_area_supplies/request_medicines">enlace</router-link></small>
        </b-message>
    </div>
</template>

<script>
import axios from 'axios';
import gql from 'graphql-tag';
const oncologicWarehouseQuery = gql` query ( $is_checked_by_warehouse_user: Boolean, $is_checked_by_preparer_user: Boolean )
{
    oncologic_warehouse_requests(is_checked_by_warehouse_user: $is_checked_by_warehouse_user, is_checked_by_preparer_user: $is_checked_by_preparer_user, order_by: "id", order_direction: "asc") {
        id
        items {
            id
            number_of_flasks
            request{
                id
            }
            warehouse_item {
                prefix {
                    prefix
                }
                batch
                expiry_date
                medicine_presentation {
                    component_quantity
                    volume_in_ml
                    medicine {
                        component_unit {
                            symbol
                        }
                        commercial_name
                        component {
                            id
                            component_type {
                                id
                            }
                        }
                    }
                }
            }
        }
    }
}`;

function initialDataState () {
    return {
        loading: false,
        isCheckedByWarehouseUser: null,
        isCheckedByPreparerUser: null,
        externalWarehouseRequestIsEmpty: false
    };
}

export default {
    props: ['comesFromExternalWarehouse'],
    beforeMount () {
        this.$apollo.queries.externalWarehouseRequests.refetch();
        if ( this.comesFromExternalWarehouse ) {
            this.isCheckedByWarehouseUser = false;
            this.isCheckedByPreparerUser = false;
        } else {
            this.isCheckedByWarehouseUser = true;
            this.isCheckedByPreparerUser = false;
        }
    },
    data: initialDataState,
    watch: {
        externalWarehouseRequests ( newValue ) {
            if ( newValue === undefined || newValue.length == 0 ) {
                return this.externalWarehouseRequestIsEmpty = true;
            } else {
                return this.externalWarehouseRequestIsEmpty = false;
            }
        }
    },
    methods: {
        cancelRequest ( requestId ) {
            var url = '/api/medicine_warehouse/requests/oncologic/' + requestId;
            this.makeRequest(url, axios.delete);
        },
        checkRequest ( requestId ) {
            if ( this.comesFromExternalWarehouse ) {
                var url = '/api/medicine_warehouse/requests/oncologic/' + requestId + '/warehouse_check';
            }
            if ( !this.comesFromExternalWarehouse ) {
                var url = '/api/medicine_warehouse/requests/oncologic/' + requestId + '/preparer_check';
            }
            this.makeRequest(url, axios.post);
        },
        makeRequest ( url, method ) {
            this.loading = true;
            method(url)
            .then(function () {
                this.$apollo.queries.externalWarehouseRequests.refetch();
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha registrado el movimiento del almacén'
                });
                this.$emit('request-completed');
            }.bind(this))
            .catch(function (error) {
                console.error(error);

                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
            this.loading = false;
        },
        isOncologicItem ( batch ) {
            return ( batch.warehouse_item.medicine_presentation.medicine.component.component_type.id == 1 );
        },
        isPieceItem ( batch ) {
            return ( batch.warehouse_item.medicine_presentation.medicine.component.component_type.id == 8
            || batch.warehouse_item.medicine_presentation.medicine.component.component_type.id == 12 );
        }
    },
    apollo: {
        externalWarehouseRequests: {
            fetchPolicy: 'cache-and-network',
            query: oncologicWarehouseQuery,
            variables () {
                return {
                    is_checked_by_warehouse_user: this.isCheckedByWarehouseUser,
                    is_checked_by_preparer_user: this.isCheckedByPreparerUser
                };
            },
            watchLoading (isLoading) {
                this.loading = isLoading;
            },
            update (data) {
                data.oncologic_warehouse_requests.map( request => {
                    return this.$set( request,'checkedRows', [] );
                });
                return data.oncologic_warehouse_requests;
            },
        },
    }
};
</script>