var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("hr"),
      _vm._v(" "),
      _c("h1", { staticClass: "subtitle" }, [_vm._v("Aditivos")]),
      _vm._v(" "),
      _vm._l(_vm.aditives, function(aditive) {
        return _c("div", { key: aditive.id }, [
          !aditive.name.toLowerCase().includes("selenio")
            ? _c(
                "div",
                {
                  staticClass: "columns",
                  staticStyle: { "padding-bottom": "10px" }
                },
                [
                  _c("div", { staticClass: "column" }, [
                    _c(
                      "div",
                      { staticClass: "field" },
                      [
                        _c(
                          "b-checkbox",
                          {
                            attrs: { value: aditive.showInput },
                            on: {
                              input: function($event) {
                                return _vm.clearItemOnFalse($event, aditive)
                              }
                            }
                          },
                          [_c("small", [_vm._v(_vm._s(aditive.name))])]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "column" },
                    [
                      aditive.showInput
                        ? _c(
                            "b-field",
                            { attrs: { horizontal: "", expanded: "" } },
                            [
                              _c(
                                "b-field",
                                [
                                  _c("b-input", {
                                    directives: [
                                      {
                                        name: "cleave",
                                        rawName: "v-cleave",
                                        value: _vm.masks.custom,
                                        expression: "masks.custom"
                                      }
                                    ],
                                    attrs: {
                                      value: aditive.quantity,
                                      placeholder: "10.00",
                                      type: "text",
                                      expanded: ""
                                    },
                                    on: {
                                      input: function($event) {
                                        return _vm.updateAditiveQuantity(
                                          $event,
                                          aditive.id
                                        )
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  aditive.units.length < 2
                                    ? _c("p", { staticClass: "control" }, [
                                        _c(
                                          "span",
                                          { staticClass: "button is-static" },
                                          [_vm._v(_vm._s(aditive.unitSymbol))]
                                        )
                                      ])
                                    : _c(
                                        "b-field",
                                        _vm._l(aditive.units, function(unit) {
                                          return _c(
                                            "b-radio-button",
                                            {
                                              key: unit.id,
                                              attrs: {
                                                value: aditive.unitSymbol,
                                                "native-value": unit
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.updateAditiveUnitSymbol(
                                                    $event,
                                                    aditive.id
                                                  )
                                                }
                                              }
                                            },
                                            [_c("span", [_vm._v(_vm._s(unit))])]
                                          )
                                        }),
                                        1
                                      )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  aditive.units.length > 1 && aditive.showInput
                    ? _c(
                        "div",
                        { staticClass: "column is-narrow" },
                        [
                          aditive.unitSymbol == "ml"
                            ? _c("b-field", [
                                _c("p", { staticClass: "control" }, [
                                  _c(
                                    "span",
                                    { staticClass: "button is-static" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          (
                                            aditive.quantity * aditive.factor
                                          ).toFixed(2)
                                        ) +
                                          " " +
                                          _vm._s(aditive.units[0])
                                      )
                                    ]
                                  )
                                ])
                              ])
                            : _c("b-field", [
                                _c("p", { staticClass: "control" }, [
                                  _c(
                                    "span",
                                    { staticClass: "button is-static" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          (
                                            aditive.quantity / aditive.factor
                                          ).toFixed(2)
                                        ) +
                                          " " +
                                          _vm._s(aditive.units[1])
                                      )
                                    ]
                                  )
                                ])
                              ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }