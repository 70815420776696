var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "level" }, [
      _c(
        "div",
        { staticClass: "level-left" },
        [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" }, [
        _c(
          "a",
          {
            staticClass: "button",
            attrs: {
              href:
                "/reports/warehouse/stock?location=internal&doc_type=pdf&payer_ids=all&type=npt",
              target: "_blank"
            }
          },
          [
            _c(
              "span",
              { staticClass: "icon" },
              [_c("font-awesome-icon", { attrs: { icon: "print" } })],
              1
            ),
            _vm._v(" "),
            _c("span", [_vm._v("Imprimir existencias")])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box" },
      [
        _c("serbitec-nutritional-preparation-area-update-supplies", {
          attrs: {
            "comes-from-cycle": true,
            "enable-weekly-cycle-input": true,
            "internal-warehouse-type": "nutritional"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }