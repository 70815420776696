<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Agregar medicamento
            </p>
        </header>
        <section class="modal-card-body">
            <b-field
                v-if="!showComponentData"
                label="Componente"
                label-position="on-border"
                :type="{'is-danger' : $v.selectedComponent.$error }"
                :message="{
                    'Se requiere introducir el componente' : !$v.selectedComponent.required && $v.selectedComponent.$error
                }"
                expanded
            >
                <serbitec-components-autocomplete
                    without-component-type-ids="1,9,13"
                    @component-added="updateComponent"
                />
            </b-field>
            <b-field
                v-if="showComponentData"
                label="Componente"
                label-position="on-border"
            >
                <b-field>
                    <p class="control">
                        <span class="button" @click="unselectComponent">
                            <span class="icon is-small">
                                <font-awesome-icon icon="times-circle" />
                            </span>
                        </span>
                    </p>
                    <p class="control">
                        <span class="button is-static">
                            Genérico: {{ selectedComponent.name }}
                        </span>
                    </p>
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                label="Nombre comercial del medicamento"
                label-position="on-border"
                :type="{'is-danger' : $v.medicineCommercialName.$error }"
                :message="{
                    'Se requiere introducir el nombre comercial del medicamento' : !$v.medicineCommercialName.required && $v.medicineCommercialName.$error
                }"
                expanded>
                <b-input
                    v-model.trim="$v.medicineCommercialName.$model"
                    type="text">
                </b-input>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                label="Fabricante"
                label-position="on-border"
                :type="{'is-danger' : $v.selectedManufacturer.$error }"
                :message="{
                    'Se requiere introducir y seleccionar el fabricante' : !$v.selectedManufacturer.required && $v.selectedManufacturer.$error
                }"
                expanded
            >
                <serbitec-manufacturer-select
                    :selectedManufacturer="selectedManufacturer"
                    @manufacturer-selected="updateSelectedManufacturer"
                />
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                label="Unidad del componente activo"
                label-position="on-border"
                :type="{'is-danger' : $v.selectedPresentationUnit.$error }"
                :message="{
                    'Se requiere seleccionar la unidad del componente activo' : !$v.selectedPresentationUnit.required && $v.selectedPresentationUnit.$error
                }"
                expanded
            >
                <serbitec-unit-select
                    :selectedUnit="selectedPresentationUnit"
                    @unit-selected="updateSelectedUnit"
                />
            </b-field> 
            <b-field
                class="serbitec-small-top-margin"
                label="Comentarios"
                label-position="on-border"
            >
                <b-input
                v-model="medicineComments"
                maxlength="200"
                type="textarea">
                </b-input>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                Cancelar
            </button>
            <button class="button is-primary" @click="submitForm">
                {{ comesFromEdit ? 'Editar' : 'Agregar' }}
            </button>
        </footer>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import serbitecComponentsAutocomplete from '../../utils/autocompletes/components.vue';
import serbitecManufacturerSelect from '../../utils/select/manufacturers.vue';
import serbitecUnitSelect from '../../utils/select/unit.vue';

export default {
    props: {
        medicine: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        serbitecComponentsAutocomplete,
        serbitecManufacturerSelect,
        serbitecUnitSelect
    },
    computed: {
        comesFromEdit: function () {
            if ( this.medicine ) {
                return true;
            } else {
                return false;
            }
        }
    },
    beforeMount () {
        if ( this.medicine ) {
            this.setData();
        }
    },
    data () {
        return {
            showComponentData: false,
            selectedComponent: {},
            medicineCommercialName: '',
            selectedManufacturer: {},
            selectedPresentationUnit: {},
            medicineComments: '',
        };
    },
    methods: {
        updateComponent (component) {
            this.selectedComponent = component;
            this.showComponentData = true;
        },
        unselectComponent () {
            this.selectedComponent = {};
            this.showComponentData = false;
        },
        updateSelectedManufacturer (manufacturer) {
            this.selectedManufacturer = manufacturer;
        },
        updateSelectedUnit (unit) {
            this.selectedPresentationUnit = unit;
        },
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                if ( this.comesFromEdit ) {
                    this.editMedicine();
                } else {
                    this.addMedicine();
                }
            }
        },
        setData () {
            this.updateComponent (this.medicine.component);
            this.medicineCommercialName = this.medicine.commercial_name;
            this.selectedManufacturer = this.medicine.manufacturer;
            this.selectedPresentationUnit = this.medicine.component_unit;
            this.medicineComments = this.medicine.comments;
        },
        getRequestParams () {
            return {
                component_id: this.selectedComponent.id,
                commercial_name: this.medicineCommercialName,
                manufacturer_id: this.selectedManufacturer.id,
                component_unit_id: this.selectedPresentationUnit.id,
                comments: this.medicineComments
            };
        },
        addMedicine () {
            var postParams = this.getRequestParams();
            this.makeRequest('/api/medicines/nutritional', axios.post, postParams);
        },
        editMedicine () {
            var putParams = this.getRequestParams();
            var url = '/api/medicines/nutritional/' + this.medicine.id;
            this.makeRequest(url, axios.put, putParams);
        },
        makeRequest ( url, method, params ) {
            const loader = this.$buefy.loading.open();
            method(url,params)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha añadido o editado el medicamento'
                });
                loader.close();
                this.$emit('success');
                this.$emit('close');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                loader.close();
            }.bind(this));
        }
    },
    validations: {
        medicineCommercialName: {
            required
        },
        selectedComponent: {
            required
        },
        selectedManufacturer: {
            required
        },
        selectedPresentationUnit: {
            required
        }
    }
};
</script>