<template>
    <div>
        <b-loading :active.sync="loading"></b-loading>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>

            <div class="level-right">
                <a class="button" :href="'/pdf/nutritional/mixtures/' + this.mixtureId + '/client_report'" target="_blank">
                    <span class="icon">
                        <font-awesome-icon icon="print" />
                    </span>
                    <span> Imprimir</span>
                </a>
            </div>
        </nav>
        <hr>
        <div v-if="mixture">
            <div class="box">
                <h1 class="title has-text-centered">Orden: ON-{{ mixture.order.id }} | Lote: LN-{{ mixture.id }}</h1>
            </div>
            <serbitec-horizontal-order-data
            :medic="mixture.order.medic"
            :patient="mixture.order.patient"></serbitec-horizontal-order-data>
            <div class="box">
                <div class="columns">
                    <div class="column is-one-third">
                        Volumen total: <strong>{{ mixture.total_volume_in_ml }} ml</strong>
                    </div>
                    <div class="column is-one-third">
                        Sobrellenado: <strong>{{ mixture.overfill_in_ml }} ml</strong>
                    </div>
                    <div v-if="water" class="column is-one-third">
                        Agua inyectable: <strong>{{ water }} ml</strong>
                    </div>
                </div>
            </div>
            <b-message v-if="mixture.comments">
                <h1><strong>Comentarios: </strong></h1>
                <p>{{ mixture.comments }}</p>
            </b-message>
            <serbitec-nutritional-elements-table
            :elements="mixture.elements"
            :total-volume="mixture.total_volume_in_ml"></serbitec-nutritional-elements-table>
        </div>
    </div>
</template>

<script>
import serbitecHorizontalOrderData from '../../../components/dashboard/utils/horizontal-order-data.vue';
import serbitecNutritionalElementsTable from '../../../components/dashboard/utils/nutritional-elements-table.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';
import axios from 'axios';

import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    nutritional_mixture( id: $id ) {
        id
        delivery_date
        total_volume_in_ml
        overfill_in_ml
        comments
        order{
            id
            medic {
                id
                first_name
                last_name_1
                last_name_2
                email
                medical_card
                payer {
                    id
                    invoicing_data {
                        id
                        business_name
                    }
                }
            }
            patient {
                id
                name
                diagnosis
                height_in_meters
                weight_in_kilograms
                birth_date
                file_id
                location
                bed
            }
        }
        elements {
            id
            medicine_quantity
            multiply_factor_to_selected_unit
            selected_unit_code
            component {
                id
                name
                nutritional_field {
                    id
                    ml_to_g_factor
                }
                component_type {
                    id
                    name
                }
            }
        }
    }
}`;

function initialDataSate () {
    return {
        loading: false,
        mixtureId: this.$route.query.mixture_id,
        route: [
            {
                label: 'Registro de mezclas nutricionales',
                to: '/nutritional_mixtures_records',
                active: false
            },
            {
                label: 'Detalles de la orden',
                to: '/nutritional_mixtures_records/order_details',
                active: true
            }
        ]
    };
}
export default {
    components: {
        serbitecHorizontalOrderData,
        serbitecNutritionalElementsTable,
        serbitecBreadcumbs
    },
    data: initialDataSate,
    computed: {
        water () {
            var water = this.mixture.elements.find( element => element.component.component_type.id == 6 );
            if ( water ) {
                return water.medicine_quantity;   
            } else {
                return false;
            }
        }
    },
    apollo: {
        mixture: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    id: this.mixtureId
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                return data.nutritional_mixture;
            }
        },
    }
};
</script>