var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "serbitec-small-top-margin block" },
      [
        _c(
          "b-radio",
          {
            attrs: { "native-value": "external" },
            on: { input: _vm.updateWarehouseReportOptions },
            model: {
              value: _vm.radio,
              callback: function($$v) {
                _vm.radio = $$v
              },
              expression: "radio"
            }
          },
          [_vm._v("\n            Inventario\n        ")]
        ),
        _vm._v(" "),
        _c(
          "b-radio",
          {
            attrs: { "native-value": "internal" },
            on: { input: _vm.updateWarehouseReportOptions },
            model: {
              value: _vm.radio,
              callback: function($$v) {
                _vm.radio = $$v
              },
              expression: "radio"
            }
          },
          [_vm._v("\n            Insumos del área de preparación\n        ")]
        ),
        _vm._v(" "),
        _vm.radio === "internal"
          ? _c(
              "b-checkbox",
              {
                attrs: { "native-value": "npt" },
                on: { input: _vm.updateWarehouseReportOptions },
                model: {
                  value: _vm.type,
                  callback: function($$v) {
                    _vm.type = $$v
                  },
                  expression: "type"
                }
              },
              [_vm._v("\n            Nutricional\n        ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.radio === "internal"
          ? _c(
              "b-checkbox",
              {
                attrs: { "native-value": "onco" },
                on: { input: _vm.updateWarehouseReportOptions },
                model: {
                  value: _vm.type,
                  callback: function($$v) {
                    _vm.type = $$v
                  },
                  expression: "type"
                }
              },
              [_vm._v("\n            Oncológico\n        ")]
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }