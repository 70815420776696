<template>
    <div>
        <nav class="level is-mobile">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title">Área de preparación de mezclas oncológicas</h1>
                </div>
            </div>
            <div class="level-right" />
        </nav>
        <hr>
        <serbitec-mixtures-table
            preparer-type="oncologic"
            :mixtures-count.sync="mixturesCount"
            :page.sync="page"
            :page-size.sync="pageSize"
            :sort-field.sync="sortField"
            :sort-order.sync="sortOrder"
            :mixtures.sync="mixtures"
            
            @page-change="onPageChange"
            @sort="onSort"
        />
    </div>
</template>

<script>
import gql from 'graphql-tag';
const query = gql` query ( $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String )
{
    oncologic_mixtures( mixture_state_ids: "2,4", page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction ) {
        id
        state {
            id
        }
        order{
            id
            delivery_date
            patient{
                id
                name
            }
            medic {
                id
                payer {
                    id
                    invoicing_data {
                        id
                        business_name
                    }
                }
            }
        }
    }
    table_counts( count_type: "oncologic_mixtures", mixture_state_ids: "2,4" ) {
        oncologic_mixtures
    }
}`;

import serbitecMixturesTable from '../../../components/dashboard/preparation-area/mixtures-table.vue';

function initialDataState () {
    return {
        page: 0,
        pageSize: 10,
        sortField: 'id',
        sortOrder: 'desc',
        mixturesCount: null
    };
}

export default {
    components: {
        serbitecMixturesTable
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if ( from.path == '/oncologic_preparation_area/select_supplies' && to.query.success ) {
                vm.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se han seleccionado los medicamentos de la mezcla oncológica'
                });
            } else if ( from.path == '/oncologic_preparation_area/prepare' && to.query.success ) {
                vm.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha preparado la mezcla oncológica'
                });
            }
        });
    },
    data: initialDataState,
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
    },
    apollo: {
        mixtures: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update ( data ) {
                this.mixturesCount = data.table_counts.oncologic_mixtures;
                return data.oncologic_mixtures;
            }
        },
    }
};
</script>