<template>
    <b-autocomplete
        v-model="medicName"
        :data="medics"
        :loading="autocompleteIsLoading"
        @select="option => $emit('medic-added', option)"
        expanded
    >

        <template slot-scope="props">
            <div class="media">
                <div class="media-content">
                    <strong>{{ props.option.first_name }} {{ props.option.last_name_1 }} {{ props.option.last_name_2 }}</strong>
                    <br>
                    <p class="has-text-weight-light">Cliente: {{ props.option.payer.invoicing_data.business_name }}</p>
                    <p class="has-text-weight-light">Cédula profesional: {{ props.option.medical_card }}</p>
                </div>
            </div>
        </template>

        <template slot="empty">No se encontró el usuario...</template>
    </b-autocomplete>
</template>

<script>
import gql from 'graphql-tag';
const query = gql` query ( $name: String, $payer_id: Int )
{
    medics( name: $name, payer_id: $payer_id ) {
        id
        first_name
        last_name_1
        last_name_2
        medical_card
        payer {
            id
            invoicing_data {
                id
                business_name
            }
        }
    }
}`;

export default {
    props: {
        payerId: {
            type: [Number, String],
            default: 0 | '0'
        }
    },
    data: function () {
        return {
            medics: [],
            medicName: null,
            autocompleteIsLoading: false,
        };
    },
    apollo: {
        medics: {
            query: query,
            variables () {
                return {
                    name: this.medicName,
                    payer_id: this.payerId
                };
            }
        },
    }
};
</script>