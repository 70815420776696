var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card", staticStyle: { width: "auto" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c("p", [
            _vm._v(
              "\n            1. Para editar los comentarios cambie o introduzca los comentarios en el siguiente campo y de click en editar.\n        "
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { label: "Comentarios", "label-position": "on-border" } },
            [
              _c("b-input", {
                attrs: { maxlength: "200", type: "textarea" },
                model: {
                  value: _vm.mixture.comments,
                  callback: function($$v) {
                    _vm.$set(_vm.mixture, "comments", $$v)
                  },
                  expression: "mixture.comments"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$parent.close()
              }
            }
          },
          [_vm._v("\n            Cancelar\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button is-primary", on: { click: _vm.editComments } },
          [_vm._v("\n            Editar\n        ")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v("\n            Editar comentarios\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }