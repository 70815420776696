<template>
    <div>
        <b-field class="serbitec-small-left-margin">
            <b-radio-button
            v-model="enabledPayerMedicines"
            :native-value="true">
                <span>Activos</span>
            </b-radio-button>

            <b-radio-button
            v-model="enabledPayerMedicines"
            :native-value="false">
                <span>Inactivos</span>
            </b-radio-button>
        </b-field>
    </div>
</template>

<script>
export default {
    props: {
        comesFromPage: {
            type: String,
            default: ''
        }
    },
    computed: {
        enabledPayerMedicines: {
            get () {
                return this.$store.state.recordFilters[this.comesFromPage].enabledPayerMedicines;
            },
            set (value) {
                let data = {
                    value: value,
                    comesFromPage: this.comesFromPage
                };
                this.$store.commit( 'recordFilters/UPDATE_ENABLED_PAYER_MEDICINES', data );
            }
        }
    }
};
</script>