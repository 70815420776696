<template>
<div>
    <div class="box">
        <h1 class="is-subtitle has-text-centered"><strong>Inspección de calidad</strong></h1>
        <hr>
        <b-field grouped group-multiline>
            <b-field
            v-for="( item, index ) in qualityInspectionCheckList" :key="index"
            :label="item.label"
            expanded>
                <b-field>
                    <b-radio-button
                    v-model="item.isApproved"
                    :native-value="true"
                    type="is-success">
                        <b-icon size="is-small" icon="check-circle"></b-icon>
                        <span>Sí</span>
                    </b-radio-button>

                    <b-radio-button
                    v-model="item.isApproved"
                    :native-value="false"
                    type="is-danger">
                        <b-icon size="is-small" icon="times-circle"></b-icon>
                        <span>No</span>
                    </b-radio-button>
                </b-field>
            </b-field>
        </b-field>
        <b-field v-if="computedMixtureWeight" label="Peso de la mezcla">
            <b-field>
                <b-input
                    @input="$emit('weight-input', $event)"
                    type="text"
                    expanded>
                </b-input>
                <p class="control">
                    <span class="button is-static">g</span>
                </p>
                <p class="control">
                    <span class="button is-static">Peso teórico: {{ computedMixtureWeight }} g</span>
                </p>
            </b-field>
        </b-field>
        <b-field label="Comentarios">
            <b-input
            @input="$emit('input', $event)"
            maxlength="500"
            type="textarea"></b-input>
        </b-field>
    </div>
</div>
</template>

<script>
export default {
    props:['qualityInspectionCheckList', 'computedMixtureWeight']
};
</script>