var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { on: { submit: _vm.submitGenerateQuote } }, [
    _c("div", { staticClass: "modal-card", staticStyle: { width: "auto" } }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c("p", [
            _vm._v(
              "\n                Para generar la cotización seleccione las opciones y de click en el botón Generar\n            "
            )
          ]),
          _vm._v(" "),
          !_vm.chargeOnlyPreparationService
            ? _c(
                "b-field",
                { staticClass: "serbitec-small-top-margin" },
                [
                  _c(
                    "b-radio-button",
                    {
                      attrs: { "native-value": true, expanded: "" },
                      model: {
                        value: _vm.detailed,
                        callback: function($$v) {
                          _vm.detailed = $$v
                        },
                        expression: "detailed"
                      }
                    },
                    [_c("span", [_vm._v("Desglosado")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-radio-button",
                    {
                      attrs: { "native-value": false, expanded: "" },
                      model: {
                        value: _vm.detailed,
                        callback: function($$v) {
                          _vm.detailed = $$v
                        },
                        expression: "detailed"
                      }
                    },
                    [_c("span", [_vm._v("Condensado")])]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.detailed || _vm.chargeOnlyPreparationService
            ? _c(
                "b-field",
                { staticClass: "serbitec-small-top-margin" },
                [
                  _c(
                    "b-radio-button",
                    {
                      attrs: { "native-value": true, expanded: "" },
                      model: {
                        value: _vm.includeTax,
                        callback: function($$v) {
                          _vm.includeTax = $$v
                        },
                        expression: "includeTax"
                      }
                    },
                    [_c("span", [_vm._v("Con I.V.A.")])]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-radio-button",
                    {
                      attrs: { "native-value": false, expanded: "" },
                      model: {
                        value: _vm.includeTax,
                        callback: function($$v) {
                          _vm.includeTax = $$v
                        },
                        expression: "includeTax"
                      }
                    },
                    [_c("span", [_vm._v("Sin I.V.A.")])]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "field serbitec-small-top-margin" },
            [
              _c(
                "b-checkbox",
                {
                  model: {
                    value: _vm.chargeOnlyPreparationService,
                    callback: function($$v) {
                      _vm.chargeOnlyPreparationService = $$v
                    },
                    expression: "chargeOnlyPreparationService"
                  }
                },
                [
                  _vm._v("\n                    Cotizar sólamente el "),
                  _c("strong", [_vm._v("servicio de preparación")])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$parent.close()
              }
            }
          },
          [_vm._v("Cancelar")]
        ),
        _vm._v(" "),
        _c("button", { staticClass: "button is-primary" }, [_vm._v("Generar")])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v("\n                Generar cotización\n            ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }