const getDefaultState = () => {
    return {
        oncologicMedicineData: null,
        fromFlaks: null,
    };
};

const state = getDefaultState();

const mutations = {
    SET_ONCOLOGIC_MEDICINE (state, data) {
        state.oncologicMedicineData = data;
    },
    SET_ONCOLOGIC_FLASK (state, data) {
        state.fromFlaks = data;
    },
    RESET_ONCOLOGIC_MEDICINE (state) {
        Object.assign(state, getDefaultState());
    }, 
};

export default {
    namespaced: true,
    state,
    mutations
};
  