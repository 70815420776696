<template>
    <div>
        <nav class="level is-mobile">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title">Registro de órdenes oncológicas</h1>
                </div>
            </div>

            <div class="level-right">
                <a
                class="button"
                @click="openCollapse = !openCollapse">
                    <span class="icon is-medium">
                        <font-awesome-icon icon="file-export" />
                    </span>
                </a>
            </div>
        </nav>
        <hr>
        <b-collapse :open="openCollapse" class="serbitec-medium-bottom-margin">
            <div class="notification">
                <div class="content">
                    <p>
                        <strong>Generación de reportes.</strong>
                        <br>
                        <small> Los reportes se generaran sobre la lista de mezclas mostradas en pantalla, si se aplicó algún filtro, se respetará durante la generación del reporte.</small>
                    </p>
                    <a class="button is-small" @click="showGenerateReportModal">
                        <span class="icon is-medium">
                            <font-awesome-icon icon="shipping-fast" />
                        </span>
                        <span>Reporte de envío consolidado</span>
                    </a>
                </div>
            </div>
        </b-collapse>
        <serbitec-filters
            :active-filters="filterOptions"
            comes-from-page="oncologicOrdersRecords"
        />
        <br>
        <serbitec-oncologic-records-table
        :table-type="'oncologic'"
        :mixtures="orders"
        :mixtures-count="ordersCount"

        :loading.sync="loading"
        :page.sync="page"
        :pageSize="pageSize"
        :sortField.sync="sortField"
        :sortOrder.sync="sortOrder"

        @page-change="onPageChange"
        @sort="onSort"
        @item-canceled="orderCanceled"></serbitec-oncologic-records-table>
    </div>
</template>

<script>
import serbitecOncologicRecordsTable from '../../../components/dashboard/utils/records-table.vue';
import serbitecFilters from '../../../components/dashboard/utils/filters.vue';
import axios from 'axios';

import gql from 'graphql-tag';
import { mapState } from 'vuex';
const query = gql` query ( $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String, $payer_ids: String, $from_delivery_date: String, $until_delivery_date: String  )
{
    orders: oncologic_orders( page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction, payer_ids: $payer_ids, from_delivery_date: $from_delivery_date, until_delivery_date: $until_delivery_date ) {
        id
        delivery_date
        patient {
            id
            name
        }
        medic {
            id
            payer {
                id
                invoicing_data {
                    id
                    business_name
                }
            }
        }
        mixtures {
            id
            comments
            state {
                id
            }
        }
        requested_flasks {
            id
            comments
            state {
                id
            }
        }
    }
    ordersCount: table_counts( count_type: "oncologic_orders", payer_ids: $payer_ids, from_delivery_date: $from_delivery_date, until_delivery_date: $until_delivery_date ) {
        oncologic_orders
    }
    deliveryReportOrdersIdsCount: table_counts( count_type: "oncologic_orders", flask_or_mixture_state_ids: "8,9,10", payer_ids: $payer_ids, from_delivery_date: $from_delivery_date, until_delivery_date: $until_delivery_date ) {
        oncologic_orders
    }
}`;

const idsQuery = gql` query ( $payer_ids: String, $from_delivery_date: String, $until_delivery_date: String  )
{
    ordersIds: oncologic_orders( flask_or_mixture_state_ids: "8,9,10", payer_ids: $payer_ids, from_delivery_date: $from_delivery_date, until_delivery_date: $until_delivery_date ) {
        id
    }
}`;

function initialDataState () {
    return {
        loading: false,
        ordersCount: null,
        deliveryReportOrdersIdsCount: null,
        skipIdsQuery: true,
        page: 0,
        pageSize: 10,
        sortField: 'id',
        sortOrder:'desc',
        openCollapse: false,
        filterOptions: [ 'payer', 'deliveryDate' ]
    };
}

export default {
    name:'index',
    components: {
       serbitecOncologicRecordsTable,
       serbitecFilters
    },
    computed: {
        ...mapState({
            payersFilters: state => state.recordFilters.oncologicOrdersRecords.payers,
            fromDateFilter: state => state.recordFilters.oncologicOrdersRecords.fromDate,
            untilDateFilter: state => state.recordFilters.oncologicOrdersRecords.untilDate
        }),
        payerIds(){
            let ids = [];
            this.payersFilters.map( payer => {
                ids.push(payer.id);
            } );
            return ids;
        }
    },
    data: initialDataState,
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.$apollo.queries.orders.refetch();
            if ( from.path == '/edit_comments' && to.query.success ) {
                vm.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se han editado los comentarios'
                });
            }
        });
    },
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
        orderCanceled ( orderId ) {
            var url = '/api/oncologic/orders/state';
            const requestParams = {
                id: orderId,
                state_id: 3
            };
            this.makeRequest( url, axios.put, requestParams );
        },
        makeRequest ( url, method, params ) {
            this.loading = true;
            method( url, params )
            .then(function ( ) {
                this.$apollo.queries.orders.refetch();
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha cancelado la orden'
                });
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                title: '¡Ocurrió un error!',
                type: 'error',
                text: error.response.data.message
            });
            }.bind(this));
            this.loading = false;
        },
        showGenerateReportModal(){
            this.skipIdsQuery = false;
            if( this.deliveryReportOrdersIdsCount > 0 ) {
                this.$buefy.dialog.confirm({
                    title: 'Reporte de envío consolidado',
                    message: 'Se generará el reporte para <strong>' + this.deliveryReportOrdersIdsCount + ' órdenes</strong>.<br><small>Sólo se toman en cuenta las órdenes con estado: acondicionado, enviado o entregado.</small><br>¿Desea continuar?',
                    cancelText: 'Cancelar',
                    hasIcon: true,
                    icon: 'file-pdf',
                    iconPack: 'fa',
                    onConfirm: () => this.generateGeneralShippingRecord()
                })
            } else {
                this.$buefy.dialog.alert({
                    title: 'Reporte de envío consolidado',
                    message: 'La selección debe contener al menos <strong>1 orden</strong>.<br><small>El reporte de envío sólo toma en cuenta las órdenes con estado acondicionado, enviado o entregado.</small>',
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                })
            }
        },
        generateGeneralShippingRecord( ) {
            window.open('/pdf/oncologic/orders/general_shipping_record/[' + this.ordersIds.toString() + ']');
        }
    },
    apollo: {
        orders: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder,
                    payer_ids: this.payerIds.toString(),
                    from_delivery_date: this.fromDateFilter,
                    until_delivery_date: this.untilDateFilter
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                this.deliveryReportOrdersIdsCount = data.deliveryReportOrdersIdsCount.oncologic_orders;
                this.ordersCount = data.ordersCount.oncologic_orders;
                return data.orders;
            }
        },
        ordersIds: {
            query: idsQuery,
            variables () {
                return {
                    payer_ids: this.payerIds.toString(),
                    from_delivery_date: this.fromDateFilter,
                    until_delivery_date: this.untilDateFilter
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            skip () {
                return this.skipIdsQuery;
            },
            update ( data ) {
                let ids = [];
                data.ordersIds.map( orderId => {
                    ids.push(orderId.id);
                } );
                return ids;
            }
        },
    }
};
</script>