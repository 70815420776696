var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.mixtures,
            loading: _vm.loading,
            paginated: "",
            "backend-pagination": "",
            total: _vm.mixturesCount,
            "per-page": _vm.pageSize,
            "aria-next-label": "Siguiente página",
            "aria-previous-label": "Página anterior",
            "aria-page-label": "Página",
            "aria-current-label": "Página actual",
            "backend-sorting": "",
            "default-sort": [_vm.sortField, _vm.sortOrder]
          },
          on: { "page-change": _vm.changePage, sort: _vm.sort },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "id",
                        label: "Orden",
                        numeric: "",
                        sortable: ""
                      }
                    },
                    [
                      _vm.isNutritional
                        ? _c("p", [_vm._v("ON-" + _vm._s(props.row.order.id))])
                        : _vm.isOncologic
                        ? _c("p", [_vm._v("OO-" + _vm._s(props.row.order.id))])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Lote", numeric: "" } },
                    [
                      _vm.isNutritional
                        ? _c("p", [_vm._v("LN-" + _vm._s(props.row.id))])
                        : _vm.isOncologic
                        ? _c("p", [_vm._v("LO-" + _vm._s(props.row.id))])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Paciente" } }, [
                    _c("p", [_vm._v(_vm._s(props.row.order.patient.name))])
                  ]),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Cliente" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          props.row.order.medic.payer.invoicing_data
                            .business_name
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "delivery_date",
                        label: "Fecha de entrega",
                        sortable: _vm.isNutritional
                      }
                    },
                    [
                      _vm.isNutritional
                        ? _c("p", [
                            _vm._v(
                              _vm._s(_vm.formatDate(props.row.delivery_date))
                            )
                          ])
                        : _vm.isOncologic
                        ? _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.formatDate(props.row.order.delivery_date)
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "QR", centered: "" } },
                    [
                      _vm.isNutritional
                        ? _c(
                            "a",
                            {
                              staticClass: "button is-light is-small",
                              attrs: {
                                href:
                                  "/nutritional/mixtures/" +
                                  props.row.id +
                                  "/qr.png",
                                target: "_blank"
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "qrcode" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm.isOncologic
                        ? _c(
                            "a",
                            {
                              staticClass: "button is-light is-small",
                              attrs: {
                                href:
                                  "/oncologic/mixtures/" +
                                  props.row.id +
                                  "/qr.png",
                                target: "_blank"
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "qrcode" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Elegir", centered: "" } },
                    [
                      props.row.state.id == 2
                        ? _c(
                            "router-link",
                            {
                              staticClass: "button is-light is-small",
                              attrs: {
                                to: {
                                  path: "select_supplies",
                                  query: { mixtureId: props.row.id }
                                },
                                append: ""
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "capsules" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : props.row.state.id == 4
                        ? _c(
                            "a",
                            {
                              staticClass: "button is-light is-small",
                              attrs: { disabled: "" }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "capsules" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Preparar", centered: "" } },
                    [
                      props.row.state.id == 4
                        ? _c(
                            "router-link",
                            {
                              staticClass: "button is-light is-small",
                              attrs: {
                                to: {
                                  path: "prepare",
                                  query: { mixtureId: props.row.id }
                                },
                                append: ""
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "mortar-pestle" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      props.row.state.id == 2
                        ? _c(
                            "a",
                            {
                              staticClass: "button is-light is-small",
                              attrs: { disabled: "" }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "mortar-pestle" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("No hay mezclas pendientes.")])
                ]
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }