<template>
    <div>
        <hr>
        <h1 class="subtitle">Aditivos</h1>
        <div
        v-for="aditive in aditives" :key="aditive.id">
            <div v-if="!aditive.name.toLowerCase().includes('selenio')" class="columns" style="padding-bottom: 10px">
                <div class="column">
                    <div class="field">
                        <b-checkbox
                        :value="aditive.showInput"
                        @input="clearItemOnFalse( $event, aditive )">
                            <small>{{ aditive.name }}</small>
                        </b-checkbox>
                    </div>
                </div>
                <div class="column">
                    <b-field
                        v-if="aditive.showInput"
                        horizontal
                        expanded>
                        <b-field>
                            <b-input
                                :value="aditive.quantity"
                                @input="updateAditiveQuantity( $event, aditive.id )"
                                placeholder="10.00"
                                v-cleave="masks.custom"
                                type="text"
                                expanded>
                            </b-input>
                            <p
                            v-if="aditive.units.length < 2"
                            class="control">
                                <span class="button is-static">{{ aditive.unitSymbol }}</span>
                            </p>
                            <b-field
                            v-else>
                                <b-radio-button
                                v-for="unit in aditive.units"
                                :key="unit.id"
                                :value="aditive.unitSymbol"
                                @input="updateAditiveUnitSymbol( $event, aditive.id )"
                                :native-value="unit">
                                    <span>{{ unit }}</span>
                                </b-radio-button>
                            </b-field>
                        </b-field>
                    </b-field>
                </div>
                <div v-if="aditive.units.length > 1 && aditive.showInput" class="column is-narrow">
                    <b-field
                    v-if="aditive.unitSymbol == 'ml'">
                        <p class="control">
                            <span class="button is-static">{{ (aditive.quantity * aditive.factor).toFixed(2) }} {{ aditive.units[0] }}</span>
                        </p>
                    </b-field>
                    <b-field
                    v-else>
                        <p class="control">
                            <span class="button is-static">{{ (aditive.quantity / aditive.factor).toFixed(2) }} {{ aditive.units[1] }}</span>
                        </p>
                    </b-field>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Cleave from 'cleave.js';

const cleave = {
    name: 'cleave',
    bind(el, binding) {
        const input = el.querySelector('input');
        input._vCleave = new Cleave(input, binding.value);
    },
    unbind(el) {
        const input = el.querySelector('input');
        input._vCleave.destroy();
    }
};

export default {
    directives: { cleave },
    computed: {
        ...mapState ({
            aditives: state => state.nutritionalOrder.aditives
        })
    },
    data () {
        return {
            masks: {
                custom: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralPositiveOnly: true
                }
            }
        };
    },
    methods: {
        clearItemOnFalse ( input, selectedAditive ) {
            if ( !input ) {
                this.$store.commit( 'nutritionalOrder/UNSELECT_SELECTED_ADITIVE_ITEM', selectedAditive.id );
            } else {
                const data = {
                    id: selectedAditive.id,
                    selectedComponent: selectedAditive,
                    showInput: input
                };
                this.$store.commit( 'nutritionalOrder/SET_SELECTED_ADITIVE_ITEM', data );
            }
        },
        updateAditiveQuantity ( input, selectedAditive ) {
            const data = {
                id: selectedAditive,
                quantity: input
            };
            this.$store.commit( 'nutritionalOrder/SET_SELECTED_ADITIVE_ITEM', data );
        },
        updateAditiveUnitSymbol ( input, selectedAditive ) {
            const data = {
                id: selectedAditive,
                unitSymbol: input
            };
            this.$store.commit( 'nutritionalOrder/SET_SELECTED_ADITIVE_ITEM', data );
        }
    }
};
</script>