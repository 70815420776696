var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card", staticStyle: { width: "auto" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: "Código EAN",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.EANcode.$error },
                message: {
                  "Se requiere introducir el código EAN de la presentación":
                    !_vm.$v.EANcode.required && _vm.$v.EANcode.$error
                },
                expanded: ""
              }
            },
            [
              _c(
                "b-field",
                { attrs: { type: { "is-danger": _vm.$v.EANcode.$error } } },
                [
                  _c("b-input", {
                    attrs: { type: "text", expanded: "" },
                    model: {
                      value: _vm.$v.EANcode.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.EANcode, "$model", $$v)
                      },
                      expression: "$v.EANcode.$model"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                label: "Volumen",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.volumeInMl.$error },
                message: {
                  "Se requiere introducir el volumen de la presentación":
                    !_vm.$v.volumeInMl.required && _vm.$v.volumeInMl.$error
                },
                expanded: ""
              }
            },
            [
              _c(
                "b-field",
                { attrs: { type: { "is-danger": _vm.$v.volumeInMl.$error } } },
                [
                  _c("b-input", {
                    attrs: { type: "text", expanded: "" },
                    model: {
                      value: _vm.$v.volumeInMl.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.volumeInMl, "$model", $$v)
                      },
                      expression: "$v.volumeInMl.$model"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "control" }, [
                    _c("span", { staticClass: "button is-static" }, [
                      _vm._v("ml")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                label: "Cantidad de principio activo",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.componentQuantity.$error },
                message: {
                  "Se requiere introducir la cantidad de principio activo de la presentación":
                    !_vm.$v.componentQuantity.required &&
                    _vm.$v.componentQuantity.$error
                },
                expanded: ""
              }
            },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    type: { "is-danger": _vm.$v.componentQuantity.$error }
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text", expanded: "" },
                    model: {
                      value: _vm.$v.componentQuantity.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.componentQuantity, "$model", $$v)
                      },
                      expression: "$v.componentQuantity.$model"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "control" }, [
                    _c("span", { staticClass: "button is-static" }, [
                      _vm._v(_vm._s(_vm.medicineComponentUnitSymbol))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$parent.close()
              }
            }
          },
          [_vm._v("\n            Cancelar\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button is-primary", on: { click: _vm.submitForm } },
          [_vm._v("\n            Agregar\n        ")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v("\n            Agregar presentación\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }