<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right"></div>
        </nav>
        <hr>
        <div v-if="elements">
            <div class="box">
                <h1 class="title has-text-centered">Orden: OO-{{ orderId }} | Lote LO-{{ mixtureId }}</h1>
            </div>
            <serbitec-horizontal-order-data
            :medic="medic"
            :patient="patient"></serbitec-horizontal-order-data>
            <b-message v-if="isTransfer">
                Esta mezcla es un <strong>Trasvase</strong>
            </b-message>
            <serbitec-prepare-order
            return-url="/oncologic_preparation_area"
            :mixture-id="mixtureId"
            :elements="elements"></serbitec-prepare-order>
        </div>
    </div>
</template>

<script>
import serbitecPrepareOrder from '../../../components/dashboard/preparation-area/prepare-order.vue';
import serbitecHorizontalOrderData from '../../../components/dashboard/utils/horizontal-order-data.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';

import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    oncologic_mixture( id: $id ) {
        id
        elements {
            id
            medicine_quantity
            component {
                id
                name
                component_type {
                    id
                }
            }
            medicine {
                id
                component_unit {
                    id
                    symbol
                }
            }
            warehouse_items {
                id
                prefix {
                    id
                    prefix
                }
                batch
                pivot {
                    id
                    medicine_warehouse_item_quantity
                }
                medicine_presentation {
                    id
                    volume_in_ml
                    component_quantity
                    medicine {
                        id
                        component {
                            id
                            name
                        }
                        component_unit {
                            id
                            symbol
                        }
                        commercial_name
                    }
                }
            }
        }
        order {
            id
            medic {
                id
                first_name
                last_name_1
                last_name_2
                email
                medical_card
                payer {
                    id
                    invoicing_data {
                        id
                        business_name
                    }
                }
            }
            patient {
                id
                name
                diagnosis
                height_in_meters
                weight_in_kilograms
                birth_date
                file_id
                location
                bed
            }
        }
    }
}`;

function initialDataState () {
    return {
        orderId: null,
        patient: null,
        medic: null,
        isTransfer: false,
        route: [
            {
                label: 'Área de preparación de mezclas oncológicas',
                to: '/oncologic_preparation_area',
                active: false
            },
            {
                label: 'Preparar mezcla',
                to: '/oncologic_preparation_area/prepare',
                active: true
            }
        ]
    };
}
export default {
    props: {
        mixtureId: {
            type: [Number, String],
            default: 0 | '0'
        }
    },
    components: {
        serbitecPrepareOrder,
        serbitecHorizontalOrderData,
        serbitecBreadcumbs
    },
    data: initialDataState,
    apollo: {
        elements: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    id: this.mixtureId
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update ( data ) {
                this.patient = data.oncologic_mixture.order.patient;
                this.medic = data.oncologic_mixture.order.medic;
                this.orderId = data.oncologic_mixture.order.id;
                var elementWithMedicine = [];
                data.oncologic_mixture.elements.map( element => {
                    var obj = {};
                    obj.mixtureMedicineId = element.id;
                    obj.componentName = element.component.name;
                    obj.quantityInMedicineUnit = element.medicine_quantity;

                    obj.isMedicine = false;
                    if ( element.medicine ) {
                        obj.isMedicine = true;
                        obj.medicineUnit = element.medicine.component_unit.symbol;
                    }

                    obj.isPiece = false;
                    if ( element.component.component_type.id == 8 || element.component.component_type.id == 12 ) {
                        obj.isPiece = true;
                    }

                    obj.isThinner = false;
                    if ( element.component.component_type.id == 13 || element.component.component_type.id == 5 ) {
                        obj.isThinner = true;
                    }


                    const warehouseItems = [];
                    element.warehouse_items.map( warehouseItem => {
                        const item = {};
                        item.prefix = warehouseItem.prefix;
                        item.batch = warehouseItem.batch;
                        item.medicineName = warehouseItem.medicine_presentation.medicine.commercial_name;
                        item.medicinePresentationQuantity = warehouseItem.medicine_presentation.component_quantity;
                        item.medicinePresentationQuantityUnit = warehouseItem.medicine_presentation.medicine.component_unit.symbol;
                        item.medicinePresentationVolumeInMl = warehouseItem.medicine_presentation.volume_in_ml;
                        item.medicineQuantityInMl = warehouseItem.pivot.medicine_warehouse_item_quantity;

                        warehouseItems.push(item);
                    });
                    obj.warehouseItems = warehouseItems;

                    if ( element.component.id == 1 ) {
                        this.isTransfer = true;
                    } else {
                        elementWithMedicine.push( obj );
                    }
                });

                let sortedElementWithMedicine = elementWithMedicine.sort(
                    function (a, b) {
                        if ( a.mixtureMedicineId > b.mixtureMedicineId ) {
                            return 1;
                        }
                    }
                );

                return sortedElementWithMedicine;
            }
        },
    }
};
</script>