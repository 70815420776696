var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card", staticStyle: { width: "auto" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("section", { staticClass: "modal-card-body" }, [
        Object.keys(_vm.medicine).length === 0 &&
        _vm.medicine.constructor === Object
          ? _c(
              "div",
              [
                _c("h1", { staticClass: "subtitle is-6" }, [
                  _vm._v("1. Seleccione el medicamento")
                ]),
                _vm._v(" "),
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: "Nombre del medicamento",
                      "label-position": "on-border",
                      type: { "is-danger": _vm.$v.medicine.$error },
                      message: {
                        "Se requiere seleccionar un medicamento":
                          !_vm.$v.medicine.required && _vm.$v.medicine.$error
                      }
                    }
                  },
                  [
                    _c("serbitec-medicine-autocomplete", {
                      on: {
                        "medicine-selected": function($event) {
                          _vm.medicine = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          : _c(
              "div",
              [
                _c("h1", { staticClass: "subtitle is-6" }, [
                  _vm._v("2. Llene los campos para agregar el lote al almacen")
                ]),
                _vm._v(" "),
                _c(
                  "b-field",
                  { attrs: { grouped: "" } },
                  [
                    _c("b-field", [
                      _c(
                        "span",
                        {
                          staticClass: "button",
                          on: {
                            click: function($event) {
                              return _vm.unselectMedicine()
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-small" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "exchange-alt" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-field",
                      { attrs: { expanded: "" } },
                      [
                        _c("b-input", {
                          attrs: {
                            value:
                              _vm.medicine.commercial_name +
                              " (Genérico: " +
                              _vm.medicine.component.name +
                              ")",
                            disabled: ""
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: "Presentación",
                      "label-position": "on-border",
                      type: { "is-danger": _vm.$v.medicinePresentation.$error },
                      message: {
                        "Se requiere seleccionar la presentación":
                          !_vm.$v.medicinePresentation.required &&
                          _vm.$v.medicinePresentation.$error
                      }
                    }
                  },
                  [
                    _c(
                      "b-select",
                      {
                        attrs: {
                          placeholder: "Seleccione la presentación",
                          expanded: ""
                        },
                        model: {
                          value: _vm.$v.medicinePresentation.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.medicinePresentation,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.medicinePresentation.$model"
                        }
                      },
                      _vm._l(_vm.medicine.presentations, function(
                        presentation
                      ) {
                        return _c(
                          "option",
                          {
                            key: presentation.id,
                            domProps: { value: presentation }
                          },
                          [
                            _vm.medicine.component.component_type.id == 12
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(presentation.component_quantity) +
                                      " " +
                                      _vm._s(
                                        _vm.medicine.component_unit.symbol
                                      ) +
                                      " de " +
                                      _vm._s(presentation.volume_in_ml) +
                                      " ml"
                                  )
                                ])
                              : _vm.medicine.component.component_type.id == 8
                              ? _c("p", [
                                  _vm._v(
                                    _vm._s(presentation.component_quantity) +
                                      " " +
                                      _vm._s(_vm.medicine.component_unit.symbol)
                                  )
                                ])
                              : _c("p", [
                                  _vm._v(
                                    _vm._s(presentation.component_quantity) +
                                      " " +
                                      _vm._s(
                                        _vm.medicine.component_unit.symbol
                                      ) +
                                      " en " +
                                      _vm._s(presentation.volume_in_ml) +
                                      " ml"
                                  )
                                ])
                          ]
                        )
                      }),
                      0
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-field",
                  { attrs: { grouped: "", "group-multiline": "" } },
                  [
                    _c(
                      "b-field",
                      {
                        attrs: {
                          label: "Prefijo",
                          "label-position": "on-border"
                        }
                      },
                      [
                        _c("serbitec-client-prefixes-select", {
                          attrs: {
                            "selected-client-prefix": _vm.selectedClientPrefix
                          },
                          on: {
                            "update:selectedClientPrefix": function($event) {
                              _vm.selectedClientPrefix = $event
                            },
                            "update:selected-client-prefix": function($event) {
                              _vm.selectedClientPrefix = $event
                            }
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-field",
                      {
                        attrs: {
                          label: "Lote",
                          "label-position": "on-border",
                          type: { "is-danger": _vm.$v.batch.$error },
                          message: {
                            "Se requiere introducir el número de lote":
                              !_vm.$v.batch.required && _vm.$v.batch.$error
                          },
                          expanded: ""
                        }
                      },
                      [
                        _c("b-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.$v.batch.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.batch,
                                "$model",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "$v.batch.$model"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-field",
                      {
                        attrs: {
                          label: "Fecha de caducidad",
                          "label-position": "on-border",
                          type: { "is-danger": _vm.$v.expiryDate.$error },
                          message: {
                            "Se requiere introducir la fecha de caducidad":
                              !_vm.$v.expiryDate.required &&
                              _vm.$v.expiryDate.$error
                          },
                          expanded: ""
                        }
                      },
                      [
                        _c("b-datepicker", {
                          attrs: {
                            "min-date": _vm.minDate,
                            "max-date": _vm.maxDate,
                            "years-range": [-10, 10],
                            "date-formatter": function(d) {
                              return d.toLocaleDateString("es-US", {
                                year: "numeric",
                                month: "long",
                                day: "numeric"
                              })
                            }
                          },
                          model: {
                            value: _vm.$v.expiryDate.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.expiryDate,
                                "$model",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "$v.expiryDate.$model"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
      ]),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$parent.close()
              }
            }
          },
          [_vm._v("\n            Cancelar\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button is-primary", on: { click: _vm.submitForm } },
          [_vm._v("\n            Agregar\n        ")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v("\n            Agregar lote\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }