<template>
    <div class="box">
        <b-table
            :data="medicines"

            paginated
            backend-pagination
            :total="medicinesCount"
            :per-page="pageSize"
            @page-change="changePage"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="sort">

            <template slot-scope="props">
                <b-table-column field="props.row.commercial_name" label="Nombre comercial">
                    {{ props.row.commercial_name }}
                </b-table-column>

                <b-table-column field="props.row.component.name" label="Genérico">
                    {{ props.row.component.name }}
                </b-table-column>

                <b-table-column
                    v-if="tableType === 'oncologic'"
                    label="Diluyentes"
                    centered
                >
                    <router-link
                        v-if="props.row.component.component_type.id === 1"
                        :to="{ path: 'thinners', query: { medicineId: props.row.id } }"
                        class="button is-light is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="tint" />
                        </span>
                    </router-link>
                    <a
                        v-else
                        class="button is-light is-small"
                        disabled
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="tint" />
                        </span>
                    </a>
                </b-table-column>

                <b-table-column label="Presentaciones" centered>
                    <router-link
                        :to="{ path: 'presentations', query: { medicineId: props.row.id } }"
                        class="button is-light is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="prescription-bottle" />
                        </span>
                    </router-link>
                </b-table-column>

                <b-table-column label="Editar" centered>
                    <a
                        @click="editMedicine(props.row)"
                        class="button is-light is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="edit" />
                        </span>
                    </a>
                </b-table-column>

                <b-table-column label="Eliminar" centered>
                    <a
                        @click="deleteMedicine(props.row.id)"
                        class="button is-danger is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="trash-alt" />
                        </span>
                    </a>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay medicamentos registrados.</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import serbitecEditOncologicMedicineModal from '../../../components/dashboard/medicines-catalog/oncologic/add-medicine-modal.vue';
import serbitecEditNutritionalMedicineModal from '../../../components/dashboard/medicines-catalog/nutritional/add-medicine-modal.vue';

export default {
    props: {
        medicines: {
            type: Array,
            default: () => []
        },
        medicinesCount: {
            type: [Number, String],
            default: 0 | ''
        },
        page: {
            type: [Number, String],
            default: 0 | ''
        },
        pageSize: {
            type: [Number, String],
            default: 0 | ''
        },
        sortField: {
            type: String,
            default: ''
        },
        sortOrder: {
            type: String,
            default: ''
        },
        tableType: {
            type: String,
            default: ''
        }
    },
    methods: {
        changePage ( page ) {
            this.$emit('page-change', page);
        },
        sort ( field, order ) {
            this.$emit('sort', field, order);
        },
        editMedicine (medicine) {
            if (this.tableType === 'oncologic') {
                this.$buefy.modal.open({
                    parent: this,
                    component: serbitecEditOncologicMedicineModal,
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true,
                    props: {
                        medicine: medicine
                    },
                    events: {
                        'success': () => this.$emit('medicine-edited')
                    }
                });
            }
            if (this.tableType === 'nutritional') {
                this.$buefy.modal.open({
                    parent: this,
                    component: serbitecEditNutritionalMedicineModal,
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true,
                    props: {
                        medicine: medicine
                    },
                    events: {
                        'success': () => this.$emit('medicine-edited')
                    }
                });
            }
        },
        deleteMedicine (itemID) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar medicamento',
                message: '¿Seguro que deseas eliminar el medicamento?',
                confirmText: 'Eliminar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteItem(itemID)
            });
        },
        deleteItem (itemID) {
            this.$serbitec.startLoading();
            this.$axios.delete('/api/medicines/' + itemID)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha eliminado el medicamento'
                });
                this.$serbitec.finishLoading();
                this.$emit('reload-medicines');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    }
};
</script>