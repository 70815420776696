<template>
    <div>
        <serbitec-warehouse-report-options
            v-if="options.find(option => option === 'warehouseReportOptions') !== undefined"
        />
    </div>
</template>

<script>
import serbitecWarehouseReportOptions from '../../../components/dashboard/reports/options/warehouse-report.vue';

export default {
    props: {
        options: {
            type: Array,
            default: () => [],
        }
    },
    components: {
        serbitecWarehouseReportOptions
    },
};
</script>