<template>
    <b-field
        label="Tipo de reporte"
        label-position="on-border"
        :type="{'is-danger' : selectedReportType.$error }"
        :message="{
            'Se requiere introducir el tipo de reporte' : !selectedReportType.required && selectedReportType.$error
        }"
        expanded>
        <b-select
            placeholder="Seleccione el tipo de reporte"
            @input="reportType => $emit('report-type-selected', reportType)"
            expanded
        >
            <option
                v-for="type in types"
                :key="type.id"
                :value="type"
            >
                {{ type.label }}
            </option>
        </b-select>
    </b-field>
</template>

<script>
export default {
    props: ['selectedReportType'],
    data () {
        return {
            types: [
                {
                    id: 1,
                    label: 'Consumo nutricional por mezcla',
                    url: '/pdf/client-mixtures/cummulative/nutritional?'
                },
                {
                    id: 2,
                    label: 'Número de mezclas',
                    url: '/pdf/client-mixtures/totals/nutritional?'
                },
                {
                    id: 3,
                    label: 'Inventario del almacén y del área de preparación',
                    url: '/reports/warehouse/stock?',
                    options: [ 'warehouseReportOptions' ]
                },
                {
                    id: 4,
                    label: 'Consumo Oncológico por Mezclas',
                    url: '/pdf/client-mixtures/cummulative/oncologic?'
                },
                {
                    id: 5,
                    label: 'Valuación de Inventario',
                    url: '/pdf/client-mixtures/cummulative/inventory?'
                }
            ],
        };
    }
};
</script>