var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.mixtures,
            loading: _vm.loading,
            paginated: "",
            "backend-pagination": "",
            total: _vm.mixturesCount,
            "per-page": _vm.pageSize,
            "aria-next-label": "Siguiente página",
            "aria-previous-label": "Página anterior",
            "aria-page-label": "Página",
            "aria-current-label": "Página actual",
            "backend-sorting": "",
            "default-sort": [_vm.sortField, _vm.sortOrder],
            detailed: "",
            "detail-key": "id"
          },
          on: { "page-change": _vm.changePage, sort: _vm.sort },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "id",
                        label: "Orden",
                        numeric: "",
                        sortable: ""
                      }
                    },
                    [
                      _vm.isNutritional
                        ? _c("p", [_vm._v("ON-" + _vm._s(props.row.order.id))])
                        : _vm.isOncologic
                        ? _c("p", [_vm._v("OO-" + _vm._s(props.row.order.id))])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Lote", numeric: "" } },
                    [
                      _vm.isNutritional
                        ? _c("p", [_vm._v("LN-" + _vm._s(props.row.id))])
                        : _vm.isOncologic
                        ? _c("p", [_vm._v("LO-" + _vm._s(props.row.id))])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Paciente" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.order.patient.name) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Cliente" } }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(
                          props.row.order.medic.payer.invoicing_data
                            .business_name
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "delivery_date",
                        label: "Fecha de entrega",
                        sortable: ""
                      }
                    },
                    [
                      _vm.isNutritional
                        ? _c("p", [
                            _vm._v(
                              _vm._s(_vm.formatDate(props.row.delivery_date))
                            )
                          ])
                        : _vm.isOncologic
                        ? _c("p", [
                            _vm._v(
                              _vm._s(
                                _vm.formatDate(props.row.order.delivery_date)
                              )
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Inspección calidad", centered: "" } },
                    [
                      props.row.state.id == 5
                        ? _c(
                            "router-link",
                            {
                              staticClass: "button is-light is-small",
                              attrs: {
                                to: {
                                  path: "mixture_inspection",
                                  query: { mixtureId: props.row.id }
                                },
                                append: ""
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "clipboard-check" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "button is-light is-small",
                              attrs: { disabled: "" }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "clipboard-check" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Editar comentarios", centered: "" } },
                    [
                      props.row.state.id == 6
                        ? _c(
                            "a",
                            {
                              staticClass: "button is-light is-small",
                              on: {
                                click: function($event) {
                                  return _vm.editComments(props.row, "mixture")
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "comment-alt" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "button is-light is-small",
                              attrs: { disabled: "" }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-medium" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "comment-alt" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                    ]
                  )
                ]
              }
            },
            {
              key: "detail",
              fn: function(props) {
                return [
                  props.row.state.id == 5
                    ? _c("b-message", [
                        _vm._v("\n                El lote debe pasar la "),
                        _c("strong", [_vm._v("inspección de calidad")]),
                        _vm._v(
                          " para activar la impresión de etiqueta\n            "
                        )
                      ])
                    : props.row.state.id == 6
                    ? _c("nav", { staticClass: "level" }, [
                        _c(
                          "div",
                          { staticClass: "level-left" },
                          [
                            _c("p", { staticClass: "control" }),
                            props.row.printed_opportunities_used >= 2
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button is-small",
                                      attrs: { disabled: "" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "icon is-medium" },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "print" }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Imprimir etiqueta")])
                                    ]
                                  )
                                ])
                              : _vm.isOncologic
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button is-small",
                                      attrs: {
                                        href:
                                          "/#/oncologic_orders_quality_control"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.$emit(
                                            "print-attempt",
                                            props.row.id
                                          )
                                          _vm.labelOnc(props.row.id)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "icon is-medium" },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "print" }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Imprimir etiqueta")])
                                    ]
                                  )
                                ])
                              : _vm.isNutritional
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button is-small",
                                      attrs: {
                                        href:
                                          "/#/nutritional_mixtures_quality_control"
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.$emit(
                                            "print-attempt",
                                            props.row.id
                                          )
                                          _vm.labelNut(props.row.id)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "icon is-medium" },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "print" }
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Imprimir etiqueta")])
                                    ]
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("p"),
                            _vm._v(" "),
                            _c(
                              "b-tag",
                              { staticStyle: { "margin-left": "10px" } },
                              [
                                _c("small", [
                                  _vm._v(
                                    "Intentos utilizados: " +
                                      _vm._s(
                                        props.row.printed_opportunities_used
                                      )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "level-right" }, [
                          _c("p", { staticClass: "control" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button is-small is-primary",
                                on: {
                                  click: function($event) {
                                    return _vm.$emit(
                                      "mixture-aconditioned",
                                      props.row.id
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "icon is-medium" },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "check-circle" }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("span", [_vm._v("Mezcla acondicionada")])
                              ]
                            )
                          ])
                        ])
                      ])
                    : _vm._e()
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("No hay mezclas pendientes.")])
                ]
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }