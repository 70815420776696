var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "b-table",
        {
          attrs: { data: _vm.payerMedicinePresentations, loading: _vm.loading },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c("b-table-column", { attrs: { label: "Medicamento" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          props.row.payer_medicine.medicine.commercial_name
                        ) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Cantidad", centered: "" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            props.row.medicine_presentation.component_quantity
                          ) +
                          " " +
                          _vm._s(
                            props.row.payer_medicine.medicine.component_unit
                              .symbol
                          ) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Volumen", centered: "" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.medicine_presentation.volume_in_ml) +
                          " ml\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Precio", centered: "" } },
                    [
                      _c(
                        "b-field",
                        { attrs: { position: "is-centered" } },
                        [
                          _c("p", { staticClass: "control" }, [
                            _c("button", { staticClass: "button is-static" }, [
                              _vm._v("$")
                            ])
                          ]),
                          _vm._v(" "),
                          _c("b-input", {
                            directives: [
                              {
                                name: "cleave",
                                rawName: "v-cleave",
                                value: _vm.masks.numeral,
                                expression: "masks.numeral"
                              }
                            ],
                            attrs: {
                              value:
                                props.row.price_in_cents_per_ml_or_pz / 100,
                              type: "text"
                            },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onInputPricePerMl(props.row.id)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "control" }, [
                            props.row.payer_medicine.medicine.component_unit
                              .symbol == "pz"
                              ? _c(
                                  "button",
                                  { staticClass: "button is-static" },
                                  [_vm._v("MXN por pieza")]
                                )
                              : _c(
                                  "button",
                                  { staticClass: "button is-static" },
                                  [_vm._v("MXN por ml")]
                                )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "No hay precios por presentación dadas de alta para este cliente."
                    )
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      attrs: { type: "is-primary" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("add-medicine-presentations-prices")
                        }
                      }
                    },
                    [_vm._v("Habilitar precios por presentación")]
                  )
                ],
                1
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }