<template>
    <div class="columns is-centered">
        <div
            class="column"
            :class="{
                'is-two-fifths': $mq === 'desktop',
                'is-half': $mq === 'touch'
            }"
        >
            <serbitec-login-form />
        </div>
    </div>
</template>

<script>
import serbitecLoginForm from '../../components/landing/login-form.vue';

export default {
    components: {
        serbitecLoginForm
    }
};
</script>