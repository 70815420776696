var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box" },
      [
        _vm._m(0),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { grouped: "", "group-multiline": "" } },
          _vm._l(_vm.qualityInspectionCheckList, function(item, index) {
            return _c(
              "b-field",
              { key: index, attrs: { label: item.label, expanded: "" } },
              [
                _c(
                  "b-field",
                  [
                    _c(
                      "b-radio-button",
                      {
                        attrs: { "native-value": true, type: "is-success" },
                        model: {
                          value: item.isApproved,
                          callback: function($$v) {
                            _vm.$set(item, "isApproved", $$v)
                          },
                          expression: "item.isApproved"
                        }
                      },
                      [
                        _c("b-icon", {
                          attrs: { size: "is-small", icon: "check-circle" }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("Sí")])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-radio-button",
                      {
                        attrs: { "native-value": false, type: "is-danger" },
                        model: {
                          value: item.isApproved,
                          callback: function($$v) {
                            _vm.$set(item, "isApproved", $$v)
                          },
                          expression: "item.isApproved"
                        }
                      },
                      [
                        _c("b-icon", {
                          attrs: { size: "is-small", icon: "times-circle" }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("No")])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          }),
          1
        ),
        _vm._v(" "),
        _vm.computedMixtureWeight
          ? _c(
              "b-field",
              { attrs: { label: "Peso de la mezcla" } },
              [
                _c(
                  "b-field",
                  [
                    _c("b-input", {
                      attrs: { type: "text", expanded: "" },
                      on: {
                        input: function($event) {
                          return _vm.$emit("weight-input", $event)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "control" }, [
                      _c("span", { staticClass: "button is-static" }, [
                        _vm._v("g")
                      ])
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "control" }, [
                      _c("span", { staticClass: "button is-static" }, [
                        _vm._v(
                          "Peso teórico: " +
                            _vm._s(_vm.computedMixtureWeight) +
                            " g"
                        )
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { label: "Comentarios" } },
          [
            _c("b-input", {
              attrs: { maxlength: "500", type: "textarea" },
              on: {
                input: function($event) {
                  return _vm.$emit("input", $event)
                }
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h1", { staticClass: "is-subtitle has-text-centered" }, [
      _c("strong", [_vm._v("Inspección de calidad")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }