<template>
    <div>
        <b-loading :active.sync="loading"></b-loading>
        <form
        @submit.prevent="submitForm"
        novalidate="true">
            <div class="box">
                <nav class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <h1 class="subtitle">Agregar frasco al pedido</h1>
                        </div>
                    </div>
                    <div class="level-right">
                        <a class="button" @click="closeForm()">
                            <span class="icon">
                                <font-awesome-icon icon="times-circle" />
                            </span>
                            <span> Cerrar forma</span>
                        </a>
                    </div>
                </nav>

                <div v-if="!selectedOncologicMedicine">
                    <h1 class="subtitle is-6">1. Seleccione el medicamento oncológico</h1>
                    <serbitec-medicine-autocomplete
                    :payer-id="payer.id"
                    @medicine-selected="storeFlask"
                    :withComponentTypeIds="'1'"></serbitec-medicine-autocomplete>
                    <br>
                </div>

                <div v-if="selectedOncologicMedicine">
                    <h1 class="subtitle is-6">2. Llene los campos para agregar el frasco a la orden</h1>
                    <b-field grouped>
                        <b-field>
                            <span class="button" @click="unselectMedicine()">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="exchange-alt" />
                                </span>
                            </span>
                        </b-field>
                        <b-field expanded>
                            <b-input
                            :value="selectedOncologicMedicine.commercial_name + ' (Genérico: ' + selectedOncologicMedicine.component.name + ')'"
                            disabled>
                            </b-input>
                        </b-field>
                    </b-field>
                    <b-field grouped group-multiline>
                        <b-field
                        label="Presentación"
                        :type="{'is-danger' : $v.medicinePresentation.$error }"
                        :message="[
                            !$v.medicinePresentation.required && $v.medicinePresentation.$error ? 'Se requiere seleccionar la presentación' : 'Seleccione la presentación' 
                        ]"
                        expanded>
                            <b-select 
                                v-model.trim="$v.medicinePresentation.$model"
                                placeholder="Seleccione la presentación">
                                <option
                                v-for="presentation in selectedOncologicMedicine.presentations"
                                :value="presentation"
                                :key="presentation.id">
                                    {{ presentation.component_quantity }} {{ selectedOncologicMedicine.component_unit.symbol }}
                                </option>
                            </b-select>
                        </b-field>
                        <b-field
                        label="Cantidad"
                        :type="{'is-danger' : $v.flasksQuantity.$error }"
                        :message="[
                            !$v.flasksQuantity.required && $v.flasksQuantity.$error ? 'Se requiere seleccionar la cantidad de frascos' : 'Seleccione la cantidad de frascos' 
                        ]"
                        expanded>
                            <b-numberinput
                            v-model="$v.flasksQuantity.$model"
                            controls-position="compact"
                            :editable="isNumberFieldEditable"
                            min="1">
                            </b-numberinput>
                        </b-field> 
                    </b-field>
                    <hr>
                    <b-field
                    :label="'Comentarios'"
                    :message="'Introduzca comentarios referentes a la mezcla'">
                        <b-input 
                            v-model="comments"
                            type="textarea"
                            maxlength="500"
                            placeholder="Introduzca sus comentarios...">
                        </b-input>
                    </b-field>
                    <b-field>
                        <p class="control has-text-centered">
                            <button type="submit" class="button is-primary">
                                Agregar frasco
                            </button>
                        </p>
                    </b-field>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import serbitecMedicineAutocomplete from '../../utils/autocompletes/medicines.vue';
import { mapState } from 'vuex';

function initialDataState() {
    return {
        isNumberFieldEditable: false,

        medicinePresentation: null,
        flasksQuantity: 1,
        comments: null,

        loading: false,
    };
}

function setID() {
    return Math.random().toString(36).substr(2, 8);
}

export default {
    props:['comesFromEdit'],
    components: {
       serbitecMedicineAutocomplete
    },
    computed: {
        ...mapState({
            selectedOncologicMedicine: state => state.oncologicMedicine.fromFlaks,
            payer: state => state.payer.payerData
        })
    },
    data: initialDataState,
    methods: {
        closeForm () {
            this.$store.commit('oncologicMedicine/RESET_ONCOLOGIC_MEDICINE');
            Object.assign(this.$data, initialDataState());
            this.$nextTick(() => { this.$v.$reset(); });
            this.$emit('close-form');
        },
        unselectMedicine () {
            this.$store.commit('oncologicMedicine/RESET_ONCOLOGIC_MEDICINE');
            Object.assign(this.$data, initialDataState());
            this.$nextTick(() => { this.$v.$reset(); });
        },
        getFlaskData () {
            // this.deliveryDate.setTime(this.deliveryHour);
            // const dateWithTime = this.deliveryDate;

            const flaskParameters = {
                medicine_id: this.selectedOncologicMedicine.id,
                medicine_name: this.selectedOncologicMedicine.commercial_name,
                medicine_component_name: this.selectedOncologicMedicine.component.name,
                presentation_quantity: this.medicinePresentation.component_quantity,
                medicine_presentation_id: this.medicinePresentation.id,
                medicine_presentation_unit_symbol: this.selectedOncologicMedicine.component_unit.symbol,
                number_of_flasks: this.flasksQuantity,
                // delivery_date: dateWithTime,
                comments: this.comments,
            };
            if ( !this.comesFromEdit ) {
                flaskParameters.id = setID();
            }
            return flaskParameters;
        },
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.addFlaskToOrder();
            }
        },
        addFlaskToOrder () {
            this.loading = true;
            this.$store.commit('oncologicOrder/ADD_ONCOLOGIC_FLASK_TO_ORDER', this.getFlaskData());
            this.closeForm();
            window.scrollTo(0,0);
            this.loading = false;
        },
        storeFlask ( flask ) {
            this.$store.commit('oncologicMedicine/SET_ONCOLOGIC_FLASK', flask );
        }
    },
    validations: {
        medicinePresentation: {
            required
        },
        flasksQuantity: {
            required
        }
    }
};
</script>