var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        _vm._l(_vm.arrangedElements, function(arrangedElement, index) {
          return _c(
            "div",
            { key: index },
            [
              _c("h1", { staticClass: "has-text-weight-bold is-size-6" }, [
                _vm._v(_vm._s(arrangedElement.name))
              ]),
              _vm._v(" "),
              _c("b-table", {
                attrs: { data: arrangedElement.elements },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          arrangedElement.name == "Activo"
                            ? _c(
                                "b-table-column",
                                { attrs: { label: "Nombre" } },
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(props.row.medicine.commercial_name)
                                    )
                                  ])
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-table-column",
                            { attrs: { label: "Componente" } },
                            [
                              _c("div", [
                                _vm._v(_vm._s(props.row.component.name))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          props.row.component.name != "Trasvase"
                            ? _c(
                                "b-table-column",
                                {
                                  attrs: {
                                    label: "Cantidad cliente",
                                    centered: ""
                                  }
                                },
                                [
                                  arrangedElement.name == "Activo"
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(props.row.medicine_quantity) +
                                            " " +
                                            _vm._s(
                                              props.row.medicine.component_unit
                                                .symbol
                                            )
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  arrangedElement.name == "Diluyente"
                                    ? _c("p", [
                                        _vm._v(
                                          _vm._s(props.row.medicine_quantity) +
                                            " ml"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.hasInfusionSet
        ? _c("b-message", { staticClass: "is-success" }, [
            _c("nav", { staticClass: "level" }, [
              _c("div", { staticClass: "level-left" }, [
                _c("h1", [_vm._v("Set de infusión")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "level-right" }, [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "check-circle" } })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v(" Solicitado")])
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }