<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right">
                <router-link
                    :to="{ path: '/oncologic_orders_authorizer/authorize', query: { orderId: orderId } }"
                    class="button"
                >
                    <span class="icon">
                        <font-awesome-icon icon="times-circle" />
                    </span>
                    <span> Cancelar edición</span>
                </router-link>
            </div>
        </nav>
        <hr>
        <div v-if="medic.payer">
            <serbitec-horizontal-order-data
                :medic="medic"
                :patient="patient"></serbitec-horizontal-order-data>
            <nav class="level">
                <div class="level-left">
                </div>
                <div class="level-right">
                    <div class="field">
                        <a v-if="!showMixForm" class="button" @click="showMixForm = !showMixForm">
                            <span class="icon is-small">
                                <font-awesome-icon icon="plus" />
                            </span>
                            <span>Agregar bolo</span>
                        </a>
                        <a v-if="!showFlaskForm" class="button" @click="showFlaskForm = !showFlaskForm">
                            <span class="icon is-small">
                                <font-awesome-icon icon="plus" />
                            </span>
                            <span>Agregar frascos</span>
                        </a>
                    </div>
                </div>
            </nav>
            <div v-if="showMixForm">
                <serbitec-oncologic-mix-form
                :comes-from-edit="true"
                @close-form="showMixForm = false"></serbitec-oncologic-mix-form>
                <br>
            </div>
            <div v-if="showFlaskForm">
                <serbitec-oncologic-flask-form
                :comes-from-edit="true"
                @close-form="showFlaskForm = false"></serbitec-oncologic-flask-form>
                <br>
            </div>
            <div>
                <serbitec-oncologic-order-items-table></serbitec-oncologic-order-items-table>
            </div>
            <br>
            <b-field grouped group-multiline="">
                <b-field
                :label="'Fecha de entrega'"
                :message="[
                    'De click para modificar la fecha de entrega'
                ]"
                expanded>
                    <b-datepicker
                        v-model.trim="deliveryDate"
                        :date-formatter="d => d.toLocaleDateString('es-US',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })"
                        :min-date="minDate">
                    </b-datepicker>
                </b-field>
                <b-field
                :label="'Hora de entrega'"
                :message="[
                    'De click para modificar la hora de entrega'
                ]"
                expanded>
                    <b-timepicker
                        v-model.trim="deliveryHour"
                        :min-time="minTime">
                    </b-timepicker>
                </b-field>
            </b-field>
            <b-field style="margin-top:25px" position="is-centered" grouped>
                <p class="control has-text-centered">
                    <button @click="editAndAuthorizeMixture" class="button is-primary" :disabled="orderIsEmpty">
                        Editar y autorizar orden
                    </button>
                </p>
            </b-field>
        </div>
    </div>
</template>

<script>
import serbitecHorizontalOrderData from '../../../components/dashboard/utils/horizontal-order-data.vue';
import serbitecOncologicMixForm from '../../../components/dashboard/requests/oncologic-orders/oncologic-mix-form.vue';
import serbitecOncologicFlaskForm from '../../../components/dashboard/requests/oncologic-orders/oncologic-flask-form.vue';
import serbitecOncologicOrderItemsTable from '../../../components/dashboard/requests/oncologic-orders/oncologic-order-items-table.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';
import axios from 'axios';
import parseISO from 'date-fns/parseISO';
import gql from 'graphql-tag';
import { mapState } from 'vuex';

const query = gql` query ( $id: Int! )
{
    oncologic_order( id: $id ) {
        id
        delivery_date
        medic {
            id
            first_name
            last_name_1
            last_name_2
            email
            medical_card
            payer {
                id
                invoicing_data {
                    id
                    business_name
                }
            }
        }
        patient {
            id
            name
            diagnosis
            height_in_meters
            weight_in_kilograms
            birth_date
            file_id
            location
            bed
        }
        mixtures{
            id
            comments
            elements{
                id
                medicine_quantity
                medicine {
                    id
                    commercial_name
                    component_unit {
                        id
                        symbol
                    }
                }
                component {
                    id
                    name
                    component_type {
                        id
                    }
                }
            }
        }
        requested_flasks {
            id
            comments
            number_of_flasks
            medicine_presentation {
                id
                volume_in_ml
                component_quantity
                medicine {
                    id
                    commercial_name
                    component_unit {
                        id
                        symbol
                    }
                    component {
                        id
                        name
                    }
                }
            }
        }
    }
}`;

function initialDataSate () {
    const today = new Date();
    const min = new Date();
    min.setHours(today.getHours());
    min.setMinutes(today.getMinutes());

    return {
        showMixForm: false,
        showFlaskForm: false,
        minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        minTime: min,

        deliveryDate: null,
        deliveryHour: null,
        comesFromQuery: false,
        route: [
            {
                label: 'Revisión de órdenes oncológicas',
                to: '/oncologic_orders_authorizer',
                active: false
            },
            {
                label: 'Editar',
                to: '/oncologic_orders_authorizer/edit',
                active: true
            }
        ]
    };
}
export default {
    props: {
        orderId: {
            type: [Number, String],
            default: 0 | '0'
        }
    },
    components: {
        serbitecHorizontalOrderData,
        serbitecOncologicMixForm,
        serbitecOncologicFlaskForm,
        serbitecOncologicOrderItemsTable,
        serbitecBreadcumbs
    },
    watch: {
        deliveryDate ( newDate ) {
            const today = new Date();
            const min = new Date();
            min.setHours(today.getHours());
            min.setMinutes(today.getMinutes());

            if ( this.comesFromQuery ) {
                return this.comesFromQuery = false;
            }
            
            if ( newDate > new Date(today.getFullYear(), today.getMonth(), today.getDate()) ) {
                this.minTime = null;
            } else {
                this.minTime = min;
                this.deliveryHour = min;
            }
        }
    },
    computed: {
        ...mapState({
            patient: state => state.patient.patientData,
            medic: state => state.medic.medicData,
            order: state => state.oncologicOrder,
        }),
        orderIsEmpty () {
            if ( ( this.order.mixtures === undefined || this.order.mixtures == 0 ) && ( this.order.flasks === undefined || this.order.flasks == 0 ) ) {
                return true;
            } else {
                return false;
            }
        },
    },
    beforeRouteLeave( to, from, next ) {
        this.$store.commit('oncologicOrder/RESET_ORDER');
        next();
    },
    data: initialDataSate,
    methods: {
        getRequestParams () {
            this.deliveryDate.setHours(this.deliveryHour.getHours());
            this.deliveryDate.setMinutes(this.deliveryHour.getMinutes());

            const requestParams = {
                id: this.orderId,
                delivery_date: this.deliveryDate,
                mixtures: this.order.mixtures,
                flasks: this.order.flasks
            };
            return requestParams;
        },
        editAndAuthorizeMixture () {
            var postParams = this.getRequestParams();
            var url = '/api/oncologic/orders/edit_and_authorize';
            this.makeRequest( url, axios.post, postParams );
        },
        makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method( url, params )
            .then(function () {
                this.$serbitec.finishLoading();
                this.$router.push({ path: '/oncologic_orders_authorizer', query: { success: true } });
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$serbitec.finishLoading();
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
        }
    },
    apollo: {
        order: {
            fetchPolicy: 'no-cache',
            query: query,
            manual: true,
            variables () {
                return {
                    id: this.orderId
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            result ( { data, loading } ) {
                if (!loading) {
                    data.oncologic_order.mixtures.map( mixture => {
                        const obj = {};
                        const medicines = [];
                        obj.id = mixture.id;
                        obj.comments = mixture.comments;
                        obj.infusion_set = false;
                        mixture.elements.map( element => {
                            if ( element.medicine ) {
                                const medicine = {};
                                medicine.component_name = element.component.name;
                                medicine.medicine_id = element.medicine.id;
                                medicine.medicine_quantity = element.medicine_quantity;
                                medicine.medicine_unit_symbol = element.medicine.component_unit.symbol;
                                medicine.name = element.medicine.commercial_name;
                                medicines.push(medicine);
                            } else if ( element.component.component_type.id == 5 || element.component.component_type.id == 13 ) {
                                obj.thinner_id = element.component.id;
                                obj.thinner_name = element.component.name;
                                obj.thinner_quantity = element.medicine_quantity;
                            } else if ( element.component.component_type.id == 8 ) {
                                obj.infusion_set = true;
                            }
                        } );
                        obj.medicines = medicines;
                        this.$store.commit('oncologicOrder/ADD_ONCOLOGIC_MIXTURE_TO_ORDER', obj );
                    });
                    data.oncologic_order.requested_flasks.map( flask => {
                        const obj = {};
                        obj.id = flask.id;
                        obj.comments = flask.comments;
                        obj.medicine_component_name = flask.medicine_presentation.medicine.component.name;
                        obj.medicine_id = flask.medicine_presentation.medicine.id;
                        obj.medicine_name = flask.medicine_presentation.medicine.commercial_name;
                        obj.medicine_presentation_id = flask.medicine_presentation.id;
                        obj.medicine_presentation_unit_symbol = flask.medicine_presentation.medicine.component_unit.symbol;
                        obj.number_of_flasks = flask.number_of_flasks;
                        obj.presentation_quantity = flask.medicine_presentation.component_quantity;
                        this.$store.commit('oncologicOrder/ADD_ONCOLOGIC_FLASK_TO_ORDER', obj );
                    } );
                    this.$store.commit('patient/SET_PATIENT', data.oncologic_order.patient);
                    this.$store.commit('medic/SET_MEDIC', data.oncologic_order.medic);

                    this.deliveryDate = parseISO(data.oncologic_order.delivery_date);
                    this.deliveryHour = parseISO(data.oncologic_order.delivery_date);
                    this.comesFromQuery = true;
                }
            }
        },
    }
};
</script>
