import { render, staticRenderFns } from "./select-supplies.vue?vue&type=template&id=038d7678&scoped=true&"
import script from "./select-supplies.vue?vue&type=script&lang=js&"
export * from "./select-supplies.vue?vue&type=script&lang=js&"
import style0 from "./select-supplies.vue?vue&type=style&index=0&id=038d7678&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "038d7678",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/mezclas.serbitec.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('038d7678')) {
      api.createRecord('038d7678', component.options)
    } else {
      api.reload('038d7678', component.options)
    }
    module.hot.accept("./select-supplies.vue?vue&type=template&id=038d7678&scoped=true&", function () {
      api.rerender('038d7678', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/pages/dashboard/oncologic-preparation-area/select-supplies.vue"
export default component.exports