<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Agregar médico
            </p>
        </header>
        <section class="modal-card-body">
            <b-message class="serbitec-small-top-margin" type="is-warning" has-icon>
                Los médicos que se dan de alta a través de esta forma no serán usuarios del sistema y por consecuente no tendrán acceso al mismo. El registrante del médico es responsable de su alta y confirma la verdacidad de los datos proporcionados incluyendo la cédula profesional.
                <br>
                <small>Si se desea dar de alta a un médico usuario del sistema, favor de contactar al administrador.</small>
            </b-message>
            <b-field grouped>
                <b-field
                    label="Nombre"
                    label-position="on-border"
                    :type="{'is-danger' : $v.name.$error }"
                    :message="{
                        'Se requiere introducir el nombre del médico' : !$v.name.required && $v.name.$error
                    }"
                    expanded>
                    <b-input
                        v-model="$v.name.$model"
                        type="text">
                    </b-input>
                </b-field>
                <b-field
                    label="Apellido Paterno"
                    label-position="on-border"
                    :type="{'is-danger' : $v.lastName1.$error }"
                    :message="{
                        'Se requiere introducir el apellido paterno del médico' : !$v.lastName1.required && $v.lastName1.$error
                    }"
                    expanded>
                    <b-input
                        v-model="$v.lastName1.$model"
                        type="text">
                    </b-input>
                </b-field>
                <b-field
                    label="Apellido Materno"
                    label-position="on-border"
                    :type="{'is-danger' : $v.lastName2.$error }"
                    :message="{
                        'Se requiere introducir el apellido materno del médico' : !$v.lastName2.required && $v.lastName2.$error
                    }"
                    expanded>
                    <b-input
                        v-model="$v.lastName2.$model"
                        type="text">
                    </b-input>
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin serbitec-small-bottom-margin"
                label="Cédula profesional"
                label-position="on-border"
                :type="{'is-danger' : $v.medicalCard.$error }"
                :message="{
                    'Se requiere introducir la cédula profesional del médico' : !$v.medicalCard.required && $v.medicalCard.$error,
                    'La cédula profesional sólo puede contener números' : !$v.medicalCard.numeric && $v.medicalCard.$error,
                    'La cédula profesional debe tener 8 números. Si la cédula contiene menos de 8 caracteres complete con ceros a la izquierda.' : (!$v.medicalCard.minLength || !$v.medicalCard.maxLength) && $v.medicalCard.$error
                }"
                expanded>
                <b-input
                    v-model="$v.medicalCard.$model"
                    type="text">
                </b-input>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                Cancelar
            </button>
            <button class="button is-primary" @click="submitForm">
                Agregar
            </button>
        </footer>
    </div>
</template>

<script>
import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators';

function initialDataState() {
    return {
        name: null,
        lastName1: null,
        lastName2: null,
        medicalCard: null
    };
}

export default {
    data: initialDataState,
    props: {
        payerId: {
            type: [Number, String],
            default: 0 | '0'
        }
    },
    methods: {
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.submitMedic();
            }
        },
        getMedicData () {
            return {
                first_name: this.name,
                last_name_1: this.lastName1,
                last_name_2: this.lastName2,
                medical_card: this.medicalCard,
                payer_id: this.payerId
            };
        },
        submitMedic () {
            this.makeRequest('/api/registration_associates/medics', this.$axios.post, this.getMedicData());
        },
        async makeRequest( url, method, params ){
            this.$serbitec.startLoading();
            try {
                let response = await method(url,params);
                this.$emit('medic-added', response.data.medic);
                this.$serbitec.finishLoading();
                this.$emit('close');
            } catch (error) {
                console.error(error);
                this.$serbitec.finishLoading();
            }
        }
    },
    validations: {
        name: {
            required
        },
        lastName1: {
            required
        },
        lastName2: {
            required
        },
        medicalCard: {
            required,
            numeric,
            minLength: minLength(8),
            maxLength: maxLength(8), 
        }
    }
};
</script>
