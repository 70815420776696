<template>
    <div>
        <hr>
        <h1 class="subtitle">Electrolitos</h1>
        <serbitec-electrolytes-table :table-data="electrolytes"></serbitec-electrolytes-table>
        <div
        v-for="electrolyte in electrolytes" :key="electrolyte.id">
            <div class="columns" style="padding-bottom: 10px">
                <div class="column">
                    <div class="field">
                        <b-checkbox
                        :value="electrolyte.showInput"
                        @input="clearItemOnFalse($event, electrolyte)">
                            <small>{{ electrolyte.name }}</small>
                        </b-checkbox>
                    </div>
                </div>
                <div class="column">
                    <b-field
                        v-if="electrolyte.showInput"
                        horizontal
                        expanded>
                        <b-field>
                            <b-input
                                :value="electrolyte.quantity"
                                @input="updateElectrolyteQuantity( $event, electrolyte.id )"
                                v-cleave="masks.custom"
                                placeholder="10.00"
                                type="text"
                                expanded
                            >
                            </b-input>
                            <b-select
                            :value="electrolyte.unitSymbol"
                            @input="updateElectrolyteUnitSymbol( $event, electrolyte.id )"
                            name="unit_symbol"
                            >
                                <option v-bind:value="'mEq'">mEq</option>
                                <option v-bind:value="'mEq/Kg'">mEq/Kg</option>
                                <option v-bind:value="'ml'">ml</option>
                            </b-select>
                        </b-field>
                    </b-field>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import serbitecElectrolytesTable from './electrolytes-table.vue';
import { mapState } from 'vuex';
import Cleave from 'cleave.js';

const cleave = {
    name: 'cleave',
    bind(el, binding) {
        const input = el.querySelector('input');
        input._vCleave = new Cleave(input, binding.value);
    },
    unbind(el) {
        const input = el.querySelector('input');
        input._vCleave.destroy();
    }
};

export default {
    directives: { cleave },
    components: {
        serbitecElectrolytesTable,
    },
    computed: {
        ...mapState({
            electrolytes: state => state.nutritionalOrder.electrolytes
        })
    },
    data () {
        return {
            masks: {
                custom: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'none',
                    numeralPositiveOnly: true
                }
            }
        };
    },
    methods: {
        clearItemOnFalse ( input, selectedElectrolyte ) {
            if ( !input ) {
               this.$store.commit( 'nutritionalOrder/UNSELECT_SELECTED_ELECROTLYTE_ITEM', selectedElectrolyte.id ); 
            } else {
                const data = {
                    id: selectedElectrolyte.id,
                    selectedComponent: selectedElectrolyte,
                    showInput: input
                };
                this.$store.commit( 'nutritionalOrder/SET_SELECTED_ELECTROLYTE_ITEM', data );
            }
        },
        updateElectrolyteQuantity ( input, selectedElectrolyte ) {
            const data = {
                id: selectedElectrolyte,
                quantity: input
            };
            this.$store.commit( 'nutritionalOrder/SET_SELECTED_ELECTROLYTE_ITEM', data );
        },
        updateElectrolyteUnitSymbol ( input, selectedElectrolyte ) {
            const data = {
                id: selectedElectrolyte,
                unitSymbol: input
            };
            this.$store.commit( 'nutritionalOrder/SET_SELECTED_ELECTROLYTE_ITEM', data );
        }
    }
};
</script>