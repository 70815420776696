<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right"></div>
        </nav>
        <hr>
        <div v-if="flask">
            <div class="box">
                <h1 class="title has-text-centered">Lote LO-{{ flask.id }}</h1>
            </div>
            <serbitec-horizontal-order-data
            :medic="flask.order.medic"
            :patient="flask.order.patient"></serbitec-horizontal-order-data>
            <div class="box" style="margin-bottom: 15px">
                <h1 class="subtitle">Frascos</h1>
                <serbitec-oncologic-flasks-table
                :flasks="[flask]"></serbitec-oncologic-flasks-table>
            </div>
            <serbitec-quality-inspection
            v-model="qualityComments"
            :quality-inspection-check-list="qualityInspectionCheckList"></serbitec-quality-inspection>
            <br>
            <b-field style="margin-top:15px" position="is-centered" grouped>
                <p class="control has-text-centered">
                    <button
                    :disabled="inspectionRejected || !inspectionCompleted"
                    @click="submitQuality( true )"
                    class="button is-primary">
                        Aprobada
                    </button>
                </p>
                <p class="control has-text-centered">
                    <button
                    :disabled="!inspectionCompleted"
                    @click="submitQuality( false )"
                    class="button">
                        Rechazada
                    </button>
                </p>
            </b-field>
        </div>
    </div>
</template>

<script>
import serbitecHorizontalOrderData from '../../../components/dashboard/utils/horizontal-order-data.vue';
import serbitecOncologicFlasksTable from '../../../components/dashboard/utils/oncologic-flasks-table.vue';
import serbitecQualityInspection from '../../../components/dashboard/quality-control/quality-inspection.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';
import axios from 'axios';

import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    requested_flask( id: $id ) {
        id
        number_of_flasks
        medicine_presentation {
            id
            component_quantity
            volume_in_ml
            medicine {
                id
                commercial_name
                component_unit {
                    id
                    symbol
                }
                component {
                    id
                    name
                }
            }
        }
        order {
            id
            medic {
                id
                first_name
                last_name_1
                last_name_2
                email
                medical_card
                payer {
                    id
                    invoicing_data {
                        id
                        business_name
                    }
                }
            }
            patient {
                id
                name
                diagnosis
                height_in_meters
                weight_in_kilograms
                birth_date
                file_id
                location
                bed
            }
        }
    }
}`;

function initialDataState () {
    return {
        loading: false,
        qualityInspectionCheckList: [
            {
                label: 'Color',
                isApproved: null
            },
            {
                label: 'Aspecto',
                isApproved: null
            },
            {
                label: 'Libre de partículas',
                isApproved: null
            },
            {
                label: 'Integridad del contenedor',
                isApproved: null
            }
        ],
        qualityComments: null,
        route: [
            {
                label: 'Control de calidad oncológico',
                to: '/oncologic_orders_quality_control',
                active: false
            },
            {
                label: 'Inspección de frasco',
                to: '/oncologic_orders_quality_control/flask_inspection',
                active: true
            }
        ]
    };
}
export default {
    props: {
        flaskId: {
            type: [Number, String],
            default: 0 | '0'
        }
    },
    components: {
        serbitecHorizontalOrderData,
        serbitecOncologicFlasksTable,
        serbitecQualityInspection,
        serbitecBreadcumbs
    },
    computed: {
        inspectionRejected () {
            return this.qualityInspectionCheckList.find( item => item.isApproved == false ) != undefined;
        },
        inspectionCompleted () {
            return this.qualityInspectionCheckList.filter( item => item.isApproved != null ).length == this.qualityInspectionCheckList.length;
        }
    },
    data: initialDataState,
    methods: {
        getRequestParams ( approved ) {
            const requestParams = {};
            if ( approved ) {
                requestParams.qa_appearance_color = true;
                requestParams.qa_appearance_aspect = true;
                requestParams.qa_appearance_particle_free = true;
                requestParams.qa_appearance_container_integrity = true;
                requestParams.new_state_id = 6;
            } else {
                this.qualityInspectionCheckList.map( listItem => {
                    if ( listItem.label == 'Color' ) {
                        requestParams.qa_appearance_color = listItem.isApproved;
                    }
                    if ( listItem.label == 'Aspecto' ) {
                        requestParams.qa_appearance_aspect = listItem.isApproved;
                    }
                    if ( listItem.label == 'Libre de partículas' ) {
                        requestParams.qa_appearance_particle_free = listItem.isApproved;
                    }
                    if ( listItem.label == 'Integridad del contenedor' ) {
                        requestParams.qa_appearance_container_integrity = listItem.isApproved;
                    }
                } );
                requestParams.new_state_id = 7;
            }
            requestParams.qa_appearance_comments = this.qualityComments;
            return requestParams;
        },
        submitQuality ( approved ) {
            var url = '/api/oncologic/flasks/' + this.flaskId + '/state';
            this.makeRequest( url, axios.post, this.getRequestParams( approved ), approved );
        },
        makeRequest ( url, method, params, isApproved ) {
            this.$serbitec.startLoading();
            method( url, params )
            .then(function () {
                if ( isApproved ) {
                    this.$serbitec.finishLoading();
                    this.$router.push({ path: '/oncologic_orders_quality_control', query: { success: true } });
                } else {
                    this.$serbitec.finishLoading();
                    this.$router.push({ path: '/oncologic_orders_quality_control', query: { rejected: true } });
                }
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$serbitec.finishLoading();
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
        }
    },
    apollo: {
        flask: {
            query: query,
            variables () {
                return {
                    id: this.flaskId
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update ( data ) {
                return data.requested_flask;
            }
        },
    }
};
</script>