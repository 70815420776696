<template>
    <div class="box">
        <div class="columns">
            <div class="column is-one-fifth">
                <figure class="image is-128x128 serbitec-has-image-centered">
                    <img class="is-rounded" src="https://picsum.photos/320/320?grayscale" />
                </figure>
            </div>
            <div class="column is-half">
                <h1 class="subtitle">{{ user.first_name }} {{ user.last_name_1 }} {{ user.last_name_2 }}</h1>
                <p v-if="user.medical_card != null"><strong>Cédula profesional: </strong>{{ user.medical_card }}</p>
                <p><strong>Correo electrónico: </strong>{{ user.email }}</p>
                <br>
                <a @click="editPassword" class="button is-small">
                    <span class="icon is-medium">
                        <font-awesome-icon icon="key" />
                    </span>
                    <span>Cambiar contraseña</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import serbitecEditPasswordModal from './edit-password-modal.vue'
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState({
            user: state => state.user
        })
    },
    methods: {
        editPassword () {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecEditPasswordModal,
                hasModalCard: true,
                trapFocus: true,
                fullScreen: false,
                props: {
                    'userId': this.user.id
                }
            });
        }
    }
};
</script>