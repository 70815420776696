<template>
    <b-field class="serbitec-small-left-margin">
        <b-checkbox-button
        v-model="componentTypes"
        :native-value="oncologicType"
        @input="updateComponentTypes">
            <span>Oncológico</span>
        </b-checkbox-button>

        <b-checkbox-button
        v-model="componentTypes"
        :native-value="nutritionalType"
        @input="updateComponentTypes">
            <span>Nutricional</span>
        </b-checkbox-button>
    </b-field>
</template>

<script>
export default {
    props: {
        comesFromPage: {
            type: String,
            default: ''
        }
    },
    data () {
        return {
            componentTypes: [],
            oncologicType: {
                id: 0,
                name: 'is_oncologic',
                label: 'Oncológico'
            },
            nutritionalType: {
                id: 1,
                name: 'is_nutritional',
                label: 'Nutricional'
            }
        };
    },
    methods: {
        updateComponentTypes () {
            let data = {
                componentTypes: this.componentTypes,
                comesFromPage: this.comesFromPage
            };
            this.$store.commit( 'recordFilters/UPDATE_COMPONENT_TYPES', data );
        }
    }
};
</script>