var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav", { staticClass: "level is-mobile" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "a",
            {
              staticClass: "button",
              on: {
                click: function($event) {
                  _vm.openCollapse = !_vm.openCollapse
                }
              }
            },
            [
              _c(
                "span",
                { staticClass: "icon is-medium" },
                [_c("font-awesome-icon", { attrs: { icon: "file-export" } })],
                1
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          staticClass: "serbitec-medium-bottom-margin",
          attrs: { open: _vm.openCollapse }
        },
        [
          _c("div", { staticClass: "notification" }, [
            _c("div", { staticClass: "content" }, [
              _c("p", [
                _c("strong", [_vm._v("Generación de reportes.")]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("small", [
                  _vm._v(
                    " Los reportes se generaran sobre la lista de mezclas mostradas en pantalla, si se aplicó algún filtro, se respetará durante la generación del reporte."
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "button is-small",
                  on: { click: _vm.showGenerateReportModal }
                },
                [
                  _c(
                    "span",
                    { staticClass: "icon is-medium" },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "shipping-fast" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Reporte de envío consolidado")])
                ]
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("serbitec-filters", {
        attrs: {
          "active-filters": _vm.filterOptions,
          "comes-from-page": "oncologicOrdersRecords"
        }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("serbitec-oncologic-records-table", {
        attrs: {
          "table-type": "oncologic",
          mixtures: _vm.orders,
          "mixtures-count": _vm.ordersCount,
          loading: _vm.loading,
          page: _vm.page,
          pageSize: _vm.pageSize,
          sortField: _vm.sortField,
          sortOrder: _vm.sortOrder
        },
        on: {
          "update:loading": function($event) {
            _vm.loading = $event
          },
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:sortField": function($event) {
            _vm.sortField = $event
          },
          "update:sort-field": function($event) {
            _vm.sortField = $event
          },
          "update:sortOrder": function($event) {
            _vm.sortOrder = $event
          },
          "update:sort-order": function($event) {
            _vm.sortOrder = $event
          },
          "page-change": _vm.onPageChange,
          sort: _vm.onSort,
          "item-canceled": _vm.orderCanceled
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("div", { staticClass: "level-item" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("Registro de órdenes oncológicas")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }