<template>
    <div>
        <nav class="level is-mobile">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title">Insumos nutricionales del área de preparación</h1>
                </div>
            </div>

            <div class="level-right">
                <b-dropdown
                    aria-role="list"
                    position="is-bottom-left"
                >
                    <b-button slot="trigger">
                        <font-awesome-icon icon="ellipsis-v" />
                    </b-button>

                    <b-dropdown-item has-link>
                        <router-link
                            :to="{ path: 'request_medicines' }"
                            style="margin-right:10px"
                            append
                        >
                            <font-awesome-icon class="serbitec-small-right-margin" icon="prescription-bottle" />
                            <span> Solicitar medicamento</span>
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item has-link>
                        <router-link
                            :to="{ path: 'receive_medicines' }"
                            style="margin-right:10px"
                            append
                        >
                            <font-awesome-icon class="serbitec-small-right-margin" icon="clipboard-list" />
                            <span> Recepción de medicamento</span>
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item has-link>
                        <router-link
                            :to="{ path: 'update' }"
                            style="margin-right:10px"
                            append
                        >
                            <font-awesome-icon class="serbitec-small-right-margin" icon="boxes" />
                            <span> Corte de almacén</span>
                        </router-link>
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </nav>
        <hr>
        <div class="box">
            <serbitec-items-table
            internal-warehouse-type="nutritional"></serbitec-items-table>
        </div>
    </div>
</template>

<script>
import serbitecItemsTable from '../../../components/dashboard/preparation-area-supplies/items-table.vue';

export default {
    components:{
        serbitecItemsTable
    }
};
</script>
