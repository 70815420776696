import Cookie from 'js-cookie';

export default function auth({ next, router, store }) {
    const user = store.state.user;
    if ( user && Object.keys(user).length === 0 && user.constructor === Object ) {
        return router.push({ path: '/login' }).catch((error) => {
            if (error.type !== 4) {
                throw error;
            }
        });
    }
    if (Cookie.get('serbitec-session-api-token') === undefined) {
        Cookie.set('serbitec-session-api-token', user.api_token, { sameSite: 'lax' });
    }

    return next();
}