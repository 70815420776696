var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-select",
    {
      attrs: { value: _vm.selectedManufacturer, expanded: "" },
      on: {
        input: function(option) {
          return _vm.$emit("manufacturer-selected", option)
        }
      }
    },
    _vm._l(_vm.medicineManufacturers, function(option) {
      return _c("option", { key: option.id, domProps: { value: option } }, [
        _vm._v("\n        " + _vm._s(option.name) + "\n    ")
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }