var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.payerMedicines,
            loading: _vm.loading,
            paginated: "",
            "backend-pagination": "",
            total: _vm.payerMedicinesCount,
            "per-page": _vm.pageSize,
            "aria-next-label": "Siguiente página",
            "aria-previous-label": "Página anterior",
            "aria-page-label": "Página",
            "aria-current-label": "Página actual"
          },
          on: { "page-change": _vm.changePage },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    { attrs: { label: "Activo", centered: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c("b-switch", {
                            on: {
                              input: function($event) {
                                return _vm.toggleEnabled(props.row.id)
                              }
                            },
                            model: {
                              value: props.row.enabled,
                              callback: function($$v) {
                                _vm.$set(props.row, "enabled", $$v)
                              },
                              expression: "props.row.enabled"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Medicamento" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.medicine.commercial_name) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Marca" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.medicine.manufacturer.name) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Tipo" } },
                    [
                      props.row.medicine.component.is_oncologic
                        ? _c("b-tag", [_vm._v("Oncológico")])
                        : _vm._e(),
                      _vm._v(" "),
                      props.row.medicine.component.is_nutritional
                        ? _c("b-tag", [_vm._v("Nutricional")])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Frasco completo", centered: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "field" },
                        [
                          _c("b-checkbox", {
                            attrs: {
                              disabled:
                                props.row.medicine.component_unit.symbol == "pz"
                            },
                            on: {
                              input: function($event) {
                                return _vm.togglePricePerFlask(props.row.id)
                              }
                            },
                            model: {
                              value:
                                props.row
                                  .is_charged_by_full_medicine_presentation,
                              callback: function($$v) {
                                _vm.$set(
                                  props.row,
                                  "is_charged_by_full_medicine_presentation",
                                  $$v
                                )
                              },
                              expression:
                                "props.row.is_charged_by_full_medicine_presentation"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Precio", centered: "" } },
                    [
                      (props.row.payer.invoicing_data
                        .price_in_cents_per_oncologic_mixture > 0 &&
                        props.row.medicine.component.is_oncologic &&
                        !props.row.medicine.component.is_nutritional) ||
                      (props.row.payer.invoicing_data
                        .price_in_cents_per_nutritional_mixture > 0 &&
                        props.row.medicine.component.is_nutritional &&
                        !props.row.medicine.component.is_oncologic) ||
                      (props.row.payer.invoicing_data
                        .price_in_cents_per_oncologic_mixture > 0 &&
                        props.row.payer.invoicing_data
                          .price_in_cents_per_nutritional_mixture > 0 &&
                        props.row.medicine.component.is_oncologic &&
                        props.row.medicine.component.is_nutritional)
                        ? _c("b-tag", [
                            _vm._v(
                              "\n                    Precio por mezcla\n                "
                            )
                          ])
                        : props.row.payer_medicine_presentations.length > 0
                        ? _c("b-tag", [
                            _vm._v(
                              "\n                    Precio por presentación\n                "
                            )
                          ])
                        : _c(
                            "b-field",
                            { attrs: { position: "is-centered" } },
                            [
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "button",
                                  { staticClass: "button is-static" },
                                  [_vm._v("$")]
                                )
                              ]),
                              _vm._v(" "),
                              _c("b-input", {
                                directives: [
                                  {
                                    name: "cleave",
                                    rawName: "v-cleave",
                                    value: _vm.masks.numeral,
                                    expression: "masks.numeral"
                                  }
                                ],
                                attrs: {
                                  value:
                                    props.row.price_in_cents_per_ml_or_pz / 100,
                                  type: "text"
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.onInputPricePerMl(props.row.id)
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                props.row.medicine.component_unit.symbol == "pz"
                                  ? _c(
                                      "button",
                                      { staticClass: "button is-static" },
                                      [_vm._v("MXN por pieza")]
                                    )
                                  : _c(
                                      "button",
                                      { staticClass: "button is-static" },
                                      [_vm._v("MXN por ml")]
                                    )
                              ])
                            ],
                            1
                          )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Presentaciones", centered: "" } },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "button is-light is-small",
                          class: {
                            "is-primary":
                              props.row.payer_medicine_presentations.length > 0
                          },
                          attrs: {
                            to: {
                              path: "presentations",
                              query: {
                                payer_id: props.row.payer.id,
                                payer_medicine_id: props.row.id
                              }
                            },
                            append: ""
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "pills" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Eliminar", centered: "" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "button is-light is-small is-danger",
                          on: {
                            click: function($event) {
                              return _vm.confirmDeleteMedicine(props.row.id)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "trash-alt" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("No hay medicamentos registrados.")])
                ]
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }