import { render, staticRenderFns } from "./not-authorized.vue?vue&type=template&id=64d16959&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/mezclas.serbitec.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('64d16959')) {
      api.createRecord('64d16959', component.options)
    } else {
      api.reload('64d16959', component.options)
    }
    module.hot.accept("./not-authorized.vue?vue&type=template&id=64d16959&", function () {
      api.rerender('64d16959', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/pages/error/not-authorized.vue"
export default component.exports