var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "level" }, [
      _c(
        "div",
        { staticClass: "level-left" },
        [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" })
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _vm.nutritionalMixture && _vm.itemsInNutritionalWarehouse
      ? _c(
          "div",
          [
            _c("div", { staticClass: "box has-text-centered" }, [
              _c("h1", { staticClass: "title" }, [
                _vm._v(
                  "Orden: ON-" +
                    _vm._s(_vm.nutritionalMixture.order.id) +
                    " | Lote: LN-" +
                    _vm._s(_vm.mixtureId)
                )
              ])
            ]),
            _vm._v(" "),
            _c("serbitec-horizontal-order-data", {
              attrs: {
                medic: _vm.nutritionalMixture.order.medic,
                patient: _vm.nutritionalMixture.order.patient
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column is-half is-size-4" }, [
                _vm._v("\n                Volumen total: "),
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.nutritionalMixture.total_volume_in_ml) + " ml"
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "column is-half is-size-4" }, [
                _vm._v("\n                Sobrellenado: "),
                _c("strong", [
                  _vm._v(_vm._s(_vm.nutritionalMixture.overfill_in_ml) + " ml")
                ])
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.itemsInNutritionalWarehouse, function(element, index) {
              return _c(
                "div",
                { key: index, staticClass: "box" },
                [
                  element.remainingQuantityInMl != element.componentQuantityInMl
                    ? _c(
                        "nav",
                        { staticClass: "level serbitec-narrow-bottom" },
                        [
                          _c("div", { staticClass: "level-left" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "level-right" }, [
                            element.isCompleted
                              ? _c(
                                  "p",
                                  { staticClass: "has-text-success" },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "check-circle" }
                                    }),
                                    _vm._v(" Completado")
                                  ],
                                  1
                                )
                              : _c(
                                  "p",
                                  { staticClass: "is-size-6 has-text-danger" },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: { icon: "exclamation-circle" }
                                    }),
                                    _vm._v(
                                      " Faltan " +
                                        _vm._s(
                                          _vm.setItemQuantity(
                                            element.remainingQuantityInMl,
                                            element.isPiece
                                          )
                                        )
                                    )
                                  ],
                                  1
                                )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("nav", { staticClass: "level serbitec-small-bottom" }, [
                    _c("div", { staticClass: "level-left" }, [
                      _c(
                        "p",
                        { staticClass: "has-text-weight-bold is-size-5" },
                        [_vm._v(_vm._s(element.componentName))]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "level-right" }, [
                      _c("p", { staticClass: "is-size-4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.setItemQuantity(
                              element.componentQuantityInMl,
                              element.isPiece
                            )
                          )
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-table",
                    {
                      attrs: {
                        data: element.nutritionalWarehouseItems,
                        checkable: "",
                        "checked-rows": element.checkedRows,
                        "header-checkable": false,
                        "is-row-checkable": _vm.enableCheckRow,
                        "default-sort": "expiry_date",
                        "default-sort-direction": "asc",
                        narrowed: ""
                      },
                      on: {
                        "update:checkedRows": function($event) {
                          return _vm.$set(element, "checkedRows", $event)
                        },
                        "update:checked-rows": function($event) {
                          return _vm.$set(element, "checkedRows", $event)
                        },
                        check: _vm.updateItemInNutritionalWarehouse
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _c(
                                  "b-table-column",
                                  { attrs: { label: "Lote", numeric: "" } },
                                  [
                                    _c("p", [
                                      props.row.prefix
                                        ? _c("strong", [
                                            _vm._v(
                                              _vm._s(props.row.prefix.prefix) +
                                                "-"
                                            )
                                          ])
                                        : _vm._e(),
                                      _vm._v(" " + _vm._s(props.row.batch))
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-table-column",
                                  { attrs: { label: "Nombre" } },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          props.row.medicine_presentation
                                            .medicine.commercial_name
                                        ) +
                                        "\n                        "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                !element.isInfusionSet
                                  ? _c(
                                      "b-table-column",
                                      {
                                        attrs: {
                                          label: "Presentación",
                                          centered: ""
                                        }
                                      },
                                      [
                                        _c("b-tag", [
                                          _vm._v(
                                            _vm._s(
                                              props.row.medicine_presentation
                                                .volume_in_ml
                                            ) + " ml"
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "b-table-column",
                                  {
                                    attrs: {
                                      field: "expiry_date",
                                      label: "Fecha de caducidad",
                                      sortable: "",
                                      centered: ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(props.row.expiry_date) +
                                        "\n                        "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-table-column",
                                  {
                                    attrs: {
                                      label: "Cantidad en almacén",
                                      centered: ""
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.setItemQuantity(
                                            props.row
                                              .quantity_in_nutritional_preparer_warehouse_in_microunits,
                                            element.isPiece,
                                            true
                                          )
                                        ) +
                                        "\n                        "
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    },
                    [
                      _vm._v(" "),
                      _c("template", { slot: "empty" }, [
                        _c("section", { staticClass: "section" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "content has-text-grey has-text-centered"
                            },
                            [
                              _c(
                                "p",
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: "exclamation-triangle",
                                      size: "is-large"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "No hay componentes disponibles en el área de preparación para este cliente. Solicite el componente al almacén para continuar"
                                )
                              ])
                            ]
                          )
                        ])
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            }),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticStyle: { "margin-top": "25px" },
                attrs: { position: "is-centered", grouped: "" }
              },
              [
                _vm.allMixtureMedicinesSelected
                  ? _c("p", { staticClass: "control has-text-centered" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-primary",
                          on: {
                            "~click": function($event) {
                              return _vm.submitMedicines($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    Seleccionar medicamentos\n                "
                          )
                        ]
                      )
                    ])
                  : _c("p", { staticClass: "control has-text-centered" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-primary",
                          attrs: { disabled: "" }
                        },
                        [
                          _vm._v(
                            "\n                    Seleccionar medicamentos\n                "
                          )
                        ]
                      )
                    ])
              ]
            )
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }