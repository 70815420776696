<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Agregar orden de compra
            </p>
        </header>
        <section class="modal-card-body">
            <b-field grouped group-multiline>
                <b-field
                    v-if="!isExternalWarehouse"
                    label="Orden de compra"
                    label-position="on-border"
                    :type="{'is-danger' : $v.purchaseOrder.$error }"
                    :message="{
                        'Se requiere introducir el número de orden de compra' : !$v.purchaseOrder.required && $v.purchaseOrder.$error
                    }"
                    expanded
                >
                    <b-input
                        v-model="$v.purchaseOrder.$model"
                        type="text"
                        expanded
                    />
                </b-field>
                <b-field
                    label="Número de frascos o piezas"
                    label-position="on-border"
                    :type="{'is-danger' : $v.flasksInOrder.$error }"
                    :message="{
                        'Se requiere introducir el número de frascos o piezas' : !$v.flasksInOrder.required && $v.flasksInOrder.$error,
                        'Debe ser un entero positivo' : !$v.flasksInOrder.integer && $v.flasksInOrder.$error
                    }"
                    expanded
                >
                    <b-input
                        v-model="$v.flasksInOrder.$model"
                        v-cleave="masks.integer"
                        type="text"
                        expanded
                    />
                </b-field>
            </b-field>
            <b-field
                v-if="!isExternalWarehouse"
                label="Costo por frasco o pieza"
                label-position="on-border"
                :type="{'is-danger' : $v.costInCentsPerItem.$error }"
                :message="{
                    'Se requiere introducir el costo por frasco (Mayor a 0.00)' : !$v.costInCentsPerItem.required && $v.costInCentsPerItem.$error
                }"
                expanded
            >
                <b-field :type="{'is-danger' : $v.costInCentsPerItem.$error }">
                    <p class="control">
                        <button class="button is-static">$</button>
                    </p>
                    <b-input
                    :value="$v.costInCentsPerItem.$model"
                    @input.native="onInputCostInCentsPerItem"
                    type="text"
                    v-cleave="masks.numeral"
                    expanded>
                    </b-input>
                    <p class="control">
                        <button class="button is-static">MXN</button>
                    </p>
                </b-field>
            </b-field>
            <b-message class="is-danger serbitec-small-top-margin">
                Revise cuidadosamente la información ya que no hay edición en alta de ordenes de {{ isExternalWarehouse ? 'ingreso' : 'compra' }}.
            </b-message>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                Cancelar
            </button>
            <button class="button is-primary" @click="submitForm">
                Agregar
            </button>
        </footer>
    </div>
</template>

<script>
import { required, integer, minValue } from 'vuelidate/lib/validators';
import Cleave from 'cleave.js';

const cleave = {
    name: 'cleave',
    bind(el, binding) {
        const input = el.querySelector('input');
        input._vCleave = new Cleave(input, binding.value);
    },
    unbind(el) {
        const input = el.querySelector('input');
        input._vCleave.destroy();
    }
};

function initialDataState() {
    return {
        purchaseOrder: '',
        flasksInOrder: '',
        costInCentsPerItem: '',
        rawCostInCentsPerItem: '',
        masks: {
            numeral: {
                numeral: true,
                numeralThousandsGroupStyle: 'none',
                numeralPositiveOnly: true
            },
            integer: {
                numeral: true,
                numeralDecimalScale: 0,
                numeralPositiveOnly: true
            }
        }
    };
}

export default {
    props: {
        batchId: {
            type: [Number, String],
            default: 0 | '0'
        },
        isExternalWarehouse: {
            type: Boolean,
            default: false
        }
    },
    data: initialDataState,
    directives: { cleave },
    methods: {
        onInputCostInCentsPerItem(){
            this.$v.costInCentsPerItem.$model = event.target._vCleave.getFormattedValue();
            this.rawCostInCentsPerItem = event.target._vCleave.getRawValue();
        },
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.addNewPurchaseOrder();
            }
        },
        getRequestParams () {
            const requestParams = {
                external_medicine_warehouse_id: this.batchId,
                purchase_order_id: this.purchaseOrder,
                flasks_in_order: this.flasksInOrder,
                cost_in_cents_per_item: parseInt( ( parseFloat(this.rawCostInCentsPerItem) * 100 ).toFixed(0) )
            };

            return requestParams;
        },
        addNewPurchaseOrder () {
            var postParams = this.getRequestParams();
            var url = '/api/external_medicine_warehouse/orders';
            this.makeRequest(url, this.$axios.post, postParams);
        },
        makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method(url,params)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha añadido la orden de compra'
                });
                this.$serbitec.finishLoading();
                this.$emit('success');
                this.$emit('close');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    },
    validations() {
        var validations = {
            flasksInOrder: {
                required,
                integer
            }
        };
        if (this.isExternalWarehouse) {
            return validations;
        }
        validations.purchaseOrder = {
            required
        };
        validations.costInCentsPerItem = {
            required,
            decimal: minValue('0.01')
        };
        console.log(this.getRequestParams());
        return validations;
    }
};
</script>