<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right"></div>
        </nav>
        <hr>
        <div v-if="mixture">
            <div class="box">
                <h1 class="title has-text-centered">Orden: ON-{{ mixture.order.id }} | Lote: LN-{{ mixture.id }}</h1>
            </div>
            <serbitec-horizontal-order-data
            :medic="mixture.order.medic"
            :patient="mixture.order.patient"></serbitec-horizontal-order-data>
            <div class="box">
                <div class="columns">
                    <div class="column is-one-third">
                        Volumen total: <strong>{{ mixture.total_volume_in_ml }} ml</strong>
                    </div>
                    <div class="column is-one-third">
                        Sobrellenado: <strong>{{ mixture.overfill_in_ml }} ml</strong>
                    </div>
                    <div v-if="water" class="column is-one-third">
                        Agua inyectable: <strong>{{ water }} ml</strong>
                    </div>
                </div>
            </div>
            <b-message>
                <h1><strong>Comentarios: </strong></h1>
                <p>{{ mixture.comments }}</p>
            </b-message>
            <serbitec-nutritional-elements-table
            :elements="mixture.elements"
            :total-volume="mixture.total_volume_in_ml"></serbitec-nutritional-elements-table>
            <serbitec-electrolytes-alerts
            class="serbitec-small-top-margin"
            :electrolytes="electrolytes"
            :computed-total-volume="mixture.total_volume_in_ml"></serbitec-electrolytes-alerts>

            <b-field style="margin-top:25px" position="is-centered" grouped>
                <p class="control has-text-centered">
                    <router-link
                        :to="{ path: '/nutritional_mixtures_authorizer/edit', query: { mixtureId: this.mixtureId } }"
                        class="button"
                    >
                        <span class="icon">
                            <font-awesome-icon icon="edit" />
                        </span>
                        <span> Editar mezcla</span>
                    </router-link>
                </p>
                <p class="control has-text-centered">
                    <button @click="authorizeMixture" class="button is-primary">
                        Autorizar mezcla
                    </button>
                </p>
            </b-field>
        </div>
    </div>
</template>

<script>
import serbitecHorizontalOrderData from '../../../components/dashboard/utils/horizontal-order-data.vue';
import serbitecNutritionalElementsTable from '../../../components/dashboard/utils/nutritional-elements-table.vue';
import serbitecElectrolytesAlerts from "../../../components/dashboard/requests/nutritional-mixtures/electrolytes-alerts.vue";
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';
import axios from 'axios';

import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    nutritional_mixture( id: $id ) {
        id
        delivery_date
        total_volume_in_ml
        overfill_in_ml
        comments
        order{
            id
            medic {
                id
                first_name
                last_name_1
                last_name_2
                email
                medical_card
                payer {
                    id
                    invoicing_data {
                        id
                        business_name
                    }
                }
            }
            patient {
                id
                name
                diagnosis
                height_in_meters
                weight_in_kilograms
                birth_date
                file_id
                location
                bed
            }
        }
        elements {
            id
            medicine_quantity
            multiply_factor_to_selected_unit
            selected_unit_code
            component {
                id
                name
                nutritional_field {
                    id
                    ml_to_g_factor
                    ca_in_meq
                    po4_in_meq
                }
                component_type {
                    id
                    name
                }
            }
        }
    }
    table_counts( count_type: "nutritional_mixtures", mixture_state_id: 1 ) {
        nutritional_mixtures
    }
}`;

function initialDataSate () {
    return {
        route: [
            {
                label: 'Revisión de mezclas nutricionales',
                to: '/nutritional_mixtures_authorizer',
                active: false
            },
            {
                label: 'Detalles de la mezcla',
                to: '/nutritional_mixtures_authorizer/authorize',
                active: true
            }
        ]
    };
}
export default {
    props: {
        mixtureId: {
            type: [Number, String],
            default: 0 | '0'
        }
    },
    components: {
        serbitecHorizontalOrderData,
        serbitecNutritionalElementsTable,
        serbitecElectrolytesAlerts,
        serbitecBreadcumbs
    },
    data: initialDataSate,
    computed: {
        water () {
            var water = this.mixture.elements.find( element => element.component.component_type.id == 6 );
            if ( water ) {
                return water.medicine_quantity;   
            } else {
                return false;
            }
        },
        electrolytes () {
            var electrolytes = [];
            this.mixture.elements.map( element => {
                if ( element.component.component_type.id == 10 ) {
                    this.$set( element, 'quantity_in_ml', element.medicine_quantity );
                    this.$set( element, 'nutritional_field', element.component.nutritional_field );
                    electrolytes.push(element);
                }
            } );
            return electrolytes;
        }
    },
    methods: {
        getRequestParams () {
            const requestParams = {
                new_state_id: 2
            };
            return requestParams;
        },
        authorizeMixture () {
            var postParams = this.getRequestParams();
            var url = '/api/nutritional/mixtures/' + this.mixtureId + '/state';
            this.makeRequest( url, axios.post, postParams );
        },
        makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method( url, params )
            .then(function () {
                this.$serbitec.finishLoading();
                this.$router.push({ path: '/nutritional_mixtures_authorizer', query: { success: true } });
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$serbitec.finishLoading();
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
        }
    },
    apollo: {
        mixture: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    id: this.mixtureId
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update ( data ) {
                this.$store.commit( 'patient/SET_PATIENT', data.nutritional_mixture.order.patient );
                this.$store.commit( 'nutritionalOrder/UPDATE_TOTAL_VOLUME', data.nutritional_mixture.total_volume_in_ml );
                return data.nutritional_mixture;
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.serbitec-small-top-margin {
    margin-top: 1.5rem
}
</style>