<template>
    <form @submit="submitGenerateQuote">
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    Generar cotización
                </p>
            </header>
            <section class="modal-card-body">
                <p>
                    Para generar la cotización seleccione las opciones y de click en el botón Generar
                </p>
                <b-field v-if="!chargeOnlyPreparationService" class="serbitec-small-top-margin">
                    <b-radio-button
                        v-model="detailed"
                        :native-value="true"
                        expanded>
                        <span>Desglosado</span>
                    </b-radio-button>

                    <b-radio-button
                        v-model="detailed"
                        :native-value="false"
                        expanded>
                        <span>Condensado</span>
                    </b-radio-button>
                </b-field>
                <b-field v-if="!detailed || chargeOnlyPreparationService" class="serbitec-small-top-margin">
                    <b-radio-button
                        v-model="includeTax"
                        :native-value="true"
                        expanded>
                        <span>Con I.V.A.</span>
                    </b-radio-button>

                    <b-radio-button
                        v-model="includeTax"
                        :native-value="false"
                        expanded>
                        <span>Sin I.V.A.</span>
                    </b-radio-button>
                </b-field>

                <div class="field serbitec-small-top-margin">
                    <b-checkbox
                        v-model="chargeOnlyPreparationService"
                    >
                        Cotizar sólamente el <strong>servicio de preparación</strong>
                    </b-checkbox>
                </div>
            </section>
            <footer class="modal-card-foot">
                <button class="button" type="button" @click="$parent.close()">Cancelar</button>
                <button class="button is-primary">Generar</button>
            </footer>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        quoteId: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            detailed: true,
            includeTax: true,
            chargeOnlyPreparationService: false
        };
    },
    methods: {
        submitGenerateQuote(){
            if (this.chargeOnlyPreparationService) {
                this.detailed = false;
            }
            if (this.type === 'oncologic') {
                window.open('/pdf/oncologic/orders/' + this.quoteId +
                '/price_quote?detailed=' + this.detailed + 
                '&include_tax=' + this.includeTax +
                '&charge_only_preparation_service=' + this.chargeOnlyPreparationService,'_blank');
            } else {
                window.open('/pdf/nutritional/mixtures/' + this.quoteId +
                '/price_quote?detailed=' + this.detailed + 
                '&include_tax=' + this.includeTax +
                '&charge_only_preparation_service=' + this.chargeOnlyPreparationService,'_blank');
            }
            this.$emit('close');
        }
    }
};
</script>