<template>
    <div>
        <nav class="level is-mobile">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title">Pacientes</h1>
                </div>
            </div>

            <div class="level-right" />
        </nav>
        <hr>
        <serbitec-patients-table
            :patients="patients"
            :patients-count="patientsCount"

            :loading.sync="loading"
            :page.sync="page"
            :pageSize="pageSize"
            :sortField.sync="sortField"
            :sortOrder.sync="sortOrder"

            @page-change="onPageChange"
            @sort="onSort"
            @reload-patients="() => $apollo.queries.patients.refresh()"
        />
    </div>
</template>

<script>
import serbitecPatientsTable from '../../../../components/dashboard/external-user/patients/patients-table.vue';

import gql from 'graphql-tag';
import { mapState } from 'vuex';
const query = gql` query ( $medic_id: Int, $page: Int, $limit: Int, $order_by: String, $order_direction: String )
{
    patients: paginated_patients( medic_id: $medic_id, page: $page, limit: $limit, order_by: $order_by, order_direction: $order_direction ) {
        total
        data {
            id
            name
            diagnosis
            height_in_meters
            weight_in_kilograms
            file_id
            location
            bed
            birth_date
            gender
            age_type
            body_surface_in_square_meters
            medic {
                id
            }
        }
    }
}`;

function initialDataState () {
    return {
        loading: false,
        patientsCount: null,
        page: 1,
        pageSize: 10,
        sortField: 'id',
        sortOrder:'asc'
    };
}

export default {
    name: 'index',
    components: {
        serbitecPatientsTable
    },
    data: initialDataState,
    computed: {
        ...mapState({
            user: state => state.user
        }),
    },
    methods: {
        onPageChange( page ) {
            this.page = page;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        }
    },
    apollo: {
        patients: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    medic_id: this.user.id,
                    page: this.page,
                    limit: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                this.patientsCount = data.patients.total;
                return data.patients.data;
            }
        },
    }
};
</script>