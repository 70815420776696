var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card", staticStyle: { width: "auto" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          !_vm.showComponentData
            ? _c(
                "b-field",
                {
                  attrs: {
                    label: "Componente",
                    "label-position": "on-border",
                    type: { "is-danger": _vm.$v.selectedComponent.$error },
                    message: {
                      "Se requiere introducir el componente":
                        !_vm.$v.selectedComponent.required &&
                        _vm.$v.selectedComponent.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("serbitec-components-autocomplete", {
                    attrs: { "without-component-type-ids": "1,9,13" },
                    on: { "component-added": _vm.updateComponent }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showComponentData
            ? _c(
                "b-field",
                {
                  attrs: { label: "Componente", "label-position": "on-border" }
                },
                [
                  _c("b-field", [
                    _c("p", { staticClass: "control" }, [
                      _c(
                        "span",
                        {
                          staticClass: "button",
                          on: { click: _vm.unselectComponent }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-small" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "times-circle" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "control" }, [
                      _c("span", { staticClass: "button is-static" }, [
                        _vm._v(
                          "\n                        Genérico: " +
                            _vm._s(_vm.selectedComponent.name) +
                            "\n                    "
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                label: "Nombre comercial del medicamento",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.medicineCommercialName.$error },
                message: {
                  "Se requiere introducir el nombre comercial del medicamento":
                    !_vm.$v.medicineCommercialName.required &&
                    _vm.$v.medicineCommercialName.$error
                },
                expanded: ""
              }
            },
            [
              _c("b-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.$v.medicineCommercialName.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$v.medicineCommercialName,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.medicineCommercialName.$model"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                label: "Fabricante",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.selectedManufacturer.$error },
                message: {
                  "Se requiere introducir y seleccionar el fabricante":
                    !_vm.$v.selectedManufacturer.required &&
                    _vm.$v.selectedManufacturer.$error
                },
                expanded: ""
              }
            },
            [
              _c("serbitec-manufacturer-select", {
                attrs: { selectedManufacturer: _vm.selectedManufacturer },
                on: { "manufacturer-selected": _vm.updateSelectedManufacturer }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                label: "Unidad del componente activo",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.selectedPresentationUnit.$error },
                message: {
                  "Se requiere seleccionar la unidad del componente activo":
                    !_vm.$v.selectedPresentationUnit.required &&
                    _vm.$v.selectedPresentationUnit.$error
                },
                expanded: ""
              }
            },
            [
              _c("serbitec-unit-select", {
                attrs: { selectedUnit: _vm.selectedPresentationUnit },
                on: { "unit-selected": _vm.updateSelectedUnit }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: { label: "Comentarios", "label-position": "on-border" }
            },
            [
              _c("b-input", {
                attrs: { maxlength: "200", type: "textarea" },
                model: {
                  value: _vm.medicineComments,
                  callback: function($$v) {
                    _vm.medicineComments = $$v
                  },
                  expression: "medicineComments"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$parent.close()
              }
            }
          },
          [_vm._v("\n            Cancelar\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button is-primary", on: { click: _vm.submitForm } },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.comesFromEdit ? "Editar" : "Agregar") +
                "\n        "
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v("\n            Agregar medicamento\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }