<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Agregar lote
            </p>
        </header>
        <section class="modal-card-body">
            <div v-if="Object.keys(medicine).length === 0 && medicine.constructor === Object">
                <h1 class="subtitle is-6">1. Seleccione el medicamento</h1>
                <b-field
                    label="Nombre del medicamento"
                    label-position="on-border"
                    :type="{'is-danger' : $v.medicine.$error }"
                    :message="{
                        'Se requiere seleccionar un medicamento' : !$v.medicine.required && $v.medicine.$error
                    }"
                >
                    <serbitec-medicine-autocomplete
                        @medicine-selected="medicine = $event"
                    />
                </b-field>
            </div>
            <div v-else>
                <h1 class="subtitle is-6">2. Llene los campos para agregar el lote al almacen</h1>
                <b-field grouped>
                    <b-field>
                        <span class="button" @click="unselectMedicine()">
                            <span class="icon is-small">
                                <font-awesome-icon icon="exchange-alt" />
                            </span>
                        </span>
                    </b-field>
                    <b-field expanded>
                        <b-input
                        :value="medicine.commercial_name + ' (Genérico: ' + medicine.component.name + ')'"
                        disabled>
                        </b-input>
                    </b-field>
                </b-field>
                <b-field
                    label="Presentación"
                    label-position="on-border"
                    :type="{'is-danger' : $v.medicinePresentation.$error }"
                    :message="{
                        'Se requiere seleccionar la presentación' : !$v.medicinePresentation.required && $v.medicinePresentation.$error
                    }"
                >
                    <b-select 
                        v-model.trim="$v.medicinePresentation.$model"
                        placeholder="Seleccione la presentación"
                        expanded
                    >
                        <option
                            v-for="presentation in medicine.presentations"
                            :value="presentation"
                            :key="presentation.id"
                        >
                            <p v-if="medicine.component.component_type.id == 12" >{{ presentation.component_quantity }} {{ medicine.component_unit.symbol }} de {{ presentation.volume_in_ml }} ml</p>
                            <p v-else-if="medicine.component.component_type.id == 8" >{{ presentation.component_quantity }} {{ medicine.component_unit.symbol }}</p>
                            <p v-else>{{ presentation.component_quantity }} {{ medicine.component_unit.symbol }} en {{ presentation.volume_in_ml }} ml</p>
                        </option>
                    </b-select>
                </b-field>
                <b-field grouped group-multiline>
                    <b-field
                        label="Prefijo"
                        label-position="on-border"
                    >
                        <serbitec-client-prefixes-select
                            :selected-client-prefix.sync="selectedClientPrefix"
                        />
                    </b-field>
                    <b-field
                        label="Lote"
                        label-position="on-border"
                        :type="{'is-danger' : $v.batch.$error }"
                        :message="{
                            'Se requiere introducir el número de lote' : !$v.batch.required && $v.batch.$error
                        }"
                        expanded
                    >
                        <b-input
                        v-model.trim="$v.batch.$model"
                        type="text">
                        </b-input>
                    </b-field>
                    <b-field
                        :label="'Fecha de caducidad'"
                        label-position="on-border"
                        :type="{'is-danger' : $v.expiryDate.$error }"
                        :message="{
                            'Se requiere introducir la fecha de caducidad' : !$v.expiryDate.required && $v.expiryDate.$error
                        }"
                        expanded
                    >
                        <b-datepicker
                            v-model.trim="$v.expiryDate.$model"
                            :min-date="minDate"
                            :max-date="maxDate"
                            :years-range="[-10,10]"
                            :date-formatter="d => d.toLocaleDateString('es-US',{ year: 'numeric', month: 'long', day: 'numeric' })">
                        </b-datepicker>
                    </b-field>
                </b-field>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                Cancelar
            </button>
            <button class="button is-primary" @click="submitForm">
                Agregar
            </button>
        </footer>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import serbitecMedicineAutocomplete from '../utils/autocompletes/medicines.vue';
import serbitecClientPrefixesSelect from '../utils/select/client-prefixes.vue';

function initialDataState() {
    return {
        medicine: {},
        medicinePresentation: {},
        batch: '',
        expiryDate: null,
        selectedClientPrefix: {},
        date: new Date(),
        currentDate: new Date(),
    };
}

export default {
    components: {
        serbitecMedicineAutocomplete,
        serbitecClientPrefixesSelect
    },
    data: initialDataState,
    methods: {
        unselectMedicine () {
            Object.assign(this.$data, initialDataState());
            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.addBatch();
            }
        },
        getRequestParams () {
            return {
                medicine_presentation_id: this.medicinePresentation.id,
                prefix_id: this.selectedClientPrefix.id,
                batch: this.batch,
                expiry_date: this.expiryDate
            };
        },
        addBatch () {
            var postParams = this.getRequestParams();
            var url = '/api/external_medicine_warehouse';
            this.makeRequest(url, this.$axios.post, postParams);
        },
        makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method(url,params)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha añadido el lote'
                });
                this.$serbitec.finishLoading();
                this.$emit('success');
                this.$emit('close');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    },
    computed: {
        minDate () {
        return new Date(
            this.currentDate.getFullYear() - 10,
            this.currentDate.getMonth(),
            this.currentDate.getDate()
        );
        },
        maxDate () {
        return new Date(
            this.currentDate.getFullYear() + 10,
            this.currentDate.getMonth(),
            this.currentDate.getDate(),
        );
        },
    },
    validations: {
        medicine: {
            required
        },
        medicinePresentation: {
            required
        },
        batch: {
            required
        },
        expiryDate: {
            required
        }
    }
};
</script>