var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "b-table",
        {
          attrs: { data: _vm.presentations, "mobile-cards": true },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    { attrs: { field: "ean_code", label: "Código EAN" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.ean_code) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      attrs: { field: "component_quantity", label: "Cantidad" }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            props.row.component_quantity +
                              " " +
                              props.row.medicine.component_unit.symbol
                          ) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { field: "volume_in_ml", label: "Volumen" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.volume_in_ml) +
                          " ml\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Eliminar", centered: "" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "button is-small is-danger",
                          on: {
                            click: function($event) {
                              return _vm.deletePresentation(props.row.id)
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "trash-alt" }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("No hay diluyentes asociados a este medicamento")
                  ])
                ]
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }