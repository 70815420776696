<template>
    <div>
        <div class="columns">
            <div class="column is-half-desktop">
                <b-field label="Filtrar por..." label-position="on-border">
                    <b-select v-model="selectedFilter" placeholder="Seleccione una opción">
                        <option
                            v-for="option in data"
                            :value="option.id"
                            :key="option.id">
                            {{ option.label }}
                        </option>
                    </b-select>
                    <serbitec-payer-autocomplete
                        v-if="selectedFilter == 0"
                        @payer-added="payerAdded" />
                    <b-datepicker
                        v-if="selectedFilter == 1"
                        @input="addDateRange"
                        placeholder="Seleccione el rango de fechas..."
                        :date-formatter="( dates ) => formatDateRange( dates )"
                        v-model="dates"
                        range
                        expanded
                    />
                    <serbitec-component-type-check
                        v-if="selectedFilter == 2"
                        :comes-from-page="comesFromPage"
                    />
                    <serbitec-payer-medicine-enabled-radio
                        v-if="selectedFilter == 3"
                        :comes-from-page="comesFromPage"
                    />
                </b-field>
            </div>
        </div>
        <serbitec-filter-tags
            v-if="hasActiveFilters"
            :comes-from-page="comesFromPage"
            @removeSelectedDates="() => this.dates = []"
        />
    </div>
</template>

<script>
import serbitecPayerAutocomplete from './autocompletes/payers.vue';
import serbitecComponentTypeCheck from './component-type-check.vue';
import serbitecPayerMedicineEnabledRadio from './payer-medicine-enabled-radio.vue';
import serbitecFilterTags from './filter-tags.vue';

import { mapState } from 'vuex';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';

export default {
    props: {
        activeFilters: {
            type: Array,
            default: () => []
        },
        comesFromPage: {
            type: String,
            default: ''
        }
    },
    components: {
        serbitecPayerAutocomplete,
        serbitecComponentTypeCheck,
        serbitecPayerMedicineEnabledRadio,
        serbitecFilterTags
    },
    beforeMount(){
        this.activeFilters.map( filter => {
            if ( filter == 'payer' ) {
                let payer = {
                    id: 0,
                    name: 'payer',
                    label: 'Cliente'
                };
                this.data.push(payer);
            }
            if ( filter == 'deliveryDate' ) {
                let deliveryDate = {
                    id: 1,
                    name: 'deliveryDate',
                    label: 'Fecha de entrega'
                };
                this.data.push(deliveryDate);
            }
            if ( filter == 'componentType' ) {
                let componentType = {
                    id: 2,
                    name: 'componentType',
                    label: 'Tipo de componente'
                };
                this.data.push(componentType);
            }
            if ( filter == 'enabledPayerMedicines' ) {
                let enabledPayerMedicines = {
                    id: 3,
                    name: 'enabledPayerMedicines',
                    label: 'Medicamentos activos'
                };
                this.data.push(enabledPayerMedicines);
            }
        } );
    },
    computed: {
        ...mapState({
            payersFilters (state) {
                if (this.activeFilters.find(filter => filter === 'payer') === undefined) {
                    return [];
                } else {
                    return state.recordFilters[this.comesFromPage].payers;
                }
            },
            fromDateFilter (state) {
                if (this.activeFilters.find(filter => filter === 'deliveryDate') === undefined) {
                    return null;
                } else {
                    return state.recordFilters[this.comesFromPage].fromDate;
                }
            },
            untilDateFilter (state) {
                if (this.activeFilters.find(filter => filter === 'deliveryDate') === undefined) {
                    return null;
                } else {
                    return state.recordFilters[this.comesFromPage].untilDate;
                }
            },
            componentTypes (state) {
                if (this.activeFilters.find(filter => filter === 'componentType') === undefined) {
                    return [];
                } else {
                    return state.recordFilters[this.comesFromPage].componentTypes;
                }
            },
            enabledPayerMedicines (state) {
                if (this.activeFilters.find(filter => filter === 'enabledPayerMedicines') === undefined) {
                    return null;
                } else {
                    return state.recordFilters[this.comesFromPage].enabledPayerMedicines;
                }
            }
        }),
        hasActiveFilters(){
            if ( this.payersFilters.length || this.fromDateFilter || this.untilDateFilter || this.componentTypes.length || this.enabledPayerMedicines != null ){
                return true;
            }
            return false;
        }
    },
    data () {
        return {
            data: [],
            selectedFilter: null,
            dates: []
        };
    },
    methods: {
        payerAdded( payer ) {
            payer.comesFromPage = this.comesFromPage;
            this.$store.commit( 'recordFilters/SET_PAYER_FILTER', payer );
        },
        addDateRange(){
            let data = {
                dates: this.dates,
                comesFromPage: this.comesFromPage
            };
            this.$store.commit( 'recordFilters/SET_DATE_RANGE', data );
        },
        formatDate( date ) {
            return format( date, "d 'de' MMMM yyyy", { locale: es } );
        },
        formatDateRange( dates ){
            if ( dates.length ){
                let formattedDates = dates.map( date => {
                    return date =  this.formatDate( date );
                } );
                return formattedDates.toString();
            }
        },
    }
};
</script>