var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("b-loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function($event) {
            _vm.loading = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.isEmpty ? [] : _vm.requestedMedicines,
            loading: _vm.loadingTable
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    { attrs: { label: "Lote", numeric: "", centered: "" } },
                    [
                      _c("p", [
                        props.row.prefix
                          ? _c("strong", [
                              _vm._v(_vm._s(props.row.prefix.prefix) + "-")
                            ])
                          : _vm._e(),
                        _vm._v(" " + _vm._s(props.row.batch))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Nombre comercial" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.medicine_commercial_name) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Fabricante" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.manufacturer_name) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Presentación", centered: "" } },
                    [
                      props.row.isEvaBag
                        ? _c("b-tag", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  props.row.medicine_presentation_volume_in_ml
                                ) +
                                " ml\n                "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      props.row.isPiece
                        ? _c("b-tag", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(props.row.component_quantity) +
                                " " +
                                _vm._s(props.row.component_unit_symbol) +
                                "\n                "
                            )
                          ])
                        : props.row.isOncologicMedicine
                        ? _c("b-tag", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(props.row.component_quantity) +
                                " " +
                                _vm._s(props.row.component_unit_symbol) +
                                " en " +
                                _vm._s(props.row.medicine_presentation) +
                                " ml\n                "
                            )
                          ])
                        : _c("b-tag", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(props.row.medicine_presentation) +
                                " ml\n                "
                            )
                          ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Fecha de caducidad", centered: "" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.expiry_date) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Frascos solicitados", centered: "" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.requested_flasks) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { centered: "" } }, [
                    _c(
                      "a",
                      {
                        staticClass: "button is-small is-danger",
                        on: {
                          click: function($event) {
                            return _vm.$store.commit(
                              "internalWarehouseRequest/REMOVE_MEDICINE_FROM_REQUEST",
                              props.row.id
                            )
                          }
                        }
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "trash-alt" }
                        })
                      ],
                      1
                    )
                  ])
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "No hay medicinas añadidas a la solicitud para el almacén externo, seleccione el medicamento, el lote y la cantidad de frascos para realizar la solicitud."
                    )
                  ])
                ]
              )
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "b-message",
        {
          staticStyle: { "margin-top": "15px" },
          attrs: {
            size: "is-small",
            title: _vm.notification.title,
            active: _vm.notification.isActive,
            type: _vm.notification.type,
            "aria-close-label": "Close message"
          },
          on: {
            "update:active": function($event) {
              return _vm.$set(_vm.notification, "isActive", $event)
            }
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.notification.message) + "\n    ")]
      ),
      _vm._v(" "),
      !_vm.requestIsEmpty
        ? _c("b-field", { staticStyle: { "margin-top": "15px" } }, [
            _c("p", { staticClass: "control has-text-centered" }, [
              _c(
                "button",
                {
                  staticClass: "button is-primary",
                  on: { click: _vm.submitRequestToExternalWarehouse }
                },
                [_vm._v("\n                Realizar pedido\n            ")]
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }