<template>
    <div class="box">
        <b-table
            :data="mixtures"

            paginated
            backend-pagination
            :total="mixturesCount"
            :per-page="pageSize"
            @page-change="changePage"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="sort">

            <template slot-scope="props">
                <b-table-column field="id" label="Orden" numeric sortable>
                    <p v-if="isNutritional">ON-{{ props.row.order.id }}</p>
                    <p v-else-if="isOncologic">OO-{{ props.row.id }}</p>
                </b-table-column>

                <b-table-column v-if="isNutritional" label="Lote" numeric>
                    <p>LN-{{ props.row.id }}</p>
                </b-table-column>

                <b-table-column label="Paciente">
                    <p v-if="isNutritional">{{ props.row.order.patient.name }}</p>
                    <p v-else-if="isOncologic">{{ props.row.patient.name }}</p>
                </b-table-column>

                <b-table-column label="Cliente">
                    <p v-if="isNutritional">{{ props.row.order.medic.payer.invoicing_data.business_name }}</p>
                    <p v-else-if="isOncologic">{{ props.row.medic.payer.invoicing_data.business_name }}</p>
                </b-table-column>

                <b-table-column field="delivery_date" label="Fecha y hora de entrega" sortable>
                    <p v-if="isNutritional">{{ formatDate( props.row.delivery_date ) }}</p>
                    <p v-else-if="isOncologic">{{ formatDate( props.row.delivery_date ) }}</p>
                </b-table-column>

                <b-table-column centered>
                    <router-link
                        :to="isNutritional ? { path: 'authorize', query: { mixtureId:  props.row.id } } : { path: 'authorize', query: { orderId:  props.row.id } }"
                        class="button is-light is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="clipboard-check" />
                        </span>
                        <span> Revisar</span>
                    </router-link>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay mezclas pendientes.</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { es } from 'date-fns/locale';

export default {
    props: {
        preparerType: {
            type: String,
            default: ''
        },
        mixtures: {
            type: Array,
            default: () => []
        },
        mixturesCount: {
            type: Number,
            default: 0
        },
        page: {
            type: [Number, String],
            default: 0 | ''
        },
        pageSize: {
            type: [Number, String],
            default: 0 | ''
        },
        sortField: {
            type: String,
            default: ''
        },
        sortOrder: {
            type: String,
            default: ''
        }
    },
    computed: {
        isOncologic () {
            if ( this.preparerType == 'oncologic' ) {
                return true;
            } else {
                return false;
            }
        },
        isNutritional () {
            if ( this.preparerType == 'nutritional' ) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        formatDate( date ) {
            return format( parseISO(date), "d 'de' MMMM yyyy '|' HH:mm", { locale: es } );
        },
        changePage ( page ) {
            this.$emit('page-change', page);
        },
        sort ( field, order ) {
            this.$emit('sort', field, order);
        }
    }
};
</script>