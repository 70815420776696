<template>
    <b-table
    :data="flasks">
        <template slot-scope="props">
            <b-table-column label="Nombre">
                <p>{{ props.row.medicine_presentation.medicine.commercial_name }}</p>
            </b-table-column>

            <b-table-column label="Componente">
                <p>{{ props.row.medicine_presentation.medicine.component.name }}</p>
            </b-table-column>

            <b-table-column label="Presentación">
                <b-tag>{{ props.row.medicine_presentation.component_quantity }} {{ props.row.medicine_presentation.medicine.component_unit.symbol }} en {{ props.row.medicine_presentation.volume_in_ml }} ml</b-tag>
            </b-table-column>

            <b-table-column label="Cantidad" centered>
                <p>{{ props.row.number_of_flasks }}</p>
            </b-table-column>

            <b-table-column v-if="comesFromRecords" label="Estado" centered>
                <b-tag>{{ props.row.state.name }}</b-tag>
            </b-table-column> 
        </template>
    </b-table>
</template>

<script>
export default {
    props:['flasks', 'comesFromRecords']
};
</script>