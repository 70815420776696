var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card", staticStyle: { width: "auto" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            { attrs: { grouped: "", "group-multiline": "" } },
            [
              !_vm.isExternalWarehouse
                ? _c(
                    "b-field",
                    {
                      attrs: {
                        label: "Orden de compra",
                        "label-position": "on-border",
                        type: { "is-danger": _vm.$v.purchaseOrder.$error },
                        message: {
                          "Se requiere introducir el número de orden de compra":
                            !_vm.$v.purchaseOrder.required &&
                            _vm.$v.purchaseOrder.$error
                        },
                        expanded: ""
                      }
                    },
                    [
                      _c("b-input", {
                        attrs: { type: "text", expanded: "" },
                        model: {
                          value: _vm.$v.purchaseOrder.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.purchaseOrder, "$model", $$v)
                          },
                          expression: "$v.purchaseOrder.$model"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Número de frascos o piezas",
                    "label-position": "on-border",
                    type: { "is-danger": _vm.$v.flasksInOrder.$error },
                    message: {
                      "Se requiere introducir el número de frascos o piezas":
                        !_vm.$v.flasksInOrder.required &&
                        _vm.$v.flasksInOrder.$error,
                      "Debe ser un entero positivo":
                        !_vm.$v.flasksInOrder.integer &&
                        _vm.$v.flasksInOrder.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    directives: [
                      {
                        name: "cleave",
                        rawName: "v-cleave",
                        value: _vm.masks.integer,
                        expression: "masks.integer"
                      }
                    ],
                    attrs: { type: "text", expanded: "" },
                    model: {
                      value: _vm.$v.flasksInOrder.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.flasksInOrder, "$model", $$v)
                      },
                      expression: "$v.flasksInOrder.$model"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          !_vm.isExternalWarehouse
            ? _c(
                "b-field",
                {
                  attrs: {
                    label: "Costo por frasco o pieza",
                    "label-position": "on-border",
                    type: { "is-danger": _vm.$v.costInCentsPerItem.$error },
                    message: {
                      "Se requiere introducir el costo por frasco (Mayor a 0.00)":
                        !_vm.$v.costInCentsPerItem.required &&
                        _vm.$v.costInCentsPerItem.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c(
                    "b-field",
                    {
                      attrs: {
                        type: { "is-danger": _vm.$v.costInCentsPerItem.$error }
                      }
                    },
                    [
                      _c("p", { staticClass: "control" }, [
                        _c("button", { staticClass: "button is-static" }, [
                          _vm._v("$")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("b-input", {
                        directives: [
                          {
                            name: "cleave",
                            rawName: "v-cleave",
                            value: _vm.masks.numeral,
                            expression: "masks.numeral"
                          }
                        ],
                        attrs: {
                          value: _vm.$v.costInCentsPerItem.$model,
                          type: "text",
                          expanded: ""
                        },
                        nativeOn: {
                          input: function($event) {
                            return _vm.onInputCostInCentsPerItem($event)
                          }
                        }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "control" }, [
                        _c("button", { staticClass: "button is-static" }, [
                          _vm._v("MXN")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-message",
            { staticClass: "is-danger serbitec-small-top-margin" },
            [
              _vm._v(
                "\n            Revise cuidadosamente la información ya que no hay edición en alta de ordenes de " +
                  _vm._s(_vm.isExternalWarehouse ? "ingreso" : "compra") +
                  ".\n        "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$parent.close()
              }
            }
          },
          [_vm._v("\n            Cancelar\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button is-primary", on: { click: _vm.submitForm } },
          [_vm._v("\n            Agregar\n        ")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v("\n            Agregar orden de compra\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }