<template>
    <div>
        <b-loading :active.sync="loading"></b-loading>
        <form
        @submit.prevent="submitForm"
        novalidate="true">
            <div class="box">
                <nav class="level">
                    <div class="level-left">
                        <div class="level-item">
                            <h1 class="subtitle">Agregar bolo al pedido</h1>
                        </div>
                    </div>
                    <div class="level-right">
                        <a class="button" @click="closeForm()">
                            <span class="icon">
                                <font-awesome-icon icon="times-circle" />
                            </span>
                            <span> Cerrar forma</span>
                        </a>
                    </div>
                </nav>

                <div v-if="!selectedOncologicMedicine">
                    <h1 class="subtitle is-6">1. Seleccione el medicamento oncológico</h1>
                    <serbitec-medicine-autocomplete
                    :payer-id="payer.id"
                    @medicine-selected="storeMedicine"
                    :withComponentTypeIds="'1'"></serbitec-medicine-autocomplete>
                    <br>
                </div>

                <div v-if="selectedOncologicMedicine">
                    <h1 class="subtitle is-6">2. Llene los campos para agregar el bolo a la orden</h1>
                    <b-field
                    :label="'Cantidad de activo (' + selectedOncologicMedicine.commercial_name + ')'"
                    :type="{'is-danger' : $v.medicineQuantity.$error }"
                    :message="{
                        'Se requiere introducir la cantidad de activo' : !$v.medicineQuantity.required && $v.medicineQuantity.$error,
                        'Se requiere introducir un número decimal' : !$v.medicineQuantity.decimal && $v.medicineQuantity.$error
                    }"
                    expanded>
                        <b-field
                        :type="{'is-danger' : $v.medicineQuantity.$error }">
                            <p class="control">
                                <span class="button" @click="unselectMedicine()">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="exchange-alt" />
                                    </span>
                                </span>
                            </p>
                            <p class="control">
                                <span class="button is-static">
                                    Genérico: {{ selectedOncologicMedicine.component.name }}
                                </span>
                            </p>
                            <b-input
                                v-model.trim="$v.medicineQuantity.$model"
                                v-cleave="masks.custom"
                                type="text"
                                expanded
                            >
                            </b-input>
                            <p class="control">
                                <span class="button is-static">{{ selectedOncologicMedicine.component_unit.symbol }}</span>
                            </p>
                        </b-field>
                    </b-field>
                    <b-field
                    v-if="!oncologicMedicineThinner"
                    label="Diluyente"
                    :type="{'is-danger' : $v.oncologicMedicineThinner.$error }"
                    :message="[
                        !$v.oncologicMedicineThinner.required && $v.oncologicMedicineThinner.$error ? 'Se requiere introducir y seleccionar el diluyente' : 'Seleccione un diluyente.' 
                    ]"
                    expanded>
                        <b-select 
                            v-model="oncologicMedicineThinner"
                            placeholder="Seleccione el diluyente" expanded>
                            <option
                            v-for="( thinner, index ) in selectedOncologicMedicine.oncologic_thinners"
                            :value="thinner"
                            :key="index">
                                {{ thinner.name }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field
                    v-if="oncologicMedicineThinner && oncologicMedicineThinner.id == 1"
                    :label="'Trasvase de medicamento'"
                    expanded>
                        <b-field>
                            <p class="control">
                                <span class="button" @click="unselectThinner">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="exchange-alt" />
                                    </span>
                                </span>
                            </p>
                            <p class="control" expanded>
                                <span class="button is-static">
                                    {{ oncologicMedicineThinner.name }}
                                </span>
                            </p>
                        </b-field>
                    </b-field>
                    <b-field
                    v-else-if="oncologicMedicineThinner"
                    :label="'Trasvase de medicamento'"
                    :type="{'is-danger' : $v.oncologicMedicineThinnerQuantity.$error }"
                    :message="{
                        'Se requiere introducir la cantidad de diluyente' : !$v.oncologicMedicineThinnerQuantity.required && $v.oncologicMedicineThinnerQuantity.$error,
                        'Se requiere introducir un número decimal' : !$v.oncologicMedicineThinnerQuantity.decimal && $v.oncologicMedicineThinnerQuantity.$error
                    }"
                    expanded>
                        <b-field
                        :type="{'is-danger' : $v.oncologicMedicineThinnerQuantity.$error }">
                            <p class="control">
                                <span class="button" @click="unselectThinner">
                                    <span class="icon is-small">
                                        <font-awesome-icon icon="exchange-alt" />
                                    </span>
                                </span>
                            </p>
                            <p class="control">
                                <span class="button is-static">
                                    {{ oncologicMedicineThinner.name }}
                                </span>
                            </p>
                            <b-input
                                v-model.trim="$v.oncologicMedicineThinnerQuantity.$model"
                                v-cleave="masks.custom"
                                type="text"
                                expanded
                            >
                            </b-input>
                            <p class="control">
                                <span class="button is-static">ml</span>
                            </p>
                        </b-field>
                    </b-field>
                    <b-field
                    :label="'Vía de Administración'"
                    expanded>
                        <b-select 
                            v-model="routeAdministration"
                            placeholder="Seleccione Vía de Administración">
                            <option value="INTRAVENOSA">INTRAVENOSA</option>
                            <option value="INTRATECAL">INTRATECAL</option>
                            <option value="INTRAMUSCULAR">INTRAMUSCULAR</option>
                            <option value="LA QUE EL MÉDICO INDIQUE">LA QUE EL MÉDICO INDIQUE</option>
                        </b-select>
                    </b-field>
                    <div v-if="mixtureConcentration">
                        <br>
                        <b-message>
                            <p>{{ mixtureConcentration.message }}</p>
                        </b-message>
                        <b-message v-if="mixtureAlert" class="is-warning">
                            <p>{{ mixtureAlert }}</p>
                        </b-message>
                    </div>
                    <hr>
                    <b-field
                    :label="'Comentarios'"
                    :message="'Introduzca comentarios referentes a la mezcla'">
                        <b-input 
                            v-model="comments"
                            type="textarea"
                            maxlength="500"
                            placeholder="Introduzca sus comentarios...">
                        </b-input>
                    </b-field>
                    <b-field v-if="withInfusionSet">
                        <b-checkbox
                            v-model="infusionSet">
                                Set de infusión
                        </b-checkbox>
                    </b-field>
                    <b-field>
                        <p class="control has-text-centered">
                            <button type="submit" class="button is-primary">
                                Agregar bolo
                            </button>
                        </p>
                    </b-field>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators';
import serbitecMedicineAutocomplete from '../../utils/autocompletes/medicines.vue';
import { mapState } from 'vuex';
import Cleave from 'cleave.js';

function initialDataState() {

    return {
        medicineQuantity: null,
        oncologicMedicineThinner: null,
        oncologicMedicineThinnerQuantity: null,
        routeAdministration: null,
        comments: null,
        infusionSet: false,
        loading: false,
        masks: {
            custom: {
                numeral: true,
                numeralThousandsGroupStyle: 'none',
                numeralPositiveOnly: true
            }
        }
    };
}

const cleave = {
    name: 'cleave',
    bind(el, binding) {
        const input = el.querySelector('input');
        input._vCleave = new Cleave(input, binding.value);
    },
    unbind(el) {
        const input = el.querySelector('input');
        input._vCleave.destroy();
    }
};

function setID() {
    return Math.random().toString(36).substr(2, 4);
}

export default {
    props:['comesFromEdit', 'withInfusionSet'],
    directives: { cleave },
    computed: {
        ...mapState({
            selectedOncologicMedicine: state => state.oncologicMedicine.oncologicMedicineData,
            payer: state => state.payer.payerData
        }),
        mixtureConcentration: function () {
            if ( this.medicineQuantity == 0 || this.medicineQuantity < 0 ) {
                return {
                    isComputed: false,
                    message: 'La cantidad de medicamento no puede ser 0 ni un número negativo'
                };
            }
            if ( this.oncologicMedicineThinnerQuantity == 0 ||  this.oncologicMedicineThinnerQuantity < 0 ) {
                return {
                    isComputed: false,
                    message: 'La cantidad de diluyente no puede ser 0 ni un número negativo'
                };
            }
            if ( this.medicineQuantity && this.oncologicMedicineThinnerQuantity ) {
                const concentration = ( parseFloat( this.medicineQuantity / this.oncologicMedicineThinnerQuantity ).toFixed(2) );
                return {
                    isComputed: true,
                    concentration: concentration,
                    message: 'La concentración de la mezcla es: ' + concentration + ' ' + this.selectedOncologicMedicine.component_unit.symbol + '/ml'
                };
            } else {
                return false;
            }
        },
        mixtureAlert: function () {
            if ( this.oncologicMedicineThinner.pivot.min_recommended_concentration && this.oncologicMedicineThinner.pivot.max_recommended_concentration && this.mixtureConcentration.isComputed ) {
                if ( ( this.oncologicMedicineThinner.pivot.min_recommended_concentration == this.oncologicMedicineThinner.pivot.max_recommended_concentration ) && ( this.mixtureConcentration.concentration != this.oncologicMedicineThinner.pivot.min_recommended_concentration ) ) {
                    return 'La concentración no es la recomendada por ' + this.oncologicMedicineThinner.pivot.reference + '. La concentración recomendada es de ' + this.oncologicMedicineThinner.pivot.min_recommended_concentration + ' ' + this.selectedOncologicMedicine.component_unit.symbol + '/ml';
                }
                else if ( ( this.mixtureConcentration.concentration < this.oncologicMedicineThinner.pivot.min_recommended_concentration ) || ( this.mixtureConcentration.concentration > this.oncologicMedicineThinner.pivot.max_recommended_concentration ) ) {
                    return 'La concentración se sale del rango recomendado por ' + this.oncologicMedicineThinner.pivot.reference + '. La concentración recomendada es entre ' + this.oncologicMedicineThinner.pivot.min_recommended_concentration + ' ' + this.selectedOncologicMedicine.component_unit.symbol + '/ml y ' + this.oncologicMedicineThinner.pivot.max_recommended_concentration + ' ' + this.selectedOncologicMedicine.component_unit.symbol +'/ml';
                }
            }
            else if ( !this.oncologicMedicineThinner.pivot.min_recommended_concentration && this.oncologicMedicineThinner.pivot.max_recommended_concentration && this.mixtureConcentration.isComputed && ( this.mixtureConcentration.concentration > this.oncologicMedicineThinner.pivot.max_recommended_concentration ) ) {
                return 'La concentración es mayor a la máxima recomendada por ' + this.oncologicMedicineThinner.pivot.reference + '. La concentración máxima recomendada es de ' + this.oncologicMedicineThinner.pivot.max_recommended_concentration + ' ' + this.selectedOncologicMedicine.component_unit.symbol +'/ml';
            }
            else if ( !this.oncologicMedicineThinner.pivot.max_recommended_concentration && this.oncologicMedicineThinner.pivot.min_recommended_concentration && this.mixtureConcentration.isComputed && ( this.mixtureConcentration.concentration < this.oncologicMedicineThinner.pivot.min_recommended_concentration ) ) {
                return 'La concentración es menor a la mínima recomendada por ' + this.oncologicMedicineThinner.pivot.reference + '. La concentración mínima recomendada es de ' + this.oncologicMedicineThinner.pivot.min_recommended_concentration + ' ' + this.selectedOncologicMedicine.component_unit.symbol +'/ml';
            }
        },
    },
    components: {
        serbitecMedicineAutocomplete
    },
    data: initialDataState,
    methods: {
        closeForm () {
            this.$store.commit('oncologicMedicine/RESET_ONCOLOGIC_MEDICINE');
            Object.assign(this.$data, initialDataState());
            this.$emit('close-form');
        },
        unselectMedicine () {
            this.$store.commit('oncologicMedicine/RESET_ONCOLOGIC_MEDICINE');
            Object.assign(this.$data, initialDataState());
            this.$nextTick(() => { this.$v.$reset(); });
        },
        unselectThinner () {
            this.oncologicMedicineThinner = null;
            this.oncologicMedicineThinnerQuantity = null;
            this.$nextTick(() => { this.$v.$reset(); });
        },

        getMixtureData () {
            const mixtureParams = {
                thinner_name: this.oncologicMedicineThinner.name,
                thinner_id: this.oncologicMedicineThinner.id,
                thinner_quantity: this.oncologicMedicineThinnerQuantity,
                route_administration: this.routeAdministration,
                comments: this.comments,
                infusion_set: this.infusionSet,

                medicines: [
                    {
                        name: this.selectedOncologicMedicine.commercial_name,
                        component_name: this.selectedOncologicMedicine.component.name,
                        medicine_unit_symbol: this.selectedOncologicMedicine.component_unit.symbol,
                        medicine_id: this.selectedOncologicMedicine.id,
                        medicine_quantity: this.medicineQuantity
                    }
                ]
            };
            console.log(this.routeAdministration);
            if ( !this.comesFromEdit ) {
                mixtureParams.id =  setID();
            }
            return mixtureParams;
        },
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.addMixToOrder();
            }
        },
        addMixToOrder () {
            this.loading = true;
            this.$store.commit('oncologicOrder/ADD_ONCOLOGIC_MIXTURE_TO_ORDER', this.getMixtureData());
            this.closeForm();
            window.scrollTo(0,0);
            this.loading = false;
        },
        storeMedicine ( medicine ) {
            this.$store.commit( 'oncologicMedicine/SET_ONCOLOGIC_MEDICINE', medicine );
        }
    },
    validations () {
        const validations = {
            medicineQuantity: {
                required,
                decimal
            },
            oncologicMedicineThinner: {
                required
            },
        };
        if ( this.oncologicMedicineThinner && this.oncologicMedicineThinner.id != 1 ) {
            validations.oncologicMedicineThinnerQuantity = {
                required,
                decimal
            };
        }
        return validations;
    }
};
</script>