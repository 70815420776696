var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav", { staticClass: "level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c(
            "div",
            { staticClass: "level-left" },
            [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "a",
            {
              staticClass: "button serbitec-small-right-margin",
              on: {
                click: function($event) {
                  _vm.addMedicineFormIsActive = true
                }
              }
            },
            [
              _c(
                "span",
                { staticClass: "icon" },
                [_c("font-awesome-icon", { attrs: { icon: "plus" } })],
                1
              ),
              _vm._v(" "),
              _c("span", [_vm._v(" Agregar medicamento")])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("serbitec-payer-info", { attrs: { "payer-id": _vm.payerId } }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm.addMedicineFormIsActive
        ? _c(
            "div",
            [
              _c("serbitec-payer-medicine-form", {
                attrs: { "payer-id": _vm.payerId, "has-close-button": true },
                on: {
                  "close-form": function($event) {
                    _vm.addMedicineFormIsActive = false
                  },
                  success: _vm.payerMedicineAdded,
                  error: _vm.payerMedicineAddedError
                }
              }),
              _vm._v(" "),
              _c("br")
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("serbitec-filters", {
        attrs: {
          "active-filters": _vm.activeFilters,
          "comes-from-page": "payerMedicines"
        }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "box" },
        [
          _c("nav", { staticClass: "level" }, [
            _c(
              "div",
              { staticClass: "level-left" },
              [
                _c(
                  "b-field",
                  {
                    attrs: {
                      message:
                        "Nota: La búsqueda se realiza por nombre comercial o componente",
                      label: "Búsqueda",
                      "label-position": "on-border"
                    }
                  },
                  [
                    _c(
                      "b-field",
                      [
                        _c("b-input", {
                          attrs: { icon: "search", rounded: "" },
                          model: {
                            value: _vm.searchParam,
                            callback: function($$v) {
                              _vm.searchParam = $$v
                            },
                            expression: "searchParam"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "control" },
                          [
                            _c("b-button", {
                              attrs: {
                                "icon-right": "times-circle",
                                rounded: ""
                              },
                              on: { click: _vm.clearSearchParam }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "level-right" },
              [
                _c("b-field", [
                  _c("p", { staticClass: "control" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button is-small",
                        on: { click: _vm.confirmDeleteAllDisabled }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "trash-alt" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(" Eliminar desactivados")])
                      ]
                    )
                  ])
                ])
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("b-message", [
            _c("strong", [_vm._v("Nota: ")]),
            _vm._v(
              "Para editar el precio del medicamento introduzca el nuevo precio y presione la tecla "
            ),
            _c("strong", [_vm._v("enter.")])
          ]),
          _vm._v(" "),
          _c("serbitec-payer-medicines-table", {
            attrs: {
              "payer-id": _vm.payerId,
              "payer-medicines": _vm.payerMedicines,
              "payer-medicines-count": _vm.payerMedicinesCount,
              loading: _vm.loading,
              page: _vm.page,
              pageSize: _vm.pageSize
            },
            on: {
              "update:loading": function($event) {
                _vm.loading = $event
              },
              "update:page": function($event) {
                _vm.page = $event
              },
              "page-change": _vm.onPageChange,
              "delete-item": _vm.deleteItem,
              "toggle-payer-medicine-enabled-state":
                _vm.togglePayerMedicineEnabledState,
              "toggle-payer-medicine-price-per-flask-state":
                _vm.togglePayerMedicinePricePerFlaskState,
              "edit-price": _vm.editMedicinePrice
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }