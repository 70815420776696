<template>
    <div>
        <b-loading :active.sync="loading"></b-loading>
        <b-message v-if="medicineNotAvaiable">
            <small>
                El medicamento ( <strong>{{ medicineName }}</strong> ) seleccionado por el cliente ya no se encuentra disponible.
                <br>
                <strong>Active</strong> el medicamento desde la pantalla de medicamentos del cliente o seleccione otro del listado
            </small>
        </b-message>
        <b-field :label="componentName">
            <b-select
            v-model="selectedMedicine"
            @input="editMedicine"
            expanded>
                <option v-for="medicine in medicines" :key="medicine.id" :value="medicine.id">{{ medicine.commercial_name }}</option>
            </b-select>
        </b-field>
    </div>
</template>

<script>
import axios from 'axios';
import gql from 'graphql-tag';
const query = gql` query ( $component_id: Int!, $enabled_for_payer_id: Int )
{
    medicines( component_id: $component_id, enabled_for_payer_id: $enabled_for_payer_id ) {
        id
        commercial_name
    }
}`;

export default {
    props:['medicineComponentId', 'componentName', 'medicineId', 'mixtureMedicineId', 'payerId', 'medicineName'],
    beforeMount () {
        this.selectedMedicine = this.medicineId;
    },
    computed: {
        medicineNotAvaiable(){
            if ( this.medicines ) {
                return this.medicines.find( medicine => medicine.id == this.medicineId ) === undefined;
            }
            return null;
        }
    },
    data () {
        return {
            selectedMedicine: null,
            loading: false,
        };
    },
    methods: {
        editMedicine() {
            let requestParams = {
                medicine_id: this.selectedMedicine
            };
            var url = '/api/oncologic/mixture_medicines/' + this.mixtureMedicineId;
            this.makeRequest( url, axios.post, requestParams );
        },
        makeRequest ( url, method, params ) {
            this.loading = true;
            method( url, params )
            .then(function () {
                this.$emit('medicine-edited');
                this.loading = false;
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$emit('medicine-not-edited', error);
                this.loading = false;
            }.bind(this));
        }
    },
    apollo: {
        medicines: {
            query: query,
            fetchPolicy: 'no-cache',
            variables () {
                let obj = {
                    component_id: this.medicineComponentId,
                };
                if ( this.payerId ) {
                    obj.enabled_for_payer_id = this.payerId;
                }
                return obj;
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            }
        },
    }
};
</script>