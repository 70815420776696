<template>
    <div class="box">
        <b-table
            :data="mixtures"
            :loading="loading"
            
            paginated
            backend-pagination
            :total="mixturesCount"
            :per-page="pageSize"
            @page-change="changePage"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="sort">

            <template slot-scope="props">
                <b-table-column field="id" label="Orden" numeric sortable>
                    <p v-if="isNutritional">ON-{{ props.row.order.id }}</p>
                    <p v-else-if="isOncologic">OO-{{ props.row.order.id }}</p>
                </b-table-column>

                <b-table-column label="Lote" numeric>
                    <p v-if="isNutritional">LN-{{ props.row.id }}</p>
                    <p v-else-if="isOncologic">LO-{{ props.row.id }}</p>
                </b-table-column>

                <b-table-column label="Paciente">
                    <p>{{ props.row.order.patient.name }}</p>
                </b-table-column>

                <b-table-column label="Cliente">
                    <p>{{ props.row.order.medic.payer.invoicing_data.business_name }}</p>
                </b-table-column>

                <b-table-column field="delivery_date" label="Fecha de entrega" :sortable="isNutritional">
                    <p v-if="isNutritional">{{ formatDate( props.row.delivery_date ) }}</p>
                    <p v-else-if="isOncologic">{{ formatDate( props.row.order.delivery_date ) }}</p>
                </b-table-column>

                <b-table-column label="QR" centered>
                    <a v-if="isNutritional" class="button is-light is-small" :href="'/nutritional/mixtures/' + props.row.id + '/qr.png'" target="_blank">
                        <span class="icon is-medium">
                            <font-awesome-icon icon="qrcode" />
                        </span>
                    </a>
                    <a v-else-if="isOncologic" class="button is-light is-small" :href="'/oncologic/mixtures/' + props.row.id + '/qr.png'" target="_blank">
                        <span class="icon is-medium">
                            <font-awesome-icon icon="qrcode" />
                        </span>
                    </a>
                </b-table-column>

                <b-table-column label="Elegir" centered>
                    <router-link
                        v-if="props.row.state.id == 2"
                        :to="{ path: 'select_supplies', query: { mixtureId: props.row.id } }"
                        class="button is-light is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="capsules" />
                        </span>
                    </router-link>
                    <a v-else-if="props.row.state.id == 4" class="button is-light is-small" disabled>
                        <span class="icon is-medium">
                            <font-awesome-icon icon="capsules" />
                        </span>
                    </a>
                </b-table-column>
            
                <b-table-column label="Preparar" centered>
                    <router-link
                        v-if="props.row.state.id == 4"
                        :to="{ path: 'prepare', query: { mixtureId: props.row.id } }"
                        class="button is-light is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="mortar-pestle" />
                        </span>
                    </router-link>
                    <a v-if="props.row.state.id == 2" class="button is-light is-small" disabled>
                        <span class="icon is-medium">
                            <font-awesome-icon icon="mortar-pestle" />
                        </span>
                    </a>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay mezclas pendientes.</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { es } from 'date-fns/locale';

export default {
    props: [
        'preparerType',
        'mixtures',
        'loading',
        'mixturesCount',
        'page',
        'pageSize',
        'sortField',
        'sortOrder'
    ],
    computed: {
        isOncologic () {
            if ( this.preparerType == 'oncologic' ) {
                return true;
            } else {
                return false;
            }
        },
        isNutritional () {
            if ( this.preparerType == 'nutritional' ) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        formatDate( date ) {
            return format( parseISO(date), "d 'de' MMMM yyyy '|' HH:mm", { locale: es } );
        },
        changePage ( page ) {
            this.$emit('page-change', page);
        },
        sort ( field, order ) {
            this.$emit('sort', field, order);
        }
    }
};
</script>