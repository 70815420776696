<template>
    <b-autocomplete
        v-model="patientName"
        :data="patients"
        :loading="autocompleteIsLoading"
        @select="(option) => $emit('patient-added', option)"
        :disabled="disabled"
        expanded
    >
        <template slot-scope="props">
            <div class="media">
                <div class="media-content">
                    <strong>{{ props.option.name }}</strong>
                    <br>
                    <p class="has-text-weight-normal">
                        Diagnóstico: {{ props.option.diagnosis }}
                    </p>
                </div>
            </div>
        </template>
        <template slot="empty">No se encontró el paciente...</template>
    </b-autocomplete>
</template>

<script>
import gql from 'graphql-tag';

const query = gql` query ( $name: String, $payer_id: Int )
{
    patients( name: $name, payer_id: $payer_id){
        id
        name
        diagnosis
        height_in_meters
        weight_in_kilograms
        file_id
        location
        bed
        birth_date
        gender
        age_type
        body_surface_in_square_meters
    }
}`;

export default {
    props: {
        payerId: {
            type: [Number, String],
            default: 0 | '0'
        },
        medicID: {
            type: [Number, String],
            default: 0 | '0'
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data: function () {
        return {
            patientName: null,
            autocompleteIsLoading: false,
        };
    },
    apollo: {
        patients: {
            query: query,
            variables () {
                return {
                    name: this.patientName,
                    payer_id: this.payerId
                };
            }
        },
    }
};
</script>