<template>
    <div>
        <b-table
        :data="data">
            <template slot-scope="props">
                <b-table-column label="Tipo de componente" centered>
                    <b-tag>{{ props.row.type }}</b-tag>
                </b-table-column>

                <b-table-column field="props.row.name" label="Componente">
                    <div v-if="props.row.selectedComponent">{{ props.row.selectedComponent.name }}</div>
                    <div v-else>{{ props.row.name }}</div>
                </b-table-column>

                <b-table-column field="props.row.quantity" label="Cantidad" centered>
                    {{ props.row.quantity }} {{ props.row.unitSymbol }}
                </b-table-column>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    props:['data']
};
</script>