<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <div class="level-left">
                    <serbitec-breadcumbs
                        :route="route"
                    />
                </div>
            </div>
            <div class="level-right">
                <a class="button" @click="addThinner">
                    <span class="icon">
                        <font-awesome-icon icon="plus" />
                    </span>
                    <span> Agregar diluyente</span>
                </a>
            </div>
        </nav>
        <hr>
        <serbitec-medicine-info
            :medicine-id="medicineId"
        />
        <serbitec-thinners-table
            v-if="medicine"
            class="serbitec-medium-top-margin"
            :thinners="medicine.oncologic_thinners"
            @reload-thinners="$apollo.queries.medicine.refresh()"
        />
    </div>
</template>

<script>
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';
import serbitecMedicineInfo from '../../../components/dashboard/utils/medicine-info.vue';
import serbitecThinnersTable from '../../../components/dashboard/medicines-catalog/oncologic/thinners-table.vue';
import serbitecAddThinnerModal from '../../../components/dashboard/medicines-catalog/oncologic/add-thinner-modal.vue';

import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    medicine( id: $id ) {
        id
        oncologic_thinners {
            id
            name
            pivot {
                id
                stability_ambient_in_hours
                stability_cooled_in_hours
                min_recommended_concentration
                max_recommended_concentration
                reference
                min_infusion_speed
                max_infusion_speed
                infusion_speed_unit {
                    id
                    symbol
                }
            }
        }
    }
}`;

export default {
    props: {
        medicineId: {
            type: [String, Number],
            default: 0 | ''
        }
    },
    components: {
        serbitecBreadcumbs,
        serbitecMedicineInfo,
        serbitecThinnersTable
    },
    data () {
        return {
            route: [
                {
                    label: 'Catálogo de medicamentos oncológicos',
                    to: '/oncologic_catalog',
                    active: false
                },
                {
                    label: 'Diluyentes',
                    to: '/oncologic_catalog/thinners',
                    active: true
                }
            ]
        };
    },
    methods: {
        addThinner () {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecAddThinnerModal,
                hasModalCard: true,
                trapFocus: true,
                fullScreen: true,
                props: {
                    medicineId: this.medicineId
                },
                events: {
                    'success': this.thinnerAdded
                }
            });
        },
        thinnerAdded () {
            this.$apollo.queries.medicine.refresh();
        }
    },
    apollo: {
        medicine: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    id: this.medicineId
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            }
        },
    }
};
</script>