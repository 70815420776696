var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav", { staticClass: "level" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "a",
            {
              staticClass: "button",
              attrs: { append: "" },
              on: { click: _vm.addManufacturer }
            },
            [
              _c(
                "span",
                { staticClass: "icon" },
                [_c("font-awesome-icon", { attrs: { icon: "plus" } })],
                1
              ),
              _vm._v(" "),
              _c("span", [_vm._v(" Agregar fabricante")])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("serbitec-manufacturers-table", {
        attrs: {
          manufacturers: _vm.medicineManufacturers,
          "manufacturers-count": _vm.medicineManufacturersCount,
          page: _vm.page,
          pageSize: _vm.pageSize,
          sortField: _vm.sortField,
          sortOrder: _vm.sortOrder
        },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:sortField": function($event) {
            _vm.sortField = $event
          },
          "update:sort-field": function($event) {
            _vm.sortField = $event
          },
          "update:sortOrder": function($event) {
            _vm.sortOrder = $event
          },
          "update:sort-order": function($event) {
            _vm.sortOrder = $event
          },
          "page-change": _vm.onPageChange,
          sort: _vm.onSort,
          "reload-manufacturers": function($event) {
            return _vm.$apollo.queries.medicineManufacturers.refresh()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("Fabricantes")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }