var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.mixtures,
            paginated: "",
            "backend-pagination": "",
            total: _vm.mixturesCount,
            "per-page": _vm.pageSize,
            "aria-next-label": "Siguiente página",
            "aria-previous-label": "Página anterior",
            "aria-page-label": "Página",
            "aria-current-label": "Página actual",
            "backend-sorting": "",
            "default-sort": [_vm.sortField, _vm.sortOrder]
          },
          on: { "page-change": _vm.changePage, sort: _vm.sort },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "id",
                        label: "Orden",
                        numeric: "",
                        sortable: ""
                      }
                    },
                    [
                      _vm.isNutritional
                        ? _c("p", [_vm._v("ON-" + _vm._s(props.row.order.id))])
                        : _vm.isOncologic
                        ? _c("p", [_vm._v("OO-" + _vm._s(props.row.id))])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isNutritional
                    ? _c(
                        "b-table-column",
                        { attrs: { label: "Lote", numeric: "" } },
                        [_c("p", [_vm._v("LN-" + _vm._s(props.row.id))])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Paciente" } }, [
                    _vm.isNutritional
                      ? _c("p", [_vm._v(_vm._s(props.row.order.patient.name))])
                      : _vm.isOncologic
                      ? _c("p", [_vm._v(_vm._s(props.row.patient.name))])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Cliente" } }, [
                    _vm.isNutritional
                      ? _c("p", [
                          _vm._v(
                            _vm._s(
                              props.row.order.medic.payer.invoicing_data
                                .business_name
                            )
                          )
                        ])
                      : _vm.isOncologic
                      ? _c("p", [
                          _vm._v(
                            _vm._s(
                              props.row.medic.payer.invoicing_data.business_name
                            )
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "delivery_date",
                        label: "Fecha y hora de entrega",
                        sortable: ""
                      }
                    },
                    [
                      _vm.isNutritional
                        ? _c("p", [
                            _vm._v(
                              _vm._s(_vm.formatDate(props.row.delivery_date))
                            )
                          ])
                        : _vm.isOncologic
                        ? _c("p", [
                            _vm._v(
                              _vm._s(_vm.formatDate(props.row.delivery_date))
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { centered: "" } },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "button is-light is-small",
                          attrs: {
                            to: _vm.isNutritional
                              ? {
                                  path: "authorize",
                                  query: { mixtureId: props.row.id }
                                }
                              : {
                                  path: "authorize",
                                  query: { orderId: props.row.id }
                                },
                            append: ""
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "clipboard-check" }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(" Revisar")])
                        ]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("No hay mezclas pendientes.")])
                ]
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }