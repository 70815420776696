var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "box" },
      [
        _c(
          "b-table",
          {
            attrs: { data: _vm.purchaseOrders },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(props) {
                  return [
                    !_vm.isExternalWarehouse
                      ? _c(
                          "b-table-column",
                          {
                            attrs: {
                              field: "purchase_order_id",
                              label: "Orden de compra",
                              width: "250",
                              numeric: "",
                              sortable: "",
                              centered: ""
                            }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(props.row.purchase_order_id) +
                                "\n                "
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-table-column",
                      {
                        attrs: {
                          label: "Número de frascos o piezas",
                          centered: ""
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(props.row.flasks_in_order) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.isExternalWarehouse
                      ? _c(
                          "b-table-column",
                          {
                            attrs: {
                              label: "Costo por frasco o pieza",
                              centered: ""
                            }
                          },
                          [
                            _vm._v(
                              "\n                    $ " +
                                _vm._s(props.row.cost_in_cents_per_item / 100) +
                                " MXN\n                "
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          },
          [
            _vm._v(" "),
            _c("template", { slot: "empty" }, [
              _c("section", { staticClass: "section" }, [
                _c(
                  "div",
                  { staticClass: "content has-text-grey has-text-centered" },
                  [
                    _c(
                      "p",
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "exclamation-triangle",
                            size: "is-large"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        'No hay órdenes de compra registradas en este lote, para dar de alta de click en el botón "Agregar orden de compra"'
                      )
                    ])
                  ]
                )
              ])
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }