<template>
    <div>
        <div>
            <serbitec-navbar
                @open-sidebar="openSideBar = true"
            />
        </div>
        <div>
            <serbitec-sidebar
                :open="openSideBar"
                :menu="menu"
                @close-sidebar="() => $mq === 'desktop' ? openSideBar = true : openSideBar = false"
                @on-cancel="() => $mq === 'desktop' ? openSideBar = true : openSideBar = false"
            />
        </div>
    </div>
</template>

<script>
import serbitecNavbar from './utils/navbar.vue';
import serbitecSidebar from './utils/sidebar.vue';
import { defaultInternalUserMenu } from './internal-user-menu.js';
import { defaultExternalUserMenu } from './external-user-menu.js';
import { mapState } from 'vuex';

export default {
    components: {
        serbitecNavbar,
        serbitecSidebar
    },
    watch: {
        $mq: function (newVal) {
            if (newVal === 'desktop') {
                this.openSideBar = true;
            }
        }
    },
    data () {
        return {
            openSideBar: true,
            menu: []
        };
    },
    beforeMount () {
        if (this.$mq !== 'desktop' ) {
            this.openSideBar = false;
        }
        if (this.user.types.find( (type) => type.id === 1 ) !== undefined) {
            defaultInternalUserMenu.map( (menu) => {
                menu.items.map( (item) => {
                    item.isAuth = true
                } )
                menu.numberOfItemsAuthorized = menu.items.filter( (item) => item.isAuth === true ).length
            } )
            this.menu = defaultInternalUserMenu;
        } else if (this.user.isExternalUser) {
            defaultExternalUserMenu.map( (menu) => {
                menu.items.map( (item) => {
                    item.isAuth = this.user.authorized_web_routes.find( (route) => route.route === item.route ) !== undefined
                } )
                menu.numberOfItemsAuthorized = menu.items.filter( (item) => item.isAuth === true ).length
            } )
            this.menu = defaultExternalUserMenu;
        } else {
            defaultInternalUserMenu.map( (menu) => {
                menu.items.map( (item) => {
                    item.isAuth = this.user.authorized_web_routes.find( (route) => route.route === item.route ) !== undefined
                } )
                menu.numberOfItemsAuthorized = menu.items.filter( (item) => item.isAuth === true ).length
            } )
            this.menu = defaultInternalUserMenu;
        }
    },
    computed: {
        ...mapState({
            user: state => state.user
        })
    }
};
</script>