var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("b-loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function($event) {
            _vm.loading = $event
          }
        }
      }),
      _vm._v(" "),
      _vm.hasCloseButton
        ? _c("nav", { staticClass: "level" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "level-right" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close-form")
                    }
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "icon" },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "times-circle" }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v(" Cerrar forma")])
                ]
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "true" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitUserForm($event)
            }
          }
        },
        [
          _c(
            "b-field",
            { attrs: { grouped: "", "group-multiline": "" } },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Nombre",
                    type: { "is-danger": _vm.$v.name.$error },
                    message: {
                      "Se requiere introducir el nombre completo de la persona de contacto":
                        !_vm.$v.name.required && _vm.$v.name.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.name, "$model", $$v)
                      },
                      expression: "$v.name.$model"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Apellido paterno",
                    type: { "is-danger": _vm.$v.last_name_1.$error },
                    message: {
                      "Se requiere introducir el apellido paterno de la persona de contacto":
                        !_vm.$v.last_name_1.required &&
                        _vm.$v.last_name_1.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.last_name_1.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.last_name_1, "$model", $$v)
                      },
                      expression: "$v.last_name_1.$model"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Apellido materno",
                    type: { "is-danger": _vm.$v.last_name_2.$error },
                    message: {
                      "Se requiere introducir el apellido materno de la persona de contacto":
                        !_vm.$v.last_name_2.required &&
                        _vm.$v.last_name_2.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.last_name_2.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.last_name_2, "$model", $$v)
                      },
                      expression: "$v.last_name_2.$model"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { grouped: "", "group-multiline": "" } },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Correo electrónico",
                    type: { "is-danger": _vm.$v.email.$error },
                    message: {
                      "Se requiere introducir el correo electrónico de la persona de contacto":
                        !_vm.$v.email.required && _vm.$v.email.$error,
                      "Se requiere introducir un correo electrónico válido":
                        !_vm.$v.email.email && _vm.$v.email.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.email.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.email, "$model", $$v)
                      },
                      expression: "$v.email.$model"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Tipo de usuario",
                    type: { "is-danger": _vm.$v.userTypeId.$error },
                    message: {
                      "Se requiere introducir el tipo de cliente":
                        !_vm.$v.userTypeId.required && _vm.$v.userTypeId.$error
                    }
                  }
                },
                [
                  _c(
                    "b-select",
                    {
                      attrs: {
                        placeholder: "Seleccione un tipo",
                        disabled: _vm.user
                      },
                      model: {
                        value: _vm.$v.userTypeId.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.userTypeId, "$model", $$v)
                        },
                        expression: "$v.userTypeId.$model"
                      }
                    },
                    _vm._l(_vm.userTypes, function(userType) {
                      return _c(
                        "option",
                        { key: userType.id, domProps: { value: userType.id } },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(userType.name) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.userTypeId && _vm.userTypeId == 3
                ? _c(
                    "b-field",
                    {
                      attrs: {
                        label: "Cédula médica",
                        type: { "is-danger": _vm.$v.medicalCard.$error },
                        message: {
                          "Se requiere introducir la cédula profesional del médico":
                            !_vm.$v.medicalCard.required &&
                            _vm.$v.medicalCard.$error
                        },
                        expanded: ""
                      }
                    },
                    [
                      _c("b-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.$v.medicalCard.$model,
                          callback: function($$v) {
                            _vm.$set(_vm.$v.medicalCard, "$model", $$v)
                          },
                          expression: "$v.medicalCard.$model"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c("b-field", { attrs: { grouped: "", position: "is-centered" } }, [
            _c("p", { staticClass: "control" }, [
              _c(
                "button",
                { staticClass: "button is-primary", attrs: { type: "submit" } },
                [
                  _vm.user
                    ? _c("span", [
                        _vm._v(
                          "\n                        Editar usuario\n                    "
                        )
                      ])
                    : _c("span", [
                        _vm._v(
                          "\n                        Agregar usuario\n                    "
                        )
                      ])
                ]
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("h1", { staticClass: "subtitle" }, [_vm._v("Agregar usuario")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }