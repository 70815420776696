<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right"></div>
        </nav>
        <hr>
        <serbitec-payer-info
        :payer-id="this.$route.query.payer_id"></serbitec-payer-info>
        <br>
        <serbitec-client-external-warehouse />
    </div>
</template>
 
 <script>
import serbitecClientExternalWarehouse from '../../../components/dashboard/payers/configuration/external-warehouse.vue';
import serbitecPayerInfo from '../../../components/dashboard/payers/payer-info.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';

 export default {
    components: {
        serbitecClientExternalWarehouse,
        serbitecPayerInfo,
        serbitecBreadcumbs
    },
    data () {
        return {
            route: [
            {
                label: 'Clientes',
                to: '/payers',
                active: false
            },
            {
                label: 'Configuración del cliente',
                to: '/payers/configuration',
                active: true
            }
        ]
        };
    }
 };
 </script>