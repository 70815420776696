var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function($event) {
            _vm.loading = $event
          }
        }
      }),
      _vm._v(" "),
      _vm.medicineNotAvaiable
        ? _c("b-message", [
            _c("small", [
              _vm._v("\n            El medicamento ( "),
              _c("strong", [_vm._v(_vm._s(_vm.medicineName))]),
              _vm._v(
                " ) seleccionado por el cliente ya no se encuentra disponible.\n            "
              ),
              _c("br"),
              _vm._v(" "),
              _c("strong", [_vm._v("Active")]),
              _vm._v(
                " el medicamento desde la pantalla de medicamentos del cliente o seleccione otro del listado\n        "
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { label: _vm.componentName } },
        [
          _c(
            "b-select",
            {
              attrs: { expanded: "" },
              on: { input: _vm.editMedicine },
              model: {
                value: _vm.selectedMedicine,
                callback: function($$v) {
                  _vm.selectedMedicine = $$v
                },
                expression: "selectedMedicine"
              }
            },
            _vm._l(_vm.medicines, function(medicine) {
              return _c(
                "option",
                { key: medicine.id, domProps: { value: medicine.id } },
                [_vm._v(_vm._s(medicine.commercial_name))]
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }