var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("hr"),
    _vm._v(" "),
    _c("h1", { staticClass: "subtitle" }, [_vm._v("Información adicional")]),
    _vm._v(" "),
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _c(
            "b-field",
            { attrs: { label: "Tiempo de infusión en horas" } },
            [
              _c(
                "b-field",
                [
                  _c("b-numberinput", {
                    attrs: { type: "is-light", min: "0" },
                    model: {
                      value: _vm.infusionTime,
                      callback: function($$v) {
                        _vm.infusionTime = $$v
                      },
                      expression: "infusionTime"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.infusionTime && (_vm.computedTotalVolume || _vm.totalVolume)
        ? _c(
            "div",
            { staticClass: "column" },
            [
              _c("b-field", { attrs: { label: "Velocidad de infusión" } }, [
                _c("p", { staticClass: "control" }, [
                  _c("span", { staticClass: "button is-static" }, [
                    _vm._v(
                      _vm._s(
                        (
                          Math.max(_vm.computedTotalVolume, _vm.totalVolume) /
                          _vm.infusionTime
                        ).toFixed(2)
                      ) + " ml/h"
                    )
                  ])
                ])
              ])
            ],
            1
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "columns box",
        staticStyle: { "padding-bottom": "15px", "padding-top": "10px" }
      },
      [
        _c("div", { staticClass: "column" }, [
          _c("h1", { staticClass: "subtitle is-5" }, [
            _vm._v("Aportes de la mezcla")
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Aporte calórico total: ")]),
            _vm._v(" "),
            _c("span", { staticClass: "serbitec-align-right" }, [
              _vm._v(
                _vm._s(_vm.componentSums.energyInputInKCalAccumulator) + " KCal"
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Aporte calórico no protéico: ")]),
            _vm._v(" "),
            _c("span", { staticClass: "serbitec-align-right" }, [
              _vm._v(
                _vm._s(
                  _vm.componentSums.nonProteicEnergyInputInKCalAccumulator
                ) + " KCal"
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Gramos de aminoácido: ")]),
            _vm._v(" "),
            _c("span", { staticClass: "serbitec-align-right" }, [
              _vm._v(
                _vm._s(_vm.componentSums.gramsOfAminoacidAccumulator) + " g"
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Gramos de nitrógeno: ")]),
            _vm._v(" "),
            _c("span", { staticClass: "serbitec-align-right" }, [
              _vm._v(
                _vm._s(_vm.componentSums.gramsOfNitrogenAccumulator) + " g"
              )
            ])
          ]),
          _vm._v(" "),
          _vm.totalVolume > 0
            ? _c("span", [
                _c("p", [
                  _c("strong", [_vm._v("Osmolaridad: ")]),
                  _vm._v(" "),
                  _c("span", { staticClass: "serbitec-align-right" }, [
                    _vm._v(
                      _vm._s(
                        _vm.componentSums.osmoticConcentrationAccumulator
                      ) + " mOsm/l"
                    )
                  ])
                ])
              ])
            : _c("span", [
                _c("strong", [_vm._v("Osmolaridad: ")]),
                _vm._v(" "),
                _c("p", { staticClass: "serbitec-align-right is-size-7" }, [
                  _vm._v(
                    "Se requiere el volumen total para calcular la osmolaridad"
                  )
                ])
              ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "column" }, [
          _c("h1", { staticClass: "subtitle is-5" }, [
            _vm._v("Electrolitos totales")
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Sodio (Na): ")]),
            _vm._v(" "),
            _c("span", { staticClass: "serbitec-align-right" }, [
              _vm._v(
                _vm._s(_vm.componentSums.naConcentrationAccumulator) + " mEq"
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Potasio (K): ")]),
            _vm._v(" "),
            _c("span", { staticClass: "serbitec-align-right" }, [
              _vm._v(
                _vm._s(_vm.componentSums.kConcentrationAccumulator) + " mEq"
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Magnesio (Mg): ")]),
            _vm._v(" "),
            _c("span", { staticClass: "serbitec-align-right" }, [
              _vm._v(
                _vm._s(_vm.componentSums.mgConcentrationAccumulator) + " mEq"
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Calcio (Ca): ")]),
            _vm._v(" "),
            _c("span", { staticClass: "serbitec-align-right" }, [
              _vm._v(
                _vm._s(_vm.componentSums.caConcentrationAccumulator) + " mEq"
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._m(0),
            _vm._v(" "),
            _c("span", { staticClass: "serbitec-align-right" }, [
              _vm._v(
                _vm._s(_vm.componentSums.po4ConcentrationAccumulator) + " mEq"
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Acetato (Ace): ")]),
            _vm._v(" "),
            _c("span", { staticClass: "serbitec-align-right" }, [
              _vm._v(
                _vm._s(_vm.componentSums.aceConcentrationAccumulator) + " mEq"
              )
            ])
          ]),
          _vm._v(" "),
          _c("p", [
            _c("strong", [_vm._v("Cloro (Cl): ")]),
            _vm._v(" "),
            _c("span", { staticClass: "serbitec-align-right" }, [
              _vm._v(
                _vm._s(_vm.componentSums.clConcentrationAccumulator) + " mEq"
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("strong", [
      _vm._v("Fosfato (PO"),
      _c("sub", [_vm._v("4")]),
      _vm._v("): ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }