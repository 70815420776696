<template>
    <b-field
        message="Nota: Por el momento la búsqueda es solo por nombre comercial"
        label="Búsqueda"
        label-position="on-border"
    >
        <b-input
            :value="search"
            @input="(search) => $emit('update:search', search)"
            placeholder="Introduce para buscar..."
            icon="search"
            icon-right="times-circle"
            icon-right-clickable
            @icon-right-click="() => $emit('clear-search')"
            rounded
        />
    </b-field>
</template>

<script>
export default {
    props: {
        search: {
            type: String,
            default: ''
        }
    }
};
</script>
