var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "b-steps",
        {
          staticClass: "serbitec-small-top-margin",
          attrs: { "has-navigation": false, animated: "", rounded: "" },
          model: {
            value: _vm.activeStep,
            callback: function($$v) {
              _vm.activeStep = $$v
            },
            expression: "activeStep"
          }
        },
        [
          _c(
            "b-step-item",
            { attrs: { step: "1", label: "Datos generales" } },
            [
              _c("serbitec-nutritional-mixtures-request-first-step", {
                key: _vm.key,
                staticClass:
                  "serbitec-medium-top-margin serbitec-medium-bottom-margin",
                on: {
                  "next-step": function($event) {
                    _vm.activeStep = 1
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-step-item",
            { attrs: { step: "2", label: "Componentes de la mezcla" } },
            [
              _c("serbitec-nutritional-mixtures-request-second-step", {
                key: _vm.key + 1,
                staticClass:
                  "serbitec-medium-top-margin serbitec-medium-bottom-margin",
                on: {
                  "previous-step": function($event) {
                    _vm.activeStep = 0
                  },
                  "next-step": function($event) {
                    _vm.activeStep = 2
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-step-item",
            { attrs: { step: "3", label: "Revisión del pedido" } },
            [
              _c("serbitec-nutritional-mixtures-request-third-step", {
                key: _vm.key + 2,
                staticClass:
                  "serbitec-medium-top-margin serbitec-medium-bottom-margin",
                on: {
                  "previous-step": function($event) {
                    _vm.activeStep = 1
                  },
                  "submit-success": _vm.submitSuccess
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { staticClass: "level is-mobile" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("div", { staticClass: "level-item" }, [
          _c("h1", { staticClass: "title" }, [
            _vm._v("Nuevo pedido de mezclas de nutrición parenteral")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }