<template>
    <div>
        <div>
            <serbitec-navbar
                :login="true"
            />
        </div>
    </div>
</template>

<script>
import serbitecNavbar from './utils/navbar.vue';

export default {
    components: {
        serbitecNavbar
    }  
};
</script>
