var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.medicine
        ? _c("b-message", [
            _c("h1", { staticClass: "subtitle is-5" }, [
              _vm._v(_vm._s(_vm.medicine.commercial_name))
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Genérico: ")]),
              _vm._v(_vm._s(_vm.medicine.component.name))
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Fabricante: ")]),
              _vm._v(_vm._s(_vm.medicine.manufacturer.name))
            ])
          ])
        : _c("b-skeleton", { attrs: { height: "137px", animated: true } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }