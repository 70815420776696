var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("b-loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function($event) {
            _vm.loading = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.isEmpty ? [] : _vm.orderList,
            hoverable: true,
            loading: _vm.loadingTable,
            paginated: "",
            "per-page": "10",
            detailed: "",
            "detail-key": "id",
            "mobile-cards": true
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    { attrs: { field: "name", label: "Medicamento" } },
                    [
                      props.row.thinner_id
                        ? _c(
                            "span",
                            _vm._l(props.row.medicines, function(medicine) {
                              return _c("span", { key: medicine.id }, [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(medicine.name) +
                                    " + " +
                                    _vm._s(props.row.thinner_name) +
                                    "\n                    "
                                )
                              ])
                            }),
                            0
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      props.row.medicine_presentation_id
                        ? _c("span", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(props.row.medicine_name) +
                                "\n                "
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Tipo" } },
                    [
                      props.row.thinner_id
                        ? _c("b-tag", [_vm._v("Bolo")])
                        : _vm._e(),
                      _vm._v(" "),
                      props.row.medicine_presentation_id
                        ? _c("b-tag", [_vm._v("Frasco")])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Eliminar", centered: "" } },
                    [
                      props.row.thinner_id
                        ? _c(
                            "a",
                            {
                              staticClass: "button is-small is-danger",
                              on: {
                                click: function($event) {
                                  return _vm.deleteMixture(props.row.id)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "trash-alt" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      props.row.medicine_presentation_id
                        ? _c(
                            "a",
                            {
                              staticClass: "button is-small is-danger",
                              on: {
                                click: function($event) {
                                  return _vm.deleteFlask(props.row.id)
                                }
                              }
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "trash-alt" }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ]
              }
            },
            {
              key: "detail",
              fn: function(props) {
                return [
                  _c("div", { staticClass: "content" }, [
                    props.row.thinner_id
                      ? _c(
                          "p",
                          [
                            _c("strong", [_vm._v("Diluyente: ")]),
                            _vm._v(
                              " " +
                                _vm._s(props.row.thinner_quantity) +
                                " ml de " +
                                _vm._s(props.row.thinner_name) +
                                "\n                    "
                            ),
                            _vm._l(props.row.medicines, function(medicine) {
                              return _c("span", { key: medicine.id }, [
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Activo: ")]),
                                _vm._v(
                                  " " +
                                    _vm._s(medicine.medicine_quantity) +
                                    " " +
                                    _vm._s(medicine.medicine_unit_symbol) +
                                    " de " +
                                    _vm._s(medicine.name) +
                                    "\n                    "
                                )
                              ])
                            }),
                            _vm._v(" "),
                            props.row.comments
                              ? _c("span", [
                                  _c("br"),
                                  _vm._v(" "),
                                  _c("strong", [_vm._v("Comentarios: ")]),
                                  _vm._v(
                                    _vm._s(props.row.comments) +
                                      "\n                    "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("strong", [_vm._v("Set de infusión: ")]),
                            _vm._v(" "),
                            props.row.infusion_set
                              ? _c("font-awesome-icon", {
                                  attrs: { icon: "check-circle" }
                                })
                              : _c("font-awesome-icon", {
                                  attrs: { icon: "times-circle" }
                                })
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    props.row.medicine_presentation_id
                      ? _c("p", [
                          _c("strong", [_vm._v("Activo: ")]),
                          _vm._v(
                            " " +
                              _vm._s(props.row.medicine_name) +
                              " " +
                              _vm._s(props.row.medicine_component_name) +
                              "\n                    "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("strong", [_vm._v("Presentación: ")]),
                          _vm._v(
                            " " +
                              _vm._s(props.row.presentation_quantity) +
                              " " +
                              _vm._s(
                                props.row.medicine_presentation_unit_symbol
                              ) +
                              "\n                    "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("strong", [_vm._v("Cantidad: ")]),
                          _vm._v(
                            " " +
                              _vm._s(props.row.number_of_flasks) +
                              " frasco(s)\n                    "
                          ),
                          props.row.comments
                            ? _c("span", [
                                _c("br"),
                                _vm._v(" "),
                                _c("strong", [_vm._v("Comentarios: ")]),
                                _vm._v(
                                  _vm._s(props.row.comments) +
                                    "\n                    "
                                )
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "No hay bolos ni frascos en la orden, para agregar de click en el botón de agregar bolo o agregar frasco."
                    )
                  ])
                ]
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }