<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <div class="level-left">
                    <serbitec-breadcumbs
                        :route="route"
                    />
                </div>
            </div>
            <div class="level-right">
                <a class="button" @click="addPurchaseOrder">
                    <span class="icon">
                        <font-awesome-icon icon="plus" />
                    </span>
                    <span> Agregar orden de {{ isExternalWarehouse ? 'ingreso' : 'compra' }}</span>
                </a>
            </div>
        </nav>
        <hr>
        <b-message v-if="warehouseItem">
            <nav class="level">
                <div class="level-left">
                    <div class="level-item">
                        <h1 class="subtitle is-5">Lote: <strong><span v-if="warehouseItem.prefix">{{ warehouseItem.prefix.prefix }}-</span>{{ warehouseItem.batch }}</strong></h1>
                    </div>
                </div>
                <div class="level-right" />
            </nav>
            <p><strong>Nombre comercial: </strong>{{ warehouseItem.medicine_presentation.medicine.commercial_name }}</p>
            <p><strong>Genérico: </strong>{{ warehouseItem.medicine_presentation.medicine.component.name }}</p>
            <p><strong>Fabricante: </strong>{{ warehouseItem.medicine_presentation.medicine.manufacturer.name }}</p>
        </b-message>
        <serbitec-shop-order-table
            :is-external-warehouse="isExternalWarehouse"
            :batch-id="batchId"
            :purchase-orders="purchaseOrders"
        />
    </div>
</template>

<script>
import serbitecShopOrderTable from '../../../components/dashboard/warehouse/shop-order-table.vue';
// import serbitecEntryOrderTable from '../../../components/dashboard/warehouse/entry-order-table.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';
import serbitecAddPurchaseOrderModal from '../../../components/dashboard/warehouse/add-purchase-order-modal.vue';

import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    warehouseItem: external_medicine_warehouse_item( id: $id ) {
        id
        prefix {
            id
            prefix
        }
        batch
        medicine_presentation {
            id
            medicine {
                id
                commercial_name
                component {
                    id
                    name
                }
                manufacturer {
                    id
                    name
                }
            }
        }
    }
    purchaseOrders: external_medicine_warehouse_item( id:$id ) {
        id
        orders {
            id
            purchase_order_id
            flasks_in_order
            cost_in_cents_per_item
        }
    }
}`;

export default {
    props: {
        batchId: {
            type: [Number, String],
            default: 0 | '0'
        }
    },
    components: {
        serbitecShopOrderTable,
        serbitecBreadcumbs
    },
    data () {
        return {
            isExternalWarehouse: false,
            route: [
                {
                    label: 'Almacén central',
                    to: '/warehouse',
                    active: false
                },
                {
                    label: 'Órdenes de compra o ingreso',
                    to: '/warehouse/purchase_orders',
                    active: true
                }
            ],
            purchaseOrders: []
        };
    },
    methods: {
        addPurchaseOrder () {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecAddPurchaseOrderModal,
                hasModalCard: true,
                trapFocus: true,
                fullScreen: false,
                props: {
                    batchId: this.batchId,
                    isExternalWarehouse: this.isExternalWarehouse
                },
                events: {
                    'success': () => this.$apollo.queries.warehouseItem.refresh()
                }
            });
        }
    },
    apollo: {
        warehouseItem: {
            query: query,
            fetchPolicy: 'cache-and-network',
            variables () {
                return {
                    id: this.batchId
                };
            },
            update (data) {
                this.isExternalWarehouse = data.warehouseItem.prefix ? true : false;
                this.purchaseOrders = data.purchaseOrders.orders;
                return data.warehouseItem;
            },
            watchLoading (isLoading) {
                this.loading = isLoading;
            },
        },
    }
};
</script>