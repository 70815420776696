var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-field",
    {
      attrs: {
        message:
          "Nota: Por el momento la búsqueda es solo por nombre comercial",
        label: "Búsqueda",
        "label-position": "on-border"
      }
    },
    [
      _c("b-input", {
        attrs: {
          value: _vm.search,
          placeholder: "Introduce para buscar...",
          icon: "search",
          "icon-right": "times-circle",
          "icon-right-clickable": "",
          rounded: ""
        },
        on: {
          input: function(search) {
            return _vm.$emit("update:search", search)
          },
          "icon-right-click": function() {
            return _vm.$emit("clear-search")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }