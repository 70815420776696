var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "level" }, [
      _c(
        "div",
        { staticClass: "level-left" },
        [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" })
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _vm.order
      ? _c(
          "div",
          [
            _c("div", { staticClass: "box" }, [
              _c("h1", { staticClass: "title has-text-centered" }, [
                _vm._v("Orden OO-" + _vm._s(_vm.orderId))
              ])
            ]),
            _vm._v(" "),
            _c("serbitec-horizontal-order-data", {
              attrs: { medic: _vm.order.medic, patient: _vm.order.patient }
            }),
            _vm._v(" "),
            _vm._l(_vm.order.mixtures, function(mixture, index) {
              return _c("div", { key: index }, [
                _c(
                  "div",
                  {
                    staticClass: "box",
                    staticStyle: { "margin-bottom": "15px" }
                  },
                  [
                    _c("h1", { staticClass: "subtitle" }, [
                      _vm._v("Mezcla con lote: LO-" + _vm._s(mixture.id))
                    ]),
                    _vm._v(" "),
                    _c("serbitec-oncologic-elements-table", {
                      attrs: { elements: mixture.elements }
                    }),
                    _vm._v(" "),
                    _c("serbitec-oncologic-alerts", {
                      attrs: { elements: mixture.elements }
                    })
                  ],
                  1
                )
              ])
            }),
            _vm._v(" "),
            _vm.order.requested_flasks.length
              ? _c(
                  "div",
                  {
                    staticClass: "box",
                    staticStyle: { "margin-bottom": "15px" }
                  },
                  [
                    _c("h1", { staticClass: "subtitle" }, [_vm._v("Frascos")]),
                    _vm._v(" "),
                    _c("serbitec-oncologic-flasks-table", {
                      attrs: { flasks: _vm.order.requested_flasks }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticStyle: { "margin-top": "25px" },
                attrs: { position: "is-centered", grouped: "" }
              },
              [
                _c(
                  "p",
                  { staticClass: "control has-text-centered" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "button",
                        attrs: {
                          to: {
                            path: "/oncologic_orders_authorizer/edit",
                            query: { orderId: this.orderId }
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon" },
                          [
                            _c("font-awesome-icon", { attrs: { icon: "edit" } })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(" Editar orden")])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", { staticClass: "control has-text-centered" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button is-primary",
                      on: { click: _vm.authorizeMixture }
                    },
                    [
                      _vm._v(
                        "\n                    Autorizar orden\n                "
                      )
                    ]
                  )
                ])
              ]
            )
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }