<template>
    <div>
        <b-field
            label="Cliente"
            :type="{'is-danger' : selectedPayer.$error }"
            :message="[
                !selectedPayer.required && selectedPayer.$error ?
                'Se requiere introducir el o los clientes' :
                'Se puede seleccionar más de un cliente haciendo click y arrastrando sin levantar el dedo del botón hasta que la selección se encuentre sombreada. También se pueden seleccionar varios clientes de forma salteada dando click mientras se aprieta el botón CTRL en windows o CMDN en macOS'
            ]"
            expanded
        >
            <b-select
                v-model="selectedPayers"
                placeholder="Seleccione el cliente"
                multiple
                expanded
                @input="value => $emit('payer-selected', value)"
            >
                <option
                    v-for="payer in payers"
                    :key="payer.id"
                    :value="payer"
                >
                    {{ payer.invoicing_data.business_name }}
                </option>
            </b-select>
        </b-field>
    </div>
</template>

<script>

import gql from 'graphql-tag';
const query = gql` query
{
    payers {
        id
        invoicing_data {
            id
            business_name
        }
    }
}`;

export default {
    props: {
        selectedPayer: {
            type: Object,
            default: () => {}
        }
    },
    data () {
        return {
            selectedPayers: []
        };
    },
    apollo: {
        payers: {
            query: query,
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            }
        },
    }
};
</script>