<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <h1 class="title">Inventario</h1>
            </div>
            <div class="level-right">
                <a class="button" @click="addBatch">
                    <span class="icon">
                        <font-awesome-icon icon="plus" />
                    </span>
                    <span> Agregar lote</span>
                </a>
            </div>
        </nav>
        <hr>
        <b-field
            grouped
            group-multiline
        >
            <serbitec-search
                :search.sync="search"
                @clear-search="search = ''"
            />
            <b-field
            label="Almacén"
            label-position="on-border">
                <b-field>
                    <b-select
                    v-model="selectedClientPrefixId"
                    placeholder="Seleccione el almacén para filtrar"
                    rounded>
                        <option
                        v-for="client in clientPrefixes"
                        :value="client.id"
                        :key="client.id">
                            {{ client.payer.invoicing_data.business_name }}
                        </option>
                    </b-select>
                    <p class="control">
                        <b-button
                        icon-right="times-circle"
                        @click="selectedClientPrefixId = null"
                        rounded></b-button>
                    </p>
                </b-field>
            </b-field>
        </b-field>
        <serbitec-batches-table
            :warehouse-items="warehouseItems"
            :warehouse-items-count="warehouseItemsCount"

            :page.sync="page"
            :pageSize="pageSize"
            :sortField.sync="sortField"
            :sortOrder.sync="sortOrder"

            @page-change="onPageChange"
            @sort="onSort"
        />
    </div>
</template>

<script>
import serbitecSearch from '../../../components/dashboard/utils/search.vue';
import serbitecBatchesTable from '../../../components/dashboard/warehouse/batches-table.vue';
import serbitecAddBatchModal from '../../../components/dashboard/warehouse/add-batch-modal.vue';
import gql from 'graphql-tag';

const query = gql` query($search: String, $prefix_id: Int, $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String)
{
    external_medicine_warehouse_items(search: $search, prefix_id: $prefix_id, page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction) {
        id
        prefix {
            id
            prefix
        }
        batch
        expiry_date
        flasks_remaining_outside
        medicine_presentation {
            id
            component_quantity
            volume_in_ml
            medicine {
                id
                commercial_name
                manufacturer {
                    id
                    name
                }
                component_unit{
                    id
                    symbol
                }
                component {
                    id
                    component_type{
                        id
                    }
              	}
            }
        }
    }
    table_counts(count_type:"external_medicine_warehouse_items", search: $search, prefix_id: $prefix_id) {
        external_medicine_warehouse_items
    }
    clientPrefixes: warehouse_item_client_prefixes{
        id
        payer {
            id
            invoicing_data {
                id
                business_name
            }
        }
    }
}`;

export default {
    name:'index',
    components: {
        serbitecBatchesTable,
        serbitecSearch
    },
    beforeRouteEnter (to, _, next) {
        next((vm) => {
            vm.$apollo.queries.warehouseItems.refresh();
        });
    },
    data () {
        return {
            search: '',
            page: 0,
            pageSize: 10,
            sortField: 'id',
            sortOrder:'asc',
            warehouseItemsCount: 0,
            clientPrefixes: [],
            selectedClientPrefixId: null
        };
    },
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
        addBatch () {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecAddBatchModal,
                hasModalCard: true,
                trapFocus: true,
                fullScreen: true,
                events: {
                    'success': () => this.$apollo.queries.warehouseItems.refresh()
                }
            });
        }
    },
    apollo: {
        warehouseItems: {
            query: query,
            fetchPolicy: 'cache-and-network',
            variables () {
                return {
                    search: this.search,
                    prefix_id: this.selectedClientPrefixId,
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder,
                };
            },
            update (data) {
                this.warehouseItemsCount = data.table_counts.external_medicine_warehouse_items;
                if (data.clientPrefixes.find(client => client.id === -1) === undefined) {
                    const allClientPrefix = {
                        id: -1,
                        payer: {
                            invoicing_data: {
                                business_name: 'Serbitec'
                            }
                        }
                    };
                    data.clientPrefixes.unshift(allClientPrefix);   
                }
                this.clientPrefixes = data.clientPrefixes;
                return data.external_medicine_warehouse_items;
            },
            watchLoading (isLoading) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
        },
    }
};
</script>