var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-field",
    {
      attrs: {
        label: "Tipo de reporte",
        "label-position": "on-border",
        type: { "is-danger": _vm.selectedReportType.$error },
        message: {
          "Se requiere introducir el tipo de reporte":
            !_vm.selectedReportType.required && _vm.selectedReportType.$error
        },
        expanded: ""
      }
    },
    [
      _c(
        "b-select",
        {
          attrs: { placeholder: "Seleccione el tipo de reporte", expanded: "" },
          on: {
            input: function(reportType) {
              return _vm.$emit("report-type-selected", reportType)
            }
          }
        },
        _vm._l(_vm.types, function(type) {
          return _c("option", { key: type.id, domProps: { value: type } }, [
            _vm._v("\n            " + _vm._s(type.label) + "\n        ")
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }