var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav", { staticClass: "level" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "level-right" },
          [
            _c(
              "router-link",
              { staticClass: "button", attrs: { to: { path: "/payers" } } },
              [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [_c("font-awesome-icon", { attrs: { icon: "caret-left" } })],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v(" Regresar")])
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("serbitec-payer-form")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("Agregar cliente")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }