var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-autocomplete",
        {
          attrs: { data: _vm.payers, loading: _vm.autocompleteIsLoading },
          on: {
            select: function(option) {
              return _vm.selectPayer(option)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c("div", { staticClass: "media" }, [
                    _c("div", { staticClass: "media-content" }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(props.option.invoicing_data.business_name)
                        )
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("p", { staticClass: "has-text-weight-light" }, [
                        _vm._v(
                          "RFC: " + _vm._s(props.option.invoicing_data.rfc)
                        )
                      ])
                    ])
                  ])
                ]
              }
            }
          ]),
          model: {
            value: _vm.payerName,
            callback: function($$v) {
              _vm.payerName = $$v
            },
            expression: "payerName"
          }
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _vm._v("No se encontró el cliente...")
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }