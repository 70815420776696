var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-message", [
        _vm._v("\n        El volumen calculado al momento es: "),
        _c("strong", [_vm._v(_vm._s(_vm.computedTotalVolume) + " ml")]),
        _vm._v(".\n        "),
        _c("br"),
        _vm._v(" "),
        _c("small", [
          _vm._v(
            "Si desea que este sea al volumen total de la mezcla de click en el botón Calcular a un lado del campo del volumen total."
          )
        ])
      ]),
      _vm._v(" "),
      _vm.volumeValidations.waterIsMoreThan60percentTotalVolume
        ? _c("b-message", { staticClass: "is-danger" }, [
            _vm._v(
              "\n        La cantidad de agua inyectable es mayor al 60% del volumen.\n        "
            ),
            _c("br"),
            _vm._v(" "),
            _c("small", [
              _vm._v(
                "Modifique el volumen total o los componentes de la mezcla para ajustar."
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.volumeValidations.totalVolumeMinorToComputedVolume
        ? _c("b-message", { staticClass: "is-danger" }, [
            _vm._v(
              "\n        El volumen total introducido es menor al volumen calculado. El volumen total debe ser mayor o igual al volumen calculado para continuar.\n    "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.volumeValidations.notValueInTotalVolume
        ? _c("b-message", { staticClass: "is-danger" }, [
            _vm._v(
              "\n        Hace falta llenar el campo de volumen total.\n        "
            ),
            _c("br"),
            _vm._v(" "),
            _c("small", [
              _vm._v(
                "Favor de llenar el campo en la parte superior de la pantalla."
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }