var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-autocomplete",
    {
      attrs: {
        data: _vm.patients,
        loading: _vm.autocompleteIsLoading,
        disabled: _vm.disabled,
        expanded: ""
      },
      on: {
        select: function(option) {
          return _vm.$emit("patient-added", option)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function(props) {
            return [
              _c("div", { staticClass: "media" }, [
                _c("div", { staticClass: "media-content" }, [
                  _c("strong", [_vm._v(_vm._s(props.option.name))]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("p", { staticClass: "has-text-weight-normal" }, [
                    _vm._v(
                      "\n                    Diagnóstico: " +
                        _vm._s(props.option.diagnosis) +
                        "\n                "
                    )
                  ])
                ])
              ])
            ]
          }
        }
      ]),
      model: {
        value: _vm.patientName,
        callback: function($$v) {
          _vm.patientName = $$v
        },
        expression: "patientName"
      }
    },
    [
      _vm._v(" "),
      _c("template", { slot: "empty" }, [
        _vm._v("No se encontró el paciente...")
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }