var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.medicines,
            paginated: "",
            "backend-pagination": "",
            total: _vm.medicinesCount,
            "per-page": _vm.pageSize,
            "aria-next-label": "Siguiente página",
            "aria-previous-label": "Página anterior",
            "aria-page-label": "Página",
            "aria-current-label": "Página actual",
            "backend-sorting": "",
            "default-sort": [_vm.sortField, _vm.sortOrder]
          },
          on: { "page-change": _vm.changePage, sort: _vm.sort },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "props.row.commercial_name",
                        label: "Nombre comercial"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.commercial_name) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "props.row.component.name",
                        label: "Genérico"
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.component.name) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.tableType === "oncologic"
                    ? _c(
                        "b-table-column",
                        { attrs: { label: "Diluyentes", centered: "" } },
                        [
                          props.row.component.component_type.id === 1
                            ? _c(
                                "router-link",
                                {
                                  staticClass: "button is-light is-small",
                                  attrs: {
                                    to: {
                                      path: "thinners",
                                      query: { medicineId: props.row.id }
                                    },
                                    append: ""
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon is-medium" },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "tint" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  staticClass: "button is-light is-small",
                                  attrs: { disabled: "" }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon is-medium" },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "tint" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Presentaciones", centered: "" } },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "button is-light is-small",
                          attrs: {
                            to: {
                              path: "presentations",
                              query: { medicineId: props.row.id }
                            },
                            append: ""
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "prescription-bottle" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Editar", centered: "" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "button is-light is-small",
                          attrs: { append: "" },
                          on: {
                            click: function($event) {
                              return _vm.editMedicine(props.row)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "edit" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Eliminar", centered: "" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "button is-danger is-small",
                          attrs: { append: "" },
                          on: {
                            click: function($event) {
                              return _vm.deleteMedicine(props.row.id)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "trash-alt" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("No hay medicamentos registrados.")])
                ]
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }