<template>
    <div>
        <nav class="level is-mobile">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title">Nuevo pedido de mezclas de nutrición parenteral</h1>
                </div>
            </div>
            <div class="level-right" />
        </nav>
        <hr>
        <b-steps
            class="serbitec-small-top-margin"
            v-model="activeStep"
            :has-navigation="false"
            animated
            rounded
        >
            <b-step-item step="1" label="Datos generales">
                <serbitec-nutritional-mixtures-request-first-step
                    class="serbitec-medium-top-margin serbitec-medium-bottom-margin"
                    :key="key"
                    @next-step="activeStep = 1"
                />
            </b-step-item>

            <b-step-item step="2" label="Componentes de la mezcla">
                <serbitec-nutritional-mixtures-request-second-step
                    class="serbitec-medium-top-margin serbitec-medium-bottom-margin"
                    :key="key + 1"
                    @previous-step="activeStep = 0"
                    @next-step="activeStep = 2"
                />
            </b-step-item>

            <b-step-item step="3" label="Revisión del pedido">
                <serbitec-nutritional-mixtures-request-third-step
                    class="serbitec-medium-top-margin serbitec-medium-bottom-margin"
                    :key="key + 2"
                    @previous-step="activeStep = 1"
                    @submit-success="submitSuccess"
                />
            </b-step-item>
        </b-steps>
    </div>
</template>

<script>
import serbitecNutritionalMixturesRequestFirstStep from '../../../components/dashboard/requests/nutritional-mixtures/steps/first.vue';
import serbitecNutritionalMixturesRequestSecondStep from '../../../components/dashboard/requests/nutritional-mixtures/steps/second.vue';
import serbitecNutritionalMixturesRequestThirdStep from '../../../components/dashboard/requests/nutritional-mixtures/steps/third.vue';

export default {
    name: 'index',
    components: {
        serbitecNutritionalMixturesRequestFirstStep,
        serbitecNutritionalMixturesRequestSecondStep,
        serbitecNutritionalMixturesRequestThirdStep
    },
    data () {
        return {
            activeStep: 0,
            key: 0
        };
    },
    methods: {
        submitSuccess (orderId) {
            this.$buefy.dialog.alert({
                title: 'Gracias por su pedido',
                message: 'El pedido se ha realizado de manera exitosa. Tu número de pedido es: ON-' + orderId,
                type: 'is-success',
                confirmText: 'Aceptar'
            });
            this.forceUpdate();
        },
        forceUpdate () {
            this.activeStep = 0;
            this.key += Math.floor(Math.random() * Math.floor(1000000));
            this.$store.commit('nutritionalOrder/RESET_ORDER');
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.forceUpdate();
        });
    },
    beforeRouteLeave (to, from, next) {
       this.forceUpdate();
       next(); 
    }
};
</script>