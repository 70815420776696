<template>
    <div>
        <b-field
        label="Nombre del medicamento"
        :message="'Introduzca el nombre del medicamento y seleccione'">
            <b-autocomplete
                v-model="searchParam"
                :data="externalWarehouseItems"
                :loading="autocompleteIsLoading"
                @select="option => selectMedicine(option)">

                <template slot-scope="props">
                    <div class="media">
                        <div class="media-content">
                            <strong>{{ props.option.commercial_name }}</strong>
                            <br>
                            <p class="has-text-weight-normal is-small">
                                Genérico: {{ props.option.component.name }}
                                <br>
                                Fabricante: {{ props.option.manufacturer.name }}
                            </p>
                        </div>
                    </div>
                </template>

                <template slot="empty">No se encontró el medicamento en el almacén externo...</template>
            </b-autocomplete>
        </b-field>
    </div>
</template>

<script>
import gql from 'graphql-tag';
const query = gql` query ( $search: String, $is_oncologic: Boolean, $is_nutritional: Boolean )
{
    medicines( has_in_external_warehouse: true, search: $search, is_oncologic: $is_oncologic, is_nutritional: $is_nutritional ) {
        id
        commercial_name
        manufacturer {
            id
            name
        }
        component{
            id
            name
        }
    }
}`;

function initialDataState () {
    return {
        searchParam: null,
        autocompleteIsLoading: false
    };
}

export default {
    props:['internalWarehouseType'],
    data: initialDataState,
    methods: {
        async selectMedicine ( medicine ) {
            this.$emit('medicine-selected', medicine);
        },
    },
    computed: {
        isOncologic () {
            if ( this.internalWarehouseType == 'oncologic' ) {
                return true;
            } else {
                return null;
            }
        },
        isNutritional () {
            if ( this.internalWarehouseType == 'nutritional' ) {
                return true;
            } else {
                return null;
            }
        }
    },
    apollo: {
        externalWarehouseItems: {
            query: query,
            variables () {
                return {
                    search: this.searchParam,
                    is_oncologic: this.isOncologic,
                    is_nutritional: this.isNutritional
                };
            },
            update: data => data.medicines
        },
    }
};
</script>