<template>
    <div class="box">
        <nav class="level">
            <div class="level-left"></div>
        
            <div class="level-right">
                <span class="icon">
                    <font-awesome-icon icon="weight" />
                </span>
                <span>Peso del paciente: <strong>{{ patient.weight_in_kilograms }} Kg</strong></span>
            </div>
        </nav>
        <b-table
        :data="tableData">
            <template slot-scope="props">
                <b-table-column field="name">
                    {{ props.row.name }}
                </b-table-column>

                <b-table-column label="Gramos totales" centered>
                    {{ totalGramsForRow( props.row ) }}
                </b-table-column>

                <b-table-column label="Mililitros totales" centered>
                    {{ totalMlForRow( props.row ) }}
                </b-table-column>

                <b-table-column label="KCal" centered>
                    {{ kcalForRow(props.row) }}
                </b-table-column>

                <b-table-column v-if="totalVolume && totalVolume > 0" label="Concentración [%]" centered>
                    {{ concentrationForRow(props.row) }}
                </b-table-column>
            </template>
        </b-table>

        <br>
        <b-message v-if="!totalVolume || totalVolume == 0">
            Se requiere el volumen total para calcular la concentración y que sea mayor a 0.
        </b-message>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { totalMlForMacrocomponent, kcalForMacrocomponent, totalGramsForMacrocomponent } from '../../../../utils';

export default {
    props: ['tableData'],
    computed: {
        ...mapState({
            patient: state => state.nutritionalOrder.patient,
            totalVolume: state => state.nutritionalOrder.totalVolume,
        }),
    },
    methods: {
        totalGramsForRow ( row ) {
            return totalGramsForMacrocomponent ( row, this.patient.weight_in_kilograms );
        },
        totalMlForRow ( row ) {
            return totalMlForMacrocomponent ( row, this.patient.weight_in_kilograms );
        },
        kcalForRow (row) {
            return kcalForMacrocomponent ( row, this.patient.weight_in_kilograms );
        },
        concentrationForRow ( row ) {
            if ( row.quantity && row.componentTypeId == '7' )
            {
                return null;
            } else {
                return ( ( this.totalGramsForRow ( row ) / this.totalVolume ) * 100 ).toFixed(2);
            }
        }
    }
};
</script>