<template>
    <b-message>
        <nav class="level">
            <div class="level-left">
                <span class="icon is-small serbitec-small-right-margin">
                    <font-awesome-icon icon="user" />
                </span>
                <span>
                    Cliente:
                    <strong>{{ payer.invoicing_data.business_name }}</strong>
                </span>
            </div>

            <div class="level-right">
                <div v-if="!isFixed" class="field">
                    <a class="button" @click="$emit('payer-unselected')">
                        <span>Cambiar</span>
                        <span class="icon is-small">
                            <font-awesome-icon icon="exchange-alt" />
                        </span>
                    </a>
                </div>
            </div>
        </nav>
    </b-message>
</template>

<script>
export default {
    props: {
        isFixed: {
            type: Boolean,
            default: false
        },
        payer: {
            type: Object,
            default: () => {}
        }
    }
};
</script>