<template>
    <div>
        <nav class="level is-mobile">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title">Control de calidad de órdenes oncológicas</h1>
                </div>
            </div>
            <div class="level-right" />
        </nav>
        <hr>
        <div>
            <b-tabs type="is-toggle" expanded>
                <b-tab-item>
                    <template slot="header">
                        <span> Mezclas <b-tag style="margin-left:10px"> {{ mixturesCount }} </b-tag> </span>
                    </template>
                    <serbitec-quality-mixtures-table
                    :quality-type="'oncologic'"
                    :mixtures="mixtures"
                    :mixtures-count="mixturesCount"

                    :loading.sync="loading"
                    :page.sync="page"
                    :pageSize="pageSize"
                    :sortField.sync="sortField"
                    :sortOrder.sync="sortOrder"

                    @page-change="onPageChange"
                    @sort="onSort"
                    @print-attempt="updatePrintAttempts"
                    @mixture-aconditioned="mixtureAconditioned"></serbitec-quality-mixtures-table>
                </b-tab-item>
                <b-tab-item>
                    <template slot="header">
                        <span> Frascos <b-tag style="margin-left:10px"> {{ flasksCount }} </b-tag> </span>
                    </template>
                    <serbitec-quality-flasks-table
                    :quality-type="'oncologic'"
                    :flasks="flasks"
                    :flasks-count="flasksCount"

                    :loading.sync="loading"
                    :page.sync="flasksPage"
                    :pageSize="flasksPageSize"
                    :sortField.sync="flasksSortField"
                    :sortOrder.sync="flasksSortOrder"

                    @page-change="onFlasksPageChange"
                    @sort="onFlasksSort"
                    @flask-aconditioned="flaskAconditioned"></serbitec-quality-flasks-table>
                </b-tab-item>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import gql from 'graphql-tag';
const query = gql` query ( $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String, $flasks_page: Int, $flasks_results_per_page: Int, $flasks_order_by: String, $flasks_order_direction: String )
{
    mixtures: oncologic_mixtures( mixture_state_ids: "5,6", page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction ) {
        id
        printed_opportunities_used
        order{
            id
            delivery_date
            patient{
                id
                name
            }
            medic {
                id
                payer {
                    id
                    invoicing_data {
                        id
                        business_name
                    }
                }
            }
        }
        state {
            id
        }
    }
    flasks: requested_flasks( mixture_state_ids: "5,6", page: $flasks_page, results_per_page: $flasks_results_per_page, order_by: $flasks_order_by, order_direction: $flasks_order_direction ) {
        id
        order{
            id
            delivery_date
            patient{
                id
                name
            }
        }
        state {
            id
        }
    }
    mixturesCount: table_counts( count_type: "oncologic_mixtures", mixture_state_ids: "5,6" ) {
        oncologic_mixtures
    }
    flasksCount: table_counts( count_type: "requested_flasks", mixture_state_ids: "5,6" ) {
        requested_flasks
    }
}`;

import serbitecQualityMixturesTable from '../../../components/dashboard/quality-control/quality-mixtures-table';
import serbitecQualityFlasksTable from '../../../components/dashboard/quality-control/quality-flasks-table.vue';

function initialDataState () {
    return {
        loading: false,
        mixturesCount: null,
        page: 0,
        pageSize: 10,
        sortField: 'id',
        sortOrder:'desc',
        flasks: [],
        flasksCount: null,
        flasksPage: 0,
        flasksPageSize: 10,
        flasksSortField: 'id',
        flasksSortOrder:'desc',
    };
}

export default {
    components: {
        serbitecQualityMixturesTable,
        serbitecQualityFlasksTable
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if ( from.path == '/oncologic_orders_quality_control/mixture_inspection' && to.query.success ) {
                vm.$notify({
                    title: '¡Mezcla aprobada!',
                    type: 'success',
                    text: 'Se ha aprobado la mezcla oncológica'
                });
            }
            if ( from.path == '/oncologic_orders_quality_control/mixture_inspection' && to.query.rejected ) {
                vm.$notify({
                    title: '¡Mezcla rechazada!',
                    type: 'error',
                    text: 'Se ha rechazado la mezcla oncológica'
                });
            }
            if ( from.path == '/oncologic_orders_quality_control/flask_inspection' && to.query.success ) {
                vm.$notify({
                    title: 'Frasco aprobado!',
                    type: 'success',
                    text: 'Se ha aprobado el frasco oncológico'
                });
            }
            if ( from.path == '/oncologic_orders_quality_control/flask_inspection' && to.query.rejected ) {
                vm.$notify({
                    title: 'Frasco rechazado!',
                    type: 'error',
                    text: 'Se ha rechazado el frasco oncológico'
                });
            }
        });
    },
    data: initialDataState,
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
        onFlasksPageChange( page ) {
            this.flasksPage = page - 1;
        },
        onFlasksSort( field, order ) {
            this.flasksSortField = field;
            this.flasksSortOrder = order;
        },
        updatePrintAttempts ( mixtureId ) {
            var url = '/api/oncologic/mixtures/' + mixtureId + '/register_printed';
            this.makeRequest( url, axios.post, [], 'print-attempt' );
        },
        mixtureAconditioned ( mixtureId ) {
            var url = '/api/oncologic/mixtures/' + mixtureId + '/state';
            const requestParams = {
                new_state_id: 9
            };
            this.makeRequest( url, axios.post, requestParams, 'mixture-aconditioned' );
        },
        flaskAconditioned ( flaskId ) {
            var url = '/api/oncologic/flasks/' + flaskId + '/state';
            const requestParams = {
                new_state_id: 9
            };
            this.makeRequest( url, axios.post, requestParams, 'flask-aconditioned' );
        },
        makeRequest ( url, method, params, comesFrom ) {
            this.loading = true;
            method( url, params )
            .then(function ( ) {
                this.$apollo.queries.mixtures.refetch();
                if ( comesFrom == 'print-attempt' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha generado la etiqueta'
                    });
                } else if ( comesFrom == 'mixture-aconditioned' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha acondicionado la mezcla'
                    });
                } else if ( comesFrom == 'flask-aconditioned' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha acondicionado el frasco'
                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
            this.loading = false;
        }
    },
    apollo: {
        mixtures: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder,
                    flasks_page: this.flasksPage,
                    flasks_results_per_page: this.flasksPageSize,
                    flasks_order_by: this.flasksSortField,
                    flasks_order_direction: this.flasksSortOrder,
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update ( data ) {
                this.mixturesCount = data.mixturesCount.oncologic_mixtures;
                this.flasksCount = data.flasksCount.requested_flasks;
                this.flasks = data.flasks;
                return data.mixtures;
            }
        },
    }
};
</script>