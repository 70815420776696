<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right">
                <router-link
                    :to="{ path: 'deleted', query: { payer_id: payerId } }"
                    class="button serbitec-small-right-margin"
                    append
                >
                    <span class="icon">
                        <font-awesome-icon icon="user-times" />
                    </span>
                    <span> Usuarios eliminados</span>
                </router-link>
                <a
                @click="addPayerUser"
                class="button serbitec-small-right-margin">
                    <span class="icon">
                        <font-awesome-icon icon="plus" />
                    </span>
                    <span> Agregar usuario</span>
                </a>
            </div>
        </nav>
        <hr>
        <serbitec-payer-info
        :payer-id="payerId"></serbitec-payer-info>
        <br>
        <div v-if="addUserFormIsActive">
            <serbitec-user-form
            :payer-id="payerId"
            :has-close-button="true"
            @close-form="addUserFormIsActive = false"
            @success="userAdded"
            @error="userAddedError"></serbitec-user-form>
            <br>
        </div>
        <serbitec-payer-users-table
        :payer-id="payerId"
        :payerUsers="users"
        :payer-users-count="payerUsersCount"

        :loading.sync="loading"
        :page.sync="page"
        :pageSize="pageSize"
        :sortField.sync="sortField"
        :sortOrder.sync="sortOrder"

        @page-change="onPageChange"
        @sort="onSort"
        @reset-password="resetPassword"
        @delete-user="deleteUser"></serbitec-payer-users-table>
    </div>
</template>

<script>
import serbitecPayerUsersTable from '../../../components/dashboard/payers/payer-users-table.vue';
import serbitecPayerInfo from '../../../components/dashboard/payers/payer-info.vue';
import serbitecUserForm from '../../../components/dashboard/payers/user-form.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';

import axios from 'axios';
import gql from 'graphql-tag';
const query = gql` query ( $payer_id: Int!, $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String )
{
    users( payer_id: $payer_id, page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction ) {
        id
        first_name
        last_name_1
        last_name_2
        email
        types {
            id
            name
        }
    }
    payerUsersCount: table_counts( count_type: "users", payer_id: $payer_id ) {
        users
    }
    payerMedicinesCount: table_counts( count_type:"payer_medicines", payer_id: $payer_id, enabled: true ) {
        payer_medicines
    }
}`;

export default {
    components: {
        serbitecPayerUsersTable,
        serbitecPayerInfo,
        serbitecUserForm,
        serbitecBreadcumbs
    },
    data (){
        return {
            payerId: this.$route.query.payer_id,
            payerUsersCount: null,
            loading: false,
            page: 0,
            pageSize: 10,
            sortField: 'first_name',
            sortOrder:'asc',
            addUserFormIsActive: false,
            payerMedicinesCount: null,
            route: [
                {
                    label: 'Clientes',
                    to: '/payers',
                    active: false
                },
                {
                    label: 'Usuarios',
                    to: '/payers/users',
                    active: true
                }
            ]
        };
    },
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
        addPayerUser(){
            if ( this.payerMedicinesCount > 0 ) {
                this.addUserFormIsActive = true;
            } else {
                var dialogMessage = '¿Seguro que deseas agregar un usuario? <br> <small>No tienes ningún medicamento dado de alta para este usuario. Si el usuario accede al sistema no podrá realizar órdenes.</small>';
                this.$buefy.dialog.confirm({
                    title: 'Agregar usuario',
                    message: dialogMessage,
                    confirmText: 'Continuar',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.addUserFormIsActive = true
                });
            }
        },
        userAdded(){
            this.addUserFormIsActive = false;
            this.$notify({
                title: '¡Éxito!',
                type: 'success',
                text: 'Se ha añadido el usuario correctamente'
            });
            this.$apollo.queries.users.refetch();
        },
        userAddedError(){
            this.$notify({
                title: '¡Algo ha sucedido!',
                type: 'error',
                text: 'Contacte el administrador'
            });
        },
        resetPassword( userId ){
            this.makeRequest('/api/admin/users/' + userId + '/password_reset', axios.post, {}, 'resetPassword');
        },
        deleteUser( userId ){
            this.makeRequest('/api/users/' + userId, axios.delete, {}, 'deleteUser');
        },
        async makeRequest( url, method, params, comesFrom ){
            this.loading = true;
            try {
                await method(url,params);
                if ( comesFrom == 'resetPassword' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha añadido el usuario correctamente'
                    });
                }
                if ( comesFrom == 'deleteUser' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha eliminado el usuario correctamente'
                    });
                }
                this.$apollo.queries.users.refetch();
                this.loading = false;
            } catch (error) {
                console.error(error);
                this.$notify({
                    title: '¡Algo ha sucedido!',
                    type: 'error',
                    text: 'Contacte el administrador'
                });
                this.loading = false;
            }
        }
    },
    apollo: {
        users: {
            fetchPolicy: 'network-only',
            query: query,
            variables () {
                return {
                    payer_id: this.payerId,
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                this.payerUsersCount = data.payerUsersCount.users;
                this.payerMedicinesCount = data.payerMedicinesCount.payer_medicines;
                return data.users;
            }
        },
    }
};
</script>