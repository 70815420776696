<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                {{ comesFromEdit ? 'Editar usuario interno' : 'Agregar usuario interno' }}
            </p>
        </header>
        <section class="modal-card-body">
            <b-field
                grouped
            >
                <b-field
                    label="Nombre"
                    :type="{'is-danger' : $v.name.$error }"
                    :message="{
                        'Se requiere introducir el nombre del usuario interno' : !$v.name.required && $v.name.$error
                    }"
                    expanded>
                    <b-input
                        v-model="$v.name.$model"
                        type="text">
                    </b-input>
                </b-field>
                <b-field
                    label="Apellido paterno"
                    :type="{'is-danger' : $v.lastName1.$error }"
                    :message="{
                        'Se requiere introducir el apellido paterno del usuario interno' : !$v.lastName1.required && $v.lastName1.$error
                    }"
                    expanded>
                    <b-input
                        v-model="$v.lastName1.$model"
                        type="text">
                    </b-input>
                </b-field>
                <b-field
                    label="Apellido materno"
                    :type="{'is-danger' : $v.lastName2.$error }"
                    :message="{
                        'Se requiere introducir el apellido materno del usuario interno' : !$v.lastName2.required && $v.lastName2.$error
                    }"
                    expanded>
                    <b-input
                        v-model="$v.lastName2.$model"
                        type="text">
                    </b-input>
                </b-field>
            </b-field>
            <b-field
                label="Correo electrónico"
                :type="{'is-danger' : $v.email.$error }"
                :message="{
                    'Se requiere introducir el correo electrónico del usuario interno' : !$v.email.required && $v.email.$error
                }"
                expanded>
                <b-input
                    v-model.trim="$v.email.$model"
                    type="text">
                </b-input>
            </b-field>
            <b-field
                label="Tipo de usuario"
                expanded
            >
                <b-field>
                    <b-radio
                        v-model="type"
                        name="type"
                        :native-value="4"
                        type="is-primary"
                    >
                        Administrador central de mezclas
                    </b-radio>
                    <b-radio
                        v-model="type"
                        name="type"
                        :native-value="5"
                        type="is-primary"
                    >
                        Coordinador central de mezclas
                    </b-radio>
                    <b-radio
                        v-model="type"
                        name="type"
                        :native-value="6"
                        type="is-primary"
                    >
                        Operativo técnico
                    </b-radio>
                    <b-radio
                        v-model="type"
                        name="type"
                        :native-value="7"
                        type="is-primary"
                    >
                        Operativo químico
                    </b-radio>
                </b-field>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                Cancelar
            </button>
            <button class="button is-primary" @click="submitForm">
                {{ comesFromEdit ? 'Editar' : 'Agregar' }}
            </button>
        </footer>
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';

export default {
    props: {
        internalUser: {
            type: Object,
            default: () => {}
        }
    },
    beforeMount () {
        if ( this.internalUser && Object.keys(this.internalUser).length !== 0 && this.internalUser.constructor === Object ) {
            this.name = this.internalUser.first_name;
            this.lastName1 = this.internalUser.last_name_1;
            this.lastName2 = this.internalUser.last_name_2;
            this.email = this.internalUser.email;
            this.type = this.internalUser.types[0].id;
        }
    },
    data () {
        return {
            name: '',
            lastName1: '',
            lastName2: '',
            email: '',
            type: 6
        };
    },
    computed: {
        comesFromEdit () {
            return (this.internalUser && Object.keys(this.internalUser).length !== 0 && this.internalUser.constructor === Object) ? true: false;
        }
    },
    methods: {
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                if (this.internalUser && Object.keys(this.internalUser).length !== 0 && this.internalUser.constructor === Object) {
                    this.editInternalUser(this.internalUser);
                } else {
                    this.addInternalUser();
                }
            }
        },
        getParams () {
            return {
                first_name: this.name,
                last_name_1: this.lastName1,
                last_name_2: this.lastName2,
                email: this.email,
                internal_user_type_id: this.type
            };
        },
        addInternalUser () {
            var url = '/api/internal_users';
            this.makeRequest(url, this.$axios.post, this.getParams());
        },
        editInternalUser () {
            var url = '/api/internal_users/' + this.internalUser.id;
            this.makeRequest(url, this.$axios.put, this.getParams());
        },
        makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method(url,params)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: this.comesFromEdit ? 'Se ha editado el usuario interno' : 'Se ha agregado el usuario interno'
                });
                this.$serbitec.finishLoading();
                this.$emit('success');
                this.$emit('close');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    },
    validations: {
        name: {
            required
        },
        lastName1: {
            required
        },
        lastName2: {
            required
        },
        email: {
            required,
            email
        }
    }
};
</script>