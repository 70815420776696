var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("nav", { staticClass: "level" }, [
        _c("div", { staticClass: "level-left" }),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "span",
            { staticClass: "icon" },
            [_c("font-awesome-icon", { attrs: { icon: "weight" } })],
            1
          ),
          _vm._v(" "),
          _c("span", [
            _vm._v("Peso del paciente: "),
            _c("strong", [
              _vm._v(_vm._s(_vm.patient.weight_in_kilograms) + " Kg")
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("b-table", {
        attrs: { data: _vm.tableData },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c("b-table-column", { attrs: { field: "name" } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(props.row.name) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Gramos totales", centered: "" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.totalGramsForRow(props.row)) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Mililitros totales", centered: "" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.totalMlForRow(props.row)) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "KCal", centered: "" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.kcalForRow(props.row)) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.totalVolume && _vm.totalVolume > 0
                  ? _c(
                      "b-table-column",
                      { attrs: { label: "Concentración [%]", centered: "" } },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.concentrationForRow(props.row)) +
                            "\n            "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      !_vm.totalVolume || _vm.totalVolume == 0
        ? _c("b-message", [
            _vm._v(
              "\n        Se requiere el volumen total para calcular la concentración y que sea mayor a 0.\n    "
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }