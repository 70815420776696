<template>
    <div v-if="macronutrients">
        <b-loading :active.sync="loading"></b-loading>
        <h1 class="subtitle">Macronutrientes</h1>
        <serbitec-macronutrients-table :tableData="macronutrients"></serbitec-macronutrients-table>
        <form
        @submit.prevent="submitForm"
        novalidate="true">
          <div v-for="( component, index ) in macronutrients" :key="index" style="margin-bottom:15px">
            <b-field
            v-if="component.componentsAvailable.length > 0"
            :message="[ (component.componentTypeId != 7) ? 'Seleccione el componente e introduzca la cantidad del componente en ml, g o g/Kg' : 'Seleccione el componente e introduzca la cantidad de bolsas deseada']"
            :label="component.name"
            expanded>
              <b-field>
                <p
                v-if="component.selectedComponent"
                class="control">
                    <span class="button" @click="unselectComponent(component.id)">
                        <span class="icon is-small">
                            <font-awesome-icon icon="times-circle" />
                        </span>
                    </span>
                </p>
                <b-select 
                  :value="component.selectedComponent"
                  @input="updateSelectedComponent( $event, component.id )"
                  placeholder="Introduzca el componente"
                  expanded>
                  <option
                    v-for="option in component.componentsAvailable"
                    :value="option"
                    :key="option.id">
                    {{ option.name }}
                  </option>
                </b-select>
                <b-input
                v-if="component.selectedComponent && component.componentTypeId != 7"
                name="component.quantity"
                :value="component.quantity"
                @input="updateComponentQuantity( $event, component.id )"
                v-cleave="masks.custom"
                type="text"></b-input>
                <b-input
                v-else-if="component.selectedComponent"
                name="component.quantity"
                :value="component.quantity"
                @input="updateComponentQuantity( $event, component.id )"
                v-cleave="masks.pieces"
                type="text"></b-input>
                <b-select
                v-if="component.selectedComponent && component.componentTypeId != 7"
                :value="component.unitSymbol"
                @input="updateSelectedComponentUnit( $event, component.id )"
                name="unitSymbol"
                >
                  <option v-bind:value="'ml'">ml</option>
                  <option v-bind:value="'g'">g</option>
                  <option v-bind:value="'g/Kg'">g/Kg</option>
                </b-select>
                <p v-else-if="component.selectedComponent" class="control">
                    <span class="button is-static">bolsa</span>
                </p>
              </b-field>
            </b-field>
          </div>
        </form>
    </div>
</template>

<script>
import serbitecMacronutrientsTable from './macronutrients-table.vue';
import { mapState } from 'vuex';
import Cleave from 'cleave.js';

const cleave = {
    name: 'cleave',
    bind(el, binding) {
        const input = el.querySelector('input');
        input._vCleave = new Cleave(input, binding.value);
    },
    unbind(el) {
        const input = el.querySelector('input');
        input._vCleave.destroy();
    }
};

export default {
    directives: { cleave },
    components: {
      serbitecMacronutrientsTable
    },
    data () {
        return {
          loading: false,
          masks: {
            custom: {
                numeral: true,
                numeralThousandsGroupStyle: 'none',
                numeralPositiveOnly: true
            },
            pieces: {
                numeral: true,
                numeralThousandsGroupStyle: 'none',
                numeralPositiveOnly: true,
                numeralDecimalScale: 0
            }
          }
        };
    },
    methods: {
      unselectComponent( componentId ) {
        this.$store.commit( 'nutritionalOrder/UNSELECT_COMPONENT', componentId );
      },
      updateSelectedComponent( selectedComponent, componentId ) {
        const data = {
          id: componentId,
          selectedComponent: selectedComponent
        };
        this.$store.commit( 'nutritionalOrder/SET_SELECTED_COMPONENT_ITEM', data );
      },
      updateComponentQuantity( quantity, componentId ) {
        const data = {
          id: componentId,
          quantity: quantity
        };
        this.$store.commit( 'nutritionalOrder/SET_SELECTED_COMPONENT_ITEM', data );
      },
      updateSelectedComponentUnit( selectedUnitSymbol, componentId ) {
        const data = {
          id: componentId,
          unitSymbol: selectedUnitSymbol
        };
        this.$store.commit( 'nutritionalOrder/SET_SELECTED_COMPONENT_ITEM', data );
      }
    },
    computed: {
      ...mapState({
        macronutrients: state => state.nutritionalOrder.macronutrients
      }),
    }
};
</script>