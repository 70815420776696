<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <div class="level-left">
                    <serbitec-breadcumbs
                        :route="route"
                    />
                </div>
            </div>
            <div class="level-right">
                <a class="button" @click="addPresentation">
                    <span class="icon">
                        <font-awesome-icon icon="plus" />
                    </span>
                    <span> Agregar presentación</span>
                </a>
            </div>
        </nav>
        <hr>
        <serbitec-medicine-info
            :medicine-id="medicineId"
        />
        <serbitec-presentations-table
            v-if="medicine"
            class="serbitec-medium-top-margin"
            :presentations="medicine.presentations"
            @reload-presentations="$apollo.queries.medicine.refresh()"
        />
    </div>
</template>

<script>
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';
import serbitecMedicineInfo from '../../../components/dashboard/utils/medicine-info.vue';
import serbitecPresentationsTable from '../../../components/dashboard/medicines-catalog/presentations-table.vue';
import serbitecAddPresentationModal from '../../../components/dashboard/medicines-catalog/add-presentation-modal.vue';
import gql from 'graphql-tag';

const query = gql` query ( $id: Int! )
{
    medicine( id: $id ) {
        id
        component_unit {
            id
            symbol
        }
        presentations {
            id
            ean_code
            component_quantity
            volume_in_ml
            medicine {
                id
                component_unit {
                    id
                    symbol
                }
            }
        }
    }
}`;

export default {
    components: {
        serbitecBreadcumbs,
        serbitecMedicineInfo,
        serbitecPresentationsTable
    },
    props: {
        medicineId: {
            type: [String, Number],
            default: 0 | ''
        }
    },
    data () {
        return {
            route: [
                {
                    label: 'Catálogo de medicamentos nutritionales',
                    to: '/nutritional_catalog',
                    active: false
                },
                {
                    label: 'Presentaciones',
                    to: '/nutritional_catalog/presentations',
                    active: true
                }
            ]
        };
    },
    methods: {
        addPresentation () {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecAddPresentationModal,
                hasModalCard: true,
                trapFocus: true,
                props: {
                    medicineId: this.medicineId,
                    medicineComponentUnitSymbol: this.medicine.component_unit.symbol
                },
                events: {
                    'success': this.presentationAdded
                }
            });
        },
        presentationAdded () {
            this.$apollo.queries.medicine.refresh();
        }
    },
    apollo: {
        medicine: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    id: this.medicineId
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            }
        },
    }
};
</script>