var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.thinners,
            detailed: "",
            "detail-key": "id",
            "mobile-cards": true
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    { attrs: { field: "name", label: "Nombre" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.name) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Eliminar", centered: "" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "button is-small is-danger",
                          on: {
                            click: function($event) {
                              return _vm.confirmDeleteMedicine(
                                props.row.pivot.id
                              )
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "trash-alt" }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]
              }
            },
            {
              key: "detail",
              fn: function(props) {
                return [
                  _c("div", { staticClass: "content" }, [
                    _c("p", [
                      _c("strong", [
                        _vm._v("Estabilidad a temperatura ambiente:")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(props.row.pivot.stability_ambient_in_hours) +
                          "\n                    "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("strong", [_vm._v("Estabilidad en refrigeración:")]),
                      _vm._v(
                        " " +
                          _vm._s(props.row.pivot.stability_cooled_in_hours) +
                          "\n                    "
                      ),
                      props.row.pivot.min_recommended_concentration != null
                        ? _c("span", [
                            _c("br"),
                            _vm._v(" "),
                            _c("strong", [
                              _vm._v("Concentración mínima recomendada:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  props.row.pivot.min_recommended_concentration
                                ) +
                                "\n                    "
                            )
                          ])
                        : _c("span", [
                            _c("br"),
                            _vm._v(" "),
                            _c("i", [
                              _vm._v(
                                "No hay datos de concentración mínima recomendada"
                              )
                            ])
                          ]),
                      _vm._v(" "),
                      props.row.pivot.max_recommended_concentration != null
                        ? _c("span", [
                            _c("br"),
                            _vm._v(" "),
                            _c("strong", [
                              _vm._v("Concentración máxima recomendada:")
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  props.row.pivot.max_recommended_concentration
                                ) +
                                "\n                    "
                            )
                          ])
                        : _c("span", [
                            _c("br"),
                            _vm._v(" "),
                            _c("i", [
                              _vm._v(
                                "No hay datos de concentración máxima recomendada"
                              )
                            ])
                          ]),
                      _vm._v(" "),
                      props.row.pivot.min_infusion_speed &&
                      props.row.pivot.max_infusion_speed &&
                      props.row.pivot.infusion_speed_unit
                        ? _c("span", [
                            _c("br"),
                            _vm._v(" "),
                            _c("strong", [_vm._v("Velocidad de infusión:")]),
                            _vm._v(
                              " " +
                                _vm._s(props.row.pivot.min_infusion_speed) +
                                " (mínima) y " +
                                _vm._s(props.row.pivot.max_infusion_speed) +
                                " (máxima) " +
                                _vm._s(
                                  props.row.pivot.infusion_speed_unit.symbol
                                ) +
                                "\n                    "
                            )
                          ])
                        : _c("span", [
                            _c("br"),
                            _vm._v(" "),
                            _c("i", [
                              _vm._v("No hay datos de velocidad de infusión")
                            ])
                          ]),
                      _vm._v(" "),
                      props.row.pivot.reference !== ""
                        ? _c("span", [
                            _c("br"),
                            _vm._v(" "),
                            _c("strong", [_vm._v("Referencia:")]),
                            _vm._v(
                              " " +
                                _vm._s(props.row.pivot.reference) +
                                "\n                    "
                            )
                          ])
                        : _c("span", [
                            _c("br"),
                            _vm._v(" "),
                            _c("i", [_vm._v("No hay datos de referencia")])
                          ])
                    ])
                  ])
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v("No hay diluyentes asociados a este medicamento")
                  ])
                ]
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }