var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function($event) {
            _vm.loading = $event
          }
        }
      }),
      _vm._v(" "),
      _c("nav", { staticClass: "level" }, [
        _c(
          "div",
          { staticClass: "level-left" },
          [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" })
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.oncologicMixture && _vm.itemsInOncologicWarehouse
        ? _c(
            "div",
            [
              _c("div", { staticClass: "box" }, [
                _c("h1", { staticClass: "title has-text-centered" }, [
                  _vm._v(
                    "Orden: OO-" +
                      _vm._s(_vm.oncologicMixture.order.id) +
                      " | Lote: LO-" +
                      _vm._s(_vm.mixtureId)
                  )
                ])
              ]),
              _vm._v(" "),
              _c("serbitec-horizontal-order-data", {
                attrs: {
                  medic: _vm.oncologicMixture.order.medic,
                  patient: _vm.oncologicMixture.order.patient
                }
              }),
              _vm._v(" "),
              _vm.isTransfer
                ? _c("div", { staticClass: "box" }, [
                    _c("nav", { staticClass: "level serbitec-small-bottom" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("div", { staticClass: "level-right" }, [
                        _c(
                          "p",
                          { staticClass: "has-text-success" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "check-circle" }
                            }),
                            _vm._v(" Completado")
                          ],
                          1
                        )
                      ])
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.itemsInOncologicWarehouse, function(element, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "box" },
                  [
                    (!element.isMedicine &&
                      element.remainingQuantity != element.componentQuantity) ||
                    (element.isMedicine &&
                      element.remainingQuantity != element.componentQuantity)
                      ? _c(
                          "nav",
                          { staticClass: "level serbitec-narrow-bottom" },
                          [
                            _c("div", { staticClass: "level-left" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "level-right" }, [
                              element.isCompleted
                                ? _c(
                                    "p",
                                    { staticClass: "has-text-success" },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "check-circle" }
                                      }),
                                      _vm._v(" Completado")
                                    ],
                                    1
                                  )
                                : element.isMedicine
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "is-size-6 has-text-danger"
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "exclamation-circle" }
                                      }),
                                      _vm._v(
                                        " Faltan " +
                                          _vm._s(element.remainingQuantity) +
                                          " " +
                                          _vm._s(element.quantityUnit)
                                      )
                                    ],
                                    1
                                  )
                                : !element.isMedicine
                                ? _c(
                                    "p",
                                    {
                                      staticClass: "is-size-6 has-text-danger"
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "exclamation-circle" }
                                      }),
                                      _vm._v(
                                        " Faltan " +
                                          _vm._s(
                                            _vm.setItemQuantity(
                                              element.remainingQuantity,
                                              element.isPiece
                                            )
                                          )
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("nav", { staticClass: "level serbitec-small-bottom" }, [
                      _c("div", { staticClass: "level-left is-mobile" }, [
                        element.isMedicine
                          ? _c(
                              "div",
                              [
                                _c("serbitec-edit-medicine", {
                                  attrs: {
                                    "payer-id":
                                      _vm.oncologicMixture.order.medic.payer.id,
                                    "medicine-component-id":
                                      element.componentId,
                                    "component-name": element.componentName,
                                    "medicine-id": element.medicineId,
                                    "mixture-medicine-id":
                                      element.mixtureMedicineId,
                                    "medicine-name": element.medicineName
                                  },
                                  on: {
                                    "medicine-edited": _vm.medicineEdited,
                                    "medicine-not-edited": _vm.medicineNotEdited
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "p",
                              { staticClass: "has-text-weight-bold is-size-5" },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(element.componentName) +
                                    "\n                    "
                                )
                              ]
                            )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "level-right" }, [
                        element.isMedicine && element.quantityToMlFactor
                          ? _c("p", { staticClass: "is-size-4" }, [
                              _vm._v(
                                _vm._s(element.componentQuantity) +
                                  " " +
                                  _vm._s(element.quantityUnit) +
                                  " | " +
                                  _vm._s(
                                    (
                                      element.componentQuantity *
                                      element.quantityToMlFactor
                                    ).toFixed(2)
                                  ) +
                                  " ml"
                              )
                            ])
                          : element.isMedicine
                          ? _c("p", { staticClass: "is-size-4" }, [
                              _vm._v(
                                _vm._s(element.componentQuantity) +
                                  " " +
                                  _vm._s(element.quantityUnit)
                              )
                            ])
                          : _c("p", { staticClass: "is-size-4" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.setItemQuantity(
                                    element.componentQuantity,
                                    element.isPiece
                                  )
                                )
                              )
                            ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-table",
                      {
                        attrs: {
                          data: _vm.isEmpty
                            ? []
                            : element.oncologicWarehouseItems,
                          loading: _vm.tableLoading,
                          checkable: "",
                          "checked-rows": element.checkedRows,
                          "header-checkable": false,
                          "is-row-checkable": _vm.enableCheckRow,
                          "default-sort": "expiry_date",
                          "default-sort-direction": "asc",
                          narrowed: ""
                        },
                        on: {
                          "update:checkedRows": function($event) {
                            return _vm.$set(element, "checkedRows", $event)
                          },
                          "update:checked-rows": function($event) {
                            return _vm.$set(element, "checkedRows", $event)
                          },
                          check: _vm.updateItemInOncologicWarehouse
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(props) {
                                return [
                                  _c(
                                    "b-table-column",
                                    { attrs: { label: "Lote", numeric: "" } },
                                    [
                                      _c("p", [
                                        props.row.prefix
                                          ? _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  props.row.prefix.prefix
                                                ) + "-"
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm._v(" " + _vm._s(props.row.batch))
                                      ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-table-column",
                                    { attrs: { label: "Nombre" } },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            props.row.medicine_presentation
                                              .medicine.commercial_name
                                          ) +
                                          "\n                        "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !element.isPiece
                                    ? _c(
                                        "b-table-column",
                                        {
                                          attrs: {
                                            label: "Presentación",
                                            centered: ""
                                          }
                                        },
                                        [
                                          element.isMedicine
                                            ? _c("b-tag", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.row
                                                      .medicine_presentation
                                                      .component_quantity
                                                  ) +
                                                    " " +
                                                    _vm._s(
                                                      props.row
                                                        .medicine_presentation
                                                        .medicine.component_unit
                                                        .symbol
                                                    ) +
                                                    " en " +
                                                    _vm._s(
                                                      props.row
                                                        .medicine_presentation
                                                        .volume_in_ml
                                                    ) +
                                                    " ml"
                                                )
                                              ])
                                            : _c("b-tag", [
                                                _vm._v(
                                                  _vm._s(
                                                    props.row
                                                      .medicine_presentation
                                                      .volume_in_ml
                                                  ) + " ml"
                                                )
                                              ])
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "b-table-column",
                                    {
                                      attrs: {
                                        field: "expiry_date",
                                        label: "Fecha de caducidad",
                                        sortable: "",
                                        centered: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(props.row.expiry_date) +
                                          "\n                        "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-table-column",
                                    {
                                      attrs: {
                                        label: "Cantidad en almacén",
                                        centered: ""
                                      }
                                    },
                                    [
                                      element.isMedicine
                                        ? _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.quantityInMedicineUnit(
                                                  props.row
                                                    .quantity_in_oncologic_preparer_warehouse_in_microunits /
                                                    1000,
                                                  props.row
                                                    .medicine_presentation
                                                )
                                              ) +
                                                " " +
                                                _vm._s(element.quantityUnit)
                                            )
                                          ])
                                        : _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.setItemQuantity(
                                                  props.row
                                                    .quantity_in_oncologic_preparer_warehouse_in_microunits,
                                                  element.isPiece,
                                                  true
                                                )
                                              )
                                            )
                                          ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  element.isMedicine
                                    ? _c(
                                        "b-table-column",
                                        {
                                          attrs: {
                                            label: "Cantidad en ml",
                                            centered: ""
                                          }
                                        },
                                        [
                                          _c("p", [
                                            _vm._v(
                                              _vm._s(
                                                props.row
                                                  .quantity_in_oncologic_preparer_warehouse_in_microunits /
                                                  1000
                                              ) + " ml"
                                            )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("template", { slot: "empty" }, [
                          _c("section", { staticClass: "section" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "content has-text-grey has-text-centered"
                              },
                              [
                                _c(
                                  "p",
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "exclamation-triangle",
                                        size: "is-large"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("p", [
                                  _vm._v(
                                    "No hay componentes disponibles en el área de preparación para este cliente. Solicite el componente al almacén para continuar"
                                  )
                                ])
                              ]
                            )
                          ])
                        ])
                      ],
                      2
                    )
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  staticStyle: { "margin-top": "25px" },
                  attrs: { position: "is-centered", grouped: "" }
                },
                [
                  _vm.allMixtureMedicinesSelected
                    ? _c("p", { staticClass: "control has-text-centered" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button is-primary",
                            on: {
                              "~click": function($event) {
                                return _vm.submitMedicines($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    Seleccionar medicamentos\n                "
                            )
                          ]
                        )
                      ])
                    : _c("p", { staticClass: "control has-text-centered" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button is-primary",
                            attrs: { disabled: "" }
                          },
                          [
                            _vm._v(
                              "\n                    Seleccionar medicamentos\n                "
                            )
                          ]
                        )
                      ])
                ]
              )
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("p", { staticClass: "has-text-weight-bold is-size-5" }, [
        _vm._v("Trasvase")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }