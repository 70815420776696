<template>
    <div class="box">
        <b-loading :active.sync="loading"></b-loading>
        <nav v-if="hasCloseButton" class="level">
            <div class="level-left">
                <h1 class="subtitle">Agregar medicamento</h1>
            </div>
            <div class="level-right">
                <a
                @click="$emit('close-form')"
                class="button">
                    <span class="icon">
                        <font-awesome-icon icon="times-circle" />
                    </span>
                    <span> Cerrar forma</span>
                </a>
            </div>
        </nav>
        <form
        @submit.prevent="submitPayerMedicineForm"
        novalidate="true">
            <serbitec-medicine-autocomplete
            v-if="!medicine"
            @medicine-selected="medicineSelected"></serbitec-medicine-autocomplete>

            <b-field v-if="medicine" grouped group-multiline>
                <b-field
                :label="'Medicamento'">
                    <b-field>
                        <p class="control">
                            <span class="button" @click="unselectMedicine">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="times-circle" />
                                </span>
                            </span>
                        </p>
                        <p class="control">
                            <span class="button is-static">
                                Nombre comercial: {{ medicine.commercial_name }} | 
                                Genérico: {{ medicine.component.name }} |
                                Fabricante: {{ medicine.manufacturer.name }}
                            </span>
                        </p>
                    </b-field>
                </b-field>

                <b-field
                v-if="!showPricePerPresentation"
                label="Precio del medicamento"
                :type="{'is-danger' : $v.pricePerMl.$error }"
                :message="{
                    'Se requiere introducir el precio por ml del medicamento' : !$v.pricePerMl.required && $v.pricePerMl.$error
                }"
                expanded>
                    <b-field
                    :type="{'is-danger' : $v.pricePerMl.$error }">
                        <p class="control">
                            <button class="button is-static">$</button>
                        </p>
                        <b-input
                            :value="$v.pricePerMl.$model"
                            @input.native="onInputPricePerMl"
                            v-cleave="masks.numeral"
                            type="text"
                            expanded>
                        </b-input>
                        <p class="control">
                            <button v-if="medicine.component_unit.symbol == 'pz'" class="button is-static">MXN por pieza</button>
                            <button v-else class="button is-static">MXN por ml</button>
                        </p>
                    </b-field>
                </b-field>
            </b-field>
            <br>
            <b-field v-if="!showPricePerPresentation" grouped position="is-centered">
                <p class="control">
                    <button type="submit" class="button is-primary">
                        <span>
                            Agregar medicamento
                        </span>
                    </button>
                </p>
            </b-field>
            <br>
        </form>
        <!-- <div v-if="medicinePresentations && medicinePresentations.length > 1">
            <b-message
            v-if="!showPricePerPresentation && !pricePerFlask"
            type="is-warning" has-icon>
                El medicamento seleccionado tiene más de una presentación.
                <br>
                <small>Si se introduce el precio se manejara el mismo para todas las presentaciones, para diferenciar introduzca el precio de las presentaciones.</small>
            </b-message>
            <div class="block">
                <b-checkbox v-model="showPricePerPresentation">
                    Introducir precio por presentación
                </b-checkbox>
                <b-checkbox v-model="pricePerFlask" :disabled="medicine.component_unit.symbol == 'pz'">
                    Precio por frasco
                </b-checkbox>
            </div>
            <div v-if="pricePerFlask">
                <b-message>
                    El precio por frasco se calcula en función de la <strong>presentación</strong> del medicamento.
                    <br>
                    <small>El precio mostrado es el que se cobrará al cliente independientemente de la cantidad de medicamento utilizado. Se puede introducir el precio por ml del medicamento, en su caso se aplicará la misma tarifa para todas sus presentaciones, o bien, seleccionar de manera independiente el precio por mililitro de las distintas presentaciones seleccionando "Introducir precio por presentación".</small>
                </b-message>
                <b-message v-if="!showPricePerPresentation">
                    El precio para las presentaciones del medicamento son las siguientes:
                    <br>
                    <small v-for="presentation in medicinePresentations" :key="presentation.id">
                        Presentación de {{ presentation.volume_in_ml }} ml: <strong>$ {{ parseFloat( presentation.volume_in_ml * rawPricePerMl ).toFixed(2) }} MXN</strong>
                        <br>
                    </small>
                </b-message>
            </div>
            <div v-if="showPricePerPresentation">
                <hr>
                <serbitec-medicine-presentation-price-form
                :price-per-flask="pricePerFlask"
                :payer-id="payerId"
                :medicine-presentations="medicinePresentations"
                :medicine-unit-symbol="medicine.component_unit.symbol"></serbitec-medicine-presentation-price-form>
            </div>
            <br> -->
        </div>
    </div>
</template>

<script>
import serbitecMedicineAutocomplete from '../utils/autocompletes/medicines.vue';
import serbitecMedicinePresentationPriceForm from './medicine-presentation-price-form.vue';
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import Cleave from 'cleave.js';

const cleave = {
    name: 'cleave',
    bind(el, binding) {
        const input = el.querySelector('input');
        input._vCleave = new Cleave(input, binding.value);
    },
    unbind(el) {
        const input = el.querySelector('input');
        input._vCleave.destroy();
    }
};

function initialDataState() {
    return {
        medicine: null,
        pricePerMl: null,
        rawPricePerMl: null,
        showPricePerPresentation: false,
        medicinePresentations: [],
        pricePerFlask: false,

        loading: false,
        masks: {
            numeral: {
                numeral: true
            }
        }
    };
}

export default {
    directives: { cleave },
    props: ['hasCloseButton', 'payerId'],
    components: {
        serbitecMedicineAutocomplete,
        serbitecMedicinePresentationPriceForm
    },
    data: initialDataState,
    methods: {
        onInputPricePerMl(){
            this.$v.pricePerMl.$model = event.target._vCleave.getFormattedValue();
            this.rawPricePerMl = event.target._vCleave.getRawValue();
        },
        medicineSelected( medicine ){
            this.medicine = medicine;
            this.medicinePresentations = medicine.presentations;
            this.medicinePresentations.map( medicinePresentation => medicinePresentation.pricePerMlOrPz = 0 );
        },
        unselectMedicine(){
            this.medicine = null;
            this.medicinePresentations = [];
        },
        submitPayerMedicineForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.addPayerMedicine();
            }
        },
        getParams () {
            const payerMedicine = {
                price_in_cents_per_ml_or_pz: parseInt( ( parseFloat( this.rawPricePerMl ) * 100).toFixed(0) ),
                is_charged_by_full_medicine_presentation: this.pricePerFlask
            };
            
            return payerMedicine;
        },
        addPayerMedicine () {
            this.makeRequest('/api/payers/' + this.payerId + '/medicines/' + this.medicine.id, axios.post, this.getParams());
        },
        async makeRequest( url, method, params ){
            this.loading = true;
            try {
                await method(url,params);
                this.$emit('success');
                this.$emit('close-form');
                this.loading = false;
            } catch (error) {
                this.$emit('error', error.response.data.error);
                this.loading = false;
            }
        }
    },
    validations () {
        const validations = {
            pricePerMl: {
                required
            }
        };
        return validations;
    }
};
</script>