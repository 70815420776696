var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-field",
        { attrs: { label: "Filtros activos" } },
        [
          _c(
            "b-field",
            { attrs: { grouped: "", "group-multiline": "" } },
            [
              _vm._l(_vm.payersFilters, function(payer) {
                return _c(
                  "div",
                  { key: payer.id, staticClass: "control" },
                  [
                    _c(
                      "b-tag",
                      {
                        attrs: {
                          attached: "",
                          closable: "",
                          "aria-close-label": "Close tag"
                        },
                        on: {
                          close: function($event) {
                            return _vm.removePayer(payer.id)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    Cliente: " +
                            _vm._s(payer.label) +
                            "\n                "
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.fromDateFilter && _vm.untilDateFilter
                    ? _c(
                        "b-tag",
                        {
                          attrs: {
                            attached: "",
                            closable: "",
                            "aria-close-label": "Close tag"
                          },
                          on: { close: _vm.removeDateRange }
                        },
                        [
                          _vm._v(
                            "\n                    Desde " +
                              _vm._s(_vm.formatDate(_vm.fromDateFilter)) +
                              " hasta " +
                              _vm._s(_vm.formatDate(_vm.untilDateFilter)) +
                              "\n                "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.componentTypes, function(componentType) {
                return _c(
                  "div",
                  { key: componentType.id, staticClass: "control" },
                  [
                    _c(
                      "b-tag",
                      {
                        attrs: {
                          attached: "",
                          closable: "",
                          "aria-close-label": "Close tag"
                        },
                        on: {
                          close: function($event) {
                            return _vm.removeComponentType(componentType.id)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                    Tipo: " +
                            _vm._s(componentType.label) +
                            "\n                "
                        )
                      ]
                    )
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.enabledPayerMedicines != null
                ? _c(
                    "div",
                    { staticClass: "control" },
                    [
                      _c(
                        "b-tag",
                        {
                          attrs: {
                            attached: "",
                            closable: "",
                            "aria-close-label": "Close tag"
                          },
                          on: { close: _vm.removeEnabledPayerMedicines }
                        },
                        [
                          _vm.enabledPayerMedicines
                            ? _c("p", [_vm._v("Medicamentos activos")])
                            : _c("p", [_vm._v("Medicamentos inactivos")])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }