var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-field",
        {
          attrs: {
            label: "Nombre del medicamento",
            message: "Introduzca el nombre del medicamento y seleccione"
          }
        },
        [
          _c(
            "b-autocomplete",
            {
              attrs: {
                data: _vm.externalWarehouseItems,
                loading: _vm.autocompleteIsLoading
              },
              on: {
                select: function(option) {
                  return _vm.selectMedicine(option)
                }
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(props) {
                    return [
                      _c("div", { staticClass: "media" }, [
                        _c("div", { staticClass: "media-content" }, [
                          _c("strong", [
                            _vm._v(_vm._s(props.option.commercial_name))
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "p",
                            { staticClass: "has-text-weight-normal is-small" },
                            [
                              _vm._v(
                                "\n                            Genérico: " +
                                  _vm._s(props.option.component.name) +
                                  "\n                            "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n                            Fabricante: " +
                                  _vm._s(props.option.manufacturer.name) +
                                  "\n                        "
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.searchParam,
                callback: function($$v) {
                  _vm.searchParam = $$v
                },
                expression: "searchParam"
              }
            },
            [
              _vm._v(" "),
              _c("template", { slot: "empty" }, [
                _vm._v("No se encontró el medicamento en el almacén externo...")
              ])
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }