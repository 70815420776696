var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { novalidate: "true" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitGenerateReport($event)
          }
        }
      },
      [
        _c(
          "b-field",
          [
            _c("serbitec-report-type-select", {
              attrs: { selectedReportType: _vm.$v.selectedReportType },
              on: { "report-type-selected": _vm.reportTypeSelected }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("serbitec-report-options", {
          attrs: { options: _vm.selectedReportType.options }
        }),
        _vm._v(" "),
        _vm.selectedReportType.id !== 3 && _vm.selectedReportType.id !== 5
          ? _c(
              "div",
              { staticClass: "serbitec-small-top-margin" },
              [
                _c("serbitec-date-picker", {
                  attrs: { selectedDatesRange: _vm.$v.selectedDatesRange },
                  on: { "dates-range-selected": _vm.datesRangeSelected }
                }),
                _vm._v(" "),
                _c("b-message", { attrs: { size: "is-small" } }, [
                  _vm._v(
                    "\n                El rango de fechas que se seleccione en el campo fecha de entrega corresponde a la fecha en la que la mezcla se cambió al estado entregada. El reporte se generará de acuerdo a este criterio.\n            "
                  )
                ])
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.selectedReportType.id !== 5
          ? _c(
              "b-field",
              { staticClass: "serbitec-small-top-margin" },
              [
                _c(
                  "b-radio",
                  {
                    attrs: { "native-value": true },
                    model: {
                      value: _vm.allClients,
                      callback: function($$v) {
                        _vm.allClients = $$v
                      },
                      expression: "allClients"
                    }
                  },
                  [_vm._v("\n                Todos los clientes\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "b-radio",
                  {
                    attrs: { "native-value": false },
                    model: {
                      value: _vm.allClients,
                      callback: function($$v) {
                        _vm.allClients = $$v
                      },
                      expression: "allClients"
                    }
                  },
                  [
                    _vm._v(
                      "\n                Seleccionar clientes\n            "
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.allClients && _vm.selectedReportType.id !== 5
          ? _c("serbitec-payers-select", {
              staticClass: "serbitec-small-top-margin",
              attrs: { selectedPayer: _vm.$v.selectedPayer },
              on: { "payer-selected": _vm.payerSelected }
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.selectedReportType &&
        _vm.selectedReportType.id === 1 &&
        _vm.largerThan2Months
          ? _c(
              "b-message",
              {
                staticClass: "serbitec-small-top-margin",
                attrs: { size: "is-small", type: "is-warning", "has-icon": "" }
              },
              [
                _vm._v(
                  "\n            Por el momento sólo se pueden realizar reportes de este tipo por un periodo de 2 meses\n        "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.missingWarehouseType
          ? _c(
              "b-message",
              {
                staticClass: "serbitec-small-top-margin",
                attrs: { size: "is-small", type: "is-warning", "has-icon": "" }
              },
              [
                _vm._v(
                  "\n            Se require el área de preparación para generar el reporte.\n        "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "b-field",
          {
            staticClass: "serbitec-small-top-margin",
            attrs: { grouped: "", position: "is-right" }
          },
          [
            _c("p", { staticClass: "control" }, [
              _c(
                "button",
                {
                  staticClass: "button is-primary",
                  attrs: {
                    type: "sumbit",
                    disabled: _vm.largerThan2Months || _vm.missingWarehouseType
                  }
                },
                [
                  _c(
                    "span",
                    { staticClass: "icon" },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "file-excel" } })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Generar reporte")])
                ]
              )
            ])
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { staticClass: "level" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("h1", { staticClass: "title" }, [_vm._v("Generación de reportes")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }