<template>
    <b-field
        label="Fecha de entrega"
        label-position="on-border"
        :type="{'is-danger' : selectedDatesRange.$error }"
        :message="{
            'Se requiere introducir el rango de fechas' : !selectedDatesRange.required && selectedDatesRange.$error
        }"
        expanded
    >
        <b-datepicker
            placeholder="Seleccione las fechas"
            :date-formatter="( dates ) => formatDateRange( dates )"
            range
            expanded
            @input="(datesRange) => $emit('dates-range-selected', datesRange)"
        >
        </b-datepicker>
    </b-field>
</template>

<script>
import format from 'date-fns/format';
import { es } from 'date-fns/locale';

export default {
    props: ['selectedDatesRange'],
    methods: {
        formatDateRange( dates ){
            if ( dates.length ){
                let formattedDates = dates.map( date => {
                    return date =  this.formatDate( date );
                } );
                return formattedDates.toString();
            }
        },
        formatDate( date ) {
            return format( date, "d 'de' MMMM yyyy", { locale: es } );
        }
    }
};
</script>