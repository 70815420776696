const getDefaultState = () => {
    return {
        payer: {},
        medic: {},
        patient: {},
        mixtures: [],
        flasks: [],
        infusionSet: null
    };
};

const getDefaultOrder = () => {
    return {
        mixtures: [],
        flasks: [] 
    };
};

const state = getDefaultState();

const mutations = {
    UPDATE_PAYER (state, data) {
        state.payer = data;
    },
    UPDATE_MEDIC (state, data) {
        state.medic = data;
    },
    UPDATE_PATIENT (state, data) {
        state.patient = data;
    },
    UNSELECT_MEDIC (state) {
        Object.assign(state, getDefaultOrder());
        state.medic = {};
        state.patient = {};
    },
    UNSELECT_PATIENT (state) {
        Object.assign(state, getDefaultOrder());
        state.patient = {};
    },
    UPDATE_INFUSION_SET (state, data) {
        state.infusionSet = (data.length > 0);
    },
    ADD_ONCOLOGIC_MIXTURE_TO_ORDER ( state, data ) {
        state.mixtures.push(data);
    },
    ADD_ONCOLOGIC_FLASK_TO_ORDER ( state, data ) {
        state.flasks.push(data);
    },
    REMOVE_ONCOLOGIC_MIXTURE_FROM_ORDER ( state, mixtureID ) {
        var index;
        state.mixtures.map ( mixture => {
            if ( mixture.id == mixtureID ) {
                index = state.mixtures.indexOf( mixture );
            }
        });
        state.mixtures.splice(index,1);
    },
    REMOVE_ONCOLOGIC_FLASK_FROM_ORDER ( state, flaskID ) {
        var index;
        state.flasks.map ( flask => {
            if ( flask.id == flaskID ) {
                index = state.flasks.indexOf( flask );
            }
        });
        state.flasks.splice(index,1);
    },
    RESET_ORDER ( state ) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    namespaced: true,
    state,
    mutations
};
  