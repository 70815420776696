<template>
    <div class="box">
        <b-table
            :data="internalUsers"
            :loading="loading"

            paginated
            backend-pagination
            :total="internalUsersCount"
            :per-page="pageSize"
            @page-change="changePage"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="sort"
        >

            <template slot-scope="props">
                <b-table-column field="first_name" label="Nombre" sortable>
                    {{ props.row.first_name }}
                </b-table-column>
                <b-table-column field="last_name_1" label="Apellido paterno" sortable>
                    {{ props.row.last_name_1 }}
                </b-table-column>
                <b-table-column field="last_name_2" label="Apellido materno" sortable>
                    {{ props.row.last_name_2 }}
                </b-table-column>
                <b-table-column field="email" label="Correo electrónico" sortable>
                    {{ props.row.email }}
                </b-table-column>
                <b-table-column label="Tipo">
                    <span
                        v-for="type in props.row.types"
                        :key="props.row.id + '-' + type.id"
                    >
                        <b-tag>
                            {{ type.name }}
                        </b-tag>
                    </span>
                </b-table-column>
                <b-table-column label="Editar" centered>
                    <a
                        @click="editInternalUser(props.row)"
                        class="button is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="edit" />
                        </span>
                    </a>
                </b-table-column>
                <b-table-column label="Eliminar" centered>
                    <a
                        @click="confirmDeleteInternalUser(props.row.id)"
                        class="button is-small is-danger"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="trash-alt" />
                        </span>
                    </a>
                </b-table-column>
            </template>
            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay usuarios internos registrados.</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import serbitecInternalUserModal from '../../../components/dashboard/internal-users/modal.vue';

export default {
    props: {
        internalUsers: {
            type: Array,
            default: () => []
        },
        internalUsersCount: {
            type: [Number, String],
            default: 0 | ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        page: {
            type: [Number, String],
            default: 0 | ''
        },
        pageSize: {
            type: [Number, String],
            default: 0 | ''
        },
        sortField: {
            type: String,
            default: ''
        },
        sortOrder: {
            type: String,
            default: ''
        },
    },
    methods: {
        changePage ( page ) {
            this.$emit('page-change', page);
        },
        sort ( field, order ) {
            this.$emit('sort', field, order);
        },
        editInternalUser (internalUser) {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecInternalUserModal,
                hasModalCard: true,
                trapFocus: true,
                props: {
                    'internalUser': internalUser
                },
                events: {
                    'success': () => this.$emit('reload-internal-users')
                }
            });
        },
        confirmDeleteInternalUser (internalUserId) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar usuario interno',
                message: '¿Seguro que deseas eliminar el usuario interno?',
                confirmText: 'Eliminar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteInternalUser(internalUserId)
            });
        },
        deleteInternalUser (internalUserId) {
            this.$serbitec.startLoading();
            this.$axios.delete('/api/users/' + internalUserId)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha eliminado el usuario interno'
                });
                this.$serbitec.finishLoading();
                this.$emit('reload-internal-users');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    }
};
</script>