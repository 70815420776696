<template>
    <div class="box">
        <b-table
            :data="warehouseItems"

            paginated
            backend-pagination
            :total="warehouseItemsCount"
            :per-page="pageSize"
            @page-change="(page) => $emit('page-change', page)"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="(field, order) => $emit('sort', field, order)">

            <template slot-scope="props">
                <b-table-column field="batch" label="Lote" numeric sortable>
                    <p><strong v-if="props.row.prefix">{{ props.row.prefix.prefix }}-</strong>{{ props.row.batch }}</p>
                </b-table-column>

                <b-table-column label="Nombre comercial">
                    {{ props.row.medicine_presentation.medicine.commercial_name }}
                </b-table-column>

                <b-table-column label="Fabricante">
                    {{ props.row.medicine_presentation.medicine.manufacturer.name }}
                </b-table-column>

                <b-table-column label="Presentación" centered>
                    <b-tag
                    v-if="isEvaBag( props.row )">
                        {{ props.row.medicine_presentation.component_quantity }} {{ props.row.medicine_presentation.medicine.component_unit.symbol }} de {{ props.row.medicine_presentation.volume_in_ml }} ml
                    </b-tag>
                    <b-tag
                    v-else-if="isPieceItem( props.row )">
                        {{ props.row.medicine_presentation.component_quantity }} {{ props.row.medicine_presentation.medicine.component_unit.symbol }}
                    </b-tag>
                    <b-tag
                    v-else-if="isOncologicItem( props.row )">
                        {{ props.row.medicine_presentation.component_quantity }} {{ props.row.medicine_presentation.medicine.component_unit.symbol }} en {{ props.row.medicine_presentation.volume_in_ml }} ml
                    </b-tag>
                    <b-tag v-else>
                        {{ props.row.medicine_presentation.volume_in_ml }} ml
                    </b-tag>
                </b-table-column>

                <b-table-column label="Existencias en almacen [frascos]" centered>
                    {{ props.row.flasks_remaining_outside }}
                </b-table-column>

                <b-table-column label="Fecha de caducidad" centered>
                    {{ props.row.expiry_date }}
                </b-table-column>

                <b-table-column label="Ordenes de compra" centered>
                    <router-link
                        :to="{ path: 'purchase_orders', query: { batchId: props.row.id } }"
                        class="button is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="file-invoice-dollar" />
                        </span>
                    </router-link>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay lotes registrados</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    props: {
        warehouseItems: {
            type: Array,
            default: () => []
        },
        warehouseItemsCount: {
            type: Number,
            default: 0
        },
        page: {
            type: [Number, String],
            default: 0 | ''
        },
        pageSize: {
            type: [Number, String],
            default: 0 | ''
        },
        sortField: {
            type: String,
            default: ''
        },
        sortOrder: {
            type: String,
            default: ''
        }
    },
    methods: {
        isOncologicItem ( batch ) {
            return ( batch.medicine_presentation.medicine.component.component_type.id == 1 );
        },
        isEvaBag ( batch ) {
            return ( batch.medicine_presentation.medicine.component.component_type.id == 12 );
        },
        isPieceItem ( batch ) {
            return ( batch.medicine_presentation.medicine.component.component_type.id == 8 );
        }
    }
};
</script>