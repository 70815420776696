<template>
    <nav class="breadcrumb is-medium is-marginless" aria-label="breadcrumbs">
        <ul>
            <li v-for="item in route" :key="item.id" :class="{ 'is-active' : item.active }">
                <router-link :to="item.to">
                    {{ item.label }}
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    props: {
        route: {
            type: Array,
            default: null
        }
    }
};
</script>
