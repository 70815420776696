var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns is-centered" }, [
    _c(
      "div",
      {
        staticClass: "column",
        class: {
          "is-three-fifths": _vm.$mq === "desktop",
          "is-half": _vm.$mq === "touch"
        }
      },
      [
        _c(
          "p",
          { staticClass: "control" },
          [
            _c(
              "router-link",
              {
                staticClass: "button",
                attrs: { to: { path: "/login" }, type: "submit" }
              },
              [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [_c("font-awesome-icon", { attrs: { icon: "caret-left" } })],
                  1
                ),
                _vm._v(" "),
                _c("span", [
                  _vm._v("\n                    Regresar\n                ")
                ])
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("serbitec-forgot-password-form", {
          staticClass: "serbitec-small-top-margin"
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }