var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function($event) {
            _vm.loading = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "true" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm($event)
            }
          }
        },
        [
          _c("div", { staticClass: "box" }, [
            _c("nav", { staticClass: "level" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "level-right" }, [
                _c(
                  "a",
                  {
                    staticClass: "button",
                    on: {
                      click: function($event) {
                        return _vm.closeForm()
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "times-circle" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v(" Cerrar forma")])
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            !_vm.selectedOncologicMedicine
              ? _c(
                  "div",
                  [
                    _c("h1", { staticClass: "subtitle is-6" }, [
                      _vm._v("1. Seleccione el medicamento oncológico")
                    ]),
                    _vm._v(" "),
                    _c("serbitec-medicine-autocomplete", {
                      attrs: {
                        "payer-id": _vm.payer.id,
                        withComponentTypeIds: "1"
                      },
                      on: { "medicine-selected": _vm.storeFlask }
                    }),
                    _vm._v(" "),
                    _c("br")
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedOncologicMedicine
              ? _c(
                  "div",
                  [
                    _c("h1", { staticClass: "subtitle is-6" }, [
                      _vm._v(
                        "2. Llene los campos para agregar el frasco a la orden"
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-field",
                      { attrs: { grouped: "" } },
                      [
                        _c("b-field", [
                          _c(
                            "span",
                            {
                              staticClass: "button",
                              on: {
                                click: function($event) {
                                  return _vm.unselectMedicine()
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-small" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "exchange-alt" }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-field",
                          { attrs: { expanded: "" } },
                          [
                            _c("b-input", {
                              attrs: {
                                value:
                                  _vm.selectedOncologicMedicine
                                    .commercial_name +
                                  " (Genérico: " +
                                  _vm.selectedOncologicMedicine.component.name +
                                  ")",
                                disabled: ""
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-field",
                      { attrs: { grouped: "", "group-multiline": "" } },
                      [
                        _c(
                          "b-field",
                          {
                            attrs: {
                              label: "Presentación",
                              type: {
                                "is-danger": _vm.$v.medicinePresentation.$error
                              },
                              message: [
                                !_vm.$v.medicinePresentation.required &&
                                _vm.$v.medicinePresentation.$error
                                  ? "Se requiere seleccionar la presentación"
                                  : "Seleccione la presentación"
                              ],
                              expanded: ""
                            }
                          },
                          [
                            _c(
                              "b-select",
                              {
                                attrs: {
                                  placeholder: "Seleccione la presentación"
                                },
                                model: {
                                  value: _vm.$v.medicinePresentation.$model,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.$v.medicinePresentation,
                                      "$model",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "$v.medicinePresentation.$model"
                                }
                              },
                              _vm._l(
                                _vm.selectedOncologicMedicine.presentations,
                                function(presentation) {
                                  return _c(
                                    "option",
                                    {
                                      key: presentation.id,
                                      domProps: { value: presentation }
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            presentation.component_quantity
                                          ) +
                                          " " +
                                          _vm._s(
                                            _vm.selectedOncologicMedicine
                                              .component_unit.symbol
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-field",
                          {
                            attrs: {
                              label: "Cantidad",
                              type: {
                                "is-danger": _vm.$v.flasksQuantity.$error
                              },
                              message: [
                                !_vm.$v.flasksQuantity.required &&
                                _vm.$v.flasksQuantity.$error
                                  ? "Se requiere seleccionar la cantidad de frascos"
                                  : "Seleccione la cantidad de frascos"
                              ],
                              expanded: ""
                            }
                          },
                          [
                            _c("b-numberinput", {
                              attrs: {
                                "controls-position": "compact",
                                editable: _vm.isNumberFieldEditable,
                                min: "1"
                              },
                              model: {
                                value: _vm.$v.flasksQuantity.$model,
                                callback: function($$v) {
                                  _vm.$set(_vm.$v.flasksQuantity, "$model", $$v)
                                },
                                expression: "$v.flasksQuantity.$model"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "b-field",
                      {
                        attrs: {
                          label: "Comentarios",
                          message:
                            "Introduzca comentarios referentes a la mezcla"
                        }
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            type: "textarea",
                            maxlength: "500",
                            placeholder: "Introduzca sus comentarios..."
                          },
                          model: {
                            value: _vm.comments,
                            callback: function($$v) {
                              _vm.comments = $$v
                            },
                            expression: "comments"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("b-field", [
                      _c("p", { staticClass: "control has-text-centered" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button is-primary",
                            attrs: { type: "submit" }
                          },
                          [
                            _vm._v(
                              "\n                            Agregar frasco\n                        "
                            )
                          ]
                        )
                      ])
                    ])
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("div", { staticClass: "level-item" }, [
        _c("h1", { staticClass: "subtitle" }, [
          _vm._v("Agregar frasco al pedido")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }