var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.externalWarehouseMedicine
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("h1", { staticClass: "subtitle is-6" }, [
              _vm._v("1. Seleccione el medicamento")
            ]),
            _vm._v(" "),
            _c("serbitec-external-warehouse-medicines-autocomplete", {
              attrs: { "internal-warehouse-type": _vm.internalWarehouseType },
              on: { "medicine-selected": _vm.medicineSelected }
            }),
            _vm._v(" "),
            _c("br")
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.externalWarehouseMedicine
      ? _c(
          "div",
          { staticClass: "box" },
          [
            _c("h1", { staticClass: "subtitle is-6" }, [
              _vm._v(
                "2. Seleccione la cantidad de frascos para añadir a la solicitud"
              )
            ]),
            _vm._v(" "),
            _c(
              "b-field",
              { attrs: { grouped: "" } },
              [
                _c("b-field", [
                  _c(
                    "span",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          return _vm.unselectMedicine()
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "icon is-small" },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "exchange-alt" }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c(
                  "b-field",
                  { attrs: { expanded: "" } },
                  [
                    _c("b-input", {
                      attrs: {
                        value:
                          _vm.externalWarehouseMedicine.commercial_name +
                          " (Genérico: " +
                          _vm.externalWarehouseMedicine.component.name +
                          ")",
                        disabled: ""
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("serbitec-request-external-warehouse-items-table", {
              attrs: { "medicine-id": _vm.externalWarehouseMedicine.id },
              on: { "item-added": _vm.unselectMedicine }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }