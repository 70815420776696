<template>
    <div>
        <b-field
            v-if="Object.keys(payer).length === 0 && payer.constructor === Object"
            label="Nombre del cliente"
            label-position="on-border"
            message="Introduzca el nombre del cliente y seleccione"
        >
            <serbitec-payer-autocomplete
                @payer-added="addPayer"
            />
        </b-field>
        <div v-if="Object.keys(payer).length !== 0 && payer.constructor === Object">
            <serbitec-payer-data
                :isFixed="user.isExternalUser && user.isMedic"
                :payer="payer"
                @payer-unselected="unselectPayer"
            />
            <b-field
                v-if="Object.keys(medic).length === 0 && medic.constructor === Object"
                class="serbitec-small-top-margin"
                grouped
                group-multiline
            >
                <b-field
                    message="Introduzca el nombre del usuario y seleccione"
                    expanded
                >
                    <b-field
                        label="Nombre del usuario"
                        label-position="on-border"
                    >
                        <serbitec-medic-autocomplete
                            :payer-id="payer.id"
                            @medic-added="addMedic"
                        />
                        <p class="control">
                            <button type="button" @click="addNewMedic" class="button">
                                <span>
                                    Agregar nuevo médico
                                </span>
                            </button>
                        </p>
                    </b-field>
                </b-field>
            </b-field>
        </div>
        <div v-if="Object.keys(medic).length !== 0 && medic.constructor === Object">
            <serbitec-medic-data
                :isFixed="user.isExternalUser && user.isMedic"
                class="serbitec-small-top-margin"
                :medic="medic"
                @medic-unselected="unselectMedic"
            />
            <b-field
                v-if="!showPatientForm && Object.keys(patient).length === 0 && patient.constructor === Object"
                class="serbitec-small-top-margin"
                label="Nombre del paciente"
                label-position="on-border"
                grouped
                group-multiline
            >
                <serbitec-patient-autocomplete
                    :payer-id="payer.id"
                    @patient-added="addPatient"
                    :disabled="showPatientForm"
                />
                <p class="control">
                    <button type="button" @click="showPatientForm = true" class="button">
                        <span>
                            Agregar nuevo paciente
                        </span>
                    </button>
                </p>
            </b-field>
            <serbitec-patient-form
                v-if="showPatientForm || (Object.keys(patient).length !== 0 && patient.constructor === Object)"
                :patient="patient"
                :order-type="orderType"
                @patient-added="$emit('next-step')"
                @patient-unselected="unselectPatient"
            />
        </div>
    </div>
</template>

<script>
import serbitecPayerAutocomplete from './payer-autocomplete.vue';
import serbitecMedicAutocomplete from './medic-autocomplete.vue';
import serbitecPatientAutocomplete from './patient-autocomplete.vue';
import serbitecPatientForm from './patient-form.vue';
import serbitecPayerData from './payer-data.vue';
import serbitecMedicData from './medic-data.vue';
import serbitecAddMedicModal from './add-medic-modal.vue';
import { mapState } from 'vuex';

function initialDataState() {
    return {
        showPatientForm: false
    };
}

export default {
    data: initialDataState,
    props: {
        orderType: {
            type: String,
            default: ''
        }
    },
    components: {
        serbitecPayerAutocomplete,
        serbitecMedicAutocomplete,
        serbitecPatientAutocomplete,
        serbitecPatientForm,
        serbitecPayerData,
        serbitecMedicData,
    },
    computed:{
        ...mapState({
            user: state => state.user,
            payer (state) {
                if (this.orderType === 'nutritional') {
                    return state.nutritionalOrder.payer;
                }
                if (this.orderType === 'oncologic') {
                    this.$store.commit('payer/SET_PAYER', state.oncologicOrder.payer);
                    return state.oncologicOrder.payer;
                }
            },
            medic (state) {
                if (this.orderType === 'nutritional') {
                    return state.nutritionalOrder.medic;
                }
                if (this.orderType === 'oncologic') {
                    return state.oncologicOrder.medic;
                }
            },
            patient (state) {
                if (this.orderType === 'nutritional') {
                    return state.nutritionalOrder.patient;
                }
                if (this.orderType === 'oncologic') {
                    return state.oncologicOrder.patient;
                }
            }
        })
    },
    methods: {
        addPayer (payer) {
            if (this.orderType === 'nutritional') {
                this.$store.commit('nutritionalOrder/UPDATE_PAYER', payer);
            }
            if (this.orderType === 'oncologic') {
                this.$store.commit('oncologicOrder/UPDATE_PAYER', payer);
            }
        },
        addMedic (medic) {
            if (this.orderType === 'nutritional') {
                this.$store.commit('nutritionalOrder/UPDATE_MEDIC', medic);
            }
            if (this.orderType === 'oncologic') {
                this.$store.commit('oncologicOrder/UPDATE_MEDIC', medic);
            }
        },
        addPatient (patient) {
            this.showPatientForm = true;
            if (this.orderType === 'nutritional') {
                this.$store.commit('nutritionalOrder/UPDATE_PATIENT', patient);
            }
            if (this.orderType === 'oncologic') {
                this.$store.commit('oncologicOrder/UPDATE_PATIENT', patient);
            }
        },
        unselectPatient () {
            this.showPatientForm = false;
            if (this.orderType === 'nutritional') {
                this.$store.commit('nutritionalOrder/UNSELECT_PATIENT');
            }
            if (this.orderType === 'oncologic') {
                this.$store.commit('oncologicOrder/UNSELECT_PATIENT');
            }
        },
        unselectMedic () {
            this.showPatientForm = false;
            if (this.orderType === 'nutritional') {
                this.$store.commit('nutritionalOrder/UNSELECT_MEDIC');
            }
            if (this.orderType === 'oncologic') {
                this.$store.commit('oncologicOrder/UNSELECT_MEDIC');
            }
        },
        unselectPayer () {
            this.showPatientForm = false;
            if (this.orderType === 'nutritional') {
                this.$store.commit('nutritionalOrder/RESET_ORDER');
            }
            if (this.orderType === 'oncologic') {
                this.$store.commit('oncologicOrder/RESET_ORDER');
            }
        },
        addNewMedic () {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecAddMedicModal,
                hasModalCard: true,
                trapFocus: true,
                props: {
                    payerId: this.payer.id
                },
                events: {
                    'medic-added': (medic) => this.addMedic(medic)
                }
            });
        },
    }
};
</script>