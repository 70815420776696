var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "level" }, [
      _c(
        "div",
        { staticClass: "level-left" },
        [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" })
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _vm.mixture
      ? _c(
          "div",
          [
            _c("div", { staticClass: "box" }, [
              _c("h1", { staticClass: "title has-text-centered" }, [
                _vm._v(
                  "Orden: ON-" +
                    _vm._s(_vm.mixture.order.id) +
                    " | Lote: LN-" +
                    _vm._s(_vm.mixture.id)
                )
              ])
            ]),
            _vm._v(" "),
            _c("serbitec-horizontal-order-data", {
              attrs: {
                medic: _vm.mixture.order.medic,
                patient: _vm.mixture.order.patient
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column is-half is-size-4" }, [
                _vm._v("\n                Volumen total: "),
                _c("strong", [
                  _vm._v(_vm._s(_vm.mixture.total_volume_in_ml) + " ml")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "column is-half is-size-4" }, [
                _vm._v("\n                Sobrellenado: "),
                _c("strong", [
                  _vm._v(_vm._s(_vm.mixture.overfill_in_ml) + " ml")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("serbitec-nutritional-elements-table", {
              attrs: {
                elements: _vm.mixture.elements,
                "total-volume": _vm.mixture.total_volume_in_ml
              }
            }),
            _vm._v(" "),
            _c("serbitec-quality-inspection", {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                "quality-inspection-check-list": _vm.qualityInspectionCheckList,
                "computed-mixture-weight": _vm.computedMixtureWeight
              },
              on: { "weight-input": _vm.weightInput },
              model: {
                value: _vm.qualityComments,
                callback: function($$v) {
                  _vm.qualityComments = $$v
                },
                expression: "qualityComments"
              }
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticStyle: {},
                attrs: { position: "is-centered", grouped: "" }
              },
              [
                _c("p", { staticClass: "control has-text-centered" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button is-primary",
                      attrs: {
                        disabled:
                          _vm.inspectionRejected || !_vm.inspectionCompleted
                      },
                      on: {
                        click: function($event) {
                          return _vm.submitQuality(true)
                        }
                      }
                    },
                    [_vm._v("\n                    Aprobada\n                ")]
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "control has-text-centered" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { disabled: !_vm.inspectionCompleted },
                      on: {
                        click: function($event) {
                          return _vm.submitQuality(false)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Rechazada\n                "
                      )
                    ]
                  )
                ])
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }