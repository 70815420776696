<template>
    <div class="box">
        
        
        <b-table
            :data="mixtures"
            :loading="loading"

            paginated
            backend-pagination
            :total="mixturesCount"
            :per-page="pageSize"
            @page-change="changePage"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="sort"
            
            detailed
            detail-key="id">

            <template slot-scope="props">
                <b-table-column field="id" label="Orden" numeric sortable>
                    <p v-if="isNutritional">ON-{{ props.row.order.id }}</p>
                    <p v-else-if="isOncologic">OO-{{ props.row.order.id }}</p>
                </b-table-column>

                <b-table-column label="Lote" numeric>
                    <p v-if="isNutritional">LN-{{ props.row.id }}</p>
                    <p v-else-if="isOncologic">LO-{{ props.row.id }}</p>
                </b-table-column>

                <b-table-column label="Paciente">
                    {{ props.row.order.patient.name }}
                </b-table-column>

                <b-table-column label="Cliente">
                    <p>{{ props.row.order.medic.payer.invoicing_data.business_name }}</p>
                </b-table-column>

                <b-table-column field="delivery_date" label="Fecha de entrega" sortable>
                    <p v-if="isNutritional">{{ formatDate( props.row.delivery_date ) }}</p>
                    <p v-else-if="isOncologic">{{ formatDate( props.row.order.delivery_date ) }}</p>
                </b-table-column>

                <b-table-column label="Inspección calidad" centered>
                    <router-link
                    v-if="props.row.state.id == 5"
                    class="button is-light is-small"
                    :to="{ path: 'mixture_inspection', query: { mixtureId: props.row.id } }" append>
                        <span class="icon is-medium">
                            <font-awesome-icon icon="clipboard-check" />
                        </span>
                    </router-link>
                    <a
                    v-else
                    class="button is-light is-small"
                    disabled>
                        <span class="icon is-medium">
                            <font-awesome-icon icon="clipboard-check" />
                        </span>
                    </a>
                </b-table-column>

                <b-table-column label="Editar comentarios" centered>
                    <a
                    v-if="props.row.state.id == 6"
                    class="button is-light is-small"
                    @click="editComments(props.row, 'mixture')"
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="comment-alt" />
                        </span>
                    </a>
                    <a
                    v-else
                    class="button is-light is-small"
                    disabled>
                        <span class="icon is-medium">
                            <font-awesome-icon icon="comment-alt" />
                        </span>
                    </a>
                </b-table-column>
            </template>

            <template slot="detail" slot-scope="props">
                <b-message
                v-if="props.row.state.id == 5">
                    El lote debe pasar la <strong>inspección de calidad</strong> para activar la impresión de etiqueta
                </b-message>
                <nav v-else-if="props.row.state.id == 6" class="level">
                    <div class="level-left">
                        <p class="control">
                            <div
                            v-if="props.row.printed_opportunities_used >= 2"
                            >
                            <a
                            class="button is-small"
                            disabled>
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="print" />
                                </span>
                                <span>Imprimir etiqueta</span>
                            </a>
                            </div>
                            <div
                                v-else-if="isOncologic"
                            >
                            <!-- <a
                            @click="$emit( 'print-attempt', props.row.id )"
                            class="button is-small"
                            :href="'/pdf/oncologic/mixtures/' + props.row.id + '/label'" target="_blank">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="print" />
                                </span>
                                <span>Imprimir etiqueta</span>
                            </a> -->
                            <a
                            @click="$emit( 'print-attempt', props.row.id );labelOnc( props.row.id)"
                            class="button is-small"
                            :href="'/#/oncologic_orders_quality_control'" >
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="print" />
                                </span>
                                <span>Imprimir etiqueta</span>
                            </a>
                            </div>
                            <div
                            v-else-if="isNutritional"
                            >
                            <!-- <a
                            @click="$emit( 'print-attempt', props.row.id )"
                            class="button is-small"
                            :href="'/pdf/nutritional/mixtures/' + props.row.id + '/label'" target="_blank">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="print" />
                                </span>
                                <span>Imprimir etiqueta</span>
                            </a> -->
                            <a
                            @click="$emit( 'print-attempt', props.row.id );labelNut( props.row.id)"
                            class="button is-small"
                            :href="'/#/nutritional_mixtures_quality_control'" >
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="print" />
                                </span>
                                <span>Imprimir etiqueta</span>
                            </a>
                            </div>
                        </p>
                        <b-tag style="margin-left: 10px"><small>Intentos utilizados: {{ props.row.printed_opportunities_used }}</small></b-tag>
                    </div>
                    <div class="level-right">
                        <p class="control">
                            <a
                            class="button is-small is-primary"
                            @click="$emit( 'mixture-aconditioned', props.row.id )">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="check-circle" />
                                </span>
                                <span>Mezcla acondicionada</span>
                            </a>
                        </p>
                    </div>
                </nav>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay mezclas pendientes.</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import axios from "axios";
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { es } from 'date-fns/locale';
import serbitecEditCommentsModal from '../../../components/dashboard/utils/modals/edit-comments.vue';
import $ from 'jquery';

var selected_device;
var errorCallback;

$(function()
    {
        BrowserPrint.getDefaultDevice('printer', function (printer) {
            if ((printer != null) && (printer.connection != undefined)) {
                console.log(printer);
                selected_device = printer;
            };
        })
    }
);

export default { 
    props: [
        'qualityType',
        'mixtures',
        'loading',
        'mixturesCount',
        'page',
        'pageSize',
        'sortField',
        'sortOrder'
    ],
    computed: {
        isOncologic () {
            return this.qualityType == 'oncologic';
        },
        isNutritional () {
            return this.qualityType == 'nutritional';
        }
    },
    methods: {
        formatDate( date ) {
            return format( parseISO(date), "d 'de' MMMM yyyy '|' HH:mm", { locale: es } );
        },
        changePage ( page ) {
            this.$emit('page-change', page);
        },
        sort ( field, order ) {
            this.$emit('sort', field, order);
        },
        labelOnc(id) {
            console.log(id);
            axios.get(`txt/oncologic/mixtures/${id}/newlabel`)
            .then((result) => {
            this.txt = result.data;
            selected_device.send(result.data, undefined, errorCallback);
            })
        },
        labelNut(id) {
            console.log(id);
            axios.get(`txt/nutritional/mixtures/${id}/label`)
            .then((result) => {
            this.txt = result.data;
            selected_device.send(result.data, undefined, errorCallback);
            })
        },
        makeRequest ( url, method, params ) { 
            this.loading = true;
            method( url, params )
            .then(function () {
                this.loading = false;
                console.log('correct');
            }.bind(this))
            .catch(function (error) {
                this.loading = false;
            }.bind(this));
        },
        editComments (mixture, itemType) {
            this.$buefy.modal.open({ 
                parent: this,
                component: serbitecEditCommentsModal,
                hasModalCard: true,
                trapFocus: true,
                props: {
                    mixture: mixture,
                    type: this.qualityType,
                    itemType: itemType
                }
            });
        }
    },

};

</script>
