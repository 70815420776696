import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import payer from './modules/payer';
import medic from './modules/medic';
import patient from './modules/patient';
import oncologicMedicine from './modules/oncologic-medicine';
import oncologicOrder from './modules/oncologic-order';
import nutritionalOrder from './modules/nutritional-order';
import externalWarehouse from './modules/external-warehouse';
import internalWarehouseRequest from './modules/internal-warehouse-request';
import recordFilters from './modules/record-filters';
import reports from './modules/reports';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

import ApolloClient from "apollo-boost";

const apolloClient = new ApolloClient({
	uri: process.env.MIX_APOLLO_URL
});

import VueApollo from "vue-apollo";
Vue.use(VueApollo);

const apolloProvider = new VueApollo({
	defaultClient: apolloClient
});

import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
    apolloProvider,
    modules: {
        user,
        payer,
        medic,
        patient,
        oncologicMedicine,
        oncologicOrder,
        nutritionalOrder,
        externalWarehouse,
        internalWarehouseRequest,
        recordFilters,
        reports
    },
    plugins: [createPersistedState({
        paths: ['user']
    })],
    strict: debug
});