<template>
    <b-select
        :value="selectedUnit"
        @input="option => $emit('unit-selected', option)"
        expanded
    >
        <option
            v-for="option in units"
            :key="option.id"
            :value="option"
        >
            {{ option.symbol }}
        </option>
    </b-select>
</template>

<script>
import gql from 'graphql-tag';
const query = gql` query
{
    units {
        data {
            id
            symbol
        }
    }
}`;
export default {
    props: {
        selectedUnit: {
            type: Object,
            default: () => {}
        }
    },
    apollo: {
        units: {
            query: query,
            update (data) {
                return data.units.data;
            },
            watchLoading (isLoading) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            }
        }
    }
};
</script>