<template>
    <div class="box">
        <b-table
            :data="mixtures"
            :loading="loading"

            paginated
            backend-pagination
            :total="mixturesCount"
            :per-page="pageSize"
            @page-change="changePage"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="sort"
            
            :detailed="isOncologic"
            detail-key="id">

            <template slot-scope="props">
                <b-table-column field="id" label="Orden" numeric sortable>
                    <p v-if="isNutritional">ON-{{ props.row.order.id }}</p>
                    <p v-else-if="isOncologic">OO-{{ props.row.id }}</p>
                </b-table-column>

                <b-table-column v-if="isNutritional" label="Lote" numeric>
                    <p>LN-{{ props.row.id }}</p>
                </b-table-column>

                <b-table-column label="Cliente">
                    <p v-if="isNutritional">{{ props.row.order.medic.payer.invoicing_data.business_name }}</p>
                    <p v-else-if="isOncologic">{{ props.row.medic.payer.invoicing_data.business_name }}</p>
                </b-table-column>

                <b-table-column label="Paciente">
                    <p v-if="isNutritional">{{ props.row.order.patient.name }}</p>
                    <p v-else-if="isOncologic">{{ props.row.patient.name }}</p>
                </b-table-column>

                <b-table-column field="delivery_date" label="Fecha de entrega" sortable>
                    <p v-if="isNutritional">{{ formatDate( props.row.delivery_date ) }}</p>
                    <p v-else-if="isOncologic">{{ formatDate( props.row.delivery_date ) }}</p>
                </b-table-column>

                <b-table-column label="Enviada" centered>
                    <a v-if="hasNotBeenAconditioned(props.row)" @click="$emit( 'order-sent', props.row.id )" class="button is-light is-small">
                        <span class="icon is-medium">
                            <font-awesome-icon icon="motorcycle" />
                        </span>
                    </a>
                    <a v-else class="button is-light is-small" disabled>
                        <span class="icon is-medium">
                            <font-awesome-icon icon="motorcycle" />
                        </span>
                    </a>
                </b-table-column>

                <b-table-column label="Entregada" centered>
                    <a v-if="hasBeenSent(props.row)" @click="$emit( 'order-delivered', props.row.id )" class="button is-light is-small">
                        <span class="icon is-medium">
                            <font-awesome-icon icon="clipboard-check" />
                        </span>
                    </a>
                    <a v-else class="button is-light is-small" disabled>
                        <span class="icon is-medium">
                            <font-awesome-icon icon="clipboard-check" />
                        </span>
                    </a>
                </b-table-column>
            </template>

            <template  slot="detail" slot-scope="props">
                <div v-for="mixture in props.row.mixtures" :key="mixture.id">
                    <small>Mezcla con lote: <strong>LO-{{ mixture.id }}</strong></small>
                    <b-tag>Estado: {{ mixture.state.name }}</b-tag>
                </div>
                <div v-for="flask in props.row.requested_flasks" :key="flask.id">
                    <small>Frasco con lote: <strong>FO-{{ flask.id }}</strong></small>
                    <b-tag>Estado: {{ flask.state.name }}</b-tag>
                </div>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay registros realizados.</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { es } from 'date-fns/locale';

export default {
    props: [
        'tableType',
        'mixtures',
        'loading',
        'mixturesCount',
        'page',
        'pageSize',
        'sortField',
        'sortOrder'
    ],
    computed: {
        isOncologic () {
            if ( this.tableType == 'oncologic' ) {
                return true;
            } else {
                return false;
            }
        },
        isNutritional () {
            if ( this.tableType == 'nutritional' ) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        formatDate( date ) {
            return format( parseISO(date), "d 'de' MMMM yyyy '|' HH:mm", { locale: es } );
        },
        changePage ( page ) {
            this.$emit('page-change', page);
        },
        sort ( field, order ) {
            this.$emit('sort', field, order);
        },
        hasNotBeenAconditioned ( order ) {
            if ( this.isNutritional ) {
                return order.state.id == 9;
            } else if ( this.isOncologic ) {
                const mixtureSent = order.mixtures.find( mixture => mixture.state.id != 9 );
                const flaskSent = order.requested_flasks.find( flask => flask.state.id != 9 );
                return ( mixtureSent || flaskSent ) == null;
            }
        },
        hasBeenSent ( order ) {
            if ( this.isNutritional ) {
                return order.state.id == 8;
            } else if ( this.isOncologic ) {
                const mixtureSent = order.mixtures.find( mixture => mixture.state.id == 8 );
                const flaskSent = order.requested_flasks.find( flask => flask.state.id == 8 );
                return ( mixtureSent || flaskSent ) != null;
            }
        }
    }
};
</script>