var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card", staticStyle: { width: "auto" } },
    [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.comesFromEdit ? "Editar medicamento" : "Agregar medicamento"
              ) +
              "\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          !_vm.showComponentData
            ? _c(
                "b-field",
                {
                  attrs: {
                    label: "Componente",
                    "label-position": "on-border",
                    type: { "is-danger": _vm.$v.selectedComponent.$error },
                    message: {
                      "Se requiere introducir el componente":
                        !_vm.$v.selectedComponent.required &&
                        _vm.$v.selectedComponent.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("serbitec-components-autocomplete", {
                    attrs: { "with-component-type-ids": "1,3,5,13,15" },
                    on: { "component-added": _vm.updateComponent }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showComponentData
            ? _c(
                "b-field",
                {
                  attrs: { label: "Componente", "label-position": "on-border" }
                },
                [
                  _c("b-field", [
                    _c("p", { staticClass: "control" }, [
                      _c(
                        "span",
                        {
                          staticClass: "button",
                          on: { click: _vm.unselectComponent }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-small" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "times-circle" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "control" }, [
                      _c("span", { staticClass: "button is-static" }, [
                        _vm._v(
                          "\n                        Genérico: " +
                            _vm._s(_vm.selectedComponent.name) +
                            "\n                    "
                        )
                      ])
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                label: "Nombre comercial del medicamento",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.medicineCommercialName.$error },
                message: {
                  "Se requiere introducir el nombre comercial del medicamento":
                    !_vm.$v.medicineCommercialName.required &&
                    _vm.$v.medicineCommercialName.$error
                },
                expanded: ""
              }
            },
            [
              _c("b-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.$v.medicineCommercialName.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$v.medicineCommercialName,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.medicineCommercialName.$model"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                label: "Fabricante",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.selectedManufacturer.$error },
                message: {
                  "Se requiere introducir y seleccionar el fabricante":
                    !_vm.$v.selectedManufacturer.required &&
                    _vm.$v.selectedManufacturer.$error
                },
                expanded: ""
              }
            },
            [
              _c("serbitec-manufacturer-select", {
                attrs: { selectedManufacturer: _vm.selectedManufacturer },
                on: { "manufacturer-selected": _vm.updateSelectedManufacturer }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                label: "Unidad del componente activo",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.selectedPresentationUnit.$error },
                message: {
                  "Se requiere seleccionar la unidad del componente activo":
                    !_vm.$v.selectedPresentationUnit.required &&
                    _vm.$v.selectedPresentationUnit.$error
                },
                expanded: ""
              }
            },
            [
              _c("serbitec-unit-select", {
                attrs: { selectedUnit: _vm.selectedPresentationUnit },
                on: { "unit-selected": _vm.updateSelectedUnit }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: { label: "Comentarios", "label-position": "on-border" }
            },
            [
              _c("b-input", {
                attrs: { maxlength: "200", type: "textarea" },
                model: {
                  value: _vm.medicineComments,
                  callback: function($$v) {
                    _vm.medicineComments = $$v
                  },
                  expression: "medicineComments"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              attrs: { label: "¿El medicamento es liofilizado?", expanded: "" }
            },
            [
              _c(
                "div",
                [
                  _c(
                    "b-checkbox",
                    {
                      model: {
                        value: _vm.medicineIsLiofilized,
                        callback: function($$v) {
                          _vm.medicineIsLiofilized = $$v
                        },
                        expression: "medicineIsLiofilized"
                      }
                    },
                    [
                      _vm._v(
                        "\n                        Es liofilizado\n                    "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _vm.medicineIsLiofilized
            ? _c(
                "div",
                [
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      staticClass: "serbitec-small-top-margin",
                      attrs: {
                        label: "Diluyente para reconstitución",
                        type: { "is-danger": _vm.$v.selectedThinner.$error },
                        message: {
                          "Se requiere introducir y seleccionar el diluyente para reconstitución":
                            !_vm.$v.selectedThinner.required &&
                            _vm.$v.selectedThinner.$error
                        },
                        expanded: ""
                      }
                    },
                    [
                      _c("serbitec-component-select", {
                        attrs: {
                          "selected-component": _vm.selectedThinner,
                          "with-component-type-ids": "6,5,13"
                        },
                        on: { "component-selected": _vm.updateThinner }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      staticClass: "serbitec-small-top-margin",
                      attrs: {
                        label:
                          "Estabilidad de reconstitución a temperatura ambiente",
                        "label-position": "on-border",
                        type: {
                          "is-danger":
                            _vm.$v.reconstitutionStabilityAmbient.$error
                        },
                        message: {
                          "Se requiere introducir la estabilidad de reconstitución a temperatura ambiente":
                            !_vm.$v.reconstitutionStabilityAmbient.required &&
                            _vm.$v.reconstitutionStabilityAmbient.$error,
                          "Se requiere introducir un número decimal":
                            !_vm.$v.reconstitutionStabilityAmbient.decimal &&
                            _vm.$v.reconstitutionStabilityAmbient.$error
                        },
                        expanded: ""
                      }
                    },
                    [
                      _c(
                        "b-field",
                        {
                          attrs: {
                            type: {
                              "is-danger":
                                _vm.$v.reconstitutionStabilityAmbient.$error
                            }
                          }
                        },
                        [
                          _c("b-input", {
                            attrs: { type: "text", expanded: "" },
                            model: {
                              value:
                                _vm.$v.reconstitutionStabilityAmbient.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.reconstitutionStabilityAmbient,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.reconstitutionStabilityAmbient.$model"
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "control" }, [
                            _c("span", { staticClass: "button is-static" }, [
                              _vm._v("h")
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      staticClass: "serbitec-small-top-margin",
                      attrs: {
                        label: "Estabilidad de reconstitución en refrigeración",
                        "label-position": "on-border",
                        type: {
                          "is-danger":
                            _vm.$v.reconstitutionStabilityCooled.$error
                        },
                        message: {
                          "Se requiere introducir la estabilidad de reconstitución en refrigeración":
                            !_vm.$v.reconstitutionStabilityCooled.required &&
                            _vm.$v.reconstitutionStabilityCooled.$error,
                          "Se requiere introducir un número decimal":
                            !_vm.$v.reconstitutionStabilityCooled.decimal &&
                            _vm.$v.reconstitutionStabilityCooled.$error
                        },
                        expanded: ""
                      }
                    },
                    [
                      _c(
                        "b-field",
                        {
                          attrs: {
                            type: {
                              "is-danger":
                                _vm.$v.reconstitutionStabilityCooled.$error
                            }
                          }
                        },
                        [
                          _c("b-input", {
                            attrs: { type: "text", expanded: "" },
                            model: {
                              value:
                                _vm.$v.reconstitutionStabilityCooled.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.reconstitutionStabilityCooled,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression:
                                "$v.reconstitutionStabilityCooled.$model"
                            }
                          }),
                          _vm._v(" "),
                          _c("p", { staticClass: "control" }, [
                            _c("span", { staticClass: "button is-static" }, [
                              _vm._v("h")
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-field",
                    {
                      staticClass:
                        "serbitec-small-top-margin serbitec-medium-bottom-margin",
                      attrs: {
                        label: "Factor para calcular el volumen final",
                        "label-position": "on-border",
                        type: { "is-danger": _vm.$v.finalVolumeFactor.$error },
                        message: [
                          !_vm.$v.finalVolumeFactor.required &&
                          _vm.$v.finalVolumeFactor.$error
                            ? "Se requiere introducir el factor para calcular el volumen final. El factor para calcular el volumen final se obtiene dividiento el volumen final entre el volumen de la presentación"
                            : "El factor para calcular el volumen final se obtiene dividiento el volumen final entre el volumen de la presentación",
                          {
                            "Se requiere introducir un número decimal":
                              !_vm.$v.finalVolumeFactor.decimal &&
                              _vm.$v.finalVolumeFactor.$error
                          }
                        ],
                        expanded: ""
                      }
                    },
                    [
                      _c(
                        "b-field",
                        {
                          attrs: {
                            type: {
                              "is-danger": _vm.$v.finalVolumeFactor.$error
                            }
                          }
                        },
                        [
                          _c("b-input", {
                            attrs: { type: "text", expanded: "" },
                            model: {
                              value: _vm.$v.finalVolumeFactor.$model,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.$v.finalVolumeFactor,
                                  "$model",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "$v.finalVolumeFactor.$model"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$parent.close()
              }
            }
          },
          [_vm._v("\n            Cancelar\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button is-primary", on: { click: _vm.submitForm } },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.comesFromEdit ? "Editar" : "Agregar") +
                "\n        "
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }