<template>
    <div class="columns is-centered">
        <div
            class="column"
            :class="{
                'is-two-fifths': $mq === 'desktop',
                'is-half': $mq === 'touch'
            }"
        >
            <serbitec-reset-password-form
                :reset-token="resetToken"
            />
        </div>
    </div>
</template>

<script>
import serbitecResetPasswordForm from '../../components/landing/reset-password-form.vue';

export default {
    data () {
        return {
            resetToken: this.$route.query.reset_token
        };
    },
    components: {
        serbitecResetPasswordForm
    }
};
</script>