import {
    setRandomId,
    getAditiveMlFactorFromName
} from "../../utils";

const macronutrients = [
    {
        id: setRandomId(),
        name: "Aminoácidos",
        componentTypeId: 2,
        componentsAvailable: [],
        selectedComponent: null,
        quantity: null,
        unitSymbol: "ml"
    },
    {
        id: setRandomId(),
        name: "Dextrosa",
        componentTypeId: 3,
        componentsAvailable: [],
        selectedComponent: null,
        quantity: null,
        unitSymbol: "ml"
    },
    {
        id: setRandomId(),
        name: "Lípidos",
        componentTypeId: 4,
        componentsAvailable: [],
        selectedComponent: null,
        quantity: null,
        unitSymbol: "ml"
    },
    {
        id: setRandomId(),
        name: "Cloruro de sodio al 0.9%",
        componentTypeId: 5,
        componentsAvailable: [],
        selectedComponent: null,
        quantity: null,
        unitSymbol: "ml"
    },
    {
        id: setRandomId(),
        name: "Bolsa tricámara",
        componentTypeId: 7,
        componentsAvailable: [],
        selectedComponent: null,
        quantity: null,
        unitSymbol: "bolsa"
    }
];

const getDefaultState = () => {
    return {
        payer: {},
        medic: {},
        patient: {},
        mixtureMedicines: {},
        totalVolume: null,
        overfilling: '0',
        infusionTime: 24,
        injectableWater: null,
        macronutrients: macronutrients,
        electrolytes: [],
        aditives: [],
        dangerAlert: false,
        infusionSet: null
    };
};

const getDefaultOrder = () => {
    return {
        mixtureMedicines: {},
        totalVolume: null,
        overfilling: '0',
        infusionTime: 24,
        injectableWater: null,
        macronutrients: macronutrients,
        electrolytes: [],
        aditives: [],
        dangerAlert: false,
        infusionSet: null
    };
};

function resetMacronutrients ( state ) {
    state.macronutrients.map((macronutrient) => {
        macronutrient.componentsAvailable = [];
        macronutrient.selectedComponent = null;
        macronutrient.quantity = null;
        macronutrient.unitSymbol = 'ml';
    });
};

const state = getDefaultState();

const mutations = {
    UPDATE_TOTAL_VOLUME (state, data) {
        state.totalVolume = data;
    },
    UPDATE_OVERFILLING (state, data) {
        state.overfilling = data;
    },
    UPDATE_INFUSION_TIME (state, data) {
        state.infusionTime = data;
    },
    UPDATE_PAYER (state, data) {
        state.payer = data;
    },
    UPDATE_MEDIC (state, data) {
        state.medic = data;
    },
    UNSELECT_MEDIC (state) {
        resetMacronutrients(state);
        Object.assign(state, getDefaultOrder());
        state.medic = {};
        state.patient = {};
    },
    UPDATE_PATIENT (state, data) {
        state.patient = data;
    },
    UNSELECT_PATIENT (state) {
        resetMacronutrients(state);
        Object.assign(state, getDefaultOrder());
        state.patient = {};
    },
    SET_INJECTABLE_WATER ( state, data ) {
        state.injectableWater = data;
    },
    RESET_INJECTABLE_WATER ( state ) {
        state.injectableWater = null;
    },
    RESET_ORDER ( state ) {
        resetMacronutrients(state);
        Object.assign(state, getDefaultState());
    },
    SET_MACRONUTRIENTS ( state, data ) {
        state.macronutrients = data;
    },
    SET_COMPONENT_IN_MACRONUTRIENT ( state, data ) {
        var component = state.macronutrients.find(
            component => component.componentTypeId == data.componentTypeId
        );
        component.componentsAvailable = data.components;
    },
    SET_SELECTED_COMPONENT_ITEM ( state, data ) {
        const component = state.macronutrients.find( macrocomponent => macrocomponent.id == data.id );
        Object.assign( component, data );
    },
    UNSELECT_COMPONENT ( state, componentId ) {
        const component = state.macronutrients.find( macrocomponent => macrocomponent.id == componentId );
        const reset = {
            selectedComponent: null,
            quantity: null,
            unitSymbol: "ml" 
        };
        Object.assign( component, reset );
    },
    SET_ELECTROLYTES ( state, data ) {
        data.map ( component => {
            var defaultFields = {
                quantity: null,
                showInput: false,
                unitSymbol: 'ml'
            };
            Object.assign(component, defaultFields);
        } );
        state.electrolytes = data;
    },
    SET_SELECTED_ELECTROLYTE_ITEM ( state, data ) {
        const component = state.electrolytes.find( electrolyte => electrolyte.id == data.id );
        Object.assign( component, data );
    },
    UNSELECT_SELECTED_ELECROTLYTE_ITEM ( state, electrolyteId ) {
        const component = state.electrolytes.find( electrolyte => electrolyte.id == electrolyteId );
        const reset = {
            selectedComponent: null,
            quantity: null,
            unitSymbol: "ml" ,
            showInput: false,
        };
        Object.assign( component, reset ); 
    },
    SET_ADITIVES ( state, data ) {
        data.map ( component => {
            var defaultFields = {
                quantity: null,
                showInput: false,
                unitSymbol: 'ml'
            };
            Object.assign(defaultFields, getAditiveMlFactorFromName(component.name));
            Object.assign(component, defaultFields);
        } );
        state.aditives = data;
    },
    SET_SELECTED_ADITIVE_ITEM ( state, data ) {
        const component = state.aditives.find( aditive => aditive.id == data.id );
        Object.assign( component, data );
    },
    UNSELECT_SELECTED_ADITIVE_ITEM ( state, aditiveId ) {
        const component = state.aditives.find( aditive => aditive.id == aditiveId );
        const reset = {
            selectedComponent: null,
            quantity: null,
            unitSymbol: "ml" ,
            showInput: false,
        };
        Object.assign( component, reset );  
    },
    SET_DANGER_ALERT ( state ) {
        state.dangerAlert = true;
    },
    RESET_DANGER_ALERT ( state ) {
        state.dangerAlert = false;
    },
    UPDATE_INFUSION_SET (state, data) {
        state.infusionSet = (data.length > 0);
    },
};

export default {
    namespaced: true,
    state,
    mutations
};
  