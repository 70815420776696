var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns" }, [
    _c(
      "div",
      { staticClass: "column serbitec-box-margin-top-bottom is-two-fifths" },
      [
        _c("div", { staticClass: "box content is-small" }, [
          _c("h4", { staticClass: "subtitle" }, [
            _vm._v(
              "Usuario: " +
                _vm._s(_vm.medic.first_name) +
                " " +
                _vm._s(_vm.medic.last_name_1) +
                " " +
                _vm._s(_vm.medic.last_name_2)
            )
          ]),
          _vm._v(" "),
          _vm.payer &&
          Object.keys(_vm.payer).length !== 0 &&
          _vm.payer.constructor === Object
            ? _c("p", [
                _c("strong", [_vm._v("Cliente: ")]),
                _vm._v(
                  _vm._s(_vm.payer.invoicing_data.business_name) +
                    "\n                "
                ),
                _c("br"),
                _vm._v(" "),
                _c("strong", [_vm._v("Cédula médica: ")]),
                _vm._v(_vm._s(_vm.medic.medical_card) + "\n            ")
              ])
            : _vm._e()
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "column is-three-fifths serbitec-box-margin-top-bottom" },
      [
        _c("div", { staticClass: "box content is-small" }, [
          _c("h4", { staticClass: "subtitle" }, [
            _vm._v("Paciente: " + _vm._s(_vm.patient.name))
          ]),
          _vm._v(" "),
          _c("strong", [_vm._v("Diagnóstico: ")]),
          _vm._v(_vm._s(_vm.patient.diagnosis) + "\n            "),
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column" }, [
              _c("strong", [_vm._v("Talla: ")]),
              _vm._v(
                _vm._s(_vm.patient.height_in_meters) + "\n                    "
              ),
              _c("br"),
              _vm._v(" "),
              _c("strong", [_vm._v("Peso: ")]),
              _vm._v(
                _vm._s(_vm.patient.weight_in_kilograms) +
                  "\n                    "
              ),
              _c("br"),
              _vm._v(" "),
              _c("strong", [_vm._v("Edad: ")]),
              _vm._v(
                _vm._s(_vm.computeAge(_vm.patient.birth_date)) +
                  "\n                "
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "column is-half" }, [
              _c("strong", [_vm._v("Expediente: ")]),
              _vm._v(_vm._s(_vm.patient.file_id) + "\n                    "),
              _c("br"),
              _vm._v(" "),
              _c("strong", [_vm._v("Servicio: ")]),
              _vm._v(_vm._s(_vm.patient.location) + "\n                    "),
              _c("br"),
              _vm._v(" "),
              _c("strong", [_vm._v("Cama: ")]),
              _vm._v(_vm._s(_vm.patient.bed) + "\n                ")
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }