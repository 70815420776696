<template>
    <b-message v-if="selectedThinner" :class="{'is-warning': isWarning }">
        {{ this.mixtureAlert() }}
    </b-message>
</template>

<script>
import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    medicineThinners: medicine( id: $id ) {
        oncologic_thinners {
            id
            pivot {
                id
                max_recommended_concentration
                min_recommended_concentration
                reference
            }
        }
    }
}`;

export default {
    props: [ 'medicineId', 'thinnerId', 'medicineQuantity', 'medicineUnitSymbol' , 'thinnerQuantity' ],
    computed: {
        mixtureConcentration () {
            return ( this.medicineQuantity / this.thinnerQuantity ).toFixed(2);
        }
    },
    data () {
        return {
            isWarning: false
        };
    },
    methods: {
        mixtureAlert () {
            if ( this.selectedThinner.min_recommended_concentration && this.selectedThinner.max_recommended_concentration && this.mixtureConcentration ) {
                if ( ( this.selectedThinner.min_recommended_concentration == this.selectedThinner.max_recommended_concentration ) && ( this.mixtureConcentration != this.selectedThinner.min_recommended_concentration ) ) {
                    this.isWarning = true;
                    return 'La concentración no es la recomendada por ' + this.selectedThinner.reference + '. La concentración recomendada es de ' + this.selectedThinner.min_recommended_concentration + ' ' + this.medicineUnitSymbol + '/ml';
                }
                else if ( ( this.mixtureConcentration < this.selectedThinner.min_recommended_concentration ) || ( this.mixtureConcentration > this.selectedThinner.max_recommended_concentration ) ) {
                    this.isWarning = true;
                    return 'La concentración se sale del rango recomendado por ' + this.selectedThinner.reference + '. La concentración recomendada es entre ' + this.selectedThinner.min_recommended_concentration + ' ' + this.medicineUnitSymbol + '/ml y ' + this.selectedThinner.max_recommended_concentration + ' ' + this.medicineUnitSymbol +'/ml';
                } else {
                    this.isWarning = false;
                    return 'No se ha generado ninguna alerta para esta mezcla';
                }
            }
            else if ( !this.selectedThinner.min_recommended_concentration && this.selectedThinner.max_recommended_concentration && this.mixtureConcentration && ( this.mixtureConcentration > this.selectedThinner.max_recommended_concentration ) ) {
                this.isWarning = true;
                return 'La concentración es mayor a la máxima recomendada por ' + this.selectedThinner.reference + '. La concentración máxima recomendada es de ' + this.selectedThinner.max_recommended_concentration + ' ' + this.medicineUnitSymbol +'/ml';
            }
            else if ( !this.selectedThinner.max_recommended_concentration && this.selectedThinner.min_recommended_concentration && this.mixtureConcentration && ( this.mixtureConcentration < this.selectedThinner.min_recommended_concentration ) ) {
                this.isWarning = true;
                return 'La concentración es menor a la mínima recomendada por ' + this.selectedThinner.reference + '. La concentración mínima recomendada es de ' + this.selectedThinner.min_recommended_concentration + ' ' + this.medicineUnitSymbol +'/ml';
            } else {
                this.isWarning = false;
                return 'No se ha generado ninguna alerta para esta mezcla';
            }
        }
    },
    apollo: {
        selectedThinner: {
            query: query,
            variables () {
                var medicineId = this.medicineId;
                return {
                    id: medicineId 
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                const selectedThinner = data.medicineThinners.oncologic_thinners.find( thinner => thinner.id == this.thinnerId );
                return selectedThinner.pivot;
            }
        },
    }
};
</script>