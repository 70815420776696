var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("hr"),
      _vm._v(" "),
      _c("h1", { staticClass: "subtitle" }, [_vm._v("Electrolitos")]),
      _vm._v(" "),
      _c("serbitec-electrolytes-table", {
        attrs: { "table-data": _vm.electrolytes }
      }),
      _vm._v(" "),
      _vm._l(_vm.electrolytes, function(electrolyte) {
        return _c("div", { key: electrolyte.id }, [
          _c(
            "div",
            {
              staticClass: "columns",
              staticStyle: { "padding-bottom": "10px" }
            },
            [
              _c("div", { staticClass: "column" }, [
                _c(
                  "div",
                  { staticClass: "field" },
                  [
                    _c(
                      "b-checkbox",
                      {
                        attrs: { value: electrolyte.showInput },
                        on: {
                          input: function($event) {
                            return _vm.clearItemOnFalse($event, electrolyte)
                          }
                        }
                      },
                      [_c("small", [_vm._v(_vm._s(electrolyte.name))])]
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "column" },
                [
                  electrolyte.showInput
                    ? _c(
                        "b-field",
                        { attrs: { horizontal: "", expanded: "" } },
                        [
                          _c(
                            "b-field",
                            [
                              _c("b-input", {
                                directives: [
                                  {
                                    name: "cleave",
                                    rawName: "v-cleave",
                                    value: _vm.masks.custom,
                                    expression: "masks.custom"
                                  }
                                ],
                                attrs: {
                                  value: electrolyte.quantity,
                                  placeholder: "10.00",
                                  type: "text",
                                  expanded: ""
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.updateElectrolyteQuantity(
                                      $event,
                                      electrolyte.id
                                    )
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c(
                                "b-select",
                                {
                                  attrs: {
                                    value: electrolyte.unitSymbol,
                                    name: "unit_symbol"
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.updateElectrolyteUnitSymbol(
                                        $event,
                                        electrolyte.id
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { domProps: { value: "mEq" } }, [
                                    _vm._v("mEq")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { domProps: { value: "mEq/Kg" } },
                                    [_vm._v("mEq/Kg")]
                                  ),
                                  _vm._v(" "),
                                  _c("option", { domProps: { value: "ml" } }, [
                                    _vm._v("ml")
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }