<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <h1 class="title">Componentes</h1>
            </div>
            <div class="level-right">
                <a
                    @click="addComponent"
                    class="button"
                    append
                >
                    <span class="icon">
                        <font-awesome-icon icon="plus" />
                    </span>
                    <span> Agregar componente</span>
                </a>
            </div>
        </nav>
        <hr>
        <serbitec-components-table
            :components="components"
            :components-count="componentsCount"

            :page.sync="page"
            :pageSize="pageSize"
            :sortField.sync="sortField"
            :sortOrder.sync="sortOrder"

            @page-change="onPageChange"
            @sort="onSort"
            @reload-components="$apollo.queries.components.refresh()"
        />
    </div>
</template>

<script>
import serbitecComponentsTable from '../../../components/dashboard/medicine-components/table.vue';
import serbitecComponentModal from '../../../components/dashboard/medicine-components/modal.vue';
import gql from 'graphql-tag';

const query = gql` query ( $search: String, $page: Int, $limit: Int, $order_by: String, $order_direction: String )
{
    components: paginated_components( search: $search, page: $page, limit: $limit, order_by: $order_by, order_direction: $order_direction ) {
        total
        data {
            id
            name
            is_oncologic
            is_nutritional
            nutritional_field {
                id
                energy_input_in_kcal
                non_protein_energy_input_in_kcal
                grams_of_aminoacid
                grams_of_nitrogen
                osmotic_concentration_in_mosmol_l
                na_in_meq
                k_in_meq
                mg_in_meq
                ca_in_meq
                po4_in_meq
                cl_in_meq
                ace_in_meq
                ml_to_g_factor
                density_in_g_per_ml
            }
            component_type {
                id
                name
            }
        }
    }
}`;

export default {
    name: 'index',
    components: {
        serbitecComponentsTable
    },
    data () {
        return {
            search: '',
            page: 1,
            pageSize: 10,
            sortField: 'id',
            sortOrder:'asc',
            componentsCount: 0
        };
    },
    methods: {
        onPageChange( page ) {
            this.page = page;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
        addComponent () {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecComponentModal,
                hasModalCard: true,
                trapFocus: true,
                fullScreen: true,
                events: {
                    'success': () => this.$apollo.queries.components.refresh()
                }
            });
        }
    },
    apollo: {
        components: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    search: this.search,
                    page: this.page,
                    limit: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                this.componentsCount = data.components.total;
                return data.components.data;
            }
        },
    }
};
</script>