i<template>
    <div class="box">
        <b-table
            :data="payerMedicinePresentations"
            :loading="loading">

            <template slot-scope="props">
                <b-table-column label="Medicamento">
                    {{ props.row.payer_medicine.medicine.commercial_name }}
                </b-table-column>

                <b-table-column label="Cantidad" centered>
                    {{ props.row.medicine_presentation.component_quantity }} {{ props.row.payer_medicine.medicine.component_unit.symbol }}
                </b-table-column>

                <b-table-column label="Volumen" centered>
                    {{ props.row.medicine_presentation.volume_in_ml }} ml
                </b-table-column>

                <b-table-column label="Precio" centered>
                    <b-field
                    position="is-centered">
                        <p class="control">
                            <button class="button is-static">$</button>
                        </p>
                        <b-input
                            :value="props.row.price_in_cents_per_ml_or_pz / 100"
                            v-cleave="masks.numeral"
                            type="text"
                            @keyup.native.enter="onInputPricePerMl( props.row.id )">
                        </b-input>
                        <p class="control">
                            <button v-if="props.row.payer_medicine.medicine.component_unit.symbol == 'pz'" class="button is-static">MXN por pieza</button>
                            <button v-else class="button is-static">MXN por ml</button>
                        </p>
                    </b-field>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay precios por presentación dadas de alta para este cliente.</p>
                        <br>
                        <b-button type="is-primary" @click="$emit('add-medicine-presentations-prices')">Habilitar precios por presentación</b-button>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import Cleave from 'cleave.js';

const cleave = {
    name: 'cleave',
    bind(el, binding) {
        const input = el.querySelector('input');
        input._vCleave = new Cleave(input, binding.value);
    },
    unbind(el) {
        const input = el.querySelector('input');
        input._vCleave.destroy();
    }
};

export default {
    directives: { cleave },
    data () {
        return {
            rawPricePerMl: null,
            masks: {
                numeral: {
                    numeral: true
                }
            }
        };
    },
    props: [
        'payerMedicinePresentations',
        'loading',
    ],
    methods: {
        onInputPricePerMl( payerMedicinePresentationId ){
            let rawPricePerMl = event.target._vCleave.getRawValue();
            this.$emit('edit-price', payerMedicinePresentationId , rawPricePerMl );
        },
    }
};
</script>