var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-field",
        { staticClass: "serbitec-small-left-margin" },
        [
          _c(
            "b-radio-button",
            {
              attrs: { "native-value": true },
              model: {
                value: _vm.enabledPayerMedicines,
                callback: function($$v) {
                  _vm.enabledPayerMedicines = $$v
                },
                expression: "enabledPayerMedicines"
              }
            },
            [_c("span", [_vm._v("Activos")])]
          ),
          _vm._v(" "),
          _c(
            "b-radio-button",
            {
              attrs: { "native-value": false },
              model: {
                value: _vm.enabledPayerMedicines,
                callback: function($$v) {
                  _vm.enabledPayerMedicines = $$v
                },
                expression: "enabledPayerMedicines"
              }
            },
            [_c("span", [_vm._v("Inactivos")])]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }