var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav", { staticClass: "level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c(
            "div",
            { staticClass: "level-left" },
            [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "a",
            { staticClass: "button", on: { click: _vm.addPurchaseOrder } },
            [
              _c(
                "span",
                { staticClass: "icon" },
                [_c("font-awesome-icon", { attrs: { icon: "plus" } })],
                1
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  " Agregar orden de " +
                    _vm._s(_vm.isExternalWarehouse ? "ingreso" : "compra")
                )
              ])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.warehouseItem
        ? _c("b-message", [
            _c("nav", { staticClass: "level" }, [
              _c("div", { staticClass: "level-left" }, [
                _c("div", { staticClass: "level-item" }, [
                  _c("h1", { staticClass: "subtitle is-5" }, [
                    _vm._v("Lote: "),
                    _c("strong", [
                      _vm.warehouseItem.prefix
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.warehouseItem.prefix.prefix) + "-"
                            )
                          ])
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.warehouseItem.batch))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "level-right" })
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Nombre comercial: ")]),
              _vm._v(
                _vm._s(
                  _vm.warehouseItem.medicine_presentation.medicine
                    .commercial_name
                )
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Genérico: ")]),
              _vm._v(
                _vm._s(
                  _vm.warehouseItem.medicine_presentation.medicine.component
                    .name
                )
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Fabricante: ")]),
              _vm._v(
                _vm._s(
                  _vm.warehouseItem.medicine_presentation.medicine.manufacturer
                    .name
                )
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("serbitec-shop-order-table", {
        attrs: {
          "is-external-warehouse": _vm.isExternalWarehouse,
          "batch-id": _vm.batchId,
          "purchase-orders": _vm.purchaseOrders
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }