var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    { attrs: { "mobile-burger": _vm.login ? false : true, type: "is-light" } },
    [
      _c(
        "template",
        { slot: "brand" },
        [
          !_vm.login && _vm.$mq !== "desktop"
            ? _c(
                "b-navbar-item",
                {
                  on: {
                    click: function($event) {
                      return _vm.$emit("open-sidebar")
                    }
                  }
                },
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: "angle-double-right" }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-navbar-item",
            { attrs: { to: { path: "/" }, tag: "router-link" } },
            [_c("img", { attrs: { src: "/images/SERBITEC_logo2.png" } })]
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.login
        ? _c(
            "template",
            { slot: "end" },
            [
              !_vm.user.isExternalUser
                ? _c(
                    "b-navbar-item",
                    {
                      attrs: {
                        href: "https://mezclas-dev-docs.vitenskap.tech",
                        target: "_blank"
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "serbitec-extra-small-right-margin",
                        attrs: { icon: "question-circle" }
                      }),
                      _vm._v("\n            Ayuda\n        ")
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-navbar-dropdown",
                {
                  attrs: {
                    label: _vm.user ? _vm.user.first_name : "",
                    hoverable: "",
                    right: ""
                  }
                },
                [
                  _c(
                    "b-navbar-item",
                    {
                      attrs: { tag: "a" },
                      on: {
                        click: function($event) {
                          return _vm.logout()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "serbitec-extra-small-right-margin",
                        attrs: { icon: "sign-out-alt" }
                      }),
                      _vm._v("\n                Cerrar sesión\n            ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }