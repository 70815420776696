var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "subtitle" }, [_vm._v("Almacén del cliente")]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { novalidate: "true" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitForm($event)
          }
        }
      },
      [
        !_vm.payerWarehousePrefix
          ? _c(
              "b-message",
              [
                _vm._v(
                  "\n            Si el cliente cuenta con medicamentos propios en el almacén externo favor de seleccionar la casilla, introducir un prefijo y dar de alta el prefijo para el cliente\n            "
                ),
                _c(
                  "div",
                  { staticClass: "field serbitec-small-top-margin" },
                  [
                    _c(
                      "b-checkbox",
                      {
                        model: {
                          value: _vm.clientHasExternalWarehouse,
                          callback: function($$v) {
                            _vm.clientHasExternalWarehouse = $$v
                          },
                          expression: "clientHasExternalWarehouse"
                        }
                      },
                      [
                        _vm._v(
                          "\n                    El cliente cuenta con medicamentos en almacén\n                "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.clientHasExternalWarehouse
                  ? _c(
                      "b-field",
                      { attrs: { grouped: "", "group-multiline": "" } },
                      [
                        _c(
                          "b-field",
                          {
                            attrs: {
                              label: "Prefijo del almacén externo",
                              "label-position": "on-border",
                              type: {
                                "is-danger": _vm.$v.clientWarehousePrefix.$error
                              },
                              message: {
                                "Se requiere introducir el prefijo":
                                  !_vm.$v.clientWarehousePrefix.required &&
                                  _vm.$v.clientWarehousePrefix.$error
                              }
                            }
                          },
                          [
                            _c("b-input", {
                              model: {
                                value: _vm.$v.clientWarehousePrefix.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.clientWarehousePrefix,
                                    "$model",
                                    $$v
                                  )
                                },
                                expression: "$v.clientWarehousePrefix.$model"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("p", { staticClass: "control" }, [
                          _c("button", { staticClass: "button is-primary" }, [
                            _vm._v("Alta")
                          ])
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _c(
              "b-field",
              { attrs: { grouped: "", "group-multiline": "" } },
              [
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: "Prefijo del almacén externo",
                      "label-position": "on-border",
                      type: {
                        "is-danger": _vm.$v.clientWarehousePrefix.$error
                      },
                      message: {
                        "Se requiere introducir el prefijo":
                          !_vm.$v.clientWarehousePrefix.required &&
                          _vm.$v.clientWarehousePrefix.$error
                      }
                    }
                  },
                  [
                    _c("b-input", {
                      model: {
                        value: _vm.$v.clientWarehousePrefix.$model,
                        callback: function($$v) {
                          _vm.$set(_vm.$v.clientWarehousePrefix, "$model", $$v)
                        },
                        expression: "$v.clientWarehousePrefix.$model"
                      }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", { staticClass: "control" }, [
                  _c("button", { staticClass: "button is-primary" }, [
                    _vm._v("Editar")
                  ])
                ])
              ],
              1
            )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }