import { render, staticRenderFns } from "./electrolytes-form.vue?vue&type=template&id=027b1c2f&"
import script from "./electrolytes-form.vue?vue&type=script&lang=js&"
export * from "./electrolytes-form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/mezclas.serbitec.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('027b1c2f')) {
      api.createRecord('027b1c2f', component.options)
    } else {
      api.reload('027b1c2f', component.options)
    }
    module.hot.accept("./electrolytes-form.vue?vue&type=template&id=027b1c2f&", function () {
      api.rerender('027b1c2f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/dashboard/requests/nutritional-mixtures/electrolytes-form.vue"
export default component.exports