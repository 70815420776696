<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <h1 class="title">Agregar cliente</h1>
            </div>
            <div class="level-right">
                <router-link
                :to="{ path: '/payers' }"
                class="button">
                    <span class="icon">
                        <font-awesome-icon icon="caret-left" />
                    </span>
                    <span> Regresar</span>
                </router-link>
            </div>
        </nav>
        <hr>
        <serbitec-payer-form></serbitec-payer-form>
    </div>
</template>

<script>
import serbitecPayerForm from '../../../components/dashboard/payers/payer-form.vue';

export default {
    components: {
        serbitecPayerForm
    }
};
</script>