<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <h1 class="title">Registro de órdenes oncológicas</h1>
            </div>
            <div class="level-right" />
        </nav>
        <hr>
        <serbitec-oncologic-records-table
            :table-type="'oncologic'"
            :mixtures="orders"
            :mixtures-count="ordersCount"

            :loading.sync="loading"
            :page.sync="page"
            :pageSize="pageSize"
            :sortField.sync="sortField"
            :sortOrder.sync="sortOrder"

            @page-change="onPageChange"
            @sort="onSort"
        />
    </div>
</template>

<script>
import serbitecOncologicRecordsTable from '../../../../components/dashboard/external-user/records-table.vue';
import gql from 'graphql-tag';
import { mapState } from 'vuex';

const query = gql` query ( $medic_id: Int, $payer_id: Int, $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String )
{
    orders: oncologic_orders( medic_id: $medic_id, payer_id: $payer_id, page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction ) {
        id
        delivery_date
        patient {
            id
            name
        }
        medic {
            id
            payer {
                id
                invoicing_data {
                    id
                    business_name
                }
            }
        }
        mixtures {
            id
            state {
                id
            }
        }
        requested_flasks {
            id
            state {
                id
            }
        }
    }
    ordersCount: table_counts( count_type: "oncologic_orders", medic_id: $medic_id, payer_id: $payer_id ) {
        oncologic_orders
    }
}`;

function initialDataState () {
    return {
        loading: false,
        ordersCount: null,
        page: 0,
        pageSize: 10,
        sortField: 'id',
        sortOrder:'desc',
        medicId: null,
        payerId: null
    };
}

export default {
    name:'index',
    components: {
        serbitecOncologicRecordsTable
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.$apollo.queries.orders.refetch();
        });
    },
    data: initialDataState,
    computed: {
        ...mapState({
            user: state => state.user
        }),
    },
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        }
    },
    apollo: {
        orders: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    payer_id: this.user.payer.id,
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                this.ordersCount = data.ordersCount.oncologic_orders;
                return data.orders;
            }
        },
    }
};
</script>