<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right"></div>
        </nav>
        <hr>
        <div v-if="order">
            <div class="box">
                <h1 class="title has-text-centered">Orden OO-{{ orderId }}</h1>
            </div>
            <serbitec-horizontal-order-data
            :medic="order.medic"
            :patient="order.patient"></serbitec-horizontal-order-data>
            <div v-for="( mixture, index ) in order.mixtures" :key="index">
                <div class="box" style="margin-bottom: 15px">
                    <h1 class="subtitle">Mezcla con lote: LO-{{ mixture.id }}</h1>
                    <serbitec-oncologic-elements-table
                    :elements="mixture.elements"></serbitec-oncologic-elements-table>
                    <serbitec-oncologic-alerts
                    :elements="mixture.elements"></serbitec-oncologic-alerts>
                </div>
            </div>

            <div v-if="order.requested_flasks.length" class="box" style="margin-bottom: 15px">
                <h1 class="subtitle">Frascos</h1>
                <serbitec-oncologic-flasks-table
                :flasks="order.requested_flasks"></serbitec-oncologic-flasks-table>
            </div>

            <b-field style="margin-top:25px" position="is-centered" grouped>
                <p class="control has-text-centered">
                    <router-link
                        :to="{ path: '/oncologic_orders_authorizer/edit', query: { orderId: this.orderId } }"
                        class="button"
                    >
                        <span class="icon">
                            <font-awesome-icon icon="edit" />
                        </span>
                        <span> Editar orden</span>
                    </router-link>
                </p>
                <p class="control has-text-centered">
                    <button @click="authorizeMixture" class="button is-primary">
                        Autorizar orden
                    </button>
                </p>
            </b-field>
        </div>
    </div>
</template>

<script>
import serbitecHorizontalOrderData from '../../../components/dashboard/utils/horizontal-order-data.vue';
import serbitecOncologicElementsTable from '../../../components/dashboard/utils/oncologic-elements-table.vue';
import serbitecOncologicFlasksTable from '../../../components/dashboard/utils/oncologic-flasks-table.vue';
import serbitecOncologicAlerts from '../../../components/dashboard/utils/oncologic/alerts.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';
import axios from 'axios';

import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    oncologic_order( id: $id ) {
        id
        delivery_date
        medic {
            id
            first_name
            last_name_1
            last_name_2
            email
            medical_card
            payer {
                id
                invoicing_data {
                    id
                    business_name
                }
            }
        }
        patient {
            id
            name
            diagnosis
            height_in_meters
            weight_in_kilograms
            birth_date
            file_id
            location
            bed
        }
        mixtures{
            id
            elements{
                id
                medicine_quantity
                medicine {
                    id
                    commercial_name
                    component_unit {
                        id
                        symbol
                    }
                }
                component {
                    id
                    name
                    component_type {
                        id
                    }
                }
            }
        }
        requested_flasks {
            id
            number_of_flasks
            medicine_presentation {
                id
                volume_in_ml
                component_quantity
                medicine {
                    id
                    commercial_name
                    component_unit {
                        id
                        symbol
                    }
                    component {
                        id
                        name
                    }
                }
            }
        }
    }
}`;

export default {
    props: {
        orderId: {
            type: [Number, String],
            default: 0 | '0'
        }
    },
    components: {
        serbitecHorizontalOrderData,
        serbitecOncologicElementsTable,
        serbitecOncologicFlasksTable,
        serbitecOncologicAlerts,
        serbitecBreadcumbs
    },
    data () {
        return {
            route: [
                {
                    label: 'Revisión de órdenes oncológicas',
                    to: '/oncologic_orders_authorizer',
                    active: false
                },
                {
                    label: 'Detalles de la orden',
                    to: '/oncologic_orders_authorizer/authorize',
                    active: true
                }
            ]
        };
    },
    methods: {
        getRequestParams () {
            const requestParams = {
                id: this.orderId
            };
            return requestParams;
        },
        authorizeMixture () {
            var postParams = this.getRequestParams();
            var url = '/api/oncologic/orders/authorize';
            this.makeRequest( url, axios.post, postParams );
        },
        makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method( url, params )
            .then(function () {
                this.$serbitec.finishLoading();
                this.$router.push({ path: '/oncologic_orders_authorizer', query: { success: true } });
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$serbitec.finishLoading();
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
        }
    },
    apollo: {
        order: {
            query: query,
            fetchPolicy: 'cache-and-network',
            variables () {
                return {
                    id: this.orderId
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update ( data ) {
                return data.oncologic_order;
            }
        },
    }
};
</script>
