<template>
    <b-select
        :value="selectedComponent"
        @input="option => $emit('update:component-type-selected', option)"
        expanded
        :disabled="disabled"
    >
        <option
            v-for="option in components"
            :key="option.id"
            :value="option"
        >
            {{ option.name }}
        </option>
    </b-select>
</template>

<script>
import gql from 'graphql-tag';
const query = gql` query ( $component_type_category_id: Int )
{
    components:paginated_component_types ( component_type_category_id: $component_type_category_id ) {
        data {
            id
            name
        }
    }
}`;

export default {
    props: {
        componentTypeCategoryId: {
            type: Number,
            default: null
        },
        selectedComponent: {
            type: Object,
            default: () => {}
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    apollo: {
        components: {
            query: query,
            variables () {
                return {
                    component_type_category_id: this.componentTypeCategoryId
                };
            },
            watchLoading (isLoading) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update (data) {
                return data.components.data;
            }
        }
    }
};
</script>