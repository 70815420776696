var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      [
        _c("serbitec-navbar", {
          on: {
            "open-sidebar": function($event) {
              _vm.openSideBar = true
            }
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("serbitec-sidebar", {
          attrs: { open: _vm.openSideBar, menu: _vm.menu },
          on: {
            "close-sidebar": function() {
              return _vm.$mq === "desktop"
                ? (_vm.openSideBar = true)
                : (_vm.openSideBar = false)
            },
            "on-cancel": function() {
              return _vm.$mq === "desktop"
                ? (_vm.openSideBar = true)
                : (_vm.openSideBar = false)
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }