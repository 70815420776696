var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass: "breadcrumb is-medium is-marginless",
      attrs: { "aria-label": "breadcrumbs" }
    },
    [
      _c(
        "ul",
        _vm._l(_vm.route, function(item) {
          return _c(
            "li",
            { key: item.id, class: { "is-active": item.active } },
            [
              _c("router-link", { attrs: { to: item.to } }, [
                _vm._v(
                  "\n                " + _vm._s(item.label) + "\n            "
                )
              ])
            ],
            1
          )
        }),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }