<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <h1 class="title">Pedidos</h1>
            </div>
            <div class="level-right" />
        </nav>
        <hr>
        <b-tabs
            v-model="activeTab"
            type="is-toggle"
            expanded
        >
            <b-tab-item label="Oncológico">
                <br>
                <serbitec-oncologic-requested-items-check-table
                    :comes-from-external-warehouse="true"
                />
            </b-tab-item>
            <b-tab-item label="Nutricional">
                <br>
                <serbitec-nutritional-requested-items-check-table
                    :comes-from-external-warehouse="true"
                />
            </b-tab-item>
        </b-tabs>
    </div>
</template>

<script>
import serbitecOncologicRequestedItemsCheckTable from '../../../components/dashboard/warehouse-requests/oncologic-requested-items-check-table.vue';
import serbitecNutritionalRequestedItemsCheckTable from '../../../components/dashboard/warehouse-requests/nutritional-requested-items-check-table.vue';

export default {
    name:'index',
    components: {
        serbitecOncologicRequestedItemsCheckTable,
        serbitecNutritionalRequestedItemsCheckTable
    },
    data () {
        return {
            activeTab: 0
        };
    }
};
</script>