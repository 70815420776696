<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                {{ comesFromEdit ? 'Editar fabricante' : 'Agregar fabricante' }}
            </p>
        </header>
        <section class="modal-card-body">
            <b-field
                label="Nombre del fabricante"
                label-position="on-border"
                :type="{'is-danger' : $v.name.$error }"
                :message="{
                    'Se requiere introducir el nombre del fabricante' : !$v.name.required && $v.name.$error
                }"
                expanded
            >
                <b-input
                    v-model="$v.name.$model"
                    type="text"
                />
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                Cancelar
            </button>
            <button class="button is-primary" @click="submitForm">
                {{ comesFromEdit ? 'Editar' : 'Agregar' }}
            </button>
        </footer>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    props: {
        manufacturer: {
            type: Object,
            default: () => {}
        }
    },
    beforeMount () {
        if (this.manufacturer && Object.keys(this.manufacturer).length !== 0 && this.manufacturer.constructor === Object) {
            this.comesFromEdit = true;
            this.name = this.manufacturer.name;
        }
    },
    data () {
        return {
            name: '',
            comesFromEdit: false
        };
    },
    methods: {
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                if (this.manufacturer && Object.keys(this.manufacturer).length !== 0 && this.manufacturer.constructor === Object) {
                    this.editManufacturer(this.manufacturer);
                } else {
                    this.addManufacturer();
                }   
            }
        },
        addManufacturer () {
            var postParams = { name: this.name };
            var url = '/api/medicine_manufacturers';
            this.makeRequest(url, this.$axios.post, postParams);
        },
        editManufacturer (manufacturer) {
            var postParams = { name: this.name };
            var url = '/api/medicine_manufacturers/' + manufacturer.id;
            this.makeRequest(url, this.$axios.put, postParams);
        },
        makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method(url,params)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha añadido o editado el fabricante'
                });
                this.$serbitec.finishLoading();
                this.$emit('success');
                this.$emit('close');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    },
    validations: {
        name: {
            required
        }
    }
};
</script>