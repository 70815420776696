<template>
    <div>
        <form
        @submit.prevent="submitPayerMedicinePresentationForm"
        novalidate="true">
            <h1 class="subtitle">Agregar precio por presentación</h1>
            <b-field v-for="presentation in $v.presentations.$each.$iter" :key="presentation.$model.id" grouped group-multiline>
                <b-field>
                    <b-field>
                        <p class="control">
                            <span class="button is-static">
                                Presentación: {{ presentation.$model.volumeInMl }} ml
                            </span>
                        </p>
                    </b-field>
                </b-field>

                <b-field
                :type="{'is-danger' : presentation.$error }"
                :message="{
                    'Se requiere introducir el precio de la presentación' : !presentation.priceInCents.required && presentation.$error
                }"
                expanded>
                    <b-field
                    :type="{'is-danger' : presentation.$error }">
                        <p class="control">
                            <button class="button is-static">$</button>
                        </p>
                        <b-input
                            v-model="presentation.priceInCents.$model"
                            @input.native="onInputPricePerMlOrPz(presentation.$model.id)"
                            v-cleave="masks.numeral"
                            type="text"
                            expanded>
                        </b-input>
                        <p class="control">
                            <button v-if="medicineUnitSymbol == 'pz'" class="button is-static">MXN por pieza</button>
                            <button v-else class="button is-static">MXN por ml</button>
                        </p>
                        <p v-if="pricePerFlask" class="control">
                            <span class="button is-static">
                                Precio por frasco: ${{ ( presentation.$model.volumeInMl * presentation.$model.rawPriceInCents ).toFixed(2) }} MXN
                            </span>
                        </p>
                    </b-field>
                </b-field>
            </b-field>
            <br>
            <b-field grouped position="is-centered">
                <p class="control">
                    <button type="submit" class="button is-primary">
                        <span>
                            Agregar medicamento
                        </span>
                    </button>
                </p>
            </b-field>
        </form>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import Cleave from 'cleave.js';

const cleave = {
    name: 'cleave',
    bind(el, binding) {
        const input = el.querySelector('input');
        input._vCleave = new Cleave(input, binding.value);
    },
    unbind(el) {
        const input = el.querySelector('input');
        input._vCleave.destroy();
    }
};

function initialDataState() {
    return {
        presentations: [],
        masks: {
            numeral: {
                numeral: true
            }
        }
    };
}

export default {
    directives: { cleave },
    props: ['medicinePresentations', 'payerId', 'medicineUnitSymbol', 'pricePerFlask'],
    data: initialDataState,
    beforeMount(){
        this.medicinePresentations.map( medicinePresentation => {
            let obj = {};
            obj.id = medicinePresentation.id;
            obj.rawPriceInCents = null;
            obj.volumeInMl = medicinePresentation.volume_in_ml;
            obj.priceInCents = null;
            this.presentations.push(obj);
        } );
    },
    methods: {
        onInputPricePerMlOrPz( presentationId ) {
            this.presentations.map( medicinePresentation => {
                if ( medicinePresentation.id == presentationId ) {
                    medicinePresentation.rawPriceInCents = event.target._vCleave.getRawValue();
                }
            } );
        },
        submitPayerMedicinePresentationForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.addPayerMedicinePresentation();
            }
        },
        getParams () {
            const payerMedicinePresentations = [];
            this.presentations.map( presentation => {
                let obj = {
                    medicine_presentation_id: presentation.id,
                    price_in_cents_per_ml_or_pz: parseInt( ( parseFloat( presentation.rawPriceInCents ) * 100).toFixed(0) )
                };
                payerMedicinePresentations.push(obj);
            } );

            return payerMedicinePresentations;
        },
        addPayerMedicinePresentation () {
            console.warn(this.getParams());
        },
        async makeRequest( url, method, params ){
            this.loading = true;
            try {
                await method(url,params);
                // The notification goes here!
                this.$emit('close-form');
                this.loading = false;
            } catch (error) {
                // The notification goes here!
                // this.$emit('error', error.response.data.error);
                this.loading = false;
            }
        }
    },
    validations () {
        const validations = {
            presentations: {
                $each: {
                    priceInCents: {
                        required
                    }
                }
            }
        };
        return validations;
    }
};
</script>