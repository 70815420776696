var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.payerMedicinePresentations
    ? _c(
        "div",
        [
          _c("nav", { staticClass: "level" }, [
            _c("div", { staticClass: "level-left" }, [
              _c(
                "div",
                { staticClass: "level-left" },
                [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "level-right" })
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm.payerMedicinePresentations.length > 0
            ? _c("nav", { staticClass: "level" }, [
                _c("div", { staticClass: "level-left" }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "level-right" },
                  [
                    _c("b-field", [
                      _c("p", { staticClass: "control" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button is-small",
                            on: {
                              click: _vm.confirmDeleteMedicinePresentations
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "icon" },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "exchange-alt" }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(" Habilitar precio por medicamento")
                            ])
                          ]
                        )
                      ])
                    ])
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("serbitec-payer-medicine-presentations-table", {
            attrs: {
              "payer-medicine-presentations": _vm.payerMedicinePresentations,
              loading: _vm.loading
            },
            on: {
              "add-medicine-presentations-prices":
                _vm.addMedicinePresentationsPrices,
              "edit-price": _vm.editMedicinePresentationPrice
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }