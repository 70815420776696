<template>
    <div class="box">
        <b-loading :active.sync="loading"></b-loading>
        <b-table
            :data="isEmpty ? [] : requestedMedicines"
            :loading="loadingTable">

            <template slot-scope="props">
                <b-table-column label="Lote" numeric centered>
                    <p><strong v-if="props.row.prefix">{{props.row.prefix.prefix}}-</strong> {{ props.row.batch }}</p>
                </b-table-column>

                <b-table-column label="Nombre comercial">
                    {{ props.row.medicine_commercial_name }}
                </b-table-column>

                <b-table-column label="Fabricante">
                    {{ props.row.manufacturer_name }}
                </b-table-column>

                <b-table-column label="Presentación" centered>
                    <b-tag v-if="props.row.isEvaBag">
                        {{ props.row.medicine_presentation_volume_in_ml }} ml
                    </b-tag>
                    <b-tag v-if="props.row.isPiece">
                        {{ props.row.component_quantity }} {{ props.row.component_unit_symbol }}
                    </b-tag>
                    <b-tag v-else-if="props.row.isOncologicMedicine">
                        {{ props.row.component_quantity }} {{ props.row.component_unit_symbol }} en {{ props.row.medicine_presentation }} ml
                    </b-tag>
                    <b-tag v-else>
                        {{ props.row.medicine_presentation }} ml
                    </b-tag>
                </b-table-column>

                <b-table-column label="Fecha de caducidad" centered>
                    {{ props.row.expiry_date }}
                </b-table-column>

                <b-table-column label="Frascos solicitados" centered>
                    {{ props.row.requested_flasks }}
                </b-table-column>

                <b-table-column centered>
                    <a class="button is-small is-danger" @click="$store.commit( 'internalWarehouseRequest/REMOVE_MEDICINE_FROM_REQUEST', props.row.id )"><font-awesome-icon icon="trash-alt" /></a>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay medicinas añadidas a la solicitud para el almacén externo, seleccione el medicamento, el lote y la cantidad de frascos para realizar la solicitud.</p>
                    </div>
                </section>
            </template>
        </b-table>
        <b-message
        size="is-small"
        :title="notification.title"
        :active.sync="notification.isActive"
        :type="notification.type"
        aria-close-label="Close message"
        style="margin-top:15px">
            {{ notification.message }}
        </b-message>
        <b-field v-if="!requestIsEmpty" style="margin-top:15px">
            <p class="control has-text-centered">
                <button class="button is-primary" @click="submitRequestToExternalWarehouse">
                    Realizar pedido
                </button>
            </p>
        </b-field>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

function initialDataState () {
    return {
        loadingTable: false,
        loading: false,
        isEmpty: false,
        notification: {
            isActive: false,
            message: null,
            title: null,
            type: null
        },
    };
}
export default {
    props: ['internalWarehouseType'],
    data: initialDataState,
    computed: {
        ...mapState({
            requestedMedicines: state => state.internalWarehouseRequest.requestedMedicines
        }),
        requestIsEmpty () {
            if ( this.requestedMedicines === undefined || this.requestedMedicines.length == 0 ) {
                return true;
            } else {
                return false;
            }
        }
    },
    methods: {
        getRequestParams () {
            var items = [];
            this.requestedMedicines.map( item => {
                    const itemObj = {
                        medicine_warehouse_item_id: item.external_warehouse_item_id,
                        number_of_flasks: item.requested_flasks
                    };
                    items.push(itemObj);
                } );
            const requestParams = {
                items
            };
            return requestParams;
        },
        submitRequestToExternalWarehouse () {
            var postParams = this.getRequestParams();
            if ( this.internalWarehouseType == 'oncologic' ) {
                var url = '/api/medicine_warehouse/requests/oncologic';
            } else if ( this.internalWarehouseType == 'nutritional' ) {
                var url = '/api/medicine_warehouse/requests/nutritional';
            }
            this.makeRequest(url, axios.post, postParams);
        },
        makeRequest ( url, method, params ) {
            this.loading = true;
            method(url,params)
            .then(function () {
                Object.assign(this.$data, initialDataState());
                this.$store.commit('internalWarehouseRequest/RESET_REQUEST');
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha realizado el pedido al almacén externo de manera exitosa'
                });
            }.bind(this))
            .catch(function (error) {
                console.error(error);

                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
        }
    }
};
</script>