import { setRandomId } from '../../utils';

const getDefaultState = () => {
    return {
        requestedMedicines: []
    };
};

const state = getDefaultState();

const mutations = {
    ADD_MEDICINE_TO_REQUEST ( state, data ) {
        const requestedMedicine = {};
        if ( data.medicine_presentation.medicine.component_unit.symbol == 'pz' ) {
            requestedMedicine.isPiece = true;
            requestedMedicine.component_quantity = data.medicine_presentation.component_quantity;
            requestedMedicine.component_unit_symbol = data.medicine_presentation.medicine.component_unit.symbol;
        } else if ( data.medicine_presentation.medicine.component.component_type.id == 1 ) {
            requestedMedicine.isOncologicMedicine = true;
            requestedMedicine.component_quantity = data.medicine_presentation.component_quantity;
            requestedMedicine.component_unit_symbol = data.medicine_presentation.medicine.component_unit.symbol;
            requestedMedicine.medicine_presentation = data.medicine_presentation.volume_in_ml;
        } else {
            requestedMedicine.medicine_presentation = data.medicine_presentation.volume_in_ml;
        }
        if ( data.medicine_presentation.medicine.component.component_type.id == 12 ) {
            requestedMedicine.isEvaBag = true;
            requestedMedicine.medicine_presentation_volume_in_ml = data.medicine_presentation.volume_in_ml;
        }
        requestedMedicine.id = setRandomId();
        requestedMedicine.medicine_commercial_name = data.medicine_presentation.medicine.commercial_name;
        requestedMedicine.manufacturer_name = data.medicine_presentation.medicine.manufacturer.name;
        requestedMedicine.external_warehouse_item_id = data.id;
        requestedMedicine.prefix = data.prefix;
        requestedMedicine.batch = data.batch;
        requestedMedicine.expiry_date = data.expiry_date;
        requestedMedicine.requested_flasks = data.requestedFlasks;
        state.requestedMedicines.push( requestedMedicine );
    },
    REMOVE_MEDICINE_FROM_REQUEST ( state, itemId ) {
        var index;
        state.requestedMedicines.map ( requestedMedicine => {
            if ( requestedMedicine.id == itemId ) {
                index = state.requestedMedicines.indexOf( requestedMedicine );
            }
        });
        state.requestedMedicines.splice(index,1);
    },
    RESET_REQUEST ( state ) {
        Object.assign(state, getDefaultState());
    }
};

export default {
    namespaced: true,
    state,
    mutations
};
  