<template>
    <div>
        <serbitec-general-data
            order-type="nutritional"
            @next-step="$emit('next-step')"
        />
    </div>
</template>

<script>
import serbitecGeneralData from '../../general-data.vue';
import { mapState } from 'vuex';
import gql from 'graphql-tag';

const query = gql` query ( $id: Int! )
{
    payer( id: $id ) {
        id
        invoicing_data {
            id
            business_name
            rfc
        }
    }
}`;

export default {
    components: {
       serbitecGeneralData
    },
    beforeMount () {
        if (this.user.isExternalUser) {
            this.$serbitec.startLoading();
            this.$apollo.query({
                query,
                variables: {
                    id: this.user.payer.id
                }
            }).then((response) => {
                this.$store.commit('nutritionalOrder/UPDATE_PAYER', response.data.payer);
                if (this.user.isMedic) {
                    this.$store.commit('nutritionalOrder/UPDATE_MEDIC', this.user);
                }
                this.$serbitec.finishLoading();
            });
        }
    },
    computed: {
        ...mapState ({
            user: state => state.user
       })
    }
};
</script>