var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "level" }, [
      _c(
        "div",
        { staticClass: "level-left" },
        [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" })
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _vm.mixture
      ? _c(
          "div",
          [
            _c("div", { staticClass: "box" }, [
              _c("h1", { staticClass: "title has-text-centered" }, [
                _vm._v(
                  "Orden: ON-" +
                    _vm._s(_vm.mixture.order.id) +
                    " | Lote: LN-" +
                    _vm._s(_vm.mixture.id)
                )
              ])
            ]),
            _vm._v(" "),
            _c("serbitec-horizontal-order-data", {
              attrs: {
                medic: _vm.mixture.order.medic,
                patient: _vm.mixture.order.patient
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "box" }, [
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column is-one-third" }, [
                  _vm._v("\n                    Volumen total: "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.mixture.total_volume_in_ml) + " ml")
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "column is-one-third" }, [
                  _vm._v("\n                    Sobrellenado: "),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.mixture.overfill_in_ml) + " ml")
                  ])
                ]),
                _vm._v(" "),
                _vm.water
                  ? _c("div", { staticClass: "column is-one-third" }, [
                      _vm._v("\n                    Agua inyectable: "),
                      _c("strong", [_vm._v(_vm._s(_vm.water) + " ml")])
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("b-message", [
              _c("h1", [_c("strong", [_vm._v("Comentarios: ")])]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.mixture.comments))])
            ]),
            _vm._v(" "),
            _c("serbitec-nutritional-elements-table", {
              attrs: {
                elements: _vm.mixture.elements,
                "total-volume": _vm.mixture.total_volume_in_ml
              }
            }),
            _vm._v(" "),
            _c("serbitec-electrolytes-alerts", {
              staticClass: "serbitec-small-top-margin",
              attrs: {
                electrolytes: _vm.electrolytes,
                "computed-total-volume": _vm.mixture.total_volume_in_ml
              }
            }),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticStyle: { "margin-top": "25px" },
                attrs: { position: "is-centered", grouped: "" }
              },
              [
                _c(
                  "p",
                  { staticClass: "control has-text-centered" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "button",
                        attrs: {
                          to: {
                            path: "/nutritional_mixtures_authorizer/edit",
                            query: { mixtureId: this.mixtureId }
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon" },
                          [
                            _c("font-awesome-icon", { attrs: { icon: "edit" } })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v(" Editar mezcla")])
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", { staticClass: "control has-text-centered" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button is-primary",
                      on: { click: _vm.authorizeMixture }
                    },
                    [
                      _vm._v(
                        "\n                    Autorizar mezcla\n                "
                      )
                    ]
                  )
                ])
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }