var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      Object.keys(_vm.patient).length !== 0 &&
      _vm.patient.constructor === Object
        ? _c("serbitec-horizontal-general-data", {
            attrs: { payer: _vm.payer, medic: _vm.medic, patient: _vm.patient }
          })
        : _vm._e(),
      _vm._v(" "),
      !(
        _vm.emptyMacronutrients &&
        _vm.electrolytes.length == 0 &&
        _vm.aditives.length == 0
      )
        ? _c(
            "div",
            [
              _c("nav", { staticClass: "level" }, [
                _c("div", { staticClass: "level-left" }),
                _vm._v(" "),
                _c("div", { staticClass: "level-right" }, [
                  _c("p", { staticClass: "control" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: { disabled: _vm.isNewPatient },
                        on: { click: _vm.loadPreviousMixture }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "undo-alt" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("Cargar mezcla anterior")])
                      ]
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("serbitec-water-form", {
                attrs: { "computed-total-volume": _vm.computedTotalVolume }
              }),
              _vm._v(" "),
              _c("serbitec-water-alerts", {
                attrs: { "computed-total-volume": _vm.computedTotalVolume }
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                [
                  _vm.emptyMacronutrients
                    ? _c(
                        "b-message",
                        { attrs: { type: "is-warning", "has-icon": "" } },
                        [
                          _c("strong", { staticClass: "is-size-4" }, [
                            _vm._v("Macronutrientes")
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n                No tiene ningún macronutriente dado de alta.\n                "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              "Por favor, contacte al administrador para que realice los ajustes pertinentes."
                            )
                          ])
                        ]
                      )
                    : _c("serbitec-macronutrients-form")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "serbitec-small-top-margin" },
                [
                  _vm.electrolytes && _vm.electrolytes.length
                    ? _c(
                        "div",
                        [
                          _c("serbitec-electrolytes-form"),
                          _vm._v(" "),
                          _c("serbitec-electrolytes-alerts", {
                            attrs: {
                              electrolytes: _vm.electrolytes,
                              "computed-total-volume": _vm.computedTotalVolume
                            }
                          })
                        ],
                        1
                      )
                    : _c(
                        "b-message",
                        { attrs: { type: "is-warning", "has-icon": "" } },
                        [
                          _c("strong", { staticClass: "is-size-4" }, [
                            _vm._v("Electrolitos")
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n                No tiene ningún electrolito dado de alta.\n                "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              "Por favor, contacte al administrador para que realice los ajustes pertinentes."
                            )
                          ])
                        ]
                      )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "serbitec-small-top-margin" },
                [
                  _vm.aditives && _vm.aditives.length
                    ? _c("serbitec-aditives-form")
                    : _c(
                        "b-message",
                        { attrs: { type: "is-warning", "has-icon": "" } },
                        [
                          _c("strong", { staticClass: "is-size-4" }, [
                            _vm._v("Aditivos")
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n                No tiene ningún aditivo dado de alta.\n                "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", [
                            _vm._v(
                              "Por favor, contacte al administrador para que realice los ajustes pertinentes."
                            )
                          ])
                        ]
                      )
                ],
                1
              ),
              _vm._v(" "),
              _c("serbitec-additional-information", {
                attrs: { "computed-total-volume": _vm.computedTotalVolume }
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("hr")
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "serbitec-medium-bottom-margin" },
            [
              _c(
                "b-message",
                { attrs: { type: "is-danger", "has-icon": "" } },
                [
                  _c("strong", { staticClass: "is-size-4" }, [
                    _vm._v("Contacte al administrador")
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(
                    "\n            No tiene ningún componente nutricional dado de alta.\n            "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "Por favor, contacte al administrador para que realice los ajustes pertinentes."
                    )
                  ])
                ]
              )
            ],
            1
          ),
      _vm._v(" "),
      _c("nav", { staticClass: "level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("p", { staticClass: "control" }, [
            _c(
              "button",
              {
                staticClass: "button",
                on: {
                  click: function($event) {
                    return _vm.$emit("previous-step")
                  }
                }
              },
              [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [_c("font-awesome-icon", { attrs: { icon: "caret-left" } })],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v("Anterior ")])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" }, [
          _c("p", { staticClass: "control" }, [
            _c(
              "button",
              {
                staticClass: "button",
                attrs: { disabled: _vm.dangerAlert },
                on: {
                  click: function($event) {
                    return _vm.$emit("next-step")
                  }
                }
              },
              [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [_c("font-awesome-icon", { attrs: { icon: "caret-right" } })],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v(" Siguiente")])
              ]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }