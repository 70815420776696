<template>
    <div>
        <h1 class="title">Registro de mezclas nutricionales</h1>
        <hr>
        <serbitec-filters
            :active-filters="filterOptions"
            comes-from-page="nutritionalMixturesRecords"
        />
        <br>
        <serbitec-nutritional-records-table
        :table-type="'nutritional'"
        :mixtures="mixtures"
        :mixtures-count="mixturesCount"

        :loading.sync="loading"
        :page.sync="page"
        :pageSize="pageSize"
        :sortField.sync="sortField"
        :sortOrder.sync="sortOrder"

        @page-change="onPageChange"
        @sort="onSort"
        @item-canceled="mixtureCanceled"></serbitec-nutritional-records-table>
    </div>
</template>

<script>
import serbitecNutritionalRecordsTable from '../../../components/dashboard/utils/records-table.vue';
import serbitecFilters from '../../../components/dashboard/utils/filters.vue';
import { mapState } from 'vuex';
import axios from 'axios';

import gql from 'graphql-tag';
const query = gql` query ( $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String, $payer_ids: String, $from_delivery_date: String, $until_delivery_date: String )
{
    mixtures: nutritional_mixtures( page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction, payer_ids: $payer_ids, from_delivery_date: $from_delivery_date, until_delivery_date: $until_delivery_date ) {
        id
        delivery_date
        comments
        order {
            id
            patient {
                id
                name
            }
            medic {
                id
                payer {
                    id
                    invoicing_data {
                        id
                        business_name
                    }
                }
            }
        }
        state {
            id
            name
        }
    }
    mixturesCount: table_counts( count_type: "nutritional_mixtures", payer_ids: $payer_ids, from_delivery_date: $from_delivery_date, until_delivery_date: $until_delivery_date ) {
        nutritional_mixtures
    }
}`;

export default {
    name: 'index',
    components: {
        serbitecNutritionalRecordsTable,
        serbitecFilters
    },
    data () {
        return {
            loading: false,
            mixturesCount: null,
            page: 0,
            pageSize: 10,
            sortField: 'id',
            sortOrder:'desc',
            filterOptions: [ 'payer', 'deliveryDate' ]
        };
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.$apollo.queries.mixtures.refetch();
            if ( from.path == '/edit_comments' && to.query.success ) {
                vm.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se han editado los comentarios'
                });
            }
        });
    },
    computed: {
        ...mapState({
            user: state => state.user,
            payersFilters: state => state.recordFilters.nutritionalMixturesRecords.payers,
            fromDateFilter: state => state.recordFilters.nutritionalMixturesRecords.fromDate,
            untilDateFilter: state => state.recordFilters.nutritionalMixturesRecords.untilDate
        }),
        payerIds(){
            let ids = [];
            this.payersFilters.map( payer => {
                ids.push(payer.id);
            } );
            return ids;
        }
    },
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
        mixtureCanceled ( mixtureId ) {
            var url = '/api/nutritional/mixtures/' + mixtureId + '/state';
            const requestParams = {
                new_state_id: 3
            };
            this.makeRequest( url, axios.post, requestParams );
        },
        makeRequest ( url, method, params ) {
            this.loading = true;
            method( url, params )
            .then(function ( ) {
                this.$apollo.queries.mixtures.refetch();
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha cancelado la mezcla'
                });
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                title: '¡Ocurrió un error!',
                type: 'error',
                text: error.response.data.message
            });
            }.bind(this));
            this.loading = false;
        }
    },
    apollo: {
        mixtures: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder,
                    payer_ids: this.payerIds.toString(),
                    from_delivery_date: this.fromDateFilter,
                    until_delivery_date: this.untilDateFilter
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                this.mixturesCount = data.mixturesCount.nutritional_mixtures;
                return data.mixtures;
            }
        },
    }
};
</script>