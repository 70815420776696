<template>
    <form @submit="submitGenerateQuote">
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">
                    Generar trazabilidad
                </p>
            </header>
            <section class="modal-card-body">
                <p>
                    Para generar la trazabilidad seleccione los procesos a incluir y de click en el botón Generar
                </p>
                <div class="columns serbitec-small-top-margin">
                    <div class="column">
                        <div class="field">
                            <b-checkbox
                                v-model="toMixtureStatesIds"
                                native-value="2"
                                disabled
                            >
                                Autorizada
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox
                                v-model="toMixtureStatesIds"
                                native-value="4"
                            >
                                En preparación
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox
                                v-model="toMixtureStatesIds"
                                native-value="5"
                                disabled
                            >
                                Preparada
                            </b-checkbox>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <b-checkbox
                                v-model="toMixtureStatesIds"
                                native-value="6"
                                disabled
                            >
                                Liberada por calidad
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox
                                v-model="toMixtureStatesIds"
                                native-value="7"
                            >
                                Rechazada por calidad
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox
                                v-model="toMixtureStatesIds"
                                native-value="8"
                            >
                                Enviada
                            </b-checkbox>
                        </div>
                    </div>
                    <div class="column">
                        <div class="field">
                            <b-checkbox
                                v-model="toMixtureStatesIds"
                                native-value="9"
                            >
                                Acondicionada
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox
                                v-model="toMixtureStatesIds"
                                native-value="10"
                            >
                                Entregada
                            </b-checkbox>
                        </div>
                        <div class="field">
                            <b-checkbox
                                v-model="toMixtureStatesIds"
                                native-value="3"
                            >
                                Cancelada
                            </b-checkbox>
                        </div>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <button class="button" type="button" @click="$parent.close()">Cancelar</button>
                <button class="button is-primary">Generar</button>
            </footer>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        traceabilityId: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            toMixtureStatesIds: ['2', '5', '6']
        };
    },
    methods: {
        submitGenerateQuote(){
            if (this.chargeOnlyPreparationService) {
                this.detailed = false;
            }
            if (this.type === 'oncologic') {
                window.open('/pdf/oncologic/orders/' + this.traceabilityId +
                '/traceability?to_mixture_state_ids=' + this.toMixtureStatesIds,'_blank');
            } else {
                window.open('/pdf/nutritional/mixtures/' + this.traceabilityId +
                '/traceability?to_mixture_state_ids=' + this.toMixtureStatesIds,'_blank');
            }
            this.$emit('close');
        }
    }
};
</script>