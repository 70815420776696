i<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Agregar presentación
            </p>
        </header>
        <section class="modal-card-body">
            <b-field
                label="Código EAN"
                label-position="on-border"
                :type="{'is-danger' : $v.EANcode.$error }"
                :message="{
                    'Se requiere introducir el código EAN de la presentación' : !$v.EANcode.required && $v.EANcode.$error,
                }"
                expanded
            >
                <b-field
                :type="{'is-danger' : $v.EANcode.$error }">
                    <b-input
                        v-model="$v.EANcode.$model"
                        type="text"
                        expanded
                        />
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                label="Volumen"
                label-position="on-border"
                :type="{'is-danger' : $v.volumeInMl.$error }"
                :message="{
                    'Se requiere introducir el volumen de la presentación' : !$v.volumeInMl.required && $v.volumeInMl.$error,
                }"
                expanded
            >
                <b-field
                :type="{'is-danger' : $v.volumeInMl.$error }">
                    <b-input
                        v-model="$v.volumeInMl.$model"
                        type="text"
                        expanded
                    />
                    <p class="control">
                        <span class="button is-static">ml</span>
                    </p>
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                label="Cantidad de principio activo"
                label-position="on-border"
                :type="{'is-danger' : $v.componentQuantity.$error }"
                :message="{
                    'Se requiere introducir la cantidad de principio activo de la presentación' : !$v.componentQuantity.required && $v.componentQuantity.$error,
                }"
                expanded
            >
                <b-field
                :type="{'is-danger' : $v.componentQuantity.$error }">
                    <b-input
                        v-model="$v.componentQuantity.$model"
                        type="text"
                        expanded
                    />
                    <p class="control">
                        <span class="button is-static">{{ medicineComponentUnitSymbol }}</span>
                    </p>
                </b-field>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                Cancelar
            </button>
            <button class="button is-primary" @click="submitForm">
                Agregar
            </button>
        </footer>
    </div>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators';

export default {
    props: {
        medicineId: {
            type: [Number, String],
            default: 0 | ''
        },
        medicineComponentUnitSymbol: {
            type: String,
            default: '--'
        }
    },
    data () {
        return {
            EANcode: '',
            volumeInMl: '',
            componentQuantity: '',
        };
    },
    methods: {
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.addPresentation();
            }
        },
        getRequestParams () {
            return {
                ean_code: this.EANcode,
                volume_in_ml: this.volumeInMl,
                component_quantity: this.componentQuantity,
            };
        },
        addPresentation () {
            var postParams = this.getRequestParams();
            var url = '/api/medicines/' + this.medicineId + '/presentations';
            this.makeRequest(url, this.$axios.post, postParams);
        },
        makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method(url,params)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha añadido la presentación'
                });
                this.$serbitec.finishLoading();
                this.$emit('success');
                this.$emit('close');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    },
    validations : {
        EANcode : {
            required
        },
        volumeInMl: {
            decimal, 
            required
        },
        componentQuantity: {
            decimal,
            required
        }
    }
};
</script>