<template>
    <div>
        <div v-if="!externalWarehouseMedicine" class="box">
            <h1 class="subtitle is-6">1. Seleccione el medicamento</h1>
            <serbitec-external-warehouse-medicines-autocomplete
            :internal-warehouse-type="internalWarehouseType"
            @medicine-selected="medicineSelected"></serbitec-external-warehouse-medicines-autocomplete>
            <br>
        </div>
        <div v-if="externalWarehouseMedicine" class="box">
            <h1 class="subtitle is-6">2. Seleccione la cantidad de frascos para añadir a la solicitud</h1>
            <b-field grouped>
                <b-field>
                    <span class="button" @click="unselectMedicine()">
                        <span class="icon is-small">
                            <font-awesome-icon icon="exchange-alt" />
                        </span>
                    </span>
                </b-field>
                <b-field expanded>
                    <b-input
                    :value="externalWarehouseMedicine.commercial_name + ' (Genérico: ' + externalWarehouseMedicine.component.name + ')'"
                    disabled>
                    </b-input>
                </b-field>
            </b-field>
            <serbitec-request-external-warehouse-items-table
            @item-added="unselectMedicine"
            :medicine-id="externalWarehouseMedicine.id"></serbitec-request-external-warehouse-items-table>
        </div>
    </div>
</template>

<script>
import serbitecExternalWarehouseMedicinesAutocomplete from '../utils/external-warehouse-medicines-autocomplete.vue';
import serbitecRequestExternalWarehouseItemsTable from './request-external-warehouse-items-table.vue';

function initialDataState () {
    return {
        loading: false,
        notification: {
            isActive: false,
            message: null,
            title: null,
            type: null
        },
        externalWarehouseMedicine: null,
    };
}
export default {
    props:['internalWarehouseType'],
    components: {
        serbitecExternalWarehouseMedicinesAutocomplete,
        serbitecRequestExternalWarehouseItemsTable,
    },
    data: initialDataState,
    methods: {
        medicineSelected ( medicine ) {
            this.externalWarehouseMedicine = medicine;
        },
        unselectMedicine () {
            Object.assign(this.$data, initialDataState());
        },
    }
};
</script>