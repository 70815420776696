<template>
    <div>
        <b-table
            :data="isEmpty ? [] : internalWarehouseItems"
            :loading="loading"

            paginated
            backend-pagination
            :total="internalWarehouseItemsCount"
            :per-page="pageSize"
            @page-change="onPageChange"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            @sort="onSort">

            <template slot-scope="props">
                <b-table-column field="batch" label="Lote" numeric sortable>
                    <p><strong v-if="props.row.prefix">{{props.row.prefix.prefix}}-</strong> {{ props.row.batch }}</p>
                </b-table-column>

                <b-table-column label="Nombre">
                    {{ props.row.medicine_presentation.medicine.commercial_name }}
                </b-table-column>

                <b-table-column label="Fabricante">
                    {{ props.row.medicine_presentation.medicine.manufacturer.name }}
                </b-table-column>

                <b-table-column label="Presentación" centered>
                    <b-tag v-if="props.row.medicine_presentation.medicine.component.component_type.id == 8">pieza</b-tag>
                    <b-tag v-else-if="props.row.medicine_presentation.medicine.component.component_type.id == 1">{{ props.row.medicine_presentation.component_quantity }} {{ props.row.medicine_presentation.medicine.component_unit.symbol }} en {{ props.row.medicine_presentation.volume_in_ml }} ml</b-tag>
                    <b-tag v-else>{{ props.row.medicine_presentation.volume_in_ml }} ml</b-tag>
                </b-table-column>

                <b-table-column v-if="!enableWeeklyCycleInput" label="Fecha de caducidad" centered>
                    {{ props.row.expiry_date }}
                </b-table-column>

                <b-table-column label="Existencias" centered>
                    <p>{{ setItemQuantity( props.row ) }}</p>
                </b-table-column>

                <b-table-column v-if="enableWeeklyCycleInput" label="Ajuste" centered>
                    <b-field position="is-centered">
                        <b-input
                        v-if="isPieceItem( props.row )"
                        v-model="props.row.adjustedPieceQuantity"
                        v-cleave="masks.pieces"
                        ></b-input>
                        <b-input
                        v-else-if="isOncologic"
                        v-model="props.row.adjustedOncologicQuantityInMl"
                        v-cleave="masks.custom"
                        ></b-input>
                        <b-input
                        v-else-if="isNutritional"
                        v-model="props.row.adjustedNutritionalQuantityInMl"
                        v-cleave="masks.custom"
                        ></b-input>
                        <p class="control">
                            <span v-if="isPieceItem( props.row )" class="button is-static" outlined>pz</span>
                            <span v-else class="button is-static" outlined>ml</span>
                        </p>
                    </b-field>
                    <b-tag v-if="quantityMismatch( props.row )" class="is-danger">Modificado</b-tag>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay elementos existentes en almacén<br><small>Para agregar realice una solicitud al almacén externo</small></p>
                    </div>
                </section>
            </template>
        </b-table>

        <b-field v-if="comesFromCycle && internalWarehouseItems" grouped position="is-centered">
            <p class="control">
                <button v-if="hasAdjustments" type="submit" class="button is-primary" @click="submitWarehouseCycle" style="margin-left:10px">
                    <span class="icon">
                        <font-awesome-icon icon="check-circle" />
                    </span>
                    <span>Hacer corte de almacén</span>
                </button>
                <button v-else type="submit" class="button is-primary" style="margin-left:10px" disabled>
                    <span class="icon">
                        <font-awesome-icon icon="check-circle" />
                    </span>
                    <span>Hacer corte de almacén</span>
                </button>
            </p>
        </b-field>
    </div>
</template>

<script>
import Cleave from 'cleave.js';
import axios from 'axios';

import gql from 'graphql-tag';
const warehouseItemsQuery = gql` query ( $has_in_oncologic_warehouse: Boolean, $has_in_nutritional_warehouse: Boolean, $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String )
{
    external_medicine_warehouse_items( has_in_oncologic_warehouse: $has_in_oncologic_warehouse, has_in_nutritional_warehouse: $has_in_nutritional_warehouse, page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction ) {
        id
        prefix {
            id
            prefix
        }
        batch
        expiry_date
        quantity_in_oncologic_preparer_warehouse_in_microunits
        quantity_in_nutritional_preparer_warehouse_in_microunits
        medicine_presentation {
            id
            component_quantity
            volume_in_ml
            medicine {
                id
                commercial_name
                manufacturer {
                    id
                    name
                }
                component_unit{
                    id
                    symbol
                }
                component {
                    id
                    component_type {
                        id
                    }
                    name
                }
            }
        }
    }
}`;

const itemsCountQuery = gql` query ( $has_in_oncologic_warehouse: Boolean, $has_in_nutritional_warehouse: Boolean )
{
    table_counts(has_in_oncologic_warehouse: $has_in_oncologic_warehouse, has_in_nutritional_warehouse: $has_in_nutritional_warehouse) {
        external_medicine_warehouse_items
    }
}`;

const cleave = {
    name: 'cleave',
    bind(el, binding) {
        const input = el.querySelector('input');
        input._vCleave = new Cleave(input, binding.value);
    },
    unbind(el) {
        const input = el.querySelector('input');
        input._vCleave.destroy();
    }
};

function initialDataState () {
    return {
        loading: false,
        sortField: 'batch',
        sortOrder: 'asc',
        page: 0,
        pageSize: 10,
        isEmpty: false,

        masks: {
            custom: {
                numeral: true,
                numeralThousandsGroupStyle: 'none',
                numeralPositiveOnly: true
            },
            pieces: {
                numeral: true,
                numeralThousandsGroupStyle: 'none',
                numeralPositiveOnly: true,
                numeralDecimalScale: 0
            }
        }
    };
}

export default {
    props:['internalWarehouseType', 'enableWeeklyCycleInput', 'comesFromCycle'],
    directives: { cleave },
    created () {
        this.$apollo.queries.internalWarehouseItems.refetch();
    },
    watch: {
        internalWarehouseItems ( newValue ) {
            newValue.map( item => {
                if ( this.isOncologic ) {
                    if ( this.isPieceItem( item ) ) {
                        this.$set( item, 'adjustedPieceQuantity', item.quantity_in_oncologic_preparer_warehouse_in_microunits );
                    } else {
                        this.$set( item, 'adjustedOncologicQuantityInMl', item.quantity_in_oncologic_preparer_warehouse_in_microunits / 1000 );
                    }
                } else if ( this.isNutritional ) {
                    if ( this.isPieceItem( item ) ) {
                        this.$set( item, 'adjustedPieceQuantity', item.quantity_in_nutritional_preparer_warehouse_in_microunits );
                    } else {
                        this.$set( item, 'adjustedNutritionalQuantityInMl', item.quantity_in_nutritional_preparer_warehouse_in_microunits / 1000 );
                    }
                }
            } );
        }
    },
    computed: {
        isOncologic () {
            if ( this.internalWarehouseType === 'oncologic' ) {
                return true;
            } else {
                return false;
            }
        },
        isNutritional () {
            if ( this.internalWarehouseType === 'nutritional' ) {
                return true;
            } else {
                return false;
            }
        },
        hasAdjustments () {
            return this.internalWarehouseItems.find( item => this.quantityMismatch(item) ) != undefined;
        }
    },
    data: initialDataState,
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
        quantityInMl( quantityInMicroL ) {
            return quantityInMicroL / 1000;
        },
        refetchItems () {
            this.$apollo.queries.internalWarehouseItems.refetch();
            return true;
        },
        isPieceItem ( item ) {
            if ( item.medicine_presentation.medicine.component_unit.symbol == 'pz' ) {
                return true;
            } else {
                return false;
            }
        },
        setItemQuantity ( item ) {
            if ( this.isOncologic ) {
                if ( this.isPieceItem( item ) ) {
                    return item.quantity_in_oncologic_preparer_warehouse_in_microunits + ' piezas';
                } else {
                    return this.quantityInMl( item.quantity_in_oncologic_preparer_warehouse_in_microunits ) + ' ml';
                }
            } else if ( this.isNutritional ) {
                if ( this.isPieceItem( item ) ) {
                    return item.quantity_in_nutritional_preparer_warehouse_in_microunits + ' piezas';
                } else {
                    return this.quantityInMl( item.quantity_in_nutritional_preparer_warehouse_in_microunits ) + ' ml';
                }
            }
        },
        quantityMismatch ( item ) {
            if ( this.isOncologic ) {
                if ( this.isPieceItem( item ) ) {
                    if ( parseFloat( item.adjustedPieceQuantity ) != item.quantity_in_oncologic_preparer_warehouse_in_microunits ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if ( parseFloat( item.adjustedOncologicQuantityInMl ) != this.quantityInMl( item.quantity_in_oncologic_preparer_warehouse_in_microunits ) ) {
                        return true;
                    } else {
                        return false;
                    }
                }
            } else if ( this.isNutritional ) {
                if ( this.isPieceItem( item ) ) {
                    if ( parseFloat( item.adjustedPieceQuantity ) != item.quantity_in_nutritional_preparer_warehouse_in_microunits ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if ( parseFloat( item.adjustedNutritionalQuantityInMl ) != this.quantityInMl( item.quantity_in_nutritional_preparer_warehouse_in_microunits ) ) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        },
        getRequestParams () {
            var adjustedQuantities = {
                adjustments: []
            };
            this.internalWarehouseItems.map ( item => {
                if ( this.quantityMismatch( item ) ){
                    const obj = {};
                    obj.external_medicine_warehouse_id = item.id;
                    if ( this.isOncologic ) {
                        if ( this.isPieceItem( item ) ) {
                            obj.quantity_in_microunits = item.adjustedPieceQuantity;
                        } else {
                            obj.quantity_in_microunits = item.adjustedOncologicQuantityInMl * 1000;
                        }
                    } else {
                        if ( this.isPieceItem( item ) ) {
                            obj.quantity_in_microunits = item.adjustedPieceQuantity;
                        } else {
                            obj.quantity_in_microunits = item.adjustedNutritionalQuantityInMl * 1000;
                        }
                    }
                    adjustedQuantities.adjustments.push(obj);
                }
            } );
            return adjustedQuantities;
        },
        submitWarehouseCycle () {
            if ( this.isNutritional ) {
                var url = '/api/medicine_warehouse/nutritional/adjustments';
            } else if ( this.isOncologic ) {
                var url = '/api/medicine_warehouse/oncologic/adjustments';
            }
            this.makeRequest( url, axios.post, this.getRequestParams() );
        },
        makeRequest ( url, method, params ) {
            this.loading = true;
            method( url, params )
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha realizado el corte de almacén'
                });
                this.$apollo.queries.internalWarehouseItems.refetch();
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
            this.loading = false;
        }
    },
    apollo: {
        internalWarehouseItems: {
            fetchPolicy: 'cache-and-network',
            query: warehouseItemsQuery, 
            variables () {
                return {
                    has_in_oncologic_warehouse: this.isOncologic,
                    has_in_nutritional_warehouse: this.isNutritional,
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            watchLoading (isLoading) {
                this.loading = isLoading;
            },
            update: data => data.external_medicine_warehouse_items
        },
        internalWarehouseItemsCount: {
            query: itemsCountQuery,
            variables () {
                return {
                    has_in_oncologic_warehouse: this.isOncologic,
                    has_in_nutritional_warehouse: this.isNutritional
                };
            },
            update: data => data.table_counts.external_medicine_warehouse_items
        },
    }
};
</script>