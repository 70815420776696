<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right"></div>
        </nav>
        <hr>
        <div v-if="nutritionalMixture && itemsInNutritionalWarehouse">
            <div class="box has-text-centered">
                <h1 class="title">Orden: ON-{{ nutritionalMixture.order.id }} | Lote: LN-{{ mixtureId }}</h1>
            </div>
            <serbitec-horizontal-order-data
                :medic="nutritionalMixture.order.medic"
                :patient="nutritionalMixture.order.patient" />
            <div class="columns">
                <div class="column is-half is-size-4">
                    Volumen total: <strong>{{ nutritionalMixture.total_volume_in_ml }} ml</strong>
                </div>
                <div class="column is-half is-size-4">
                    Sobrellenado: <strong>{{ nutritionalMixture.overfill_in_ml }} ml</strong>
                </div>
            </div>
            <div class="box" v-for="( element, index ) in itemsInNutritionalWarehouse" :key="index">
                <nav v-if="element.remainingQuantityInMl != element.componentQuantityInMl" class="level serbitec-narrow-bottom">
                    <div class="level-left"></div>
                    <div class="level-right">
                        <p v-if="element.isCompleted" class="has-text-success"><font-awesome-icon icon="check-circle" /> Completado</p>
                        <p v-else class="is-size-6 has-text-danger"><font-awesome-icon icon="exclamation-circle" /> Faltan {{ setItemQuantity( element.remainingQuantityInMl, element.isPiece ) }}</p>
                    </div>
                </nav>
                <nav class="level serbitec-small-bottom">
                    <div class="level-left">
                        <p class="has-text-weight-bold is-size-5">{{ element.componentName }}</p>
                    </div>
                    <div class="level-right">
                        <p class="is-size-4">{{ setItemQuantity( element.componentQuantityInMl, element.isPiece ) }}</p>
                    </div>
                </nav>
                <b-table
                    :data="element.nutritionalWarehouseItems"

                    checkable
                    :checked-rows.sync="element.checkedRows"
                    :header-checkable="false"
                    :is-row-checkable="enableCheckRow"
                    @check="updateItemInNutritionalWarehouse"

                    default-sort="expiry_date"
                    default-sort-direction="asc"

                    narrowed>
                        <template slot-scope="props">
                            <b-table-column label="Lote" numeric>
                                <p><strong v-if="props.row.prefix">{{props.row.prefix.prefix}}-</strong> {{ props.row.batch }}</p>
                            </b-table-column>

                            <b-table-column label="Nombre">
                                {{ props.row.medicine_presentation.medicine.commercial_name }}
                            </b-table-column>

                            <b-table-column v-if="!element.isInfusionSet" label="Presentación" centered>
                                <b-tag>{{ props.row.medicine_presentation.volume_in_ml }} ml</b-tag>
                            </b-table-column>

                            <b-table-column field="expiry_date" label="Fecha de caducidad" sortable centered>
                                {{ props.row.expiry_date }}
                            </b-table-column>

                            <b-table-column label="Cantidad en almacén" centered>
                                {{ setItemQuantity( props.row.quantity_in_nutritional_preparer_warehouse_in_microunits, element.isPiece, true ) }}
                            </b-table-column>
                        </template>

                        <template slot="empty">
                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="exclamation-triangle"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>No hay componentes disponibles en el área de preparación para este cliente. Solicite el componente al almacén para continuar</p>
                                </div>
                            </section>
                        </template>
                </b-table>
            </div>
            <b-field style="margin-top:25px" position="is-centered" grouped>
                <p v-if="allMixtureMedicinesSelected" class="control has-text-centered">
                    <button @click.once="submitMedicines" class="button is-primary">
                        Seleccionar medicamentos
                    </button>
                </p>
                <p v-else class="control has-text-centered">
                    <button class="button is-primary" disabled>
                        Seleccionar medicamentos
                    </button>
                </p>
            </b-field>
        </div>
    </div>
</template>

<script>
import serbitecHorizontalOrderData from '../../../components/dashboard/utils/horizontal-order-data.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';
import axios from 'axios';

import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    nutritional_mixture( id: $id ) {
        id
        total_volume_in_ml
        overfill_in_ml
        elements {
            id
            component {
                id
                name
                component_type{
                    id
                }
            }
            medicine_quantity
        }
        order {
            id
            medic {
                id
                first_name
                last_name_1
                last_name_2
                email
                medical_card
                payer {
                    id
                    invoicing_data {
                        id
                        business_name
                    }
                }
            }
            patient {
                id
                name
                diagnosis
                height_in_meters
                weight_in_kilograms
                birth_date
                file_id
                location
                bed
            }
        }
    }
}`;

const componentsQuery = gql` query ( $component_ids: String, $enabled_for_payer_id: Int )
{
    external_medicine_warehouse_items( component_ids: $component_ids, has_in_nutritional_warehouse: true, enabled_for_payer_id: $enabled_for_payer_id ) {
        id
        prefix {
            id
            prefix
        }
        batch
        quantity_in_nutritional_preparer_warehouse_in_microunits
        expiry_date
        medicine_presentation {
            id
            volume_in_ml
            medicine {
                id
                commercial_name
                component {
                    id
                    component_type {
                        id
                    }
                }
                component_unit {
                    id
                    symbol
                }
            }
        }
    }
}`;

export default {
    props: {
        mixtureId: {
            type: [Number, String],
            default: 0 | '0'
        }
    },
    components: {
        serbitecHorizontalOrderData,
        serbitecBreadcumbs
    },
    computed: {
        mixtureElementsIds () {
            if ( this.nutritionalMixture ) {
                var elementsComponentIds = [];
                this.nutritionalMixture.elements.map ( element => {
                    elementsComponentIds.push( element.component.id );
                });
                return elementsComponentIds.join();
            } else {
                return null;
            }
        },
        selectedMedicines () {
            var selectedMedicines = [];
            this.itemsInNutritionalWarehouse.map ( item => {
                item.checkedRows.map ( selectedItem => {
                    const medicine = {};
                    medicine.medicine_warehouse_item_id = selectedItem.id;
                    if ( selectedItem.medicine_presentation.medicine.component.component_type.id == 12 || selectedItem.medicine_presentation.medicine.component.component_type.id == 8 ) {
                        medicine.medicine_warehouse_item_quantity = selectedItem.usedQuantity;
                    } else {
                        medicine.medicine_warehouse_item_quantity = selectedItem.usedQuantityInMicroL / 1000;
                    }
                    medicine.mixture_medicine_id = item.mixtureComponentId;
                    selectedMedicines.push(medicine);
                } );
            } );
            return selectedMedicines;
        },
        allMixtureMedicinesSelected () {
            return ( this.itemsInNutritionalWarehouse.find( item => item.remainingQuantityInMl != 0 ) ) == undefined;
        }
    },
    data () {
        return {
            route: [
                {
                    label: 'Área de preparación de mezclas nutricionales',
                    to: '/nutritional_preparation_area',
                    active: false
                },
                {
                    label: 'Seleccionar insumos',
                    to: '/nutritional_preparation_area/select_supplies',
                    active: true
                }
            ]
        };
    },
    methods: {
        setItemQuantity( itemQuantity, isPieceItem, isInMicroL ) {
            if ( isPieceItem ) {
                return itemQuantity + ' pz';
            } else {
                if ( isInMicroL ) {
                    return ( itemQuantity / 1000 ) + ' ml';
                } else {
                    return itemQuantity + ' ml';
                }
            }
        },
        enableCheckRow( row ) {
            const item = this.itemsInNutritionalWarehouse.find( item => item.componentId == row.medicine_presentation.medicine.component.id );
            const rowIsNotChecked = item.checkedRows.find( checkedItem => checkedItem.batch == row.batch ) == null;

            return !( item.isCompleted && rowIsNotChecked );
        },
        updateItemInNutritionalWarehouse () {
            this.$nextTick( () => {
                this.itemsInNutritionalWarehouse.map( item => {
                    item.remainingQuantityInMl = item.componentQuantityInMl;
                    var remainingQuantityInMl = item.remainingQuantityInMl;
                    item.checkedRows.map( checkedItem => {
                        if ( item.isPiece ) {
                            var quantityInNutritionalPreparerWarehouse = checkedItem.quantity_in_nutritional_preparer_warehouse_in_microunits;
                            var usedQuantity = Math.min( quantityInNutritionalPreparerWarehouse, remainingQuantityInMl );
                            this.$set( checkedItem, 'usedQuantity', usedQuantity );
                            remainingQuantityInMl -= usedQuantity;
                            item.remainingQuantityInMl = remainingQuantityInMl;
                        } else {
                            var quantityInNutritionalPreparerWarehouse = checkedItem.quantity_in_nutritional_preparer_warehouse_in_microunits / 1000;
                            var usedQuantity = Math.min( quantityInNutritionalPreparerWarehouse, remainingQuantityInMl );
                            this.$set( checkedItem, 'usedQuantityInMicroL', usedQuantity * 1000 );
                            remainingQuantityInMl -= Math.min( quantityInNutritionalPreparerWarehouse, remainingQuantityInMl );
                            item.remainingQuantityInMl = remainingQuantityInMl;
                        }
                    } );
                    item.isCompleted = ( remainingQuantityInMl == 0 );
                });
            });
        },
        getRequestParams () {
            const requestParams = {};
            requestParams.mixture_id = this.mixtureId;
            requestParams.medicine_warehouse_array = this.selectedMedicines;
            return requestParams;
        },
        submitMedicines () {
            var url = '/api/medicine_warehouse/nutritional/usage';
            this.makeRequest( url, axios.post, this.getRequestParams() );
        },
        makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method( url, params )
            .then(function () {
                this.$serbitec.finishLoading();
                this.$router.push({ path: '/nutritional_preparation_area', query: { success: true } });
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$serbitec.finishLoading();
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
        }
    },
    apollo: {
        nutritionalMixture: {
            query: query,
            fetchPolicy: 'no-cache',
            variables () {
                return {
                    id: this.mixtureId,
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update ( data ) {
                return data.nutritional_mixture;
            }
        },
        itemsInNutritionalWarehouse: {
            query: componentsQuery,
            fetchPolicy: 'no-cache',
            variables () {
                let obj = {
                    component_ids: this.mixtureElementsIds
                };
                if ( this.nutritionalMixture ) {
                    obj.enabled_for_payer_id = this.nutritionalMixture.order.medic.payer.id;
                }
                return obj;
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update ( data ) {
                var elementsWithComponent = [];
                if ( this.nutritionalMixture ) {
                    const overfillFactor = ( this.nutritionalMixture.total_volume_in_ml + this.nutritionalMixture.overfill_in_ml ) / this.nutritionalMixture.total_volume_in_ml;
                    this.nutritionalMixture.elements.map( element => {
                        var obj = {};
                        obj.mixtureComponentId = element.id;
                        obj.componentName = element.component.name;
                        obj.componentId = element.component.id;
                        obj.checkedRows = [];
                        obj.componentQuantityInMl = ( element.medicine_quantity * overfillFactor ).toFixed(3);
                        obj.remainingQuantityInMl = ( element.medicine_quantity * overfillFactor ).toFixed(3);
                        obj.isCompleted = false;
                        obj.isPieceItem = false;
                        // Nutritional components in pz
                        if ( element.component.component_type.id == 8 || element.component.component_type.id == 12 ) {
                            obj.componentQuantityInMl = element.medicine_quantity;
                            obj.remainingQuantityInMl = element.medicine_quantity;
                            obj.isPiece = true;
                        }

                        if ( element.component.component_type.id == 8 ) {
                            obj.isInfusionSet = true;
                        } else {
                            obj.isInfusionSet = false;
                        }

                        obj.nutritionalWarehouseItems = data.external_medicine_warehouse_items.filter( item => {
                            if ( item.medicine_presentation.medicine.component.id == element.component.id && element.component.component_type.id == 12 ) {
                                if ( item.medicine_presentation.volume_in_ml >= this.nutritionalMixture.total_volume_in_ml ) {
                                    return item;
                                } else {
                                    return null;
                                }
                            }
                            if ( item.medicine_presentation.medicine.component.id == element.component.id ) {
                                return item;
                            }
                        });

                        elementsWithComponent.push( obj );
                    });
                }
                return elementsWithComponent;
            },
        }
    }
};
</script>

<style lang="scss" scoped>
.serbitec-small-bottom{
    margin-bottom: 10px
}
.serbitec-narrow-bottom{
    margin-bottom: 0px
}
</style>