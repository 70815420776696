<template>
    <div>
        <b-message v-if="medicine">
            <h1 class="subtitle is-5">{{ medicine.commercial_name }}</h1>
            <p><strong>Genérico: </strong>{{ medicine.component.name }}</p>
            <p><strong>Fabricante: </strong>{{ medicine.manufacturer.name }}</p>
        </b-message>
        <b-skeleton v-else height="137px" :animated="true"></b-skeleton>
    </div>
</template>

<script>
import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    medicine( id: $id ) {
        id
        commercial_name
        component {
            id
            name
        }
        manufacturer {
            id
            name
        }
    }
}`;
export default {
    props: {
        medicineId: {
            type: [Number, String],
            default: 0 | ''
        }
    },
    apollo: {
        medicine: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    id: this.medicineId
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            }
        },
    }
};
</script>