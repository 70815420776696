<template>
    <b-select
        :value="selectedManufacturer"
        @input="option => $emit('manufacturer-selected', option)"
        expanded
    >
        <option
            v-for="option in medicineManufacturers"
            :key="option.id"
            :value="option"
        >
            {{ option.name }}
        </option>
    </b-select>
</template>

<script>
import gql from 'graphql-tag';
const query = gql` query
{
    medicineManufacturers: medicine_manufacturers {
        data {
            id
            name
        }
    }
}`;
export default {
    props: {
        selectedManufacturer: {
            type: Object,
            default: () => {}
        }
    },
    apollo: {
        medicineManufacturers: {
            query: query,
            update (data) {
                return data.medicineManufacturers.data;
            },
            watchLoading (isLoading) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            }
        }
    }
};
</script>