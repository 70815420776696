<template>
    <div>
        <b-table
            :data="externalWarehouseItems"
            :loading="loading"

            paginated
            backend-pagination
            :total="externalWarehouseItemsCount"
            :per-page="pageSize"
            @page-change="onPageChange"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="onSort">

            <template slot-scope="props">
                <b-table-column field="batch" label="Lote" numeric sortable>
                    <p><strong v-if="props.row.prefix">{{props.row.prefix.prefix}}-</strong> {{ props.row.batch }}</p>
                </b-table-column>

                <b-table-column label="Fabricante" sortable>
                    {{ props.row.medicine_presentation.medicine.manufacturer.name }}
                </b-table-column>

                <b-table-column label="Presentación" centered>
                    <b-tag v-if="isPieceItem( props.row )">
                        {{ props.row.medicine_presentation.component_quantity }} {{ props.row.medicine_presentation.medicine.component_unit.symbol }}
                    </b-tag>
                    <b-tag v-else-if="isOncologicItem( props.row )">
                        {{ props.row.medicine_presentation.component_quantity }} {{ props.row.medicine_presentation.medicine.component_unit.symbol }} en {{ props.row.medicine_presentation.volume_in_ml }} ml
                    </b-tag>
                    <b-tag v-else>
                        {{ props.row.medicine_presentation.volume_in_ml }} ml
                    </b-tag>
                    <b-tag v-if="isEvaBag( props.row )">
                        {{ props.row.medicine_presentation.volume_in_ml }} ml
                    </b-tag>
                </b-table-column>

                <b-table-column field="expiry_date" label="Fecha de caducidad" centered sortable>
                    {{ props.row.expiry_date }}
                </b-table-column>

                <b-table-column label="Existencias [frascos]" centered>
                    <p :class="{ 'has-text-danger' : computeRemainingFlasks( props.row ) == 0 }" >{{ computeRemainingFlasks( props.row ) }}</p>
                </b-table-column>

                <b-table-column label="Frascos a solicitar" centered>
                    <b-numberinput
                    v-model="props.row.requestedFlasks"
                    min="1"
                    :max="computeRemainingFlasks( props.row )"
                    :editable="false"
                    size="is-small"
                    type="is-light"
                    controls-position="compact"
                    :disabled="computeRemainingFlasks( props.row ) == 0"></b-numberinput>
                </b-table-column>

                <b-table-column label="Agregar al listado" centered>
                    <a v-if="computeRemainingFlasks( props.row ) > 0" class="button is-small" @click="addRequestedMedicine( props.row )"><font-awesome-icon icon="check" /></a>
                    <a v-else class="button is-small" disabled><font-awesome-icon icon="check" /></a>
                </b-table-column>
            </template>
        </b-table>
    </div>
</template>

<script>
import gql from 'graphql-tag';
import { mapState } from 'vuex';
const query = gql` query ( $medicine_id: Int, $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String )
{
    external_medicine_warehouse_items( has_in_external_warehouse: true, medicine_id: $medicine_id, page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction ) {
        id
        prefix {
            id
            prefix
        }
        batch
        expiry_date
        flasks_remaining_outside
        flasks_requested_outside
        flasks_available_outside
        quantity_in_oncologic_preparer_warehouse_in_microunits
        medicine_presentation {
            id
            component_quantity
            volume_in_ml
            medicine{
                id
                commercial_name
                manufacturer {
                    id
                    name
                }
                component_unit{
                    id
                    symbol
                }
                component {
                    id
                    component_type {
                        id
                    }
                }
            }
        }
    }
    table_counts( has_in_external_warehouse: true, medicine_id: $medicine_id ) {
        external_medicine_warehouse_items
    }
}`;

function initialDataState () {
    return {
        loading: false,
        sortField: 'expiry_date',
        sortOrder: 'asc',
        page: 0,
        pageSize: 5,
        requestedFlasks: 0,
        externalWarehouseItemsCount: null
    };
}

export default {
    props:['medicineId'],
    data: initialDataState,
    created () {
        this.$apollo.queries.externalWarehouseItems.refetch();
    },
    computed: {
        ...mapState({
            requestedMedicines: state => state.internalWarehouseRequest.requestedMedicines,
        })
    },
    watch: {
        externalWarehouseItems ( newValue ) {
            newValue.map( item => {
                this.$set( item, 'requestedFlasks', 1 );
            } );
        }
    },
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
        addRequestedMedicine( medicine ) {
            this.$store.commit('internalWarehouseRequest/ADD_MEDICINE_TO_REQUEST', medicine );
            this.externalWarehouseItems.map( item => {
                this.$set( item, 'requestedFlasks', 1 );
            } );
            this.$emit('item-added');
        },
        computeRemainingFlasks ( externalWarehouseItem ) {
            var flasksRemainingOutside = externalWarehouseItem.flasks_available_outside;
            this.requestedMedicines.map( medicine => { 
                if ( medicine.external_warehouse_item_id == externalWarehouseItem.id ) {
                    flasksRemainingOutside -= medicine.requested_flasks;
                }       
             } );
            return flasksRemainingOutside;
        },
        isEvaBag ( item ) {
            return item.medicine_presentation.medicine.component.component_type.id == 12;
        },
        isPieceItem ( item ) {
            return item.medicine_presentation.medicine.component_unit.symbol == 'pz';
        },
        isOncologicItem ( item ) {
            return item.medicine_presentation.medicine.component.component_type.id == 1;
        }
    },
    apollo: {
        externalWarehouseItems: {
            query: query,
            variables () {
                return {
                    medicine_id: this.medicineId,
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            update ( data ) {
                this.externalWarehouseItemsCount = data.table_counts.external_medicine_warehouse_items;
                return data.external_medicine_warehouse_items;
            }
        },
    }
};
</script>