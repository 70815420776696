var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.computeCalciumAndPhosphate
    ? _c(
        "div",
        [
          _vm.computeCalciumAndPhosphate
            .currentCalciumAndPhosphateConcentration >= 30.0
            ? _c("b-message", { staticClass: "is-warning" }, [
                _vm._v(
                  "\n        La concentración actual de calcio y fosfato es: " +
                    _vm._s(
                      _vm.computeCalciumAndPhosphate
                        .currentCalciumAndPhosphateConcentration
                    ) +
                    " mEq por cada 1000 ml (" +
                    _vm._s(
                      _vm.computeCalciumAndPhosphate.calciumAndPhosphateInMeq
                    ) +
                    " mEq en " +
                    _vm._s(_vm.computeCalciumAndPhosphate.volume) +
                    " ml). La mezcla podría no ser procesada ya que es mayor a 30 mEq por cada 1000 ml.\n    "
                )
              ])
            : _vm.computeCalciumAndPhosphate
                .currentCalciumAndPhosphateConcentration != null
            ? _c("b-message", [
                _vm._v(
                  "\n        La concentración actual de calcio y fosfato es: " +
                    _vm._s(
                      _vm.computeCalciumAndPhosphate
                        .currentCalciumAndPhosphateConcentration
                    ) +
                    " mEq por cada 1000 ml (" +
                    _vm._s(
                      _vm.computeCalciumAndPhosphate.calciumAndPhosphateInMeq
                    ) +
                    " mEq en " +
                    _vm._s(_vm.computeCalciumAndPhosphate.volume) +
                    " ml).\n    "
                )
              ])
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }