<template>
    <div>
        <b-loading :active.sync="loading"></b-loading>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right"></div>
        </nav>
        <hr>
        <div v-if="oncologicMixture && itemsInOncologicWarehouse">
            <div class="box">
                <h1 class="title has-text-centered">Orden: OO-{{ oncologicMixture.order.id }} | Lote: LO-{{ mixtureId }}</h1>
            </div>
            <serbitec-horizontal-order-data
            :medic="oncologicMixture.order.medic"
            :patient="oncologicMixture.order.patient"></serbitec-horizontal-order-data>
            <div v-if="isTransfer" class="box">
                <nav class="level serbitec-small-bottom">
                    <div class="level-left">
                        <p class="has-text-weight-bold is-size-5">Trasvase</p>
                    </div>
                    <div class="level-right">
                        <p class="has-text-success"><font-awesome-icon icon="check-circle" /> Completado</p>
                    </div>
                </nav>
            </div>
            <div class="box" v-for="( element, index ) in itemsInOncologicWarehouse" :key="index">
                <nav
                v-if="!element.isMedicine && ( element.remainingQuantity != element.componentQuantity )
                || element.isMedicine && ( element.remainingQuantity != element.componentQuantity )"
                class="level serbitec-narrow-bottom">
                    <div class="level-left"></div>
                    <div class="level-right">
                        <p v-if="element.isCompleted" class="has-text-success"><font-awesome-icon icon="check-circle" /> Completado</p>
                        <p v-else-if="element.isMedicine" class="is-size-6 has-text-danger"><font-awesome-icon icon="exclamation-circle" /> Faltan {{ element.remainingQuantity }} {{ element.quantityUnit }}</p>
                        <p v-else-if="!element.isMedicine" class="is-size-6 has-text-danger"><font-awesome-icon icon="exclamation-circle" /> Faltan {{ setItemQuantity( element.remainingQuantity, element.isPiece ) }}</p>
                    </div>
                </nav>
                <nav class="level serbitec-small-bottom">
                    <div class="level-left is-mobile">
                        <div v-if="element.isMedicine">
                            <serbitec-edit-medicine
                            :payer-id="oncologicMixture.order.medic.payer.id"
                            :medicine-component-id="element.componentId"
                            :component-name="element.componentName"
                            :medicine-id="element.medicineId"
                            :mixture-medicine-id="element.mixtureMedicineId"
                            :medicine-name="element.medicineName"
                            @medicine-edited="medicineEdited"
                            @medicine-not-edited="medicineNotEdited"></serbitec-edit-medicine>
                        </div>
                        <p v-else class="has-text-weight-bold is-size-5">
                            {{ element.componentName }}
                        </p>
                    </div>
                    <div class="level-right">
                        <p v-if="element.isMedicine && element.quantityToMlFactor" class="is-size-4">{{ element.componentQuantity}} {{ element.quantityUnit }} | {{ ( element.componentQuantity * element.quantityToMlFactor ).toFixed(2) }} ml</p>
                        <p v-else-if="element.isMedicine" class="is-size-4">{{ element.componentQuantity}} {{ element.quantityUnit }}</p>
                        <p v-else class="is-size-4">{{ setItemQuantity( element.componentQuantity, element.isPiece ) }}</p>
                    </div>
                </nav>
                <b-table
                    :data="isEmpty ? [] : element.oncologicWarehouseItems"
                    :loading="tableLoading"

                    checkable
                    :checked-rows.sync="element.checkedRows"
                    :header-checkable="false"
                    :is-row-checkable="enableCheckRow"
                    @check="updateItemInOncologicWarehouse"

                    default-sort="expiry_date"
                    default-sort-direction="asc"

                    narrowed>
                        <template slot-scope="props">
                            <b-table-column label="Lote" numeric>
                                <p><strong v-if="props.row.prefix">{{props.row.prefix.prefix}}-</strong> {{ props.row.batch }}</p>
                            </b-table-column>

                            <b-table-column label="Nombre">
                                {{ props.row.medicine_presentation.medicine.commercial_name }}
                            </b-table-column>

                            <b-table-column v-if="!element.isPiece" label="Presentación" centered>
                                <b-tag v-if="element.isMedicine">{{ props.row.medicine_presentation.component_quantity }} {{ props.row.medicine_presentation.medicine.component_unit.symbol }} en {{ props.row.medicine_presentation.volume_in_ml }} ml</b-tag>
                                <b-tag v-else>{{ props.row.medicine_presentation.volume_in_ml }} ml</b-tag>
                            </b-table-column>

                            <b-table-column field="expiry_date" label="Fecha de caducidad" sortable centered>
                                {{ props.row.expiry_date }}
                            </b-table-column>

                            <b-table-column label="Cantidad en almacén" centered>
                                <p v-if="element.isMedicine">{{ quantityInMedicineUnit( props.row.quantity_in_oncologic_preparer_warehouse_in_microunits / 1000, props.row.medicine_presentation ) }} {{ element.quantityUnit }}</p>
                                <p v-else>{{ setItemQuantity( props.row.quantity_in_oncologic_preparer_warehouse_in_microunits, element.isPiece, true ) }}</p>
                            </b-table-column>

                            <b-table-column v-if="element.isMedicine" label="Cantidad en ml" centered>
                                <p>{{ ( props.row.quantity_in_oncologic_preparer_warehouse_in_microunits / 1000 ) }} ml</p>
                            </b-table-column>
                        </template>

                        <template slot="empty">
                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="exclamation-triangle"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>No hay componentes disponibles en el área de preparación para este cliente. Solicite el componente al almacén para continuar</p>
                                </div>
                            </section>
                        </template>
                </b-table>
            </div>
            <b-field style="margin-top:25px" position="is-centered" grouped>
                <p v-if="allMixtureMedicinesSelected" class="control has-text-centered">
                    <button @click.once="submitMedicines" class="button is-primary">
                        Seleccionar medicamentos
                    </button>
                </p>
                <p v-else class="control has-text-centered">
                    <button class="button is-primary" disabled>
                        Seleccionar medicamentos
                    </button>
                </p>
            </b-field>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import serbitecHorizontalOrderData from '../../../components/dashboard/utils/horizontal-order-data.vue';
import serbitecEditMedicine from '../../../components/dashboard/preparation-area/edit-medicine.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';

function initialDataState () {
    return {
        loading: false,
        tableLoading: false,
        isEmpty: false,
        isTransfer: false,
        route: [
            {
                label: 'Área de preparación de mezclas oncológicas',
                to: '/oncologic_preparation_area',
                active: false
            },
            {
                label: 'Seleccionar insumos',
                to: '/oncologic_preparation_area/select_supplies',
                active: true
            }
        ]
    };
}

import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    oncologic_mixture( id: $id ) {
        elements {
            id
            component {
                id
                name
                component_type {
                    id
                }
            }
            medicine {
                id
                commercial_name
                component_unit {
                    symbol
                }
            }
            medicine_quantity
        }
        order {
            id
            medic {
                first_name
                last_name_1
                last_name_2
                email
                medical_card
                payer {
                    id
                    invoicing_data {
                        business_name
                    }
                }
            }
            patient {
                name
                diagnosis
                height_in_meters
                weight_in_kilograms
                birth_date
                file_id
                location
                bed
            }
        }
    }
}`;

const itemsQuery = gql` query ( $component_ids: String, $medicine_ids: String, $enabled_for_payer_id: Int )
{
    components: external_medicine_warehouse_items( component_ids: $component_ids, has_in_oncologic_warehouse: true, enabled_for_payer_id: $enabled_for_payer_id ) {
        id
        prefix {
            prefix
        }
        batch
        quantity_in_oncologic_preparer_warehouse_in_microunits
        expiry_date
        medicine_presentation {
            volume_in_ml
            component_quantity
            medicine {
                commercial_name
                component {
                    id
                }
                component_unit {
                    symbol
                }
            }
        }
    }

    medicines: external_medicine_warehouse_items( medicine_ids: $medicine_ids, has_in_oncologic_warehouse: true, enabled_for_payer_id: $enabled_for_payer_id ) {
        id
        prefix {
            prefix
        }
        batch
        quantity_in_oncologic_preparer_warehouse_in_microunits
        expiry_date
        medicine_presentation {
            volume_in_ml
            component_quantity
            medicine {
                id
                commercial_name
                component {
                    id
                }
                component_unit {
                    symbol
                }
            }
        }
    }
}`;

export default {
    props: {
        mixtureId: {
            type: [Number, String],
            default: 0 | '0'
        }
    },
    components: {
        serbitecHorizontalOrderData,
        serbitecEditMedicine,
        serbitecBreadcumbs
    },
    data: initialDataState,
    computed: {
        mixtureElementsIds () {
            if ( this.oncologicMixture ) {
                const obj = {};
                var elementsComponentIds = [];
                var elementsMedicineIds = [];
                this.oncologicMixture.elements.map ( element => {
                    if ( element.medicine ) {
                        elementsMedicineIds.push( element.medicine.id );
                    } else {
                        elementsComponentIds.push( element.component.id );
                    }
                });
                obj.componentIds = elementsComponentIds.join();
                obj.medicineIds = elementsMedicineIds.join(); 
                return obj;
            } else {
                return {};
            }
        },
        selectedMedicines () {
            var selectedMedicines = [];
            this.itemsInOncologicWarehouse.map ( item => {
                item.checkedRows.map ( selectedItem => {
                    const medicine = {};
                    medicine.medicine_warehouse_item_id = selectedItem.id;
                    medicine.medicine_warehouse_item_quantity = selectedItem.usedQuantity;
                    medicine.mixture_medicine_id = item.mixtureMedicineId;
                    selectedMedicines.push(medicine);
                } );
            } );
            return selectedMedicines;
        },
        allMixtureMedicinesSelected () {
            return ( this.itemsInOncologicWarehouse.find( item => item.remainingQuantity != 0 ) ) == undefined;
        }
    },
    methods: {
        setItemQuantity( itemQuantity, isPieceItem, isInMicroL ) {
            if ( isPieceItem ) {
                return itemQuantity + ' pz';
            }
            if ( isInMicroL ) {
                return ( itemQuantity / 1000 ) + ' ml';
            } else {
                return itemQuantity + ' ml';
            }
        },
        enableCheckRow( row ) {
            const item = this.itemsInOncologicWarehouse.find( item => item.componentId == row.medicine_presentation.medicine.component.id );
            const rowIsNotChecked = item.checkedRows.find( checkedItem => checkedItem.batch == row.batch ) == null;

            return !( item.isCompleted && rowIsNotChecked );
        },
        quantityInMedicineUnit ( quantityInMl , medicinePresentation ) {
            return ( ( quantityInMl * medicinePresentation.component_quantity ) / medicinePresentation.volume_in_ml ).toFixed(2);
        },
        quantityInMl ( quantityInMedicineUnit, medicinePresentation ) {
            return ( ( quantityInMedicineUnit * medicinePresentation.volume_in_ml ) / medicinePresentation.component_quantity ).toFixed(2);
        },
        updateItemInOncologicWarehouse () {
            this.$nextTick( () => {
                this.itemsInOncologicWarehouse.map( item => {
                    if ( item.isMedicine ) {
                        item.remainingQuantity = item.componentQuantity;
                        var remainingQuantity = item.remainingQuantity;
                    } else {
                        item.remainingQuantity = item.componentQuantity;
                        var remainingQuantity = item.remainingQuantity;
                    }
                    item.checkedRows.map( checkedItem => {
                        if ( item.isPiece ) {
                            var quantityInOncologicPreparerWarehouse = checkedItem.quantity_in_oncologic_preparer_warehouse_in_microunits;
                            var usedQuantity = Math.min( quantityInOncologicPreparerWarehouse, remainingQuantity );
                            this.$set( checkedItem, 'usedQuantity', usedQuantity );
                            remainingQuantity -= usedQuantity;
                            item.remainingQuantity = remainingQuantity;
                        } else {
                            var quantityInOncologicPreparerWarehouse = checkedItem.quantity_in_oncologic_preparer_warehouse_in_microunits / 1000;
                            if ( item.isMedicine ) {
                                var usedQuantity = Math.min( quantityInOncologicPreparerWarehouse, this.quantityInMl( remainingQuantity, checkedItem.medicine_presentation ) );
                                this.$set( checkedItem, 'usedQuantity', usedQuantity );
                                remainingQuantity -= Math.min( this.quantityInMedicineUnit( quantityInOncologicPreparerWarehouse, checkedItem.medicine_presentation ), remainingQuantity );
                                item.remainingQuantity = remainingQuantity;
                            } else {
                                var usedQuantity = Math.min( quantityInOncologicPreparerWarehouse, remainingQuantity );
                                this.$set( checkedItem, 'usedQuantity', usedQuantity );
                                remainingQuantity -= Math.min( quantityInOncologicPreparerWarehouse, remainingQuantity );
                                item.remainingQuantity = remainingQuantity;
                            }
                        }
                    });
                    item.isCompleted = ( remainingQuantity == 0 );
                });
            });
        },
        getRequestParams () {
            const requestParams = {};
            requestParams.mixture_id = this.mixtureId;
            requestParams.medicine_warehouse_array = this.selectedMedicines;
            return requestParams;
        },
        submitMedicines () {
            var url = '/api/medicine_warehouse/oncologic/usage';
            this.makeRequest( url, axios.post, this.getRequestParams() );
        },
        makeRequest ( url, method, params ) {
            this.loading = true;
            method( url, params )
            .then(function () {
                this.$router.push({ path: '/oncologic_preparation_area', query: { success: true } });
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
            this.loading = false;
        },
        medicineEdited () {
            this.$notify({
                title: '¡Éxito!',
                type: 'success',
                text: 'Se ha modificado el medicamento'
            });
            this.$apollo.queries.oncologicMixture.refetch();
            this.$apollo.queries.itemsInOncologicWarehouse.refetch();
        },
        medicineNotEdited ( error ) {
            this.$notify({
                title: '¡Ocurrió un error!',
                type: 'error',
                text: error.response.data.error
            });
        }
    },
    apollo: {
        oncologicMixture: {
            query: query,
            fetchPolicy: 'no-cache',
            variables () {
                return {
                    id: this.mixtureId,
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update ( data ) {
                return data.oncologic_mixture;
            }
        },
        itemsInOncologicWarehouse: {
            query: itemsQuery,
            fetchPolicy: 'no-cache',
            variables () {
                let obj = {
                    component_ids: this.mixtureElementsIds.componentIds,
                    medicine_ids: this.mixtureElementsIds.medicineIds
                };
                if ( this.oncologicMixture ) {
                    obj.enabled_for_payer_id = this.oncologicMixture.order.medic.payer.id;
                }
                return obj;
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update ( data ) {
                var elementsWithComponentOrMedicine = [];
                if ( this.oncologicMixture ) {
                    this.oncologicMixture.elements.map( element => {
                        var obj = {};
                        obj.mixtureMedicineId = element.id;
                        obj.componentName = element.component.name;
                        obj.componentId = element.component.id;
                        obj.componentQuantity = element.medicine_quantity;
                        obj.remainingQuantity = element.medicine_quantity;
                        obj.checkedRows = [];
                        obj.isCompleted = false;
                        if ( element.medicine ) {
                            obj.isMedicine = true;
                            obj.medicineId = element.medicine.id;
                            obj.quantityUnit = element.medicine.component_unit.symbol;
                            obj.medicineName = element.medicine.commercial_name;
                            var item = data.medicines.find( item => item.id );
                            if ( item ) {
                                obj.quantityToMlFactor = ( item.medicine_presentation.volume_in_ml / item.medicine_presentation.component_quantity );
                            } else {
                                obj.quantityToMlFactor = 0;
                            }
                            obj.oncologicWarehouseItems = data.medicines.filter( item => {
                                if ( item.medicine_presentation.medicine.component.id == element.component.id ) {
                                    return item;
                                }
                            });
                        } else {
                            obj.isMedicine = false;
                            if ( element.component.id == 1 ) {
                                this.isTransfer = true;
                                return {};
                            }
                            if ( element.component.component_type.id == 8 || element.component.component_type.id == 12 ) {
                                obj.isPiece = true;
                            } else {
                                obj.isPiece = false;
                            }
                            obj.oncologicWarehouseItems = data.components.filter( item => {
                                if ( item.medicine_presentation.medicine.component.id == element.component.id ) {
                                    return item;
                                }
                            }); 
                        }
                        elementsWithComponentOrMedicine.push( obj );
                    });

                    var medicinesVolume = elementsWithComponentOrMedicine.reduce( ( accumulator, element ) => {
                        if ( element.isMedicine ) {
                            return accumulator + ( element.componentQuantity * element.quantityToMlFactor );
                        }
                        return accumulator;
                    }, 0.0 );

                    var thinner = elementsWithComponentOrMedicine.find( element => element.isMedicine == false );
                    if ( thinner ) {
                        thinner.componentQuantity = ( thinner.componentQuantity - medicinesVolume ).toFixed(2);
                        thinner.remainingQuantity = ( thinner.remainingQuantity - medicinesVolume ).toFixed(2);
                    }
                    
                    let sortedElementsWithComponentOrMedicine = elementsWithComponentOrMedicine.sort(
                        function (a, b) {
                            if ( a.mixtureMedicineId > b.mixtureMedicineId ) {
                                return 1;
                            }
                        }
                    );

                    return sortedElementsWithComponentOrMedicine;
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.serbitec-small-bottom{
    margin-bottom: 10px
}
.serbitec-narrow-bottom{
    margin-bottom: 0px
}
</style>