import { render, staticRenderFns } from "./external-warehouse.vue?vue&type=template&id=4a0b88ae&"
import script from "./external-warehouse.vue?vue&type=script&lang=js&"
export * from "./external-warehouse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/mezclas.serbitec.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a0b88ae')) {
      api.createRecord('4a0b88ae', component.options)
    } else {
      api.reload('4a0b88ae', component.options)
    }
    module.hot.accept("./external-warehouse.vue?vue&type=template&id=4a0b88ae&", function () {
      api.rerender('4a0b88ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/dashboard/payers/configuration/external-warehouse.vue"
export default component.exports