var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.payer
    ? _c(
        "div",
        [
          _c("b-loading", {
            attrs: { active: _vm.loading },
            on: {
              "update:active": function($event) {
                _vm.loading = $event
              }
            }
          }),
          _vm._v(" "),
          _c("b-message", [
            _c("h1", { staticClass: "subtitle" }, [
              _c("strong", [_vm._v("Cliente: ")]),
              _vm._v(
                _vm._s(_vm.payer.invoicing_data.business_name) +
                  "\n            "
              ),
              _c("br"),
              _vm._v(" "),
              _c("small", [
                _c("strong", [_vm._v("Dirección: ")]),
                _vm._v(_vm._s(_vm.payer.invoicing_data.address))
              ])
            ])
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }