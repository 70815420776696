<template>
    <div>
        <b-field label="Filtros activos">
            <b-field grouped group-multiline>
                <div v-for="payer in payersFilters" :key="payer.id" class="control">
                    <b-tag
                        attached
                        closable
                        aria-close-label="Close tag"
                        @close="removePayer(payer.id)">
                        Cliente: {{ payer.label }}
                    </b-tag>
                </div>
                <div>
                    <b-tag
                    v-if="fromDateFilter && untilDateFilter"
                    attached
                    closable
                    aria-close-label="Close tag"
                    @close="removeDateRange">
                        Desde {{ formatDate( fromDateFilter ) }} hasta {{ formatDate( untilDateFilter ) }}
                    </b-tag>
                </div>
                <div v-for="componentType in componentTypes" :key="componentType.id" class="control">
                    <b-tag
                        attached
                        closable
                        aria-close-label="Close tag"
                        @close="removeComponentType(componentType.id)">
                        Tipo: {{ componentType.label }}
                    </b-tag>
                </div>
                <div v-if="enabledPayerMedicines != null" class="control">
                    <b-tag
                        attached
                        closable
                        aria-close-label="Close tag"
                        @close="removeEnabledPayerMedicines">
                        <p v-if="enabledPayerMedicines">Medicamentos activos</p>
                        <p v-else>Medicamentos inactivos</p>
                    </b-tag>
                </div>
            </b-field>
        </b-field>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';

export default {
    props: {
        comesFromPage: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapState({
            payersFilters (state) {
                return state.recordFilters[this.comesFromPage].payers;
            },
            fromDateFilter (state) {
                return state.recordFilters[this.comesFromPage].fromDate;
            },
            untilDateFilter (state) {
                return state.recordFilters[this.comesFromPage].untilDate;
            },
            componentTypes (state) {
                return state.recordFilters[this.comesFromPage].componentTypes;
            },
            enabledPayerMedicines (state) {
                return state.recordFilters[this.comesFromPage].enabledPayerMedicines;
            }
        }),
    },
    methods:{
        formatDate( date ) {
            return format( date, "d 'de' MMMM yyyy", { locale: es } );
        },
        removePayer( payerId ){
            var data = {
                payerId: payerId,
                comesFromPage: this.comesFromPage
            };
            this.$store.commit( 'recordFilters/REMOVE_PAYER_FILTER', data );
        },
        removeDateRange(){
            this.dates = [];
            this.$store.commit( 'recordFilters/REMOVE_DATE_RANGE', this.comesFromPage );
            this.$emit('removeSelectedDates');
        },
        removeComponentType( componentTypeId ) {
            let data = {
                componentTypeId: componentTypeId,
                comesFromPage: this.comesFromPage
            };
            this.$store.commit( 'recordFilters/REMOVE_COMPONENT_TYPE', data );
        },
        removeEnabledPayerMedicines(){
            this.$store.commit( 'recordFilters/REMOVE_ENABLED_PAYER_MEDICINES', this.comesFromPage );
        }
    }
};
</script>