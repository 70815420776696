const getDefaultState = () => {
    return {
        warehouseOptions: {
            location: 'external',
            type: ''
        }
    };
};

const state = getDefaultState();

const mutations = {
    UPDATE_WAREHOUSE_OPTIONS( state, data ){
        Object.assign(state.warehouseOptions, data);
    }
};

export default {
    namespaced: true,
    state,
    mutations
};