var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "columns" }, [
        _c(
          "div",
          { staticClass: "column is-half-desktop" },
          [
            _c(
              "b-field",
              {
                attrs: {
                  label: "Filtrar por...",
                  "label-position": "on-border"
                }
              },
              [
                _c(
                  "b-select",
                  {
                    attrs: { placeholder: "Seleccione una opción" },
                    model: {
                      value: _vm.selectedFilter,
                      callback: function($$v) {
                        _vm.selectedFilter = $$v
                      },
                      expression: "selectedFilter"
                    }
                  },
                  _vm._l(_vm.data, function(option) {
                    return _c(
                      "option",
                      { key: option.id, domProps: { value: option.id } },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(option.label) +
                            "\n                    "
                        )
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.selectedFilter == 0
                  ? _c("serbitec-payer-autocomplete", {
                      on: { "payer-added": _vm.payerAdded }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedFilter == 1
                  ? _c("b-datepicker", {
                      attrs: {
                        placeholder: "Seleccione el rango de fechas...",
                        "date-formatter": function(dates) {
                          return _vm.formatDateRange(dates)
                        },
                        range: "",
                        expanded: ""
                      },
                      on: { input: _vm.addDateRange },
                      model: {
                        value: _vm.dates,
                        callback: function($$v) {
                          _vm.dates = $$v
                        },
                        expression: "dates"
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedFilter == 2
                  ? _c("serbitec-component-type-check", {
                      attrs: { "comes-from-page": _vm.comesFromPage }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.selectedFilter == 3
                  ? _c("serbitec-payer-medicine-enabled-radio", {
                      attrs: { "comes-from-page": _vm.comesFromPage }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.hasActiveFilters
        ? _c("serbitec-filter-tags", {
            attrs: { "comes-from-page": _vm.comesFromPage },
            on: {
              removeSelectedDates: function() {
                return (this$1.dates = [])
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }