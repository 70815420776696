<template>
    <div>
        <nav class="level is-mobile">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title">Distribución de mezclas nutricionales</h1>
                </div>
            </div>
            <div class="level-right" />
        </nav>
        <hr>
        <serbitec-nutritional-distribution-table
            :table-type="'nutritional'"
            :mixtures="mixtures"
            :mixtures-count="mixturesCount"

            :loading.sync="loading"
            :page.sync="page"
            :pageSize="pageSize"
            :sortField.sync="sortField"
            :sortOrder.sync="sortOrder"

            @page-change="onPageChange"
            @sort="onSort"
            @order-sent="orderSent"
            @order-delivered="orderDelivered"
        />
    </div>
</template>

<script>
import serbitecNutritionalDistributionTable from '../../../components/dashboard/distribution/distribution-table.vue';
import axios from 'axios';
import gql from 'graphql-tag';

const query = gql` query ( $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String )
{
    mixtures: nutritional_mixtures( mixture_state_ids: "8,9", page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction ) {
        id
        delivery_date
        order {
            id
            patient {
                id
                name
            }
            medic {
                id
                payer {
                    id
                    invoicing_data {
                        id
                        business_name
                    }
                }
            }
        }
        state {
            id
            name
        }
    }
    mixturesCount: table_counts( mixture_state_ids: "8,9", count_type: "nutritional_mixtures" ) {
        nutritional_mixtures
    }
}`;

function initialDataState () {
    return {
        loading: false,
        mixturesCount: null,
        page: 0,
        pageSize: 10,
        sortField: 'id',
        sortOrder:'desc'
    };
}
export default {
    components: {
       serbitecNutritionalDistributionTable 
    },
    data: initialDataState,
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
        orderSent ( orderId ) {
            var url = '/api/nutritional/mixtures/' + orderId + '/state';
            const requestParams = {
                new_state_id: 8
            };
            this.makeRequest( url, axios.post, requestParams, 'order-sent' );
        },
        orderDelivered ( orderId ) {
            var url = '/api/nutritional/mixtures/' + orderId + '/state';
            const requestParams = {
                new_state_id: 10
            };
            this.makeRequest( url, axios.post, requestParams, 'order-delivered' );
        },
        makeRequest ( url, method, params, comesFrom ) {
            this.$serbitec.startLoading();
            method( url, params )
            .then(function ( ) {
                this.$apollo.queries.mixtures.refetch();
                this.$serbitec.finishLoading();
                if ( comesFrom == 'order-sent' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha enviado la orden'
                    });
                } else if ( comesFrom == 'order-delivered' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha entregado la orden'
                    });
                }
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$serbitec.finishLoading();
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
        }
    },
    apollo: {
        mixtures: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                this.mixturesCount = data.mixturesCount.nutritional_mixtures;
                return data.mixtures;
            }
        },
    }
};
</script>