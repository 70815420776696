var defaultExternalUserMenu = [
    {
        id: 1,
        label: 'Administración',
        items: [
            {
                id: 1,
                label: 'Cuenta',
                route: '/external_user/account'
            },
            {
                id: 2,
                label: 'Pacientes',
                route: '/external_user/patients'
            }
        ]
    },
    {
        id: 2,
        label: 'Nutricionales',
        items: [
            {
                id: 1,
                label: 'Registro de mezclas nutricionales',
                route: '/external_user/nutritional_mixtures_records'
            },
            {
                id: 2,
                label: 'Nuevo pedido nutricional',
                route: '/nutritional_mixtures_request'
            }
        ]
    },
    {
        id: 3,
        label: 'Oncológicos',
        items: [
            {
                id: 1,
                label: 'Registro de órdenes oncológicas',
                route: '/external_user/oncologic_orders_records'
            },
            {
                id: 2,
                label: 'Nuevo pedido oncológica',
                route: '/oncologic_orders_request'
            }
        ]
    }
];

export { defaultExternalUserMenu };
