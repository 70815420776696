var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("b-table", {
        attrs: {
          data: _vm.manufacturers,
          paginated: "",
          "backend-pagination": "",
          total: _vm.manufacturersCount,
          "per-page": _vm.pageSize,
          "aria-next-label": "Siguiente página",
          "aria-previous-label": "Página anterior",
          "aria-page-label": "Página",
          "aria-current-label": "Página actual",
          "backend-sorting": "",
          "default-sort": [_vm.sortField, _vm.sortOrder]
        },
        on: {
          "page-change": function(page) {
            return _vm.$emit("page-change", page)
          },
          sort: function(field, order) {
            return _vm.$emit("sort", field, order)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c("b-table-column", { attrs: { label: "Fabricante" } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(props.row.name) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Editar", centered: "" } },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "button is-small",
                        attrs: { append: "" },
                        on: {
                          click: function($event) {
                            return _vm.editManufacturer(props.row)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon is-medium" },
                          [
                            _c("font-awesome-icon", { attrs: { icon: "edit" } })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Eliminar", centered: "" } },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "button is-small is-danger",
                        attrs: { append: "" },
                        on: {
                          click: function($event) {
                            return _vm.openDeleteManufacturerDialog(
                              props.row.id
                            )
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon is-medium" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "trash-alt" }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }