var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-field",
        { attrs: { grouped: "", "group-multiline": "" } },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: "Volumen total",
                message: "Introduzca el volumen total en ml",
                expanded: ""
              }
            },
            [
              _c(
                "b-field",
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.totalVolume,
                      callback: function($$v) {
                        _vm.totalVolume =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "totalVolume"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "control" }, [
                    _c("span", { staticClass: "button is-static" }, [
                      _vm._v("ml")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "control" }, [
                    _c(
                      "span",
                      {
                        staticClass: "button",
                        on: {
                          click: function($event) {
                            _vm.totalVolume = _vm.computedTotalVolume
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon is-small" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "calculator" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("Calcular")])
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              attrs: {
                label: "Sobrellenado",
                message: "Introduzca una cantidad o deje en 0",
                expanded: ""
              }
            },
            [
              _c(
                "b-field",
                [
                  _c(
                    "b-select",
                    {
                      attrs: { expanded: "" },
                      model: {
                        value: _vm.overfilling,
                        callback: function($$v) {
                          _vm.overfilling = $$v
                        },
                        expression: "overfilling"
                      }
                    },
                    [
                      _c("option", { attrs: { value: "0" } }, [_vm._v("0")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "20" } }, [_vm._v("20")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "25" } }, [_vm._v("25")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "30" } }, [_vm._v("30")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "35" } }, [_vm._v("35")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "50" } }, [_vm._v("50")]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "100" } }, [_vm._v("100")])
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "control" }, [
                    _c("span", { staticClass: "button is-static" }, [
                      _vm._v("ml")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.computeWater
        ? _c(
            "b-field",
            {
              attrs: {
                label: "Agua inyectable",
                message:
                  "La cantidad de agua inyectable se calcula en función al volumen total introducido y el volumen calculado",
                expanded: ""
              }
            },
            [
              _c(
                "b-field",
                [
                  _c("b-input", {
                    attrs: { type: "text", expanded: "", disabled: "" },
                    model: {
                      value: _vm.computeWater,
                      callback: function($$v) {
                        _vm.computeWater = $$v
                      },
                      expression: "computeWater"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "control", attrs: { disabled: "" } }, [
                    _c("span", { staticClass: "button is-static" }, [
                      _vm._v("ml")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }