<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Editar paciente
            </p>
        </header>
        <section class="modal-card-body">
            <b-field
                label="Nombre completo del paciente"
                :type="{'is-danger' : $v.name.$error }"
                :message="{
                    'Se requiere introducir el nombre completo del paciente' : !$v.name.required && $v.name.$error
                }"
                expanded>
                <b-input
                    v-model.trim="$v.name.$model"
                    type="text">
                </b-input>
            </b-field>
            <b-field
                label="Diagnóstico del paciente"
                :type="{'is-danger' : $v.diagnosis.$error }"
                :message="{
                    'Se requiere introducir el diagnóstico del paciente' : !$v.diagnosis.required && $v.diagnosis.$error
                }"
                expanded>
                <b-input
                    v-model.trim="$v.diagnosis.$model"
                    type="text">
                </b-input>
            </b-field>
            <b-field
            grouped>
                <b-field
                :label="'Talla'"
                expanded>
                    <b-field>
                        <b-input
                        v-model="heightInMeters"
                        type="text">
                        </b-input>
                        <p class="control">
                            <span class="button is-static">m</span>
                        </p>
                    </b-field>
                </b-field>
                <b-field
                :label="'Peso'"
                :type="{'is-danger' : $v.weightInKg.$error }"
                :message="{
                    'Se requiere introducir el peso del paciente' : !$v.weightInKg.required && $v.weightInKg.$error
                }"
                expanded>
                    <b-field :type="{'is-danger' : $v.weightInKg.$error }">
                        <b-input
                        v-model.trim="$v.weightInKg.$model"
                        type="text">
                        </b-input>
                        <p class="control">
                            <span class="button is-static">Kg</span>
                        </p>
                    </b-field>
                </b-field>
            </b-field>
            <b-field
            :label="'Superficie corporal'">
                <b-field>
                    <b-input
                    v-model="bodySurface"
                    type="text">
                    </b-input>
                    <p class="control">
                        <span class="button is-static">Kg/m<sup>2</sup></span>
                    </p>
                    <p class="control">
                        <span class="button" @click="computedBodySurface">
                            <span class="icon is-small">
                                <font-awesome-icon icon="calculator" />
                            </span>
                            <span>Calcular</span>
                        </span>
                    </p>
                </b-field>
            </b-field>
            <b-field
            grouped
            group-multiline>
                <b-field
                :label="'Género'"
                :type="{'is-danger' : $v.gender.$error }"
                :message="{
                    'Se requiere introducir el género del paciente' : !$v.gender.required && $v.gender.$error
                }" expanded>
                    <b-field>
                        <b-radio-button
                        v-model="gender"
                        native-value="Masculino"
                        type="is-primary">
                            <font-awesome-icon icon="male" /> 
                            <span style="padding-left:10px">Masculino</span>
                        </b-radio-button>

                        <b-radio-button
                        v-model="gender"
                        native-value="Femenino"
                        type="is-primary">
                            <font-awesome-icon icon="female" />
                            <span style="padding-left:10px">Femenino</span>
                        </b-radio-button>
                    </b-field>
                </b-field>
                <b-field
                :label="'Tipo de paciente'"
                :type="{'is-danger' : $v.ageType.$error }"
                :message="{
                    'Se requiere introducir el tipo del paciente' : !$v.ageType.required && $v.ageType.$error
                }"
                expanded>
                    <b-field>
                        <b-radio-button
                        v-model="ageType"
                        native-value="Adulto"
                        type="is-primary">
                            <span>Adulto</span>
                        </b-radio-button>

                        <b-radio-button
                        v-model="ageType"
                        native-value="Pediátrico"
                        type="is-primary">
                            <span>Pediátrico</span>
                        </b-radio-button>

                        <b-radio-button
                        v-model="ageType"
                        native-value="Neonato"
                        type="is-primary">
                            <span>Neonato</span>
                        </b-radio-button>
                    </b-field>
                </b-field>
            </b-field>
            <b-field
            :label="'Fecha de nacimiento del paciente'"
            :type="{'is-danger' : $v.birthDate.$error }"
            :message="{
                'Se requiere introducir la fecha de nacimiento del paciente' : !$v.birthDate.required && $v.birthDate.$error
            }">
                <b-datepicker
                    v-model="birthDate"
                    :date-formatter="d => d.toLocaleDateString('es-US',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })"
                    :class="{ danger : $v.birthDate.$error }"
                    placeholder="Seleccione una fecha">
                </b-datepicker>
            </b-field>
            <b-field
            :label="'Número de expediente'"
            expanded>
                <b-field>
                    <b-input
                    v-model="fileID"
                    type="text">
                    </b-input>
                </b-field>
            </b-field>
            <b-field
            :label="'Servicio'"
            expanded>
                <b-field>
                    <b-input
                    v-model="location"
                    type="text">
                    </b-input>
                </b-field>
            </b-field>
            <b-field
            :label="'Cama'"
            expanded>
                <b-field>
                    <b-input
                    v-model="bed"
                    type="text">
                    </b-input>
                </b-field>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                Cancelar
            </button>
            <button class="button is-primary" @click="submitForm">
                Editar
            </button>
        </footer>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    props: {
        patient: {
            type: Object,
            default: () => {}
        }
    },
    beforeMount () {
        if ( this.patient && Object.keys(this.patient).length !== 0 && this.patient.constructor === Object ) {
            this.id = this.patient.id;
            this.name = this.patient.name;
            this.diagnosis = this.patient.diagnosis;
            this.heightInMeters = this.patient.height_in_meters;
            this.weightInKg = this.patient.weight_in_kilograms;
            this.bodySurface = this.patient.body_surface_in_square_meters;
            this.gender = this.patient.gender;
            this.ageType = this.patient.age_type;
            this.birthDate = new Date(this.patient.birth_date);
            this.fileID = this.patient.file_id;
            this.location = this.patient.location;
            this.bed = this.patient.bed;

            if ( this.weightInKg && this.heightInMeters ) {
                this.$watch('weightInKg', function ( ) {
                    this.bodySurface = null;
                });
                this.$watch('heightInMeters', function ( ) {
                    this.bodySurface = null;
                });
            }
        }
    },
    created () {
        this.computedBodySurface();
    },
    data () {
        return {
            name: '',
            diagnosis: '',
            heightInMeters: '',
            weightInKg: '',
            bodySurface: '',
            gender: '',
            ageType: '',
            birthDate: null,
            fileID: '',
            location: '',
            bed: '',
        };
    },
    methods: {
        computedBodySurface() {
            if ( this.weightInKg && this.heightInMeters ) {
                const heightInCentimeters = this.heightInMeters * 100;
                const bodySurface = Math.sqrt( ( heightInCentimeters * this.weightInKg ) / 3600 ).toFixed(2);
                return this.bodySurface = bodySurface;
            } else {
                return 0;
            }
        },
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.editPatient(); 
            }
        },
        getParams () {
            return {
                name: this.name,
                diagnosis: this.diagnosis,
                height_in_meters: this.heightInMeters,
                weight_in_kilograms: this.weightInKg,
                body_surface_in_square_meters: this.bodySurface,
                gender: this.gender,
                age_type: this.ageType,
                birth_date: this.birthDate,
                file_id: this.fileID,
                location: this.location,
                bed: this.bed,
                medic_id: this.patient.medic.id
            };
        },
        editPatient () {
            var url = '/api/patients/' + this.patient.id;
            this.makeRequest(url, this.$axios.put, this.getParams());
        },
        makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method(url,params)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha editado el paciente'
                });
                this.$serbitec.finishLoading();
                this.$emit('success');
                this.$emit('close');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    },
    validations: {
        name: {
            required
        },
        diagnosis: {
            required
        },
        weightInKg: {
            required
        },
        gender: {
            required
        },
        ageType: {
            required
        },
        birthDate: {
            required
        }
    }
};
</script>