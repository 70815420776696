var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card", staticStyle: { width: "auto" } },
    [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.comesFromEdit
                  ? "Editar usuario interno"
                  : "Agregar usuario interno"
              ) +
              "\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Nombre",
                    type: { "is-danger": _vm.$v.name.$error },
                    message: {
                      "Se requiere introducir el nombre del usuario interno":
                        !_vm.$v.name.required && _vm.$v.name.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.name, "$model", $$v)
                      },
                      expression: "$v.name.$model"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Apellido paterno",
                    type: { "is-danger": _vm.$v.lastName1.$error },
                    message: {
                      "Se requiere introducir el apellido paterno del usuario interno":
                        !_vm.$v.lastName1.required && _vm.$v.lastName1.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.lastName1.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.lastName1, "$model", $$v)
                      },
                      expression: "$v.lastName1.$model"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Apellido materno",
                    type: { "is-danger": _vm.$v.lastName2.$error },
                    message: {
                      "Se requiere introducir el apellido materno del usuario interno":
                        !_vm.$v.lastName2.required && _vm.$v.lastName2.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.lastName2.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.lastName2, "$model", $$v)
                      },
                      expression: "$v.lastName2.$model"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              attrs: {
                label: "Correo electrónico",
                type: { "is-danger": _vm.$v.email.$error },
                message: {
                  "Se requiere introducir el correo electrónico del usuario interno":
                    !_vm.$v.email.required && _vm.$v.email.$error
                },
                expanded: ""
              }
            },
            [
              _c("b-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.$v.email.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$v.email,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.email.$model"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { label: "Tipo de usuario", expanded: "" } },
            [
              _c(
                "b-field",
                [
                  _c(
                    "b-radio",
                    {
                      attrs: {
                        name: "type",
                        "native-value": 4,
                        type: "is-primary"
                      },
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Administrador central de mezclas\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-radio",
                    {
                      attrs: {
                        name: "type",
                        "native-value": 5,
                        type: "is-primary"
                      },
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Coordinador central de mezclas\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-radio",
                    {
                      attrs: {
                        name: "type",
                        "native-value": 6,
                        type: "is-primary"
                      },
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Operativo técnico\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-radio",
                    {
                      attrs: {
                        name: "type",
                        "native-value": 7,
                        type: "is-primary"
                      },
                      model: {
                        value: _vm.type,
                        callback: function($$v) {
                          _vm.type = $$v
                        },
                        expression: "type"
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Operativo químico\n                "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$parent.close()
              }
            }
          },
          [_vm._v("\n            Cancelar\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button is-primary", on: { click: _vm.submitForm } },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.comesFromEdit ? "Editar" : "Agregar") +
                "\n        "
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }