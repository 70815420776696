var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card", staticStyle: { width: "auto" } },
    [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(
            "\n            " +
              _vm._s(
                _vm.comesFromEdit ? "Editar fabricante" : "Agregar fabricante"
              ) +
              "\n        "
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: "Nombre del fabricante",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.name.$error },
                message: {
                  "Se requiere introducir el nombre del fabricante":
                    !_vm.$v.name.required && _vm.$v.name.$error
                },
                expanded: ""
              }
            },
            [
              _c("b-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.$v.name.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.name, "$model", $$v)
                  },
                  expression: "$v.name.$model"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$parent.close()
              }
            }
          },
          [_vm._v("\n            Cancelar\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button is-primary", on: { click: _vm.submitForm } },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.comesFromEdit ? "Editar" : "Agregar") +
                "\n        "
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }