var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        attrs: { data: _vm.data },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c(
                  "b-table-column",
                  { attrs: { label: "Tipo de componente", centered: "" } },
                  [_c("b-tag", [_vm._v(_vm._s(props.row.type))])],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { field: "props.row.name", label: "Componente" } },
                  [
                    props.row.selectedComponent
                      ? _c("div", [
                          _vm._v(_vm._s(props.row.selectedComponent.name))
                        ])
                      : _c("div", [_vm._v(_vm._s(props.row.name))])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  {
                    attrs: {
                      field: "props.row.quantity",
                      label: "Cantidad",
                      centered: ""
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.quantity) +
                        " " +
                        _vm._s(props.row.unitSymbol) +
                        "\n            "
                    )
                  ]
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }