var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "box" },
        _vm._l(_vm.arrangedElements, function(arrangedElement, index) {
          return _c(
            "div",
            { key: index },
            [
              arrangedElement.elements.length > 0
                ? _c("h1", { staticClass: "has-text-weight-bold is-size-5" }, [
                    _vm._v(_vm._s(arrangedElement.name))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("b-table", {
                attrs: { data: arrangedElement.elements },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "b-table-column",
                            {
                              attrs: {
                                field: "component.name",
                                label: "Componente"
                              }
                            },
                            [
                              _c("div", [
                                _vm._v(_vm._s(props.row.component.name))
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-table-column",
                            {
                              attrs: { label: "Cantidad cliente", centered: "" }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    (
                                      props.row.medicine_quantity *
                                      props.row.multiply_factor_to_selected_unit
                                    ).toFixed(2)
                                  ) +
                                  " " +
                                  _vm._s(props.row.selected_unit_code) +
                                  "\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-table-column",
                            {
                              attrs: { label: "Cantidad en ml", centered: "" }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(props.row.medicine_quantity) +
                                  " ml\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          arrangedElement.name == "Macronutrientes"
                            ? _c(
                                "b-table-column",
                                {
                                  attrs: {
                                    label: "Concentración",
                                    centered: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.totalGramsForMacrocomponent(
                                          props.row
                                        )
                                      ) +
                                      " %\n                    "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          arrangedElement.name == "Electrolitos"
                            ? _c(
                                "b-table-column",
                                {
                                  attrs: {
                                    label: "Cantidad en mEq",
                                    centered: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.electrolyteInMEq(props.row)) +
                                      " mEq\n                    "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.hasInfusionSet
        ? _c("b-message", { staticClass: "is-success" }, [
            _c("nav", { staticClass: "level" }, [
              _c("div", { staticClass: "level-left" }, [
                _c("h1", [_vm._v("Set de infusión")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "level-right" }, [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "check-circle" } })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v(" Solicitado")])
              ])
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }