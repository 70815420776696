<template>
    <b-select
        :value="selectedClientPrefix"
        @input="option => $emit('update:selected-client-prefix', option)"
        expanded
    >
        <option
            v-for="option in clientPrefixes"
            :key="option.id"
            :value="option"
        >
            {{ option.prefix }}
        </option>
    </b-select>
</template>

<script>
import gql from 'graphql-tag';
const query = gql` query
{
    clientPrefixes: warehouse_item_client_prefixes {
        id
        prefix
    }
}`;

export default {
    props: {
        selectedClientPrefix: {
            type: Object,
            default: () => {}
        }
    },
    apollo: {
        clientPrefixes: {
            query: query,
            watchLoading (isLoading) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            }
        }
    }
};
</script>