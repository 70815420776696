<template>
    <div class="box">
        <b-table
            :data="manufacturers"

            paginated
            backend-pagination
            :total="manufacturersCount"
            :per-page="pageSize"
            @page-change="(page) => $emit('page-change', page)"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="(field, order) => $emit('sort', field, order)"
        >
            <template slot-scope="props">
                <b-table-column label="Fabricante">
                    {{ props.row.name }}
                </b-table-column>
                <b-table-column label="Editar" centered>
                    <a
                        @click="editManufacturer(props.row)"
                        class="button is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="edit" />
                        </span>
                    </a>
                </b-table-column>
                <b-table-column label="Eliminar" centered>
                    <a
                        @click="openDeleteManufacturerDialog(props.row.id)"
                        class="button is-small is-danger"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="trash-alt" />
                        </span>
                    </a>
                </b-table-column>
            </template>
        </b-table>
    </div>
</template>

<script>
import serbitecMedicineManufacturersModal from '../../../components/dashboard/medicine-manufacturers/medicine-manufacturer-modal.vue';

export default {
    props: {
        manufacturers: {
            type: Array,
            default: () => []
        },
        manufacturersCount: {
            type: [Number, String],
            default: 0| '0'
        },
        page: {
            type: [Number, String],
            default: 0 | ''
        },
        pageSize: {
            type: [Number, String],
            default: 0 | ''
        },
        sortField: {
            type: String,
            default: ''
        },
        sortOrder: {
            type: String,
            default: ''
        }
    },
    methods: {
        editManufacturer (manufacturer) {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecMedicineManufacturersModal,
                hasModalCard: true,
                trapFocus: true,
                props: {
                    'manufacturer': manufacturer
                },
                events: {
                    'success': () => this.$emit('reload-manufacturers')
                }
            });
        },
        openDeleteManufacturerDialog (itemId) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar fabricante',
                message: '¿Seguro que deseas eliminar el fabricante?',
                confirmText: 'Eliminar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteManufacturer(itemId)
            });
        },
        deleteManufacturer (itemId) {
            this.$serbitec.startLoading();
            this.$axios.delete('/api/medicine_manufacturers/' + itemId)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha eliminado el fabricante'
                });
                this.$serbitec.finishLoading();
                this.$emit('reload-manufacturers');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    }
};
</script>