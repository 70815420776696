<template>
        <b-autocomplete
            v-model="searchParam"
            :data="medicines"
            :loading="autocompleteIsLoading"
            placeholder="Introduzca el nombre del medicamento y seleccione"
            @select="option => selectMedicine(option)"
        >
            <template slot-scope="props">
                <div class="media">
                    <div class="media-content">
                        <strong>{{ props.option.commercial_name }}</strong>
                        <br>
                        <p class="has-text-weight-normal is-small">
                            Genérico: {{ props.option.component.name }}
                            <br>
                            Fabricante: {{ props.option.manufacturer.name }} ml
                        </p>
                    </div>
                </div>
            </template>

            <template slot="empty">
                <p>No se encontró el medicamento...</p>
            </template>
        </b-autocomplete>
</template>

<script>
import gql from 'graphql-tag';
const query = gql` query ( $search: String, $with_component_type_ids: String, $enabled_for_payer_id: Int )
{
    medicines( search: $search, without_component_type_ids: "9", with_component_type_ids: $with_component_type_ids, enabled_for_payer_id: $enabled_for_payer_id ) {
        id
        commercial_name
        manufacturer {
            id
            name
        }
        component_unit{
            id
            symbol
        }
        component {
            id
            component_type{
                id
            }
            name
        }
        presentations {
            id
            component_quantity
            volume_in_ml
        }
        oncologic_thinners {
            id
            name
            pivot {
                id
                min_recommended_concentration
                max_recommended_concentration
                reference
            }
        }
    }
}`;

function initialDataState () {
    return {
        searchParam: ' ',
        autocompleteIsLoading: false
    };
}

export default {
    props:['withComponentTypeIds', 'payerId'],
    data: initialDataState,
    methods: {
        async selectMedicine ( medicine ) {
            this.$emit('medicine-selected', medicine);
        },
    },
    apollo: {
        medicines: {
            query: query,
            variables () {
                let params = {
                    search: this.searchParam,
                    with_component_type_ids: this.withComponentTypeIds
                };
                if ( this.payerId ){
                    params.enabled_for_payer_id = this.payerId;
                }
                return params;
            }
        },
    }
};
</script>