<template>
    <div>
        <h1 class="subtitle">Almacén del cliente</h1>
        <form
        @submit.prevent="submitForm"
        novalidate="true">
            <b-message v-if="!payerWarehousePrefix">
                Si el cliente cuenta con medicamentos propios en el almacén externo favor de seleccionar la casilla, introducir un prefijo y dar de alta el prefijo para el cliente
                <div class="field serbitec-small-top-margin">
                    <b-checkbox
                        v-model="clientHasExternalWarehouse"
                    >
                        El cliente cuenta con medicamentos en almacén
                    </b-checkbox>
                </div>
                <b-field v-if="clientHasExternalWarehouse" grouped group-multiline>
                    <b-field
                        label="Prefijo del almacén externo"
                        label-position="on-border"
                        :type="{'is-danger' : $v.clientWarehousePrefix.$error }"
                        :message="{
                            'Se requiere introducir el prefijo' : !$v.clientWarehousePrefix.required && $v.clientWarehousePrefix.$error
                        }"
                    >
                            <b-input v-model="$v.clientWarehousePrefix.$model" />
                    </b-field>
                    <p class="control">
                        <button class="button is-primary">Alta</button>
                    </p>
                </b-field>
            </b-message>
            <b-field v-else grouped group-multiline>
                <b-field
                    label="Prefijo del almacén externo"
                    label-position="on-border"
                    :type="{'is-danger' : $v.clientWarehousePrefix.$error }"
                    :message="{
                        'Se requiere introducir el prefijo' : !$v.clientWarehousePrefix.required && $v.clientWarehousePrefix.$error
                    }"
                >
                        <b-input v-model="$v.clientWarehousePrefix.$model" />
                </b-field>
                <p class="control">
                    <button class="button is-primary">Editar</button>
                </p>
            </b-field>
        </form>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import axios from 'axios';
import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    payerWarehousePrefix: payer(id:$id) {
        id
        warehouse_item_client_prefix {
            id
            prefix
        }
    }
}`;

export default {
    data () {
        return {
            clientWarehousePrefix: null,
            clientHasExternalWarehouse: false
        };
    },
    methods: {
        submitForm() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.storeOrEditPrefix(this.clientHasExternalWarehouse ? 'store': 'update');
            }
        },
        storeOrEditPrefix(type){
            const params = {
                prefix: this.clientWarehousePrefix
            };
            var route = null;
            if (type === 'store') {
                route = '/api/payers/' + this.$route.query.payer_id + '/prefixes';
            } else {
                route = '/api/payers/prefixes/' + this.payerWarehousePrefix.id;
            }
            this.makeRequest(route, type === 'store' ? axios.post : axios.put, params, type);
        },
        async makeRequest( url, method, params, comesFrom ){
            const loader = this.$buefy.loading.open();
            try {
                await method( url, params );
                if ( comesFrom == 'store' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha dado de alta el prefijo para el almacén del cliente'
                    });
                }
                if ( comesFrom == 'update' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha modificado el prefijo'
                    });
                }
                this.clientHasExternalWarehouse = false;
                this.$apollo.queries.payerWarehousePrefix.refetch();
                loader.close();
            } catch (error) {
                console.error(error);
                this.$notify({
                    title: '¡Algo ha sucedido!',
                    type: 'error',
                    text: 'Por favor contacte al administrador'
                });
                loader.close();
            }
        },
    },
    validations: {
        clientWarehousePrefix: {
            required
        }
    },
    apollo: {
        payerWarehousePrefix: {
            query: query,
            variables () {
                return {
                    id: this.$route.query.payer_id
                };
            },
            update (data) {
                if (data.payerWarehousePrefix.warehouse_item_client_prefix) {
                    this.clientWarehousePrefix = data.payerWarehousePrefix.warehouse_item_client_prefix.prefix;
                }
                return data.payerWarehousePrefix.warehouse_item_client_prefix;
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            }
        },
    }
};
</script>
