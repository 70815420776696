<template>
    <div>
        <div class="serbitec-small-top-margin block">
            <b-radio
                v-model="radio"
                native-value="external"
                @input="updateWarehouseReportOptions"
            >
                Inventario
            </b-radio>
            <b-radio
                v-model="radio"
                native-value="internal"
                @input="updateWarehouseReportOptions"
            >
                Insumos del área de preparación
            </b-radio>
            <b-checkbox
                v-if="radio === 'internal'"
                v-model="type"
                native-value="npt"
                @input="updateWarehouseReportOptions"
            >
                Nutricional
            </b-checkbox>
            <b-checkbox
                v-if="radio === 'internal'"
                v-model="type"
                native-value="onco"
                @input="updateWarehouseReportOptions"
            >
                Oncológico
            </b-checkbox>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            radio: 'external',
            type: []
        };
    },
    methods: {
        updateWarehouseReportOptions () {
            let type = this.type.toString();
            if (this.type.length > 1) {
                type = 'both';
            }
            let data = {
                location: this.radio,
                type: type
            };
            this.$store.commit('reports/UPDATE_WAREHOUSE_OPTIONS', data);
        }
    }
};
</script>