var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { novalidate: "true" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitPayerMedicinePresentationForm($event)
          }
        }
      },
      [
        _c("h1", { staticClass: "subtitle" }, [
          _vm._v("Agregar precio por presentación")
        ]),
        _vm._v(" "),
        _vm._l(_vm.$v.presentations.$each.$iter, function(presentation) {
          return _c(
            "b-field",
            {
              key: presentation.$model.id,
              attrs: { grouped: "", "group-multiline": "" }
            },
            [
              _c(
                "b-field",
                [
                  _c("b-field", [
                    _c("p", { staticClass: "control" }, [
                      _c("span", { staticClass: "button is-static" }, [
                        _vm._v(
                          "\n                            Presentación: " +
                            _vm._s(presentation.$model.volumeInMl) +
                            " ml\n                        "
                        )
                      ])
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    type: { "is-danger": presentation.$error },
                    message: {
                      "Se requiere introducir el precio de la presentación":
                        !presentation.priceInCents.required &&
                        presentation.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c(
                    "b-field",
                    { attrs: { type: { "is-danger": presentation.$error } } },
                    [
                      _c("p", { staticClass: "control" }, [
                        _c("button", { staticClass: "button is-static" }, [
                          _vm._v("$")
                        ])
                      ]),
                      _vm._v(" "),
                      _c("b-input", {
                        directives: [
                          {
                            name: "cleave",
                            rawName: "v-cleave",
                            value: _vm.masks.numeral,
                            expression: "masks.numeral"
                          }
                        ],
                        attrs: { type: "text", expanded: "" },
                        nativeOn: {
                          input: function($event) {
                            return _vm.onInputPricePerMlOrPz(
                              presentation.$model.id
                            )
                          }
                        },
                        model: {
                          value: presentation.priceInCents.$model,
                          callback: function($$v) {
                            _vm.$set(presentation.priceInCents, "$model", $$v)
                          },
                          expression: "presentation.priceInCents.$model"
                        }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "control" }, [
                        _vm.medicineUnitSymbol == "pz"
                          ? _c("button", { staticClass: "button is-static" }, [
                              _vm._v("MXN por pieza")
                            ])
                          : _c("button", { staticClass: "button is-static" }, [
                              _vm._v("MXN por ml")
                            ])
                      ]),
                      _vm._v(" "),
                      _vm.pricePerFlask
                        ? _c("p", { staticClass: "control" }, [
                            _c("span", { staticClass: "button is-static" }, [
                              _vm._v(
                                "\n                            Precio por frasco: $" +
                                  _vm._s(
                                    (
                                      presentation.$model.volumeInMl *
                                      presentation.$model.rawPriceInCents
                                    ).toFixed(2)
                                  ) +
                                  " MXN\n                        "
                              )
                            ])
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        _vm._v(" "),
        _c("br"),
        _vm._v(" "),
        _c("b-field", { attrs: { grouped: "", position: "is-centered" } }, [
          _c("p", { staticClass: "control" }, [
            _c(
              "button",
              { staticClass: "button is-primary", attrs: { type: "submit" } },
              [
                _c("span", [
                  _vm._v(
                    "\n                        Agregar medicamento\n                    "
                  )
                ])
              ]
            )
          ])
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }