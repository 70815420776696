var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card", staticStyle: { width: "auto" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-field",
            {
              attrs: {
                label: "Nombre completo del paciente",
                type: { "is-danger": _vm.$v.name.$error },
                message: {
                  "Se requiere introducir el nombre completo del paciente":
                    !_vm.$v.name.required && _vm.$v.name.$error
                },
                expanded: ""
              }
            },
            [
              _c("b-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.$v.name.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$v.name,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.name.$model"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              attrs: {
                label: "Diagnóstico del paciente",
                type: { "is-danger": _vm.$v.diagnosis.$error },
                message: {
                  "Se requiere introducir el diagnóstico del paciente":
                    !_vm.$v.diagnosis.required && _vm.$v.diagnosis.$error
                },
                expanded: ""
              }
            },
            [
              _c("b-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.$v.diagnosis.$model,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.$v.diagnosis,
                      "$model",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "$v.diagnosis.$model"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _c(
                "b-field",
                { attrs: { label: "Talla", expanded: "" } },
                [
                  _c(
                    "b-field",
                    [
                      _c("b-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.heightInMeters,
                          callback: function($$v) {
                            _vm.heightInMeters = $$v
                          },
                          expression: "heightInMeters"
                        }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "control" }, [
                        _c("span", { staticClass: "button is-static" }, [
                          _vm._v("m")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Peso",
                    type: { "is-danger": _vm.$v.weightInKg.$error },
                    message: {
                      "Se requiere introducir el peso del paciente":
                        !_vm.$v.weightInKg.required && _vm.$v.weightInKg.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c(
                    "b-field",
                    {
                      attrs: { type: { "is-danger": _vm.$v.weightInKg.$error } }
                    },
                    [
                      _c("b-input", {
                        attrs: { type: "text" },
                        model: {
                          value: _vm.$v.weightInKg.$model,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.$v.weightInKg,
                              "$model",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "$v.weightInKg.$model"
                        }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "control" }, [
                        _c("span", { staticClass: "button is-static" }, [
                          _vm._v("Kg")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { label: "Superficie corporal" } },
            [
              _c(
                "b-field",
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.bodySurface,
                      callback: function($$v) {
                        _vm.bodySurface = $$v
                      },
                      expression: "bodySurface"
                    }
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "control" }, [
                    _c("span", { staticClass: "button is-static" }, [
                      _vm._v("Kg/m"),
                      _c("sup", [_vm._v("2")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "control" }, [
                    _c(
                      "span",
                      {
                        staticClass: "button",
                        on: { click: _vm.computedBodySurface }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon is-small" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "calculator" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("Calcular")])
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { grouped: "", "group-multiline": "" } },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Género",
                    type: { "is-danger": _vm.$v.gender.$error },
                    message: {
                      "Se requiere introducir el género del paciente":
                        !_vm.$v.gender.required && _vm.$v.gender.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c(
                    "b-field",
                    [
                      _c(
                        "b-radio-button",
                        {
                          attrs: {
                            "native-value": "Masculino",
                            type: "is-primary"
                          },
                          model: {
                            value: _vm.gender,
                            callback: function($$v) {
                              _vm.gender = $$v
                            },
                            expression: "gender"
                          }
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "male" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticStyle: { "padding-left": "10px" } },
                            [_vm._v("Masculino")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-radio-button",
                        {
                          attrs: {
                            "native-value": "Femenino",
                            type: "is-primary"
                          },
                          model: {
                            value: _vm.gender,
                            callback: function($$v) {
                              _vm.gender = $$v
                            },
                            expression: "gender"
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "female" }
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticStyle: { "padding-left": "10px" } },
                            [_vm._v("Femenino")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Tipo de paciente",
                    type: { "is-danger": _vm.$v.ageType.$error },
                    message: {
                      "Se requiere introducir el tipo del paciente":
                        !_vm.$v.ageType.required && _vm.$v.ageType.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c(
                    "b-field",
                    [
                      _c(
                        "b-radio-button",
                        {
                          attrs: {
                            "native-value": "Adulto",
                            type: "is-primary"
                          },
                          model: {
                            value: _vm.ageType,
                            callback: function($$v) {
                              _vm.ageType = $$v
                            },
                            expression: "ageType"
                          }
                        },
                        [_c("span", [_vm._v("Adulto")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-radio-button",
                        {
                          attrs: {
                            "native-value": "Pediátrico",
                            type: "is-primary"
                          },
                          model: {
                            value: _vm.ageType,
                            callback: function($$v) {
                              _vm.ageType = $$v
                            },
                            expression: "ageType"
                          }
                        },
                        [_c("span", [_vm._v("Pediátrico")])]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-radio-button",
                        {
                          attrs: {
                            "native-value": "Neonato",
                            type: "is-primary"
                          },
                          model: {
                            value: _vm.ageType,
                            callback: function($$v) {
                              _vm.ageType = $$v
                            },
                            expression: "ageType"
                          }
                        },
                        [_c("span", [_vm._v("Neonato")])]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              attrs: {
                label: "Fecha de nacimiento del paciente",
                type: { "is-danger": _vm.$v.birthDate.$error },
                message: {
                  "Se requiere introducir la fecha de nacimiento del paciente":
                    !_vm.$v.birthDate.required && _vm.$v.birthDate.$error
                }
              }
            },
            [
              _c("b-datepicker", {
                class: { danger: _vm.$v.birthDate.$error },
                attrs: {
                  "date-formatter": function(d) {
                    return d.toLocaleDateString("es-US", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric"
                    })
                  },
                  placeholder: "Seleccione una fecha"
                },
                model: {
                  value: _vm.birthDate,
                  callback: function($$v) {
                    _vm.birthDate = $$v
                  },
                  expression: "birthDate"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { label: "Número de expediente", expanded: "" } },
            [
              _c(
                "b-field",
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.fileID,
                      callback: function($$v) {
                        _vm.fileID = $$v
                      },
                      expression: "fileID"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { label: "Servicio", expanded: "" } },
            [
              _c(
                "b-field",
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.location,
                      callback: function($$v) {
                        _vm.location = $$v
                      },
                      expression: "location"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { label: "Cama", expanded: "" } },
            [
              _c(
                "b-field",
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.bed,
                      callback: function($$v) {
                        _vm.bed = $$v
                      },
                      expression: "bed"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$parent.close()
              }
            }
          },
          [_vm._v("\n            Cancelar\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button is-primary", on: { click: _vm.submitForm } },
          [_vm._v("\n            Editar\n        ")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v("\n            Editar paciente\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }