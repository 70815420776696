<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right">
                <a
                    class="button"
                    :href="'/reports/warehouse/stock?location=internal&doc_type=pdf&payer_ids=all&type=onco'"
                    target="_blank"
                >
                    <span class="icon">
                        <font-awesome-icon icon="print" />
                    </span>
                    <span>Imprimir existencias</span>
                </a>
            </div>
        </nav>
        <hr>
        <div class="box">
            <serbitec-oncologic-preparation-area-update-supplies
                :comes-from-cycle="true"
                :enable-weekly-cycle-input="true"
                internal-warehouse-type="oncologic" />
        </div>
    </div>
</template>

<script>
import serbitecOncologicPreparationAreaUpdateSupplies from '../../../components/dashboard/preparation-area-supplies/items-table.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';

export default {
    components: {
        serbitecOncologicPreparationAreaUpdateSupplies,
        serbitecBreadcumbs
    },
    data () {
        return {
            route: [
                {
                    label: 'Insumos oncológicos del área de preparación',
                    to: '/oncologic_preparation_area_supplies',
                    active: false
                },
                {
                    label: 'Actualización de insumos',
                    to: '/oncologic_preparation_area_supplies/update',
                    active: true
                }
            ]
        };
    }
};
</script>