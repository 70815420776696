<template>
    <div class="box">
        <b-table
            :data="components"

            paginated
            backend-pagination
            :total="componentsCount"
            :per-page="pageSize"
            @page-change="(page) => $emit('page-change', page)"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="(field, order) => $emit('sort', field, order)"
            detailed
            detail-key="id"
        >
            <template slot-scope="props">
                <b-table-column label="Nombre del genérico">
                    {{ props.row.name }}
                    <b-tag v-if="props.row.is_nutritional" rounded>
                        Nutricional
                    </b-tag>
                    <b-tag v-if="props.row.is_oncologic" rounded>
                        Oncológico
                    </b-tag>
                </b-table-column>
                <b-table-column label="Tipo">
                    {{ props.row.component_type.name }}
                </b-table-column>
                <b-table-column label="Editar" centered>
                    <a
                        @click="editComponent(props.row)"
                        class="button is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="edit" />
                        </span>
                    </a>
                </b-table-column>
                <b-table-column label="Eliminar" centered>
                    <a
                        @click="openDeleteComponentDialog(props.row.id)"
                        class="button is-small is-danger"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="trash-alt" />
                        </span>
                    </a>
                </b-table-column>
            </template>
            <template
                slot="detail"
                slot-scope="props"
            >
                <b-message
                    v-if="props.row.nutritional_field
                    && Object.keys(props.row.nutritional_field).length !== 0
                    && props.row.nutritional_field.constructor === Object"
                    size="is-small"
                >
                    <strong>Información nutricional del componente</strong><br>
                    <div class="columns">
                        <div class="column is-one-third">
                            Aporte calórico: <strong>{{ props.row.nutritional_field.energy_input_in_kcal }} Kcal</strong> <br>
                            Aporte calórico no protéico: <strong>{{ props.row.nutritional_field.non_protein_energy_input_in_kcal }} Kcal</strong> <br>
                            Gramos de aminoácido: <strong>{{ props.row.nutritional_field.grams_of_aminoacid }} g</strong> <br>
                            Gramos de nitrógeno: <strong>{{ props.row.nutritional_field.grams_of_nitrogen }} g</strong> <br>
                            Concentración osmótica: <strong>{{ props.row.nutritional_field.osmotic_concentration_in_mosmol_l }} mOsmol/L</strong> <br>
                        </div>
                        <div class="column is-one-third">
                            Densidad: <strong>{{ props.row.nutritional_field.density_in_g_per_ml }} g/mL</strong> <br>
                            Sodio (Na): <strong>{{ props.row.nutritional_field.na_in_meq }} mEq</strong> <br>
                            Potasio (K): <strong>{{ props.row.nutritional_field.k_in_meq }} mEq</strong> <br>
                            Magnesio (Mg): <strong>{{ props.row.nutritional_field.mg_in_meq }} mEq</strong> <br>
                        </div>
                        <div class="column is-one-third">
                            Calcio (Ca): <strong>{{ props.row.nutritional_field.ca_in_meq }} mEq</strong> <br>
                            Acetato (Ace): <strong>{{ props.row.nutritional_field.ace_in_meq }} mEq</strong> <br>
                            Cloro (Cl): <strong>{{ props.row.nutritional_field.cl_in_meq }} mEq</strong> <br>
                            Fosfato (PO4): <strong>{{ props.row.nutritional_field.po4_in_meq }} mEq</strong> <br>
                        </div>
                    </div>
                </b-message>
                <b-message v-else size="is-small">
                    El componente no contiene información nutricional
                </b-message>
            </template>
        </b-table>
    </div>
</template>

<script>
import serbitecComponentModal from '../../../components/dashboard/medicine-components/modal.vue';

export default {
    props: {
        components: {
            type: Array,
            default: () => []
        },
        componentsCount: {
            type: [Number, String],
            default: 0| '0'
        },
        page: {
            type: [Number, String],
            default: 0 | ''
        },
        pageSize: {
            type: [Number, String],
            default: 0 | ''
        },
        sortField: {
            type: String,
            default: ''
        },
        sortOrder: {
            type: String,
            default: ''
        }
    },
    methods: {
        editComponent (component) {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecComponentModal,
                hasModalCard: true,
                trapFocus: true,
                fullScreen: true,
                props: {
                    'component': component
                },
                events: {
                    'success': () => this.$emit('reload-components')
                }
            });
        },
        openDeleteComponentDialog (itemId) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar componente',
                message: '¿Seguro que deseas eliminar el componente? <br><small>Los componentes no se pueden recuperar y los registros de mezclas preparadas con este componente se pueden ver afectadas.</small>',
                confirmText: 'Eliminar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteComponent(itemId)
            });
        },
        deleteComponent (itemId) {
            this.$serbitec.startLoading();
            this.$axios.delete('/api/medicine_components/' + itemId)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha eliminado el componente'
                });
                this.$serbitec.finishLoading();
                this.$emit('reload-components');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    }
};
</script>