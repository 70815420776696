<template>
    <div class="box">
        <div class="column is-flex is-horizontal-center">
            <figure class="image is-256x256">
                <img src="images/SERBITEC_login.png">
            </figure>
        </div>
        <form
        @submit.prevent="submitForm"
        novalidate="true">
            <b-field
                label="Correo electrónico"
                :type="{'is-danger' : $v.email.$error }"
                :message="{
                    'Se requiere introducir el correo electrónico' : !$v.email.required && $v.email.$error,
                    'Se requiere introducir un correo electrónico válido' : !$v.email.email && $v.email.$error
                }"
                label-position="on-border"
                expanded
            >
                <b-input
                    v-model="$v.email.$model"
                    type="text">
                </b-input>
            </b-field>
            <b-field
                label="Contraseña"
                :type="{'is-danger' : $v.password.$error }"
                :message="{
                    'Se requiere introducir la contraseña' : !$v.password.required && $v.password.$error
                }"
                label-position="on-border"
                expanded
            >
                <b-input
                    v-model="$v.password.$model"
                    type="password"
                    password-reveal>
                </b-input>
            </b-field>
            <b-field grouped position="is-centered">
                <p class="control">
                    <button type="submit" class="button is-primary">
                        <span>
                            Iniciar sesión
                        </span>
                    </button>
                </p>
            </b-field>
            <b-field class="serbitec-medium-bottom-margin" grouped position="is-centered">
                <router-link
                    :to="{ path: '/forgot_password' }"
                >
                    ¿Olvidaste tu contraseña?
                </router-link>
            </b-field>
        </form>
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import axios from 'axios';

export default {
    data () {
        return {
            email: '',
            password: ''
        };
    },
    methods: {
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.login();
            }
        },
        login() {
            const loader = this.$buefy.loading.open();
            const params = {
                email: this.email,
                password: this.password
            };
            this.$store.dispatch('user/GET_USER', params);
            axios.post('/login', params).then(() => {
                this.$router.push({ path: '/' });
                loader.close();
            }).catch((error) => {
                console.error(error);
                this.$notify({
                    title: '¡Algo ha sucedido!',
                    type: 'error',
                    text: error.response.data.errors ? error.response.data.errors.email[0] : 'Algo ha sucedido, contacte al administrador'
                });
                loader.close();
            });
        }
    },
    validations: {
        email: {
            required,
            email
        },
        password: {
            required
        }
    }
};
</script>