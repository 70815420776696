<template>
    <div>
        <nav class="level is-mobile">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title">Distribución de órdenes oncológicas</h1>
                </div>
            </div>
            <div class="level-right" />
        </nav>
        <hr>
        <serbitec-oncologic-distribution-table
        :table-type="'oncologic'"
        :mixtures="orders"
        :mixtures-count="ordersCount"

        :page.sync="page"
        :pageSize="pageSize"
        :sortField.sync="sortField"
        :sortOrder.sync="sortOrder"

        @page-change="onPageChange"
        @sort="onSort"
        @order-sent="orderSent"
        @order-delivered="orderDelivered"></serbitec-oncologic-distribution-table>
    </div>
</template>

<script>
import serbitecOncologicDistributionTable from '../../../components/dashboard/distribution/distribution-table.vue';
import axios from 'axios';

import gql from 'graphql-tag';
const query = gql` query ( $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String )
{
    orders: oncologic_orders( flask_or_mixture_state_ids: "4,5,6,8,9", page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction ) {
        id
        delivery_date
        patient {
            id
            name
        }
        medic {
            id
            payer {
                id
                invoicing_data {
                    id
                    business_name
                }
            }
        }
        mixtures {
            id
            state {
                id
                name
            }
        }
        requested_flasks {
            id
            state {
                id
                name
            }
        }
    }
    ordersCount: table_counts( flask_or_mixture_state_ids: "4,5,6,8,9", count_type: "oncologic_orders" ) {
        oncologic_orders
    }
}`;

function initialDataState () {
    return {
        ordersCount: null,
        page: 0,
        pageSize: 10,
        sortField: 'id',
        sortOrder:'desc'
    };
}

export default {
    components: {
       serbitecOncologicDistributionTable 
    },
    data: initialDataState,
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
        orderSent ( orderId ) {
            var url = '/api/oncologic/orders/state';
            const requestParams = {
                id: orderId,
                state_id: 8
            };
            this.makeRequest( url, axios.put, requestParams, 'order-sent' );
        },
        orderDelivered ( orderId ) {
            var url = '/api/oncologic/orders/state';
            const requestParams = {
                id: orderId,
                state_id: 10
            };
            this.makeRequest( url, axios.put, requestParams, 'order-delivered' );
        },
        makeRequest ( url, method, params, comesFrom ) {
            this.$serbitec.startLoading();
            method( url, params )
            .then(function ( ) {
                this.$apollo.queries.orders.refetch();
                if ( comesFrom == 'order-sent' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha enviado la orden'
                    });
                } else if ( comesFrom == 'order-delivered' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha entregado la orden'
                    });
                }
                this.$serbitec.finishLoading();
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$serbitec.finishLoading();
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
        }
    },
    apollo: {
        orders: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update ( data ) {
                this.ordersCount = data.ordersCount.oncologic_orders;
                return data.orders;
            }
        },
    }
};
</script>