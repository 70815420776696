<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <h1 class="title">Generación de reportes</h1>
            </div>
            <div class="level-right" />
        </nav>
        <hr>
        <form
        @submit.prevent="submitGenerateReport"
        novalidate="true">
            <b-field>
                <serbitec-report-type-select
                    :selectedReportType="$v.selectedReportType"
                    @report-type-selected="reportTypeSelected"
                />
            </b-field>
            <serbitec-report-options
                :options="selectedReportType.options"
            />
            <div
                class="serbitec-small-top-margin"
                v-if="selectedReportType.id !== 3 && selectedReportType.id !== 5"
            >
                <serbitec-date-picker
                    :selectedDatesRange="$v.selectedDatesRange"
                    @dates-range-selected="datesRangeSelected"
                />
                <b-message size="is-small" >
                    El rango de fechas que se seleccione en el campo fecha de entrega corresponde a la fecha en la que la mezcla se cambió al estado entregada. El reporte se generará de acuerdo a este criterio.
                </b-message>
            </div>
            <b-field 
            class="serbitec-small-top-margin"
            v-if="selectedReportType.id !== 5"
            >
                <b-radio
                    v-model="allClients"
                    :native-value="true">
                    Todos los clientes
                </b-radio>
                <b-radio
                    v-model="allClients"
                    :native-value="false">
                    Seleccionar clientes
                </b-radio>
            </b-field>
            <serbitec-payers-select
                class="serbitec-small-top-margin"
                v-if="!allClients && selectedReportType.id !== 5"
                :selectedPayer="$v.selectedPayer"
                @payer-selected="payerSelected"
            />
            <b-message
                v-if="selectedReportType && selectedReportType.id === 1 && largerThan2Months"
                class="serbitec-small-top-margin"
                size="is-small"
                type="is-warning"
                has-icon
            >
                Por el momento sólo se pueden realizar reportes de este tipo por un periodo de 2 meses
            </b-message>
            <b-message
                v-if="missingWarehouseType"
                class="serbitec-small-top-margin"
                size="is-small"
                type="is-warning"
                has-icon
            >
                Se require el área de preparación para generar el reporte.
            </b-message>
            <b-field class="serbitec-small-top-margin" grouped position="is-right">
                <p class="control">
                    <button
                        type="sumbit"
                        class="button is-primary"
                        :disabled="largerThan2Months || missingWarehouseType"
                    >
                        <span class="icon">
                            <font-awesome-icon icon="file-excel" />
                        </span>
                        <span>Generar reporte</span>
                    </button>
                </p>
            </b-field>
        </form>
    </div>
</template>

<script>
import serbitecReportTypeSelect from '../../../components/dashboard/reports/report-type-select.vue';
import serbitecReportOptions from '../../../components/dashboard/reports/report-options.vue';
import serbitecPayersSelect from '../../../components/dashboard/reports/payers-select.vue';
import serbitecDatePicker from '../../../components/dashboard/reports/date-picker.vue';
import { required } from 'vuelidate/lib/validators';
import format from 'date-fns/format';
import differenceInMonths from 'date-fns/differenceInMonths';
import FileSaver from 'file-saver';
import axios from 'axios';
import { mapState } from 'vuex';


export default {
    components: {
        serbitecPayersSelect,
        serbitecReportTypeSelect,
        serbitecDatePicker,
        serbitecReportOptions
    },
    data () {
        return {
            selectedReportType: {
                id: 0,
                options: []
            },
            selectedPayer: [],
            selectedDatesRange: [],
            allClients: true
        };
    },
    computed: {
        largerThan2Months () {
            if (this.selectedDatesRange.length && this.selectedReportType && this.selectedReportType.id === 1) {
                return differenceInMonths(this.selectedDatesRange[1], this.selectedDatesRange[0]) > 1;
            }
            return false;
        },
        missingWarehouseType () {
            if (this.selectedReportType.id === 3 && this.warehouseOptions.location === 'internal') {
                return this.warehouseOptions.type === '';
            }
            return false;
        },
        ...mapState({
            warehouseOptions: state => state.reports.warehouseOptions
        })
    },
    methods: {
        reportTypeSelected (reportType) {
            this.selectedReportType = reportType;
        },
        payerSelected (payer) {
            this.selectedPayer = payer;
        },
        datesRangeSelected (datesRange) {
            this.selectedDatesRange = datesRange;
        },
        submitGenerateReport () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.generateReport();
            }
        },
        generateReport () {
            const loader = this.$buefy.loading.open();
            const root = this.selectedReportType.url;
            let url = '';
            var payer = 'payer_ids=all';
            if (!this.allClients) {
                payer = 'payer_ids=' + this.getIds(this.selectedPayer);
            }
            if (this.selectedReportType.id !== 3 && this.selectedReportType.id !== 5) {
                const fromDeliveryDate = 'from_delivered_date=' + format(this.selectedDatesRange[0], 'yyyy-MM-dd');
                const toDeliveryDate = 'to_delivered_date=' + format(this.selectedDatesRange[1], 'yyyy-MM-dd');
                url = root + payer +
                    '&' + fromDeliveryDate + 
                    '&' + toDeliveryDate;
            }
            if (this.selectedReportType.id === 3) {
                const location = 'location=' + this.warehouseOptions.location;
                const type = 'type=' + this.warehouseOptions.type;
                url = root + 'doc_type=excel' + '&' + payer + '&' + location + '&' + type;
            }
            if (this.selectedReportType.id === 5) {
                url = root + 'doc_type=excel' ;
            }
            axios.get(url,  {
                responseType: 'blob'
            }).then((response) => {
                FileSaver.saveAs(response.data, this.selectedReportType.label + '.xlsx');
                loader.close();
            }).catch((error) => {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                loader.close();
            });
        },
        getIds (array) {
            return array.flatMap((item) => item.id).toString();
        }
    },
    validations () {
        var validations = {
            selectedReportType: {
                required
            }
        };
        if (this.selectedReportType.id !== 3 && this.selectedReportType.id !== 5) {
            validations.selectedDatesRange = {
                required
            };
        }
        if (!this.allClients) {
            validations.selectedPayer = {
                required
            };
        }
        return validations;
    }
};
</script>