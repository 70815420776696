<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <h1 class="title">Cuenta</h1>
            </div>
            <div class="level-right" />
        </nav>
        <hr>
        <serbitec-user-info></serbitec-user-info>
    </div>
</template>

<script>
import serbitecUserInfo from '../../../../components/dashboard/external-user/account/user-info.vue';

export default {
    components:{
        serbitecUserInfo
    }
};
</script>