<template>
    <div class="box">
        <b-table
            :data="mixtures"
            :loading="loading"

            paginated
            backend-pagination
            :total="mixturesCount"
            :per-page="pageSize"
            @page-change="changePage"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="sort"
            
            detailed
            detail-key="id"
            :show-detail-icon="hasPermissionToSeeDetails"
        >
            <template slot-scope="props">
                <b-table-column field="id" label="Orden" numeric sortable>
                    <p v-if="isNutritional">ON-{{ props.row.order.id }}</p>
                    <p v-else-if="isOncologic">OO-{{ props.row.id }}</p>
                </b-table-column>

                <b-table-column v-if="isNutritional" label="Lote" numeric>
                    <p>LN-{{ props.row.id }}</p>
                </b-table-column>

                <b-table-column label="Cliente">
                    <p v-if="isNutritional">{{ props.row.order.medic.payer.invoicing_data.business_name }}</p>
                    <p v-else-if="isOncologic">{{ props.row.medic.payer.invoicing_data.business_name }}</p>
                </b-table-column>

                <b-table-column label="Paciente">
                    <p v-if="isNutritional">{{ props.row.order.patient.name }}</p>
                    <p v-else-if="isOncologic">{{ props.row.patient.name }}</p>
                </b-table-column>

                <b-table-column field="delivery_date" label="Fecha de entrega" sortable centered>
                    <p v-if="isNutritional">{{ formatDate( props.row.delivery_date ) }}</p>
                    <p v-else-if="isOncologic">{{ formatDate( props.row.delivery_date ) }}</p>
                </b-table-column>

                <b-table-column label="Hora de entrega" centered>
                    <p v-if="isNutritional">{{ formatTime( props.row.delivery_date ) }}</p>
                    <p v-else-if="isOncologic">{{ formatTime( props.row.delivery_date ) }}</p>
                </b-table-column>

                <b-table-column label="Estado">
                    <b-tag v-if="isNutritional" :class="{ 'is-success': props.row.state.id >= 8, 'is-danger': props.row.state.id == 3 }">{{ props.row.state.name }}</b-tag>
                    <b-tag v-if="isOncologic" :class="{ 'is-success': orderIsReadyToBeSent( props.row ), 'is-danger': orderIsCanceled( props.row ) }">{{ setOrderState(props.row) }}</b-tag>
                </b-table-column>

                <b-table-column label="Detalles" centered>
                    <router-link
                        v-if="isNutritional"
                        :to="{ path: 'mixture_details', query: { mixture_id: props.row.id } }"
                        class="button is-light is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="info-circle" />
                        </span>
                    </router-link>
                    <router-link
                        v-else-if="isOncologic"
                        :to="{ path: 'order_details', query: { order_id: props.row.id } }"
                        class="button is-light is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="info-circle" />
                        </span>
                    </router-link>
                </b-table-column>
            </template>

            <template  slot="detail" slot-scope="props">
                <b-message v-if="( isNutritional && props.row.state.id == 3 ) || ( isOncologic && orderIsCanceled( props.row ) )" class="is-danger">
                    <small>Esta orden ha sido cancelada</small>
                </b-message>
                <article v-else-if="isOncologic && orderIsReadyToBeSent( props.row )" class="media">
                    <b-field grouped group-multiline>
                        <p class="control">
                            <a
                                class="button is-small"
                                @click="confirmTraceabilityWithOptions(props.row.id)"
                            >
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="map-marked-alt" />
                                </span>
                                <span>Trazabilidad</span>
                            </a>
                        </p>
                        <p class="control">
                            <a class="button is-small" :href="'/pdf/oncologic/orders/' + props.row.id + '/shipping_record'" target="_blank">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="truck" />
                                </span>
                                <span>Registro de envío</span>
                            </a>
                        </p>
                        <p class="control">
                            <a class="button is-small" :href="'/pdf/oncologic/orders/' + props.row.id + '/receipt'" target="_blank">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="receipt" />
                                </span>
                                <span>Recibo</span>
                            </a>
                        </p>
                        <p class="control">
                            <a
                            class="button is-small"
                            @click="confirmQuoteWithOptions(props.row.id)">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="file-invoice-dollar" />
                                </span>
                                <span>Cotización</span>
                            </a>
                        </p>
                        <p v-if="setOrderState( props.row ) != 'Entregada'" class="control">
                        <a
                            class="button is-small is-danger"
                            @click="confirmCancelMixture( props.row.id )">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="times-circle" />
                                </span>
                                <span>Cancelar</span>
                            </a>
                        </p>
                    </b-field>
                </article>
                <b-message v-else-if="isOncologic">
                    <small>Algún elemento de la orden no se encuentra en estado acondicinado, por lo tanto no se puede gerenar la papelería.</small>
                    <b-field class="serbitec-small-top-margin" grouped group-multiline>
                        <p class="control">
                            <a
                            class="button is-small"
                            @click="confirmQuoteWithOptions(props.row.id)">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="file-invoice-dollar" />
                                </span>
                                <span>Cotización</span>
                            </a>
                        </p>
                        <p v-if="setOrderState( props.row ) != 'Entregada'" class="control">
                            <a
                            class="button is-small is-danger"
                            @click="confirmCancelMixture( props.row.id )">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="times-circle" />
                                </span>
                                <span>Cancelar</span>
                            </a>
                        </p>
                    </b-field>
                </b-message>
                <article v-else-if="isNutritional && props.row.state.id >= 8" class="media">
                    <b-field grouped group-multiline>
                        <p class="control">
                            <a
                                class="button is-small"
                                @click="confirmTraceabilityWithOptions(props.row.id)"
                            >
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="map-marked-alt" />
                                </span>
                                <span>Trazabilidad</span>
                            </a>
                        </p>
                        <p class="control">
                            <a class="button is-small" :href="'/pdf/nutritional/mixtures/' + props.row.id + '/shipping_record'" target="_blank">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="truck" />
                                </span>
                                <span>Registro de envío</span>
                            </a>
                        </p>
                        <p class="control">
                            <a class="button is-small" :href="'/pdf/nutritional/mixtures/' + props.row.id + '/receipt'" target="_blank">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="receipt" />
                                </span>
                                <span>Recibo</span>
                            </a>
                        </p>
                        <p class="control">
                            <a
                            class="button is-small"
                            @click="confirmQuoteWithOptions(props.row.id)">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="file-invoice-dollar" />
                                </span>
                                <span>Cotización</span>
                            </a>
                        </p>
                        <p v-if="props.row.id >= 4" class="control">
                            <a class="button is-small" :href="'/pdf/nutritional/mixtures/' + props.row.id + '/preparer_order'" target="_blank">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="mortar-pestle" />
                                </span>
                                <span>Orden de preparación</span>
                            </a>
                        </p>
                        <p class="control">
                            <a
                            class="button is-small"
                            @click="editComments(props.row)">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="comment-alt" />
                                </span>
                                <span>Comentarios</span>
                            </a>
                        </p>
                        <p v-if="props.row.state.id != 10" class="control">
                            <a
                            class="button is-small is-danger"
                            @click="confirmCancelMixture( props.row.id )">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="times-circle" />
                                </span>
                                <span>Cancelar</span>
                            </a>
                        </p>
                    </b-field>
                </article>
                <b-message v-else-if="isNutritional">
                    <small>La mezcla nutricional no se encuentra en estado acondicinado, por lo tanto no se puede gerenar toda la papelería.</small>
                    <b-field class="serbitec-small-top-margin" grouped group-multiline>
                        <p class="control">
                            <a
                            class="button is-small"
                            @click="confirmQuoteWithOptions(props.row.id)">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="file-invoice-dollar" />
                                </span>
                                <span>Cotización</span>
                            </a>
                        </p>
                        <p v-if="props.row.state.id >= 4" class="control">
                            <a class="button is-small" :href="'/pdf/nutritional/mixtures/' + props.row.id + '/preparer_order'" target="_blank">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="mortar-pestle" />
                                </span>
                                <span>Orden de preparación</span>
                            </a>
                        </p>
                        <p v-if="props.row.state.id != 10" class="control">
                            <a
                            class="button is-small is-danger"
                            @click="confirmCancelMixture( props.row.id )">
                                <span class="icon is-medium">
                                    <font-awesome-icon icon="times-circle" />
                                </span>
                                <span>Cancelar</span>
                            </a>
                        </p>
                    </b-field>
                </b-message>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay registros realizados.</p>
                    </div>
                </section>
            </template>
        </b-table>
        <b-modal :active.sync="isQuoteModalActive"
                has-modal-card
                trap-focus
                aria-role="dialog"
                aria-modal>
                <serbitec-quote-with-options-modal
                    :quote-id="quoteId"
                    :type="tableType"
                    @close="isQuoteModalActive = false"
                ></serbitec-quote-with-options-modal>
        </b-modal>
    </div>
</template>

<script>
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { es } from 'date-fns/locale';
import serbitecQuoteWithOptionsModal from '../utils/modals/quote-with-options.vue';
import serbitecEditCommentsModal from '../utils/modals/edit-comments.vue';
import serbitecConfirmTraceabilityModal from '../utils/modals/traceability-with-options.vue';
import { mapState } from 'vuex';

export default {
    props: [
        'tableType',
        'mixtures',
        'loading',
        'mixturesCount',
        'page',
        'pageSize',
        'sortField',
        'sortOrder'
    ],
    components: {
        serbitecQuoteWithOptionsModal
    },
    data () {
        return {
            isQuoteModalActive: false,
            quoteId: null
        };
    },
    computed: {
        isOncologic () {
            return this.tableType == 'oncologic';
        },
        isNutritional () {
            return this.tableType == 'nutritional';
        },
        ...mapState({
            user: state => state.user
        }),
        hasPermissionToSeeDetails () {
            return this.user.types.find((type) => type.id === 6 || type.id === 7) === undefined;
        }
    },
    methods: {
        formatDate( date ) {
            return format( parseISO( date ), "d 'de' MMMM yyyy", { locale: es } );
        },
        formatTime( date ) {
            return format( parseISO( date ), "HH:mm " );
        },
        changePage ( page ) {
            this.$emit('page-change', page);
        },
        sort ( field, order ) {
            this.$emit('sort', field, order);
        },
        setOrderState ( order ) {
            if ( order.mixtures.length ) {
                var mixtureCanceled = order.mixtures.find( mixture => mixture.state.id == 3 );
                if ( mixtureCanceled ) {
                    return 'Cancelada';
                }
                var mixtureInVerification = order.mixtures.find( mixture => mixture.state.id == 1 );
                if ( mixtureInVerification ) {
                    return 'En verificación';
                }
                var mixtureInPreparation = order.mixtures.find( mixture => mixture.state.id == 2 || mixture.state.id == 4 || mixture.state.id == 5 || mixture.state.id ==6 || mixture.state.id == 9 );
                if ( mixtureInPreparation ) {
                    return 'En preparación';
                }
                var mixtureSent = order.mixtures.find( mixture => mixture.state.id == 8 );
                if ( mixtureSent ) {
                    return 'Enviada';
                }
                var mixtureDelivered = order.mixtures.find( mixture => mixture.state.id == 10 );
                if ( mixtureDelivered ) {
                    return 'Entregada';
                }
            } else if ( order.requested_flasks.length ) {
                var flaskCanceled = order.requested_flasks.find( flask => flask.state.id == 3 );
                if ( flaskCanceled ) {
                    return 'Cancelada';
                }
                var flaskInVerification = order.requested_flasks.find( flask => flask.state.id == 1 );
                if ( flaskInVerification ) {
                    return 'En verificación';
                }
                var flaskInPreparation = order.requested_flasks.find( flask => flask.state.id == 2 || flask.state.id == 4 || flask.state.id == 5 || flask.state.id == 6 || flask.state.id == 9 );
                if ( flaskInPreparation ) {
                    return 'En preparación';
                }
                var flaskSent = order.requested_flasks.find( flask => flask.state.id == 8 );
                if ( flaskSent ) {
                    return 'Enviada';
                }
                var flaskDelivered = order.requested_flasks.find( flask => flask.state.id == 10 );
                if ( flaskDelivered ) {
                    return 'Entregada';
                }
            }
        },
        orderIsReadyToBeSent ( order ) {
            var mixtureNotReadyToBeSent = null;
            var flaskNotReadyToBeSent = null;

            if ( order.mixtures.length ) {
                var mixtureNotReadyToBeSent = order.mixtures.find( mixture => mixture.state.id < 8 );
            }
            if ( order.requested_flasks.length ) {
                var flaskNotReadyToBeSent = order.requested_flasks.find( flask => flask.state.id < 8 );
            }
            return ( mixtureNotReadyToBeSent || flaskNotReadyToBeSent ) == null;
        },
        orderIsCanceled ( order ) {
            var mixtureIsCanceled = null;
            var flaskIsCanceled = null;

            if ( order.mixtures.length ) {
                mixtureIsCanceled = order.mixtures.find( mixture => mixture.state.id == 3 );
            }
            if ( order.requested_flasks.length ) {
                flaskIsCanceled = order.requested_flasks.find( flask => flask.state.id == 3 );
            }
            return ( mixtureIsCanceled || flaskIsCanceled ) != null;
        },
        confirmQuoteWithOptions ( itemId ) {
            this.quoteId = itemId;
            this.isQuoteModalActive = true;
        },
        updateVariableFromQuoteModal (event, variable) {
            if (variable === 'detailed') {
                this.quoteIsDetailed = event;
            }
            if (variable === 'includeTax') {
                this.quoteIncludeTax = event;
            }
            if (variable === 'chargeOnlyPreparationService') {
                this.chargeOnlyPreparationService = event;
            }
        },
        confirmCancelMixture ( itemId ) {
            var dialogMessage = null;
            if( this.isOncologic ) {
                dialogMessage = '¿Seguro que deseas eliminar toda la orden oncológica? Se cancelaran todas las mezclas y frascos de la orden y las cancelaciones no pueden revertirse';
            } else if ( this.isNutritional ) {
                dialogMessage = '¿Seguro que deseas cancelar la orden nutricional? Las cancelaciones no pueden revertirse';
            }
            this.$buefy.dialog.confirm({
                title: 'Cancelar mezcla',
                message: dialogMessage,
                confirmText: 'Cancelar',
                cancelText: 'Regresar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.$emit( 'item-canceled', itemId )
            });
        },
        editComments (mixture) {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecEditCommentsModal,
                hasModalCard: true,
                trapFocus: true,
                props: {
                    mixture: mixture,
                    type: this.tableType
                }
            });
        },
        confirmTraceabilityWithOptions (mixture) {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecConfirmTraceabilityModal,
                hasModalCard: true,
                trapFocus: true,
                props: {
                    traceabilityId: mixture,
                    type: this.tableType
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.serbitec-small-top-margin {
    margin-top: 15px
}
</style>