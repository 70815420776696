import Cookie from 'js-cookie';
import axios from 'axios';

const getDefaultState = () => { return {}; };

const state = getDefaultState();

const mutations = {
    SET_USER (state, data) {
        data.isExternalUser = data.types.find( type => type.is_internal === false ) !== undefined;
        data.isMedic = data.types.find( type => type.id == 3 ) != undefined;
        data.isMixtureRequester = data.types.find( type => type.id == 9 ) != undefined;
        Object.assign(state, data);
    },
    RESET_USER (state) {
        Cookie.remove('serbitec-session-api-token');
        Object.keys(state).forEach(function(key) { delete state[key]; });
    },
};

const actions = {
    async GET_USER ({ commit }, data) {
        try {
            const response = await axios.post('api/users/login', data);
            Cookie.set('serbitec-session-api-token', response.data.api_token, { sameSite: 'lax' });
            commit('SET_USER', response.data);
        } catch (error) {
            console.error(error);
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
  