<template>
    <div class="box">
        <div class="column is-flex is-horizontal-center">
            <figure class="image is-256x256">
                <img src="images/SERBITEC_login.png">
            </figure>
        </div>
        <p>
            Introduce tu correo electrónico, recibirás un correo electrónico con una liga para cambiar tu contraseña.
        </p>
        <form
            class="serbitec-small-top-margin"
            @submit.prevent="submitForm"
            novalidate="true"
        >
            <b-field
                label="Correo electrónico"
                :type="{'is-danger' : $v.email.$error }"
                :message="{
                    'Se requiere introducir el correo electrónico' : !$v.email.required && $v.email.$error,
                    'Se requiere introducir un correo electrónico válido' : !$v.email.email && $v.email.$error
                }"
                label-position="on-border"
                expanded
            >
                <b-input
                    v-model="$v.email.$model"
                    type="text">
                </b-input>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                grouped position="is-centered"
            >
                <p class="control">
                    <button type="submit" class="button is-primary">
                        <span>
                            Recuperar contraseña
                        </span>
                    </button>
                </p>
            </b-field>
        </form>
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import axios from 'axios';

export default {
    data () {
        return {
            email: ''
        };
    },
    methods: {
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.login();
            }
        },
        login() {
            const loader = this.$buefy.loading.open();
            const params = {
                email: this.email
            };
            axios.post('/api/password/send_reset_link', params).then(() => {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha mandado un correo electrónico con la liga'
                });
                this.$router.push({ path: '/' });
                loader.close();
            }).catch((error) => {
                console.error(error);
                this.$notify({
                    title: '¡Algo ha sucedido!',
                    type: 'error',
                    text: error.response.data.errors ? error.response.data.errors.email[0] : 'Algo ha sucedido, contacte al administrador'
                });
                loader.close();
            });
        }
    },
    validations: {
        email: {
            required,
            email
        }
    }
};
</script>