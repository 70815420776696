var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-card", staticStyle: { width: "auto" } },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "modal-card-body" },
        [
          _c(
            "b-message",
            {
              staticClass: "serbitec-small-top-margin",
              attrs: { type: "is-warning", "has-icon": "" }
            },
            [
              _vm._v(
                "\n            Los médicos que se dan de alta a través de esta forma no serán usuarios del sistema y por consecuente no tendrán acceso al mismo. El registrante del médico es responsable de su alta y confirma la verdacidad de los datos proporcionados incluyendo la cédula profesional.\n            "
              ),
              _c("br"),
              _vm._v(" "),
              _c("small", [
                _vm._v(
                  "Si se desea dar de alta a un médico usuario del sistema, favor de contactar al administrador."
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { grouped: "" } },
            [
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Nombre",
                    "label-position": "on-border",
                    type: { "is-danger": _vm.$v.name.$error },
                    message: {
                      "Se requiere introducir el nombre del médico":
                        !_vm.$v.name.required && _vm.$v.name.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.name.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.name, "$model", $$v)
                      },
                      expression: "$v.name.$model"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Apellido Paterno",
                    "label-position": "on-border",
                    type: { "is-danger": _vm.$v.lastName1.$error },
                    message: {
                      "Se requiere introducir el apellido paterno del médico":
                        !_vm.$v.lastName1.required && _vm.$v.lastName1.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.lastName1.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.lastName1, "$model", $$v)
                      },
                      expression: "$v.lastName1.$model"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-field",
                {
                  attrs: {
                    label: "Apellido Materno",
                    "label-position": "on-border",
                    type: { "is-danger": _vm.$v.lastName2.$error },
                    message: {
                      "Se requiere introducir el apellido materno del médico":
                        !_vm.$v.lastName2.required && _vm.$v.lastName2.$error
                    },
                    expanded: ""
                  }
                },
                [
                  _c("b-input", {
                    attrs: { type: "text" },
                    model: {
                      value: _vm.$v.lastName2.$model,
                      callback: function($$v) {
                        _vm.$set(_vm.$v.lastName2, "$model", $$v)
                      },
                      expression: "$v.lastName2.$model"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-field",
            {
              staticClass:
                "serbitec-small-top-margin serbitec-small-bottom-margin",
              attrs: {
                label: "Cédula profesional",
                "label-position": "on-border",
                type: { "is-danger": _vm.$v.medicalCard.$error },
                message: {
                  "Se requiere introducir la cédula profesional del médico":
                    !_vm.$v.medicalCard.required && _vm.$v.medicalCard.$error,
                  "La cédula profesional sólo puede contener números":
                    !_vm.$v.medicalCard.numeric && _vm.$v.medicalCard.$error,
                  "La cédula profesional debe tener 8 números. Si la cédula contiene menos de 8 caracteres complete con ceros a la izquierda.":
                    (!_vm.$v.medicalCard.minLength ||
                      !_vm.$v.medicalCard.maxLength) &&
                    _vm.$v.medicalCard.$error
                },
                expanded: ""
              }
            },
            [
              _c("b-input", {
                attrs: { type: "text" },
                model: {
                  value: _vm.$v.medicalCard.$model,
                  callback: function($$v) {
                    _vm.$set(_vm.$v.medicalCard, "$model", $$v)
                  },
                  expression: "$v.medicalCard.$model"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "modal-card-foot" }, [
        _c(
          "button",
          {
            staticClass: "button",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$parent.close()
              }
            }
          },
          [_vm._v("\n            Cancelar\n        ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "button is-primary", on: { click: _vm.submitForm } },
          [_vm._v("\n            Agregar\n        ")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title" }, [
        _vm._v("\n            Agregar médico\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }