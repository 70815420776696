var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c("b-table", {
        attrs: {
          data: _vm.components,
          paginated: "",
          "backend-pagination": "",
          total: _vm.componentsCount,
          "per-page": _vm.pageSize,
          "aria-next-label": "Siguiente página",
          "aria-previous-label": "Página anterior",
          "aria-page-label": "Página",
          "aria-current-label": "Página actual",
          "backend-sorting": "",
          "default-sort": [_vm.sortField, _vm.sortOrder],
          detailed: "",
          "detail-key": "id"
        },
        on: {
          "page-change": function(page) {
            return _vm.$emit("page-change", page)
          },
          sort: function(field, order) {
            return _vm.$emit("sort", field, order)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _c(
                  "b-table-column",
                  { attrs: { label: "Nombre del genérico" } },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.name) +
                        "\n                "
                    ),
                    props.row.is_nutritional
                      ? _c("b-tag", { attrs: { rounded: "" } }, [
                          _vm._v(
                            "\n                    Nutricional\n                "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    props.row.is_oncologic
                      ? _c("b-tag", { attrs: { rounded: "" } }, [
                          _vm._v(
                            "\n                    Oncológico\n                "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("b-table-column", { attrs: { label: "Tipo" } }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(props.row.component_type.name) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Editar", centered: "" } },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "button is-small",
                        attrs: { append: "" },
                        on: {
                          click: function($event) {
                            return _vm.editComponent(props.row)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon is-medium" },
                          [
                            _c("font-awesome-icon", { attrs: { icon: "edit" } })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "b-table-column",
                  { attrs: { label: "Eliminar", centered: "" } },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "button is-small is-danger",
                        attrs: { append: "" },
                        on: {
                          click: function($event) {
                            return _vm.openDeleteComponentDialog(props.row.id)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon is-medium" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "trash-alt" }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "detail",
            fn: function(props) {
              return [
                props.row.nutritional_field &&
                Object.keys(props.row.nutritional_field).length !== 0 &&
                props.row.nutritional_field.constructor === Object
                  ? _c("b-message", { attrs: { size: "is-small" } }, [
                      _c("strong", [
                        _vm._v("Información nutricional del componente")
                      ]),
                      _c("br"),
                      _vm._v(" "),
                      _c("div", { staticClass: "columns" }, [
                        _c("div", { staticClass: "column is-one-third" }, [
                          _vm._v("\n                        Aporte calórico: "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                props.row.nutritional_field.energy_input_in_kcal
                              ) + " Kcal"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n                        Aporte calórico no protéico: "
                          ),
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                props.row.nutritional_field
                                  .non_protein_energy_input_in_kcal
                              ) + " Kcal"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n                        Gramos de aminoácido: "
                          ),
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                props.row.nutritional_field.grams_of_aminoacid
                              ) + " g"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n                        Gramos de nitrógeno: "
                          ),
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                props.row.nutritional_field.grams_of_nitrogen
                              ) + " g"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(
                            "\n                        Concentración osmótica: "
                          ),
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                props.row.nutritional_field
                                  .osmotic_concentration_in_mosmol_l
                              ) + " mOsmol/L"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "column is-one-third" }, [
                          _vm._v("\n                        Densidad: "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                props.row.nutritional_field.density_in_g_per_ml
                              ) + " g/mL"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v("\n                        Sodio (Na): "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(props.row.nutritional_field.na_in_meq) +
                                " mEq"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v("\n                        Potasio (K): "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(props.row.nutritional_field.k_in_meq) +
                                " mEq"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v("\n                        Magnesio (Mg): "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(props.row.nutritional_field.mg_in_meq) +
                                " mEq"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "column is-one-third" }, [
                          _vm._v("\n                        Calcio (Ca): "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(props.row.nutritional_field.ca_in_meq) +
                                " mEq"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v("\n                        Acetato (Ace): "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(props.row.nutritional_field.ace_in_meq) +
                                " mEq"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v("\n                        Cloro (Cl): "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(props.row.nutritional_field.cl_in_meq) +
                                " mEq"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v("\n                        Fosfato (PO4): "),
                          _c("strong", [
                            _vm._v(
                              _vm._s(props.row.nutritional_field.po4_in_meq) +
                                " mEq"
                            )
                          ]),
                          _vm._v(" "),
                          _c("br")
                        ])
                      ])
                    ])
                  : _c("b-message", { attrs: { size: "is-small" } }, [
                      _vm._v(
                        "\n                El componente no contiene información nutricional\n            "
                      )
                    ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }