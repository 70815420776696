<template>
    <div>
        <div v-for="( element, index ) in elements" :key="index" class="box">
            <nav class="level serbitec-small-bottom">
                <div class="level-left">
                    <p class="has-text-weight-bold is-size-5">{{ element.componentName }}</p>
                </div>
                <div class="level-right">
                    <p class="is-size-4">{{ element.quantityInMedicineUnit }} ml</p>
                </div>
            </nav>
            <b-table
            :data="element.warehouseItems">
                <template slot-scope="props">
                    <b-table-column label="Lote">
                        <p><strong v-if="props.row.prefix">{{props.row.prefix.prefix}}-</strong> {{ props.row.batch }}</p>
                    </b-table-column>

                    <b-table-column label="Medicamento">
                        {{ props.row.medicineName }}
                    </b-table-column>

                    <b-table-column label="Presentación" centered>
                        <b-tag v-if="element.isPiece">{{ props.row.medicinePresentationQuantity }} {{ props.row.medicinePresentationQuantityUnit }}</b-tag>
                        <b-tag v-else>{{ props.row.medicinePresentationVolumeInMl }} ml</b-tag>
                    </b-table-column>

                    <b-table-column label="Cantidad" centered>
                        <p v-if="element.isPiece"><strong>{{ props.row.medicineQuantityInMl }} {{ props.row.medicinePresentationQuantityUnit }}</strong></p>
                        <p v-else><strong>{{ props.row.medicineQuantityInMl }} ml</strong></p>
                    </b-table-column>
                </template>
            </b-table>
        </div>
        <b-message v-if="hasInfusionSet" class="is-success">
            <nav class="level">
                <div class="level-left">
                    <h1>Set de infusión</h1>
                </div>
                <div class="level-right">
                    <span class="icon">
                        <font-awesome-icon icon="check-circle" />
                    </span>
                    <span> Solicitado</span>
                </div>
            </nav>
        </b-message>
        <b-field style="margin-top:25px" position="is-centered" grouped>
            <p class="control has-text-centered">
                <button @click.once="$emit('mixture-prepared')" class="button is-primary">
                    Mezcla preparada
                </button>
            </p>
        </b-field>
    </div>
</template>

<script>
export default {
    props: ['elements', 'hasInfusionSet']
};
</script>