<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Cambiar contraseña
            </p>
        </header>
        <section class="modal-card-body">
            <small>Para cambiar la contraseña introduzca la contraseña anterior, la nueva contraseña y su confirmación</small>
            <b-field
            label="Contraseña anterior"
            :type="{'is-danger' : $v.oldPassword.$error }"
            :message="{
                'Se requiere introducir la contraseña anterior' : !$v.oldPassword.required && $v.oldPassword.$error
            }"
            expanded>
                <b-input
                    v-model="$v.oldPassword.$model"
                    type="password"
                    password-reveal>
                </b-input>
            </b-field>
            <b-field
            label="Contraseña nueva"
            :type="{'is-danger' : $v.newPassword.$error }"
            :message="{
                'Se requiere introducir la contraseña anterior' : !$v.newPassword.required && $v.newPassword.$error
            }"
            expanded>
                <b-input
                    v-model="$v.newPassword.$model"
                    type="password"
                    password-reveal>
                </b-input>
            </b-field>
            <b-field
            label="Repita la nueva contraseña"
            :type="{'is-danger' : $v.newPasswordConfirmation.$error }"
            :message="{
                'Se requiere introducir la contraseña anterior' : !$v.newPasswordConfirmation.required && $v.newPasswordConfirmation.$error,
                'La contraseña repetida debe der igual a la nueva contraseña' : !$v.newPasswordConfirmation.sameAsNewPassword && $v.newPasswordConfirmation.$error
            }"
            expanded>
                <b-input
                    v-model="$v.newPasswordConfirmation.$model"
                    type="password"
                    password-reveal>
                </b-input>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                Cancelar
            </button>
            <button class="button is-primary" @click="submitForm">
                Cambiar contraseña
            </button>
        </footer>
    </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';

export default {
    props: {
        userId: {
            type: [Number, String],
            default: 0 | '0'
        }
    },
    data () {
        return {
            oldPassword: '',
            newPassword: '',
            newPasswordConfirmation: ''
        };
    },
    methods: {
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.editPassword(); 
            }
        },
        getParams () {
            const params = {
                old_password: this.oldPassword,
                new_password: this.newPassword,
                new_password_confirmation: this.newPasswordConfirmation,
            };
            
            return params;
        },
        editPassword () {
            var url = '/api/users/' + this.userId + '/password_reset';
            this.makeRequest(url, this.$axios.post, this.getParams());
        },
        makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method(url,params)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha modificado la contraseña'
                });
                this.$serbitec.finishLoading();
                this.$emit('close');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    },
    validations: {
        oldPassword: {
            required
        },
        newPassword: {
            required
        },
        newPasswordConfirmation: {
            required,
            sameAsNewPassword: sameAs('newPassword')
        }
    }
};
</script>