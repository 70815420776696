<template>
    <div class="box">
        <b-table
            :data="payerUsers"
            :loading="loading"

            paginated
            backend-pagination
            :total="payerUsersCount"
            :per-page="pageSize"
            @page-change="changePage"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="sort">

            <template slot-scope="props">
                <b-table-column field="first_name" label="Nombre" sortable>
                    {{ props.row.first_name }}
                </b-table-column>

                <b-table-column field="last_name_1" label="Apellido materno" sortable>
                    {{ props.row.last_name_1 }}
                </b-table-column>

                <b-table-column field="last_name_2" label="Apellido paterno" sortable>
                    {{ props.row.last_name_2 }}
                </b-table-column>

                <b-table-column label="Tipo de usuario" centered>
                    <b-tag v-for="type in props.row.types" :key="type.id">
                        {{ type.name }}
                    </b-tag>
                </b-table-column>

                <b-table-column label="Reestablecer" centered>
                    <a @click="restoreUser( props.row.id )" class="button is-light is-small">
                        <span class="icon is-medium">
                            <font-awesome-icon icon="user-check" />
                        </span>
                    </a>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay usuarios eliminados en este cliente.</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    props: [
        'payerUsers',
        'loading',
        'payerUsersCount',
        'page',
        'pageSize',
        'sortField',
        'sortOrder'
    ],
    methods: {
        changePage ( page ) {
            this.$emit('page-change', page);
        },
        sort ( field, order ) {
            this.$emit('sort', field, order);
        },
        restoreUser( userId ){
            var dialogMessage = '¿Seguro que deseas reestablecer al usuario? <br> <small>El usuario se reestablece y puede ingresar al sistema con su última contraseña.</small>';
            this.$buefy.dialog.confirm({
                title: 'Reestablecer usuario',
                message: dialogMessage,
                confirmText: 'Reestablecer',
                type: 'is-warning',
                hasIcon: true,
                onConfirm: () => this.$emit( 'restore-user', userId )
            });
        },
    }
};
</script>