var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav", { staticClass: "level" }, [
        _c(
          "div",
          { staticClass: "level-left" },
          [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" })
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("serbitec-payer-info", {
        attrs: { "payer-id": this.$route.query.payer_id }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("serbitec-client-external-warehouse")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }