var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav", { staticClass: "level" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "level-right" },
          [
            _c(
              "router-link",
              {
                staticClass: "button serbitec-small-right-margin",
                attrs: { to: { path: "deleted" }, append: "" }
              },
              [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [_c("font-awesome-icon", { attrs: { icon: "user-times" } })],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v(" Usuarios eliminados")])
              ]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "button",
                on: { click: _vm.openInternalUserModal }
              },
              [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [_c("font-awesome-icon", { attrs: { icon: "plus" } })],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v(" Agregar usuario interno")])
              ]
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("serbitec-internal-users-table", {
        attrs: {
          "internal-users": _vm.internalUsers,
          "internal-users-count": _vm.internalUsersCount,
          loading: _vm.loading,
          page: _vm.page,
          pageSize: _vm.limit,
          sortField: _vm.sortField,
          sortOrder: _vm.sortOrder
        },
        on: {
          "update:loading": function($event) {
            _vm.loading = $event
          },
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:sortField": function($event) {
            _vm.sortField = $event
          },
          "update:sort-field": function($event) {
            _vm.sortField = $event
          },
          "update:sortOrder": function($event) {
            _vm.sortOrder = $event
          },
          "update:sort-order": function($event) {
            _vm.sortOrder = $event
          },
          "page-change": _vm.onPageChange,
          sort: _vm.onSort,
          "reload-internal-users": function() {
            return _vm.$apollo.queries.internalUsers.refresh()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("Usuarios internos")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }