<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <h1 class="title">Fabricantes</h1>
            </div>
            <div class="level-right">
                <a
                    @click="addManufacturer"
                    class="button"
                    append
                >
                    <span class="icon">
                        <font-awesome-icon icon="plus" />
                    </span>
                    <span> Agregar fabricante</span>
                </a>
            </div>
        </nav>
        <hr>
        <serbitec-manufacturers-table
            :manufacturers="medicineManufacturers"
            :manufacturers-count="medicineManufacturersCount"

            :page.sync="page"
            :pageSize="pageSize"
            :sortField.sync="sortField"
            :sortOrder.sync="sortOrder"

            @page-change="onPageChange"
            @sort="onSort"
            @reload-manufacturers="$apollo.queries.medicineManufacturers.refresh()"
        />
    </div>
</template>

<script>
import serbitecManufacturersTable from '../../../components/dashboard/medicine-manufacturers/table.vue';
import serbitecMedicineManufacturersModal from '../../../components/dashboard/medicine-manufacturers/medicine-manufacturer-modal.vue';
import gql from 'graphql-tag';

const query = gql` query ( $search: String, $page: Int, $limit: Int, $order_by: String, $order_direction: String )
{
    medicineManufacturers: medicine_manufacturers( search: $search, page: $page, limit: $limit, order_by: $order_by, order_direction: $order_direction ) {
        total
        data {
            id
            name
        }
    }
}`;

export default {
    components: {
        serbitecManufacturersTable
    },
    data () {
        return {
            page: 1,
            pageSize: 10,
            sortField: 'id',
            sortOrder:'asc',
            medicineManufacturersCount: 0
        };
    },
    methods: {
        onPageChange( page ) {
            this.page = page;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
        addManufacturer () {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecMedicineManufacturersModal,
                hasModalCard: true,
                trapFocus: true,
                events: {
                    'success': () => this.$apollo.queries.medicineManufacturers.refresh()
                }
            });
        }
    },
    apollo: {
        medicineManufacturers: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    search: this.search,
                    page: this.page,
                    limit: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            update (data) {
                this.medicineManufacturersCount = data.medicineManufacturers.total;
                return data.medicineManufacturers.data;
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            }
        },
    }
};
</script>
