<template>
    <div class="box">
        <b-table
            :data="patients"
            :loading="loading"

            paginated
            backend-pagination
            :total="patientsCount"
            :per-page="pageSize"
            @page-change="changePage"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="sort"
            detailed
            detail-key="id"
        >

            <template slot-scope="props">
                <b-table-column label="Paciente">
                    {{ props.row.name }}
                </b-table-column>

                <b-table-column label="Diagnóstico">
                    {{ props.row.diagnosis }}
                </b-table-column>

                <b-table-column label="Editar" centered>
                    <a @click="editPatient(props.row)" class="button is-light is-small">
                        <span class="icon is-medium">
                            <font-awesome-icon icon="edit" />
                        </span>
                    </a>
                </b-table-column>

                <b-table-column label="Eliminar" centered>
                    <a class="button is-light is-small is-danger" @click="confirmDeletePatient(props.row.id)">
                        <span class="icon is-medium">
                            <font-awesome-icon icon="trash-alt" />
                        </span>
                    </a>
                </b-table-column>
            </template>
            <template
                slot="detail"
                slot-scope="props"
            >
                <b-message
                    size="is-small"
                >
                    <strong>Información adicional del paciente</strong><br>
                    <div class="columns">
                        <div class="column is-half">
                            Talla: <strong>{{ props.row.height_in_meters + ' m' }}</strong> <br>
                            Peso: <strong>{{ props.row.weight_in_kilograms + ' Kg' }}</strong> <br>
                            Edad: <strong>{{ computeAge(props.row.birth_date) }}</strong> <br>
                            Género: <strong>{{ props.row.gender }}</strong> <br>
                            Tipo: <strong>{{ props.row.age_type }}</strong> <br>
                        </div>
                        <div class="column is-half">
                            Superficie corporal: <strong>{{ props.row.body_surface_in_square_meters }}</strong> <br>
                            Expediente: <strong>{{ props.row.file_id }}</strong> <br>
                            Servicio: <strong>{{ props.row.location }}</strong> <br>
                            Cama: <strong>{{ props.row.bed }}</strong> <br>
                        </div>
                    </div>
                </b-message>
            </template>
            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay pacientes registrados.</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import serbitecPatientModal from './patient-modal.vue';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import { es } from 'date-fns/locale';

export default {
    props: {
        patients: {
            type: Array,
            default: () => []
        },
        patientsCount: {
            type: [Number, String],
            default: 0 | ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        page: {
            type: [Number, String],
            default: 0 | ''
        },
        pageSize: {
            type: [Number, String],
            default: 0 | ''
        },
        sortField: {
            type: String,
            default: ''
        },
        sortOrder: {
            type: String,
            default: ''
        },
    },
    methods: {
        formatDate( date ) {
            return format( parseISO(date), "d 'de' MMMM yyyy '|' HH:mm", { locale: es } );
        },
        changePage ( page ) {
            this.$emit('page-change', page);
        },
        sort ( field, order ) {
            this.$emit('sort', field, order);
        },
        computeAge: function (birth_date) {
            var birthDate = new Date(birth_date);
            var now = new Date();
            var age = formatDistanceStrict(now,birthDate,{locale: es});
            return age;
        },
        editPatient (patient) {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecPatientModal,
                hasModalCard: true,
                trapFocus: true,
                fullScreen: true,
                props: {
                    'patient': patient
                },
                events: {
                    'success': () => this.$emit('reload-patients')
                }
            });
        },
        confirmDeletePatient (patientId) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar paciente',
                message: '¿Seguro que deseas eliminar el paciente? <br><small>Se eliminará toda la información y mezclas relacionada con el paciente.</small>',
                confirmText: 'Eliminar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deletePatient(patientId)
            });
        },
        deletePatient (patientId) {
            this.$serbitec.startLoading();
            this.$axios.delete('/api/patients/' + patientId)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha eliminado el paciente'
                });
                this.$serbitec.finishLoading();
                this.$emit('reload-patients');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    }
};
</script>