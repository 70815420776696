<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                {{ comesFromEdit ? 'Editar componente' : 'Agregar componente' }}
            </p>
        </header>
        <section class="modal-card-body">
            <b-field
                label="Nombre del componente"
                label-position="on-border"
                :type="{'is-danger' : $v.componentName.$error }"
                :message="{
                    'Se requiere introducir el nombre del componente' : !$v.componentName.required && $v.componentName.$error
                }"
                expanded
            >
                <b-input
                    v-model.trim="$v.componentName.$model"
                    type="text">
                </b-input>
            </b-field>
            <b-field
                label="Tipo de mezcla en la que se usará el componente"
                :type="{'is-danger' : $v.componentType.$error }"
                :message="{
                    'Se requiere introducir el tipo de mezcla' : !$v.componentType.required && $v.componentType.$error
                }"
                expanded
            >
                <div>
                    <b-checkbox
                        v-model="componentType.isNutritional"
                        :disabled="comesFromEdit"
                    >
                        Nutricional
                    </b-checkbox>
                    <b-checkbox
                        v-model="componentType.isOncologic"
                        :disabled="comesFromEdit"
                    >
                        Oncológico
                    </b-checkbox>
                </div>
            </b-field>
            <b-field
                v-if="componentType.isOncologic && !componentType.isNutritional"
                label="Tipo de componente"
                expanded
            >
                <div class="block">
                    <b-radio
                        v-model="oncologicComponent.componentTypeId"
                        name="componentTypeId"
                        :native-value="1"
                        :disabled="comesFromEdit"
                    >
                        Medicamento oncológico
                    </b-radio>
                    <b-radio
                        v-model="oncologicComponent.componentTypeId"
                        name="componentTypeId"
                        :native-value="13"
                        :disabled="comesFromEdit"
                    >
                        Diluyente
                    </b-radio>
                </div>
            </b-field>
            <div v-if="componentType.isNutritional">
            <hr>
            <h2 class="subtitle is-6">Información del aporte nutricional del componente</h2>
            <b-field
                label="Tipo de componente nutricional"
                label-position="on-border"
                :type="{'is-danger' : $v.nutritionalComponent.componentType.$error }"
                :message="{
                    'Se requiere introducir el tipo de componente nutricional' : !$v.nutritionalComponent.componentType.required && $v.nutritionalComponent.componentType.$error
                }"
                expanded
            >
                <serbitec-component-type-select
                    :component-type-category-id="2"
                    :selected-component="$v.nutritionalComponent.$model.componentType"
                    @update:component-type-selected="(option) => $v.nutritionalComponent.$model.componentType = option"
                    :disabled="comesFromEdit"
                />
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                grouped
                group-multiline
            >
                <b-field
                    label="Aporte calórico"
                    label-position="on-border"
                    :type="{'is-danger' : $v.nutritionalComponent.energyInputInKcal.$error }"
                    :message="{
                        'Se requiere introducir el aporte calórico en Kcal' : !$v.nutritionalComponent.energyInputInKcal.required && $v.nutritionalComponent.energyInputInKcal.$error
                    }"
                    expanded
                >
                    <b-field
                    :type="{'is-danger' : $v.nutritionalComponent.energyInputInKcal.$error }">
                        <b-input
                            v-model.trim="$v.nutritionalComponent.energyInputInKcal.$model"
                            type="text"
                            expanded
                        >
                        </b-input>
                        <p class="control">
                            <span class="button is-static">Kcal</span>
                        </p>
                    </b-field>
                </b-field>
                <b-field
                    label="Aporte calórico no protéico"
                    label-position="on-border"
                    :type="{'is-danger' : $v.nutritionalComponent.nonProteicEnergyInputInKcal.$error }"
                    :message="{
                        'Se requiere introducir el aporte calórico no protéico en Kcal' : !$v.nutritionalComponent.nonProteicEnergyInputInKcal.required && $v.nutritionalComponent.nonProteicEnergyInputInKcal.$error
                    }"
                    expanded
                >
                    <b-field
                    :type="{'is-danger' : $v.nutritionalComponent.nonProteicEnergyInputInKcal.$error }">
                        <b-input
                            v-model.trim="$v.nutritionalComponent.nonProteicEnergyInputInKcal.$model"
                            type="text"
                            expanded
                        >
                        </b-input>
                        <p class="control">
                            <span class="button is-static">Kcal</span>
                        </p>
                    </b-field>
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                grouped
                group-multiline
            >
                <b-field
                    label="Gramos de aminoácidos"
                    label-position="on-border"
                    :type="{'is-danger' : $v.nutritionalComponent.gramsOfAminoacid.$error }"
                    :message="{
                        'Se requiere introducir los gramos de aminoácidos' : !$v.nutritionalComponent.gramsOfAminoacid.required && $v.nutritionalComponent.gramsOfAminoacid.$error,
                        'Se requiere introducir un número decimal' : !$v.nutritionalComponent.gramsOfAminoacid.decimal && $v.nutritionalComponent.gramsOfAminoacid.$error
                    }"
                    expanded
                >
                    <b-field
                        :type="{'is-danger' : $v.nutritionalComponent.gramsOfAminoacid.$error }"
                    >
                        <b-input
                            v-model.trim="$v.nutritionalComponent.gramsOfAminoacid.$model"
                            type="text"
                            expanded
                        >
                        </b-input>
                        <p class="control">
                            <span class="button is-static">g</span>
                        </p>
                    </b-field>
                </b-field>
                <b-field
                    label="Gramos de nitrógeno"
                    label-position="on-border"
                    :type="{'is-danger' : $v.nutritionalComponent.nitrogenGrams.$error }"
                    :message="{
                        'Se requiere introducir los gramos de nitrógeno' : !$v.nutritionalComponent.nitrogenGrams.required && $v.nutritionalComponent.nitrogenGrams.$error,
                        'Los gramos de nitrógeno deben ser un número decimal' : !$v.nutritionalComponent.nitrogenGrams.decimal && $v.nutritionalComponent.nitrogenGrams.$error
                    }"
                    expanded
                >
                    <b-field
                        :type="{'is-danger' : $v.nutritionalComponent.nitrogenGrams.$error }"
                    >
                        <b-input
                            v-model.trim="$v.nutritionalComponent.nitrogenGrams.$model"
                            type="text"
                            expanded
                        >
                        </b-input>
                        <p class="control">
                            <span class="button is-static">g</span>
                        </p>
                    </b-field>
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                grouped
                group-multiline
            >
                <b-field
                    label="Concentración osmótica"
                    label-position="on-border"
                    :type="{'is-danger' : $v.nutritionalComponent.osmoticConcentration.$error }"
                    :message="{
                        'Se requiere introducir la concentración osmótica' : !$v.nutritionalComponent.osmoticConcentration.required && $v.nutritionalComponent.osmoticConcentration.$error,
                        'La concentración osmótica debe ser un número' : !$v.nutritionalComponent.osmoticConcentration.decimal && $v.nutritionalComponent.osmoticConcentration.$error
                    }"
                    expanded
                >
                    <b-field
                        :type="{'is-danger' : $v.nutritionalComponent.osmoticConcentration.$error }"
                    >
                        <b-input
                            v-model.trim="$v.nutritionalComponent.osmoticConcentration.$model"
                            type="text"
                            expanded
                        >
                        </b-input>
                        <p class="control">
                            <span class="button is-static">mOsmol/L</span>
                        </p>
                    </b-field>
                </b-field>
                <b-field
                    label="Densidad"
                    label-position="on-border"
                    :type="{'is-danger' : $v.nutritionalComponent.densityQuantity.$error || $v.nutritionalComponent.densityUnit.$error }"
                    :message="{
                        'Se requiere introducir la densidad' : !$v.nutritionalComponent.densityQuantity.required && $v.nutritionalComponent.densityQuantity.$error,
                        'La densidad tiene que ser un número' : !$v.nutritionalComponent.densityQuantity.decimal && $v.nutritionalComponent.densityQuantity.$error,
                        'Se requiere introducir la unidad de la densidad' : !$v.nutritionalComponent.densityUnit.required && $v.nutritionalComponent.densityUnit.$error
                    }"
                    expanded
                >
                    <b-field
                        :type="{'is-danger' : $v.nutritionalComponent.densityQuantity.$error || $v.nutritionalComponent.densityUnit.$error }"
                    >
                        <b-input
                            v-model.trim="$v.nutritionalComponent.densityQuantity.$model"
                            type="text"
                            expanded
                        >
                        </b-input>
                        <b-select
                            v-model="nutritionalComponent.densityUnit"
                        >
                            <option v-bind:value="{ symbol: 'g/ml' }">g/ml</option>
                            <option v-bind:value="{ symbol: 'g/l' }">g/l</option>
                        </b-select>
                    </b-field>
                </b-field>
            </b-field>
            <h2 class="subtitle is-6"><strong>Aporte de electrolitos</strong></h2>
            <b-field v-for="electrolyte in nutritionalComponent.electrolytes" :key="electrolyte.id" grouped group-multiline style="margin-bottom:25px">
                <b-checkbox v-model="electrolyte.showInput" @change.native="electrolyte.quantityInMeq = null" style="margin-right:25px">
                    {{ electrolyte.name }}
                </b-checkbox>
                <b-field v-if="electrolyte.showInput">
                    <b-input
                        v-model="electrolyte.quantityInMeq"
                        type="text"
                        expanded
                    >
                    </b-input>
                    <p class="control">
                        <span class="button is-static">mEq</span>
                    </p>
                </b-field>
            </b-field>
        </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                Cancelar
            </button>
            <button class="button is-primary" @click="submitForm">
                {{ comesFromEdit ? 'Editar' : 'Agregar' }}
            </button>
        </footer>
    </div>
</template>

<script>
import serbitecComponentTypeSelect from '../../../components/dashboard/utils/select/components-type.vue';
import { required, decimal } from 'vuelidate/lib/validators';

export default {
    props: {
        component: {
            type: Object,
            default: () => {}
        }
    },
    components: {
        serbitecComponentTypeSelect
    },
    beforeMount () {
        if (this.component && Object.keys(this.component).length !== 0 && this.component.constructor === Object) {
            this.comesFromEdit = true;
            this.componentName = this.component.name;
            if ( this.component.is_oncologic) {
                this.componentType.isOncologic = true;
                this.oncologicComponent.componentTypeId = this.component.component_type.id;
            }
            if ( this.component.is_nutritional) {
                this.componentType.isNutritional = true;
                this.nutritionalComponent.componentType = this.component.component_type;
                this.nutritionalComponent.energyInputInKcal = this.component.nutritional_field.energy_input_in_kcal;
                this.nutritionalComponent.nonProteicEnergyInputInKcal = this.component.nutritional_field.non_protein_energy_input_in_kcal;
                this.nutritionalComponent.gramsOfAminoacid = this.component.nutritional_field.grams_of_aminoacid;
                this.nutritionalComponent.nitrogenGrams = this.component.nutritional_field.grams_of_nitrogen;
                this.nutritionalComponent.osmoticConcentration = this.component.nutritional_field.osmotic_concentration_in_mosmol_l;
                this.nutritionalComponent.densityQuantity = this.component.nutritional_field.density_in_g_per_ml;
                this.nutritionalComponent.densityUnit = {
                    symbol: "g/ml"
                };
                if ( this.component.nutritional_field.na_in_meq !== "0" ) {
                    this.nutritionalComponent.electrolytes.map( electrolyte => {
                        if (electrolyte.name == 'Sodio (Na)') {
                            electrolyte.showInput = true;
                            electrolyte.quantityInMeq = this.component.nutritional_field.na_in_meq;
                        }
                    });
                }
                if ( this.component.nutritional_field.k_in_meq !== "0" ) {
                    this.nutritionalComponent.electrolytes.map( electrolyte => {
                        if (electrolyte.name == 'Potasio (K)') {
                            electrolyte.showInput = true;
                            electrolyte.quantityInMeq = this.component.nutritional_field.k_in_meq;
                        }
                    });
                }
                if ( this.component.nutritional_field.mg_in_meq !== "0" ) {
                    this.nutritionalComponent.electrolytes.map( electrolyte => {
                        if (electrolyte.name == 'Magnesio (Mg)') {
                            electrolyte.showInput = true;
                            electrolyte.quantityInMeq = this.component.nutritional_field.mg_in_meq;
                        }
                    });
                }
                if ( this.component.nutritional_field.ca_in_meq !== "0" ) {
                    this.nutritionalComponent.electrolytes.map( electrolyte => {
                        if (electrolyte.name == 'Calcio (Ca)') {
                            electrolyte.showInput = true;
                            electrolyte.quantityInMeq = this.component.nutritional_field.ca_in_meq;
                        }
                    });
                }
                if ( this.component.nutritional_field.ace_in_meq !== "0" ) {
                    this.nutritionalComponent.electrolytes.map( electrolyte => {
                        if (electrolyte.name == 'Acetato (Ace)') {
                            electrolyte.showInput = true;
                            electrolyte.quantityInMeq = this.component.nutritional_field.ace_in_meq;
                        }
                    });
                }
                if ( this.component.nutritional_field.cl_in_meq !== "0" ) {
                    this.nutritionalComponent.electrolytes.map( electrolyte => {
                        if (electrolyte.name == 'Cloro (Cl)') {
                            electrolyte.showInput = true;
                            electrolyte.quantityInMeq = this.component.nutritional_field.cl_in_meq;
                        }
                    });
                }
                if ( this.component.nutritional_field.po4_in_meq !== "0" ) {
                    this.nutritionalComponent.electrolytes.map( electrolyte => {
                        if (electrolyte.name == 'Fosfato (PO4)') {
                            electrolyte.showInput = true;
                            electrolyte.quantityInMeq = this.component.nutritional_field.po4_in_meq;
                        }
                    });
                }
            }
        }
    },
    data () {
        return {
            componentName: '',
            componentType: {
                isNutritional: false,
                isOncologic: false
            },
            oncologicComponent: {
                componentTypeId: 1
            },
            nutritionalComponent: {
                componentType: {},
                energyInputInKcal: '',
                nonProteicEnergyInputInKcal: '',
                gramsOfAminoacid: '',
                nitrogenGrams: '',
                osmoticConcentration: '',
                densityQuantity: '',
                densityUnit: '',
                electrolytes: [
                    {
                        name: 'Sodio (Na)',
                        showInput: false,
                        quantityInMeq: '',
                    },
                    {
                        name: 'Potasio (K)',
                        showInput: false,
                        quantityInMeq: '',
                    },
                    {
                        name: 'Magnesio (Mg)',
                        showInput: false,
                        quantityInMeq: '',
                    },
                    {
                        name: 'Calcio (Ca)',
                        showInput: false,
                        quantityInMeq: '',
                    },
                    {
                        name: 'Acetato (Ace)',
                        showInput: false,
                        quantityInMeq: '',
                    },
                    {
                        name: 'Cloro (Cl)',
                        showInput: false,
                        quantityInMeq: '',
                    },
                    {
                        name: 'Fosfato (PO4)',
                        showInput: false,
                        quantityInMeq: '',
                    }
                ]
            },
            comesFromEdit: false
        };
    },
    methods: {
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                if (this.component && Object.keys(this.component).length !== 0 && this.component.constructor === Object) {
                    this.editComponent(this.component);
                } else {
                    this.addComponent();
                }   
            }
        },
        getRequestParams () {
            var requesParams = {};
            if ( this.componentType.isOncologic && !this.componentType.isNutritional ) {
                requesParams = {
                    name: this.componentName,
                    is_oncologic: this.componentType.isOncologic,
                    is_nutritional: this.componentType.isNutritional,
                    component_type_id: this.oncologicComponent.componentTypeId,
                };
            } else {
                var densityInGperMl;
                if( this.nutritionalComponent.densityUnit.symbol == 'g/l' ) {
                    densityInGperMl = ( this.nutritionalComponent.densityQuantity*1000 );
                } else{
                    densityInGperMl = this.nutritionalComponent.densityQuantity;
                }
                const naInMeq = this.nutritionalComponent.electrolytes.find( electrolyte => electrolyte.name == "Sodio (Na)" ).quantityInMeq;
                const kInMeq = this.nutritionalComponent.electrolytes.find( electrolyte => electrolyte.name == "Potasio (K)" ).quantityInMeq;
                const mgInMeq = this.nutritionalComponent.electrolytes.find( electrolyte => electrolyte.name == "Magnesio (Mg)" ).quantityInMeq;
                const caInMeq = this.nutritionalComponent.electrolytes.find( electrolyte => electrolyte.name == "Calcio (Ca)" ).quantityInMeq;
                const aceInMeq = this.nutritionalComponent.electrolytes.find( electrolyte => electrolyte.name == "Acetato (Ace)" ).quantityInMeq;
                const clInMeq = this.nutritionalComponent.electrolytes.find( electrolyte => electrolyte.name == "Cloro (Cl)" ).quantityInMeq;
                const po4InMeq = this.nutritionalComponent.electrolytes.find( electrolyte => electrolyte.name == "Fosfato (PO4)" ).quantityInMeq;

                requesParams = {
                    name: this.componentName,
                    is_oncologic: this.componentType.isOncologic,
                    is_nutritional: this.componentType.isNutritional,
                    component_type_id: this.nutritionalComponent.componentType.id,
                    energy_input_in_kcal: this.nutritionalComponent.energyInputInKcal,
                    non_protein_energy_input_in_kcal: this.nutritionalComponent.nonProteicEnergyInputInKcal,
                    grams_of_aminoacid: this.nutritionalComponent.gramsOfAminoacid,
                    grams_of_nitrogen: this.nutritionalComponent.nitrogenGrams,
                    osmotic_concentration_in_mosmol_l: this.nutritionalComponent.osmoticConcentration,
                    density_in_g_per_ml: densityInGperMl,
                    na_in_meq: naInMeq,
                    k_in_meq: kInMeq,
                    mg_in_meq: mgInMeq,
                    ca_in_meq: caInMeq,
                    ace_in_meq: aceInMeq,
                    cl_in_meq: clInMeq,
                    po4_in_meq: po4InMeq,
                };
            }
            return requesParams;
        },
        addComponent () {
            var url = '/api/medicine_components';
            this.makeRequest(url, this.$axios.post, this.getRequestParams());
        },
        editComponent (component) {
            var url = '/api/medicine_components/' + component.id;
            this.makeRequest(url, this.$axios.put, this.getRequestParams());
        },
        makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method(url,params)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha añadido o editado el componente'
                });
                this.$serbitec.finishLoading();
                this.$emit('success');
                this.$emit('close');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    },
    validations() {
        if ( this.componentType.isNutritional ) {
            return {
                componentName: {
                    required
                },
                componentType: {
                    required
                },
                nutritionalComponent: {
                    componentType: {
                        required
                    },
                    energyInputInKcal: {
                        required
                    },
                    nonProteicEnergyInputInKcal: {
                        required
                    },
                    gramsOfAminoacid: {
                        required,
                        decimal
                    },
                    nitrogenGrams: {
                        required,
                        decimal
                    },
                    osmoticConcentration: {
                        required,
                        decimal
                    },
                    densityQuantity: {
                        required,
                        decimal
                    },
                    densityUnit: {
                        required
                    }
                }
            };
        } else {
            return {
                componentName: {
                    required
                },
                componentType: {
                    atLeastOneTrue (value) {
                        return value.isNutritional || value.isOncologic;
                    }
                }
            };
        }
    }
};
</script>