<template>
    <div>
        <b-table
            :data="payerMedicines"
            :loading="loading"

            paginated
            backend-pagination
            :total="payerMedicinesCount"
            :per-page="pageSize"
            @page-change="changePage"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual">

            <template slot-scope="props">
                <b-table-column label="Activo" centered>
                    <div class="field">
                        <b-switch
                        v-model="props.row.enabled"
                        @input="toggleEnabled( props.row.id )"></b-switch>
                    </div>
                </b-table-column>

                <b-table-column label="Medicamento">
                    {{ props.row.medicine.commercial_name }}
                </b-table-column>

                <b-table-column label="Marca">
                    {{ props.row.medicine.manufacturer.name }}
                </b-table-column>

                <b-table-column label="Tipo">
                    <b-tag v-if="props.row.medicine.component.is_oncologic">Oncológico</b-tag>
                    <b-tag v-if="props.row.medicine.component.is_nutritional">Nutricional</b-tag>
                </b-table-column>

                <b-table-column label="Frasco completo" centered>
                    <div class="field">
                        <b-checkbox
                        v-model="props.row.is_charged_by_full_medicine_presentation"
                        @input="togglePricePerFlask( props.row.id )"
                        :disabled="props.row.medicine.component_unit.symbol == 'pz'"></b-checkbox>
                    </div>
                </b-table-column>

                <b-table-column label="Precio" centered>
                    <b-tag
                    v-if="
                    ( props.row.payer.invoicing_data.price_in_cents_per_oncologic_mixture > 0 &&
                      props.row.medicine.component.is_oncologic && !props.row.medicine.component.is_nutritional ) ||
                    ( props.row.payer.invoicing_data.price_in_cents_per_nutritional_mixture > 0 && 
                      props.row.medicine.component.is_nutritional && !props.row.medicine.component.is_oncologic ) ||
                    ( props.row.payer.invoicing_data.price_in_cents_per_oncologic_mixture > 0 &&
                      props.row.payer.invoicing_data.price_in_cents_per_nutritional_mixture > 0 && 
                      props.row.medicine.component.is_oncologic && 
                      props.row.medicine.component.is_nutritional 
                    )
                    ">
                        Precio por mezcla
                    </b-tag>
                    <b-tag v-else-if="props.row.payer_medicine_presentations.length > 0">
                        Precio por presentación
                    </b-tag>
                    <b-field
                    v-else
                    position="is-centered">
                        <p class="control">
                            <button class="button is-static">$</button>
                        </p>
                        <b-input
                            :value="props.row.price_in_cents_per_ml_or_pz / 100"
                            v-cleave="masks.numeral"
                            type="text"
                            @keyup.native.enter="onInputPricePerMl( props.row.id )">
                        </b-input>
                        <p class="control">
                            <button v-if="props.row.medicine.component_unit.symbol == 'pz'" class="button is-static">MXN por pieza</button>
                            <button v-else class="button is-static">MXN por ml</button>
                        </p>
                    </b-field>
                </b-table-column>

                <b-table-column label="Presentaciones" centered>
                    <router-link
                    :to="{ path:'presentations', query: { payer_id: props.row.payer.id, payer_medicine_id: props.row.id } }"
                    class="button is-light is-small"
                    :class="{ 'is-primary': props.row.payer_medicine_presentations.length > 0 }"
                    append
                >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="pills" />
                        </span>
                    </router-link>
                </b-table-column>

                <b-table-column label="Eliminar" centered>
                    <a class="button is-light is-small is-danger" @click="confirmDeleteMedicine( props.row.id )">
                        <span class="icon is-medium">
                            <font-awesome-icon icon="trash-alt" />
                        </span>
                    </a>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay medicamentos registrados.</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import Cleave from 'cleave.js';

const cleave = {
    name: 'cleave',
    bind(el, binding) {
        const input = el.querySelector('input');
        input._vCleave = new Cleave(input, binding.value);
    },
    unbind(el) {
        const input = el.querySelector('input');
        input._vCleave.destroy();
    }
};

export default {
    directives: { cleave },
    props: [
        'payerMedicines',
        'loading',
        'payerMedicinesCount',
        'page',
        'pageSize',
    ],
    data(){
        return {
            masks: {
                numeral: {
                    numeral: true
                }
            }
        };
    },
    methods: {
        onInputPricePerMl( payerMedicineId ){
            let rawPricePerMl = event.target._vCleave.getRawValue();
            this.$emit('edit-price', payerMedicineId , rawPricePerMl );
        },
        changePage ( page ) {
            this.$emit('page-change', page);
        },
        confirmDeleteMedicine ( itemId ) {
            var dialogMessage = '¿Seguro que deseas eliminar el medicamento? <br> <small>Los medicamentos eliminados no se mostrarán al cliente</small>';
            this.$buefy.dialog.confirm({
                title: 'Eliminar medicamento',
                message: dialogMessage,
                confirmText: 'Eliminar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.$emit( 'delete-item', itemId )
            });
        },
        toggleEnabled( payerMedicineId ){
            this.$emit('toggle-payer-medicine-enabled-state', payerMedicineId);
        },
        togglePricePerFlask( payerMedicineId ){
            this.$emit('toggle-payer-medicine-price-per-flask-state', payerMedicineId);
        }
    }
};
</script>