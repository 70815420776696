var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.warehouseItems,
            paginated: "",
            "backend-pagination": "",
            total: _vm.warehouseItemsCount,
            "per-page": _vm.pageSize,
            "aria-next-label": "Siguiente página",
            "aria-previous-label": "Página anterior",
            "aria-page-label": "Página",
            "aria-current-label": "Página actual",
            "backend-sorting": "",
            "default-sort": [_vm.sortField, _vm.sortOrder]
          },
          on: {
            "page-change": function(page) {
              return _vm.$emit("page-change", page)
            },
            sort: function(field, order) {
              return _vm.$emit("sort", field, order)
            }
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "batch",
                        label: "Lote",
                        numeric: "",
                        sortable: ""
                      }
                    },
                    [
                      _c("p", [
                        props.row.prefix
                          ? _c("strong", [
                              _vm._v(_vm._s(props.row.prefix.prefix) + "-")
                            ])
                          : _vm._e(),
                        _vm._v(_vm._s(props.row.batch))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Nombre comercial" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            props.row.medicine_presentation.medicine
                              .commercial_name
                          ) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Fabricante" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          props.row.medicine_presentation.medicine.manufacturer
                            .name
                        ) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Presentación", centered: "" } },
                    [
                      _vm.isEvaBag(props.row)
                        ? _c("b-tag", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  props.row.medicine_presentation
                                    .component_quantity
                                ) +
                                " " +
                                _vm._s(
                                  props.row.medicine_presentation.medicine
                                    .component_unit.symbol
                                ) +
                                " de " +
                                _vm._s(
                                  props.row.medicine_presentation.volume_in_ml
                                ) +
                                " ml\n                "
                            )
                          ])
                        : _vm.isPieceItem(props.row)
                        ? _c("b-tag", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  props.row.medicine_presentation
                                    .component_quantity
                                ) +
                                " " +
                                _vm._s(
                                  props.row.medicine_presentation.medicine
                                    .component_unit.symbol
                                ) +
                                "\n                "
                            )
                          ])
                        : _vm.isOncologicItem(props.row)
                        ? _c("b-tag", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  props.row.medicine_presentation
                                    .component_quantity
                                ) +
                                " " +
                                _vm._s(
                                  props.row.medicine_presentation.medicine
                                    .component_unit.symbol
                                ) +
                                " en " +
                                _vm._s(
                                  props.row.medicine_presentation.volume_in_ml
                                ) +
                                " ml\n                "
                            )
                          ])
                        : _c("b-tag", [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  props.row.medicine_presentation.volume_in_ml
                                ) +
                                " ml\n                "
                            )
                          ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        label: "Existencias en almacen [frascos]",
                        centered: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.flasks_remaining_outside) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Fecha de caducidad", centered: "" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.expiry_date) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Ordenes de compra", centered: "" } },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "button is-small",
                          attrs: {
                            to: {
                              path: "purchase_orders",
                              query: { batchId: props.row.id }
                            },
                            append: ""
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "file-invoice-dollar" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("No hay lotes registrados")])
                ]
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }