<template>
    <div>
        <form
        @submit.prevent="submitPatientForm"
        novalidate="true">
            <b-field grouped group-multiline>
                <b-field
                    :type="{'is-danger' : $v.name.$error }"
                    :message="{
                        'Se requiere introducir el nombre completo del paciente' : !$v.name.required && $v.name.$error
                    }"
                    expanded
                >
                    <b-field
                        label="Nombre completo del paciente"
                        label-position="on-border"
                        :type="{'is-danger' : $v.name.$error }"
                    >
                        <b-input
                            v-model.trim="$v.name.$model"
                            type="text"
                            :disabled="comesFromAutocomplete"
                            expanded
                        />
                        <p class="control">
                            <button type="button" @click="$emit('patient-unselected')" class="button">
                                <span>Cambiar</span>
                                <span class="icon is-small">
                                    <font-awesome-icon icon="exchange-alt" />
                                </span>
                            </button>
                        </p>
                    </b-field>
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                label="Diagnóstico del paciente"
                label-position="on-border"
                :type="{'is-danger' : $v.diagnosis.$error }"
                :message="{
                    'Se requiere introducir el diagnóstico del paciente' : !$v.diagnosis.required && $v.diagnosis.$error
                }"
                expanded>
                <b-input
                    v-model.trim="$v.diagnosis.$model"
                    type="text">
                </b-input>
            </b-field>
            <b-field
                grouped
            >
                <b-field
                    class="serbitec-small-top-margin"
                    :label="'Talla'"
                    label-position="on-border"
                    expanded
                >
                    <b-field>
                        <b-input
                        v-model="heightInMeters"
                        type="text">
                        </b-input>
                        <p class="control">
                            <span class="button is-static">m</span>
                        </p>
                    </b-field>
                </b-field>
                <b-field
                    class="serbitec-small-top-margin"
                    :label="'Peso'"
                    label-position="on-border"
                    :type="{'is-danger' : $v.weightInKg.$error }"
                    :message="{
                        'Se requiere introducir el peso del paciente' : !$v.weightInKg.required && $v.weightInKg.$error
                    }"
                    expanded
                >
                    <b-field :type="{'is-danger' : $v.weightInKg.$error }">
                        <b-input
                        v-model.trim="$v.weightInKg.$model"
                        type="text">
                        </b-input>
                        <p class="control">
                            <span class="button is-static">Kg</span>
                        </p>
                    </b-field>
                </b-field>
            </b-field>
            <b-field
                grouped
            >
                <b-field
                class="serbitec-small-top-margin"
                v-if="isOncologic"
                :label="'Superficie corporal'"
                label-position="on-border"
                :type="{'is-danger' : $v.bodySurface.$error }"
                :message="[
                    !$v.bodySurface.required && $v.bodySurface.$error ? 'Se requiere introducir la superficie corporal' : 'Para calcular de click en el botón Calcular, de lo contrario introduzca un valor'
                ]"
                expanded
                >
                    <b-field :type="{'is-danger' : $v.bodySurface.$error }">
                        <b-input
                        v-model.trim="$v.bodySurface.$model"
                        type="text">
                        </b-input>
                        <p class="control">
                            <span class="button is-static">Kg/m<sup>2</sup></span>
                        </p>
                        <p class="control">
                            <span class="button" @click="bodySurface = computedBodySurface">
                                <span class="icon is-small">
                                    <font-awesome-icon icon="calculator" />
                                </span>
                                <span>Calcular</span>
                            </span>
                        </p>
                    </b-field>
                </b-field>
                <b-field
                    class="serbitec-small-top-margin"
                    :label="'Días de Tratamiento'"
                    label-position="on-border"
                    expanded
                >
                    <b-field>
                        <b-input
                        v-model="treatmentDays"
                        type="text">
                        </b-input>
                        <p class="control">
                            <span class="button is-static">Días</span>
                        </p>
                    </b-field>
                </b-field>
            </b-field>
            <b-field
            grouped
            group-multiline>
                <b-field
                :label="'Género'"
                :type="{'is-danger' : $v.gender.$error }"
                :message="{
                    'Se requiere introducir el género del paciente' : !$v.gender.required && $v.gender.$error
                }"
                expanded>
                    <b-field>
                        <b-radio-button
                        v-model="gender"
                        native-value="Masculino"
                        type="is-primary"
                        :disabled="comesFromAutocomplete && gender != 'Masculino'">
                            <font-awesome-icon icon="male" /> 
                            <span style="padding-left:10px">Masculino</span>
                        </b-radio-button>

                        <b-radio-button
                        v-model="gender"
                        native-value="Femenino"
                        type="is-primary"
                        :disabled="comesFromAutocomplete && gender != 'Femenino'">
                            <font-awesome-icon icon="female" />
                            <span style="padding-left:10px">Femenino</span>
                        </b-radio-button>
                    </b-field>
                </b-field>
                <b-field
                :label="'Tipo de paciente'"
                :type="{'is-danger' : $v.ageType.$error }"
                :message="{
                    'Se requiere introducir el tipo del paciente' : !$v.ageType.required && $v.ageType.$error
                }"
                expanded>
                    <b-field>
                        <b-radio-button
                        v-model="ageType"
                        native-value="Adulto"
                        type="is-primary">
                            <span>Adulto</span>
                        </b-radio-button>

                        <b-radio-button
                        v-model="ageType"
                        native-value="Pediátrico"
                        type="is-primary">
                            <span>Pediátrico</span>
                        </b-radio-button>

                        <b-radio-button
                        v-model="ageType"
                        native-value="Neonato"
                        type="is-primary">
                            <span>Neonato</span>
                        </b-radio-button>
                    </b-field>
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                :label="'Fecha de nacimiento del paciente'"
                label-position="on-border"
                :type="{'is-danger' : $v.birthDate.$error }"
                :message="{
                    'Se requiere introducir la fecha de nacimiento del paciente' : !$v.birthDate.required && $v.birthDate.$error
                }"
            >
                <b-datepicker
                    v-model="birthDate"
                    :date-formatter="d => d.toLocaleDateString('es-US',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })"
                    :class="{ danger : $v.birthDate.$error }"
                    placeholder="Seleccione una fecha"
                    :disabled="comesFromAutocomplete">
                </b-datepicker>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                :label="'Número de expediente'"
                label-position="on-border"
                expanded
            >
                <b-field>
                    <b-input
                    v-model="fileID"
                    type="text"
                    :disabled="comesFromAutocomplete">
                    </b-input>
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                :label="'Servicio'"
                label-position="on-border"
                expanded
            >
                <b-field>
                    <b-input
                    v-model="location"
                    type="text">
                    </b-input>
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                :label="'Cama'"
                label-position="on-border"
                expanded
            >
                <b-field>
                    <b-input
                    v-model="bed"
                    type="text">
                    </b-input>
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                :label="'Ubicación'"
                label-position="on-border"
                expanded
            >
                <b-field>
                    <b-input
                    v-model="section"
                    type="text">
                    </b-input>
                </b-field>
            </b-field>

            <hr>
            <b-field grouped position="is-right">
                <p class="control">
                    <button type="submit" class="button">
                        <span>
                            Siguiente
                        </span>
                        <span class="icon">
                            <font-awesome-icon icon="caret-right" />
                        </span>
                    </button>
                </p>
            </b-field>
        </form> 
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { parse } from 'date-fns';

function initialDataState() {
    return {
        id: null,
        name: null,
        diagnosis: null,
        heightInMeters: null,
        weightInKg: null,
        bodySurface: null,
        treatmentDays: null,
        gender: null,
        ageType: null,
        birthDate: null,
        fileID: null,
        location: null,
        bed: null,
        section: null,

        comesFromAutocomplete: false
    };
}

export default {
    props: {
        orderType: {
            type: String,
            default: ''
        },
        patient: {
            type: Object,
            default: () => {}
        }
    },
    computed: {
        computedBodySurface() {
            if ( this.weightInKg && this.heightInMeters ) {
                const heightInCentimeters = this.heightInMeters * 100;
                const bodySurface = Math.sqrt( ( heightInCentimeters * this.weightInKg ) / 3600 ).toFixed(2);
                return bodySurface;
            } else {
                return 0;
            }
        },
        isOncologic () {
            return this.orderType == 'oncologic';
        },
        isNutritional () {
            return this.orderType == 'nutritional';
        }
    },
    beforeMount: function () {
        if ( Object.keys(this.patient).length !== 0 && this.patient.constructor === Object ) {
            this.setDataFromVuex();
            this.comesFromAutocomplete = true;
        } else {
            this.comesFromAutocomplete = false;
        }
        this.$watch('weightInKg', function ( ) {
            this.bodySurface = null; 
        });
        this.$watch('heightInMeters', function ( ) {
            this.bodySurface = null; 
        });
    },
    data: initialDataState,
    methods: {
        submitPatientForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.submitPatientToVuex();
            }
        },
        setDataFromVuex () {
            this.id = this.patient.id;
            this.name = this.patient.name;
            this.diagnosis = this.patient.diagnosis;
            this.heightInMeters = this.patient.height_in_meters;
            this.weightInKg = this.patient.weight_in_kilograms;
            if ( this.orderType == 'oncologic' ) {
                this.bodySurface = this.patient.body_surface_in_square_meters;
            }
            this.treatmentDays = this.patient.treatment_days;
            this.gender = this.patient.gender;
            this.ageType = this.patient.age_type;
            this.birthDate = parse(this.patient.birth_date, 'yyyy-MM-dd', new Date());
            this.fileID = this.patient.file_id;
            this.location = this.patient.location;
            this.bed = this.patient.bed;
            this.section = this.patient.section;
        },
        setPatientData () {
            const patient = {
                id: this.id,
                name: this.name,
                diagnosis: this.diagnosis,
                height_in_meters: this.heightInMeters,
                weight_in_kilograms: this.weightInKg,
                treatment_days: this.treatmentDays,
                gender: this.gender,
                age_type: this.ageType,
                birth_date: this.birthDate,
                file_id: this.fileID,
                location: this.location,
                bed: this.bed,
                section: this.section,
            };
            if ( this.orderType == 'oncologic' ) {
                patient.body_surface_in_square_meters = this.bodySurface;
            } 
            console.log(this.treatmentDays);
            return patient;
        },

        submitPatientToVuex () {
            if (this.isNutritional) {
                this.$store.commit('nutritionalOrder/UPDATE_PATIENT', this.setPatientData());
            }
            if (this.isOncologic) {
                this.$store.commit('oncologicOrder/UPDATE_PATIENT', this.setPatientData());
            }
            this.$emit('patient-added');
        },
    },
    validations () {
        const validations = {
            name: {
                required
            },
            diagnosis: {
                required
            },
            weightInKg: {
                required
            },
            gender: {
                required
            },
            ageType: {
                required
            },
            birthDate: {
                required
            },
        };
        if ( this.isOncologic ) {
            validations.bodySurface = {
                required
            };
        }
        return validations;
    },
};
</script>