var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "level" }, [
      _c(
        "div",
        { staticClass: "level-left" },
        [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" })
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _vm.elements
      ? _c(
          "div",
          [
            _c("div", { staticClass: "box" }, [
              _c("h1", { staticClass: "title has-text-centered" }, [
                _vm._v(
                  "Orden: ON-" +
                    _vm._s(_vm.orderId) +
                    " | Lote: LN-" +
                    _vm._s(_vm.mixtureId)
                )
              ])
            ]),
            _vm._v(" "),
            _c("serbitec-horizontal-order-data", {
              attrs: { medic: _vm.medic, patient: _vm.patient }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column is-half is-size-4" }, [
                _vm._v("\n                Volumen total: "),
                _c("strong", [_vm._v(_vm._s(_vm.totalVolumeInMl) + " ml")])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "column is-half is-size-4" }, [
                _vm._v("\n                Sobrellenado: "),
                _c("strong", [_vm._v(_vm._s(_vm.overfillInMl) + " ml")])
              ])
            ]),
            _vm._v(" "),
            _c("serbitec-nutritional-preparer-order", {
              attrs: {
                elements: _vm.sortedElements(),
                "has-infusion-set": _vm.hasInfusionSet
              },
              on: { "mixture-prepared": _vm.submitMixturePrepared }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }