var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "b-tabs",
        {
          attrs: { type: "is-toggle", expanded: "" },
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        [
          _c(
            "b-tab-item",
            { attrs: { label: "Oncológico" } },
            [
              _c("br"),
              _vm._v(" "),
              _c("serbitec-oncologic-requested-items-check-table", {
                attrs: { "comes-from-external-warehouse": true }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab-item",
            { attrs: { label: "Nutricional" } },
            [
              _c("br"),
              _vm._v(" "),
              _c("serbitec-nutritional-requested-items-check-table", {
                attrs: { "comes-from-external-warehouse": true }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { staticClass: "level" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("h1", { staticClass: "title" }, [_vm._v("Pedidos")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }