// Load font awesome dependencies
import { library } from '@fortawesome/fontawesome-svg-core';
import { faExclamationCircle, faSignInAlt, faEye, faAngleRight, faAngleLeft, faArrowUp, faCaretLeft, faFileInvoiceDollar, faPlus, faMinus, faTimesCircle, faExclamationTriangle, faClipboardCheck, faEdit, faCheckCircle, faTrashAlt, faInfoCircle, faMapMarkedAlt, faTruck, faReceipt, faMortarPestle, faCommentAlt, faPrint, faSignOutAlt, faChevronUp, faChevronDown, faBars, faFileExport, faShippingFast, faFilePdf, faCog, faTablets, faUsers, faSearch, faPills, faExchangeAlt, faKey, faUserTimes, faUserCheck, faTint, faPrescriptionBottle, faClipboardList, faBoxes, faEllipsisV, faCheck, faCapsules, faQrcode, faMotorcycle, faCalculator, faWeight, faCaretRight, faUser, faMale, faFemale, faUndoAlt, faHome, faFileExcel, faHourglassEnd, faAngleDoubleRight, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add( faExclamationCircle, faSignInAlt, faEye, faAngleRight, faAngleLeft, faArrowUp, faCaretLeft, faFileInvoiceDollar, faPlus, faMinus, faTimesCircle, faExclamationTriangle, faClipboardCheck, faEdit, faCheckCircle, faTrashAlt, faInfoCircle, faMapMarkedAlt, faTruck, faReceipt, faMortarPestle, faCommentAlt, faPrint, faSignOutAlt, faChevronUp, faChevronDown, faBars, faFileExport, faShippingFast, faFilePdf, faCog, faTablets, faUsers, faSearch, faPills, faExchangeAlt, faKey, faUserTimes, faUserCheck, faTint, faPrescriptionBottle, faClipboardList, faBoxes, faEllipsisV, faCheck, faCapsules, faQrcode, faMotorcycle, faCalculator, faWeight, faCaretRight, faUser, faMale, faFemale, faUndoAlt, faHome, faFileExcel, faHourglassEnd, faAngleDoubleRight, faQuestionCircle );

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import axios from 'axios';
Vue.prototype.$axios = axios;

import ApolloClient from 'apollo-boost';
const apolloClient = new ApolloClient({
    uri: process.env.MIX_APOLLO_URL
});

import VueApollo from 'vue-apollo';
const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
        // apollo options applied to all queries in components
        $query: {
            fetchPolicy: 'cache-and-network',
        },
    }
});
Vue.use(VueApollo);

import VueMq from 'vue-mq';

Vue.use(VueMq, {
    breakpoints: {
        mobile: 768,
        touch: 1023,
        desktop: Infinity
    }
});

import store from './store';
Vue.prototype.$store = store;

import Buefy from 'buefy';
Vue.use(Buefy, {
    defaultIconComponent: 'font-awesome-icon',
    defaultIconPack: 'fas',
    defaultDayNames: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
    defaultMonthNames: [
        'Enero', 
        'Febrero', 
        'Marzo', 
        'Abril', 
        'Mayo', 
        'Junio', 
        'Julio', 
        'Agosto', 
        'Septiembre', 
        'Octubre', 
        'Noviembre', 
        'Diciembre'
    ],
});

import Notifications from 'vue-notification';
import velocity      from 'velocity-animate';

Vue.use(Notifications, { velocity });

Vue.prototype.$animation = {
    enter: {
        translateX: [0,300],
        opacity: [1, 0],
        scale: [1, 0.2]
    },
    leave: {
        opacity: 0,
    } 
};

import Vue from 'vue';
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

// Vue router
import VueRouter from 'vue-router';
import { routes } from './routes.js';
const router = new VueRouter({
    routes
});
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}
router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const context = {
            from,
            next,
            router,
            to,
            store
        };
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({ ...context, next: nextMiddleware });
    }
    return next();
});
Vue.use(VueRouter);

import IdleVue from "idle-vue";

const eventsHub = new Vue();

Vue.use(IdleVue, {
    eventEmitter: eventsHub,
    store,
    keepTracking: false,
    idleTime: 3600000
});

import serbitecLandingLayout from './layouts/components/landing-layout.vue';
import serbitecDashboardLayout from './layouts/components/dashboard-layout.vue';
import { mapState } from 'vuex';

new Vue({
    el: '#app',
    created () {
        Vue.prototype.$serbitec = this;
    },
    computed: {
        ...mapState({
            user: state => state.user
        })
    },
    data () {
        return {
            isLoading: false
        };
    },
    methods: {
        startLoading() {
            this.isLoading = true;
        },
        finishLoading() {
            this.isLoading = false;
        }
    },
    apolloProvider,
    router,
    components: {
        serbitecLandingLayout,
        serbitecDashboardLayout
    },
    onIdle() {
        this.$buefy.dialog.alert({
            message: '¿Sigues ahí? <br><small>La aplicación ha estado inactiva mucho tiempo, necesitamos confirmar que continuas utilizandola.</small>',
            hasIcon: true,
            icon: 'hourglass-end',
            onConfirm: () => location.reload()
        });
    }
});

