var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      Object.keys(_vm.payer).length === 0 && _vm.payer.constructor === Object
        ? _c(
            "b-field",
            {
              attrs: {
                label: "Nombre del cliente",
                "label-position": "on-border",
                message: "Introduzca el nombre del cliente y seleccione"
              }
            },
            [
              _c("serbitec-payer-autocomplete", {
                on: { "payer-added": _vm.addPayer }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      Object.keys(_vm.payer).length !== 0 && _vm.payer.constructor === Object
        ? _c(
            "div",
            [
              _c("serbitec-payer-data", {
                attrs: {
                  isFixed: _vm.user.isExternalUser && _vm.user.isMedic,
                  payer: _vm.payer
                },
                on: { "payer-unselected": _vm.unselectPayer }
              }),
              _vm._v(" "),
              Object.keys(_vm.medic).length === 0 &&
              _vm.medic.constructor === Object
                ? _c(
                    "b-field",
                    {
                      staticClass: "serbitec-small-top-margin",
                      attrs: { grouped: "", "group-multiline": "" }
                    },
                    [
                      _c(
                        "b-field",
                        {
                          attrs: {
                            message:
                              "Introduzca el nombre del usuario y seleccione",
                            expanded: ""
                          }
                        },
                        [
                          _c(
                            "b-field",
                            {
                              attrs: {
                                label: "Nombre del usuario",
                                "label-position": "on-border"
                              }
                            },
                            [
                              _c("serbitec-medic-autocomplete", {
                                attrs: { "payer-id": _vm.payer.id },
                                on: { "medic-added": _vm.addMedic }
                              }),
                              _vm._v(" "),
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button",
                                    attrs: { type: "button" },
                                    on: { click: _vm.addNewMedic }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                                Agregar nuevo médico\n                            "
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      Object.keys(_vm.medic).length !== 0 && _vm.medic.constructor === Object
        ? _c(
            "div",
            [
              _c("serbitec-medic-data", {
                staticClass: "serbitec-small-top-margin",
                attrs: {
                  isFixed: _vm.user.isExternalUser && _vm.user.isMedic,
                  medic: _vm.medic
                },
                on: { "medic-unselected": _vm.unselectMedic }
              }),
              _vm._v(" "),
              !_vm.showPatientForm &&
              Object.keys(_vm.patient).length === 0 &&
              _vm.patient.constructor === Object
                ? _c(
                    "b-field",
                    {
                      staticClass: "serbitec-small-top-margin",
                      attrs: {
                        label: "Nombre del paciente",
                        "label-position": "on-border",
                        grouped: "",
                        "group-multiline": ""
                      }
                    },
                    [
                      _c("serbitec-patient-autocomplete", {
                        attrs: {
                          "payer-id": _vm.payer.id,
                          disabled: _vm.showPatientForm
                        },
                        on: { "patient-added": _vm.addPatient }
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "control" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                _vm.showPatientForm = true
                              }
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                "\n                        Agregar nuevo paciente\n                    "
                              )
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showPatientForm ||
              (Object.keys(_vm.patient).length !== 0 &&
                _vm.patient.constructor === Object)
                ? _c("serbitec-patient-form", {
                    attrs: {
                      patient: _vm.patient,
                      "order-type": _vm.orderType
                    },
                    on: {
                      "patient-added": function($event) {
                        return _vm.$emit("next-step")
                      },
                      "patient-unselected": _vm.unselectPatient
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }