<template>
    <div>
        <b-loading :active.sync="loading"></b-loading>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right" />
        </nav>
        <hr>
        <serbitec-payer-form
        v-if="payer"
        :payer="payer"></serbitec-payer-form>
    </div>
</template>

<script>
import serbitecPayerForm from '../../../components/dashboard/payers/payer-form.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';

import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    payer( id: $id ) {
        id
        first_name
        last_name_1
        last_name_2
        email
        type {
            id
        }
        invoicing_data {
            id
            business_name
            rfc
            address
            phone_number
            email
            price_in_cents_per_nutritional_mixture
            price_in_cents_per_oncologic_mixture
        }
    }
}`;

export default {
    components: {
        serbitecPayerForm,
        serbitecBreadcumbs
    },
    data() {
        return {
            payerId: this.$route.query.payer_id,
            loading: false,
            route: [
                {
                    label: 'Clientes',
                    to: '/payers',
                    active: false
                },
                {
                    label: 'Editar',
                    to: '/payers/edit',
                    active: true
                }
            ]
        };
    },
    apollo: {
        payer: {
            fetchPolicy: 'network-only',
            query: query,
            variables () {
                return {
                    id: this.payerId
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                return data.payer;
            }
        },
    }
};
</script>