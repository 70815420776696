<template>
    <div>
        <serbitec-horizontal-general-data
            v-if="Object.keys(patient).length !== 0 && patient.constructor === Object"
            :payer="payer"
            :medic="medic"
            :patient="patient"
        />
        <div v-if="!( emptyMacronutrients && electrolytes.length == 0 && aditives.length == 0 )">
            <nav class="level">
                <div class="level-left"></div>
                <div class="level-right">
                    <p class="control">
                        <button class="button" @click="loadPreviousMixture" :disabled="isNewPatient">
                            <span class="icon">
                                <font-awesome-icon icon="undo-alt" />
                            </span>
                            <span>Cargar mezcla anterior</span>
                        </button>
                    </p>
                </div>
            </nav>
            <serbitec-water-form
                :computed-total-volume="computedTotalVolume"
            />
            <serbitec-water-alerts
                :computed-total-volume="computedTotalVolume"
            />
            <br>
            <div>
                <b-message
                v-if="emptyMacronutrients"
                type="is-warning"
                has-icon>
                    <strong class="is-size-4">Macronutrientes</strong>
                    <br>
                    No tiene ningún macronutriente dado de alta.
                    <br>
                    <small>Por favor, contacte al administrador para que realice los ajustes pertinentes.</small>
                </b-message>
                <serbitec-macronutrients-form v-else></serbitec-macronutrients-form>
            </div>
            <div class="serbitec-small-top-margin">
                <div v-if="electrolytes && electrolytes.length">
                    <serbitec-electrolytes-form></serbitec-electrolytes-form>
                    <serbitec-electrolytes-alerts
                    :electrolytes="electrolytes"
                    :computed-total-volume="computedTotalVolume"></serbitec-electrolytes-alerts>
                </div>
                <b-message
                v-else
                type="is-warning"
                has-icon>
                    <strong class="is-size-4">Electrolitos</strong>
                    <br>
                    No tiene ningún electrolito dado de alta.
                    <br>
                    <small>Por favor, contacte al administrador para que realice los ajustes pertinentes.</small>
                </b-message>
            </div>
            <div class="serbitec-small-top-margin">
                <serbitec-aditives-form v-if="aditives && aditives.length"></serbitec-aditives-form>
                <b-message
                v-else
                type="is-warning"
                has-icon>
                    <strong class="is-size-4">Aditivos</strong>
                    <br>
                    No tiene ningún aditivo dado de alta.
                    <br>
                    <small>Por favor, contacte al administrador para que realice los ajustes pertinentes.</small>
                </b-message>
            </div>
            <serbitec-additional-information
                :computed-total-volume="computedTotalVolume"
            />
            <br>
            <hr>
        </div>
        <div class="serbitec-medium-bottom-margin" v-else>
            <b-message
            type="is-danger"
            has-icon>
                <strong class="is-size-4">Contacte al administrador</strong>
                <br>
                No tiene ningún componente nutricional dado de alta.
                <br>
                <small>Por favor, contacte al administrador para que realice los ajustes pertinentes.</small>
            </b-message>
        </div>

        <nav class="level">
            <div class="level-left">
                <p class="control">
                    <button class="button" @click="$emit('previous-step')">
                        <span class="icon">
                        <font-awesome-icon icon="caret-left" />
                        </span>
                        <span>Anterior </span>
                    </button>
                </p>
            </div>
            <div class="level-right">
                <p class="control">
                    <button
                    class="button" @click="$emit('next-step')"
                    :disabled="dangerAlert">
                        <span class="icon">
                        <font-awesome-icon icon="caret-right" />
                        </span>
                        <span> Siguiente</span>
                    </button>
                </p>
            </div>
        </nav>
    </div>
</template>

<script>
import serbitecHorizontalGeneralData from '../../horizontal-general-data.vue';
import serbitecWaterForm from '../water-form.vue';
import serbitecWaterAlerts from '../water-alerts.vue';
import serbitecMacronutrientsForm from '../macronutrients-form.vue';
import serbitecAdditionalInformation from '../additional-information';
import serbitecElectrolytesForm from '../electrolytes-form.vue';
import serbitecElectrolytesAlerts from '../electrolytes-alerts.vue';
import serbitecAditivesForm from '../aditives-form.vue';
import { mapState } from 'vuex';
import gql from "graphql-tag";
import {
	setRandomId,
	totalMlForMacrocomponent,
	electrolyteInMl,
	aditiveInMl
} from "../../../../../utils";

const query = gql`
	query components( $component_type_id: Int, $enabled_for_payer_id: Int ) {
		components(component_type_id: $component_type_id, enabled_for_payer_id: $enabled_for_payer_id) {
			id
			name
			component_type {
				id
			}
			nutritional_field {
                id
				ml_to_g_factor
				energy_input_in_kcal
				non_protein_energy_input_in_kcal
				grams_of_nitrogen
				grams_of_aminoacid
				osmotic_concentration_in_mosmol_l
				na_in_meq
				k_in_meq
				mg_in_meq
				ca_in_meq
				po4_in_meq
				ace_in_meq
				cl_in_meq
			}
		}
	}
`;

const lastMixtureQuery = gql` query ( $patient_id: Int! )
{
	nutritional_mixtures ( patient_id: $patient_id, order_by: "id", order_direction: "desc", page: 0, results_per_page: 1 ) {
        id
		total_volume_in_ml
        overfill_in_ml
		infusion_time_in_h
		elements {
            id
			medicine_quantity
			multiply_factor_to_selected_unit
			selected_unit_code
			component {
				id
				name
				component_type {
					id
				}
				nutritional_field {
                    id
					ml_to_g_factor
					energy_input_in_kcal
					non_protein_energy_input_in_kcal
					grams_of_nitrogen
					grams_of_aminoacid
					osmotic_concentration_in_mosmol_l
					na_in_meq
					k_in_meq
					mg_in_meq
					ca_in_meq
					po4_in_meq
					ace_in_meq
					cl_in_meq
				}
			}
		}
	}
}`;

export default {
    components: {
        serbitecHorizontalGeneralData,
        serbitecWaterForm,
        serbitecWaterAlerts,
        serbitecMacronutrientsForm,
        serbitecAdditionalInformation,
        serbitecElectrolytesForm,
        serbitecElectrolytesAlerts,
        serbitecAditivesForm
    },
    computed: {
        ...mapState({
            payer: state => state.nutritionalOrder.payer,
            medic: state => state.nutritionalOrder.medic,
            patient: state => state.nutritionalOrder.patient,
            infusionTime: state => state.nutritionalOrder.infusionTime,
			macronutrients: state => state.nutritionalOrder.macronutrients,
			electrolytes: state => state.nutritionalOrder.electrolytes,
			aditives: state => state.nutritionalOrder.aditives,
			totalVolume: state => state.nutritionalOrder.totalVolume,
			overfilling: state => state.nutritionalOrder.overfilling,
			injectableWater: state => state.nutritionalOrder.injectableWater,
			dangerAlert: state => state.nutritionalOrder.dangerAlert,
        }),
        emptyMacronutrients(){
			return this.macronutrients.filter( macronutrient => macronutrient.componentsAvailable.length > 0 ).length == 0;
        },
        isNewPatient() {
			if (this.patient.id) {
				return false;
			} else {
				return true;
			}
        },
        computedTotalVolume() {
			var volumeAccumulator = 0.0;
			this.macronutrients
				.filter(macronutrient => macronutrient.quantity)
				.map(usedMacronutrient => {
					volumeAccumulator =
						volumeAccumulator +
						parseFloat(
							totalMlForMacrocomponent(
								usedMacronutrient,
								this.patient.weight_in_kilograms
							)
						);
				});

			this.electrolytes
				.filter(electrolyte => electrolyte.quantity)
				.map(usedElectrolyte => {
					volumeAccumulator =
						volumeAccumulator +
						parseFloat(
							electrolyteInMl(
								usedElectrolyte,
								this.patient.weight_in_kilograms
							)
						);
				});

			this.aditives
				.filter(aditive => aditive.quantity)
				.map(usedAditive => {
					volumeAccumulator =
						volumeAccumulator +
						parseFloat(aditiveInMl(usedAditive));
				});

			return volumeAccumulator.toFixed(2);
		}
    },
    watch: {
        medic (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.refetchApolloQueries()
            }
        },
        patient (newValue, oldValue) {
            if (newValue !== oldValue) {
                this.refetchApolloQueries()
            }
        }
    },
    data () {
        return {
            skipPreviousMixtureQuery: true
        }
    },
    methods: {
        loadPreviousMixture() {
			this.skipPreviousMixtureQuery = false;
        },
        refetchApolloQueries() {
			this.$apollo.queries.aminoacids.refetch();
			this.$apollo.queries.dextrose.refetch();
			this.$apollo.queries.lipids.refetch();
			this.$apollo.queries.sodiumChloride.refetch();
			this.$apollo.queries.threeChamberBag.refetch();
			this.$apollo.queries.electrolytes.refetch();
			this.$apollo.queries.aditives.refetch();
		}
    },
    apollo: {
		aminoacids: {
			manual: true,
			query: query,
			variables() {
				let obj = {
					component_type_id: 2
				};
				if ( this.payer ){
					obj.enabled_for_payer_id = this.payer.id;
				};
				return obj;
			},
			result({ data, loading }) {
				if (!loading) {
					data.componentTypeId = 2;
					this.$store.commit('nutritionalOrder/SET_COMPONENT_IN_MACRONUTRIENT', data);
				}
			},
		},
		dextrose: {
			manual: true,
			query: query,
			variables() {
				let obj = {
					component_type_id: 3
				};
				if ( this.payer ){
					obj.enabled_for_payer_id = this.payer.id;
				};
				return obj;
			},
			result({ data, loading }) {
				if (!loading) {
					data.componentTypeId = 3;
					this.$store.commit('nutritionalOrder/SET_COMPONENT_IN_MACRONUTRIENT', data);
				}
			},
		},
		lipids: {
			manual: true,
			query: query,
			variables() {
				let obj = {
					component_type_id: 4
				};
				if ( this.payer ){
					obj.enabled_for_payer_id = this.payer.id;
				};
				return obj;
			},
			result({ data, loading }) {
				if (!loading) {
					data.componentTypeId = 4;
					this.$store.commit('nutritionalOrder/SET_COMPONENT_IN_MACRONUTRIENT', data);
				}
			},
		},
		sodiumChloride: {
			manual: true,
			query: query,
			variables() {
				let obj = {
					component_type_id: 5
				};
				if ( this.payer ){
					obj.enabled_for_payer_id = this.payer.id;
				};
				return obj;
			},
			result({ data, loading }) {
				if (!loading) {
					data.componentTypeId = 5;
					this.$store.commit('nutritionalOrder/SET_COMPONENT_IN_MACRONUTRIENT', data);
				}
			},
		},
		threeChamberBag: {
			manual: true,
			query: query,
			variables() {
				let obj = {
					component_type_id: 7
				};
				if ( this.payer ){
					obj.enabled_for_payer_id = this.payer.id;
				};
				return obj;
			},
			result({ data, loading }) {
				if (!loading) {
					data.componentTypeId = 7;
					this.$store.commit('nutritionalOrder/SET_COMPONENT_IN_MACRONUTRIENT', data);
				}
			},
		},
		electrolytes: {
			manual: true,
			query: query,
			variables() {
				let obj = {
					component_type_id: 10
				};
				if ( this.payer ){
					obj.enabled_for_payer_id = this.payer.id;
				};
				return obj;
			},
			result({ data, loading }) {
				if (!loading) {
					this.$store.commit('nutritionalOrder/SET_ELECTROLYTES', data.components);
				}
			},
		},
		aditives: {
            manual: true,
			query: query,
			variables() {
				let obj = {
					component_type_id: 11
				};
				if ( this.payer ){
					obj.enabled_for_payer_id = this.payer.id;
				};
				return obj;
            },
            result({ data, loading }) {
				if (!loading) {
					this.$store.commit('nutritionalOrder/SET_ADITIVES', data.components);
				}
			}
		},
		infusionSet: {
			manual: true,
			query: query,
			variables() {
				let obj = {
					component_type_id: 8
				};
				if ( this.payer ){
					obj.enabled_for_payer_id = this.payer.id;
				};
				return obj;
			},
			result({ data, loading }) {
				if (!loading) {
					this.$store.commit('nutritionalOrder/UPDATE_INFUSION_SET', data.components);
				}
			},
		},
		previousMixture: {
			manual: true,
			fetchPolicy: 'cache-and-network',
			query: lastMixtureQuery,
			watchLoading ( isLoading ) {
				isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
			},
			variables () {
				return {
					patient_id: this.patient.id
				};
			},
			result ( { data, loading } ) {
				if ( !loading ) {
					if ( !data.nutritional_mixtures.length ) {
                        this.$notify({
                            title: 'No existe una mezcla anterior!',
                            type: 'error',
                            text: 'El paciente no tiene una mezcla anterior registrada'
                        });
						return this.skipPreviousMixtureQuery = true;
					}
					this.$store.commit('nutritionalOrder/UPDATE_TOTAL_VOLUME', data.nutritional_mixtures[0].total_volume_in_ml );
					this.$store.commit('nutritionalOrder/UPDATE_OVERFILLING', data.nutritional_mixtures[0].overfill_in_ml );
					this.$store.commit('nutritionalOrder/UPDATE_INFUSION_TIME', data.nutritional_mixtures[0].infusion_time_in_h );

					data.nutritional_mixtures[0].elements.map( element => {
                        this.macronutrients.map( macronutrient => {
                            if ( macronutrient.componentTypeId == element.component.component_type.id ) {
                                const data = {
                                    id: macronutrient.id,
                                    selectedComponent: element.component,
                                    quantity: ( element.medicine_quantity * element.multiply_factor_to_selected_unit ).toFixed(2),
                                    unitSymbol: element.selected_unit_code, 
                                };
                                this.$store.commit( 'nutritionalOrder/SET_SELECTED_COMPONENT_ITEM', data );
                            }
                        } );
                        this.electrolytes.map( electrolyte => {
                            if ( electrolyte.id == element.component.id ) {
                                const data = {
                                    id: electrolyte.id,
                                    selectedComponent: element.component,
                                    quantity: ( element.medicine_quantity * element.multiply_factor_to_selected_unit ).toFixed(2),
                                    unitSymbol: element.selected_unit_code,
                                    showInput: true,
                                };
                                this.$store.commit( 'nutritionalOrder/SET_SELECTED_ELECTROLYTE_ITEM', data );
                            }
                        } );
                        this.aditives.map( aditive => {
                            if ( aditive.id == element.component.id ) {
                                const data = {
                                    id: aditive.id,
                                    selectedComponent: element.component,
                                    quantity: ( element.medicine_quantity * element.multiply_factor_to_selected_unit ).toFixed(2),
                                    unitSymbol: element.selected_unit_code,
                                    showInput: true,
                                };
                                this.$store.commit( 'nutritionalOrder/SET_SELECTED_ADITIVE_ITEM', data );
                            }
                        });
					} );
					this.skipPreviousMixtureQuery = true;
				}
			},
			skip () {
				return this.skipPreviousMixtureQuery;
			},
		}
	}
};
</script>