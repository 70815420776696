var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.medicine
      ? _c(
          "div",
          [
            _c("serbitec-one-medicine-one-thinner-alerts", {
              attrs: {
                "medicine-id": _vm.medicine.medicine.id,
                "thinner-id": _vm.thinner.component.id,
                "medicine-quantity": _vm.medicine.medicine_quantity,
                "thinner-quantity": _vm.thinner.medicine_quantity,
                "medicine-unit-symbol":
                  _vm.medicine.medicine.component_unit.symbol
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }