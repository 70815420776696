var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.internalUsers,
            loading: _vm.loading,
            paginated: "",
            "backend-pagination": "",
            total: _vm.internalUsersCount,
            "per-page": _vm.pageSize,
            "aria-next-label": "Siguiente página",
            "aria-previous-label": "Página anterior",
            "aria-page-label": "Página",
            "aria-current-label": "Página actual",
            "backend-sorting": "",
            "default-sort": [_vm.sortField, _vm.sortOrder]
          },
          on: { "page-change": _vm.changePage, sort: _vm.sort },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "first_name",
                        label: "Nombre",
                        sortable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.first_name) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "last_name_1",
                        label: "Apellido paterno",
                        sortable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.last_name_1) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "last_name_2",
                        label: "Apellido materno",
                        sortable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.last_name_2) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    {
                      attrs: {
                        field: "email",
                        label: "Correo electrónico",
                        sortable: ""
                      }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.email) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Tipo" } },
                    _vm._l(props.row.types, function(type) {
                      return _c(
                        "span",
                        { key: props.row.id + "-" + type.id },
                        [
                          _c("b-tag", [
                            _vm._v(
                              "\n                        " +
                                _vm._s(type.name) +
                                "\n                    "
                            )
                          ])
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Editar", centered: "" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "button is-small",
                          attrs: { append: "" },
                          on: {
                            click: function($event) {
                              return _vm.editInternalUser(props.row)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "edit" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Eliminar", centered: "" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "button is-small is-danger",
                          attrs: { append: "" },
                          on: {
                            click: function($event) {
                              return _vm.confirmDeleteInternalUser(props.row.id)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "trash-alt" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("No hay usuarios internos registrados.")])
                ]
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }