<template>
    <b-autocomplete
    placeholder="Introduzca el nombre y seleccione..."
    v-model="componentName"
    :data="components"
    :loading="autocompleteIsLoading"
    @select="option => selectComponent(option)" expanded>

        <template slot-scope="props">
            <div class="media">
                <div class="media-content">
                    <p class="has-text-weight-light">{{ props.option.name }}</p>
                </div>
            </div>
        </template>

        <template slot="empty">No se encontró el componente...</template>
    </b-autocomplete>
</template>

<script>
import gql from 'graphql-tag';
const query = gql` query ( $search: String, $with_component_type_ids: String, $without_component_type_ids: String )
{
    components ( search: $search, with_component_type_ids: $with_component_type_ids, without_component_type_ids: $without_component_type_ids ) {
        id
        name
    }
}`;

export default {
    props: {
        withComponentTypeIds: {
            type: String,
            default: ''
        },
        withoutComponentTypeIds: {
            type: String,
            default: ''
        }
    },
    data: function () {
        return {
            components: [],
            componentName: null,
            autocompleteIsLoading: false
        };
    },
    methods: {
        selectComponent(selectedComponent){
            this.$emit('component-added', selectedComponent);
        },
    },
    apollo: {
        components: {
            query: query,
            variables () {
                return {
                    search: this.componentName,
                    with_component_type_ids: this.withComponentTypeIds,
                    without_component_type_ids: this.withoutComponentTypeIds
                };
            },
            watchLoading (isLoading) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            }
        },
    }
};
</script>