<template>
    <div>
        <b-message>
            El volumen calculado al momento es: <strong>{{ computedTotalVolume }} ml</strong>.
            <br> <small>Si desea que este sea al volumen total de la mezcla de click en el botón Calcular a un lado del campo del volumen total.</small>
        </b-message>
        <b-message
            v-if="volumeValidations.waterIsMoreThan60percentTotalVolume"
            class="is-danger"
        >
            La cantidad de agua inyectable es mayor al 60% del volumen.
            <br> <small>Modifique el volumen total o los componentes de la mezcla para ajustar.</small>
        </b-message>
        <b-message
        v-if="volumeValidations.totalVolumeMinorToComputedVolume"
        class="is-danger">
            El volumen total introducido es menor al volumen calculado. El volumen total debe ser mayor o igual al volumen calculado para continuar.
        </b-message>
        <b-message
        v-if="volumeValidations.notValueInTotalVolume"
        class="is-danger">
            Hace falta llenar el campo de volumen total.
            <br> <small>Favor de llenar el campo en la parte superior de la pantalla.</small>
        </b-message>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: [ 'computedTotalVolume' ],
    computed: {
        ...mapState({
            totalVolume: state => state.nutritionalOrder.totalVolume,
            injectableWater: state => state.nutritionalOrder.injectableWater,
            macronutrients: state => state.nutritionalOrder.macronutrients
        }),
        volumeValidations() {
            var validations = {};

            validations.totalVolumeMinorToComputedVolume = ( parseFloat( this.totalVolume ) < parseFloat( this.computedTotalVolume ) );
            if (this.hasLipids) {
                validations.waterIsMoreThan60percentTotalVolume = ( parseFloat( this.injectableWater ) > this.totalVolume * 0.6 );
            }
            validations.notValueInTotalVolume = ( !this.totalVolume );
            
            if ( validations.totalVolumeMinorToComputedVolume || validations.waterIsMoreThan60percentTotalVolume || validations.notValueInTotalVolume ) {
                this.$store.commit('nutritionalOrder/SET_DANGER_ALERT');
            } else {
                this.$store.commit('nutritionalOrder/RESET_DANGER_ALERT');
            }
            
			return validations;
        },
        hasLipids () {
            return this.macronutrients.find(macronutrient => macronutrient.componentTypeId === 4).quantity > 0;
        }
    }
};
</script>