<template>
    <div class="box">
        <b-loading :active.sync="loading"></b-loading>
        <form
        @submit.prevent="submitPayerForm"
        novalidate="true">
            <p>1. Ingrese los datos de la persona de contacto del cliente</p>
            <br>
            <b-field grouped group-multiline>
                <b-field
                label="Nombre"
                :type="{'is-danger' : $v.name.$error }"
                :message="{
                    'Se requiere introducir el nombre completo de la persona de contacto' : !$v.name.required && $v.name.$error
                }"
                expanded>
                    <b-input
                        v-model="$v.name.$model"
                        type="text">
                    </b-input>
                </b-field>
                <b-field
                label="Apellido paterno"
                :type="{'is-danger' : $v.last_name_1.$error }"
                :message="{
                    'Se requiere introducir el apellido paterno de la persona de contacto' : !$v.last_name_1.required && $v.last_name_1.$error
                }"
                expanded>
                    <b-input
                        v-model="$v.last_name_1.$model"
                        type="text">
                    </b-input>
                </b-field>
                <b-field
                    label="Apellido materno"
                    :type="{'is-danger' : $v.last_name_2.$error }"
                    :message="{
                        'Se requiere introducir el apellido materno de la persona de contacto' : !$v.last_name_2.required && $v.last_name_2.$error
                    }"
                    expanded>
                    <b-input
                        v-model="$v.last_name_2.$model"
                        type="text">
                    </b-input>
                </b-field>
            </b-field>
            <b-field grouped group-multiline>
                <b-field
                    label="Correo electrónico"
                    :type="{'is-danger' : $v.contact_email.$error }"
                    :message="{
                        'Se requiere introducir el correo electrónico de la persona de contacto' : !$v.contact_email.required && $v.contact_email.$error,
                        'Se requiere introducir un correo electrónico válido' : !$v.contact_email.email && $v.contact_email.$error
                    }"
                    expanded>
                    <b-input
                        v-model="$v.contact_email.$model"
                        type="text">
                    </b-input>
                </b-field>
                <b-field
                label="Tipo de cliente"
                :type="{'is-danger' : $v.payerTypeId.$error }"
                    :message="{
                        'Se requiere introducir el tipo de cliente' : !$v.payerTypeId.required && $v.payerTypeId.$error
                    }">
                    <b-select
                    v-model="$v.payerTypeId.$model"
                    placeholder="Seleccione un tipo">
                        <option
                            v-for="payerType in payerTypes"
                            :value="payerType.id"
                            :key="payerType.id">
                                {{ payerType.name }}
                        </option>
                    </b-select>
                </b-field>
            </b-field>
            <hr>
            <p>2. Ingrese los datos fiscales del cliente</p>
            <br>
            <b-field grouped group-multiline>
                <b-field
                label="Razón social"
                :type="{'is-danger' : $v.business_name.$error }"
                :message="{
                    'Se requiere introducir la razón social del cliente' : !$v.business_name.required && $v.business_name.$error
                }"
                expanded>
                    <b-input
                        v-model="$v.business_name.$model"
                        type="text">
                    </b-input>
                </b-field>
                <b-field
                label="RFC"
                :type="{'is-danger' : $v.rfc.$error }"
                :message="{
                    'Se requiere introducir el RFC del cliente' : !$v.rfc.required && $v.rfc.$error
                }"
                expanded>
                    <b-input
                        v-model="$v.rfc.$model"
                        type="text">
                    </b-input>
                </b-field>
            </b-field>
            <b-field
            label="Dirección fiscal"
            :type="{'is-danger' : $v.address.$error }"
            :message="{
                'Se requiere introducir la dirección fiscal del cliente' : !$v.address.required && $v.address.$error
            }"
            expanded>
                <b-input
                    v-model="$v.address.$model"
                    type="text">
                </b-input>
            </b-field>
            <b-field grouped group-multiline>
                <b-field
                label="Teléfono"
                :type="{'is-danger' : $v.phone_number.$error }"
                :message="{
                    'Se requiere introducir el teléfono del cliente' : !$v.phone_number.required && $v.phone_number.$error
                }"
                expanded>
                    <b-input
                        v-model="$v.phone_number.$model"
                        v-cleave="masks.phone"
                        type="text">
                    </b-input>
                </b-field>
                <b-field
                label="Correo electrónico (facturación)"
                :type="{'is-danger' : $v.business_email.$error }"
                :message="{
                    'Se requiere introducir el correo electrónico de facturación del cliente' : !$v.business_email.required && $v.business_email.$error,
                    'Se requiere introducir un correo electrónico válido' : !$v.business_email.email && $v.business_email.$error
                }"
                expanded>
                    <b-input
                        v-model="$v.business_email.$model"
                        type="text">
                    </b-input>
                </b-field>
            </b-field>
            <hr>
            <p>
                3. <strong>OPCIONAL.</strong> Seleccione en su caso si el cobró al cliente se le realizará por mezcla
                <br>
                <small>Se puede seleccionar cobro por mezcla nutricional, oncológica o ambas, así como asignar el precio de manera individual.</small>
            </p>
            <br>

            <b-message>
                El precio de la mezcla se puede modificar posteriormente desde la pantalla de edición de cliente.
            </b-message>
            <b-field grouped group-multiline>
                <div class="field">
                    <b-checkbox v-model="pricePerOncologicMixture">
                        Oncológicos
                    </b-checkbox>
                </div>
                <div class="field">
                    <b-checkbox v-model="pricePerNutritionalMixture">
                        Nutricionales
                    </b-checkbox>
                </div>
            </b-field>
            <b-field
            v-if="pricePerOncologicMixture || pricePerNutritionalMixture"
            grouped group-multiline>
                <b-field
                v-if="pricePerOncologicMixture"
                label="Precio por mezcla oncológica"
                :type="{'is-danger' : $v.oncologicMixturePrice.$error }"
                :message="{
                    'Se requiere introducir el precio por mezcla oncológica' : !$v.oncologicMixturePrice.required && $v.oncologicMixturePrice.$error
                }"
                expanded>
                    <b-field
                    :type="{'is-danger' : $v.oncologicMixturePrice.$error }">
                        <p class="control">
                            <button class="button is-static">$</button>
                        </p>
                        <b-input
                            :value="$v.oncologicMixturePrice.$model"
                            @input.native="onInputOncologicMixturePrice"
                            v-cleave="masks.numeral"
                            type="text">
                        </b-input>
                        <p class="control">
                            <button class="button is-static">MXN</button>
                        </p>
                    </b-field>
                </b-field>
                <b-field
                v-if="pricePerNutritionalMixture"
                label="Precio por mezcla nutricional"
                :type="{'is-danger' : $v.nutritionalMixturePrice.$error }"
                :message="{
                    'Se requiere introducir el precio por mezcla nutricional' : !$v.nutritionalMixturePrice.required && $v.nutritionalMixturePrice.$error
                }"
                expanded>
                    <b-field
                    :type="{'is-danger' : $v.nutritionalMixturePrice.$error }">
                        <p class="control">
                                <button class="button is-static">$</button>
                            </p>
                        <b-input
                            :value="$v.nutritionalMixturePrice.$model"
                            @input.native="onInputNutritionalMixturePrice"
                            v-cleave="masks.numeral"
                            type="text">
                        </b-input>
                        <p class="control">
                            <button class="button is-static">MXN</button>
                        </p>
                    </b-field>
                </b-field>
            </b-field>
            <div v-if="!payer">
                <hr>
                <p>
                    4. Seleccione los tipos de medicamento para dar de alta al cliente
                    <br>
                    <small>Se darán de alta todos los medicamentos en existencia en el sistema del tipo seleccionado.</small>
                </p>
                <br>
                <b-message class="is-warning">
                    <p>Los medicamentos se darán de alta desactivados y con un precio de 0.0, favor de hacer los ajustes previo a dar de alta usuarios ya que de lo contrario no podrán seleccionar los componentes que no estén activados.</p>
                </b-message>
                <b-field grouped group-multiline>
                    <div class="field">
                        <b-checkbox v-model="enablePayerOncologicMedicines">
                            Oncológicos
                        </b-checkbox>
                    </div>
                    <div class="field">
                        <b-checkbox v-model="enablePayerNutritionalMedicines">
                            Nutricionales
                        </b-checkbox>
                    </div>
                </b-field>
            </div>
            <br>
            <b-field grouped position="is-centered">
                <p class="control">
                    <button type="submit" class="button is-primary">
                        <span v-if="payer">
                            Editar cliente
                        </span>
                        <span v-else>
                            Agregar cliente
                        </span>
                    </button>
                </p>
            </b-field>
        </form> 
    </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import axios from 'axios';
import Cleave from 'cleave.js';
require('cleave.js/dist/addons/cleave-phone.mx');

const cleave = {
    name: 'cleave',
    bind(el, binding) {
        const input = el.querySelector('input');
        input._vCleave = new Cleave(input, binding.value);
    },
    unbind(el) {
        const input = el.querySelector('input');
        input._vCleave.destroy();
    }
};

import gql from 'graphql-tag';
const query = gql` query
{
    payer_types {
        id
        name
    }
}`;

function initialDataState() {
    return {
        name: null,
        last_name_1: null,
        last_name_2: null,
        contact_email: null,
        business_name: null,
        rfc: null,
        address: null,
        phone_number: null,
        business_email: null,
        payerTypeId: null,
        enablePayerOncologicMedicines: false,
        enablePayerNutritionalMedicines: false,
        pricePerOncologicMixture: false,
        pricePerNutritionalMixture: false,
        oncologicMixturePrice: null,
        rawOncologicMixturePrice: null,
        nutritionalMixturePrice: null,
        rawNutritionalMixturePrice: null,

        loading: false,
        masks: {
            phone: {
                phone: true,
                phoneRegionCode: 'MX'
            },
            numeral: {
                numeral: true
            }
        }
    };
}

export default {
    directives: { cleave },
    props: ['payer'],
    beforeMount() {
        if ( this.payer ){
            this.name = this.payer.first_name;
            this.last_name_1 = this.payer.last_name_1;
            this.last_name_2 = this.payer.last_name_2;
            this.contact_email = this.payer.email;
            this.business_name = this.payer.invoicing_data.business_name;
            this.rfc = this.payer.invoicing_data.rfc;
            this.address = this.payer.invoicing_data.address;
            this.phone_number = this.payer.invoicing_data.phone_number;
            this.business_email = this.payer.invoicing_data.email;
            this.payerTypeId = this.payer.type.id;

            if ( this.payer.invoicing_data.price_in_cents_per_nutritional_mixture > 0 ) {
                this.pricePerNutritionalMixture = true;
                let price = this.payer.invoicing_data.price_in_cents_per_nutritional_mixture / 100;
                this.nutritionalMixturePrice = price;
                this.rawNutritionalMixturePrice = price;
            }
            if ( this.payer.invoicing_data.price_in_cents_per_oncologic_mixture > 0 ) {
                this.pricePerOncologicMixture = true;
                let price = this.payer.invoicing_data.price_in_cents_per_oncologic_mixture / 100;
                this.oncologicMixturePrice = price;
                this.rawOncologicMixturePrice = price;
            }
        }
    },
    data: initialDataState,
    methods: {
        onInputOncologicMixturePrice(){
            this.$v.oncologicMixturePrice.$model = event.target._vCleave.getFormattedValue();
            this.rawOncologicMixturePrice = event.target._vCleave.getRawValue();
        },
        onInputNutritionalMixturePrice(){
            this.$v.nutritionalMixturePrice.$model = event.target._vCleave.getFormattedValue();
            this.rawNutritionalMixturePrice = event.target._vCleave.getRawValue();
        },
        submitPayerForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.addPayer();
            }
        },
        getPayerData () {
            const payer = {
                first_name: this.name,
                last_name_1: this.last_name_1,
                last_name_2: this.last_name_2,
                email: this.contact_email,
                business_name: this.business_name,
                rfc: this.rfc,
                address: this.address,
                phone_number: this.phone_number,
                business_email: this.business_email,
                business_type_id: this.payerTypeId,
                price_in_cents_per_nutritional_mixture: 0,
                price_in_cents_per_oncologic_mixture: 0
            };
            if ( !this.payer ) {
                payer.enable_payer_oncologic_medicines = this.enablePayerOncologicMedicines;
                payer.enable_payer_nutritional_medicines = this.enablePayerNutritionalMedicines;
            }
            if ( this.pricePerOncologicMixture ) {
                payer.price_in_cents_per_oncologic_mixture = parseInt((parseFloat( this.rawOncologicMixturePrice ) * 100).toFixed(0));
            }
            if ( this.pricePerNutritionalMixture ) {
                payer.price_in_cents_per_nutritional_mixture = parseInt((parseFloat( this.rawNutritionalMixturePrice ) * 100).toFixed(0));
            }
            return payer;
        },
        addPayer () {
            if ( this.payer ) {
                this.makeRequest('/api/payers/' + this.payer.id, axios.put, this.getPayerData());
            } else {
                this.makeRequest('/api/payers', axios.post, this.getPayerData());
            }
        },
        async makeRequest( url, method, params ){
            this.loading = true;
            try {
                await method(url,params);
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha dado de alta al cliente'
                });
                this.$router.push( { path: '/payers', query: { success: true } } );
                this.loading = false;
            } catch (error) {
                console.error(error);
                this.$notify({
                    title: '¡Algo ha sucedido!',
                    type: 'error',
                    text: 'Por favor contacte al administrador'
                });
                this.loading = false;
            }
        }
    },
    validations () {
        const validations = {
            name: {
                required
            },
            last_name_1: {
                required
            },
            last_name_2: {
                required
            },
            contact_email: {
                required,
                email
            },
            business_name: {
                required
            },
            rfc: {
                required
            },
            address: {
                required
            },
            phone_number: {
                required
            },
            business_email: {
                required,
                email
            },
            payerTypeId: {
                required
            }
        };

        if ( this.pricePerOncologicMixture ){
            validations.oncologicMixturePrice = {
                required
            };
        }
        if ( this.pricePerNutritionalMixture ){
            validations.nutritionalMixturePrice = {
                required
            };
        }

        return validations;
    },
    apollo: {
        payerTypes: {
            query: query,
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                return data.payer_types;
            }
        },
    }
};
</script>