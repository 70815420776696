<template>
    <div>
        <hr>
        <h1 class="subtitle">Información adicional</h1>

        <div class="columns">
            <div class="column">
                <b-field label="Tiempo de infusión en horas">
                    <b-field>
                        <b-numberinput
                            v-model="infusionTime"
                            type="is-light"
                            min="0">
                        </b-numberinput>
                    </b-field>
                </b-field>
            </div>
            <div v-if="infusionTime && (computedTotalVolume || totalVolume)" class="column">
                <b-field label="Velocidad de infusión">
                    <p class="control">
                        <span class="button is-static">{{ ( Math.max( computedTotalVolume, totalVolume ) / infusionTime ).toFixed(2) }} ml/h</span>
                    </p>
                </b-field>
            </div>
        </div>

        <br>
        <div class="columns box" style="padding-bottom: 15px; padding-top: 10px">
            <div class="column">
                <h1 class="subtitle is-5">Aportes de la mezcla</h1>
                <p><strong>Aporte calórico total: </strong> <span class="serbitec-align-right">{{ componentSums.energyInputInKCalAccumulator }} KCal</span></p>
                <p><strong>Aporte calórico no protéico: </strong> <span class="serbitec-align-right">{{ componentSums.nonProteicEnergyInputInKCalAccumulator }} KCal</span></p>
                <p><strong>Gramos de aminoácido: </strong> <span class="serbitec-align-right">{{ componentSums.gramsOfAminoacidAccumulator }} g</span></p>
                <p><strong>Gramos de nitrógeno: </strong> <span class="serbitec-align-right">{{ componentSums.gramsOfNitrogenAccumulator }} g</span></p>
                <span v-if="totalVolume > 0">
                    <p><strong>Osmolaridad: </strong> <span class="serbitec-align-right">{{ componentSums.osmoticConcentrationAccumulator }} mOsm/l</span></p>
                </span>
                <span v-else>
                    <strong>Osmolaridad: </strong> <p class="serbitec-align-right is-size-7">Se requiere el volumen total para calcular la osmolaridad</p>
                </span>
            </div>
            <div class="column">
                <h1 class="subtitle is-5">Electrolitos totales</h1>
                <p><strong>Sodio (Na): </strong> <span class="serbitec-align-right">{{ componentSums.naConcentrationAccumulator }} mEq</span></p>
                <p><strong>Potasio (K): </strong> <span class="serbitec-align-right">{{ componentSums.kConcentrationAccumulator }} mEq</span></p>
                <p><strong>Magnesio (Mg): </strong> <span class="serbitec-align-right">{{ componentSums.mgConcentrationAccumulator }} mEq</span></p>
                <p><strong>Calcio (Ca): </strong> <span class="serbitec-align-right">{{ componentSums.caConcentrationAccumulator }} mEq</span></p>
                <p><strong>Fosfato (PO<sub>4</sub>): </strong> <span class="serbitec-align-right">{{ componentSums.po4ConcentrationAccumulator }} mEq</span></p>
                <p><strong>Acetato (Ace): </strong> <span class="serbitec-align-right">{{ componentSums.aceConcentrationAccumulator }} mEq</span></p>
                <p><strong>Cloro (Cl): </strong> <span class="serbitec-align-right">{{ componentSums.clConcentrationAccumulator }} mEq</span></p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { totalMlForMacrocomponent, electrolyteInMl, aditiveInMl } from '../../../../utils';

export default {
    props:['computedTotalVolume'],
    computed: {
        ...mapState({
            patient: state => state.nutritionalOrder.patient,
            totalVolume: state => state.nutritionalOrder.totalVolume,
            macronutrients: state => state.nutritionalOrder.macronutrients,
            electrolytes: state => state.nutritionalOrder.electrolytes,
            aditives: state => state.nutritionalOrder.aditives
        }),
        infusionTime: {
            get () {
                return this.$store.state.nutritionalOrder.infusionTime;
            },
            set (value) {
                this.$store.commit('nutritionalOrder/UPDATE_INFUSION_TIME', value);
            }
        },
        componentSums () {
            var accumulator = {
                energyInputInKCalAccumulator: 0.0,
                nonProteicEnergyInputInKCalAccumulator: 0.0,
                gramsOfAminoacidAccumulator: 0.0,
                gramsOfNitrogenAccumulator: 0.0,
                osmoticConcentrationAccumulator: 0.0,
                naConcentrationAccumulator: 0.0,
                kConcentrationAccumulator: 0.0,
                mgConcentrationAccumulator: 0.0,
                caConcentrationAccumulator: 0.0,
                po4ConcentrationAccumulator: 0.0,
                aceConcentrationAccumulator: 0.0,
                clConcentrationAccumulator: 0.0,
            };
            this.macronutrients.filter( macronutrient => macronutrient.quantity ).map( usedMacronutrient => {
                accumulator.energyInputInKCalAccumulator = accumulator.energyInputInKCalAccumulator + ( usedMacronutrient.selectedComponent.nutritional_field.energy_input_in_kcal * totalMlForMacrocomponent( usedMacronutrient, this.patient.weight_in_kilograms ) );
                accumulator.nonProteicEnergyInputInKCalAccumulator = accumulator.nonProteicEnergyInputInKCalAccumulator + ( usedMacronutrient.selectedComponent.nutritional_field.non_protein_energy_input_in_kcal * totalMlForMacrocomponent( usedMacronutrient, this.patient.weight_in_kilograms ) );
                accumulator.gramsOfAminoacidAccumulator = accumulator.gramsOfAminoacidAccumulator + ( usedMacronutrient.selectedComponent.nutritional_field.grams_of_aminoacid * totalMlForMacrocomponent( usedMacronutrient, this.patient.weight_in_kilograms ) );
                accumulator.gramsOfNitrogenAccumulator = accumulator.gramsOfNitrogenAccumulator + ( usedMacronutrient.selectedComponent.nutritional_field.grams_of_nitrogen * totalMlForMacrocomponent( usedMacronutrient, this.patient.weight_in_kilograms ) );
                accumulator.osmoticConcentrationAccumulator = accumulator.osmoticConcentrationAccumulator + ( usedMacronutrient.selectedComponent.nutritional_field.osmotic_concentration_in_mosmol_l * ( totalMlForMacrocomponent( usedMacronutrient, this.patient.weight_in_kilograms ) / this.totalVolume ) );

                accumulator.naConcentrationAccumulator = accumulator.naConcentrationAccumulator + + ( usedMacronutrient.selectedComponent.nutritional_field.na_in_meq * totalMlForMacrocomponent( usedMacronutrient, this.patient.weight_in_kilograms ) );
                accumulator.kConcentrationAccumulator = accumulator.kConcentrationAccumulator + + ( usedMacronutrient.selectedComponent.nutritional_field.k_in_meq * totalMlForMacrocomponent( usedMacronutrient, this.patient.weight_in_kilograms ) );
                accumulator.mgConcentrationAccumulator = accumulator.mgConcentrationAccumulator + + ( usedMacronutrient.selectedComponent.nutritional_field.mg_in_meq * totalMlForMacrocomponent( usedMacronutrient, this.patient.weight_in_kilograms ) );
                accumulator.caConcentrationAccumulator = accumulator.caConcentrationAccumulator + + ( usedMacronutrient.selectedComponent.nutritional_field.ca_in_meq * totalMlForMacrocomponent( usedMacronutrient, this.patient.weight_in_kilograms ) );
                accumulator.po4ConcentrationAccumulator = accumulator.po4ConcentrationAccumulator + + ( usedMacronutrient.selectedComponent.nutritional_field.po4_in_meq * totalMlForMacrocomponent( usedMacronutrient, this.patient.weight_in_kilograms ) );
                accumulator.aceConcentrationAccumulator = accumulator.aceConcentrationAccumulator + + ( usedMacronutrient.selectedComponent.nutritional_field.ace_in_meq * totalMlForMacrocomponent( usedMacronutrient, this.patient.weight_in_kilograms ) );
                accumulator.clConcentrationAccumulator = accumulator.clConcentrationAccumulator + + ( usedMacronutrient.selectedComponent.nutritional_field.cl_in_meq * totalMlForMacrocomponent( usedMacronutrient, this.patient.weight_in_kilograms ) );
            });

            this.electrolytes.filter( electrolyte => electrolyte.quantity ).map( usedElectrolyte => {
                accumulator.energyInputInKCalAccumulator = accumulator.energyInputInKCalAccumulator + ( usedElectrolyte.nutritional_field.energy_input_in_kcal * electrolyteInMl( usedElectrolyte, this.patient.weight_in_kilograms ) );
                accumulator.nonProteicEnergyInputInKCalAccumulator = accumulator.nonProteicEnergyInputInKCalAccumulator + ( usedElectrolyte.nutritional_field.non_protein_energy_input_in_kcal * electrolyteInMl( usedElectrolyte, this.patient.weight_in_kilograms ) );
                accumulator.gramsOfAminoacidAccumulator = accumulator.gramsOfAminoacidAccumulator + ( usedElectrolyte.nutritional_field.grams_of_aminoacid * electrolyteInMl( usedElectrolyte, this.patient.weight_in_kilograms ) );
                accumulator.gramsOfNitrogenAccumulator = accumulator.gramsOfNitrogenAccumulator + ( usedElectrolyte.nutritional_field.grams_of_nitrogen * electrolyteInMl( usedElectrolyte, this.patient.weight_in_kilograms ) );
                accumulator.osmoticConcentrationAccumulator = accumulator.osmoticConcentrationAccumulator + ( usedElectrolyte.nutritional_field.osmotic_concentration_in_mosmol_l * ( electrolyteInMl( usedElectrolyte, this.patient.weight_in_kilograms ) / this.totalVolume ) );

                accumulator.naConcentrationAccumulator = accumulator.naConcentrationAccumulator + ( usedElectrolyte.nutritional_field.na_in_meq * electrolyteInMl( usedElectrolyte, this.patient.weight_in_kilograms ) );
                accumulator.kConcentrationAccumulator = accumulator.kConcentrationAccumulator + ( usedElectrolyte.nutritional_field.k_in_meq * electrolyteInMl( usedElectrolyte, this.patient.weight_in_kilograms ) );
                accumulator.mgConcentrationAccumulator = accumulator.mgConcentrationAccumulator + ( usedElectrolyte.nutritional_field.mg_in_meq * electrolyteInMl( usedElectrolyte, this.patient.weight_in_kilograms ) );
                accumulator.caConcentrationAccumulator = accumulator.caConcentrationAccumulator + ( usedElectrolyte.nutritional_field.ca_in_meq * electrolyteInMl( usedElectrolyte, this.patient.weight_in_kilograms ) );
                accumulator.po4ConcentrationAccumulator = accumulator.po4ConcentrationAccumulator + ( usedElectrolyte.nutritional_field.po4_in_meq * electrolyteInMl( usedElectrolyte, this.patient.weight_in_kilograms ) );
                accumulator.aceConcentrationAccumulator = accumulator.aceConcentrationAccumulator + ( usedElectrolyte.nutritional_field.ace_in_meq * electrolyteInMl( usedElectrolyte, this.patient.weight_in_kilograms ) );
                accumulator.clConcentrationAccumulator = accumulator.clConcentrationAccumulator + ( usedElectrolyte.nutritional_field.cl_in_meq * electrolyteInMl( usedElectrolyte, this.patient.weight_in_kilograms ) );
            });

            this.aditives.filter( aditive => aditive.quantity ).map( usedAditive => {
                accumulator.energyInputInKCalAccumulator = accumulator.energyInputInKCalAccumulator + ( usedAditive.nutritional_field.energy_input_in_kcal * aditiveInMl( usedAditive ) );
                accumulator.nonProteicEnergyInputInKCalAccumulator = accumulator.nonProteicEnergyInputInKCalAccumulator + ( usedAditive.nutritional_field.non_protein_energy_input_in_kcal * aditiveInMl( usedAditive ) );
                accumulator.gramsOfAminoacidAccumulator = accumulator.gramsOfAminoacidAccumulator + ( usedAditive.nutritional_field.grams_of_aminoacid * aditiveInMl( usedAditive ) );
                accumulator.gramsOfNitrogenAccumulator = accumulator.gramsOfNitrogenAccumulator + ( usedAditive.nutritional_field.grams_of_nitrogen * aditiveInMl( usedAditive ) );
                accumulator.osmoticConcentrationAccumulator = accumulator.osmoticConcentrationAccumulator + ( usedAditive.nutritional_field.osmotic_concentration_in_mosmol_l * ( aditiveInMl( usedAditive ) / this.totalVolume ) );

                accumulator.naConcentrationAccumulator = accumulator.naConcentrationAccumulator + ( usedAditive.nutritional_field.na_in_meq * aditiveInMl( usedAditive ) );
                accumulator.kConcentrationAccumulator = accumulator.kConcentrationAccumulator + ( usedAditive.nutritional_field.k_in_meq * aditiveInMl( usedAditive ) );
                accumulator.mgConcentrationAccumulator = accumulator.mgConcentrationAccumulator + ( usedAditive.nutritional_field.mg_in_meq * aditiveInMl( usedAditive ) );
                accumulator.caConcentrationAccumulator = accumulator.caConcentrationAccumulator + ( usedAditive.nutritional_field.ca_in_meq * aditiveInMl( usedAditive ) );
                accumulator.po4ConcentrationAccumulator = accumulator.po4ConcentrationAccumulator + ( usedAditive.nutritional_field.po4_in_meq * aditiveInMl( usedAditive ) );
                accumulator.aceConcentrationAccumulator = accumulator.aceConcentrationAccumulator + ( usedAditive.nutritional_field.ace_in_meq * aditiveInMl( usedAditive ) );
                accumulator.clConcentrationAccumulator = accumulator.clConcentrationAccumulator + ( usedAditive.nutritional_field.cl_in_meq * aditiveInMl( usedAditive ) );
            });

            Object.keys(accumulator).map(function(index) {
                accumulator[index] = accumulator[index].toFixed(2);
            });
            return accumulator;
        },
    },
};
</script>

<style lang="scss" scoped>
.serbitec-align-right {
    float: right
}
</style>