var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "columns is-centered" }, [
    _c(
      "div",
      { staticClass: "column is-half" },
      [
        _c(
          "b-message",
          { attrs: { type: "is-danger", "has-icon": "" } },
          [
            _c(
              "h1",
              {
                staticClass:
                  "subtitle has-text-danger is-paddingless is-marginless"
              },
              [
                _vm._v(
                  "\n                La página que busca no existe.\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c("small", { staticClass: "serbitec-small-top-margin" }, [
              _vm._v(
                "\n                Para regresar al home de click en el siguiente botón.\n            "
              )
            ]),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticClass: "serbitec-medium-top-margin",
                attrs: { position: "is-centered" }
              },
              [
                _c(
                  "p",
                  { staticClass: "control" },
                  [
                    _c(
                      "router-link",
                      { staticClass: "button", attrs: { to: { path: "/" } } },
                      [
                        _c(
                          "span",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "serbitec-extra-small-right-margin",
                              attrs: { icon: "home" }
                            })
                          ],
                          1
                        ),
                        _vm._v(
                          "\n                        Llévame a home\n                    "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }