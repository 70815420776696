var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        attrs: { novalidate: "true" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitPatientForm($event)
          }
        }
      },
      [
        _c(
          "b-field",
          { attrs: { grouped: "", "group-multiline": "" } },
          [
            _c(
              "b-field",
              {
                attrs: {
                  type: { "is-danger": _vm.$v.name.$error },
                  message: {
                    "Se requiere introducir el nombre completo del paciente":
                      !_vm.$v.name.required && _vm.$v.name.$error
                  },
                  expanded: ""
                }
              },
              [
                _c(
                  "b-field",
                  {
                    attrs: {
                      label: "Nombre completo del paciente",
                      "label-position": "on-border",
                      type: { "is-danger": _vm.$v.name.$error }
                    }
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        type: "text",
                        disabled: _vm.comesFromAutocomplete,
                        expanded: ""
                      },
                      model: {
                        value: _vm.$v.name.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.name,
                            "$model",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "$v.name.$model"
                      }
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "control" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("patient-unselected")
                            }
                          }
                        },
                        [
                          _c("span", [_vm._v("Cambiar")]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "icon is-small" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "exchange-alt" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            staticClass: "serbitec-small-top-margin",
            attrs: {
              label: "Diagnóstico del paciente",
              "label-position": "on-border",
              type: { "is-danger": _vm.$v.diagnosis.$error },
              message: {
                "Se requiere introducir el diagnóstico del paciente":
                  !_vm.$v.diagnosis.required && _vm.$v.diagnosis.$error
              },
              expanded: ""
            }
          },
          [
            _c("b-input", {
              attrs: { type: "text" },
              model: {
                value: _vm.$v.diagnosis.$model,
                callback: function($$v) {
                  _vm.$set(
                    _vm.$v.diagnosis,
                    "$model",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "$v.diagnosis.$model"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { grouped: "" } },
          [
            _c(
              "b-field",
              {
                staticClass: "serbitec-small-top-margin",
                attrs: {
                  label: "Talla",
                  "label-position": "on-border",
                  expanded: ""
                }
              },
              [
                _c(
                  "b-field",
                  [
                    _c("b-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.heightInMeters,
                        callback: function($$v) {
                          _vm.heightInMeters = $$v
                        },
                        expression: "heightInMeters"
                      }
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "control" }, [
                      _c("span", { staticClass: "button is-static" }, [
                        _vm._v("m")
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticClass: "serbitec-small-top-margin",
                attrs: {
                  label: "Peso",
                  "label-position": "on-border",
                  type: { "is-danger": _vm.$v.weightInKg.$error },
                  message: {
                    "Se requiere introducir el peso del paciente":
                      !_vm.$v.weightInKg.required && _vm.$v.weightInKg.$error
                  },
                  expanded: ""
                }
              },
              [
                _c(
                  "b-field",
                  {
                    attrs: { type: { "is-danger": _vm.$v.weightInKg.$error } }
                  },
                  [
                    _c("b-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.$v.weightInKg.$model,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.$v.weightInKg,
                            "$model",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "$v.weightInKg.$model"
                      }
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "control" }, [
                      _c("span", { staticClass: "button is-static" }, [
                        _vm._v("Kg")
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { grouped: "" } },
          [
            _vm.isOncologic
              ? _c(
                  "b-field",
                  {
                    staticClass: "serbitec-small-top-margin",
                    attrs: {
                      label: "Superficie corporal",
                      "label-position": "on-border",
                      type: { "is-danger": _vm.$v.bodySurface.$error },
                      message: [
                        !_vm.$v.bodySurface.required &&
                        _vm.$v.bodySurface.$error
                          ? "Se requiere introducir la superficie corporal"
                          : "Para calcular de click en el botón Calcular, de lo contrario introduzca un valor"
                      ],
                      expanded: ""
                    }
                  },
                  [
                    _c(
                      "b-field",
                      {
                        attrs: {
                          type: { "is-danger": _vm.$v.bodySurface.$error }
                        }
                      },
                      [
                        _c("b-input", {
                          attrs: { type: "text" },
                          model: {
                            value: _vm.$v.bodySurface.$model,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.$v.bodySurface,
                                "$model",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "$v.bodySurface.$model"
                          }
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "control" }, [
                          _c("span", { staticClass: "button is-static" }, [
                            _vm._v("Kg/m"),
                            _c("sup", [_vm._v("2")])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "control" }, [
                          _c(
                            "span",
                            {
                              staticClass: "button",
                              on: {
                                click: function($event) {
                                  _vm.bodySurface = _vm.computedBodySurface
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "icon is-small" },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: "calculator" }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("span", [_vm._v("Calcular")])
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticClass: "serbitec-small-top-margin",
                attrs: {
                  label: "Días de Tratamiento",
                  "label-position": "on-border",
                  expanded: ""
                }
              },
              [
                _c(
                  "b-field",
                  [
                    _c("b-input", {
                      attrs: { type: "text" },
                      model: {
                        value: _vm.treatmentDays,
                        callback: function($$v) {
                          _vm.treatmentDays = $$v
                        },
                        expression: "treatmentDays"
                      }
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "control" }, [
                      _c("span", { staticClass: "button is-static" }, [
                        _vm._v("Días")
                      ])
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          { attrs: { grouped: "", "group-multiline": "" } },
          [
            _c(
              "b-field",
              {
                attrs: {
                  label: "Género",
                  type: { "is-danger": _vm.$v.gender.$error },
                  message: {
                    "Se requiere introducir el género del paciente":
                      !_vm.$v.gender.required && _vm.$v.gender.$error
                  },
                  expanded: ""
                }
              },
              [
                _c(
                  "b-field",
                  [
                    _c(
                      "b-radio-button",
                      {
                        attrs: {
                          "native-value": "Masculino",
                          type: "is-primary",
                          disabled:
                            _vm.comesFromAutocomplete &&
                            _vm.gender != "Masculino"
                        },
                        model: {
                          value: _vm.gender,
                          callback: function($$v) {
                            _vm.gender = $$v
                          },
                          expression: "gender"
                        }
                      },
                      [
                        _c("font-awesome-icon", { attrs: { icon: "male" } }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticStyle: { "padding-left": "10px" } },
                          [_vm._v("Masculino")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-radio-button",
                      {
                        attrs: {
                          "native-value": "Femenino",
                          type: "is-primary",
                          disabled:
                            _vm.comesFromAutocomplete &&
                            _vm.gender != "Femenino"
                        },
                        model: {
                          value: _vm.gender,
                          callback: function($$v) {
                            _vm.gender = $$v
                          },
                          expression: "gender"
                        }
                      },
                      [
                        _c("font-awesome-icon", { attrs: { icon: "female" } }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticStyle: { "padding-left": "10px" } },
                          [_vm._v("Femenino")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-field",
              {
                attrs: {
                  label: "Tipo de paciente",
                  type: { "is-danger": _vm.$v.ageType.$error },
                  message: {
                    "Se requiere introducir el tipo del paciente":
                      !_vm.$v.ageType.required && _vm.$v.ageType.$error
                  },
                  expanded: ""
                }
              },
              [
                _c(
                  "b-field",
                  [
                    _c(
                      "b-radio-button",
                      {
                        attrs: { "native-value": "Adulto", type: "is-primary" },
                        model: {
                          value: _vm.ageType,
                          callback: function($$v) {
                            _vm.ageType = $$v
                          },
                          expression: "ageType"
                        }
                      },
                      [_c("span", [_vm._v("Adulto")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-radio-button",
                      {
                        attrs: {
                          "native-value": "Pediátrico",
                          type: "is-primary"
                        },
                        model: {
                          value: _vm.ageType,
                          callback: function($$v) {
                            _vm.ageType = $$v
                          },
                          expression: "ageType"
                        }
                      },
                      [_c("span", [_vm._v("Pediátrico")])]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-radio-button",
                      {
                        attrs: {
                          "native-value": "Neonato",
                          type: "is-primary"
                        },
                        model: {
                          value: _vm.ageType,
                          callback: function($$v) {
                            _vm.ageType = $$v
                          },
                          expression: "ageType"
                        }
                      },
                      [_c("span", [_vm._v("Neonato")])]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            staticClass: "serbitec-small-top-margin",
            attrs: {
              label: "Fecha de nacimiento del paciente",
              "label-position": "on-border",
              type: { "is-danger": _vm.$v.birthDate.$error },
              message: {
                "Se requiere introducir la fecha de nacimiento del paciente":
                  !_vm.$v.birthDate.required && _vm.$v.birthDate.$error
              }
            }
          },
          [
            _c("b-datepicker", {
              class: { danger: _vm.$v.birthDate.$error },
              attrs: {
                "date-formatter": function(d) {
                  return d.toLocaleDateString("es-US", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })
                },
                placeholder: "Seleccione una fecha",
                disabled: _vm.comesFromAutocomplete
              },
              model: {
                value: _vm.birthDate,
                callback: function($$v) {
                  _vm.birthDate = $$v
                },
                expression: "birthDate"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            staticClass: "serbitec-small-top-margin",
            attrs: {
              label: "Número de expediente",
              "label-position": "on-border",
              expanded: ""
            }
          },
          [
            _c(
              "b-field",
              [
                _c("b-input", {
                  attrs: { type: "text", disabled: _vm.comesFromAutocomplete },
                  model: {
                    value: _vm.fileID,
                    callback: function($$v) {
                      _vm.fileID = $$v
                    },
                    expression: "fileID"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            staticClass: "serbitec-small-top-margin",
            attrs: {
              label: "Servicio",
              "label-position": "on-border",
              expanded: ""
            }
          },
          [
            _c(
              "b-field",
              [
                _c("b-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.location,
                    callback: function($$v) {
                      _vm.location = $$v
                    },
                    expression: "location"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            staticClass: "serbitec-small-top-margin",
            attrs: {
              label: "Cama",
              "label-position": "on-border",
              expanded: ""
            }
          },
          [
            _c(
              "b-field",
              [
                _c("b-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.bed,
                    callback: function($$v) {
                      _vm.bed = $$v
                    },
                    expression: "bed"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            staticClass: "serbitec-small-top-margin",
            attrs: {
              label: "Ubicación",
              "label-position": "on-border",
              expanded: ""
            }
          },
          [
            _c(
              "b-field",
              [
                _c("b-input", {
                  attrs: { type: "text" },
                  model: {
                    value: _vm.section,
                    callback: function($$v) {
                      _vm.section = $$v
                    },
                    expression: "section"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("b-field", { attrs: { grouped: "", position: "is-right" } }, [
          _c("p", { staticClass: "control" }, [
            _c("button", { staticClass: "button", attrs: { type: "submit" } }, [
              _c("span", [
                _vm._v(
                  "\n                        Siguiente\n                    "
                )
              ]),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "icon" },
                [_c("font-awesome-icon", { attrs: { icon: "caret-right" } })],
                1
              )
            ])
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }