const getDefaultState = () => {
    return {
        medicData: []
    };
};

const state = getDefaultState();

const mutations = {
    SET_MEDIC (state, data) {
        state.medicData = data;
    },
    RESET_MEDIC (state) {
        Object.assign(state, getDefaultState());
    }
};

export default {
    namespaced: true,
    state,
    mutations
};
  