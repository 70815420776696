<template>
    <section>
        <b-sidebar
            :type="type"
            :fullheight="true"
            :can-cancel="canCancel"
            :overlay="overlay"
            :open="open"
            @close="$emit('close-sidebar')"
        >
        <div class="p-1">
            <b-menu>
                <b-menu-list label="Menu">
                    <div
                        v-for="menuSection in menu"
                        :key="menuSection.id"
                    >
                        <b-menu-item
                            v-if="menuSection.numberOfItemsAuthorized > 0"
                            disabled
                            expanded
                        >
                            <template
                                slot="label"
                                slot-scope="props"
                            >
                                <p class="is-size-7">
                                    {{ menuSection.label }}
                                    <b-icon class="is-pulled-right" size="is-small" :icon="props.expanded ? 'chevron-down' : 'chevron-up'"></b-icon>
                                </p>
                            </template>
                            <div
                                v-for="item in menuSection.items"
                                :key="item.id"
                            >
                                <b-menu-item
                                    v-if="item.isAuth"
                                    tag="router-link"
                                    :to="item.route"
                                    @click.native="$emit('close-sidebar')"
                                >
                                    <template slot="label">
                                        <p class="is-size-7">
                                            {{ item.label }}
                                        </p>
                                    </template>
                                </b-menu-item>
                            </div>
                        </b-menu-item>
                    </div>
                </b-menu-list>
            </b-menu>
        </div>
        </b-sidebar>
    </section>
</template>

<script>
export default {
    props: {
        open: {
            type: Boolean,
            default: false
        },
        menu: {
            type: Array,
            default: () => []
        }
    },
    watch: {
        $mq: function (newVal) {
            if (newVal === 'desktop') {
                this.type = 'is-white';
                this.canCancel = false;
                this.overlay = false;
            }
            else {
                this.type = 'is-light';
                this.canCancel = true;
                this.overlay = true;
                this.$emit('close-sidebar');
            }
        }
    },
    data () {
        return {
            type: 'is-white',
            canCancel: false,
            overlay: false
        };
    },
    beforeMount () {
        if (this.$mq === 'desktop') {
            this.type = 'is-white';
            this.canCancel = false;
            this.overlay = false;
        } else {
            this.type = 'is-light';
            this.canCancel = true;
            this.overlay = true; 
        }
    }
};
</script>

<style>
@media screen and (max-width: 1023px) {
    .b-sidebar .sidebar-content.is-fullheight {
        margin-top: 0px;
    }
    .p-1 {
        padding: 1em;
    }
}
@media screen and (min-width: 1024px) {
    .b-sidebar .sidebar-content.is-fullheight {
        margin-top: 52px;
        z-index: auto;
    }
    .p-1 {
        padding: 1em;
        padding-bottom: 4em;
    }
}
</style>