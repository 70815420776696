var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-field",
        {
          attrs: {
            label: "Cliente",
            type: { "is-danger": _vm.selectedPayer.$error },
            message: [
              !_vm.selectedPayer.required && _vm.selectedPayer.$error
                ? "Se requiere introducir el o los clientes"
                : "Se puede seleccionar más de un cliente haciendo click y arrastrando sin levantar el dedo del botón hasta que la selección se encuentre sombreada. También se pueden seleccionar varios clientes de forma salteada dando click mientras se aprieta el botón CTRL en windows o CMDN en macOS"
            ],
            expanded: ""
          }
        },
        [
          _c(
            "b-select",
            {
              attrs: {
                placeholder: "Seleccione el cliente",
                multiple: "",
                expanded: ""
              },
              on: {
                input: function(value) {
                  return _vm.$emit("payer-selected", value)
                }
              },
              model: {
                value: _vm.selectedPayers,
                callback: function($$v) {
                  _vm.selectedPayers = $$v
                },
                expression: "selectedPayers"
              }
            },
            _vm._l(_vm.payers, function(payer) {
              return _c(
                "option",
                { key: payer.id, domProps: { value: payer } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(payer.invoicing_data.business_name) +
                      "\n            "
                  )
                ]
              )
            }),
            0
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }