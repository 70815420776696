var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "level is-mobile" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "level-right" },
        [
          _c(
            "b-dropdown",
            { attrs: { "aria-role": "list", position: "is-bottom-left" } },
            [
              _c(
                "b-button",
                { attrs: { slot: "trigger" }, slot: "trigger" },
                [_c("font-awesome-icon", { attrs: { icon: "ellipsis-v" } })],
                1
              ),
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                { attrs: { "has-link": "" } },
                [
                  _c(
                    "router-link",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: { to: { path: "request_medicines" }, append: "" }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "serbitec-small-right-margin",
                        attrs: { icon: "prescription-bottle" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(" Solicitar medicamento")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                { attrs: { "has-link": "" } },
                [
                  _c(
                    "router-link",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: { to: { path: "receive_medicines" }, append: "" }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "serbitec-small-right-margin",
                        attrs: { icon: "clipboard-list" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(" Recepción de medicamento")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                { attrs: { "has-link": "" } },
                [
                  _c(
                    "router-link",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: { to: { path: "update" }, append: "" }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "serbitec-small-right-margin",
                        attrs: { icon: "boxes" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(" Corte de almacén")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "box" },
      [
        _c("serbitec-items-table", {
          attrs: { "internal-warehouse-type": "nutritional" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("div", { staticClass: "level-item" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("Insumos nutricionales del área de preparación")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }