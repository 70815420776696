var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.options.find(function(option) {
        return option === "warehouseReportOptions"
      }) !== undefined
        ? _c("serbitec-warehouse-report-options")
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }