<template>
    <div>
        <div v-if="medicine">
            <serbitec-one-medicine-one-thinner-alerts
            :medicine-id="medicine.medicine.id"
            :thinner-id="thinner.component.id"
            :medicine-quantity="medicine.medicine_quantity"
            :thinner-quantity="thinner.medicine_quantity"
            :medicine-unit-symbol="medicine.medicine.component_unit.symbol"></serbitec-one-medicine-one-thinner-alerts>
        </div>
    </div>
</template>

<script>
import serbitecOneMedicineOneThinnerAlerts from '../../utils/oncologic/one-medicine-one-thinner-alerts.vue';

export default {
    props: [ 'elements' ],
    beforeMount () {
        this.medicines = this.elements.filter( element => element.medicine != null );
        if ( this.medicines.length == 1 ) {
            this.medicine = this.medicines[0];
        }
        this.thinner = this.elements.find( element => element.medicine == null );
    },
    components: {
        serbitecOneMedicineOneThinnerAlerts
    },
    data () {
        return {
            medicines: null,
            medicine: null,
            thinner: null,
        };
    },
};
</script>