<template>
    <div>
        <b-loading :active.sync="loading"></b-loading>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right"></div>
        </nav>
        <hr>
        <div v-if="order">
            <div class="box">
                <h1 class="title has-text-centered">Orden OO-{{ orderId }}</h1>
            </div>
            <serbitec-horizontal-order-data
            :medic="order.medic"
            :patient="order.patient"></serbitec-horizontal-order-data>
            <div v-for="( mixture, index ) in order.mixtures" :key="index">
                <div class="box" style="margin-bottom: 15px">
                    <nav class="level">
                        <div class="level-left">
                            <h1 class="subtitle">Mezcla con lote: LO-{{ mixture.id }}</h1>
                        </div>
                        <div class="level-right">
                            <b-tag>Estado: {{ mixture.state.name }}</b-tag>
                        </div>
                    </nav>
                    <serbitec-oncologic-elements-table
                    :elements="mixture.elements"></serbitec-oncologic-elements-table>
                    <b-message class="serbitec-small-top-margin">
                        <nav class="level">
                            <div class="level-left">
                                <h1 class="subtitle">Comentarios</h1>
                            </div>
                            <div class="level-right">
                                <a class="button is-small" @click="editComments(mixture, 'mixture')">
                                    <span class="icon is-medium">
                                        <font-awesome-icon icon="edit" />
                                    </span>
                                </a>
                            </div>
                        </nav>
                        {{ mixture.comments ? mixture.comments : 'Sin comentarios' }}
                    </b-message>
                </div>
            </div>

            <div v-if="order.requested_flasks.length" class="box" style="margin-bottom: 15px">
                <h1 class="subtitle">Frascos</h1>
                <serbitec-oncologic-flasks-table
                :comes-from-records="true"
                :flasks="order.requested_flasks"></serbitec-oncologic-flasks-table>
            </div>
        </div>
    </div>
</template>

<script>
import serbitecHorizontalOrderData from '../../../components/dashboard/utils/horizontal-order-data.vue';
import serbitecOncologicElementsTable from '../../../components/dashboard/utils/oncologic-elements-table.vue';
import serbitecOncologicFlasksTable from '../../../components/dashboard/utils/oncologic-flasks-table.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';
import serbitecEditCommentsModal from '../../../components/dashboard/utils/modals/edit-comments.vue';

import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    oncologic_order( id: $id ) {
        id
        delivery_date
        medic {
            id
            first_name
            last_name_1
            last_name_2
            email
            medical_card
            payer {
                id
                invoicing_data {
                    id
                    business_name
                }
            }
        }
        patient {
            id
            name
            diagnosis
            height_in_meters
            weight_in_kilograms
            birth_date
            file_id
            location
            bed
        }
        mixtures{
            id
            comments
            state {
                id
                name
            }
            elements{
                id
                medicine_quantity
                medicine {
                    id
                    commercial_name
                    component_unit {
                        id
                        symbol
                    }
                }
                component {
                    id
                    name
                    component_type {
                        id
                    }
                }
            }
        }
        requested_flasks {
            id
            comments
            state {
                id
                name
            }
            number_of_flasks
            medicine_presentation {
                id
                volume_in_ml
                component_quantity
                medicine {
                    id
                    commercial_name
                    component_unit {
                        id
                        symbol
                    }
                    component {
                        id
                        name
                    }
                }
            }
        }
    }
}`;

function initialDataState () {
    return {
        loading: false,
        orderId: this.$route.query.order_id,
        route: [
            {
                label: 'Registro de órdenes oncológicas',
                to: '/oncologic_orders_records',
                active: false
            },
            {
                label: 'Detalles de la orden',
                to: '/oncologic_orders_records/order_details',
                active: true
            }
        ]
    };
}

export default {
    components: {
        serbitecHorizontalOrderData,
        serbitecOncologicElementsTable,
        serbitecOncologicFlasksTable,
        serbitecBreadcumbs
    },
    data: initialDataState,
    apollo: {
        order: {
            query: query,
            variables () {
                return {
                    id: this.orderId
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                return data.oncologic_order;
            }
        },
    },
    methods: {
        editComments (mixture, itemType) {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecEditCommentsModal,
                hasModalCard: true,
                trapFocus: true,
                props: {
                    mixture: mixture,
                    type: 'oncologic',
                    itemType: itemType
                }
            });
        }
    }
};
</script>