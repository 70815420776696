var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function($event) {
            _vm.loading = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { novalidate: "true" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submitForm($event)
            }
          }
        },
        [
          _c("div", { staticClass: "box" }, [
            _c("nav", { staticClass: "level" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "level-right" }, [
                _c(
                  "a",
                  {
                    staticClass: "button",
                    on: {
                      click: function($event) {
                        return _vm.closeForm()
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "times-circle" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("span", [_vm._v(" Cerrar forma")])
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            !_vm.selectedOncologicMedicine
              ? _c(
                  "div",
                  [
                    _c("h1", { staticClass: "subtitle is-6" }, [
                      _vm._v("1. Seleccione el medicamento oncológico")
                    ]),
                    _vm._v(" "),
                    _c("serbitec-medicine-autocomplete", {
                      attrs: {
                        "payer-id": _vm.payer.id,
                        withComponentTypeIds: "1"
                      },
                      on: { "medicine-selected": _vm.storeMedicine }
                    }),
                    _vm._v(" "),
                    _c("br")
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.selectedOncologicMedicine
              ? _c(
                  "div",
                  [
                    _c("h1", { staticClass: "subtitle is-6" }, [
                      _vm._v(
                        "2. Llene los campos para agregar el bolo a la orden"
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-field",
                      {
                        attrs: {
                          label:
                            "Cantidad de activo (" +
                            _vm.selectedOncologicMedicine.commercial_name +
                            ")",
                          type: { "is-danger": _vm.$v.medicineQuantity.$error },
                          message: {
                            "Se requiere introducir la cantidad de activo":
                              !_vm.$v.medicineQuantity.required &&
                              _vm.$v.medicineQuantity.$error,
                            "Se requiere introducir un número decimal":
                              !_vm.$v.medicineQuantity.decimal &&
                              _vm.$v.medicineQuantity.$error
                          },
                          expanded: ""
                        }
                      },
                      [
                        _c(
                          "b-field",
                          {
                            attrs: {
                              type: {
                                "is-danger": _vm.$v.medicineQuantity.$error
                              }
                            }
                          },
                          [
                            _c("p", { staticClass: "control" }, [
                              _c(
                                "span",
                                {
                                  staticClass: "button",
                                  on: {
                                    click: function($event) {
                                      return _vm.unselectMedicine()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "icon is-small" },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "exchange-alt" }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "control" }, [
                              _c("span", { staticClass: "button is-static" }, [
                                _vm._v(
                                  "\n                                Genérico: " +
                                    _vm._s(
                                      _vm.selectedOncologicMedicine.component
                                        .name
                                    ) +
                                    "\n                            "
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("b-input", {
                              directives: [
                                {
                                  name: "cleave",
                                  rawName: "v-cleave",
                                  value: _vm.masks.custom,
                                  expression: "masks.custom"
                                }
                              ],
                              attrs: { type: "text", expanded: "" },
                              model: {
                                value: _vm.$v.medicineQuantity.$model,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.$v.medicineQuantity,
                                    "$model",
                                    typeof $$v === "string" ? $$v.trim() : $$v
                                  )
                                },
                                expression: "$v.medicineQuantity.$model"
                              }
                            }),
                            _vm._v(" "),
                            _c("p", { staticClass: "control" }, [
                              _c("span", { staticClass: "button is-static" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.selectedOncologicMedicine.component_unit
                                      .symbol
                                  )
                                )
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.oncologicMedicineThinner
                      ? _c(
                          "b-field",
                          {
                            attrs: {
                              label: "Diluyente",
                              type: {
                                "is-danger":
                                  _vm.$v.oncologicMedicineThinner.$error
                              },
                              message: [
                                !_vm.$v.oncologicMedicineThinner.required &&
                                _vm.$v.oncologicMedicineThinner.$error
                                  ? "Se requiere introducir y seleccionar el diluyente"
                                  : "Seleccione un diluyente."
                              ],
                              expanded: ""
                            }
                          },
                          [
                            _c(
                              "b-select",
                              {
                                attrs: {
                                  placeholder: "Seleccione el diluyente",
                                  expanded: ""
                                },
                                model: {
                                  value: _vm.oncologicMedicineThinner,
                                  callback: function($$v) {
                                    _vm.oncologicMedicineThinner = $$v
                                  },
                                  expression: "oncologicMedicineThinner"
                                }
                              },
                              _vm._l(
                                _vm.selectedOncologicMedicine
                                  .oncologic_thinners,
                                function(thinner, index) {
                                  return _c(
                                    "option",
                                    {
                                      key: index,
                                      domProps: { value: thinner }
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(thinner.name) +
                                          "\n                        "
                                      )
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.oncologicMedicineThinner &&
                    _vm.oncologicMedicineThinner.id == 1
                      ? _c(
                          "b-field",
                          {
                            attrs: {
                              label: "Trasvase de medicamento",
                              expanded: ""
                            }
                          },
                          [
                            _c("b-field", [
                              _c("p", { staticClass: "control" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "button",
                                    on: { click: _vm.unselectThinner }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "icon is-small" },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "exchange-alt" }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass: "control",
                                  attrs: { expanded: "" }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "button is-static" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.oncologicMedicineThinner.name
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        )
                      : _vm.oncologicMedicineThinner
                      ? _c(
                          "b-field",
                          {
                            attrs: {
                              label: "Trasvase de medicamento",
                              type: {
                                "is-danger":
                                  _vm.$v.oncologicMedicineThinnerQuantity.$error
                              },
                              message: {
                                "Se requiere introducir la cantidad de diluyente":
                                  !_vm.$v.oncologicMedicineThinnerQuantity
                                    .required &&
                                  _vm.$v.oncologicMedicineThinnerQuantity
                                    .$error,
                                "Se requiere introducir un número decimal":
                                  !_vm.$v.oncologicMedicineThinnerQuantity
                                    .decimal &&
                                  _vm.$v.oncologicMedicineThinnerQuantity.$error
                              },
                              expanded: ""
                            }
                          },
                          [
                            _c(
                              "b-field",
                              {
                                attrs: {
                                  type: {
                                    "is-danger":
                                      _vm.$v.oncologicMedicineThinnerQuantity
                                        .$error
                                  }
                                }
                              },
                              [
                                _c("p", { staticClass: "control" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "button",
                                      on: { click: _vm.unselectThinner }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "icon is-small" },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: "exchange-alt" }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "control" }, [
                                  _c(
                                    "span",
                                    { staticClass: "button is-static" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.oncologicMedicineThinner.name
                                          ) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c("b-input", {
                                  directives: [
                                    {
                                      name: "cleave",
                                      rawName: "v-cleave",
                                      value: _vm.masks.custom,
                                      expression: "masks.custom"
                                    }
                                  ],
                                  attrs: { type: "text", expanded: "" },
                                  model: {
                                    value:
                                      _vm.$v.oncologicMedicineThinnerQuantity
                                        .$model,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.$v.oncologicMedicineThinnerQuantity,
                                        "$model",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression:
                                      "$v.oncologicMedicineThinnerQuantity.$model"
                                  }
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "control" }, [
                                  _c(
                                    "span",
                                    { staticClass: "button is-static" },
                                    [_vm._v("ml")]
                                  )
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "b-field",
                      {
                        attrs: { label: "Vía de Administración", expanded: "" }
                      },
                      [
                        _c(
                          "b-select",
                          {
                            attrs: {
                              placeholder: "Seleccione Vía de Administración"
                            },
                            model: {
                              value: _vm.routeAdministration,
                              callback: function($$v) {
                                _vm.routeAdministration = $$v
                              },
                              expression: "routeAdministration"
                            }
                          },
                          [
                            _c("option", { attrs: { value: "INTRAVENOSA" } }, [
                              _vm._v("INTRAVENOSA")
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "INTRATECAL" } }, [
                              _vm._v("INTRATECAL")
                            ]),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "INTRAMUSCULAR" } },
                              [_vm._v("INTRAMUSCULAR")]
                            ),
                            _vm._v(" "),
                            _c(
                              "option",
                              { attrs: { value: "LA QUE EL MÉDICO INDIQUE" } },
                              [_vm._v("LA QUE EL MÉDICO INDIQUE")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.mixtureConcentration
                      ? _c(
                          "div",
                          [
                            _c("br"),
                            _vm._v(" "),
                            _c("b-message", [
                              _c("p", [
                                _vm._v(_vm._s(_vm.mixtureConcentration.message))
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.mixtureAlert
                              ? _c("b-message", { staticClass: "is-warning" }, [
                                  _c("p", [_vm._v(_vm._s(_vm.mixtureAlert))])
                                ])
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c(
                      "b-field",
                      {
                        attrs: {
                          label: "Comentarios",
                          message:
                            "Introduzca comentarios referentes a la mezcla"
                        }
                      },
                      [
                        _c("b-input", {
                          attrs: {
                            type: "textarea",
                            maxlength: "500",
                            placeholder: "Introduzca sus comentarios..."
                          },
                          model: {
                            value: _vm.comments,
                            callback: function($$v) {
                              _vm.comments = $$v
                            },
                            expression: "comments"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.withInfusionSet
                      ? _c(
                          "b-field",
                          [
                            _c(
                              "b-checkbox",
                              {
                                model: {
                                  value: _vm.infusionSet,
                                  callback: function($$v) {
                                    _vm.infusionSet = $$v
                                  },
                                  expression: "infusionSet"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                            Set de infusión\n                    "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("b-field", [
                      _c("p", { staticClass: "control has-text-centered" }, [
                        _c(
                          "button",
                          {
                            staticClass: "button is-primary",
                            attrs: { type: "submit" }
                          },
                          [
                            _vm._v(
                              "\n                            Agregar bolo\n                        "
                            )
                          ]
                        )
                      ])
                    ])
                  ],
                  1
                )
              : _vm._e()
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("div", { staticClass: "level-item" }, [
        _c("h1", { staticClass: "subtitle" }, [
          _vm._v("Agregar bolo al pedido")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }