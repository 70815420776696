var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { novalidate: "true" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submitForm($event)
          }
        }
      },
      [
        _c(
          "b-field",
          {
            attrs: {
              label: "Correo electrónico",
              type: { "is-danger": _vm.$v.email.$error },
              message: {
                "Se requiere introducir el correo electrónico":
                  !_vm.$v.email.required && _vm.$v.email.$error,
                "Se requiere introducir un correo electrónico válido":
                  !_vm.$v.email.email && _vm.$v.email.$error
              },
              "label-position": "on-border",
              expanded: ""
            }
          },
          [
            _c("b-input", {
              attrs: { type: "text" },
              model: {
                value: _vm.$v.email.$model,
                callback: function($$v) {
                  _vm.$set(_vm.$v.email, "$model", $$v)
                },
                expression: "$v.email.$model"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-field",
          {
            attrs: {
              label: "Contraseña",
              type: { "is-danger": _vm.$v.password.$error },
              message: {
                "Se requiere introducir la contraseña":
                  !_vm.$v.password.required && _vm.$v.password.$error
              },
              "label-position": "on-border",
              expanded: ""
            }
          },
          [
            _c("b-input", {
              attrs: { type: "password", "password-reveal": "" },
              model: {
                value: _vm.$v.password.$model,
                callback: function($$v) {
                  _vm.$set(_vm.$v.password, "$model", $$v)
                },
                expression: "$v.password.$model"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("b-field", { attrs: { grouped: "", position: "is-centered" } }, [
          _c("p", { staticClass: "control" }, [
            _c(
              "button",
              { staticClass: "button is-primary", attrs: { type: "submit" } },
              [
                _c("span", [
                  _vm._v(
                    "\n                        Iniciar sesión\n                    "
                  )
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "b-field",
          {
            staticClass: "serbitec-medium-bottom-margin",
            attrs: { grouped: "", position: "is-centered" }
          },
          [
            _c("router-link", { attrs: { to: { path: "/forgot_password" } } }, [
              _vm._v(
                "\n                ¿Olvidaste tu contraseña?\n            "
              )
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "column is-flex is-horizontal-center" }, [
      _c("figure", { staticClass: "image is-256x256" }, [
        _c("img", { attrs: { src: "images/SERBITEC_login.png" } })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }