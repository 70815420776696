<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <div class="level-left">
                    <serbitec-breadcumbs
                        :route="route"
                    />
                </div>
            </div>
            <div class="level-right">
                <a
                @click="addMedicineFormIsActive = true"
                class="button serbitec-small-right-margin">
                    <span class="icon">
                        <font-awesome-icon icon="plus" />
                    </span>
                    <span> Agregar medicamento</span>
                </a>
            </div>
        </nav>
        <hr>
        <serbitec-payer-info
        :payer-id="payerId"></serbitec-payer-info>
        <br>
        <div v-if="addMedicineFormIsActive">
            <serbitec-payer-medicine-form
            :payer-id="payerId"
            :has-close-button="true"
            @close-form="addMedicineFormIsActive = false"
            @success="payerMedicineAdded"
            @error="payerMedicineAddedError"></serbitec-payer-medicine-form>
            <br>
        </div>
        <serbitec-filters
            :active-filters="activeFilters"
            comes-from-page="payerMedicines"
        />
        <br>
        <div class="box">
            <nav class="level">
                <div class="level-left">
                    <b-field
                    message="Nota: La búsqueda se realiza por nombre comercial o componente"
                    label="Búsqueda"
                    label-position="on-border">
                        <b-field>
                            <b-input
                            v-model="searchParam"
                            icon="search"
                            rounded></b-input>
                            <p class="control">
                                <b-button
                                icon-right="times-circle"
                                @click="clearSearchParam"
                                rounded></b-button>
                            </p>
                        </b-field>
                    </b-field>
                </div>
                <div class="level-right">
                    <b-field>
                        <p class="control">
                            <button class="button is-small" @click="confirmDeleteAllDisabled">
                                <span class="icon">
                                    <font-awesome-icon icon="trash-alt" />
                                </span>
                                <span> Eliminar desactivados</span>
                            </button>
                        </p>
                    </b-field>
                </div>
            </nav>
            <b-message><strong>Nota: </strong>Para editar el precio del medicamento introduzca el nuevo precio y presione la tecla <strong>enter.</strong></b-message>
            <serbitec-payer-medicines-table
            :payer-id="payerId"
            :payer-medicines="payerMedicines"
            :payer-medicines-count="payerMedicinesCount"

            :loading.sync="loading"
            :page.sync="page"
            :pageSize="pageSize"

            @page-change="onPageChange"
            @delete-item="deleteItem"
            @toggle-payer-medicine-enabled-state="togglePayerMedicineEnabledState"
            @toggle-payer-medicine-price-per-flask-state="togglePayerMedicinePricePerFlaskState"
            @edit-price="editMedicinePrice"></serbitec-payer-medicines-table>
        </div>
    </div>
</template>

<script>
import serbitecPayerMedicinesTable from '../../../components/dashboard/payers/medicines-table.vue';
import serbitecPayerInfo from '../../../components/dashboard/payers/payer-info.vue';
import serbitecFilters from '../../../components/dashboard/utils/filters.vue';
import serbitecPayerMedicineForm from '../../../components/dashboard/payers/payer-medicine-form.vue'
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';
import axios from 'axios';
import { isEmpty } from 'lodash';

import gql from 'graphql-tag';
import { mapState } from 'vuex';
const query = gql` query ( $search: String, $payer_id: Int, $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String, $is_nutritional: Boolean, $is_oncologic: Boolean, $enabled: Boolean )
{
    payer_medicines( search: $search, payer_id: $payer_id, page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction, is_nutritional: $is_nutritional, is_oncologic: $is_oncologic, enabled: $enabled ) {
        id
        enabled
        price_in_cents_per_ml_or_pz
        is_charged_by_full_medicine_presentation
        payer_medicine_presentations {
            id
        }
        medicine {
            id
            commercial_name
            manufacturer {
                id
                name
            }
            component {
                id
                is_oncologic
                is_nutritional
            }
            component_unit {
                id
                symbol
            }
        }
        payer {
            id
            invoicing_data {
                id
                price_in_cents_per_oncologic_mixture
                price_in_cents_per_nutritional_mixture
            }
        }
    }
    payerMedicinesCount: table_counts( count_type:"payer_medicines", payer_id: $payer_id, search: $search, is_nutritional: $is_nutritional, is_oncologic: $is_oncologic, enabled: $enabled ) {
        payer_medicines
    }
}`;

export default {
    components: {
        serbitecPayerMedicinesTable,
        serbitecPayerInfo,
        serbitecFilters,
        serbitecPayerMedicineForm,
        serbitecBreadcumbs
    },
    computed: {
        ...mapState({
            componentTypes: state => state.recordFilters.payerMedicines.componentTypes,
            enabledPayerMedicines: state => state.recordFilters.payerMedicines.enabledPayerMedicines
        }),
        isOncologic(){
            if ( !isEmpty(this.componentTypes.find( componentType => componentType.name == 'is_oncologic' )) ){
                return true;
            } else if ( !isEmpty(this.componentTypes.find( componentType => componentType.name == 'is_nutritional' )) ){
                return false;
            } else {
                return null;
            }
        },
        isNutritional(){
            if ( !isEmpty(this.componentTypes.find( componentType => componentType.name == 'is_nutritional' )) ){
                return true;
            } else if ( !isEmpty(this.componentTypes.find( componentType => componentType.name == 'is_oncologic' )) ){
                return false;
            } else {
                return null;
            }
        }
    },
    data () {
        return {
            payerId: this.$route.query.payer_id,
            loading: false,
            page: 0,
            pageSize: 10,
            sortField: 'id',
            sortOrder:'asc',
            payerMedicinesCount: null,
            searchParam: null,
            addMedicineFormIsActive: false,
            activeFilters: [ 'componentType', 'enabledPayerMedicines' ],
            route: [
                {
                    label: 'Clientes',
                    to: '/payers',
                    active: false
                },
                {
                    label: 'Medicamentos del cliente',
                    to: '/payers/medicines',
                    active: true
                }
            ]
        };
    },
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        clearSearchParam() {
            this.searchParam = null;
        },
        deleteItem( itemId ) {
            this.makeRequest('/api/payers/medicines/' + itemId, axios.delete, {}, 'deleteItem' );
        },
        togglePayerMedicineEnabledState( payerMedicineId ){
            let payerMedicine = this.payerMedicines.find( payerMedicine => payerMedicine.id == payerMedicineId );
            let enabledState = {
                enabled: payerMedicine.enabled
            };
            this.makeRequest('/api/payers/medicines/' + payerMedicineId, axios.put, enabledState, 'togglePayerMedicineState' );
        },
        togglePayerMedicinePricePerFlaskState( payerMedicineId ){
            let payerMedicine = this.payerMedicines.find( payerMedicine => payerMedicine.id == payerMedicineId );
            let enabledState = {
                is_charged_by_full_medicine_presentation: payerMedicine.is_charged_by_full_medicine_presentation
            };
            this.makeRequest('/api/payers/medicines/' + payerMedicineId, axios.put, enabledState, 'togglePayerMedicineState' );
        },
        async makeRequest( url, method, params, comesFrom ){
            this.loading = true;
            try {
                await method( url, params );
                if ( comesFrom == 'deleteItem' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha eliminado el medicamento'
                    });
                }
                if ( comesFrom == 'togglePayerMedicineState' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha modificado el estado del medicamento'
                    });
                }
                if ( comesFrom == 'deleteAllDisabled' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se han eliminado todos los medicamentos desactivados'
                    });
                }
                if ( comesFrom == 'editMedicinePrice' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha editado el precio del medicamento'
                    });
                }
                this.$apollo.queries.payerMedicines.refetch();
                this.loading = false;
            } catch (error) {
                console.error(error);
                this.$notify({
                    title: '¡Algo ha sucedido!',
                    type: 'error',
                    text: 'Por favor contacte al administrador'
                });
                this.loading = false;
            }
        },
        confirmDeleteAllDisabled(){
            var dialogMessage = '¿Seguro que deseas eliminar los medicamentos? <br> <small>Se eliminarán todos los medicamentos desactivados y no se mostrarán al cliente.</small>';
            this.$buefy.dialog.confirm({
                title: 'Eliminar medicamentos',
                message: dialogMessage,
                confirmText: 'Eliminar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteAllDisabled()
            });
        },
        deleteAllDisabled(){
            this.makeRequest('/api/payers/' + this.payerId + '/medicines/disabled', axios.delete, {}, 'deleteAllDisabled' );
        },
        payerMedicineAdded(){
            this.$notify({
                title: '¡Éxito!',
                type: 'success',
                text: 'Se ha añadido el medicamento al cliente'
            });
            this.$apollo.queries.payerMedicines.refetch();
        },
        payerMedicineAddedError( error ){
            this.$notify({
                title: '¡Algo ha sucedido!',
                type: 'error',
                text: error
            });
        },
        editMedicinePrice( medicineId, newPrice ){
            let params = {
                price_in_cents_per_ml_or_pz: parseInt( ( parseFloat( newPrice ) * 100).toFixed(0) )
            }
            this.makeRequest('/api/payers/medicines/' + medicineId, axios.put, params, 'editMedicinePrice' );
        }
    },
    apollo: {
        payerMedicines: {
            fetchPolicy: 'network-only',
            query: query,
            variables () {
                return {
                    search: this.searchParam,
                    payer_id: this.payerId,
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder,
                    is_oncologic: this.isOncologic,
                    is_nutritional: this.isNutritional,
                    enabled: this.enabledPayerMedicines
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                this.payerMedicinesCount = data.payerMedicinesCount.payer_medicines;
                return data.payer_medicines;
            }
        },
    }
};
</script>