<template>
    <b-autocomplete
    placeholder="Introduzca el nombre y seleccione..."
    v-model="payerName"
    :data="payers"
    :loading="autocompleteIsLoading"
    @select="option => selectPayer(option)" expanded>

        <template slot-scope="props">
            <div class="media">
                <div class="media-content">
                    <strong>{{ props.option.invoicing_data.business_name }}</strong>
                    <br>
                    <p class="has-text-weight-light">RFC: {{ props.option.invoicing_data.rfc }}</p>
                </div>
            </div>
        </template>

        <template slot="empty">No se encontró el cliente...</template>
    </b-autocomplete>
</template>

<script>
import gql from 'graphql-tag';
const query = gql` query ( $search: String )
{
    payers ( search: $search ) {
        id
        invoicing_data {
            id
            business_name
            rfc
        }
    }
}`;

export default {
    data: function () {
        return {
            payers: [],
            payerName: null,
            autocompleteIsLoading: false,
        };
    },
    methods: {
        selectPayer(selectedPayer){
            this.$emit('payer-added', selectedPayer);
        },
    },
    apollo: {
        payers: {
            query: query,
            variables () {
                return {
                    search: this.payerName,
                };
            }
        },
    }
};
</script>