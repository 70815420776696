var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function($event) {
            _vm.loading = $event
          }
        }
      }),
      _vm._v(" "),
      _c("nav", { staticClass: "level" }, [
        _c(
          "div",
          { staticClass: "level-left" },
          [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" })
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.payer
        ? _c("serbitec-payer-form", { attrs: { payer: _vm.payer } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }