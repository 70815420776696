var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-message", [
    _c("nav", { staticClass: "level" }, [
      _c("div", { staticClass: "level-left" }, [
        _c(
          "span",
          { staticClass: "icon is-small" },
          [_c("font-awesome-icon", { attrs: { icon: "user" } })],
          1
        ),
        _vm._v(" "),
        _c("span", { staticStyle: { "padding-left": "15px" } }, [
          _vm._v("\n                Usuario:\n                "),
          _c("strong", [
            _vm._v(
              _vm._s(_vm.medic.first_name) +
                " " +
                _vm._s(_vm.medic.last_name_1) +
                " " +
                _vm._s(_vm.medic.last_name_2)
            )
          ]),
          _vm._v(
            "\n                (Cédula médica: " +
              _vm._s(_vm.medic.medical_card) +
              ")\n            "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" }, [
        !_vm.isFixed
          ? _c("div", { staticClass: "field" }, [
              _c(
                "a",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$emit("medic-unselected")
                    }
                  }
                },
                [
                  _c("span", [_vm._v("Cambiar")]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "icon is-small" },
                    [
                      _c("font-awesome-icon", {
                        attrs: { icon: "exchange-alt" }
                      })
                    ],
                    1
                  )
                ]
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }