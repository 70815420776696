<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <h1 class="title">Usuarios internos</h1>
            </div>
            <div class="level-right">
                <router-link
                    :to="{ path: 'deleted' }"
                    class="button serbitec-small-right-margin"
                    append
                >
                    <span class="icon">
                        <font-awesome-icon icon="user-times" />
                    </span>
                    <span> Usuarios eliminados</span>
                </router-link>
                <a
                    class="button"
                    @click="openInternalUserModal"
                >
                    <span class="icon">
                        <font-awesome-icon icon="plus" />
                    </span>
                    <span> Agregar usuario interno</span>
                </a>
            </div>
        </nav>
        <hr>
        <serbitec-internal-users-table
            :internal-users="internalUsers"
            :internal-users-count="internalUsersCount"

            :loading.sync="loading"
            :page.sync="page"
            :pageSize="limit"
            :sortField.sync="sortField"
            :sortOrder.sync="sortOrder"

            @page-change="onPageChange"
            @sort="onSort"
            @reload-internal-users="() => $apollo.queries.internalUsers.refresh()"
        />
    </div>
</template>

<script>
import serbitecInternalUsersTable from '../../../components/dashboard/internal-users/table.vue';
import serbitecInternalUserModal from '../../../components/dashboard/internal-users/modal.vue';
import gql from 'graphql-tag';
const query = gql` query ( $page: Int, $limit: Int, $order_by: String, $order_direction: String )
{
    users: paginated_users( is_internal:true, page: $page, limit: $limit, order_by: $order_by, order_direction: $order_direction ) {
        total
        data {
            id
            first_name
            last_name_1
            last_name_2
            email
            types {
                id
                name
            }
        }
    }
}`;

export default {
    components: {
        serbitecInternalUsersTable
    },
    data () {
        return {
            loading: false,
            page: 1,
            limit: 10,
            sortField: 'id',
            sortOrder: 'asc',
            internalUsersCount: 0
        };
    },
    methods: {
        openInternalUserModal () {
            this.$buefy.modal.open({
                parent: this,
                component: serbitecInternalUserModal,
                hasModalCard: true,
                trapFocus: true,
                events: {
                    'success': () => this.$apollo.queries.internalUsers.refresh()
                }
            });
        },
        onPageChange( page ) {
            this.page = page;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        }
    },
    apollo: {
        internalUsers: {
            query: query,
            variables () {
                return {
                    page: this.page,
                    limit: this.limit,
                    order_by: this.sortField,
                    order_direction: this.sortOrder
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update(data) {
                this.internalUsersCount = data.users.total;
                data.users.data.map ( user => {
                    const isAdmin = user.types.find((type) => type.id === 1) !== undefined;
                    if ( isAdmin ){
                        const userIndex = data.users.data.indexOf(user);
                        data.users.data.splice(userIndex,1);
                    }
                } );
                return data.users.data;
            }
        },
    }
};
</script>
