<template>
    <div>
        <serbitec-horizontal-general-data
            v-if="Object.keys(patient).length !== 0 && patient.constructor === Object"
            :payer="payer"
            :medic="medic"
            :patient="patient"
        />
        <div v-if="nutritionalOrder">
            <div class="box">
                <div class="columns">
                    <div class="column is-one-third">
                        Volumen total: <strong>{{ totalVolume }}</strong>
                    </div>
                    <div class="column is-one-third">
                        Sobrellenado: <strong>{{ overfilling }}</strong>
                    </div>
                    <div v-if="injectableWater" class="column is-one-third">
                        Agua inyectable: <strong>{{ injectableWater }}</strong>
                    </div>
                </div>
                <serbitec-nutritional-order-data
                :data="nutritionalOrder"></serbitec-nutritional-order-data>
            </div>
            <br>
            <serbitec-delivery-dates-form
            :without-infusion-set="!infusionSet"
            :delivery-dates.sync="deliveryDates"></serbitec-delivery-dates-form>
            <br>
            <div class="box">
                <b-field
                :label="'Comentarios (opcional)'"
                :message="'Introduzca comentarios referentes a la mezcla'">
                    <b-input 
                        v-model="comments"
                        type="textarea"
                        maxlength="500"
                        placeholder="Introduzca sus comentarios...">
                    </b-input>
                </b-field>
            </div>
        </div>
        <b-message v-else class="is-danger">
            No hay ningún componente elegido para preparar una mezcla de nutrición parenteral
            <br>
            <small>Selecciona los componentes y su cantidad en el paso 2</small>
        </b-message>

        <hr>
        <nav class="level">
            <div class="level-left">
                <p class="control">
                    <button class="button" @click="$emit('previous-step')">
                        <span class="icon">
                        <font-awesome-icon icon="caret-left" />
                        </span>
                        <span>Anterior </span>
                    </button>
                </p>
            </div>
            <div class="level-right">
                <p class="control">
                    <button class="button is-primary" @click="submitOrder()" :disabled="!nutritionalOrder">
                        <span>Realizar pedido </span>
                        <span class="icon">
                        <font-awesome-icon icon="check-circle" />
                        </span>
                    </button>
                </p>
            </div>
        </nav>
    </div>
</template>

<script>
import serbitecHorizontalGeneralData from '../../horizontal-general-data.vue';
import serbitecDeliveryDatesForm from "../../delivery-dates-form.vue";
import serbitecNutritionalOrderData from "../nutritional-order-data.vue";
import { merge, concat } from "lodash";
import { mapState } from 'vuex';
import { format } from 'date-fns';
import {
	setRandomId,
	totalMlForMacrocomponent,
	electrolyteInMl,
	aditiveInMl
} from "../../../../../utils";

const today = new Date();
const min = new Date();
min.setHours(today.getHours());
min.setMinutes(today.getMinutes());

export default {
    components: {
        serbitecHorizontalGeneralData,
        serbitecDeliveryDatesForm,
        serbitecNutritionalOrderData
    },
    computed: {
        ...mapState({
            user: state => state.user,
            payer: state => state.nutritionalOrder.payer,
            medic: state => state.nutritionalOrder.medic,
            patient: state => state.nutritionalOrder.patient,
            macronutrients: state => state.nutritionalOrder.macronutrients,
			electrolytes: state => state.nutritionalOrder.electrolytes,
			aditives: state => state.nutritionalOrder.aditives,
			totalVolume: state => state.nutritionalOrder.totalVolume,
			overfilling: state => state.nutritionalOrder.overfilling,
			injectableWater: state => state.nutritionalOrder.injectableWater,
			dangerAlert: state => state.nutritionalOrder.dangerAlert,
            infusionSet: state => state.nutritionalOrder.infusionSet,
            infusionTime: state => state.nutritionalOrder.infusionTime
        }),
        nutritionalOrder() {
			var selectedMacronutrients = this.macronutrients
				.filter(macronutrient => macronutrient.quantity)
				.map(x => merge(x, { type: "Macronutriente" }));
			var selectedElectrolytes = this.electrolytes
				.filter(electrolyte => electrolyte.quantity)
				.map(x => merge(x, { type: "Electrolito" }));
			var selectedAditives = this.aditives
				.filter(aditive => aditive.quantity)
				.map(x => merge(x, { type: "Aditivo" }));

			if (
				selectedMacronutrients.length ||
				selectedElectrolytes.length ||
				selectedAditives.length
			) {
				return concat(
					selectedMacronutrients,
					selectedElectrolytes,
					selectedAditives
				);
			} else {
				return null;
			}
		}
    },
    data () {
        return {
            deliveryDates: [
                {
                    id: setRandomId(),
                    deliveryDate: new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate()
                    ),
                    deliveryHour: min,
                    minDate: new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate()
                    ),
                    infusionSet: false
                }
            ],
            comments: null,
        };
    },
    methods: {
        getRequestParams() {
			const mixture_dates = [];
			this.deliveryDates.map(date => {
				const deliveryDate = {};
				date.deliveryDate.setHours(date.deliveryHour.getHours());
				date.deliveryDate.setMinutes(date.deliveryHour.getMinutes());
				deliveryDate.date = date.deliveryDate;
				deliveryDate.infusion_set = date.infusionSet;
				mixture_dates.push(deliveryDate);
			});

			const mixture_medicines = [];
			this.nutritionalOrder.map(item => {
				const mixtureMedicine = {};
				if (item.type == "Macronutriente") {
					mixtureMedicine.component_id = item.selectedComponent.id;
					mixtureMedicine.multiply_factor_to_selected_unit = parseFloat( item.quantity / totalMlForMacrocomponent( item, this.patient.weight_in_kilograms ) ).toFixed(6);
				} else if (item.type == "Electrolito") {
					mixtureMedicine.component_id = item.id;
					mixtureMedicine.multiply_factor_to_selected_unit = parseFloat( item.quantity / electrolyteInMl( item, this.patient.weight_in_kilograms ) ).toFixed(6);
				} else if (item.type == "Aditivo") {
					mixtureMedicine.component_id = item.id;
					mixtureMedicine.multiply_factor_to_selected_unit = parseFloat( item.quantity / aditiveInMl( item, this.patient.weight_in_kilograms ) ).toFixed(6);
				}
				mixtureMedicine.medicine_quantity = parseFloat( item.quantity / mixtureMedicine.multiply_factor_to_selected_unit ).toFixed(3);
				mixtureMedicine.selected_unit_code = item.unitSymbol;
				mixture_medicines.push(mixtureMedicine);
			});

			return {
                mixture_requester_id: this.user.isMixtureRequester ? this.user.id : null,
                mixture_type_id: 2,
                patient_id: this.patient.id,
                name: this.patient.name,
                diagnosis: this.patient.diagnosis,
                height_in_meters: this.patient.height_in_meters,
                weight_in_kilograms: this.patient.weight_in_kilograms,
                gender: this.patient.gender,
                age_type: this.patient.age_type,
                birth_date: format(new Date(this.patient.birth_date), 'yyyy-MM-dd'),
                file_id: this.patient.file_id,
                location: this.patient.location,
                bed: this.patient.bed,
                medic_id: this.medic.id,
                total_volume_in_ml: this.totalVolume,
                overfill_in_ml: this.overfilling,
                infusion_time_in_h: this.infusionTime,
                comments: this.comments,

                mixture_dates: mixture_dates,
                mixture_medicines: mixture_medicines
			};
		},
		submitOrder() {
			var postParams = this.getRequestParams();
			this.makeRequest( "/api/nutritional/mixtures", this.$axios.post, postParams );
		},
		makeRequest(url, method, params) {
			this.$serbitec.startLoading();
			method(url, params)
				.then(
					function (response) {
						this.$nextTick(() => {
							this.$emit('submit-success', response.data.order.id);
						});
						window.scrollTo(0, 0);
						this.$serbitec.finishLoading();
					}.bind(this)
				)
				.catch(
					function (error) {
						console.error(error);
                        this.$serbitec.finishLoading();
                        this.$notify({
                            title: '¡Algo ha sucedido!',
                            type: 'error',
                            text: error.response.data.error
                        });
						window.scrollTo(0, 0);
					}.bind(this)
				);
		}
    }
};
</script>
