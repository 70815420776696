var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav", { staticClass: "level" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" }, [
          _c("a", { staticClass: "button", on: { click: _vm.addBatch } }, [
            _c(
              "span",
              { staticClass: "icon" },
              [_c("font-awesome-icon", { attrs: { icon: "plus" } })],
              1
            ),
            _vm._v(" "),
            _c("span", [_vm._v(" Agregar lote")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "b-field",
        { attrs: { grouped: "", "group-multiline": "" } },
        [
          _c("serbitec-search", {
            attrs: { search: _vm.search },
            on: {
              "update:search": function($event) {
                _vm.search = $event
              },
              "clear-search": function($event) {
                _vm.search = ""
              }
            }
          }),
          _vm._v(" "),
          _c(
            "b-field",
            { attrs: { label: "Almacén", "label-position": "on-border" } },
            [
              _c(
                "b-field",
                [
                  _c(
                    "b-select",
                    {
                      attrs: {
                        placeholder: "Seleccione el almacén para filtrar",
                        rounded: ""
                      },
                      model: {
                        value: _vm.selectedClientPrefixId,
                        callback: function($$v) {
                          _vm.selectedClientPrefixId = $$v
                        },
                        expression: "selectedClientPrefixId"
                      }
                    },
                    _vm._l(_vm.clientPrefixes, function(client) {
                      return _c(
                        "option",
                        { key: client.id, domProps: { value: client.id } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                client.payer.invoicing_data.business_name
                              ) +
                              "\n                    "
                          )
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "control" },
                    [
                      _c("b-button", {
                        attrs: { "icon-right": "times-circle", rounded: "" },
                        on: {
                          click: function($event) {
                            _vm.selectedClientPrefixId = null
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("serbitec-batches-table", {
        attrs: {
          "warehouse-items": _vm.warehouseItems,
          "warehouse-items-count": _vm.warehouseItemsCount,
          page: _vm.page,
          pageSize: _vm.pageSize,
          sortField: _vm.sortField,
          sortOrder: _vm.sortOrder
        },
        on: {
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:sortField": function($event) {
            _vm.sortField = $event
          },
          "update:sort-field": function($event) {
            _vm.sortField = $event
          },
          "update:sortOrder": function($event) {
            _vm.sortOrder = $event
          },
          "update:sort-order": function($event) {
            _vm.sortOrder = $event
          },
          "page-change": _vm.onPageChange,
          sort: _vm.onSort
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "level-left" }, [
      _c("h1", { staticClass: "title" }, [_vm._v("Inventario")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }