var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("nav", { staticClass: "level" }, [
      _c(
        "div",
        { staticClass: "level-left" },
        [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" })
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _vm.flask
      ? _c(
          "div",
          [
            _c("div", { staticClass: "box" }, [
              _c("h1", { staticClass: "title has-text-centered" }, [
                _vm._v("Lote LO-" + _vm._s(_vm.flask.id))
              ])
            ]),
            _vm._v(" "),
            _c("serbitec-horizontal-order-data", {
              attrs: {
                medic: _vm.flask.order.medic,
                patient: _vm.flask.order.patient
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "box", staticStyle: { "margin-bottom": "15px" } },
              [
                _c("h1", { staticClass: "subtitle" }, [_vm._v("Frascos")]),
                _vm._v(" "),
                _c("serbitec-oncologic-flasks-table", {
                  attrs: { flasks: [_vm.flask] }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("serbitec-quality-inspection", {
              attrs: {
                "quality-inspection-check-list": _vm.qualityInspectionCheckList
              },
              model: {
                value: _vm.qualityComments,
                callback: function($$v) {
                  _vm.qualityComments = $$v
                },
                expression: "qualityComments"
              }
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "b-field",
              {
                staticStyle: { "margin-top": "15px" },
                attrs: { position: "is-centered", grouped: "" }
              },
              [
                _c("p", { staticClass: "control has-text-centered" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button is-primary",
                      attrs: {
                        disabled:
                          _vm.inspectionRejected || !_vm.inspectionCompleted
                      },
                      on: {
                        click: function($event) {
                          return _vm.submitQuality(true)
                        }
                      }
                    },
                    [_vm._v("\n                    Aprobada\n                ")]
                  )
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "control has-text-centered" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button",
                      attrs: { disabled: !_vm.inspectionCompleted },
                      on: {
                        click: function($event) {
                          return _vm.submitQuality(false)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n                    Rechazada\n                "
                      )
                    ]
                  )
                ])
              ]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }