<template>
    <div>
        <div>
            <div v-for="( arrangedElement, index ) in arrangedElements" :key="index">
                <h1 class="has-text-weight-bold is-size-6">{{ arrangedElement.name }}</h1>
                <b-table
                :data="arrangedElement.elements">
                    <template slot-scope="props">
                        <b-table-column v-if="arrangedElement.name == 'Activo'" label="Nombre">
                            <p>{{ props.row.medicine.commercial_name }}</p>
                        </b-table-column>

                        <b-table-column label="Componente">
                            <div>{{ props.row.component.name }}</div>
                        </b-table-column>

                        <b-table-column v-if="props.row.component.name != 'Trasvase'" label="Cantidad cliente" centered>
                            <p v-if="arrangedElement.name == 'Activo'">{{ props.row.medicine_quantity }} {{ props.row.medicine.component_unit.symbol }}</p>
                            <p v-if="arrangedElement.name == 'Diluyente'">{{ props.row.medicine_quantity }} ml</p>
                        </b-table-column>
                    </template>
                </b-table>
            </div>
        </div>
        <br>
        <b-message v-if="hasInfusionSet" class="is-success">
            <nav class="level">
                <div class="level-left">
                    <h1>Set de infusión</h1>
                </div>
                <div class="level-right">
                    <span class="icon">
                        <font-awesome-icon icon="check-circle" />
                    </span>
                    <span> Solicitado</span>
                </div>
            </nav>
        </b-message>
    </div>
</template>

<script>
export default {
    props:['elements'],
    computed: {
        arrangedElements () {
            const array = [];
            array.push( this.getMedicines() );
            array.push( this.getThinner() );
            return array; 
        },
        hasInfusionSet () {
            return ( this.elements.find( element => element.component.component_type.id == 8 ) != undefined );
        }
    },
    methods: {
        getMedicines () {
            const obj = {};
            obj.name = 'Activo';
            obj.elements = this.elements.filter( element =>
                element.component.component_type.id == 1 );
            return obj;
        },
        getThinner () {
            const obj = {};
            obj.name = 'Diluyente';
            obj.elements = this.elements.filter( element => 
                element.component.component_type.id == 5
                || element.component.component_type.id == 13 );
            return obj;
        }
    }
};
</script>