<template>
    <div>
        <nav class="level is-mobile">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title">Control de calidad de mezclas nutricionales</h1>
                </div>
            </div>
            <div class="level-right" />
        </nav>
        <hr>
        <serbitec-quality-mixtures-table
            quality-type="nutritional"
            :mixtures="mixtures"
            :mixtures-count="mixturesCount"

            :page.sync="page"
            :pageSize="pageSize"
            :sortField.sync="sortField"
            :sortOrder.sync="sortOrder"

            @page-change="onPageChange"
            @sort="onSort"
            @print-attempt="updatePrintAttempts"
            @mixture-aconditioned="mixtureAconditioned"
        />
    </div>
</template>

<script>
import serbitecQualityMixturesTable from '../../../components/dashboard/quality-control/quality-mixtures-table.vue';
import axios from 'axios';
import gql from 'graphql-tag';

const query = gql` query ( $page: Int, $results_per_page: Int, $order_by: String, $order_direction: String )
{
    mixtures: nutritional_mixtures( mixture_state_ids: "5,6", page: $page, results_per_page: $results_per_page, order_by: $order_by, order_direction: $order_direction ) {
        id
        printed_opportunities_used
        delivery_date
        comments
        order{
            id
            patient{
                id
                name
            }
            medic {
                id
                payer {
                    id
                    invoicing_data {
                        id
                        business_name
                    }
                }
            }
        }
        state {
            id
        }
    }
    mixturesCount: table_counts( count_type: "nutritional_mixtures", mixture_state_ids: "5,6" ) {
        nutritional_mixtures
    }
}`;

export default {
    components: {
        serbitecQualityMixturesTable
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if ( from.path == '/nutritional_mixtures_quality_control/mixture_inspection' && to.query.success ) {
                vm.$notify({
                    title: '¡Mezcla aprobada!',
                    type: 'success',
                    text: 'Se ha aprobado la mezcla nutricional'
                });
            }
            if ( from.path == '/nutritional_mixtures_quality_control/mixture_inspection' && to.query.rejected ) {
                vm.$notify({
                    title: '¡Mezcla rechazada!',
                    type: 'error',
                    text: 'Se ha rechazado la mezcla nutritional'
                });
            }
        });
    },
    data () {
        return {
            page: 0,
            pageSize: 10,
            sortField: 'id',
            sortOrder:'desc',
            mixturesCount: null
        };
    },
    methods: {
        onPageChange( page ) {
            this.page = page - 1;
        },
        onSort( field, order ) {
            this.sortField = field;
            this.sortOrder = order;
        },
        updatePrintAttempts ( mixtureId ) {
            var url = '/api/nutritional/mixtures/' + mixtureId + '/register_printed';
            this.makeRequest( url, axios.post, [], 'print-attempt' );
        },
        mixtureAconditioned ( mixtureId ) {
            var url = '/api/nutritional/mixtures/' + mixtureId + '/state';
            const requestParams = {
                new_state_id: 9
            };
            this.makeRequest( url, axios.post, requestParams, 'mixture-aconditioned' );
        },
        makeRequest ( url, method, params, comesFrom ) {
            this.$serbitec.startLoading();
            method( url, params )
            .then(function ( ) {
                this.$apollo.queries.mixtures.refetch();
                if ( comesFrom == 'print-attempt' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha generado la etiqueta'
                    });
                } else if ( comesFrom == 'mixture-aconditioned' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha acondicionado la mezcla'
                    });
                }
                this.$serbitec.finishLoading();
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$serbitec.finishLoading();
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
        }
    },
    apollo: {
        mixtures: {
            fetchPolicy: 'cache-and-network',
            query: query,
            variables () {
                return {
                    page: this.page,
                    results_per_page: this.pageSize,
                    order_by: this.sortField,
                    order_direction: this.sortOrder,
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            update ( data ) {
                this.mixturesCount = data.mixturesCount.nutritional_mixtures;
                return data.mixtures;
            }
        },
    }
};
</script>