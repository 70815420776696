<template>
    <div>
        <serbitec-horizontal-general-data
            v-if="Object.keys(patient).length !== 0 && patient.constructor === Object"
            :payer="payer"
            :medic="medic"
            :patient="patient"
        />
        <div v-if="enabledMedicinesCount > 0">
            <nav class="level">
                <div class="level-left">
                </div>
                <div class="level-right">
                    <div class="field">
                        <a v-if="!showMixForm" class="button" @click="showMixForm = !showMixForm">
                            <span class="icon is-small">
                                <font-awesome-icon icon="plus" />
                            </span>
                            <span>Agregar bolo</span>
                        </a>
                        <a v-if="!showFlaskForm" class="button" @click="showFlaskForm = !showFlaskForm">
                            <span class="icon is-small">
                                <font-awesome-icon icon="plus" />
                            </span>
                            <span>Agregar frascos</span>
                        </a>
                    </div>
                </div>
            </nav>
            <div v-if="showMixForm">
                <serbitec-oncologic-mix-form
                    :with-infusion-set="infusionSet"
                    @close-form="showMixForm = false"
                />
                <br>
            </div>
            <div v-if="showFlaskForm">
                <serbitec-oncologic-flask-form
                    @close-form="showFlaskForm = false"
                />
                <br>
            </div>
            <div>
                <serbitec-oncologic-order-items-table />
            </div>
            <hr>
            <serbitec-delivery-dates-form
                :delivery-dates="deliveryDates"
                :without-infusion-set="true"
            />
        </div>
        <div v-else class="serbitec-medium-bottom-margin">
            <b-message
                type="is-danger"
                has-icon
            >
                <strong class="is-size-4">Contacte al administrador</strong>
                <br>
                No tiene ningún medicamento oncológico dado de alta.
                <br>
                <small>Por favor, contacte al administrador para que realice los ajustes pertinentes.</small>
            </b-message>
        </div>
        <hr>
        <nav class="level">
            <div class="level-left">
                <p class="control">
                    <button class="button" @click="$emit('previous-step')">
                        <span class="icon">
                        <font-awesome-icon icon="caret-left" />
                        </span>
                        <span>Anterior </span>
                    </button>
                </p>
            </div>
            <div class="level-right">
                <p class="control">
                    <button
                        class="button is-primary"
                        @click="submitOrder"
                        :disabled="orderIsEmpty"
                    >
                        <span>Realizar orden </span>
                        <span class="icon">
                            <font-awesome-icon icon="check-circle" />
                        </span>
                    </button>
                </p>
            </div>
        </nav>
    </div>
</template>

<script>
import serbitecHorizontalGeneralData from '../../horizontal-general-data.vue';
import serbitecOncologicMixForm from '../oncologic-mix-form.vue';
import serbitecOncologicFlaskForm from '../oncologic-flask-form.vue';
import serbitecOncologicOrderItemsTable from '../oncologic-order-items-table.vue';
import serbitecDeliveryDatesForm from '../../delivery-dates-form.vue';
import { setRandomId } from "../../../../../utils";
import { mapState } from 'vuex';
import gql from 'graphql-tag';
import { format } from 'date-fns';

const componentQuery = gql` query components( $component_type_id: Int, $enabled_for_payer_id: Int, $payer_id: Int )
{
    components(component_type_id: $component_type_id, enabled_for_payer_id: $enabled_for_payer_id) {
        id
    }
    enabledMedicinesCount: table_counts(count_type:"payer_medicines", payer_id: $payer_id, is_oncologic: true, enabled: true) {
        payer_medicines
    }
}`;

export default {
    components: {
        serbitecHorizontalGeneralData,
        serbitecOncologicMixForm,
        serbitecOncologicFlaskForm,
        serbitecOncologicOrderItemsTable,
        serbitecDeliveryDatesForm
    },
    computed: {
        ...mapState({
            user: state => state.user,
            payer: state => state.oncologicOrder.payer,
            medic: state => state.oncologicOrder.medic,
            patient: state => state.oncologicOrder.patient,
            order: state => state.oncologicOrder,
            infusionSet: state => state.nutritionalOrder.infusionSet
        }),
        orderIsEmpty () {
            if ( ( this.order.mixtures === undefined || this.order.mixtures == 0 ) && ( this.order.flasks === undefined || this.order.flasks == 0 ) ) {
                return true;
            } else {
                return false;
            }
        }
    },
    data () {
        const today = new Date();
        const min = new Date();
        min.setHours(today.getHours());
        min.setMinutes(today.getMinutes());

        return {
            showMixForm: false,
            showFlaskForm: false,
            enabledMedicinesCount: 0,
            deliveryDates: [
                {
                    id: setRandomId(),
                    deliveryDate: new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate()
                    ),
                    deliveryHour: min,
                    minDate: new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        today.getDate()
                    )
                }
            ]
        };
    },
    methods: {
        getRequestParams () {
            const mixture_dates = [];
            this.deliveryDates.map( date => {
				let deliveryDate = null;
				date.deliveryDate.setHours(date.deliveryHour.getHours());
                date.deliveryDate.setMinutes(date.deliveryHour.getMinutes());
                deliveryDate = date.deliveryDate;
				mixture_dates.push(deliveryDate);
			});

            return {
                mixture_requester_id: this.user.isMixtureRequester ? this.user.id : null,
                mixture_type_id: 1,
                name: this.patient.name,
                diagnosis: this.patient.diagnosis,
                height_in_meters: this.patient.height_in_meters,
                weight_in_kilograms: this.patient.weight_in_kilograms,
                body_surface_in_square_meters: this.patient.body_surface_in_square_meters,
                gender: this.patient.gender,
                age_type: this.patient.age_type,
                birth_date: format(new Date(this.patient.birth_date), 'yyyy-MM-dd'),
                file_id: this.patient.file_id,
                location: this.patient.location,
                bed: this.patient.bed,
                medic_id: this.medic.id,
                treatment_days : this.patient.treatment_days,
                section : this.patient.section,

                delivery_dates: mixture_dates,
                mixtures: this.order.mixtures,
                flasks: this.order.flasks
            };
        },
        submitOrder () {
            var postParams = this.getRequestParams();
            this.makeRequest('/api/oncologic/orders', this.$axios.post, postParams);
        },
        makeRequest(url, method, params) {
			this.$serbitec.startLoading();
			method(url, params)
            .then(
                function (response) {
                    this.$nextTick(() => {
                        this.$emit('submit-success', response.data.success);
                    });
                    window.scrollTo(0, 0);
                    this.$serbitec.finishLoading();
                }.bind(this)
            )
            .catch(
                function (error) {
                    console.error(error);
                    this.$serbitec.finishLoading();
                    this.$notify({
                        title: '¡Algo ha sucedido!',
                        type: 'error',
                        text: error.response.data.error
                    });
                    window.scrollTo(0, 0);
                }.bind(this)
            );
		}
    },
    apollo: {
        infusionSet: {
			manual: true,
			query: componentQuery,
			variables() {
				return {
                    component_type_id: 8,
                    enabled_for_payer_id: this.payer.id,
				};
			},
			result({ data, loading }) {
				if (!loading) {
                    this.enabledMedicinesCount = data.enabledMedicinesCount.payer_medicines;
					this.$store.commit('nutritionalOrder/UPDATE_INFUSION_SET', data.components);
				}
			}
		}
    }
};
</script>