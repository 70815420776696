<template>
    <div class="box">
        <b-table
            :data="payerUsers"
            :loading="loading"

            paginated
            backend-pagination
            :total="payerUsersCount"
            :per-page="pageSize"
            @page-change="changePage"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="sort">

            <template slot-scope="props">
                <b-table-column field="first_name" label="Nombre" sortable>
                    {{ props.row.first_name }}
                </b-table-column>

                <b-table-column field="last_name_1" label="Apellido paterno" sortable>
                    {{ props.row.last_name_1 }}
                </b-table-column>

                <b-table-column field="last_name_2" label="Apellido materno" sortable>
                    {{ props.row.last_name_2 }}
                </b-table-column>

                <b-table-column label="Tipo de usuario" centered>
                    <b-tag v-for="type in props.row.types" :key="type.id">
                        {{ type.name }}
                    </b-tag>
                </b-table-column>

                <b-table-column label="Editar" centered>
                    <router-link :to="{ path: 'edit', query: { user_id: props.row.id, payer_id: payerId } }" class="button is-light is-small" append>
                        <span class="icon is-medium">
                            <font-awesome-icon icon="edit" />
                        </span>
                    </router-link>
                </b-table-column>
                <b-table-column label="Resetear contraseña" centered>
                    <a @click="passwordReset( props.row.id )" class="button is-light is-small">
                        <span class="icon is-medium">
                            <font-awesome-icon icon="key" />
                        </span>
                    </a>
                </b-table-column>
                <b-table-column label="Eliminar" centered>
                    <a class="button is-light is-small is-danger" @click="confirmDeleteMedicine( props.row.id )">
                        <span class="icon is-medium">
                            <font-awesome-icon icon="trash-alt" />
                        </span>
                    </a>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay usuarios registrados para este cliente.</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    props: [
        'payerUsers',
        'loading',
        'payerUsersCount',
        'page',
        'pageSize',
        'sortField',
        'sortOrder',
        'payerId'
    ],
    methods: {
        changePage ( page ) {
            this.$emit('page-change', page);
        },
        sort ( field, order ) {
            this.$emit('sort', field, order);
        },
        passwordReset( userId ){
            var dialogMessage = '¿Seguro que deseas resetear la contraseña? <br> <small>El usuario ya no podrá utilizar su contraseña anterior y se mandará un correo al usuario con su nueva contraseña.</small>';
            this.$buefy.dialog.confirm({
                title: 'Reestablecer contraseña de usuario',
                message: dialogMessage,
                confirmText: 'Reestablecer',
                type: 'is-warning',
                hasIcon: true,
                onConfirm: () => this.$emit( 'reset-password', userId )
            });
        },
        confirmDeleteMedicine ( userId ) {
            var dialogMessage = '¿Seguro que deseas eliminar el usuario? <br> <small>El usuario eliminado no tendrá acceso al sistema.</small>';
            this.$buefy.dialog.confirm({
                title: 'Eliminar usuario',
                message: dialogMessage,
                confirmText: 'Eliminar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.$emit( 'delete-user', userId )
            });
        },
    }
};
</script>