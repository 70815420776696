var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      Object.keys(_vm.patient).length !== 0 &&
      _vm.patient.constructor === Object
        ? _c("serbitec-horizontal-general-data", {
            attrs: { payer: _vm.payer, medic: _vm.medic, patient: _vm.patient }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.nutritionalOrder
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "box" },
                [
                  _c("div", { staticClass: "columns" }, [
                    _c("div", { staticClass: "column is-one-third" }, [
                      _vm._v("\n                    Volumen total: "),
                      _c("strong", [_vm._v(_vm._s(_vm.totalVolume))])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "column is-one-third" }, [
                      _vm._v("\n                    Sobrellenado: "),
                      _c("strong", [_vm._v(_vm._s(_vm.overfilling))])
                    ]),
                    _vm._v(" "),
                    _vm.injectableWater
                      ? _c("div", { staticClass: "column is-one-third" }, [
                          _vm._v("\n                    Agua inyectable: "),
                          _c("strong", [_vm._v(_vm._s(_vm.injectableWater))])
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("serbitec-nutritional-order-data", {
                    attrs: { data: _vm.nutritionalOrder }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("serbitec-delivery-dates-form", {
                attrs: {
                  "without-infusion-set": !_vm.infusionSet,
                  "delivery-dates": _vm.deliveryDates
                },
                on: {
                  "update:deliveryDates": function($event) {
                    _vm.deliveryDates = $event
                  },
                  "update:delivery-dates": function($event) {
                    _vm.deliveryDates = $event
                  }
                }
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "box" },
                [
                  _c(
                    "b-field",
                    {
                      attrs: {
                        label: "Comentarios (opcional)",
                        message: "Introduzca comentarios referentes a la mezcla"
                      }
                    },
                    [
                      _c("b-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "500",
                          placeholder: "Introduzca sus comentarios..."
                        },
                        model: {
                          value: _vm.comments,
                          callback: function($$v) {
                            _vm.comments = $$v
                          },
                          expression: "comments"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("b-message", { staticClass: "is-danger" }, [
            _vm._v(
              "\n        No hay ningún componente elegido para preparar una mezcla de nutrición parenteral\n        "
            ),
            _c("br"),
            _vm._v(" "),
            _c("small", [
              _vm._v("Selecciona los componentes y su cantidad en el paso 2")
            ])
          ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("nav", { staticClass: "level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c("p", { staticClass: "control" }, [
            _c(
              "button",
              {
                staticClass: "button",
                on: {
                  click: function($event) {
                    return _vm.$emit("previous-step")
                  }
                }
              },
              [
                _c(
                  "span",
                  { staticClass: "icon" },
                  [_c("font-awesome-icon", { attrs: { icon: "caret-left" } })],
                  1
                ),
                _vm._v(" "),
                _c("span", [_vm._v("Anterior ")])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" }, [
          _c("p", { staticClass: "control" }, [
            _c(
              "button",
              {
                staticClass: "button is-primary",
                attrs: { disabled: !_vm.nutritionalOrder },
                on: {
                  click: function($event) {
                    return _vm.submitOrder()
                  }
                }
              },
              [
                _c("span", [_vm._v("Realizar pedido ")]),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "icon" },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "check-circle" } })
                  ],
                  1
                )
              ]
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }