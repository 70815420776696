<template>
    <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">
                Agregar diluyente
            </p>
        </header>
        <section class="modal-card-body">
            <b-field
                label="Diluyente"
                label-position="on-border"
                :type="{'is-danger' : $v.selectedThinner.$error }"
                :message="{
                    'Se requiere introducir y seleccionar el diluyente para reconstitución' : !$v.selectedThinner.required && $v.selectedThinner.$error
                }"
                expanded
            >
                <serbitec-component-select
                    :selected-component="selectedThinner"
                    with-component-type-ids="6,5,13"
                    @component-selected="(thinner) => selectedThinner = thinner"
                />
            </b-field>
            <b-field
            class="serbitec-small-top-margin"
            label="Estabilidad a temperatura ambiente"
            label-position="on-border"
            :type="{'is-danger' : $v.ambientStability.$error }"
            :message="{
                'Se requiere introducir la estabilidad a temperatura ambiente' : !$v.ambientStability.required && $v.ambientStability.$error,
                'Se requiere introducir un número decimal' : !$v.ambientStability.decimal && $v.ambientStability.$error
            }"
            expanded>
                <b-field
                :type="{'is-danger' : $v.ambientStability.$error }">
                    <b-input
                    v-model.trim="$v.ambientStability.$model"
                    type="text"
                    expanded>
                    </b-input>
                    <p class="control">
                        <span class="button is-static">h</span>
                    </p>
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                label="Estabilidad en refrigeración"
                label-position="on-border"
                :type="{'is-danger' : $v.cooledStability.$error }"
                :message="{
                    'Se requiere introducir la estabilidad en refrigeración' : !$v.cooledStability.required && $v.cooledStability.$error,
                    'Se requiere introducir un número decimal' : !$v.cooledStability.decimal && $v.cooledStability.$error
                }"
                expanded>
                <b-field
                :type="{'is-danger' : $v.cooledStability.$error }">
                    <b-input
                    v-model.trim="$v.cooledStability.$model"
                    type="text"
                    expanded>
                    </b-input>
                    <p class="control">
                        <span class="button is-static">h</span>
                    </p>
                </b-field>
            </b-field>
            <hr>
            <h1 class="subtitle is-6">Información adicional</h1>
            <b-field
                class="serbitec-small-top-margin"
                label="Concentración mínima recomendada"
                label-position="on-border"
                expanded>
                <b-field>
                    <b-input
                    v-model="minimumConcentrationRecommended"
                    type="text"
                    expanded>
                    </b-input>
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                label="Concentración máxima recomendada"
                label-position="on-border"
                expanded>
                <b-field>
                    <b-input
                    v-model="maximumConcentrationRecommended"
                    type="text"
                    expanded>
                    </b-input>
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                grouped
                group-multiline
            >
                <b-field
                :label="'Velocidad de infusión mínima'"
                label-position="on-border"
                expanded>
                    <b-input
                        v-model="minInfusionSpeed"
                        placeholder="Mínima"
                        type="text"
                        expanded>
                    </b-input>
                </b-field>
                <b-field
                :label="'Máxima'"
                label-position="on-border"
                expanded>
                    <b-input
                        name="max_infusion_speed"
                        v-model="maxInfusionSpeed"
                        placeholder="Máxima"
                        type="text"
                        expanded>
                    </b-input>
                </b-field>
                <b-field
                    v-if="minInfusionSpeed || maxInfusionSpeed"
                    :label="'Unidad'"
                    label-position="on-border"
                    :type="{'is-danger' : $v.selectedUnit.$error }"
                    :message="{
                        'Se requiere la unidad' : !$v.selectedUnit.required && $v.selectedUnit.$error,
                    }"
                    expanded
                >
                        <serbitec-unit-select
                            :selectedUnit="selectedUnit"
                            @unit-selected="(unit) => selectedUnit = unit"
                        />
                </b-field>
            </b-field>
            <b-field
                class="serbitec-small-top-margin"
                label="Referencia"
                label-position="on-border"
                message="Introduzca la referencia de la cual se obtuvo la información adicional"
                expanded>
                <b-field>
                    <b-input
                    v-model="reference"
                    type="text"
                    expanded>
                    </b-input>
                </b-field>
            </b-field>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button" @click="$parent.close()">
                Cancelar
            </button>
            <button class="button is-primary" @click="submitForm">
                Agregar
            </button>
        </footer>
    </div>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators';
import serbitecComponentSelect from '../../utils/select/components.vue';
import serbitecUnitSelect from '../../utils/select/unit.vue';

export default {
    props: {
        medicineId: {
            type: [Number, String],
            default: 0 | ''
        }
    },
    components: {
        serbitecComponentSelect,
        serbitecUnitSelect
    },
    data () {
        return {
            selectedThinner: {},
            selectedUnit: {},
            ambientStability: '',
            cooledStability: '',
            minimumConcentrationRecommended: '',
            maximumConcentrationRecommended: '',
            minInfusionSpeed: '',
            maxInfusionSpeed: '',
            reference: '',
        };
    },
    methods: {
        submitForm () {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.addThinner();
            }
        },
        getRequestParams () {
            return {
                thinner_id: this.selectedThinner.id,
                stability_ambient_in_hours: this.ambientStability,
                stability_cooled_in_hours: this.cooledStability,
                min_recommended_concentration: this.minimumConcentrationRecommended,
                max_recommended_concentration: this.maximumConcentrationRecommended,
                min_infusion_speed: this.minInfusionSpeed,
                max_infusion_speed: this.maxInfusionSpeed,
                infusion_speed_unit_id: this.selectedUnit ? this.selectedUnit.id : '',
                reference: this.reference,
            };
        },
        addThinner () {
            var postParams = this.getRequestParams();
            var url = '/api/medicines/' + this.medicineId + '/oncologic_thinners';
            this.makeRequest(url, this.$axios.post, postParams);
        },
        makeRequest ( url, method, params ) {
            this.$serbitec.startLoading();
            method(url,params)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha añadido el diluyente'
                });
                this.$serbitec.finishLoading();
                this.$emit('success');
                this.$emit('close');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    },
    validations: function () {
        if ( this.minInfusionSpeed || this.maxInfusionSpeed ) {
            return {
                selectedThinner: {
                    required
                },
                ambientStability: {
                    required,
                    decimal
                },
                cooledStability: {
                    required,
                    decimal
                },
                selectedUnit: {
                    required
                }
            };
        } else {
            return {
                selectedThinner: {
                    required
                },
                ambientStability: {
                    required,
                    decimal
                },
                cooledStability: {
                    required,
                    decimal
                }
            };
        }
    }
};
</script>