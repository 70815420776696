<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right">
                <router-link
                    :to="{ path: '/nutritional_mixtures_authorizer/authorize', query: { mixtureId: mixtureId } }"
                    class="button"
                >
                    <span class="icon">
                        <font-awesome-icon icon="times-circle" />
                    </span>
                    <span> Cancelar edición</span>
                </router-link>
            </div>
        </nav>
        <hr>
        <div v-if="medic.payer">
            <div class="box">
                <h1 class="title has-text-centered">Lote LN-{{ mixtureId }}</h1>
            </div>
            <serbitec-horizontal-order-data
            :medic="medic"
            :patient="patient"></serbitec-horizontal-order-data>
            <serbitec-water-form
            :computed-total-volume="computedTotalVolume"></serbitec-water-form>
            <serbitec-water-alerts
            :computed-total-volume="computedTotalVolume"></serbitec-water-alerts>
            <br>
            <serbitec-macronutrients-form></serbitec-macronutrients-form>
            <serbitec-electrolytes-form></serbitec-electrolytes-form>
            <serbitec-electrolytes-alerts
            :electrolytes="electrolytes"
            :computed-total-volume="computedTotalVolume"></serbitec-electrolytes-alerts>
            <serbitec-aditives-form></serbitec-aditives-form>
            <serbitec-additional-information
            :computed-total-volume="computedTotalVolume"></serbitec-additional-information>
            <br>
            <hr>
            <br>
            <serbitec-delivery-dates-form
            :comes-from-edit="true"
            :delivery-dates.sync="deliveryDates"></serbitec-delivery-dates-form>
            <br>
            <div class="box">
                <b-field
                :label="'Comentarios (opcional)'"
                :message="'Introduzca comentarios referentes a la mezcla'">
                    <b-input 
                        v-model="comments"
                        type="textarea"
                        maxlength="500"
                        placeholder="Introduzca sus comentarios...">
                    </b-input>
                </b-field>
            </div>
            <b-field style="margin-top:25px" position="is-centered" grouped>
                <p class="control has-text-centered">
                    <button @click="editAndAuthorizeMixture" class="button is-primary" :disabled="dangerAlert">
                        Editar y autorizar orden
                    </button>
                </p>
            </b-field>
        </div>
    </div>
</template>

<script>
import { setRandomId, totalMlForMacrocomponent, electrolyteInMl, aditiveInMl } from '../../../utils';
import { merge, concat } from 'lodash';
import serbitecHorizontalOrderData from '../../../components/dashboard/utils/horizontal-order-data.vue';
import serbitecMacronutrientsForm from '../../../components/dashboard/requests/nutritional-mixtures/macronutrients-form.vue';
import serbitecElectrolytesForm from '../../../components/dashboard/requests/nutritional-mixtures/electrolytes-form.vue';
import serbitecAditivesForm from '../../../components/dashboard/requests/nutritional-mixtures/aditives-form.vue';
import serbitecAdditionalInformation from '../../../components/dashboard/requests/nutritional-mixtures/additional-information.vue';
import serbitecElectrolytesAlerts from '../../../components/dashboard/requests/nutritional-mixtures/electrolytes-alerts.vue';        
import serbitecDeliveryDatesForm from '../../../components/dashboard/requests/delivery-dates-form.vue';
import serbitecWaterForm from "../../../components/dashboard/requests/nutritional-mixtures/water-form.vue";
import serbitecWaterAlerts from "../../../components/dashboard/requests/nutritional-mixtures/water-alerts.vue";
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';

import axios from 'axios';

import parseISO from 'date-fns/parseISO';
import gql from 'graphql-tag';
import { mapState } from 'vuex';
const query = gql` query ( $id: Int! )
{
    nutritional_mixture( id: $id ) {
        id
        delivery_date
        total_volume_in_ml
        overfill_in_ml
        infusion_time_in_h
        comments
        order {
            id
            medic {
                id
                first_name
                last_name_1
                last_name_2
                email
                medical_card
                payer {
                    id
                    invoicing_data {
                        id
                        business_name
                    }
                }
            }
            patient {
                id
                name
                diagnosis
                height_in_meters
                weight_in_kilograms
                birth_date
                file_id
                location
                bed
            }
        }
        elements {
            id
            medicine_quantity
            multiply_factor_to_selected_unit
            selected_unit_code
            component {
                id
                name
                component_type {
                    id
                }
                nutritional_field {
                    id
                    ml_to_g_factor
                    energy_input_in_kcal
                    non_protein_energy_input_in_kcal
                    grams_of_nitrogen
                    grams_of_aminoacid
                    osmotic_concentration_in_mosmol_l
                    na_in_meq
                    k_in_meq
                    mg_in_meq
                    ca_in_meq
                    po4_in_meq
                    ace_in_meq
                    cl_in_meq
                }
            }
        }
    }
}`;

const componentsQuery = gql` query components( $component_type_id: Int )
{
    components( component_type_id: $component_type_id ) {
        id
        name
        component_type {
            id
        }
        nutritional_field {
            id
            ml_to_g_factor
            energy_input_in_kcal
            non_protein_energy_input_in_kcal
            grams_of_nitrogen
            grams_of_aminoacid
            osmotic_concentration_in_mosmol_l
            na_in_meq
            k_in_meq
            mg_in_meq
            ca_in_meq
            po4_in_meq
            ace_in_meq
            cl_in_meq
        }
    }
}`;

function initialDataSate () {
    return {
        macronutrientsFormKey: setRandomId(),
        deliveryDates: [],
        comments: null,
        route: [
            {
                label: 'Revisión de mezclas nutricionales',
                to: '/nutritional_mixtures_authorizer',
                active: false
            },
            {
                label: 'Editar',
                to: '/nutritional_mixtures_authorizer/edit',
                active: true
            }
        ]
    };
}
export default {
    props: {
        mixtureId: {
            type: [Number, String],
            default: 0 | '0'
        }
    },
    components: {
        serbitecHorizontalOrderData,
        serbitecMacronutrientsForm,
        serbitecElectrolytesForm,
        serbitecAditivesForm,
        serbitecAdditionalInformation,
        serbitecElectrolytesAlerts,
        serbitecDeliveryDatesForm,
        serbitecWaterForm,
        serbitecWaterAlerts,
        serbitecBreadcumbs
    },
    beforeMount () {
        this.$store.commit('nutritionalOrder/RESET_ORDER');
    },
    data: initialDataSate,
    computed: {
        ...mapState({
            patient: state => state.nutritionalOrder.patient,
            medic: state => state.nutritionalOrder.medic,
            infusionTime: state => state.nutritionalOrder.infusionTime,
			macronutrients: state => state.nutritionalOrder.macronutrients,
			electrolytes: state => state.nutritionalOrder.electrolytes,
			aditives: state => state.nutritionalOrder.aditives,
			totalVolume: state => state.nutritionalOrder.totalVolume,
			overfilling: state => state.nutritionalOrder.overfilling,
			injectableWater: state => state.nutritionalOrder.injectableWater,
			dangerAlert: state => state.nutritionalOrder.dangerAlert
        }),
        computedTotalVolume () {
                var volumeAccumulator = 0.0;
                this.macronutrients.filter( macronutrient => macronutrient.quantity ).map( usedMacronutrient => {
                    volumeAccumulator = volumeAccumulator + parseFloat( totalMlForMacrocomponent( usedMacronutrient, this.patient.weight_in_kilograms ));
                });

                this.electrolytes.filter( electrolyte => electrolyte.quantity ).map( usedElectrolyte => {
                    volumeAccumulator = volumeAccumulator + parseFloat( electrolyteInMl( usedElectrolyte, this.patient.weight_in_kilograms ));
                });

                this.aditives.filter( aditive => aditive.quantity ).map( usedAditive => {
                    volumeAccumulator = volumeAccumulator + parseFloat( aditiveInMl( usedAditive ));
                });

                return volumeAccumulator.toFixed(2);
        },
        nutritionalOrder () {
            var selectedMacronutrients = this.macronutrients.filter( macronutrient => macronutrient.quantity ).map( x => merge(x, { type: 'Macronutriente' } ) );
            var selectedElectrolytes = this.electrolytes.filter( electrolyte => electrolyte.quantity ).map( x => merge(x, { type: 'Electrolito' } ) );
            var selectedAditives = this.aditives.filter( aditive => aditive.quantity ).map( x => merge(x, { type: 'Aditivo' } ) );

            if ( selectedMacronutrients.length || selectedElectrolytes.length || selectedAditives.length ) {
                return concat(selectedMacronutrients, selectedElectrolytes, selectedAditives);
            } else {
                return null;
            }
        },
    },
    methods:{
        getRequestParams () {
            this.deliveryDates[0].deliveryDate.setHours(this.deliveryDates[0].deliveryHour.getHours());
            this.deliveryDates[0].deliveryDate.setMinutes(this.deliveryDates[0].deliveryHour.getMinutes());

            const mixture = {};
            mixture.id = this.mixtureId;
            mixture.delivery_date = this.deliveryDates[0].deliveryDate;
            mixture.total_volume_in_ml = this.totalVolume;
            mixture.overfill_in_ml = this.overfilling;
            mixture.infusion_time_in_h = this.infusionTime;
            mixture.infusion_set = this.deliveryDates[0].infusionSet;
            mixture.comments = this.comments;

            const medicines = [];
            this.nutritionalOrder.map(item => {
				const mixtureMedicine = {};
				if (item.type == "Macronutriente") {
					mixtureMedicine.component_id = item.selectedComponent.id;
					mixtureMedicine.multiply_factor_to_selected_unit = parseFloat( item.quantity / totalMlForMacrocomponent( item, this.patient.weight_in_kilograms ) ).toFixed(6);
				} else if (item.type == "Electrolito") {
					mixtureMedicine.component_id = item.id;
					mixtureMedicine.multiply_factor_to_selected_unit = parseFloat( item.quantity / electrolyteInMl( item, this.patient.weight_in_kilograms ) ).toFixed(6);
				} else if (item.type == "Aditivo") {
					mixtureMedicine.component_id = item.id;
					mixtureMedicine.multiply_factor_to_selected_unit = parseFloat( item.quantity / aditiveInMl( item, this.patient.weight_in_kilograms ) ).toFixed(6);
				}
				mixtureMedicine.medicine_quantity = parseFloat( item.quantity / mixtureMedicine.multiply_factor_to_selected_unit ).toFixed(3);
				mixtureMedicine.selected_unit_code = item.unitSymbol;
				medicines.push(mixtureMedicine);
			});
            mixture.medicines = medicines;

            return mixture;
        },
        editAndAuthorizeMixture () {
            this.$serbitec.startLoading();
            var postParams = this.getRequestParams();
            this.makeRequest('/api/nutritional/mixtures/edit_and_authorize', axios.post, postParams);
        },
        makeRequest ( url, method, params ) {
            method(url,params)
            .then(function () {
                this.resetVuexStates();
                this.$serbitec.finishLoading();
                this.$router.push({ path: '/nutritional_mixtures_authorizer', query: { success: true } });
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$serbitec.finishLoading();
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
            }.bind(this));
        },
        resetVuexStates () {
			this.$store.commit("nutritionalOrder/RESET_ORDER");
        }
    },
    apollo: {
        aminoacids: {
			manual: true,
			query: componentsQuery,
			variables: {
				component_type_id: 2
			},
			result({ data, loading }) {
				if (!loading) {
					data.componentTypeId = 2;
					this.$store.commit('nutritionalOrder/SET_COMPONENT_IN_MACRONUTRIENT', data);
				}
			},
		},
		dextrose: {
			manual: true,
			query: componentsQuery,
			variables: {
				component_type_id: 3
			},
			result({ data, loading }) {
				if (!loading) {
					data.componentTypeId = 3;
					this.$store.commit('nutritionalOrder/SET_COMPONENT_IN_MACRONUTRIENT', data);
				}
			},
		},
		lipids: {
			manual: true,
			query: componentsQuery,
			variables: {
				component_type_id: 4
			},
			result({ data, loading }) {
				if (!loading) {
					data.componentTypeId = 4;
					this.$store.commit('nutritionalOrder/SET_COMPONENT_IN_MACRONUTRIENT', data);
				}
			},
		},
		sodiumChloride: {
			manual: true,
			query: componentsQuery,
			variables: {
				component_type_id: 5
			},
			result({ data, loading }) {
				if (!loading) {
					data.componentTypeId = 5;
					this.$store.commit('nutritionalOrder/SET_COMPONENT_IN_MACRONUTRIENT', data);
				}
			},
		},
		threeChamberBag: {
			manual: true,
			query: componentsQuery,
			variables: {
				component_type_id: 7
			},
			result({ data, loading }) {
				if (!loading) {
					data.componentTypeId = 7;
					this.$store.commit('nutritionalOrder/SET_COMPONENT_IN_MACRONUTRIENT', data);
				}
			},
		},
		electrolytes: {
			manual: true,
			query: componentsQuery,
			variables: {
				component_type_id: 10,
			},
			result({ data, loading }) {
				if (!loading) {
					this.$store.commit('nutritionalOrder/SET_ELECTROLYTES', data.components);
				}
			},
		},
		aditives: {
			manual: true,
			query: componentsQuery,
			variables: {
				component_type_id: 11,
			},
			result({ data, loading }) {
				if (!loading) {
					this.$store.commit('nutritionalOrder/SET_ADITIVES', data.components);
				}
			},
		},
        order: {
            fetchPolicy: 'no-cache',
            query: query,
            manual: true,
            variables () {
                return {
                    id: this.mixtureId
                };
            },
            watchLoading ( isLoading ) {
                isLoading ? this.$serbitec.startLoading() : this.$serbitec.finishLoading();
            },
            result ( { data, loading } ) {
                if (!loading) {
                    const deliveryDate = {};
                    this.$store.commit('nutritionalOrder/UPDATE_PATIENT', data.nutritional_mixture.order.patient);
                    this.$store.commit('nutritionalOrder/UPDATE_MEDIC', data.nutritional_mixture.order.medic);
                    this.$store.commit('nutritionalOrder/UPDATE_TOTAL_VOLUME', data.nutritional_mixture.total_volume_in_ml );
                    this.$store.commit('nutritionalOrder/UPDATE_OVERFILLING', data.nutritional_mixture.overfill_in_ml );
                    this.$store.commit('nutritionalOrder/UPDATE_INFUSION_TIME', data.nutritional_mixture.infusion_time_in_h );
                    this.comments = data.nutritional_mixture.comments; 
                    
                    data.nutritional_mixture.elements.map( element => {
                        this.macronutrients.map( macronutrient => {
                            if ( macronutrient.componentTypeId == element.component.component_type.id ) {
                                const data = {
                                    id: macronutrient.id,
                                    selectedComponent: element.component,
                                    quantity: ( element.medicine_quantity * element.multiply_factor_to_selected_unit ).toFixed(2),
                                    unitSymbol: element.selected_unit_code, 
                                };
                                this.$store.commit( 'nutritionalOrder/SET_SELECTED_COMPONENT_ITEM', data );
                            }
                        } );
                        this.electrolytes.map( electrolyte => {
                            if ( electrolyte.id == element.component.id ) {
                                const data = {
                                    id: electrolyte.id,
                                    selectedComponent: element.component,
                                    quantity: ( element.medicine_quantity * element.multiply_factor_to_selected_unit ).toFixed(2),
                                    unitSymbol: element.selected_unit_code,
                                    showInput: true,
                                };
                                this.$store.commit( 'nutritionalOrder/SET_SELECTED_ELECTROLYTE_ITEM', data );
                            }
                        } );
                        this.aditives.map( aditive => {
                            if ( aditive.id == element.component.id ) {
                                const data = {
                                    id: aditive.id,
                                    selectedComponent: element.component,
                                    quantity: ( element.medicine_quantity * element.multiply_factor_to_selected_unit ).toFixed(2),
                                    unitSymbol: element.selected_unit_code,
                                    showInput: true,
                                };
                                this.$store.commit( 'nutritionalOrder/SET_SELECTED_ADITIVE_ITEM', data );
                            }
                        });
                        if ( element.component.component_type.id == 8 ) {
                            deliveryDate.infusionSet = true;
                        }
                    } );
                    deliveryDate.deliveryDate = parseISO(data.nutritional_mixture.delivery_date);
                    deliveryDate.deliveryHour = parseISO(data.nutritional_mixture.delivery_date);
                    this.deliveryDates.push(deliveryDate);
                }
            }
        },
    }
};
</script>