import { render, staticRenderFns } from "./medic-autocomplete.vue?vue&type=template&id=8b2ccad0&"
import script from "./medic-autocomplete.vue?vue&type=script&lang=js&"
export * from "./medic-autocomplete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/mezclas.serbitec.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8b2ccad0')) {
      api.createRecord('8b2ccad0', component.options)
    } else {
      api.reload('8b2ccad0', component.options)
    }
    module.hot.accept("./medic-autocomplete.vue?vue&type=template&id=8b2ccad0&", function () {
      api.rerender('8b2ccad0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/dashboard/requests/medic-autocomplete.vue"
export default component.exports