var defaultInternalUserMenu = [
    {
        id: 1,
        label: 'Administrar usuarios',
        items: [
            {
                id: 1,
                label: 'Clientes',
                route: '/payers'
            },
            {
                id: 2,
                label: 'Usuarios internos',
                route: '/internal_users'
            }
        ]
    },
    {
        id: 2,
        label: 'Catálogo de medicamentos',
        items: [
            {
                id: 1,
                label: 'Fabricantes',
                route: '/medicine_manufacturers'
            },
            {
                id: 2,
                label: 'Componentes',
                route: '/components'
            },
            {
                id: 3,
                label: 'Nutricionales',
                route: '/nutritional_catalog'
            },
            {
                id: 4,
                label: 'Oncológicos',
                route: '/oncologic_catalog'
            }
        ]
    },
    {
        id: 3,
        label: 'Administrar almacén',
        items: [
            {
                id: 1,
                label: 'Inventario',
                route: '/warehouse'
            },
            {
                id: 2,
                label: 'Pedidos de las áreas de preparación',
                route: '/warehouse_requests'
            }
        ]
    },
    {
        id: 4,
        label: 'Levantar pedidos',
        items: [
            {
                id: 1,
                label: 'Nutricionales',
                route: '/nutritional_mixtures_request'
            },
            {
                id: 2,
                label: 'Oncológicos',
                route: '/oncologic_orders_request'
            }
        ]
    },
    {
        id: 5,
        label: 'Nutricionales',
        items: [
            {
                id: 1,
                label: 'Registro de mezclas nutricionales',
                route: '/nutritional_mixtures_records'
            },
            {
                id: 2,
                label: 'Revisión de mezclas nutricionales',
                route: '/nutritional_mixtures_authorizer'
            },
            {
                id: 3,
                label: 'Insumos nutricionales del área de preparación',
                route: '/nutritional_preparation_area_supplies'
            },
            {
                id: 4,
                label: 'Área de preparación de mezclas nutricionales',
                route: '/nutritional_preparation_area'
            },
            {
                id: 5,
                label: 'Control de calidad',
                route: '/nutritional_mixtures_quality_control'
            },
            {
                id: 6,
                label: 'Distribución',
                route: '/nutritional_mixtures_distribution'
            }
        ]
    },
    {
        id: 6,
        label: 'Oncológicos',
        items: [
            {
                id: 1,
                label: 'Registro de órdenes oncológicas',
                route: '/oncologic_orders_records'
            },
            {
                id: 2,
                label: 'Revisión de órdenes oncológicas',
                route: '/oncologic_orders_authorizer'
            },
            {
                id: 3,
                label: 'Insumos oncológicos del área de preparación',
                route: '/oncologic_preparation_area_supplies'
            },
            {
                id: 4,
                label: 'Área de preparación de mezclas oncológicas',
                route: '/oncologic_preparation_area'
            },
            {
                id: 5,
                label: 'Control de calidad',
                route: '/oncologic_orders_quality_control'
            },
            {
                id: 6,
                label: 'Distribución',
                route: '/oncologic_orders_distribution'
            }
        ]
    },
    {
        id: 7,
        label: 'Administrativo',
        items: [
            {
                id: 1,
                label: 'Generación de reportes',
                route: '/reports'
            },
            {
                id: 2,
                label: 'Reestablecer intentos de impresión',
                route: '/updates'
            }
        ]
    }
];

export { defaultInternalUserMenu };
