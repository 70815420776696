<template>
    <div>
        <b-loading :active.sync="loading"></b-loading>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right"></div>
        </nav>
        <hr>
        <serbitec-user-form
        v-if="user"
        :user="user"
        @success="userEdited"
        @error="userEditedError"></serbitec-user-form>
    </div>
</template>

<script>
import serbitecUserForm from '../../../components/dashboard/payers/user-form.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';

import gql from 'graphql-tag';
const query = gql` query ( $id: Int! )
{
    user( id: $id ) {
        id
        first_name
        last_name_1
        last_name_2
        email
        medical_card
        types {
            id
        }
    }
}`;

export default {
    components: {
        serbitecUserForm,
        serbitecBreadcumbs
    },
    data() {
        return {
            payerId: this.$route.query.payer_id,
            userId: this.$route.query.user_id,
            loading: false,
            route: [
                {
                    label: 'Clientes',
                    to: '/payers',
                    active: false
                },
                {
                    label: 'Usuarios',
                    to: '/payers/users?payer_id=' + this.$route.query.payer_id,
                    active: false
                },
                {
                    label: 'Editar',
                    to: '/payers/users/edit',
                    active: true
                }
            ]
        };
    },
    methods: {
        userEdited(){
            this.$notify({
                title: '¡Éxito!',
                type: 'success',
                text: 'Se ha editado el usuario correctamente'
            });
            this.$apollo.queries.user.refetch();
        },
        userEditedError(){
            this.$notify({
                title: '¡Algo ha sucedido!',
                type: 'error',
                text: 'Contacte el administrador'
            });
        }
    },
    apollo: {
        user: {
            fetchPolicy: 'network-only',
            query: query,
            variables () {
                return {
                    id: this.userId
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                return data.user;
            }
        },
    }
};
</script>