export default function mixCenterAdmin({ next, router, store, to }) {
    const user = store.state.user;
    const isSuperAdmin = user.types.find( (type) => type.id === 1 ) !== undefined;
    if (isSuperAdmin) {
        return next();
    }
    const isAuthorized = user.authorized_web_routes.find( (authRoute) => authRoute.route === to.path ) !== undefined;
    if (isAuthorized) {
        return next();
    } else {
        router.push('/not-authorized');
    }

    return next();
}