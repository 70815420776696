var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("notifications", {
        attrs: {
          "animation-type": "velocity",
          animation: this.$animation,
          position: "top right",
          classes: "serbitec-light",
          speed: 500,
          max: 5
        }
      }),
      _vm._v(" "),
      _c("b-loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function($event) {
            _vm.loading = $event
          }
        }
      }),
      _vm._v(" "),
      _vm._l(_vm.externalWarehouseRequests, function(request) {
        return _c(
          "div",
          { key: request.id, staticClass: "box" },
          [
            _c("nav", { staticClass: "level" }, [
              _c("div", { staticClass: "level-left" }, [
                _c("h1", [
                  _vm._v("Número de pedido: "),
                  _c("strong", [_vm._v(_vm._s(request.id))])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "level-right" }, [
                _vm.comesFromExternalWarehouse
                  ? _c(
                      "button",
                      {
                        staticClass: "button",
                        on: {
                          click: function($event) {
                            return _vm.cancelRequest(request.id)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "icon" },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "times-circle" }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", [_vm._v("Cancelar solicitud")])
                      ]
                    )
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("b-table", {
              attrs: {
                data: request.items,
                "checked-rows": request.checkedRows,
                checkable: ""
              },
              on: {
                "update:checkedRows": function($event) {
                  return _vm.$set(request, "checkedRows", $event)
                },
                "update:checked-rows": function($event) {
                  return _vm.$set(request, "checkedRows", $event)
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "b-table-column",
                          { attrs: { label: "Lote", numeric: "" } },
                          [
                            _c("p", [
                              props.row.warehouse_item.prefix
                                ? _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        props.row.warehouse_item.prefix.prefix
                                      ) + "-"
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(
                                " " + _vm._s(props.row.warehouse_item.batch)
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("b-table-column", { attrs: { label: "Nombre" } }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                props.row.warehouse_item.medicine_presentation
                                  .medicine.commercial_name
                              ) +
                              "\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-table-column",
                          { attrs: { label: "Presentación", centered: "" } },
                          [
                            _vm.isPieceItem(props.row)
                              ? _c("b-tag", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        props.row.warehouse_item
                                          .medicine_presentation
                                          .component_quantity
                                      ) +
                                      " " +
                                      _vm._s(
                                        props.row.warehouse_item
                                          .medicine_presentation.medicine
                                          .component_unit.symbol
                                      ) +
                                      "\n                        "
                                  )
                                ])
                              : _vm.isOncologicItem(props.row)
                              ? _c("b-tag", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        props.row.warehouse_item
                                          .medicine_presentation
                                          .component_quantity
                                      ) +
                                      " " +
                                      _vm._s(
                                        props.row.warehouse_item
                                          .medicine_presentation.medicine
                                          .component_unit.symbol
                                      ) +
                                      " en " +
                                      _vm._s(
                                        props.row.warehouse_item
                                          .medicine_presentation.volume_in_ml
                                      ) +
                                      " ml\n                        "
                                  )
                                ])
                              : _c("b-tag", [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        props.row.warehouse_item
                                          .medicine_presentation.volume_in_ml
                                      ) +
                                      " ml\n                        "
                                  )
                                ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-table-column",
                          {
                            attrs: { label: "Fecha de caducidad", centered: "" }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(props.row.warehouse_item.expiry_date) +
                                "\n                    "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-table-column",
                          {
                            attrs: {
                              label: "Frascos solicitados",
                              centered: ""
                            }
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(props.row.number_of_flasks) +
                                "\n                    "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            }),
            _vm._v(" "),
            _c("b-field", { staticStyle: { "margin-top": "15px" } }, [
              _c("p", { staticClass: "control has-text-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "button is-primary",
                    attrs: {
                      disabled:
                        request.checkedRows.length != request.items.length
                    },
                    on: {
                      click: function($event) {
                        return _vm.checkRequest(request.id)
                      }
                    }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "icon" },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "check-circle" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.comesFromExternalWarehouse
                      ? _c("span", [_vm._v("Solicitud entregada")])
                      : _c("span", [_vm._v("Solicitud recibida")])
                  ]
                )
              ])
            ])
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.externalWarehouseRequestIsEmpty
        ? _c("b-message", [
            _vm._v(
              "\n        No hay solicitudes pendientes en el almacén\n        "
            ),
            _c("br"),
            _vm._v(" "),
            !_vm.comesFromExternalWarehouse
              ? _c(
                  "small",
                  [
                    _vm._v(
                      "\n        Para realizar una solicitud vaya a la pantalla de solicitar medicamento o de click en el siguiente "
                    ),
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to:
                            "/oncologic_preparation_area_supplies/request_medicines"
                        }
                      },
                      [_vm._v("enlace")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }