<template>
    <div class="box">
        <b-table
            :data="presentations"
            :mobile-cards="true"
        >
            <template slot-scope="props">
                <b-table-column field="ean_code" label="Código EAN">
                    {{ props.row.ean_code }}
                </b-table-column>

                <b-table-column field="component_quantity" label="Cantidad">
                    {{ props.row.component_quantity + ' ' + props.row.medicine.component_unit.symbol }}
                </b-table-column>

                <b-table-column field="volume_in_ml" label="Volumen">
                    {{ props.row.volume_in_ml }} ml
                </b-table-column>

                <b-table-column label="Eliminar" centered>
                    <a
                        class="button is-small is-danger"
                        v-on:click="deletePresentation(props.row.id)"
                    >
                        <font-awesome-icon icon="trash-alt" />
                    </a>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay diluyentes asociados a este medicamento</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    props: {
        presentations: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        deletePresentation (itemID) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar presentación',
                message: '¿Seguro que deseas eliminar la presentación?',
                confirmText: 'Eliminar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteItem(itemID)
            });
        },
        deleteItem (itemID) {
            this.$serbitec.startLoading();
            this.$axios.delete('/api/medicines/presentations/' + itemID)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha eliminado la presentación'
                });
                this.$serbitec.finishLoading();
                this.$emit('reload-presentations');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    }
};
</script>