<template>
    <div>
        <div class="box">
            <div v-for="( arrangedElement, index ) in arrangedElements" :key="index">
                <h1 v-if="arrangedElement.elements.length > 0" class="has-text-weight-bold is-size-5">{{ arrangedElement.name }}</h1>
                <b-table
                :data="arrangedElement.elements">
                    <template slot-scope="props">
                        <b-table-column field="component.name" label="Componente">
                            <div>{{ props.row.component.name }}</div>
                        </b-table-column>

                        <b-table-column label="Cantidad cliente" centered>
                            {{ ( props.row.medicine_quantity * props.row.multiply_factor_to_selected_unit ).toFixed(2) }} {{ props.row.selected_unit_code }}
                        </b-table-column>

                        <b-table-column label="Cantidad en ml" centered>
                            {{ props.row.medicine_quantity }} ml
                        </b-table-column>

                        <b-table-column v-if="arrangedElement.name == 'Macronutrientes'" label="Concentración" centered>
                            {{ totalGramsForMacrocomponent( props.row ) }} %
                        </b-table-column>

                        <b-table-column v-if="arrangedElement.name == 'Electrolitos'" label="Cantidad en mEq" centered>
                            {{ electrolyteInMEq( props.row ) }} mEq
                        </b-table-column>
                    </template>
                </b-table>
            </div>
        </div>
        <b-message v-if="hasInfusionSet" class="is-success">
            <nav class="level">
                <div class="level-left">
                    <h1>Set de infusión</h1>
                </div>
                <div class="level-right">
                    <span class="icon">
                        <font-awesome-icon icon="check-circle" />
                    </span>
                    <span> Solicitado</span>
                </div>
            </nav>
        </b-message>
    </div>
</template>

<script>
import { getmEqPerMlFromName } from '../../../utils.js';

export default {
    props:['elements', 'totalVolume'],
    computed: {
        arrangedElements () {
            const array = [];
            array.push( this.getMacronutrients() );
            array.push( this.getElectrolytes() );
            array.push( this.getAditives() );
            return array; 
        },
        hasInfusionSet () {
            return ( this.elements.find( element => element.component.component_type.id == 8 ) != undefined );
        }
    },
    methods: {
        getMacronutrients () {
            const obj = {};
            obj.name = 'Macronutrientes';
            obj.elements = this.elements.filter( element =>
                element.component.component_type.id == 2
                || element.component.component_type.id == 3
                || element.component.component_type.id == 4
                || element.component.component_type.id == 5 );
            return obj;
        },
        getElectrolytes () {
            const obj = {};
            obj.name = 'Electrolitos';
            obj.elements = this.elements.filter( element => 
                element.component.component_type.id == 10 );
            return obj;
        },
        getAditives () {
            const obj = {};
            obj.name = 'Aditivos';
            obj.elements = this.elements.filter( element => 
                element.component.component_type.id == 11 );
            return obj;
        },
        totalGramsForMacrocomponent ( macronutrient ) {
            if ( macronutrient.component.quantity && macronutrient.component.component_type.id == '7' )
            {
                return null;
            }
            if ( macronutrient.medicine_quantity ) {
                var macronutrientInGrams = macronutrient.medicine_quantity * macronutrient.component.nutritional_field.ml_to_g_factor;
                return ( ( macronutrientInGrams / this.totalVolume ) * 100 ).toFixed(2);
            }
            return 0;
        },
        electrolyteInMEq ( electrolyte ) {
            if ( electrolyte.medicine_quantity ) {
                var mEqPerMl = getmEqPerMlFromName( electrolyte.component.name );
                return ( electrolyte.medicine_quantity * mEqPerMl ).toFixed(2);
            }
            return 0;
        }
    }
};
</script>