<template>
    <div class="box">
        <b-table
            :data="thinners"
            detailed
            detail-key="id"
            :mobile-cards="true">

            <template slot-scope="props">
                <b-table-column field="name" label="Nombre">
                    {{ props.row.name }}
                </b-table-column>

                <b-table-column label="Eliminar" centered>
                    <a class="button is-small is-danger" v-on:click="confirmDeleteMedicine(props.row.pivot.id)">
                        <font-awesome-icon icon="trash-alt" />
                    </a>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay diluyentes asociados a este medicamento</p>
                    </div>
                </section>
            </template>

            <template slot="detail" slot-scope="props">
                <div class="content">
                    <p>
                        <strong>Estabilidad a temperatura ambiente:</strong> {{ props.row.pivot.stability_ambient_in_hours }}
                        <br>
                        <strong>Estabilidad en refrigeración:</strong> {{ props.row.pivot.stability_cooled_in_hours }}
                        <span v-if="props.row.pivot.min_recommended_concentration != null">
                            <br>
                            <strong>Concentración mínima recomendada:</strong> {{ props.row.pivot.min_recommended_concentration }}
                        </span>
                        <span v-else>
                            <br>
                            <i>No hay datos de concentración mínima recomendada</i>
                        </span>
                        <span v-if="props.row.pivot.max_recommended_concentration != null">
                            <br>
                            <strong>Concentración máxima recomendada:</strong> {{ props.row.pivot.max_recommended_concentration }}
                        </span>
                        <span v-else>
                            <br>
                            <i>No hay datos de concentración máxima recomendada</i>
                        </span>
                        <span v-if="props.row.pivot.min_infusion_speed && props.row.pivot.max_infusion_speed && props.row.pivot.infusion_speed_unit">
                            <br>
                            <strong>Velocidad de infusión:</strong> {{ props.row.pivot.min_infusion_speed }} (mínima) y {{ props.row.pivot.max_infusion_speed }} (máxima) {{ props.row.pivot.infusion_speed_unit.symbol }}
                        </span>
                        <span v-else>
                            <br>
                            <i>No hay datos de velocidad de infusión</i>
                        </span>
                        <span v-if="props.row.pivot.reference !== ''">
                            <br>
                            <strong>Referencia:</strong> {{ props.row.pivot.reference }}
                        </span>
                        <span v-else>
                            <br>
                            <i>No hay datos de referencia</i>
                        </span>
                    </p>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    props: {
        thinners: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        confirmDeleteMedicine ( itemId ) {
            this.$buefy.dialog.confirm({
                title: 'Eliminar diluyente',
                message: '¿Seguro que deseas eliminar el diluyente?',
                confirmText: 'Eliminar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteItem(itemId)
            });
        },
        deleteItem (itemId) {
            this.$serbitec.startLoading();
            this.$axios.delete('/api/medicines/oncologic_thinners/' + itemId)
            .then(function () {
                this.$notify({
                    title: '¡Éxito!',
                    type: 'success',
                    text: 'Se ha eliminado el diluyente'
                });
                this.$serbitec.finishLoading();
                this.$emit('reload-thinners');
            }.bind(this))
            .catch(function (error) {
                console.error(error);
                this.$notify({
                    title: '¡Ocurrió un error!',
                    type: 'error',
                    text: error.response.data.error
                });
                this.$serbitec.finishLoading();
            }.bind(this));
        }
    }
};
</script>