<template>
    <div v-if="payerMedicinePresentations">
        <nav class="level">
            <div class="level-left">
                <div class="level-left">
                    <serbitec-breadcumbs
                        :route="route"
                    />
                </div>
            </div>
            <div class="level-right"></div>
        </nav>
        <hr>
        <nav v-if="payerMedicinePresentations.length > 0" class="level">
            <div class="level-left">
            </div>
            <div class="level-right">
                <b-field>
                    <p class="control">
                        <button class="button is-small" @click="confirmDeleteMedicinePresentations">
                            <span class="icon">
                                <font-awesome-icon icon="exchange-alt" />
                            </span>
                            <span> Habilitar precio por medicamento</span>
                        </button>
                    </p>
                </b-field>
            </div>
        </nav>
        <serbitec-payer-medicine-presentations-table
        :payer-medicine-presentations="payerMedicinePresentations"
        :loading="loading"
        @add-medicine-presentations-prices="addMedicinePresentationsPrices"
        @edit-price="editMedicinePresentationPrice"></serbitec-payer-medicine-presentations-table>
    </div>
</template>

<script>
import serbitecPayerMedicinePresentationsTable from '../../../components/dashboard/payers/payer-medicine-presentation-table.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';
import axios from 'axios';

import gql from 'graphql-tag';
const query = gql` query ( $payer_medicine_id: Int! )
{
    payer_medicine_presentations( payer_medicine_id: $payer_medicine_id, order_by: "id", order_direction: "asc" ) {
        id
        price_in_cents_per_ml_or_pz
        payer_medicine {
            id
            medicine {
                id
                commercial_name
                component_unit {
                    id
                    symbol
                }
            }
        }
        medicine_presentation {
            id
            volume_in_ml
            component_quantity
        }
    }
}`;

export default {
    components: {
       serbitecPayerMedicinePresentationsTable,
       serbitecBreadcumbs
    },
    data(){
        return {
            payerId: this.$route.query.payer_id,
            medicinePresentationId: this.$route.query.payer_medicine_id,
            loading: false,
            route: [
                {
                    label: 'Clientes',
                    to: '/payers',
                    active: false
                },
                {
                    label: 'Medicamentos del cliente',
                    to: '/payers/medicines?payer_id=' + this.$route.query.payer_id,
                    active: false
                },
                {
                    label: 'Presentaciones del medicamento',
                    to: '/payers/medicines/presentations',
                    active: true
                }
            ]
        };
    },
    methods:{
        addMedicinePresentationsPrices(){
            this.makeRequest('/api/payers/medicines/' + this.medicinePresentationId + '/init_presentations', axios.post, {}, 'addMedicinePresentationsPrices' );
        },
        editMedicinePresentationPrice( payerMedicinePresentationId, rawPricePerMl ){
            let params = {
                price_in_cents_per_ml_or_pz: parseInt( ( parseFloat( rawPricePerMl ) * 100).toFixed(0) )
            };
            this.makeRequest('/api/payers/medicines/presentations/' + payerMedicinePresentationId, axios.put, params, 'editMedicinePresentationPrice' );
        },
        confirmDeleteMedicinePresentations(){
            var dialogMessage = '¿Seguro que deseas cambiar a precio por medicamento? <br> <small>Los precios por presentación del medicamento se eliminarán.</small>';
            this.$buefy.dialog.confirm({
                title: 'Cambiar a precio por medicamento',
                message: dialogMessage,
                confirmText: 'Cambiar',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteMedicinePresentations()
            });
        },
        deleteMedicinePresentations(){
            this.makeRequest('/api/payers/medicines/' + this.medicinePresentationId + '/presentations', axios.delete, {}, 'deleteMedicinePresentations' );
        },
        async makeRequest( url, method, params, comesFrom ){
            this.loading = true;
            try {
                await method( url, params );
                if ( comesFrom == 'addMedicinePresentationsPrices' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se han habilitado los precios por presentación'
                    });
                }
                if ( comesFrom == 'deleteMedicinePresentations' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se han deshabilitado los precios por presentación'
                    });
                }
                if ( comesFrom == 'editMedicinePresentationPrice' ) {
                    this.$notify({
                        title: '¡Éxito!',
                        type: 'success',
                        text: 'Se ha editado el precio de la presentación'
                    });
                }
                this.$apollo.queries.payerMedicinePresentations.refetch();
                this.loading = false;
            } catch (error) {
                console.error(error);
                this.$notify({
                    title: '¡Algo ha sucedido!',
                    type: 'error',
                    text: 'Por favor contacte al administrador'
                });
                this.loading = false;
            }
        }
    },
    apollo: {
        payerMedicinePresentations: {
            fetchPolicy: 'network-only',
            query: query,
            variables () {
                return {
                    payer_medicine_id: this.medicinePresentationId
                };
            },
            watchLoading ( isLoading ) {
                this.loading = isLoading;
            },
            update ( data ) {
                return data.payer_medicine_presentations;
            }
        },
    }
};
</script>