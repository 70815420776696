var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.payers,
            loading: _vm.loading,
            paginated: "",
            "backend-pagination": "",
            total: _vm.payersCount,
            "per-page": _vm.pageSize,
            "aria-next-label": "Siguiente página",
            "aria-previous-label": "Página anterior",
            "aria-page-label": "Página",
            "aria-current-label": "Página actual",
            "backend-sorting": "",
            "default-sort": [_vm.sortField, _vm.sortOrder]
          },
          on: { "page-change": _vm.changePage, sort: _vm.sort },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c(
                    "b-table-column",
                    { attrs: { label: "Cliente" } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(props.row.invoicing_data.business_name) +
                          "\n                "
                      ),
                      props.row.invoicing_data
                        .price_in_cents_per_nutritional_mixture > 0
                        ? _c("b-tag", [_vm._v("Precio por mezcla NPT")])
                        : _vm._e(),
                      _vm._v(" "),
                      props.row.invoicing_data
                        .price_in_cents_per_oncologic_mixture > 0
                        ? _c("b-tag", [_vm._v("Precio por mezcla Oncológicos")])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Configuración", centered: "" } },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "button is-light is-small",
                          attrs: {
                            to: {
                              path: "configuration",
                              query: { payer_id: props.row.id }
                            },
                            append: ""
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "cog" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Medicamentos", centered: "" } },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "button is-light is-small",
                          attrs: {
                            to: {
                              path: "medicines",
                              query: { payer_id: props.row.id }
                            },
                            append: ""
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "tablets" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Usuarios", centered: "" } },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "button is-light is-small",
                          attrs: {
                            to: {
                              path: "users",
                              query: { payer_id: props.row.id }
                            },
                            append: ""
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "users" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Editar", centered: "" } },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "button is-light is-small",
                          attrs: {
                            to: {
                              path: "edit",
                              query: { payer_id: props.row.id }
                            },
                            append: ""
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "edit" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("No hay clientes registrados.")])
                ]
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }