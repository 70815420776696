<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right" />
        </nav>
        <hr>
        <serbitec-request-medicine-form
            internal-warehouse-type="oncologic" />
        <serbitec-requested-items-table
            class="serbitec-small-top-margin"
            internal-warehouse-type="oncologic" />
    </div>
</template>

<script>
import serbitecRequestMedicineForm from '../../../components/dashboard/preparation-area-supplies/request-medicine-form.vue';
import serbitecRequestedItemsTable from '../../../components/dashboard/preparation-area-supplies/requested-items-table.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';

export default {
    components: {
        serbitecRequestMedicineForm,
        serbitecRequestedItemsTable,
        serbitecBreadcumbs
    },
    data () {
        return {
            route: [
                {
                    label: 'Insumos oncológicos del área de preparación',
                    to: '/oncologic_preparation_area_supplies',
                    active: false
                },
                {
                    label: 'Solicitud de medicamento',
                    to: '/oncologic_preparation_area_supplies/request_medicines',
                    active: true
                }
            ]
        };
    }
};
</script>