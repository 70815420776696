<template>
    <div class="box">
        <nav v-if="patient" class="level">
            <div class="level-left"></div>
        
            <div class="level-right">
                <span class="icon">
                    <i class="fas fa-weight"></i>
                </span>
                <span>Peso del paciente: <strong>{{ patient.weight_in_kilograms }} Kg</strong></span>
            </div>
        </nav>
        <b-table
        :data="tableData">
            <template slot-scope="props">
                <b-table-column field="name">
                    {{ props.row.name }}
                </b-table-column>

                <b-table-column label="mEq" centered>
                    {{ electrolyteInMEqForRow( props.row ) }}
                </b-table-column>

                <b-table-column label="mEq/Kg" centered>
                    {{ electrolyteInMEqPerKgForRow( props.row ) }}
                </b-table-column>

                <b-table-column field="ml" label="ml" centered>
                    {{ electrolyteInMlForRow( props.row ) }}
                </b-table-column>
            </template>
        </b-table>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { electrolyteInMEq, electrolyteInMEqPerKg, electrolyteInMl } from '../../../../utils';

export default {
    props: ['tableData'],
    computed: {
        ...mapState({
            patient: state => state.nutritionalOrder.patient,
        })
    },
    methods:{
        electrolyteInMEqForRow ( row ) {
            return electrolyteInMEq( row, this.patient.weight_in_kilograms ); 
        },
        electrolyteInMEqPerKgForRow ( row ) {
            return electrolyteInMEqPerKg( row, this.patient.weight_in_kilograms );
        },
        electrolyteInMlForRow (row) {
            return electrolyteInMl( row, this.patient.weight_in_kilograms );
        },
    }
};
</script>