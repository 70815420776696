var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "b-table",
        {
          attrs: {
            data: _vm.patients,
            loading: _vm.loading,
            paginated: "",
            "backend-pagination": "",
            total: _vm.patientsCount,
            "per-page": _vm.pageSize,
            "aria-next-label": "Siguiente página",
            "aria-previous-label": "Página anterior",
            "aria-page-label": "Página",
            "aria-current-label": "Página actual",
            "backend-sorting": "",
            "default-sort": [_vm.sortField, _vm.sortOrder],
            detailed: "",
            "detail-key": "id"
          },
          on: { "page-change": _vm.changePage, sort: _vm.sort },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(props) {
                return [
                  _c("b-table-column", { attrs: { label: "Paciente" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.name) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("b-table-column", { attrs: { label: "Diagnóstico" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(props.row.diagnosis) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Editar", centered: "" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "button is-light is-small",
                          on: {
                            click: function($event) {
                              return _vm.editPatient(props.row)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "edit" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-table-column",
                    { attrs: { label: "Eliminar", centered: "" } },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "button is-light is-small is-danger",
                          on: {
                            click: function($event) {
                              return _vm.confirmDeletePatient(props.row.id)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "icon is-medium" },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "trash-alt" }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                ]
              }
            },
            {
              key: "detail",
              fn: function(props) {
                return [
                  _c("b-message", { attrs: { size: "is-small" } }, [
                    _c("strong", [
                      _vm._v("Información adicional del paciente")
                    ]),
                    _c("br"),
                    _vm._v(" "),
                    _c("div", { staticClass: "columns" }, [
                      _c("div", { staticClass: "column is-half" }, [
                        _vm._v("\n                        Talla: "),
                        _c("strong", [
                          _vm._v(_vm._s(props.row.height_in_meters + " m"))
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v("\n                        Peso: "),
                        _c("strong", [
                          _vm._v(_vm._s(props.row.weight_in_kilograms + " Kg"))
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v("\n                        Edad: "),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.computeAge(props.row.birth_date)))
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v("\n                        Género: "),
                        _c("strong", [_vm._v(_vm._s(props.row.gender))]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v("\n                        Tipo: "),
                        _c("strong", [_vm._v(_vm._s(props.row.age_type))]),
                        _vm._v(" "),
                        _c("br")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "column is-half" }, [
                        _vm._v(
                          "\n                        Superficie corporal: "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(props.row.body_surface_in_square_meters)
                          )
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v("\n                        Expediente: "),
                        _c("strong", [_vm._v(_vm._s(props.row.file_id))]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v("\n                        Servicio: "),
                        _c("strong", [_vm._v(_vm._s(props.row.location))]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v("\n                        Cama: "),
                        _c("strong", [_vm._v(_vm._s(props.row.bed))]),
                        _vm._v(" "),
                        _c("br")
                      ])
                    ])
                  ])
                ]
              }
            }
          ])
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c("template", { slot: "empty" }, [
            _c("section", { staticClass: "section" }, [
              _c(
                "div",
                { staticClass: "content has-text-grey has-text-centered" },
                [
                  _c(
                    "p",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          size: "is-large"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("p", [_vm._v("No hay pacientes registrados.")])
                ]
              )
            ])
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }