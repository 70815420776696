<template>
    <div class="box">
        <nav class="level">
            <div class="level-left">
                <h1>Fechas de solicitud de mezclas</h1>
            </div>
            <div v-if="!comesFromEdit" class="level-right">
                <button class="button" @click="addNewDeliveryDate">
                <span class="icon">
                    <font-awesome-icon icon="plus" />
                </span>
                <span>Agregar mezcla a la orden</span>
                </button>
            </div>
        </nav>
        <hr/>
        <div v-for="(deliveryDate, key) in deliveryDates" :key="deliveryDate.id" class="columns" style="margin-bottom:15px">
            <div v-if="!withoutInfusionSet" class="column is-3">
                <b-field>
                    <b-checkbox v-model="deliveryDate.infusionSet">Set de infusión</b-checkbox>
                </b-field>
            </div>
            <div class="column" :class="{ 'is-8': !withoutInfusionSet }">
                <b-field grouped group-multiline="">
                    <b-field
                    :label="'Fecha de entrega'"
                    :message="[
                        'De click para modificar la fecha de entrega'
                    ]"
                    expanded>
                        <b-datepicker
                            v-model.trim="deliveryDate.deliveryDate"
                            :date-formatter="d => d.toLocaleDateString('es-US',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })"
                            :min-date="deliveryDate.minDate">
                        </b-datepicker>
                    </b-field>
                    <b-field
                    :label="'Hora de entrega'"
                    :message="[
                        'De click para modificar la hora de entrega'
                    ]"
                    expanded>
                        <b-timepicker
                            v-model.trim="deliveryDate.deliveryHour">
                        </b-timepicker>
                    </b-field>
                </b-field>
            </div>
            <div v-if="deliveryDates.length > 1" class="column is-narrow">
                <b-field>
                <p class="control">
                    <button class="button is-small" @click="removeDeliveryDate(key)">
                    <span class="icon">
                        <font-awesome-icon icon="minus" />
                    </span>
                    </button>
                </p>
                </b-field>
            </div>
        </div>
    </div>
</template>

<script>
import { setRandomId } from '../../../utils';

const today = new Date();
const min = new Date();
min.setHours(today.getHours());
min.setMinutes(today.getMinutes());

export default {
    props:['deliveryDates', 'comesFromEdit', 'withoutInfusionSet'],
    methods: {
        addNewDeliveryDate () {
            var newDeliveryDate = {
                id: setRandomId(),
                deliveryDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
                deliveryHour: min,
                minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
            };
            if ( !this.withoutInfusionSet ) {
                newDeliveryDate.infusionSet = false;
            }
            this.deliveryDates.push( newDeliveryDate );
        },
        removeDeliveryDate (index) {
            this.deliveryDates.splice(index,1);
        },
    }
};
</script>