var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("serbitec-nutritional-distribution-table", {
        attrs: {
          "table-type": "nutritional",
          mixtures: _vm.mixtures,
          "mixtures-count": _vm.mixturesCount,
          loading: _vm.loading,
          page: _vm.page,
          pageSize: _vm.pageSize,
          sortField: _vm.sortField,
          sortOrder: _vm.sortOrder
        },
        on: {
          "update:loading": function($event) {
            _vm.loading = $event
          },
          "update:page": function($event) {
            _vm.page = $event
          },
          "update:sortField": function($event) {
            _vm.sortField = $event
          },
          "update:sort-field": function($event) {
            _vm.sortField = $event
          },
          "update:sortOrder": function($event) {
            _vm.sortOrder = $event
          },
          "update:sort-order": function($event) {
            _vm.sortOrder = $event
          },
          "page-change": _vm.onPageChange,
          sort: _vm.onSort,
          "order-sent": _vm.orderSent,
          "order-delivered": _vm.orderDelivered
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { staticClass: "level is-mobile" }, [
      _c("div", { staticClass: "level-left" }, [
        _c("div", { staticClass: "level-item" }, [
          _c("h1", { staticClass: "title" }, [
            _vm._v("Distribución de mezclas nutricionales")
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "level-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }