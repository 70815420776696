<template>
    <div class="columns">
        <div class="column serbitec-box-margin-top-bottom is-two-fifths">
            <div class="box content is-small">
                <h4 class="subtitle">Usuario: {{ medic.first_name }} {{ medic.last_name_1 }} {{ medic.last_name_2 }}</h4>
                <p>
                    <strong>Cliente: </strong>{{ medic.payer.invoicing_data.business_name }}
                    <br>
                    <strong>Cédula médica: </strong>{{ medic.medical_card }}
                </p>
            </div>
        </div>
        <div class="column is-three-fifths serbitec-box-margin-top-bottom">
            <div class="box content is-small">
                <h4 class="subtitle">Paciente: {{ patient.name }}</h4>
                <strong>Diagnóstico: </strong>{{ patient.diagnosis }}
                <div class="columns">
                    <div class="column">
                        <strong>Talla: </strong>{{ patient.height_in_meters }}
                        <br>
                        <strong>Peso: </strong>{{ patient.weight_in_kilograms }}
                        <br>
                        <strong>Edad: </strong>{{ computeAge(patient.birth_date) }}
                    </div>
                    <div class="column is-half">
                        <strong>Expediente: </strong>{{ patient.file_id }}
                        <br>
                        <strong>Servicio: </strong>{{ patient.location }}
                        <br>
                        <strong>Cama: </strong>{{ patient.bed }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import { es } from 'date-fns/locale'

export default {
    props: ['medic', 'patient'],
    methods: {
        computeAge: function (birth_date) {
            var birthDate = new Date(birth_date);
            var now = new Date();
            var age = formatDistanceStrict(now,birthDate,{ locale: es });
            return age;
        },
    }
};
</script>

<style lang="scss">
.serbitec-box-margin-top-bottom {
    margin-top: 15px;
    margin-bottom: 15px;
}
.icon-text-space {
    padding-right: 15px
}
</style>