<template>
    <div class="box">
        <b-table
            :data="mixtures"
            :loading="loading"

            paginated
            backend-pagination
            :total="mixturesCount"
            :per-page="pageSize"
            @page-change="changePage"
            
            aria-next-label="Siguiente página"
            aria-previous-label="Página anterior"
            aria-page-label="Página"
            aria-current-label="Página actual"

            backend-sorting
            :default-sort="[sortField, sortOrder]"
            @sort="sort">

            <template slot-scope="props">
                <b-table-column field="id" label="Orden" numeric sortable>
                    <p v-if="isNutritional">ON-{{ props.row.order.id }}</p>
                    <p v-else-if="isOncologic">OO-{{ props.row.id }}</p>
                </b-table-column>

                <b-table-column v-if="isNutritional" label="Lote" numeric>
                    <p>LN-{{ props.row.id }}</p>
                </b-table-column>

                <b-table-column label="Paciente">
                    <p v-if="isNutritional">{{ props.row.order.patient.name }}</p>
                    <p v-else-if="isOncologic">{{ props.row.patient.name }}</p>
                </b-table-column>

                <b-table-column field="delivery_date" label="Fecha de entrega" sortable centered>
                    <p v-if="isNutritional">{{ formatDate( props.row.delivery_date ) }}</p>
                    <p v-else-if="isOncologic">{{ formatDate( props.row.delivery_date ) }}</p>
                </b-table-column>

                <b-table-column label="Hora de entrega" centered>
                    <p v-if="isNutritional">{{ formatTime( props.row.delivery_date ) }}</p>
                    <p v-else-if="isOncologic">{{ formatTime( props.row.delivery_date ) }}</p>
                </b-table-column>

                <b-table-column label="Estado">
                    <b-tag v-if="isNutritional">{{ props.row.state.name }}</b-tag>
                    <b-tag v-if="isOncologic">{{ setOrderState(props.row) }}</b-tag>
                </b-table-column>

                <b-table-column label="Detalles" centered>
                    <router-link
                        v-if="isNutritional"
                        :to="{ path: 'mixture_details', query: { mixtureId: props.row.id } }"
                        class="button is-light is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="info-circle" />
                        </span>
                    </router-link>
                    <router-link
                        v-else-if="isOncologic"
                        :to="{ path: 'order_details', query: { orderId: props.row.id } }"
                        class="button is-light is-small"
                        append
                    >
                        <span class="icon is-medium">
                            <font-awesome-icon icon="info-circle" />
                        </span>
                    </router-link>
                </b-table-column>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>
                            <b-icon
                                icon="exclamation-triangle"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>No hay registros realizados.</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { es } from 'date-fns/locale';

export default {
    props: [
        'tableType',
        'mixtures',
        'loading',
        'mixturesCount',
        'page',
        'pageSize',
        'sortField',
        'sortOrder'
    ],
    computed: {
        isOncologic () {
            return this.tableType == 'oncologic';
        },
        isNutritional () {
            return this.tableType == 'nutritional';
        }
    },
    methods: {
        formatDate( date ) {
            return format( parseISO(date), "d 'de' MMMM yyyy", { locale: es } );
        },
        formatTime( date ) {
            return format( parseISO( date ), "HH:mm " );
        },
        changePage ( page ) {
            this.$emit('page-change', page);
        },
        sort ( field, order ) {
            this.$emit('sort', field, order);
        },
        setOrderState ( order ) {
            if ( order.mixtures.length ) {
                var mixtureInVerification = order.mixtures.find( mixture => mixture.state.id == 1 );
                if ( mixtureInVerification ) {
                    return 'En verificación';
                }
                var mixtureInPreparation = order.mixtures.find( mixture => mixture.state.id == 2 || mixture.state.id == 4 || mixture.state.id == 5 || mixture.state.id ==6 || mixture.state.id == 9 );
                if ( mixtureInPreparation ) {
                    return 'En preparación';
                }
                var mixtureSent = order.mixtures.find( mixture => mixture.state.id == 8 );
                if ( mixtureSent ) {
                    return 'Enviada';
                }
                var mixtureDelivered = order.mixtures.find( mixture => mixture.state.id == 10 );
                if ( mixtureDelivered ) {
                    return 'Entregada';
                }
            } else if ( order.requested_flasks.length ) {
                var flaskInVerification = order.requested_flasks.find( flask => flask.state.id == 1 );
                if ( flaskInVerification ) {
                    return 'En verificación';
                }
                var flaskInPreparation = order.requested_flasks.find( flask => flask.state.id == 2 || flask.state.id == 4 || flask.state.id == 5 || flask.state.id == 6 || flask.state.id == 9 );
                if ( flaskInPreparation ) {
                    return 'En preparación';
                }
                var flaskSent = order.requested_flasks.find( flask => flask.state.id == 8 );
                if ( flaskSent ) {
                    return 'Enviada';
                }
                var flaskDelivered = order.requested_flasks.find( flask => flask.state.id == 10 );
                if ( flaskDelivered ) {
                    return 'Entregada';
                }
            }
        },
    }
};
</script>