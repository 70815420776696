var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("nav", { staticClass: "level" }, [
        _c("div", { staticClass: "level-left" }, [
          _c(
            "div",
            { staticClass: "level-left" },
            [_c("serbitec-breadcumbs", { attrs: { route: _vm.route } })],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "level-right" }, [
          _c(
            "a",
            { staticClass: "button", on: { click: _vm.addPresentation } },
            [
              _c(
                "span",
                { staticClass: "icon" },
                [_c("font-awesome-icon", { attrs: { icon: "plus" } })],
                1
              ),
              _vm._v(" "),
              _c("span", [_vm._v(" Agregar presentación")])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("serbitec-medicine-info", {
        attrs: { "medicine-id": _vm.medicineId }
      }),
      _vm._v(" "),
      _vm.medicine
        ? _c("serbitec-presentations-table", {
            staticClass: "serbitec-medium-top-margin",
            attrs: { presentations: _vm.medicine.presentations },
            on: {
              "reload-presentations": function($event) {
                return _vm.$apollo.queries.medicine.refresh()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }