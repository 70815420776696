<template>
    <div>
        <nav class="level">
            <div class="level-left">
                <serbitec-breadcumbs
                    :route="route"
                />
            </div>
            <div class="level-right" />
        </nav>
        <hr>
        <serbitec-nutritional-requested-items-check-table
            :comes-from-external-warehouse="false"
        />
    </div>
</template>

<script>
import serbitecNutritionalRequestedItemsCheckTable from '../../../components/dashboard/warehouse-requests/nutritional-requested-items-check-table.vue';
import serbitecBreadcumbs from '../../../components/dashboard/utils/breadcumbs.vue';

export default {
    components: {
        serbitecNutritionalRequestedItemsCheckTable,
        serbitecBreadcumbs
    },
    data () {
        return {
            route: [
                {
                    label: 'Insumos nutricionales del área de preparación',
                    to: '/nutritional_preparation_area_supplies',
                    active: false
                },
                {
                    label: 'Recepción de medicamento',
                    to: '/nutritional_preparation_area_supplies/receive_medicines',
                    active: true
                }
            ]
        };
    }
};
</script>