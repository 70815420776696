<template>
    <b-message>
        <nav class="level">
            <div class="level-left">
                <span class="icon is-small">
                    <font-awesome-icon icon="user" />
                </span>
                <span style="padding-left: 15px">
                    Usuario:
                    <strong>{{ medic.first_name }} {{ medic.last_name_1 }} {{ medic.last_name_2 }}</strong>
                    (Cédula médica: {{ medic.medical_card }})
                </span>
            </div>

            <div class="level-right">
                <div v-if="!isFixed" class="field">
                    <a class="button" @click="$emit('medic-unselected')">
                        <span>Cambiar</span>
                        <span class="icon is-small">
                            <font-awesome-icon icon="exchange-alt" />
                        </span>
                    </a>
                </div>
            </div>
        </nav>
    </b-message>
</template>

<script>
export default {
    props: {
        isFixed: {
            type: Boolean,
            default: false
        },
        medic: {
            type: Object,
            default: () => {}
        }
    }
};
</script>